import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../../utils/shared-services/caption.service';
import { EditColumnsData } from '../wid-016';
import { BrowserStorage } from '../../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'editColumnsDialogWid016',
    templateUrl: 'src/app/features/store-portal/widgets/templates/dialogs/wid-016-edit-columns-dialog.html',
})
export class EditColumnsDialogWid016 {
    public captions;
    public columnSelectForm: any;
    public wid016_col1 = 10;
    public wid016_col2 = 5;
    public wid016_col3 = 4;

    private readonly WIDGET_COLUMN1_KEY: string = 'wid016_col1';
    private readonly WIDGET_COLUMN2_KEY: string = 'wid016_col2';
    private readonly WIDGET_COLUMN3_KEY: string = 'wid016_col3';

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EditColumnsDialogWid016>,
        @Inject(CaptionService) private captionService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(UntypedFormBuilder) private formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: EditColumnsData
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit() {
        this.wid016_col1 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN1_KEY, this.data.wid016_col1);
        this.wid016_col2 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN2_KEY, this.data.wid016_col2);
        this.wid016_col3 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN3_KEY, this.data.wid016_col3);
        this.columnSelectForm = this.formBuilder.group({
            column1Selection:
                this.wid016_col1 != 10 ? [this.wid016_col1.toString(), Validators.required] : ['10', Validators.required],
            column2Selection:
                this.wid016_col2 != 5 ? [this.wid016_col2.toString(), Validators.required] : ['5', Validators.required],
            column3Selection:
                this.wid016_col3 != 4 ? [this.wid016_col3.toString(), Validators.required] : ['4', Validators.required],
        });
    }

    saveColumns() {
        const dialogData: EditColumnsData = {
            wid016_col1: this.columnSelectForm.value.column1Selection,
            wid016_col2: this.columnSelectForm.value.column2Selection,
            wid016_col3: this.columnSelectForm.value.column3Selection,
        };

        this.dialogRef.close(dialogData);
    }
}
