import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CreateTagSelectedStoresDialog } from '../../../utils/shared-components/user-access/create-tag-selected-stores-dialog.component';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { EmitterService } from '../../../utils/shared-services/emitter.service';
import { ConceptApiService } from '../../concepts/services/concept.resource';
import { StoreApiService } from '../../stores/services/store.resource';
import { TagApiService } from '../../tags/services/tag.resource';
import { PrivilegeName, PrivilegeType, UserPrivileges } from '../enums/user-privileges.enum';
import { UserRole } from '../enums/user-role.enum';
import { UserAccessHandler } from '../services/user-access-handler.service';
import { UserEmitterService } from '../services/user-emitter.service';
import { UserSecurityApiService } from '../services/user-security.resource';
import { UserApiService } from '../services/users.resource';
import { UserConstants } from '../users.constants';

@Component({
    selector: 'manageUserAccess',
    templateUrl: 'src/app/features/users/templates/manage.access-user.html',
})
export class ManageUserAccessComponent {
    public captions;
    private USER_ACCESS;
    public currentUserSecurity;
    public passedUserId;
    public isGroupItemOrTagUser;
    public userName: any;
    public passedUser;
    public newTag;
    public selectedUserSecurity;
    public cancel;
    public currentUser;
    public storesForTag;
    public subscriptions: Subscription[] = [];
    public isDataAvailable = false;
    public form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
    previousPath: any;

    constructor(
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(UserSecurityApiService) private userSecurityApiService: UserSecurityApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(DataUtils) private dataUtils: DataUtils,
        @Inject(UserEmitterService) private userEmitterService: UserEmitterService,
        @Inject(EmitterService) private emitterService: EmitterService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUser = this.userAccessHandler.getUserAccess();
        this.isGroupItemOrTagUser = true;
        this.newTag = {
            displayName: '',
            conceptID: null,
        };
        this.form = this.formBuilder.group({});

        this.subscriptions.push(
            this.userEmitterService.groupItemChangedDirectiveItem$.subscribe((groupItemAndConcept) => {
                this.groupItemPickerHandler(groupItemAndConcept[0].id ?? groupItemAndConcept[0][0].id);
            })
        );

        this.subscriptions.push(
            this.userEmitterService.tagChangedDirectiveItem$.subscribe((tagAndConcept) => {
                this.tagPickerHandler(tagAndConcept[0].id ?? tagAndConcept[0][0].id);
            })
        );

        this.subscriptions.push(
            this.userEmitterService.storeChangedDirectiveItem$.subscribe((stores: any) => {
                if (stores.length) {
                    this.storePickerHandler(stores);
                } else {
                    let tempArray = [];
                    this.storePickerHandler(tempArray.push(stores));
                }
            })
        );

        this.subscriptions.push(
            this.emitterService.conceptLoadedCtrlItem$.subscribe((concept) => {
                this.userEmitterService.conceptLoadedDirective(concept);
            })
        );

        this.subscriptions.push(
            // selected a concept
            this.emitterService.conceptChangedCtrlItem$.subscribe((concept) => {
                this.userEmitterService.conceptChangedDirective(concept);
            })
        );

        this.subscriptions.push(
            this.emitterService.groupItemLoadedCtrlItem$.subscribe((groupItem) => {
                this.userEmitterService.groupItemLoadedDirective(groupItem);
            })
        );

        this.subscriptions.push(
            // selected a group
            this.emitterService.groupItemChangedCtrlItem$.subscribe((groupItemAndConcept) => {
                if (groupItemAndConcept[0] && groupItemAndConcept[0][0]?.id) {
                    this.groupItemPickerHandler(groupItemAndConcept[0][0].id);
                }
                this.userEmitterService.groupItemChangedDirective(groupItemAndConcept[0], groupItemAndConcept[1]);
            })
        );

        this.subscriptions.push(
            this.emitterService.tagLoadedCtrlItem$.subscribe((tag) => {
                this.userEmitterService.tagLoadedDirective(tag);
            })
        );

        this.subscriptions.push(
            this.emitterService.tagChangedCtrlItem$.subscribe((tagAndConcept) => {
                if (tagAndConcept[0] && tagAndConcept[0][0]?.id) {
                    this.tagPickerHandler(tagAndConcept[0][0].id);
                } else {
                    this.tagPickerHandler(0);
                }
                this.userEmitterService.tagChangedDirective(tagAndConcept[0], tagAndConcept[1]);
            })
        );

        this.subscriptions.push(
            this.emitterService.storeChangedForGroupItemCtrlItem$.subscribe((storeAndGroupItem) => {
                this.userEmitterService.storeChangedForGroupItemDirective(storeAndGroupItem[0], storeAndGroupItem[1]);
            })
        );

        this.subscriptions.push(
            this.emitterService.storeChangedForTagCtrlItem$.subscribe((storeAndTag) => {
                this.userEmitterService.storeChangedForTagDirective(storeAndTag[0], storeAndTag[1]);
            })
        );

        this.subscriptions.push(
            this.emitterService.storeChangedCtrlItem$.subscribe((allStoresSelected) => {
                this.userEmitterService.storeChangedDirective(allStoresSelected);
            })
        );

        this.subscriptions.push(
            this.emitterService.addTagForSelectedStoresPopUpItem$.subscribe((storesAndDialog) => {
                this.storesForTag = storesAndDialog[0];

                const dialogRef = this.dialog.open(CreateTagSelectedStoresDialog, {
                    panelClass: 'app-full-bleed-dialog',
                    maxWidth: '90vw',
                });

                dialogRef.afterClosed().subscribe((res) => {
                    if (typeof res != 'undefined') {
                        this.newTag = res.data.tag;
                        this.newTag.conceptID = this.selectedUserSecurity.conceptID;
                        this.tagApiService.createTag(this.newTag).then(
                            (tag) => {
                                this.createTagSuccess(tag);
                                this.conceptApiService
                                    .getConceptById(this.selectedUserSecurity.conceptID)
                                    .then((concept: any) => {
                                        this.emitterService.tagChangedCtrl(tag, concept);
                                    });
                            },
                            (reason) => {
                                this.createTagError(reason);
                            }
                        );
                    } else {
                        // tag creation was cancelled
                        this.cancelTagCreation();
                    }
                });
            })
        );
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.passedUserId = +params['pathId'];
        });
        this.passedUser = this.browserStorage.getSessionstorage('newUserForm', null);
        this.actRoute.queryParams.subscribe((params) => {
            this.previousPath = params['state'];
        });
        if (this.passedUser) {
            this.cancel = () => {
                this.router.navigate(['users/addUser']);
            };
        } else if (this.passedUserId) {
            this.cancel = () => {
                this.router.navigate(['users/editUser', this.passedUserId]);
            };
        } else {
            this.router.navigate(['users/allUsers']);
        }
        this._getCurrentUserSecurity(this.currentUser);
        this._setUserName();
    }

    ngOnDestroy() {
        for (let sub of this.subscriptions) {
            sub?.unsubscribe();
        }
    }

    _isFromAddUser() {
        return this.previousPath === 'addUser';
    }

    _getCurrentUserSecurity(currentUser) {
        this.currentUserSecurity = currentUser;

        this.getSecurityAccessByUserId(this.passedUserId);
        if (currentUser.role === this.USER_ACCESS.GROUP_ITEM || currentUser.role === this.USER_ACCESS.TAG)
            this.isGroupItemOrTagUser = false;
    }

    getSelectedUserInfoById(userId) {
        this.userApiService.getUserById(userId).then((user: any) => {
            this.userName = user.firstName + ' ' + user.lastName;
        });
    }

    _setUserName() {
        if (!this._isFromAddUser()) {
            this.getSelectedUserInfoById(this.passedUserId);
        } else {
            this.userName = this.passedUser.firstName + ' ' + this.passedUser.lastName;
        }
    }

    getSecurityAccessByUserId(userId) {
        this.userSecurityApiService.getSelectedUserById(userId).then((selectedUserSecurity: any) => {
            this.selectedUserSecurity = selectedUserSecurity;

            this.isDataAvailable = true;
            if (selectedUserSecurity.role === this.USER_ACCESS.GLOBAL) this.isGroupItemOrTagUser = false;
        });
    }

    createTagSuccess(tag) {
        for (let store of this.storesForTag) {
            tag.isActive = true;
            let storeToSubmitToTag = {
                conceptID: store.conceptID,
                displayName: store.displayName,
                id: store.id,
                tags: [tag],
            };
            this.storeApiService.updateTagsForStore(storeToSubmitToTag);
        }
        this.userEmitterService.createTagForSelectedStores(tag);
        this.newTag.displayName = '';
    }
    createTagError(reason) {
        this.userEmitterService.cancelTagCreation(null);
    }

    cancelTagCreation() {
        this.userEmitterService.cancelTagCreation(null);
    }

    submitUser() {
        if (!this._isFromAddUser()) {
            this.userSecurityApiService.updateUserSecurity(this.selectedUserSecurity).then(() => {
                this.router.navigate(['users/editUser', this.passedUserId]);
            });
        } else {
            this.passedUser.conceptID = this.selectedUserSecurity.conceptID;
            this.passedUser.groupItemID = this.selectedUserSecurity.groupItemID;
            this.passedUser.tagID = this.selectedUserSecurity.tagID;
            this.passedUser.storeID = this.selectedUserSecurity.storeID;
            this.passedUser.role = this.dataUtils.getRoleFromIDs(
                this.passedUser.conceptID,
                this.passedUser.groupItemID,
                this.passedUser.tagID,
                this.passedUser.storeID
            );
            this.getValidPrivilegesForRole(this.passedUser).then(privileges => {
                this.passedUser.userPrivilege = privileges;

                this.browserStorage.setSessionstorage('newUserForm', this.passedUser);
                this.router.navigate(['users/addUser']);
            });
        }
    }

    groupItemPickerHandler(groupItemModel) {
        this.selectedUserSecurity.groupItemID = groupItemModel;
    }

    storePickerHandler(storeModel) {
        this.selectedUserSecurity.storeID = storeModel;
    }

    tagPickerHandler(tagModel) {
        this.selectedUserSecurity.tagID = tagModel;
    }

    conceptPickerHandler(conceptModel) {
        this.selectedUserSecurity.conceptID = conceptModel;
    }

    private async getValidPrivilegesForRole(user: {role: UserRole, userPrivilege: string}): Promise<string> {
        if (user.userPrivilege !== null) {

            if (user.role === UserRole.Global) {
                return user.userPrivilege;
            }

            let privilegesForRole = await this.userApiService.getUserPrivileges(user);
            let userPrivileges = JSON.parse(user.userPrivilege) as {name: PrivilegeName, type: PrivilegeType}[];

            // if `userPrivileges` is empty, add all privileges available for that role
            // except for VIEW_SHARED_DASH_ONLY and MANAGE_COUPONS
            if (userPrivileges.length <= 0) {
                for (let privilegeCategory of privilegesForRole.privilegeCategories) {
                    for (let privilege of privilegeCategory.userPrivilege) {
                        if (!["VIEW_SHARED_DASH_ONLY", "MANAGE_COUPONS"].includes(privilege.name)) {
                            userPrivileges.push({
                                name: privilege.name,
                                type: privilege.type,
                            });
                        }
                    }
                }
            }

            // if `userPrivileges` is not empty, just filter the list to only 
            // contain privileges that are available for that role
            else {
                userPrivileges = userPrivileges.filter(p => {
                    for (let privilegeCategory of privilegesForRole.privilegeCategories) {
                        for (let privilege of privilegeCategory.userPrivilege) {
                            if (privilege.name === p.name) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
            }

            return JSON.stringify(userPrivileges);
        }
    }
}
