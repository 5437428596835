import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import intlTelInput from 'intl-tel-input';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { UserApiService } from '../services/users.resource';
import { ValidationService } from '../../../utils/shared-services/validation.service';

export interface SubscriptionsDialogData {
    user: any;
    subscriptions: any;
    notificationConsent: any;
    emailSub: any;
    phoneSub: any;
}

@Component({
    selector: 'subscriptions-dialog',
    templateUrl: 'src/app/features/users/templates/subscriptions-dialog.html',
})
export class SubscriptionsDialog {
    public iti: any;
    public captions: any;
    public form: UntypedFormGroup;
    public formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<SubscriptionsDialog>,
        @Inject(CaptionService) private captionService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(MAT_DIALOG_DATA) public data: SubscriptionsDialogData,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit() {
        let input = document.querySelector('#phone');
        this.iti = intlTelInput(input, {
            utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js',
            autoPlaceholder: 'aggressive',
            autoHideDialCode: false,
            separateDialCode: true,
        });
    }

    addEmail() {
        if (!this.data.notificationConsent) {
            this.snackbarService.errorMessageBottom(this.captions.pleaseConsent);
        } else {
            this.submitSubscription(this.data.emailSub);
            this.data.notificationConsent = false;
            this.data.emailSub = '';
        }
    }

    addPhoneNumber() {
        if (!this.data.notificationConsent) {
            this.snackbarService.errorMessageBottom(this.captions.pleaseConsent);
        } else {
            let isValid = this.iti.isValidNumber();
            let error = this.iti.getValidationError();
            let errMsg = '';
            if (!isValid) {
                switch (error) {
                    case -99:
                        errMsg = this.captions.enterPhoneNumber;
                        break;
                    case 0:
                        errMsg = this.captions.notValidPhoneNumber;
                        break;
                    case 1:
                        errMsg = this.captions.invalidCountryCode;
                        break;
                    case 2:
                        errMsg = this.captions.phoneNumberTooShort;
                        break;
                    case 3:
                        errMsg = this.captions.phoneNumberTooLong;
                        break;
                    case 4:
                        errMsg = this.captions.validOnlyForLocal;
                        break;
                    case 5:
                        errMsg = this.captions.invalidPNLength;
                        break;
                }
                this.snackbarService.errorMessageBottom(errMsg);
            } else {
                let number = this.iti.getNumber();
                this.submitSubscription(number);
                this.data.notificationConsent = false;
                this.data.phoneSub = '';
            }
        }
    }

    submitSubscription(sub) {
        this.userApiService
            .addSubscription(sub)
            .then(() => {
                this.data.subscriptions.push(sub);
            })
            .catch((exception) => {
                this.snackbarService.errorMessageBottom(exception.error.reason);
            });
    }

    removeSubscription(sub) {
        this.userApiService.updateSubscriptions(sub).then(() => {
            let subIndex = this.data.subscriptions.indexOf(sub);
            this.data.subscriptions.splice(subIndex, 1);
        });
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
