import { Inject, Injectable } from '@angular/core';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { UserRole } from '../enums/user-role.enum';
import { UserAccess } from '../models/user-access.model';
import { Limitation } from '../models/user-security.model';

@Injectable()
export class UserAccessHandler {
    public userAccess: UserAccess;

    constructor(@Inject(BrowserStorage) private browserStorage: BrowserStorage) {
        this.browserStorage = browserStorage;
        this.userAccess = {
            webUserID: null,
            conceptID: null,
            groupItemID: null,
            tagID: null,
            storeID: null,
            role: null,
            roleItem: null,
            userPrivilege: null,
            userManagement: null,
            limitation: {
                maxConcept: null,
                maxStore: null,
            },
            storeCount: null,
        };
    }

    getUserAccess(): UserAccess {
        if (!this.userAccess.conceptID) {
            let userAccessClassTemp = this.browserStorage.getSessionstorage('userAccess', null);

            if (!userAccessClassTemp) {
                return null;
            }
            this.userAccess = userAccessClassTemp;
            return userAccessClassTemp;
        } else {
            return this.userAccess;
        }
    }

    setUserID(webUserID: number): void {
        this.userAccess.webUserID = webUserID;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setConceptID(conceptID: number): void {
        this.userAccess.conceptID = conceptID;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setGroupItemID(groupItemID: number): void {
        this.userAccess.groupItemID = groupItemID;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setTagID(tagID: number): void {
        this.userAccess.tagID = tagID;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setStoreID(storeID: number): void {
        this.userAccess.storeID = storeID;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setRole(role: UserRole): void {
        this.userAccess.role = role;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setRoleItem(roleItem: Object): void {
        this.userAccess.roleItem = roleItem;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setUserPrivilege(userPrivilege: string): void {
        this.userAccess.userPrivilege = userPrivilege;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setUserManagement(userManagement: number): void {
        this.userAccess.userManagement = userManagement;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setLimitation(limitations: Limitation): void {
        this.userAccess.limitation.maxConcept = limitations.maxConcept;
        this.userAccess.limitation.maxStore = limitations.maxStore;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    setStoreCount(storeCount: number): void {
        this.userAccess.storeCount = storeCount;
        this.browserStorage.setSessionstorage('userAccess', this.userAccess);
    }

    clearUser(): void {
        this.browserStorage.removeSessionstorageItem('userAccess');
    }
}
