import { Inject, Injectable } from '@angular/core';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

@Injectable()
export class ReportParamsHandler {

    public reportParams;
    jobParams: { parameters: any[]; };
    jobParamsTemp: any;

    constructor(@Inject(BrowserStorage) private browserStorage: BrowserStorage) {

        this.reportParams = {
            conceptID: null,
            startDate: null,
            endDate: null,
            stores: [],
            tags: [],
            groupItems: [],
            currency: null,
            localCurrency: null,
            commonCurrency: null,
            hqLinks: []
        };

        this.jobParams = {
            parameters: [],
        };

        this.jobParamsTemp = browserStorage.getSessionstorage('jobParams', null);
        this.jobParams = this.jobParamsTemp ? this.jobParamsTemp : this.jobParams;
        let reportParamsTemp = browserStorage.getSessionstorage('reportParams', null);
        this.reportParams = !reportParamsTemp ? this.reportParams : reportParamsTemp;
    }

    getJobParams() {
        let jobParamsTemp = this.browserStorage.getSessionstorage('jobParams', null);
        return jobParamsTemp ? jobParamsTemp : [];
    }

    // getStep() {
    //     let wizardParamsTemp = this.browserStorage.getSessionstorage('wizardParams', null);
    //     return wizardParamsTemp ? wizardParamsTemp.step : -1;
    // }

    // setWizardSteps(wizardSteps) {
    //     this.wizardParams.wizardSteps = wizardSteps;
    //     this.browserStorage.setSessionstorage('wizardParams', this.wizardParams);
    // }

    // setStep(step) {
    //     this.wizardParams.step = step;
    //     this.browserStorage.setSessionstorage('wizardParams', this.wizardParams);
    // }

    // clearWizardParams() {
    //     this.browserStorage.removeSessionstorageItem('wizardParams');
    // }

    setJobParams(params) {
        this.jobParams.parameters = params;
        this.browserStorage.setSessionstorage('jobParams', this.jobParams);
    }

    getReportParams() {
        let reportParamsTemp = this.browserStorage.getSessionstorage('reportParams', null);
        return !reportParamsTemp ? null : reportParamsTemp;
    }

    setConceptID(conceptID) {
        this.reportParams.conceptID = conceptID;
        this.browserStorage.setSessionstorage('reportParams', this.reportParams);
    }

    setStartDate(startDate) {
        this.reportParams.startDate = startDate;
        this.browserStorage.setSessionstorage('reportParams', this.reportParams);
    }

    setEndDate(endDate) {
        this.reportParams.endDate = endDate;
        this.browserStorage.setSessionstorage('reportParams', this.reportParams);
    }

    setTags(tags) {
        this.reportParams.tags = [];
        if (tags == null || typeof tags == 'undefined') {
            this.browserStorage.setSessionstorage('reportParams', this.reportParams);
        } else {
            for (let tag of tags) {
                this.reportParams.tags.push(tag);
                this.browserStorage.setSessionstorage('reportParams', this.reportParams);
            }
        }
    }

    setGroupItems(groupItems) {
        this.reportParams.groupItems = [];
        if (groupItems == null || typeof groupItems == 'undefined') {
            this.browserStorage.setSessionstorage('reportParams', this.reportParams);
        } else {
            for (let groupItem of groupItems) {
                this.reportParams.groupItems.push(groupItem);
                this.browserStorage.setSessionstorage('reportParams', this.reportParams);
            }
        }
    }

    setStores(stores) {
        this.reportParams.stores = [];
        if (stores == null || typeof stores == 'undefined') {
            this.browserStorage.setSessionstorage('reportParams', this.reportParams);
        } else {
            for (let store of stores) {
                this.reportParams.stores.push(store);
                this.browserStorage.setSessionstorage('reportParams', this.reportParams);
            }
        }
    }

    setCurrency(currency) {
        this.reportParams.currency = currency;
        this.browserStorage.setSessionstorage('reportParams', this.reportParams);
    }

    setCurrencyParams(localCurrency, commonCurrency) {
        this.reportParams.localCurrency = localCurrency;
        this.reportParams.commonCurrency = commonCurrency;
        this.browserStorage.setSessionstorage('reportParams', this.reportParams);
    }

    setHQLinks(hqLinks) {
        this.reportParams.hqLinks = [];
        if (hqLinks == null || typeof hqLinks == 'undefined') {
            this.browserStorage.setSessionstorage('reportParams', this.reportParams);
        } else {
            for (let hqLink of hqLinks) {
                this.reportParams.hqLinks.push(hqLink);
                this.browserStorage.setSessionstorage('reportParams', this.reportParams);
            }
        }
    }

    clearParams() {
        this.browserStorage.removeSessionstorageItem('reportParams');
        this.browserStorage.removeSessionstorageItem('wizardParams');
    }
}