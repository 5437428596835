import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRootComponent } from './app-root.component';
import { OnlyLoggedInUsersGuard } from "./auth/services/auth-guard.service";
import { RoleGuard } from './auth/services/role-guard.service';
import { ViewReportComponent } from './features/reports';
import { CaptionService } from './utils/shared-services/caption.service';
import { EoiUnsubscribeComponent } from './features/eoi/components/eoi-unsubscribe.component';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },
    {
        path: 'home',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'dummy',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./features/dummy/dummy-loading.module').then(m => m.DummyLoadingModule)
    },
    {
        path: 'concepts',
        canActivate: [OnlyLoggedInUsersGuard, RoleGuard],
        loadChildren: () => import('./features/concepts/concepts.module').then(m => m.ConceptModule),
    },
    {
        path: 'stores',
        canActivate: [OnlyLoggedInUsersGuard, RoleGuard],
        loadChildren: () => import('./features/stores/stores.module').then(m => m.StoreModule)
    },
    {
        path: 'reports',
        canActivate: [RoleGuard],
        loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule)
    },
    {
        path: 'dashboard',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'users',
        canActivate: [OnlyLoggedInUsersGuard],
        loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule)
    },
    // {
    //     path: 'recycleBin',
    //     canActivate: [OnlyLoggedInUsersGuard, RoleGuard],
    //     loadChildren: () => import('./features/recycle-bin/recycle-bin.module').then(m => m.RecycleBinModule)
    // },
    {
        path: 'inventoryCounter',
        loadChildren: () => import('./features/inventory-counter/inventory-counter.module').then(m => m.CountsheetModule)
    },
    {
        path: 'storePortal',
        loadChildren: () => import('./features/store-portal/store-portal.module').then(m => m.StorePortalModule)
    },
    {
        path: 'hoPortal',
        loadChildren: () => import('./features/head-office-portal/head-office-portal.module').then(m => m.HeadOfficePortalModule)
    },
    {
        path: 'schedule/job/:pathId/:accessToken',
        component: ViewReportComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "viewReport",
            parent: {
                path: '',
                name: ''
            }
        }
    },
    {
        path: 'unsubscribe-eoi-alert/:hash1/:hash2',
        component: EoiUnsubscribeComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "unsubscribe",
            parent: {
                path: '',
                name: ''
            }
        }
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {})
    ],
    declarations: [AppRootComponent],
    exports: [RouterModule],
    providers: []
})
export class AppRoutingModule { }