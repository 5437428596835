import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreGroupItemEdit } from '../../store-groups/models/store-group-item.model';
import { StoreGroup } from '../../store-groups/services/store-group.resource';
import { PrivilegeName, PrivilegeType } from '../../users/enums/user-privileges.enum';
import { UserRole } from '../../users/enums/user-role.enum';
import { UserAccess } from '../../users/models/user-access.model';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserConstants } from '../../users/users.constants';
import { StoreWithGroupsAndSelections } from '../models/store.model';
import { StoreApiService } from '../services/store.resource';

@Component({
    selector: 'assignStoreGroups',
    templateUrl: 'src/app/features/stores/templates/assign-store-groups.html',
})
export class StoreGroupsComponent {
    private currentUser: UserAccess;
    private storeID: number;
    private storeName: string;
    private conceptName: string;
    private conceptID: number;
    private itemList: StoreGroupItemEdit;

    public showTagSliders = true;
    public store: StoreWithGroupsAndSelections;
    public captions: Record<string, string>;
    public emptyList: boolean;
    public showGroupItemDropdown: boolean;
    public dataLoaded: boolean;

    @Input() public searchStr: string;

    constructor(
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
    ) {
        this.currentUser = this.userAccessHandler.getUserAccess();
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });

        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['pathId'];
            this.conceptName = params['pathName'];
            this.storeID = +params['storeID'];
            this.storeName = params['displayName']
        });

        this.itemList = {
            storeID: this.storeID,
            groupItemList: [],
        };

        this.manageStoreGroupPageBasedOnUserRole(this.currentUser);
        this.getGroupsByStoreId(this.storeID);
    }

    saveChanges(): void {
        for (let groupWithItem of this.store.groupWithItemsList) {
            if (groupWithItem.assignedItem && groupWithItem.assignedItemID === -1) {
                this.itemList.groupItemList.push({
                    itemID: null,
                    groupID: groupWithItem.groupID,
                });
            }
            else if (groupWithItem.assignedItemID !== -1) {
                this.itemList.groupItemList.push({
                    itemID: groupWithItem.assignedItemID,
                    groupID: groupWithItem.groupID,
                });
            }
        }

        this.storeApiService.updateGroupsForStore(this.itemList).then(() => {
            this.router.navigate(['stores/allStores', this.conceptID, this.conceptName]);
        });
    }

    cancelChanges(): void {
        let conceptID = this.conceptID;
        this.itemList = { storeID: null, groupItemList: [] };
        if (typeof conceptID === 'undefined') {
            this.router.navigate(['home']);
        }
        else {
            this.router.navigate(['stores/allStores', this.conceptID, this.conceptName]);
        }
    }

    changedSearchText(searchStr: string): void {
        this.searchStr = searchStr;
    }

    goBackToStores(): void {
        this.router.navigate(['stores/allStores', this.conceptID, this.conceptName]);
    }

    filterStoreGroup(storeGroup: StoreGroup, searchStr: string): boolean {
        return !searchStr || storeGroup.displayName.toLowerCase().includes(searchStr);
    }

    private manageStoreGroupPageBasedOnUserRole(user: UserAccess): void {
        if (
            user.role === UserRole.GroupItem || 
            user.role === UserRole.Tag || 
            user.role === UserRole.Store
        ) {
            this.showGroupItemDropdown = false;
        }
        else {
            this.showGroupItemDropdown = true;
        }

        this.manageStoreGroupPageBasedOnUserPrivilege(user);
    }

    private manageStoreGroupPageBasedOnUserPrivilege(user: UserAccess): void {
        if (user.userPrivilege !== null && user.userPrivilege !== UserConstants.USER_PRIVILEGES.ALL_PRIVILEGES) {
            let privileges = JSON.parse(user.userPrivilege) as {name: PrivilegeName, type: PrivilegeType}[];
            let assignStoreGroupsPrivilege = false;
            for (let privilege of privileges) {
                if (privilege.name === PrivilegeName.AssignStoreGroupSelections) {
                    assignStoreGroupsPrivilege = true;
                }
            }
            if (!assignStoreGroupsPrivilege) {
                this.showGroupItemDropdown = false;
            }
        }
        else if (user.userPrivilege === null) {
            this.showGroupItemDropdown = false;
        }
        else {
            this.showGroupItemDropdown = true;
        }
    }

    private getGroupsByStoreId(storeID: number): void {
        this.storeApiService.getGroupsByStoreId(storeID).then((storeGroupsWithItems: StoreWithGroupsAndSelections) => {
            let storeGroupsWithItemsData = storeGroupsWithItems;
            for (let groupWithItem of storeGroupsWithItemsData.groupWithItemsList) {
                if (groupWithItem.assignedItemID) {
                    for (let item of groupWithItem.groupItems) {
                        if (item.id === groupWithItem.assignedItemID) {
                            groupWithItem.assignedItem = item;
                        }
                    }
                }
                else {
                    groupWithItem.assignedItemID = -1;
                }
            }
            this.store = storeGroupsWithItemsData;
            this.dataLoaded = true;
            this.emptyList = this.store.groupWithItemsList.length === 0;
        });
    }
}
