import { Inject, Injectable } from '@angular/core';
import * as wijmoChart from 'wijmo/wijmo.chart';
import { CaptionService } from '../../../utils/shared-services/caption.service';

@Injectable()
export class WijmoThemeService {

    private WIJMO_THEMES_CAPTIONS: { description: string, key: string }[];
    private WIJMO_THEMES = {
        standard: wijmoChart.Palettes.standard,
        cocoa: wijmoChart.Palettes.cocoa,
        coral: wijmoChart.Palettes.coral,
        dark: wijmoChart.Palettes.dark,
        highcontrast: wijmoChart.Palettes.highcontrast,
        light: wijmoChart.Palettes.light,
        midnight: wijmoChart.Palettes.midnight,
        modern: wijmoChart.Palettes.modern,
        organic: wijmoChart.Palettes.organic,
        slate: wijmoChart.Palettes.slate,
        zen: wijmoChart.Palettes.zen,
        sh: wijmoChart.Palettes.superhero,
        flat: wijmoChart.Palettes.flatly,
        darkly: wijmoChart.Palettes.darkly,
        cerulan: wijmoChart.Palettes.cerulan,
    };

    public captions: Record<string, string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService
    ) {
        this.captions = this.captionService.captions;
        this.WIJMO_THEMES_CAPTIONS = [
            { description: this.captions.standardTheme, key: 'standard' },
            { description: this.captions.cocoaTheme, key: 'cocoa' },
            { description: this.captions.coralTheme, key: 'coral' },
            { description: this.captions.darkTheme, key: 'dark' },
            { description: this.captions.highcontrastTheme, key: 'highcontrast' },
            { description: this.captions.lightTheme, key: 'light' },
            { description: this.captions.midnightTheme, key: 'midnight' },
            { description: this.captions.modernTheme, key: 'modern' },
            { description: this.captions.organicTheme, key: 'organic' },
            { description: this.captions.slateTheme, key: 'slate' },
            { description: this.captions.zenTheme, key: 'zen' },
            { description: this.captions.shTheme, key: 'sh' },
            { description: this.captions.flatTheme, key: 'flat' },
            { description: this.captions.darklyTheme, key: 'darkly' },
            { description: this.captions.cerulanTheme, key: 'cerulan' },
        ];
    }

    getThemeObjByName(theme: string): string[] {
        if (typeof this.WIJMO_THEMES[theme] !== 'undefined') {
            return this.WIJMO_THEMES[theme];
        } 
        else {
            return this.WIJMO_THEMES.standard;
        }
    }

    getWijmoThemeCaptions() {
        return this.WIJMO_THEMES_CAPTIONS;
    }

    getWijmoThemes() {
        return this.WIJMO_THEMES;
    }
}
