import { Component, Inject, Injectable } from '@angular/core';
import { AppConstants } from '../../../../app.constants';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { UserAccessHandler } from '../../../users/services/user-access-handler.service';
import { UserInfoHandler } from '../../../users/services/user-info-handler.service';
import { UserConstants } from '../../../users/users.constants';
import { ReportDataWizard } from '../../wizard/services/report-data-wizard.service';
import { SharedWizardData } from '../../wizard/services/report-shared-wizard-data.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationService } from "../../../../utils/shared-services/validation.service";

@Component({
    selector: 'reportWizardConfirmation',
    templateUrl: 'src/app/features/reports/wizard/templates/report-wizard-confirmation.html',
})
@Injectable()
export class ReportWizardConfirmationComponent {
    private FLAT_SEVEN_DAY_LABOUR;
    private USER_ACCESS;

    public currentUser;
    public captions;
    public conceptID;
    public conceptName;

    public state;
    public showStartDate;
    public showStoreLink;
    public showSchedule;
    public dateMissing;
    public scheduleMissing;
    public storeMissing;
    public stringSchedule;
    public sendOut;
    public stores;
    public storesToDisplay: any = [];
    public storeOverflows;
    public storeHasOverflow;
    public disabledRun;
    tagOverflows: boolean;
    tags: any = [];
    dates: any = [];
    jobName: any;
    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
    reportTemplateName: any;

    constructor(
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData,
        @Inject(ReportDataWizard) private reportDataWizard: ReportDataWizard,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(Router) public router: Router,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.sharedWizardData = sharedWizardData;
        this.reportDataWizard = reportDataWizard;
        this.userInfoHandler = userInfoHandler;
        this.FLAT_SEVEN_DAY_LABOUR = AppConstants.FLAT_SEVEN_DAY_LABOUR;
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.captions = this.captionService.captions;

        this.jobName = sharedWizardData.templateName;
        this.form = this.formBuilder.group({
            displayName: ['', [Validators.required, Validators.maxLength(this.validationService.reportTemplateJobNameMaxLength)]],
        });

        this.conceptID = this.sharedWizardData.conceptID;
        this.conceptName = this.sharedWizardData.conceptName;
        this.sharedWizardData = this.sharedWizardData;
        this.reportTemplateName = this.sharedWizardData.templateName;

        this.showStoreLink = this.userAccessHandler.getUserAccess().role != this.USER_ACCESS.STORE;
        this.showSchedule = false;
    }

    ngOnInit() {
        this.form.setValue({
            displayName: this.jobName
        });
        if (this.showSchedule) {
            // Lists to use
            var days = [
                {
                    id: '1',
                    name: this.captions.monday,
                },
                {
                    id: '2',
                    name: this.captions.tuesday,
                },
                {
                    id: '3',
                    name: this.captions.wednesday,
                },
                {
                    id: '4',
                    name: this.captions.thursday,
                },
                {
                    id: '5',
                    name: this.captions.friday,
                },
                {
                    id: '6',
                    name: this.captions.saturday,
                },
                {
                    id: '7',
                    name: this.captions.sunday,
                },
            ];
            var ofEvery = [
                {
                    id: '1',
                    name: this.captions.ofEvery + ' ' + this.captions.monthFront.toLowerCase(),
                },
                {
                    id: '2',
                    name: this.captions.ofEvery + ' 2 ' + this.captions.monthsFront.toLowerCase(),
                },
                {
                    id: '3',
                    name: this.captions.ofEvery + ' 3 ' + this.captions.monthsFront.toLowerCase(),
                },
                {
                    id: '4',
                    name: this.captions.ofEvery + ' 4 ' + this.captions.monthsFront.toLowerCase(),
                },
            ];
            // Functions to call
            var checkLast = (value) =>
                value == 'last' ? this.captions.onTheLast.toLowerCase() : this.captions.onTheFirst.toLowerCase();
            var getDayName = (value) => days.find((day) => day.id == value).name;

            // Checks if schedule Params are filled
            if (this.sharedWizardData.scheduleParams.repeats != null) {
                if (this.sharedWizardData.scheduleParams.repeats == 'weekly') {
                    this.stringSchedule =
                        this.captions.recursEvery +
                        ' ' +
                        this.sharedWizardData.scheduleParams.list.map((value) => getDayName(value)).join(', ');
                } else {
                    // Checks which option the scheduleParams had selected and constructs a sentence based on it
                    this.stringSchedule =
                        this.sharedWizardData.scheduleParams.recurs == 'optionOne'
                            ? this.captions.recurs +
                            ' ' +
                            checkLast(this.sharedWizardData.scheduleParams.option.firstOne) +
                            ' ' +
                            this.captions.dayFront.toLowerCase()
                            : this.captions.recurs +
                            ' ' +
                            checkLast(this.sharedWizardData.scheduleParams.option.firstTwo) +
                            ' ' +
                            getDayName(this.sharedWizardData.scheduleParams.option.secondTwo);
                    this.stringSchedule +=
                        ' ' + ofEvery.find((x) => x.id == this.sharedWizardData.scheduleParams.option.third).name;
                }
                this.stringSchedule +=
                    ' ' +
                    this.captions.at.toLowerCase() +
                    ' ' +
                    this.sharedWizardData.scheduleParams.time +
                    (this.sharedWizardData.scheduleParams.timezone
                        ? ' (' + this.sharedWizardData.scheduleParams.timezone + ').'
                        : '.');
            } else {
                this.scheduleMissing = true;
            }
            // Sets Email, custom if not null otherwise userHander
            this.sendOut = (this.sharedWizardData.sendOut == null ? this.userInfoHandler.getUserInfo().email : this.sharedWizardData.sendOut);
            this.dateMissing = false;
            this.scheduleMissing = false;
            this.storeMissing = false;
        }
        // Sets Email, custom if not null otherwise userHander
        this.sendOut = (this.sharedWizardData.sendOut == null ? this.userInfoHandler.getUserInfo().email : this.sharedWizardData.sendOut);
        this.dateMissing = false;
        this.scheduleMissing = false;
        this.storeMissing = false;

        for (let param of this.reportDataWizard.getParameters()) {
            // If the param is dateSchedule set up the display of the DateSchedule
            if (param.parameterType == 'dateschedule') {
                var words = '';
                // sets captions based on the date schedule selected
                if (param.value != null) {
                    switch (param.value) {
                        case 'currentDay':
                            words = this.captions.currentDay;
                            break;
                        case 'previousDay':
                            words = this.captions.previousDay;
                            break;
                        case 'previousWeekStart':
                            words = this.captions.previousWeekStart;
                            break;
                        case 'previousWeekEnd':
                            words = this.captions.previousWeekEnd;
                            break;
                        case 'currentWeekStart':
                            words = this.captions.currentWeekStart;
                            break;
                        case 'previousMonthStart':
                            words = this.captions.previousMonthStart;
                            break;
                        case 'previousMonthEnd':
                            words = this.captions.previousMonthEnd;
                            break;
                        case 'currentMonthStart':
                            words = this.captions.currentMonthStart;
                            break;
                        default:
                            words = param.value;
                            break;
                    }
                }
                // if value is empty set the words value to DATE MISSING and set that an date is missing
                else {
                    words = this.captions.dateMissing;
                    this.dateMissing = true;
                }

                // Gets a reduced description, removing everything after the '('
                var indexOfN = param.description.indexOf('(');
                var reducedDescription =
                    this.captions.report +
                    ' ' +
                    param.description.substring(0, indexOfN != -1 ? indexOfN : param.description.length);

                // push the values into the date list
                this.dates.push({
                    description: reducedDescription,
                    value: words,
                });
            } else if (param.parameterType == 'datepicker') {
                // Converts the date value to a proper string format
                var newValue = new Date(param.value);

                var month = (newValue.getMonth() + 1).toString(),
                    day = newValue.getDate().toString(),
                    year = newValue.getFullYear().toString();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                // push the values into the date list
                this.dates.push({
                    description: param.description,
                    value: [year, month, day].join('-'),
                });
            }
        }

        /* 
        * Fetches StoreNames and add a max of 5 store names to the list. If there's more than 5, 
        * a message will show up displayng the number of additional stores selected
         */
        this.stores = [];
        if (this.sharedWizardData.storeList != null && this.sharedWizardData.storeList.length) {
            for (let store of this.sharedWizardData.storeList) {
                this.stores.push({
                    name: store.displayName,
                });
            }
            this.getMaxStoresToDisplay(this.stores.length);
            this.storeOverflows = this.stores.length > 5;
            this.stores.length > 5 ? (this.storeHasOverflow = true) : (this.storeHasOverflow = false);
        } else {
            this.storeMissing = true;
        }

        // Disables the run button if any of the params are considered missing
        this.disabledRun = this.storeMissing || this.dateMissing || this.scheduleMissing;

        /* TO BE ADDED
        this.groupItems = [];
        if (this.sharedWizardData.groupItemList != null && this.sharedWizardData.groupItemList.length) {
        this.sharedWizardData.groupItemList.forEach(function (groupItem) {
            this.groupItems.push({
                name: groupItem.displayName
            });
        });
        this.groupItemHasOverflow = this.groupItems.length > 3;
        this.groupItemHasOverflow = this.groupItems.length > 3;
        }
        
        this.tags = [];
        if (this.sharedWizardData.tagList != null && this.sharedWizardData.tagList.length) {
        this.sharedWizardData.tagList.forEach(function (tag) {
            this.tags.push({
                name: tag.displayName
            });
        });
        */
        this.tagOverflows = this.tags.length > 3;
        this.tagOverflows = this.tags.length > 3;
    }

    getMaxStoresToDisplay(length) {
        let counter;
        length > 5 ? counter = 5 : counter = length;
        for (let i = 0; i < counter; i++) {
            this.storesToDisplay.push(this.stores[i]);
        }
        return this.storesToDisplay;
    }

    submitJob() {
        this.sharedWizardData.templateName = this.form.value.displayName;
        this.reportDataWizard.submitJob();
    }

    moveToStores() {
        this.router.navigate(['reports/wizard/stores',
            this.conceptID, this.conceptName,
            this.reportTemplateName
        ]);
    }

    cancelClicked() {
        this.router.navigate(['reports/manageReports',
            this.sharedWizardData.conceptID,
            this.sharedWizardData.conceptName
        ]);
    }
}
