import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { SnackbarService } from '../../../../utils/shared-services/snackbar/snackbar.service';
import { DeleteJobDialog, ManageReportsData } from '../../internal';
import { ReportApiService } from '../../services/reports.resource';

@Component({
    selector: 'scheduleJobs',
    templateUrl: 'src/app/features/reports/templates/dialogs/schedule-jobs.tpl.html',
})
export class ScheduleJobsDialog {
    captions: any;
    specificJobs: any;
    conceptName: any;
    conceptID: any;
    selectedSchedule: any
    lastItemPassed: any;
    jobListEmpty: boolean = false;
    jobs: any;
    scheduleID: any;
    selectedScheduleName: any;
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ScheduleJobsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ManageReportsData,
        @Inject(Router) public router: Router,
        @Inject(SnackbarService) public snackbarService: SnackbarService,
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(MatDialog) public dialog: MatDialog,
    ) { }
    ngOnInit(): void {
        this.captions = this.data.captions;
        this.jobs = this.data.jobs;

        this.conceptName = this.data.conceptName;
        this.conceptID = this.data.conceptID
        this.selectedScheduleName = this.data.selectedSchedule.scheduleName;
        this.scheduleID = this.data.selectedSchedule.id;
        this.specificJobs = [];
        for (let i = 0; i < this.jobs.length; i++) {
            if (this.jobs[i].scheduleID == this.scheduleID) {
                // Create a brand new object and assign it the properties of the current job object
                let newJob = Object.assign({}, this.jobs[i]);
                this.specificJobs.push(newJob);
            }
        }
        if (this.specificJobs.length == 0) this.jobListEmpty = true;
    }

    filteredJobs = (job) => {
        return job.scheduleID == this.scheduleID;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }

    openReportJob(job) {
        if (job.status == 'Done') {
            this.router.navigate(['reports/viewReport', this.conceptID, this.conceptName, job.displayName, job.id], {
                queryParams: {
                    isReady: true,
                },
            });
        } else {
            this.snackbarService.errorMessageTop(this.captions.failedReportJob);
        }
        this.closeDialog();
    }

    setStoreNames(itemPassed, itemType) {
        if (itemType == 'job') {
            this.reportApiService.getStoreNamesFromJobID(this.conceptID, itemPassed.id).then((jobInfo) => {
                let jInfo = jobInfo as any;
                itemPassed.startDate = moment(jobInfo[0], 'YYYYMMDD').format('YYYY-MM-DD');
                itemPassed.endDate = moment(jobInfo[1], 'YYYYMMDD').format('YYYY-MM-DD');
                itemPassed.storeNames = jInfo.slice(2);
                itemPassed.tooltip = true;
            });
        }
    }

    tooltip(itemPassed, itemType) {
        // This is to stop the APIs in here from being called when the data
        // is already being displayed
        if (itemPassed.tooltip && itemPassed.tooltip === true) {
            itemPassed.tooltip = false;
            return;
        }
        itemPassed.tooltip = !itemPassed.tooltip;
        if (this.lastItemPassed && this.lastItemPassed != itemPassed) this.lastItemPassed.tooltip = false;
        this.lastItemPassed = itemPassed;
        if (itemType == 'job') {
            this.setStoreNames(itemPassed, 'job');
            itemPassed.tooltip = false;
        }
    }

    deleteJobDialog(job) {
        if (this.lastItemPassed) this.lastItemPassed.tooltip = false;
        if (job.scheduleName) job.displayName = job.scheduleName;
        let dialogRef = this.dialog.open(DeleteJobDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                currentJobToDelete: job,
                jobs: this.jobs,
                captions: this.captions,
                conceptID: this.conceptID
            },
        });
    }
}