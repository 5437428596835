import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageReportsData } from "../../internal";

@Component({
    selector: 'deleteAllSchedulesDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/delete-all-schedules.tpl.html',
})
export class DeleteAllSchedulesDialog {
    captions: any;
    conceptID: any;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteAllSchedulesDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ManageReportsData,
    ) {
    }

    ngOnInit(): void {
        this.captions = this.data.captions;
        this.conceptID = this.data.conceptID;
    }

    deleteAllSchedules() {
        this.dialogRef.close(true);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}