export class WidgetWizardData {
    private parameters = [];
    private widgetName;
    private PARAM_TYPE = {
        CONCEPT: 'conceptID',
        CURRENCY_FIELD: 'currencyField',
        STORE_LIST: 'storeList',
        DATE_DROPDOWN: 'dateDropdown',
        DROPDOWN: 'dropDown',
        REFRESH_INTERVAL: 'refreshInterval',
        COLOR_THEME_DROPDOWN: 'colorThemeDropdown',
        CHART_TYPE_DROPDOWN: 'chartTypeDropdown'
    };
    public data;

    constructor() {
        this.data = {
            checkStoreConceptParameterOrder: this.checkStoreConceptParameterOrder,
            getParameters: this.getParameters,
            setWidgetName: this.setWidgetName,
            getWidgetName: this.getWidgetName,
            cleanUpData: this.cleanUpData,
            PARAM_TYPE: this.PARAM_TYPE
        };

        return this.data;
    }

    $onInit() { }

    getParameters() {
        return this.parameters;
    }

    checkStoreConceptParameterOrder(parameters: { type?: string }[]): void {
        this.parameters = parameters || [];

        /*making sure store list param comes after concept picking param*/
        let conceptParam: { type?: string }
        let conceptIndex: number;

        let storelistParam: { type?: string }
        let storeIndex: number;

        for (let param of parameters) {
            if (param.type == this.PARAM_TYPE.CONCEPT) {
                conceptParam = param;
                conceptIndex = parameters.findIndex(k => k == param);
            } 
            else if (param.type == this.PARAM_TYPE.STORE_LIST) {
                storelistParam = param;
                storeIndex = parameters.findIndex(k => k == param);
            }
        }

        if (storeIndex < conceptIndex && conceptParam && storelistParam) {
            parameters[storeIndex] = conceptParam;
            parameters[conceptIndex] = storelistParam;
        }
    }

    setWidgetName(newWidgetName: string): void {
        this.widgetName = newWidgetName;
    }

    getWidgetName() {
        return this.widgetName;
    }

    cleanUpData() {
        this.parameters = [];
        this.widgetName = '';
    }
}
