import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'dummy',
    templateUrl: 'src/app/features/dummy/templates/dummy-loading-screen.html',
})
export class DummyLoadingComponent {
    public captions;

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
    ) {
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        const previousRoute = this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString();

        if (previousRoute !== '/dummy') {
            this.router.navigateByUrl('home');
        }
    }
}
