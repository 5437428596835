import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { UserInfoHandler } from '../../users/services/user-info-handler.service';

@Injectable()
export class ReportApiService {
    private BASE_URL;
    private reportTemplatesUrl: string = 'report-templates';
    private reportTemplateByIdUrl: string = 'report-templates/{templateID}';
    private reportJobsByConceptIdUrl: string = 'report/jobs';
    private reportJobByIdUrl: string = 'report/jobs/{jobID}';
    private reportJobByTokenUrl: string = 'report/jobs/schedule/{accessToken}'
    private reportJobFlatReportHTMLUrl: string = 'report/jobs/html/{jobID}';

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getAllReportTemplates() {
        let url = this.BASE_URL + this.reportTemplatesUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async checkReportDocumentationURL(reportDocURL) {
        let url = reportDocURL;
        const data$ = this.http.head(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getReportTemplateById(templateID) {
        let reportTemplateByIdUrlValid = this.reportTemplateByIdUrl.replace('{templateID}', templateID);
        let url = this.BASE_URL + reportTemplateByIdUrlValid;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getJobsByConceptId(conceptID) {
        let url = this.BASE_URL + this.reportJobsByConceptIdUrl;
        const data$ = this.http.get(url, {
            params: {
                conceptID: conceptID,
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getStoreNamesFromJobID(conceptID, reportJobID) {
        let url = this.BASE_URL + 'reportv2/job-storenames?conceptID=' + conceptID + '&reportJobID=' + reportJobID;
        const data$ = this.http.post(url, []);
        const value = await lastValueFrom(data$);
        return value;
    }

    async submitJob(job) {
        let url = this.BASE_URL + this.reportJobsByConceptIdUrl;
        const data$ = this.http.post(url, job);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateJob(jobId, job) {
        let url = this.BASE_URL + this.reportJobByIdUrl.replace('{jobID}', jobId);
        const data$ = this.http.put(url, job);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateTemplate(template) {
        let url = this.BASE_URL + this.reportTemplatesUrl;
        const data$ = this.http.post(url, template);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteJob(jobId, conceptId) {
        let url = this.BASE_URL + this.reportJobByIdUrl.replace('{jobID}', jobId);
        const data$ = this.http.delete(url, {
            params: {
                conceptID: conceptId,
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getJobDataSet(conceptId, jobId, visualizationId) {
        let url = this.BASE_URL + this.reportJobByIdUrl.replace('{jobID}', jobId);
        const data$ = this.http.get(url, {
            params: {
                conceptID: conceptId,
                visualizationID: visualizationId,
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getJobScript(conceptId, jobId, visualizationId) {
        let url = this.BASE_URL + this.reportJobByIdUrl.replace('{jobID}', jobId);
        const data$ = this.http.get(url, {
            params: {
                conceptID: conceptId,
                visualizationID: visualizationId,
                field: 'script',
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getJobInfo(conceptId, jobId) {
        let url = this.BASE_URL + this.reportJobByIdUrl.replace('{jobID}', jobId);
        const data$ = this.http.get(url, {
            params: {
                conceptID: conceptId,
                field: 'info',
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getScheduledJobInfo(accessToken, conceptID) {
        let url = this.BASE_URL + this.reportJobByTokenUrl.replace('{accessToken}', accessToken);
        const data$ = this.http.get(url, {
            params: {
                field: 'info',
                conceptID: conceptID
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getScheduledJobDataSet(conceptID, accessToken, visualizationId) {
        let url = this.BASE_URL + this.reportJobByTokenUrl.replace('{accessToken}', accessToken);
        const data$ = this.http.get(url, {
            params: {
                visualizationID: visualizationId,
                conceptID: conceptID
            }
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getJobCompletionInfo(conceptId, jobId) {
        let url = this.BASE_URL + this.reportJobByIdUrl.replace('{jobID}', jobId);
        const data$ = this.http.get(url, {
            params: {
                conceptID: conceptId,
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteCustomTemplate(templateId) {
        let url = this.BASE_URL + this.reportTemplateByIdUrl.replace('{templateID}', templateId);
        const data$ = this.http.delete(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    getLinkForFlatReportHTML(conceptId, jobId, visualizationId) {
        let userInfo = this.userInfoHandler.getUserInfo();
        let token = userInfo.token;
        let url = this.BASE_URL + this.reportJobFlatReportHTMLUrl.replace('{jobID}', jobId);
        url += '?conceptID=' + conceptId + '&visualizationID=' + visualizationId + '&accessToken=' + token;
        return url;
    }

    async deleteAllJobsByConceptID(conceptID) {
        let url = this.BASE_URL + this.reportJobsByConceptIdUrl + '?conceptID=' + conceptID;
        const data$ = this.http.delete(url);
        const value = await lastValueFrom(data$);
        return value;
    }
}
