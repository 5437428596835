import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderStringBy',
})
export class OrderStringByPipe implements PipeTransform {
    transform(array: any, field: string, reverse: boolean): any[] {
        if (reverse) {
            array.sort((a: any, b: any) => {
                if (isNaN(a[field]) && isNaN(b[field])) {
                    if (!a[field]) a[field] = '';
                    if (!b[field]) b[field] = '';
                    if (a[field] !== '' && b[field] === '') return -1;
                    else if (a[field] === '' && b[field] !== '') return 1;
                    else if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
                    else if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
                    else return 0;
                } else {
                    if (a[field] > b[field]) return -1;
                    else if (a[field] < b[field]) return 1;
                    else return 0;
                }
            });
        } else {
            array.sort((a: any, b: any) => {
                if (isNaN(a[field]) && isNaN(b[field])) {
                    if (!a[field]) a[field] = '';
                    if (!b[field]) b[field] = '';
                    if (a[field] !== '' && b[field] === '') return 1;
                    else if (a[field] === '' && b[field] !== '') return -1;
                    else if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
                    else if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
                    else return 0;
                } else {
                    if (a[field] < b[field]) return -1;
                    else if (a[field] > b[field]) return 1;
                    else return 0;
                }
            });
        }
        return array;
    }
}
