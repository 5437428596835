import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';

@Injectable()
export class InventoryCountApiService {
    private readonly getInventoryCountsheetXMLUrl: string = 'inventoryCount';
    private readonly loginInventoryCountsheetUrl: string = 'inventoryCount/login';
    private readonly submitInventoryCountsheetUrl: string = 'inventoryCount/submit';
    public captions: any = this.captionService.captions;
    private readonly BASE_URL: string;

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(DatePipe) private datepipe: DatePipe,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(CaptionService) public readonly captionService: CaptionService
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
        this.captions = this.captionService.captions;
    }

    ngOnInit() { }

    /**
     * @name loginForInventoryCount
     * @description this method authenticates for the inventory count
     * @param storeID this is a unique string identifier (referred to as store countsheet alias on frontend)
     * @param user username, which can be blank
     * @param password password for login, which is required
     * @returns
     */
    async loginForInventoryCount(storeID, userID, password) {
        let url = this.BASE_URL + this.loginInventoryCountsheetUrl;
        const data$ = this.http.get(url, {
            params: {
                empID: password,
                user: userID ? userID : '',
                storeSID: storeID,
            },
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name getInventoryCountsheetXMLByDate
     * @description this method gets the countsheet xml data by date
     * @param date the date that you want to retrieve data from
     * @returns xml data in text form
     */
    async getInventoryCountsheetXMLByDate(date: Date) {
        let savedAccessToken = this.browserStorage.getLocalstorage('inventoryCounterToken', null);
        let inventoryCountsheetXMLUrlValid = this.getInventoryCountsheetXMLUrl;
        let url = this.BASE_URL + inventoryCountsheetXMLUrlValid;
        let convertedDate: string = this.datepipe.transform(date, 'yyyy-MM-dd');

        const data$ = this.http.get(url, {
            params: {
                date: convertedDate,
                token: savedAccessToken,
            },
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name submitXMLtoPOS
     * @description submit the xml in string form to API to be submitted to POS
     * @param xmlString the string to be submitted to POS through api
     * @returns Promise<any> number is the response from the api
     */
    async submitXMLtoPOS(xmlData, date, saveOnly) {
        let savedAccessToken = this.browserStorage.getLocalstorage('inventoryCounterToken', null);
        let url = this.BASE_URL + this.submitInventoryCountsheetUrl;

        date = this.datepipe.transform(date, 'yyyy-MM-dd');
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'text/plain',
        });
        const data$ = this.http.post(url, xmlData, {
            headers: httpHeaders,
            params: {
                token: savedAccessToken,
                date: date,
                saveOnly: saveOnly
            },
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name validateAccessToken
     * @description this method validates an access token for inventory counter
     * @param token this is the access token [required]
     * @returns boolean indicating validity status
     */
    async validateAccessToken(token: string) {
        let inventoryCountsheetXMLUrlValid = this.getInventoryCountsheetXMLUrl + '/validate';
        let url = this.BASE_URL + inventoryCountsheetXMLUrlValid;
        const data$ = this.http.get(url, {
            params: {
                token: token,
            },
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }
}
