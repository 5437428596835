import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from "rxjs";
import { AppConstants } from '../../../app.constants';
import { Notification } from "../models/maintenance-data.model";

@Injectable()
export class HomeApiService {
    private notificationUrl: string = 'notification';
    private dismissNotificationsURL: string = 'notification/dismissal';
    private BASE_URL: string;

    constructor(
        @Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }
    
    async getUserMaintenanceNotifications(): Promise<Notification[]> {
        let url = this.BASE_URL + this.notificationUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$) as Notification[];
        return value;
    }

    async dismissNotifications(id: number): Promise<object> {
        let url = this.BASE_URL + this.dismissNotificationsURL;
        const data$ = this.http.post(url, id);
        const value = await lastValueFrom(data$);
        return value;
    }
}