import { Component, Inject, Optional } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { EditColumnsDialogWid013 } from './dialogs/wid-013-edit-colums-dialog.component';
import { TableDetailDialog } from '../../components/dialogs/table-detail-dialog.component';

export interface EditColumnsData {
    wid013_col1: number;
    wid013_col2: number;
    wid013_col3: number;
}

@Component({
    selector: 'wid013',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-013.html',
})
export class Wid013 {
    public captions: any;
    public isTimedOut: boolean = false;
    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    public records: any;
    public recordData = [];
    public sortedData;
    public wid013_col1 = 0;
    public wid013_col2 = 6;
    public wid013_col3 = 9;
    public currentSort: Sort = { active: '', direction: '' };

    public pageEvent: PageEvent;
    private pageIndex = 0;
    public pageSize = 18;

    private readonly WIDGET_COLUMN1_KEY: string = 'wid013_col1';
    private readonly WIDGET_COLUMN2_KEY: string = 'wid013_col2';
    private readonly WIDGET_COLUMN3_KEY: string = 'wid013_col3';
    private readonly WIDGET_CURRENT_SORT_KEY: string = 'currentSort';

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.captions = this.captionService.captions;
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    openTableDetailDialog(transactNumber: number) {
        this.dialog.open(TableDetailDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                selectedTransactNumber: transactNumber,
            },
        });
    }

    ngOnInit() {
        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }

        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }
        if (this.areDatapointsInvalid) {
            return;
        }

        this.records = this.widget.data[0].Record;
        if (!this.records) {
            this.records = [];
            return;
        }

        this.records = Array.isArray(this.records) ? this.records : [this.records];
        this.records.forEach((o) => {
            this.recordData.push(o);
        });
        this.getColumns();

        this.currentSort = JSON.parse(this.browserStorage.getSessionstorage(this.WIDGET_CURRENT_SORT_KEY, null));
        if (this.currentSort) {
            this.sortData(this.currentSort);
        } else {
            this.sortedData = this.recordData.slice();
            this.updateSortedData();
        }
    }

    getColumns() {
        let portalData = this.browserStorage.getSessionstorage('portalData', null);
        let data = JSON.parse(JSON.stringify(portalData));
        let config;
        if (data.WidgetValueConfig) {
            config = JSON.parse(data.WidgetValueConfig);
        }

        let col1 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN1_KEY, null);
        let col2 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN2_KEY, null);
        let col3 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN3_KEY, null);

        // open table (2) column index
        this.wid013_col1 = col1
            ? col1
            : config?.openTable2column1Selection
            ? parseInt(config.openTable2column1Selection)
            : 0;
        this.wid013_col2 = col2
            ? col2
            : config?.openTable2column2Selection
            ? parseInt(config.openTable2column2Selection)
            : 6;
        this.wid013_col3 = col3
            ? col3
            : config?.openTable2column3Selection
            ? parseInt(config.openTable2column3Selection)
            : 9;
    }

    saveColumns() {
        this.browserStorage.setSessionstorage(this.WIDGET_COLUMN1_KEY, this.wid013_col1);
        this.browserStorage.setSessionstorage(this.WIDGET_COLUMN2_KEY, this.wid013_col2);
        this.browserStorage.setSessionstorage(this.WIDGET_COLUMN3_KEY, this.wid013_col3);
    }

    updateSortedData(): any[] {
        const startIndex = this.pageIndex * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.sortedData = this.recordData.slice(startIndex, endIndex);

        return this.sortedData;
    }

    headerValue(headerNum) {
        switch (headerNum) {
            case 0:
                return this.captions.tableNum;
            case 1:
                return this.captions.tableLabel;
            case 2:
                return this.captions.posName;
            case 3:
                return this.captions.custNum;
            case 4:
                return this.captions.totalSum;
            case 5:
                return this.captions.duration2;
            case 6:
                return this.captions.sinceLast;
            case 7:
                return this.captions.course;
            case 8:
                return this.captions.moneyTime;
            case 9:
                return this.captions.moneyCust;
        }
    }

    dataValue(colNum, data) {
        let colSwitch;
        if (colNum == 1) {
            colSwitch = this.wid013_col1;
        } else if (colNum == 2) {
            colSwitch = this.wid013_col2;
        } else if (colNum == 3) {
            colSwitch = this.wid013_col3;
        }
        switch (colSwitch) {
            case 0:
                return data.TABLENUMBER;
            case 1:
                if (data.TABLELABEL == '') {
                    return data.TABLENUMBER;
                } else {
                    return data.TABLELABEL;
                }
            case 2:
                return data.EMPPOSNAME;
            case 3:
                return data.NUMCUST;
            case 4:
                return data.TRANSACTTOTAL;
            case 5:
                return data.STARTEDAGOMIN;
            case 6:
                return data.INTERACTAGOMIN;
            case 7:
                return data.TABLECOURSE;
            case 8:
                return this.calcMoneyOverTime(data.TRANSACTTOTAL, data.STARTEDAGOMIN);
            case 9:
                return this.calcMoneyOverCust(data.TRANSACTTOTAL, data.NUMCUST);
        }
    }

    calcMoneyOverTime(total, duration) {
        let dur = duration / 60;
        return dur == 0 ? total / 1 : Number((total / dur).toFixed(2));
    }

    calcMoneyOverCust(total, cust) {
        return cust == 0 ? total / 1 : Number((total / cust).toFixed(2));
    }

    calcHourMins(mins) {
        const days = Math.floor(mins / 1440);
        const hours = Math.floor((mins % 1440) / 60);
        const minutes = Math.floor(mins % 60);
        return days + 'd ' + hours + 'h ' + minutes + 'm';
    }

    openEditColumnsDialog() {
        const dialogRef = this.dialog.open(EditColumnsDialogWid013, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
                widgetTitle: this.widget?.title,
                wid013_col1: this.wid013_col1,
                wid013_col2: this.wid013_col2,
                wid013_col3: this.wid013_col3,
            },
        });

        dialogRef.afterClosed().subscribe((result: EditColumnsData) => {
            if (result) {
                this.wid013_col1 = Number(result.wid013_col1);
                this.wid013_col2 = Number(result.wid013_col2);
                this.wid013_col3 = Number(result.wid013_col3);
                this.saveColumns();
            }
        });
    }

    handlePageEvent(e: PageEvent) {
        this.pageEvent = e;
        this.pageIndex = e.pageIndex;

        this.currentSort = JSON.parse(this.browserStorage.getSessionstorage(this.WIDGET_CURRENT_SORT_KEY, null));
        if (this.currentSort) {
            this.sortData(this.currentSort);
        } else {
            this.updateSortedData();
        }
    }

    private tooltipValues = [
        (data: any) => data.TABLENUMBER,
        (data: any) => (data.TABLELABEL == '' ? data.TABLENUMBER + '*' : data.TABLELABEL),
        (data: any) => data.EMPPOSNAME,
        (data: any) => data.NUMCUST,
        (data: any) => data.TRANSACTTOTAL,
        (data: any) => this.calcHourMins(data.STARTEDAGOMIN),
        (data: any) => this.calcHourMins(data.INTERACTAGOMIN),
        (data: any) => data.TABLECOURSE,
        (data: any) => this.calcMoneyOverTime(data.TRANSACTTOTAL, data.STARTEDAGOMIN),
        (data: any) => this.calcMoneyOverCust(data.TRANSACTTOTAL, data.NUMCUST),
    ];

    getTooltipText(data: any, column: number): string {
        return this.tooltipValues[column](data);
    }

    sortData(sort: Sort) {
        const data = this.updateSortedData();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            this.browserStorage.removeSessionstorageItem(this.WIDGET_CURRENT_SORT_KEY);
            return;
        }

        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';

            switch (sort.active) {
                case 'wid013_col1':
                    return this.compare(this.dataValue(1, a), this.dataValue(1, b), isAsc);
                case 'wid013_col2':
                    return this.compare(this.dataValue(2, a), this.dataValue(2, b), isAsc);
                case 'wid013_col3':
                    return this.compare(this.dataValue(3, a), this.dataValue(3, b), isAsc);
                default:
                    return 0;
            }
        });

        this.browserStorage.setSessionstorage(this.WIDGET_CURRENT_SORT_KEY, JSON.stringify(sort));
    }

    compare(a: any, b: any, isAsc: boolean) {
        if ((isNaN(a) && isNaN(b)) || (!isNaN(a) && !isNaN(b))) {
            return a > b ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        } else {
            return isNaN(a) ? (isAsc ? -1 : 1) : isAsc ? 1 : -1;
        }
    }
}
