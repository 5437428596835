import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from '../../../../../utils/shared-services/caption.service';
import { EditColumnsData } from "../wid-013";
import { BrowserStorage } from '../../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'editColumnsDialogWid013',
    templateUrl: 'src/app/features/store-portal/widgets/templates/dialogs/wid-013-edit-columns-dialog.html',
})
export class EditColumnsDialogWid013 {
    public captions;
    public columnSelectForm: any;
    public wid013_col1 = 0;
    public wid013_col2 = 6;
    public wid013_col3 = 9;


    private readonly WIDGET_COLUMN1_KEY: string = 'wid013_col1';
    private readonly WIDGET_COLUMN2_KEY: string = 'wid013_col2';
    private readonly WIDGET_COLUMN3_KEY: string = 'wid013_col3';

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EditColumnsDialogWid013>,
        @Inject(CaptionService) private captionService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(UntypedFormBuilder) private formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: EditColumnsData,
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit() {
        this.wid013_col1 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN1_KEY, this.data.wid013_col1);
        this.wid013_col2 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN2_KEY, this.data.wid013_col2);
        this.wid013_col3 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN3_KEY, this.data.wid013_col3);

        this.columnSelectForm = this.formBuilder.group({
            column1Selection: (this.wid013_col1 != 0) ? [this.wid013_col1.toString(), Validators.required] : ['0', Validators.required],
            column2Selection: (this.wid013_col2 != 6) ? [this.wid013_col2.toString(), Validators.required] : ['6', Validators.required],
            column3Selection: (this.wid013_col3 != 9) ? [this.wid013_col3.toString(), Validators.required] : ['9', Validators.required],
        });
    }

    saveColumns() {
        const dialogData: EditColumnsData = {
            wid013_col1: this.columnSelectForm.value.column1Selection,
            wid013_col2: this.columnSelectForm.value.column2Selection,
            wid013_col3: this.columnSelectForm.value.column3Selection
        };

        this.dialogRef.close(dialogData);
    }
}
