import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ReportEmitterService } from '../../features/reports/services/reportEmitter.service';

export interface Item {
    displayName?: string,
    numStores?: number,
    [label: string]: string | number,
}

@Component({
    selector: 'infoList',
    templateUrl: 'src/app/utils/shared-templates/info-list.html',
})
export class InfoListComponent implements OnInit {
    public captions;
    public isLeadLabel = false;

    @Input() public leadingLabel;
    @Output() public leadingClickEvent = new EventEmitter();
    @Input() public leadingHide;
    @Input() public items;
    @Output() public itemClickEvent = new EventEmitter();
    @Input() public filter;
    @Input() public label;
    @Input() public xlabels;
    @Input() public bgColor;
    @Input() public showMenu;
    @Input() public showCheckbox;
    @Output() public optionClickEvent = new EventEmitter();
    @Input() public optionIcon;
    @Input() public showInfoIcon;
    @Input() public storesForChosenItems;
    @Input() public storesForChosenTags;
    @Input() public listType: string;
    @Input() public searchStr;
    @Input() public noMargins;

    constructor(
        @Inject(ReportEmitterService) private reportEmitterService: ReportEmitterService,
    ) { }

    ngOnInit() {
        //for tags and group items
        for (let item of this.items) {
            if (typeof item.webBgColor == 'undefined') {
                item.webBgColor = '#f4c20d';
            }
        }
        this.updateLeadingLabel();

        // when lead label changes, update it
        this.reportEmitterService.leadLabelChangedCtrlItem$.subscribe((value) => {
            this.leadingHide = value;
            this.updateLeadingLabel();
        });
    }

    updateLeadingLabel() {
        if (typeof this.leadingLabel != 'undefined' && !this.leadingHide) {
            this.isLeadLabel = true;
        }
        else {
            this.isLeadLabel = false;
        }
    }

    itemClick(clickedItem) {
        this.itemClickEvent.emit(clickedItem);
    }

    optionClick(clickedConcept) {
        this.optionClickEvent.emit(clickedConcept);
    }

    leadingClick(clickedBox) {
        this.leadingClickEvent.emit(clickedBox);
    }

    filterItem(item: Item, searchStr: string) {
        return !searchStr || item.displayName.toLowerCase().includes(searchStr);
    }
}
