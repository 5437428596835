import { Component, Input } from "@angular/core";

@Component({
    selector: 'flat-widget',
    templateUrl: 'src/app/features/dashboard/widget/templates/flat-widget.tpl.html',
})
export class FlatWidgetComponent {
    @Input() public widgetData;

    ngOnInit(){
    }
}