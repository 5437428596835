import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";

@Component({
    selector: "increase-price-by-dialog",
    templateUrl: "./src/app/features/price-change/templates/dialogs/set-price.component.html"
})
export class SetPriceDialog implements OnInit {
    private fb: FormBuilder = new FormBuilder();
    myForm: FormGroup;
    public captions: Record<string, string>;
    public inputLabel: string = 'Amount';
    public options;
    isRow: boolean;

    constructor(@Inject(MatDialogRef) public dialogRef: MatDialogRef<SetPriceDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this.captions = this.captionService.captions;

        this.options = [ {label: this.captions.amount, value: 'Amount'},
        {label: this.captions.percentageFront, value: 'Percentage'}];

    }

    ngOnInit(): void {
        this.isRow = this.data.isRow;
        this.myForm = this.fb.group({
            actionType: ['increase', Validators.required],
            dropdown: ['Amount', Validators.required],
            inputBox: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/), Validators.min(0.01)]]
        });

        this.myForm.get('dropdown').valueChanges.subscribe(value => {
            this.updateInputLabel(value);
          });
    }

    updateInputLabel(selectedOption: string) {
        switch (selectedOption) {
          case 'Amount':
            this.inputLabel = 'Amount';
            break;
          case 'Percentage':
            this.inputLabel = 'Percentage';
            break;
        }
      }

    formatCurrency(event: any) {
        const input = event.target.value;
        let formattedInput = input.replace(/[^0-9.]/g, '');
        const [whole, decimal] = formattedInput.split('.');
        if (decimal !== undefined) {
            formattedInput = `${whole}.${decimal.slice(0, 2)}`;
        }
        this.myForm.controls['inputBox'].setValue(formattedInput, { emitEvent: false });
    }

    onSubmit() {
        if (this.myForm.valid) {
            this.dialogRef.close({
                change: this.myForm.value
            });
        }
    }

    onClose() {
        this.dialogRef.close();
    }

}