import { Inject, Injectable } from '@angular/core';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { Router } from '@angular/router';
import { HeadOfficePortalData } from '../models/head-office-portal.model';

@Injectable()
export class HOPortalService {
    public captions: any = {};
    private readonly ACCESS_TOKEN_KEY: string = 'accessToken';
    private readonly PORTAL_DATA_KEY: string = 'portalData';

    constructor(
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(Router) private router: Router
    ) {}

    /**
     * @name clearAccessToken
     * @description clears the session storage for the access token
     */
    clearAccessToken() {
        this.browserStorage.removeSessionstorageItem(this.ACCESS_TOKEN_KEY);
    }

    /**
     * @name getHoPortalData
     * @description gets the store portal data from session storage
     * @return saved store portal data
     */
    getHoPortalData() {
        return this.browserStorage.getSessionstorage(this.PORTAL_DATA_KEY, null);
    }

    /**
     * @name setStorePortalData
     * @description sets the store portal data into session
     * @param data this is the store portal data to be saved for the session
     */
    setHoPortalData(data: HeadOfficePortalData): void {
        this.browserStorage.setSessionstorage(this.PORTAL_DATA_KEY, data);
    }

    /**
     * @name clearStorePortalData
     * @description clears the session storage for the store portal data
     */
    clearStorePortalData() {
        this.browserStorage.removeSessionstorageItem(this.PORTAL_DATA_KEY);
    }

    /**
     * @name logout
     * @description this method logs the user out and navigates them to the login page of the
     * inventory counter, and clears all saved data.
     */
    logout() {
        let alias = this.getHoPortalData().Alias;
        this.router.navigate(['/hoPortal/login'], {
            queryParams: {
                ...(alias ? { PortalID: alias } : {}),
            },
        });
        this.clearAccessToken();
        this.clearStorePortalData();
    }
}
