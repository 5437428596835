import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Inject, Injectable, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../app.constants';
import { CaptionService } from '../shared-services/caption.service';

@Component({
    selector: 'colorPicker',
    templateUrl: 'src/app/utils/shared-templates/colorpicker.html',
})
@Injectable()
export class ColorPickerComponent implements OnInit {
    @Input() public color;

    @Output() public colorSelected = new EventEmitter();

    private COLOR_PICKER;

    public captions;
    public colorPickerModel;
    public colors;
    public showColors: boolean = false;
    public breakpoint: number;
    public rowHeight: string;
    public gutterSize: string;

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(CaptionService) private captionService: CaptionService
    ) {
        this.COLOR_PICKER = AppConstants.COLOR_PICKER;
        this.captions = this.captionService.captions;
    }

    async ngOnInit() {
        let url = this.COLOR_PICKER.url;
        const data$ = this.http.get(url);
        this.colors = await lastValueFrom(data$);
        this.adjustGrid();
    }
    colorPickerDialog() {
        this.showColors = !this.showColors;
    }
    closeDialog() {
        this.showColors = false;
    }
    selectedColor(color) {
        document.getElementById('colorBox')!.style.background = color;
        this.colorSelected.emit(color);
    }

    onResize() {
        this.adjustGrid();
    }

    adjustGrid() {
        if (window.innerWidth <= 320) {
            this.breakpoint = 2;
            this.rowHeight = '3rem';
            this.gutterSize = '1rem';
        } else if (window.innerWidth <= 400) {
            this.breakpoint = 2;
            this.rowHeight = '4rem';
            this.gutterSize = '1px';
        } else {
            this.breakpoint = 3;
            this.rowHeight = '6rem';
            this.gutterSize = '1px';
        }
    }
}