import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { UserAccess } from '../../users/models/user-access.model';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { PrivilegeName, PrivilegeType, UserPrivileges } from '../../users/enums/user-privileges.enum';

@Component({
    selector: 'conceptOptions',
    templateUrl: 'src/app/features/concepts/templates/concept-options.tpl.html',
})
export class ConceptOptionsComponent {
    private currentUser: UserAccess;

    public captions: Record<string, string>;
    public conceptName: string;
    public manageConceptsPrivilege: boolean = false;
    public manageStoresPrivilege: boolean = false;
    public manageStoreTagsPrivilege: boolean = false;
    public manageGroupItemsPrivilege: boolean = false;
    // public manageCouponsPrivilege: boolean = false;
    public hideProd: boolean = false;
    public isExpanded: boolean = false;
    @ViewChild('priceContainer') priceContainer: ElementRef | undefined;
    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(MatBottomSheetRef) private _bottomSheetRef: MatBottomSheetRef<ConceptOptionsComponent>,
        @Inject(Router) private router: Router
    ) {
        this.currentUser = this.userAccessHandler.getUserAccess();
        this.captions = this.captionService.captions;
    }

    ngOnInit(): void {

        if (
            this.currentUser.userPrivilege !== UserPrivileges.AllPrivileges &&
            this.currentUser.userPrivilege !== null
        ) {
            const privileges = JSON.parse(this.currentUser.userPrivilege) as { name: PrivilegeName, type: PrivilegeType }[];
            for (let privilege of privileges) {
                if (privilege.name === PrivilegeName.ManageOrganizations) {
                    this.manageConceptsPrivilege = true;
                }
                else if (privilege.name === PrivilegeName.ManageStores) {
                    this.manageStoresPrivilege = true;
                }
                else if (privilege.name === PrivilegeName.ManageStoreTags) {
                    this.manageStoreTagsPrivilege = true;
                } 
                else if (privilege.name === PrivilegeName.ManageStoreGroupsAndSelections) {
                    this.manageGroupItemsPrivilege = true;
                }
                // else if (privilege.name === this.USER_PRIVILEGES.MANAGE_COUPONS) {
                //     this.manageCouponsPrivilege = true;
                // }
            }
        }
        else if (this.currentUser.userPrivilege !== null) {
            this.manageConceptsPrivilege = true;
            this.manageStoresPrivilege = true;
            this.manageStoreTagsPrivilege = true;
            this.manageGroupItemsPrivilege = true;
            // this.manageCouponsPrivilege = true;
        }

        this.conceptName = this.itemStorageService.selectedConcept.displayName;

         // hide price change from PROD only
         if (
            window.location.origin.indexOf('localhost') > -1 ||
            window.location.origin.indexOf('ci') > -1 ||
            window.location.origin.indexOf('qa') > -1
        ) {
            this.hideProd = true;
        }
    }

    ngAfterViewChecked() {
        if (this.isExpanded && this.priceContainer) {
          this.priceContainer.nativeElement.scrollTop = this.priceContainer.nativeElement.scrollHeight;
        }
    }

    togglePriceMenu() {
        this.isExpanded = !this.isExpanded;      }

    closePopup(): void {
        this._bottomSheetRef.dismiss();
    }

    listItemClick(item: string): void {
        let selectedConceptId = this.itemStorageService.selectedConcept.id;
        if (item === 'manageConcept') {
            this.router.navigate(['concepts/editConcept', selectedConceptId]);
            this._bottomSheetRef.dismiss();
        } 
        else if (item === 'manageStoreGroups') {
            this.router.navigate(['concepts/storeGroups', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        } 
        else if (item === 'manageTags') {
            this.router.navigate(['concepts/manageTags', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        } 
        else if (item === 'manageApis') {
            this.router.navigate(['concepts/manageConceptApis', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        } 
        // else if (item === 'manageCoupons') {
        //     this.router.navigate(['manageCoupons', selectedConceptId, this.conceptName]);
        //     this._bottomSheetRef.dismiss();
        // } 
        else if (item === 'manageReports') {
            this.router.navigate(['reports/manageReports', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        } 
        else if (item === 'EOIStatus') {
            this.router.navigate(['concepts/eoiStatus', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        } 
        else if (item === 'BackupStatus') {
            this.router.navigate(['concepts/backupStatus', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        } 
        else if (item === 'headOffice') {
            this.router.navigate(['concepts/enableHOPortal', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        } 
        else if (item === 'PriceChange') {
            this.router.navigate(['concepts/priceChange', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        }
        else if (item === 'PriceMatch') {
            this.router.navigate(['concepts/priceMatch', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        }
        else if (item === 'PriceExplorer') {
            this.router.navigate(['concepts/priceExplorer', selectedConceptId, this.conceptName]);
            this._bottomSheetRef.dismiss();
        }
    }
}
