import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "storeGroupsFilter",
    pure: false
})
export class StoreGroupsFilterPipe implements PipeTransform {
    transform(stores: any[], groups: any): any[] {
		let storesForChosenStoreGroups = groups;
        if (typeof stores === 'undefined' || stores.length <= 0) return [];
        /* we cannot check storesForChosenTags.length attribute. Thats why we check numberOfGroups around line 77 */
        if (typeof storesForChosenStoreGroups === 'undefined') return stores;

        let allStoreIds = [];
        let numberOfGroups = 0;
        let groupIds = [];
        for (let itemGroupKey in storesForChosenStoreGroups) {
            if (storesForChosenStoreGroups.hasOwnProperty(itemGroupKey)) {
                allStoreIds = allStoreIds.concat(storesForChosenStoreGroups[itemGroupKey]);

                let groupId = parseInt(itemGroupKey.substring(itemGroupKey.indexOf('.') + 1, itemGroupKey.length));
                if (isNaN(groupId)) {
                    return stores;
                }
                if (!groupIds.includes(groupId)) {
                    groupIds.push(groupId);
                    numberOfGroups++;
                }
            }
        };
        if (numberOfGroups === 0) return stores;
        let storeNumberRepeatedHash = [];

        for (let i = 0; i < allStoreIds.length; i++) {
            if (typeof storeNumberRepeatedHash[allStoreIds[i].toString() + '.key'] === 'undefined')
                storeNumberRepeatedHash[allStoreIds[i].toString() + '.key'] = 0;

            storeNumberRepeatedHash[allStoreIds[i].toString() + '.key']++;
        }

        let filteredStores = [];
        for (let store of stores) {
            if (storeNumberRepeatedHash[store.id.toString() + '.key'] == numberOfGroups) filteredStores.push(store);
        }

        return filteredStores;
    }
}