import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../app.constants';

@Injectable()
export class AuthService {
    private changePasswordUrl: string = 'currentuser/password';
    private loginUrl: string = 'authentication/login';
    private shareLoginUrl: string = 'authentication/schedule_login?jobToken={jobToken}&conceptID={conceptID}';
    private requestUrl: string = 'authentication/request_token';
    private getTemplatesHashsUrl: string = 'templates-hash';
    private recoveryUrl: string = '/recovery';
    private getPingIpUrl: string = 'ping/info';
    private aboutUsUrl: string = '../../data/aboutus.json';
    private maintenanceUrl: string = 'notification/maintenance';
    private eoiVendorRequestUrl: string = "eoi-vendor-request/submit";

    private BASE_URL;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }
    async getPing() {
        let url = this.BASE_URL + this.getPingIpUrl;
        const data$ = this.http.get(url, {responseType: 'text'});
        const value = await lastValueFrom(data$);
        return value;
    }

    async getTemplatesHashs() {
        let url = this.BASE_URL + this.getTemplatesHashsUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async sendRecoveryCode(email) {
        let url = this.BASE_URL + this.requestUrl;
        const data$ = this.http.post(url, { email });
        const value = await lastValueFrom(data$);
        return value;
    }

    async changePasswordFromRecovery(newPassword) {
        let url = this.BASE_URL + this.changePasswordUrl + this.recoveryUrl;
        const data$ = this.http.post(url, { newPassword });
        const value = await lastValueFrom(data$);
        return value;
    }

    async shareLogin(credentials) {
        let shareLoginUrlValid = this.shareLoginUrl
            .replace('{jobToken}', credentials.jobToken)
            .replace('{conceptID}', credentials.conceptID);
        let url = this.BASE_URL + shareLoginUrlValid;
        const data$ = this.http.post(url, { credentials });
        const value = await lastValueFrom(data$);
        return value;
    }

    async login(credentials) {
        let url = this.BASE_URL + this.loginUrl;
        const data$ = this.http.post(url, credentials);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getRecaptchaInfo() {
        let url = this.BASE_URL + 'recaptcha';
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    logout() {
        sessionStorage.clear();
    }

    async changePassword(oldPassword, newPassword) {
        const updates = { oldPassword, newPassword };
        const url = this.BASE_URL + this.changePasswordUrl;
        const data$ = this.http.post(url, updates);
        const value = await lastValueFrom(data$);
        return value;
    }

    async resetPassword(userId, newPassword) {
        let url = this.BASE_URL + this.changePasswordUrl + '/' + userId;
        const data$ = this.http.post(url, { newPassword });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getAboutUsInfo() {
        let url = this.aboutUsUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getGeneralMaintenanceNotifications() {
        let url = this.BASE_URL + this.maintenanceUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }
    async submitEoiVendorRequestForm(form: string){
        let url = this.BASE_URL + this.eoiVendorRequestUrl;
        const data$ = this.http.post(url,form, {responseType: 'text'})
        const value = await lastValueFrom(data$);
        return value;
    }
}
