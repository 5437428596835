import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { UserApiService } from '../services/users.resource';

interface Parameters {
    users: any;
    USER_PRIVILEGES: any;
    USER_ACCESS: any;
    privilegeCategories: any;
    selectAllPrivileges: any;
}

@Component({
    selector: 'inherit-user-privilege-dialog',
    templateUrl: 'src/app/features/users/templates/inherit-user-privilege-dialog.html',
})
export class InheritUserPrivilegeDialog {
    @Output() inheritUserPrivilege = new EventEmitter<any>();
    public captions: any;

    constructor(
        @Inject(CaptionService) private captionService,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<InheritUserPrivilegeDialog>,
        @Inject(MAT_DIALOG_DATA) public data: Parameters
    ) {
        this.captions = this.captionService.captions;
        this.inheritUserPrivilege.subscribe((user: any) => {
            if (
                typeof user.userPrivilege !== 'undefined' &&
                user.userPrivilege !== this.data.USER_PRIVILEGES.ALL_PRIVILEGES
            ) {
                let checkedPrivileges = [],
                    privileges = JSON.parse(user.userPrivilege),
                    totalPrivilegesLength = 0;
                for (let privilegeCategory of this.data.privilegeCategories) {
                    totalPrivilegesLength += privilegeCategory.userPrivilege.length;
                    for (let i = 0; i < privilegeCategory.userPrivilege.length; i++) {
                        privilegeCategory.userPrivilege[i].isChecked = false;
                        for (let j = 0; j < privileges.length; j++) {
                            if (privilegeCategory.userPrivilege[i].name === privileges[j].name) {
                                privilegeCategory.userPrivilege[i].isChecked = true;
                                checkedPrivileges = checkedPrivileges.concat(privilegeCategory.userPrivilege[i]);
                            }
                        }
                    }
                }
                if (checkedPrivileges.length === totalPrivilegesLength) {
                    for (let privilegeCategory of this.data.privilegeCategories) {
                        privilegeCategory.allChecked = true;
                    }
                } else {
                    for (let privilegeCategory of this.data.privilegeCategories) {
                        privilegeCategory.allChecked = false;
                    }
                }
            } else if (
                user.userPrivilege === this.data.USER_PRIVILEGES.ALL_PRIVILEGES &&
                user.role === this.data.USER_ACCESS.GLOBAL
            ) {
                this.data.selectAllPrivileges();
            } else if (
                user.userPrivilege === this.data.USER_PRIVILEGES.ALL_PRIVILEGES &&
                user.role !== this.data.USER_ACCESS.GLOBAL
            ) {
                this.selectAllPrivilegesForNonGlobalUser(user);
            } else {
                for (let privilegeCategory of this.data.privilegeCategories) {
                    privilegeCategory.allChecked = false;
                    for (let privilege of privilegeCategory.userPrivilege) {
                        privilege.isChecked = false;
                    }
                }
            }
        });
    }

    selectAllPrivilegesForNonGlobalUser(user) {
        let userPrivileges = [],
            checkedPrivileges = [],
            totalPrivilegesLength = 0;
        this.userApiService.getUserPrivileges(user).then((privileges: any) => {
            for (let privilegeCategory of privileges.privilegeCategories) {
                for (let privilege of privilegeCategory.userPrivilege) {
                    userPrivileges = userPrivileges.concat(privilege);
                }
            }

            for (let privilegeCategory of this.data.privilegeCategories) {
                totalPrivilegesLength += privilegeCategory.userPrivilege.length;
                for (let privilege of privilegeCategory.userPrivilege) {
                    privilege.isChecked = false;
                    for (let i = 0; i < userPrivileges.length; i++) {
                        if (privilege.name === userPrivileges[i].name) {
                            privilege.isChecked = true;
                            checkedPrivileges = checkedPrivileges.concat(privilege);
                        }
                    }
                }
            }

            if (checkedPrivileges.length === totalPrivilegesLength) {
                for (let privilegeCategory of this.data.privilegeCategories) {
                    privilegeCategory.allChecked = true;
                }
            } else {
                for (let privilegeCategory of this.data.privilegeCategories) {
                    privilegeCategory.allChecked = false;
                }
            }
        });
    }

    onInheritUserPrivilege(user) {
        this.inheritUserPrivilege.emit(user);
        this.dialogRef.close();
    }
}
