import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { AppSettings } from '../../app-settings.service';
import { AppConstants } from '../../app.constants';
import { UtilsApiService } from './utils.resource';
@Injectable({
    providedIn: 'root'
})

export class CaptionService implements Resolve<any> {
    private LANGUAGES;
    public languages;

    public captions: Record<string, string>;

    constructor(@Inject(AppSettings) private appSettings: AppSettings,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService) {
        this.LANGUAGES = AppConstants.LANGUAGES;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.assignCaptions('', 'FRONT');
    }

    assignCaptions(language: string, level: string): Promise<Record<string, string>> {
        let isChangeLanguage = typeof language === 'string' && language !== '';
        let appLanguage;
        if (language == '') appLanguage = this.appSettings.getLanguage();
        else {
            appLanguage = isChangeLanguage ? language : this.appSettings.getLanguage();
        }
        delete this.captions;
        if (typeof level == 'undefined' || level == null) {
            level = 'FRONT';
        }
        let captions = this.utilsApiService.getCaptions(appLanguage, level);
        captions.then(captions => {
            this.captions = captions;
            this.assignLanguages(captions);
        });
        return captions;
    }

    assignLanguages(captions: Record<string, string>): void {
        this.languages = [
            {
                caption: captions.chinese,
                language: this.LANGUAGES.chinese
            },
            {
                caption: captions.english,
                language: this.LANGUAGES.english
            },
            {
                caption: captions.arabic,
                language: this.LANGUAGES.arabic
            },
            {
                caption: captions.spanish,
                language: this.LANGUAGES.spanish
            },
            {
                caption: captions.french,
                language: this.LANGUAGES.french
            }
        ];
    }
}