import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";
import { StoreBaseWithAddress } from "src/app/features/stores/models/store.model";
import { StoreApiService } from "../../../../features/stores/services/store.resource";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { WidgetOpenDate } from "../../models/widget.model";
import type { DashboardDialogData } from "../../models/dialog.model";

@Component({
    selector: 'widgetOpenDatesDialog',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/widget-opendate.tpl.html',
})
export class WidgetOpenDatesDialog {
    private openDates: { [storeID: number]: WidgetOpenDate };
    private sortType: string;

    public captions: Record<string, string>;
    public startDate: moment.Moment;
    public endDate: moment.Moment;
    public period: string;
    public openDatesTableData: WidgetOpenDate[];
    public displayNameAsc: boolean = false;
    public openDateAsc: boolean = false;
    public diffAsc: boolean = false;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<WidgetOpenDatesDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public passedData: DashboardDialogData,
        @Inject(StoreApiService) private storeApiService: StoreApiService
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit(): void {
        let openDateInfo = this.passedData.openDates;
        let storeIds = openDateInfo.storeIds;

        this.openDates = openDateInfo.openDates;
        this.startDate = moment(openDateInfo.startDate, 'YYYYMMDD');
        this.endDate = moment(openDateInfo.endDate, 'YYYYMMDD');
        let nowToEnd = moment().diff(this.endDate);
        if (nowToEnd < 0) {
            this.endDate = moment();
        }
        this.startDate = moment(this.startDate);
        this.endDate = moment(this.endDate);

        // change period to front end caption
        let periodArray = openDateInfo.period.split(/(?=[A-Z])/);
        periodArray.push('front');
        let periodCaption = periodArray.join('_').toLowerCase();
        this.period = this.captions[periodCaption];

        // promise function to get store info based on store id
        let getStoreInfo = (storeid: number): Promise<StoreBaseWithAddress> => {
            return new Promise<StoreBaseWithAddress>((resolve) => {
                this.storeApiService.getStoreById(storeid).then((store) => {
                    resolve(store);
                });
            });
        }

        // push getStoreInfo promises into array
        let storesToGet: Promise<StoreBaseWithAddress>[] = [];
        for (let id of storeIds) {
            storesToGet.push(getStoreInfo(id));
        }

        this.openDatesTableData = [];
        /* NOTE: this wijmoData isn't really doing anything. Widget is being passed in the dialog Data, test later to tie it to that maybe. */
        let wijmoData = [];

        // gets all store infos based on storesToGet promise array
        Promise.all(storesToGet).then((stores) => {
            // assign store open date info with store display name
            for (let store of stores) {
                if (!this.openDates[store.id]) {
                    this.openDates[store.id] = {};
                }
                this.openDates[store.id].name = store.displayName;
            }
            // for each store id, get difference of days between end date
            // and push data to wijmo
            for (let storeId of storeIds) {
                this.openDatesTableData.push(this.openDates[storeId]);
                if (this.openDates[storeId].openDate) {
                    let openDate = moment(this.openDates[storeId].openDate, 'YYYYMMDD');
                    let diff = this.endDate.diff(openDate, 'days');
                    this.openDates[storeId].diff = diff < 0 ? 0 : diff;
                    this.openDates[storeId].displayOpenDate = moment(openDate).format('LL');
                    wijmoData.push({
                        name: this.openDates[storeId].name + ' (' + storeId + ')',
                        diff: diff
                    });
                }
            }
        });
    }

    getFormattedMomentDate(date: moment.Moment, type='LL'): string {
        return date.format(type);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    sortData(predicate: string): void {
        this.sortType = predicate;
        if (predicate === 'name') {
            this.displayNameAsc = !this.displayNameAsc;
        } 
        else if (predicate === 'openDate') {
            this.openDateAsc = !this.openDateAsc;
        } 
        else if (predicate === 'diff') {
            this.diffAsc = !this.diffAsc;
        }
    }
}
