import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../app.constants';
import { ExtractionTables, ExtractionData } from '../shared-models/extraction.model';

@Injectable()
export class ExtractionAPIService {
    private BASE_URL: string = '';
    private infoExtractionURL: string = 'extraction/generic/description';
    private dataExtractionURL: string = 'extraction/data';
    constructor(@Inject(HttpClient) private http: HttpClient, @Inject(DomSanitizer) private sanitizer: DomSanitizer) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    /**
     * @name getExtractionTables
     * @description makes api call to get the possible extraction options
     * @returns object (two arrays)
     */
    async getExtractionTables(): Promise<ExtractionTables> {
        let url = this.BASE_URL.replace('/web', '') + this.infoExtractionURL;
        const data$ = this.http.get<ExtractionTables>(url, {
            params: {},
        });
        const value = await lastValueFrom(data$);
        return value;
    }
    /**
     * @name extractTable
     * @description makes api call to the to get table data
     * @returns json response
     */
    async extractTable(_tableName: string, _storeID: string, _openDate: string = null): Promise<ExtractionData> {
        let url = this.BASE_URL + this.dataExtractionURL;
        let params: {} = {};
        if (_openDate) {
            params = { tableName: _tableName, storeID: _storeID, openDate: _openDate };
        } else {
            params = { tableName: _tableName, storeID: _storeID };
        }
        let data$ = this.http.post<ExtractionData>(
            url,
            { header: { 'Content-Type': 'application/json' } },
            { params: params, }
        );

        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name generateURL
     * @description generates the url for the blob data so that we can download the data as a file
     * @param data the data that will be in the text file
     * @returns the url to the blob data
     */
    generateURL(data: any) {
        const blob = new Blob([data], { type: 'text' });
        const url = window.URL.createObjectURL(blob);
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
