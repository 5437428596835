import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StoreBase } from "../../../stores/models/store.model";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { StoreSelectionDialogData } from "../../models/dialog.model";
import { Tag } from "../../../tags/models/tag.model";
import { GroupItem, GroupWithItems } from "../../../store-groups/models/group-item.model";
import { StoreGroupMembersApiService } from "../../../store-groups/services/store-group-member.resource";
import { TagApiService } from "../../../tags/services/tag.resource";

@Component({
    selector: "report-category-selection",
    templateUrl: "./src/app/features/price-change/templates/dialogs/store-selection.component.html"
})
export class PriceChangeStoreSelectionDialog implements OnInit {

    public captions: Record<string, string>
    public stores: StoreBase[];
    public tags: Tag[];
    public storeGroups: GroupWithItems[];
    public selectedStores: StoreBase[];
    public firstTimeGroupSelection: Boolean = true;
    public firstTimeTagSelection: Boolean = true;
    public isPriceMatch: Boolean = false;
    public storesFrom: StoreBase[];
    public storeSelectedToReverse: StoreBase;


    constructor(
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(TagApiService) private tageApiService: TagApiService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<PriceChangeStoreSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: StoreSelectionDialogData,
    ) {
        this.captions = this.captionService.captions;
        this.stores = [];
        this.storeGroups = [];
        this.tags = [];
        this.selectedStores = [];
        this.storesFrom = [];
    }

    ngOnInit(): void {
        this.stores = structuredClone(this.data.stores);
        this.storesFrom = structuredClone(this.data.stores);
        this.isPriceMatch = this.data.isPriceMatch;
        this.storeGroups = structuredClone(this.data.storeGroups);
        this.tags = structuredClone(this.data.tags);
    }

    selectStore(store: StoreBase, reset: Boolean): void {
        if (reset) {
            this.resetGroupItemSelection();
            this.resetTagSelection();
            store.isChecked = !store.isChecked;
        } else {
            this.stores.forEach(s => {
                if (s.id == store.id) s.isChecked = true;
            });
        }
    }

    selectGroupItem(selectedItem: GroupItem): void {
        this.resetStoreSelection();
        this.resetTagSelection();
        selectedItem.isChecked = !selectedItem.isChecked;

        this.storeGroups.forEach(group => {
            group.groupItems.forEach(item => {
                if (item.isChecked) {
                    this.storeGroupMembersApiService.getGroupItemInfoById(item.id).then(stores => {
                        for (let store of stores) {
                            this.selectStore(store, false);
                        }
                    });
                }
            });
        });
    }

    addObject(array: string | any[], id: any) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return false;
            }
        }
        return true;
    }

    selectTag(selectedTag: Tag): void {
        this.resetStoreSelection();
        this.resetGroupItemSelection();
        selectedTag.isChecked = !selectedTag.isChecked;
        this.tags.forEach(tag => {
            if (tag.isChecked) {
                this.tageApiService.getStoresByTagID(tag.id).then(stores => {
                    for (let store of stores) {
                        this.selectStore(store, false);
                    }
                });
            }
        });
    }

    selectAllStores(): void {
        this.resetAllSelection();
        this.stores.forEach(store => store.isChecked = true);
    }

    selectAllGroupItems(): void {
        this.resetAllSelection();
        this.storeGroups.forEach(group => {
            group.groupItems.forEach(item => {
                item.isChecked = true;
                this.storeGroupMembersApiService.getGroupItemInfoById(item.id).then(stores => {
                    for (let store of stores) {
                        this.selectStore(store, false);
                    }
                });
            });
        });
    }
    selectAllTags(): void {
        this.resetAllSelection();
        this.tags.forEach(tag => {
            tag.isChecked = true;
            this.tageApiService.getStoresByTagID(tag.id).then(stores => {
                for (let store of stores) {
                    this.selectStore(store, false);
                }
            });
        });
    }

    resetStoreSelection(): void {
        this.stores.forEach(store => store.isChecked = false);
    }
    resetGroupItemSelection(): void {
        this.firstTimeGroupSelection = true;
        this.storeGroups.forEach(group => {
            group.groupItems.forEach(item => {
                item.isChecked = false;
            });
        });
    }
    resetTagSelection(): void {
        this.firstTimeTagSelection = true;
        this.tags.forEach(tag => tag.isChecked = false);
    }

    resetAllSelection(): void {
        this.resetStoreSelection();
        this.resetGroupItemSelection();
        this.resetTagSelection();
    }

    confirmSelection(): void {
        this.dialogRef.close({
            stores: this.stores,
            storeGroups: this.storeGroups,
            tags: this.tags,
            selectedStores: this.stores.filter(store => store.isChecked),
            selectedStoreFrom: this.storesFrom.filter(store => store.isChecked)
        })
    }

    filterStore(store: StoreBase, searchStr: string) {
        return !searchStr || store.displayName.toLowerCase().includes(searchStr);
    }



    onCheckboxChange(selectedStore: any): void {
        // Uncheck all stores
        this.storesFrom.forEach(store => store.isChecked = false);
        // Check only the selected store
        selectedStore.isChecked = true;
        // find index of store selected and remove from "TO" list
        this.stores = structuredClone(this.data.stores);
        let selectedStoreIndex = this.stores.findIndex(s => s.id === selectedStore.id);
        this.stores.splice(selectedStoreIndex, 1);
    }
}


