import { Inject, Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CaptionService } from './caption.service';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    constructor(@Inject(CaptionService) private captionService: CaptionService) {}

    // auth
    getEmailRegex(): RegExp {
        return new RegExp(
            /^[a-zA-Z0-9.'!#-_%$%%%^%&%*%/]+(\.[a-zA-Z0-9.'!#-_%$%%%^%&%*%/]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\s*$/
        );
    }

    getPasswordRegex(): RegExp {
        return new RegExp(/^(?=.*\W)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,32}$/);
    }

    getConfirmPasswordRegex(): RegExp {
        return new RegExp('^.{8,32}$');
    }

    public maxEmailLength: number = 45;
    public minPasswordLength: number = 8;
    public maxPasswordLength: number = 32;
    public userFirstLastNameLength: number = 45;
    public userEmailMaxLength: number = 45;
    public passwordRecoveryCodeLength: number = 6;

    // feedback
    public maxFeedbackProvidedLength: number = 500;

    // subscription
    getSubscriptionRegex(): RegExp {
        return new RegExp(
            /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/
        );
    }

    public subscriptionEmailMaxLength: number = 30;

    // concepts
    public conceptNameMaxLength: number = 45;

    // stores
    public storeGroupMemberNameMaxLength: number = 30;
    public storeGroupNameMaxLength: number = 30;
    public storeTagNameMaxLength: number = 30;
    public storeDescMaxLength: number = 45;
    public storeLineCityMaxLength: number = 90;

    // vendor APIs
    public vendorTokenMaxLength: number = 20;

    // inventory counter
    public inventoryStoreIDMaxLength: number = 32;
    public inventoryStoreIDMinLength: number = 8;

    // EOI
    eoiValidateEmail(email: string): RegExpMatchArray | boolean {
        if (email) {
            return email.match(/\S+@\S+\.\S+/);
        }
        return false;
    }

    public eoiEmailAndSubjectMaxLength: number = 70;

    // reports
    public reportTemplateJobNameMaxLength: number = 45;
    public reportsToHoldMaxLength: number = 1;
    public reportsEmailMaxLength: number = 255;

    // dashboard
    public dashboardTabNameMaxLength: number = 30;
    public dashboardWidgetNameMaxLength: number = 45;
    
    // store portal
    public portalMessageWidgetMaxLength: number = 512;
    public portalIDMaxLength: number = 32;
    public portalIDMinLength: number = 8;
    public portalConfigTitleMaxLength: number = 32;
    public portalConfigTitleMinLength: number = 4;
    public portalAliasMaxLength: number = 32;
    public portalAliasMinLength: number = 8;
    public portalEmpIDMaxLength: number = 32;

    /**
     * @name deleteStoreConceptConfirmation
     * @description checks if the input is the same as the deleteKeyWord stored in captions
     * @returns null if form is valid, ValidationErrors object if form is invalid
     */
    deleteStoreConceptConfirmation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (value != this.captionService.captions.deleteKeyWord) {
                return { messageIncomplete: true };
            } else {
                return null;
            }
        };
    }
}
