import { Inject, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange, MatDateRangeSelectionStrategy
} from '@angular/material/datepicker';

@Injectable()
export class SevenDayRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(@Inject(DateAdapter) private _dateAdapter: DateAdapter<D>) { }

  selectionFinished(date: D | null): DateRange<D> {
    return this._createFiveDayRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createFiveDayRange(activeDate);
  }
// used in 7 day labour report date picker , want start date picked and add 6 to it
  private _createFiveDayRange(date: D | null): DateRange<D> {
    if (date) {
      const start = date; //this._dateAdapter.addCalendarDays(date, -3);
      const end = this._dateAdapter.addCalendarDays(date, 6);
      return new DateRange<D>(start, end);
    }

    return new DateRange<D>(null, null);
  }
}