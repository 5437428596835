import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserApiService } from "../../../../features/users/services/users.resource";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { UtilsApiService } from "../../../../utils/shared-services/utils.resource";
import { DashboardTabGetWithContent } from "../../models/dashboard.model";
import { DashboardApiService } from "../../services/dashboard.resource";
import type { DashboardDialogData } from "../../models/dialog.model";

@Component({
    selector: 'deleteTabDialog',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/delete-tab-confirmation.tpl.html',
})
export class DeleteTabDialog {
    private toggleWelcome: boolean;

    public captions: Record<string, string>;
    public tabs: DashboardTabGetWithContent[];
    public currentTabIndex: number;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteTabDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(MAT_DIALOG_DATA) public passedData: DashboardDialogData,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(UserApiService) private userApiService: UserApiService
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit(): void {
        this.tabs = this.passedData.tabs;
        this.currentTabIndex = this.passedData.currentTabIndex;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    removeTab(): void {
        if (!this.tabs[this.currentTabIndex]) return;
        let tabId = this.tabs[this.currentTabIndex].id;
        this.dashboardApiService.removeTab(tabId).then(() => {
            this.utilsApiService.getUserInfo().then((userInfo) => {
                this.userApiService.updateFavouriteTab(userInfo, 0);
            });
            this.tabs.splice(this.currentTabIndex, 1);
            this.currentTabIndex--;
            if (this.tabs.length === 0) {
                this.toggleWelcome = true
                this.dialogRef.close({ event: this.toggleWelcome });
            } 
            else {
                this.toggleWelcome = false;
                this.dialogRef.close({ event: this.toggleWelcome });
            }
        });
    }
}
