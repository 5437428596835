import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CaptionService } from "../utils/shared-services/caption.service";
import { ErrorRedirectionComponent } from "./components/404.component";

export const routes: Routes = [
    {
        path: '**',
        pathMatch: 'full',
        component: ErrorRedirectionComponent,
        resolve: {
            captions: CaptionService
        }
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ErrorRoutingModule { }