import { Component, Inject, Input } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';



@Component({
  selector: 'wid000',
  templateUrl: 'src/app/features/store-portal/widgets/templates/wid-000.html'
})
export class Wid000 {
  public captions: any;
  @Input() isTimedOut: boolean = false;

  constructor(@Inject(CaptionService) private captionService) {
    
    this.captions = this.captionService.captions;

   }

  ngOnInit() { }


}