import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { enableProdMode } from '@angular/core';
import { environment } from '../environment';
import { AppModule } from './app/app.module';

// import tailwind
import './css/styles.css';
import './css/tailwind.css';


if (environment.isProd()) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
