import '../polyfills';
// node_modules
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkMenuModule } from '@angular/cdk/menu';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GridsterModule } from 'angular-gridster2';
import { QRCodeModule } from 'angularx-qrcode';
import { SwiperModule } from 'swiper/angular';
import { CUSTOM_DATE_FORMATS } from './utils/date-picker/custom-date-format';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { LayoutModule } from '@angular/cdk/layout';
import { AgGridAngular } from 'ag-grid-angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
//root
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, DatePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { NgChartsModule } from 'ng2-charts';
import { OnlineStatusModule } from 'ngx-online-status';
import { AppInitService } from './app-init.service';
import { AppRootComponent } from './app-root.component';
import { AppSettings } from './app-settings.service';
import { AppRoutingModule } from './app.routing.module';
import { ErrorRedirectionComponent } from './auth/components/404.component';
import { AboutUsComponent, TemplateHashDialog } from './auth/components/about-us.component';
import { ContactUsComponent } from './auth/components/contact-us.component';
import { EnterCodeComponent } from './auth/components/enter-code.component';
import { LoginComponent } from './auth/components/login.component';
import { NewPasswordComponent } from './auth/components/new-password.component';
import { NotificationsDialog } from './auth/components/notifications-dialog.component';
import { PasswordRecoveryComponent } from './auth/components/password-recovery.component';
import { PrivacyPolicyComponent } from './auth/components/privacy-policy.component';
import { TermsOfServiceComponent } from './auth/components/terms-of-service.component';
import { ErrorRoutingModule } from './auth/error-redirect.routing';
import { OnlyLoggedInUsersGuard } from './auth/services/auth-guard.service';
import { AuthService } from './auth/services/auth.resource';
import { HttpCacheService } from './auth/services/cache.service';
import { OnlyLoggedInInventoryUsersGuard } from './auth/services/inventory-auth-guard.service';
import { JwtInterceptor } from './auth/services/jwt.interceptor';
import { OnlyLoggedInHOPortalUsersGuard, OnlyLoggedInStorePortalUsersGuard } from './auth/services/portal-auth-guard.service';
import { RoleGuard } from './auth/services/role-guard.service';
import { AddConceptComponent } from './features/concepts/components/add-concept.component';
import { AllConceptsComponent } from './features/concepts/components/all-concepts.component';
import { BackupStatusComponent } from './features/concepts/components/backup-status.component';
import { ConceptOptionsComponent } from './features/concepts/components/concept-options.component';
import { ConceptDetailsDialog } from './features/concepts/components/dialogs/concept-details-dialog.component.ts';
import { NoConceptsDialog } from './features/concepts/components/dialogs/no-concepts-dialog.conponent';
import { EditConceptComponent } from './features/concepts/components/edit-concept.component';
import { EoiStatusComponent } from './features/concepts/components/eoi-status.component';
import { ConceptApiService } from './features/concepts/services/concept.resource';
import { AddTabDialog } from './features/dashboard/components/dialogs/add-tab.dialog';
import { AssignUserDialog } from './features/dashboard/components/dialogs/assign-user.dialog';
import { ConfigureDateRangeSelectionDialog } from './features/dashboard/components/dialogs/configureDateRangeSelection.dialog';
import { ConfigureDateSelectionDialog } from './features/dashboard/components/dialogs/configureDateSelection.dialog';
import { DeleteTabDialog } from './features/dashboard/components/dialogs/deleteTab.dialog';
import { DeleteWidgetDialog } from './features/dashboard/components/dialogs/deleteWidget.dialog';
import { EditTabDialog } from './features/dashboard/components/dialogs/edit-tab.dialog';
import { WidgetOpenDatesDialog } from './features/dashboard/components/dialogs/widget-opendates.dialog';
import { MainDashboardComponent } from './features/dashboard/components/main-dashboard.component';
import { DashboardEmitterService } from './features/dashboard/services/dashboard-emitter.service';
import { DashboardApiService } from './features/dashboard/services/dashboard.resource';
import { SerializerService } from './features/dashboard/services/serializer.service';
import { WijmoChartTypeService } from './features/dashboard/services/wijmo-chart-type.service';
import { WijmoThemeService } from './features/dashboard/services/wijmo-theme.service';
import { ConfigureWidgetComponent } from './features/dashboard/widget/components/configure-widget.component';
import { FlatWidgetComponent } from './features/dashboard/widget/components/flat-widget.component';
import { WidgetWizardData } from './features/dashboard/widget/services/widget-wizard-data.service';
import { DataExchangeComponent } from './features/data-exchange/components/data-exchange.component';
import { DataExchangeApiService } from './features/data-exchange/services/data-exchange.resource';
import { EoiConfigDialog } from './features/eoi/components/dialogs/eoi-config-dialog.component';
import { EoiFailDetailsDialog } from './features/eoi/components/dialogs/eoi-fail-details-dialog.component';
import { EoiFailResponseDialog } from './features/eoi/components/dialogs/eoi-fail-response-dialog.component';
import { EoiStatusDialog } from './features/eoi/components/dialogs/eoi-status-dialog.component';
import { EoiSubscriptionDialog } from './features/eoi/components/dialogs/eoi-subscription-dialog.component';
import { EoiSuccessResponseDialog } from './features/eoi/components/dialogs/eoi-success-response-dialog.component';
import { EoiVendorDialog } from './features/eoi/components/dialogs/eoi-vendor-dialog.component';
import { EoiDetailComponent } from './features/eoi/components/eoi-detail.component';
import { EoiApiService } from './features/eoi/services/eoi.resource';
import { eoiService } from './features/eoi/services/eoi.service';
import { FeedbackFormComponent } from './features/feedback/components/feedback-form.component';
import { FeedbackApiService } from './features/feedback/services/feedback.resource';
import { HelpDialogComponent } from './features/help/components/help.component';
import { HomeComponent, MaintenanceDialog } from './features/home/components/home.component';
import { HomeApiService } from './features/home/services/home.resource';
import { CountLocationsComponent } from './features/inventory-counter/components/count-locations.component';
import { CountTypesComponent } from './features/inventory-counter/components/count-types.component';
import { CountWizardComponent } from './features/inventory-counter/components/count-wizard.component';
import { CountsheetComponent } from './features/inventory-counter/components/countsheet.component';
import { DatePickerComponent } from './features/inventory-counter/components/date-picker.component';
import { InventoryCounterComponent } from './features/inventory-counter/components/inventory-counter.component';
import { InventoryCounterDialogService } from './features/inventory-counter/services/inventory-counter-dialog.service';
import { InventoryCountApiService } from './features/inventory-counter/services/inventory-counter.resource';
import { InventoryCounterService } from './features/inventory-counter/services/inventory-counter.service';
import { ConfirmPermanentDeleteDialog } from './features/recycle-bin/components/dialogs/confirm-permanent-delete-dialog.component';
import { NoItemSelectedDialog } from './features/recycle-bin/components/dialogs/no-item-selected-dialog.component';
import { RestoreItemsConfirmationDialog } from './features/recycle-bin/components/dialogs/restore-items-confirmation-dialog.component';
import { RecycleBinComponent } from './features/recycle-bin/components/recycle-bin.component';
import { RecycleBinApiService } from './features/recycle-bin/services/recycle-bin.resource';
import { ManageReportsComponent, ViewReportComponent } from './features/reports';
import { AllConceptsReportsComponent } from './features/reports/components/all-concepts-reports.component';
import { ReportTooltipComponent } from './features/reports/components/dialogs/report-tooltip.component';
import { ShowRedirectDialog } from './features/reports/components/dialogs/show-redirect-dialog.component';
import {
    DeleteAllJobsDialog,
    DeleteAllSchedulesDialog,
    DeleteJobDialog,
    DeleteTemplateDialog,
    SaveReportAsJobDialog,
    SaveReportAsTemplateDialog,
    ScheduleJobsDialog,
    ShowDiscardDialog,
} from './features/reports/internal';
import { ScheduleApiService } from './features/reports/services/report-schedules.resource';
import { ReportTemplatesDataService } from './features/reports/services/report-templates-data.resource';
import { ReportEmitterService } from './features/reports/services/reportEmitter.service';
import { ReportApiService } from './features/reports/services/reports.resource';
import { WijmoFormatterService } from './features/reports/services/wijmo-formatter.service';
import { AllStoresReportComponent } from './features/reports/wizard/components/all-stores-report.component';
import { ReportWizardButtonsComponent } from './features/reports/wizard/components/report-wizard-buttons.component';
import { ReportWizardConfirmationComponent } from './features/reports/wizard/components/report-wizard-confirmation.component';
import { ReportWizardDateComponent } from './features/reports/wizard/components/report-wizard-date.component';
import { ReportScheduleComponent } from './features/reports/wizard/components/report-wizard-schedule.component';
import { ReportWizardComponent } from './features/reports/wizard/report-wizard';
import { ReportDataWizard } from './features/reports/wizard/services/report-data-wizard.service';
import { ReportParamsHandler } from './features/reports/wizard/services/report-params-handler.service';
import { SharedWizardData } from './features/reports/wizard/services/report-shared-wizard-data.service';
import { AllGroupMembersComponent } from './features/store-groups/components/all-group-members.component';
import { AllStoreGroupsComponent } from './features/store-groups/components/all-store-groups.component';
import { DeleteStoreGroupMemberDialog } from './features/store-groups/components/delete-group-member-dialog.component';
import { DeleteStoreGroupDialog } from './features/store-groups/components/delete-store-group-dialog.component';
import { StoreGroupMembersApiService } from './features/store-groups/services/store-group-member.resource';
import { StoreGroupApiService } from './features/store-groups/services/store-group.resource';
import { DateSelectionDialog } from './features/store-portal/components/dialogs/date-selection-dialog.component';
import { WidgetDescriptionDialog } from './features/store-portal/components/dialogs/widget-description-dialog.component';
import { StorePortalLoginComponent } from './features/store-portal/components/store-portal-login.component';
import { StorePortalComponent } from './features/store-portal/components/store-portal.component';
import { StorePortalApiService } from './features/store-portal/services/store-portal.resource';
import { StorePortalService } from './features/store-portal/services/store-portal.service';
import { HOPortalLoginComponent } from './features/head-office-portal/components/head-office-login.component';
import { HOPortalApiService } from './features/head-office-portal/services/head-office-portal.resource';
import { HOPortalService } from './features/head-office-portal/services/head-office-portal.service';
import { HOPortalComponent } from './features/head-office-portal/components/head-office-portal.component';
import { ChartBar } from './features/store-portal/widgets/charts/components/chart-bar';
import { ChartDoughnut } from './features/store-portal/widgets/charts/components/chart-doughnut';
import { TableHourly } from './features/store-portal/widgets/charts/components/table-hourly';
import { TableLegend } from './features/store-portal/widgets/charts/components/table-legend';
import { Wid000 } from './features/store-portal/widgets/components/wid-000';
import { Wid001 } from './features/store-portal/widgets/components/wid-001';
import { Wid002 } from './features/store-portal/widgets/components/wid-002';
import { Wid003 } from './features/store-portal/widgets/components/wid-003';
import { Wid004 } from './features/store-portal/widgets/components/wid-004';
import { Wid005 } from './features/store-portal/widgets/components/wid-005';
import { Wid006 } from './features/store-portal/widgets/components/wid-006';
import { Wid007 } from './features/store-portal/widgets/components/wid-007';
import { Wid008 } from './features/store-portal/widgets/components/wid-008';
import { Wid009 } from './features/store-portal/widgets/components/wid-009';
import { Wid010 } from './features/store-portal/widgets/components/wid-010';
import { Wid011 } from './features/store-portal/widgets/components/wid-011';
import { Wid012 } from './features/store-portal/widgets/components/wid-012';
import { Wid013 } from './features/store-portal/widgets/components/wid-013';
import { Wid014 } from './features/store-portal/widgets/components/wid-014';
import { Wid015 } from './features/store-portal/widgets/components/wid-015';
import { Wid016 } from './features/store-portal/widgets/components/wid-016';
import { Wid017 } from './features/store-portal/widgets/components/wid-017';
import { Wid018 } from './features/store-portal/widgets/components/wid-018';
import { Wid019 } from './features/store-portal/widgets/components/wid-019';
import { Wid020 } from './features/store-portal/widgets/components/wid-020';
import { Wid021 } from './features/store-portal/widgets/components/wid-021';
import { Wid022 } from './features/store-portal/widgets/components/wid-022';
import { Wid023 } from './features/store-portal/widgets/components/wid-023';
import { Wid024 } from './features/store-portal/widgets/components/wid-024';
import { Wid025 } from './features/store-portal/widgets/components/wid-025';
import { AddStoreComponent } from './features/stores/components/add-store.component';
import { AllStoresComponent } from './features/stores/components/all-stores.component';
import { StoreGroupsComponent } from './features/stores/components/assign-store-groups.component';
import { StoreTagsComponent } from './features/stores/components/assign-store-tags.component';
import { BackupDeleteDialog } from './features/stores/components/dialogs/backup-delete-dialog.component';
import { BackupDeleteOneDialog } from './features/stores/components/dialogs/backup-deleteOne-dialog.component';
import { BackupInfoDialog } from './features/stores/components/dialogs/backup-info-dialog.component';
import { StoreAndConceptConfirmDeleteDialog } from './features/stores/components/dialogs/confirm-delete-dialog.component';
import { ConfirmDeleteViewDialog } from './features/stores/components/dialogs/confirm-delete-view-dialog.component';
import { ConfirmResetQ1Dialog } from './features/stores/components/dialogs/confirm-reset-Q1-dialog.component';
import { ConfirmResetQ2Dialog } from './features/stores/components/dialogs/confirm-reset-Q2-dialog.component';
import { ConfirmedMessageDialog } from './features/stores/components/dialogs/confirmed-message-dialog.component';
import { NoStoresDialog } from './features/stores/components/dialogs/no-stores-dialog.component';
import { OpenCalDialog } from './features/stores/components/dialogs/open-cal-dialog.component';
import { ScheduledUpdatesDialog } from './features/stores/components/dialogs/scheduled-updates-dialog.component';
import { StoreDetailsDialog } from './features/stores/components/dialogs/store-details-dialog.component';
import { StoreDownloadDialog } from './features/stores/components/dialogs/store-download-dialog.component';
import { EditStoreComponent } from './features/stores/components/edit-store.component';
import { EnableCountsheetComponent } from './features/stores/components/enable-countsheet.component';
import { EnableStorePortalComponent } from './features/stores/components/enable-store-portal.component';
import { EnableHOPortalComponent } from './features/concepts/components/enable-ho-portal.component';
import { ExtractDataComponent } from './features/stores/components/extract-data.component';
import { StoreBackupComponent } from './features/stores/components/store-backup.component';
import { StoreOptionsComponent } from './features/stores/components/store-options.component';
import { StoreSoftwareComponent } from './features/stores/components/store-software.component';
import { StoreApiService } from './features/stores/services/store.resource';
import { StoreTagFilterStorage } from './features/stores/services/stores.service';
import { DeleteTagDialog, TagsComponent } from './features/tags/components/tags.component';
import { TagApiService } from './features/tags/services/tag.resource';
import { AddUserComponent } from './features/users/components/add-user.component';
import { AllUsersComponent } from './features/users/components/all-users.component';
import { PasswordChangeComponent } from './features/users/components/change-password.component';
import { ConfirmDeleteUserDialog } from './features/users/components/confirm-delete-user-dialog.component';
import { ConfirmResetPasswordDialog } from './features/users/components/confirm-reset-password-dialog.component';
import { EditUserComponent } from './features/users/components/edit-user.component';
import { InheritUserPrivilegeDialog } from './features/users/components/inherit-user-privilege-dialog.component';
import { ManageUserAccessComponent } from './features/users/components/manage-user-access.component';
import { ManageUserPrivilegesComponent } from './features/users/components/manage-user-privilege.component';
import { PasswordResetComponent } from './features/users/components/reset-password.component';
import { SubscriptionsDialog } from './features/users/components/subscriptions.component';
import { UserSettingsComponent } from './features/users/components/user-settings.component';
import { UserAccessHandler } from './features/users/services/user-access-handler.service';
import { UserEmitterService } from './features/users/services/user-emitter.service';
import { UserInfoHandler } from './features/users/services/user-info-handler.service';
import { UserSecurityApiService } from './features/users/services/user-security.resource';
import { UserApiService } from './features/users/services/users.resource';
import { ManageApisComponent } from './features/vendor-apis/components/manage-apis.component';
import { ConfirmDeleteTokenDialog } from './features/vendor-apis/components/remove-token-dialog.component';
import { ApiConsumerService } from './features/vendor-apis/services/api-consumer.resource';
import { ApiPackageService } from './features/vendor-apis/services/api-package.resource';
import { VendorApiService } from './features/vendor-apis/services/vendor.resource';
import { AngularMaterialModule } from './material.module';
import { BreadcrumbComponent } from './utils/breadcrumb/breadcrumb.component';
import { SevenDayRangeSelectionStrategy } from './utils/date-picker/date-range-selection-strategy.service';
import { SevenDayDatePickerComponent } from './utils/date-picker/seven-day-date-picker.component';
import { MyFilterPipe } from './utils/pipes/filter.pipe';
import { OrderByPipe, SortByDatePipe } from './utils/pipes/order-by.pipe';
import { OrderStringByPipe } from './utils/pipes/order-string-by.pipe';
import { RecycledItemsFilterPipe } from './utils/pipes/recycled-items-filter.pipe';
import { SafePipe } from './utils/pipes/safe-pipe';
import { StoreGroupsFilterPipe } from './utils/pipes/store-groups.pipe';
import { TagsFilterPipe } from './utils/pipes/tags.pipe';
import { ColorPickerComponent } from './utils/shared-components/colorpicker.component';
import { ConfirmationDialogComponent } from './utils/shared-components/confirmation-dialog.component';
import { InfoListGroupComponent } from './utils/shared-components/info-list-group.component';
import { InfoListComponent } from './utils/shared-components/info-list.component';
import { TitleBarComponent } from './utils/shared-components/titlebar.component';
import { ConceptPickerDialog } from './utils/shared-components/user-access/concept-picker-dialog.component';
import { ConceptPickerComponent } from './utils/shared-components/user-access/concept-picker.component';
import { CreateTagSelectedStoresDialog } from './utils/shared-components/user-access/create-tag-selected-stores-dialog.component';
import { GroupItemPickerDialog } from './utils/shared-components/user-access/group-item-picker-dialog.component';
import { GroupItemPickerComponent } from './utils/shared-components/user-access/group-item-picker.component';
import { StorePickerDialog } from './utils/shared-components/user-access/store-picker-dialog.component';
import { StorePickerComponent } from './utils/shared-components/user-access/store-picker.component';
import { TagPickerDialog } from './utils/shared-components/user-access/tag-picker-dialog.component';
import { TagPickerComponent } from './utils/shared-components/user-access/tag-picker.component';
import { SortDirective } from './utils/shared-directives/sort.directive';
import { BrowserStorage } from './utils/shared-services/browser-storage.service';
import { CaptionService } from './utils/shared-services/caption.service';
import { DataUtils } from './utils/shared-services/data-utils.service';
import { EmitterService } from './utils/shared-services/emitter.service';
import { ExtractionAPIService } from './utils/shared-services/extraction.resource';
import { ItemStorageService } from './utils/shared-services/item-storage.service';
import { MobileViewService } from './utils/shared-services/mobileView/mobileView.service';
import { SnackbarService } from './utils/shared-services/snackbar/snackbar.service';
import { SpinnerComponent } from './utils/shared-services/spinner/spinner-component';
import { SpinnerInterceptor } from './utils/shared-services/spinner/spinner.interceptor';
import { SpinnerService } from './utils/shared-services/spinner/spinner.service';
import { UtilsApiService } from './utils/shared-services/utils.resource';
import { ChartGauge } from './features/store-portal/widgets/charts/components/chart-gauge';
import { ConfirmDisablePortalDialog } from './features/stores/components/dialogs/confirm-disable-portal-dialog.component';
import { ConfirmDisableHOPortalDialog } from './features/concepts/components/dialogs/confirm-disable-portal-dialog.component';
import { DummyLoadingComponent } from './features/dummy/components/dummy-loading.component';
import { EditColumnsDialog } from './features/store-portal/widgets/components/dialogs/wid-009-edit-columns-dialog.component';
import { EditColumnsDialogV2 } from './features/store-portal/widgets/components/dialogs/wid-011-edit-columns-dialog.component';
import { EditColumnsDialogWid012 } from './features/store-portal/widgets/components/dialogs/wid-012-edit-column-dialog.component';
import { EditColumnsDialogWid013 } from './features/store-portal/widgets/components/dialogs/wid-013-edit-colums-dialog.component';
import { EditColumnsDialogWid016 } from './features/store-portal/widgets/components/dialogs/wid-016-edit-column-dialog.component';
import { EoiUnsubscribeComponent } from './features/eoi/components/eoi-unsubscribe.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableDetailDialog } from './features/store-portal/components/dialogs/table-detail-dialog.component';
import { EoiCustomDateDialog } from './features/eoi/components/dialogs/eoi-custom-date-dialog.component';
import { UsageLoggerApiService } from './utils/shared-services/usageLogger.service';
import { PriceChangeComponent } from './features/price-change/components/price-change.component';
import { PriceChangeService } from './features/price-change/services/price-change.resource';
import { PriceChangeReportCategorySelectionDialog } from './features/price-change/components/dialogs/report-category-selection.component';
import { PriceChangeStoreSelectionDialog } from './features/price-change/components/dialogs/store-selection.component';
import { PriceChangeColumnSelectionDialog } from './features/price-change/components/dialogs/column-selection.component';
import { SubmitPriceChangeDialog } from './features/price-change/components/dialogs/submit-price-change.component';
import { PriceChangeRowCompDialog } from './features/price-change/components/dialogs/row-comp-dialog.component';
import { PriceHistoryDialog } from './features/price-change/components/dialogs/price-history-dialog.component';
import { PriceListDialog } from './features/price-change/components/dialogs/price-list-dialog.component';
import { SetPriceDialog } from './features/price-change/components/dialogs/set-price.component';
import { ReportViewerDialog } from './features/store-portal/components/dialogs/report-viewer-dialog.component';
import { ReviewPriceChangeDialog } from './features/price-change/components/dialogs/review-price-changes.component';
import { ReviewStoreNamesDialog } from './features/price-change/components/dialogs/review-store-names.component';
import { PriceMatchComponent } from './features/price-change/components/price-match.component';
import { LoadingScreenComponent } from './features/store-portal/components/dialogs/loading-screen-dialog.component';
import { EoiVendorRequestComponent } from './auth/components/eoi-vendor-request.component';
import { SelectPrimaryStoreDialog } from './features/concepts/components/dialogs/select-primary-store-dialog.component';
import { PriceExplorerComponent } from './features/price-change/components/price-explorer.component';
import { ConfirmPriceChangeDialog } from './features/price-change/components/dialogs/confirm-dialog.component';

export function initializeApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.Init();
    };
}

const dbConfig: DBConfig = {
    name: 'MyDb',
    version: 1,
    objectStoresMeta: [{
        store: 'orgProducts',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
            { name: 'products', keypath: 'products', options: { unique: false } }
        ]
    }]
};

@NgModule({
    imports: [
        AngularMaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ClipboardModule,
        CommonModule,
        DragDropModule,
        ErrorRoutingModule,
        LayoutModule,
        FormsModule,
        GridsterModule,
        HttpClientModule,
        NgChartsModule,
        MatMenuModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatDatepickerModule,
        MomentDateModule,
        OnlineStatusModule,
        QRCodeModule,
        ReactiveFormsModule,
        ScrollingModule,
        SwiperModule,
        NgxMatSelectSearchModule,
        MatTooltipModule,
        AgGridAngular,
        CdkMenuModule,
        NgxMaterialTimepickerModule,
        NgxIndexedDBModule.forRoot(dbConfig)

    ],
    providers: [
        {
            provide: MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS,
        },
        {
            provide: Window,
            useValue: 'window',
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SpinnerInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true,
        },
        ApiConsumerService,
        ApiPackageService,
        AppInitService,
        AppSettings,
        AuthService,
        BrowserStorage,
        CaptionService,
        ConceptApiService,
        DashboardApiService,
        DashboardEmitterService,
        DataExchangeApiService,
        DataUtils,
        DatePipe,
        EmitterService,
        EoiApiService,
        eoiService,
        ExtractionAPIService,
        FeedbackApiService,
        HomeApiService,
        HOPortalApiService,
        HOPortalService,
        HttpCacheService,
        InventoryCountApiService,
        InventoryCounterDialogService,
        InventoryCounterService,
        ItemStorageService,
        MobileViewService,
        OnlyLoggedInInventoryUsersGuard,
        OnlyLoggedInStorePortalUsersGuard,
        OnlyLoggedInUsersGuard,
        OnlyLoggedInHOPortalUsersGuard,
        PriceChangeService,
        RecycleBinApiService,
        ReportApiService,
        ReportDataWizard,
        ReportEmitterService,
        ReportParamsHandler,
        ReportTemplatesDataService,
        RoleGuard,
        ScheduleApiService,
        SerializerService,
        SevenDayRangeSelectionStrategy,
        SharedWizardData,
        SnackbarService,
        SpinnerService,
        StoreApiService,
        StoreGroupApiService,
        StoreGroupMembersApiService,
        StorePortalApiService,
        StorePortalService,
        StoreTagFilterStorage,
        TagApiService,
        UserAccessHandler,
        UserApiService,
        UserEmitterService,
        UserInfoHandler,
        UserSecurityApiService,
        UtilsApiService,
        VendorApiService,
        WidgetWizardData,
        WijmoChartTypeService,
        WijmoFormatterService,
        WijmoThemeService,
        UsageLoggerApiService,
    ],
    declarations: [
        AboutUsComponent,
        AddConceptComponent,
        AddStoreComponent,
        AddTabDialog,
        AddUserComponent,
        AllConceptsComponent,
        AllConceptsReportsComponent,
        AllGroupMembersComponent,
        AllStoreGroupsComponent,
        AllStoresComponent,
        AllStoresReportComponent,
        AllUsersComponent,
        AssignUserDialog,
        BackupDeleteDialog,
        BackupDeleteOneDialog,
        BackupInfoDialog,
        BackupStatusComponent,
        BreadcrumbComponent,
        ChartBar,
        ChartDoughnut,
        ChartGauge,
        ColorPickerComponent,
        ConceptDetailsDialog,
        ConceptPickerComponent,
        ConceptPickerDialog,
        ConceptOptionsComponent,
        ConfigureDateRangeSelectionDialog,
        ConfigureDateSelectionDialog,
        ConfigureWidgetComponent,
        ConfirmDeleteTokenDialog,
        ConfirmDeleteUserDialog,
        ConfirmPermanentDeleteDialog,
        ConfirmResetPasswordDialog,
        ConfirmResetQ1Dialog,
        ConfirmResetQ2Dialog,
        ConfirmationDialogComponent,
        ConfirmedMessageDialog,
        ContactUsComponent,
        CountLocationsComponent,
        CountTypesComponent,
        CountWizardComponent,
        CountsheetComponent,
        CreateTagSelectedStoresDialog,
        DataExchangeComponent,
        DatePickerComponent,
        DateSelectionDialog,
        DeleteAllJobsDialog,
        DeleteAllSchedulesDialog,
        DeleteJobDialog,
        DeleteStoreGroupDialog,
        DeleteStoreGroupMemberDialog,
        DeleteTabDialog,
        DeleteTagDialog,
        DeleteTemplateDialog,
        DeleteWidgetDialog,
        DummyLoadingComponent,
        EditConceptComponent,
        EditStoreComponent,
        EditTabDialog,
        EditUserComponent,
        EnableCountsheetComponent,
        EnableStorePortalComponent,
        EnableHOPortalComponent,
        EnterCodeComponent,
        EditColumnsDialogV2,
        EditColumnsDialog,
        EditColumnsDialogWid012,
        EditColumnsDialogWid013,
        EditColumnsDialogWid016,
        EoiConfigDialog,
        EoiDetailComponent,
        EoiFailDetailsDialog,
        EoiFailResponseDialog,
        EoiStatusComponent,
        EoiStatusDialog,
        EoiSubscriptionDialog,
        EoiSuccessResponseDialog,
        EoiVendorDialog,
        EoiCustomDateDialog,
        ErrorRedirectionComponent,
        ExtractDataComponent,
        FeedbackFormComponent,
        FlatWidgetComponent,
        GroupItemPickerComponent,
        GroupItemPickerDialog,
        HelpDialogComponent,
        HOPortalLoginComponent,
        HOPortalComponent,
        HomeComponent,
        InfoListComponent,
        InfoListGroupComponent,
        InheritUserPrivilegeDialog,
        InventoryCounterComponent,
        LoginComponent,
        MainDashboardComponent,
        MaintenanceDialog,
        ManageApisComponent,
        ManageReportsComponent,
        ManageUserAccessComponent,
        ManageUserPrivilegesComponent,
        MyFilterPipe,
        NewPasswordComponent,
        NoConceptsDialog,
        NoItemSelectedDialog,
        NoStoresDialog,
        NotificationsDialog,
        OpenCalDialog,
        OrderByPipe,
        OrderStringByPipe,
        PasswordChangeComponent,
        PasswordRecoveryComponent,
        PasswordResetComponent,
        PriceChangeComponent,
        PriceChangeColumnSelectionDialog,
        SubmitPriceChangeDialog,
        PriceChangeReportCategorySelectionDialog,
        PriceChangeStoreSelectionDialog,
        PriceChangeRowCompDialog,
        PrivacyPolicyComponent,
        RecycleBinComponent,
        RecycledItemsFilterPipe,
        ReportScheduleComponent,
        ReportTooltipComponent,
        ReportWizardButtonsComponent,
        ReportWizardComponent,
        ReportWizardConfirmationComponent,
        ReportWizardDateComponent,
        RestoreItemsConfirmationDialog,
        SafePipe,
        SaveReportAsJobDialog,
        SaveReportAsTemplateDialog,
        ScheduleJobsDialog,
        ScheduledUpdatesDialog,
        SevenDayDatePickerComponent,
        ShowDiscardDialog,
        ShowRedirectDialog,
        SortByDatePipe,
        SortDirective,
        SpinnerComponent,
        StoreAndConceptConfirmDeleteDialog,
        StoreBackupComponent,
        StoreDetailsDialog,
        StoreDownloadDialog,
        StoreGroupsComponent,
        StoreGroupsFilterPipe,
        StoreOptionsComponent,
        StorePickerComponent,
        StorePickerDialog,
        StorePortalComponent,
        StorePortalLoginComponent,
        StoreSoftwareComponent,
        StoreTagsComponent,
        SubscriptionsDialog,
        TableHourly,
        TableLegend,
        TagPickerComponent,
        TagPickerDialog,
        TagsComponent,
        TagsFilterPipe,
        TemplateHashDialog,
        ConfirmDeleteViewDialog,
        ConfirmDisablePortalDialog,
        ConfirmDisableHOPortalDialog,
        TermsOfServiceComponent,
        TitleBarComponent,
        UserSettingsComponent,
        ViewReportComponent,
        TableDetailDialog,
        LoadingScreenComponent,
        Wid000,
        Wid001,
        Wid002,
        Wid003,
        Wid004,
        Wid005,
        Wid006,
        Wid007,
        Wid008,
        Wid009,
        Wid010,
        Wid011,
        Wid012,
        Wid013,
        Wid014,
        Wid015,
        Wid016,
        Wid017,
        Wid018,
        Wid019,
        Wid020,
        Wid020,
        Wid021,
        Wid022,
        Wid023,
        Wid024,
        Wid025,
        WidgetOpenDatesDialog,
        WidgetDescriptionDialog,
        EoiUnsubscribeComponent,
        PriceHistoryDialog,
        PriceListDialog,
        SetPriceDialog,
        ReportViewerDialog,
        ReviewPriceChangeDialog,
        ReviewStoreNamesDialog,
        PriceMatchComponent,
        EoiVendorRequestComponent,
        SelectPrimaryStoreDialog,
        PriceExplorerComponent,
        ConfirmPriceChangeDialog
    ],
    bootstrap: [AppRootComponent],
})
export class AppModule { }
