import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class FormValidatorService {
    constructor() {}

    matchPassword(password: string, confirmPassword: string) {
        return (fg: UntypedFormGroup) => {
            const passwordCtl = fg.controls[password];
            const confirmPasswordCtl = fg.controls[confirmPassword];
            if (!passwordCtl || !confirmPasswordCtl) return;
            if (confirmPasswordCtl.errors && !confirmPasswordCtl.errors?.['passwordMismatch']) return;
            if (passwordCtl.value !== confirmPasswordCtl.value)
                confirmPasswordCtl.setErrors({ passwordMismatch: true });
            else confirmPasswordCtl.setErrors(null);
            return;
        };
    }
}
