import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'confirmResetQ1-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/confirm-reset-Q1-dialog.html',
})
export class ConfirmResetQ1Dialog {

    public captions: Record<string, string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfirmResetQ1Dialog>
    ) {
        this.captions = this.captionService.captions;
    }

    confirmReset(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
