import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { InventoryCountApiService } from '../services/inventory-counter.resource';
import { ValidationService } from '../../../utils/shared-services/validation.service';
import { InventoryLogin } from '../models/inventory-counter.model';

@Component({
    selector: 'inventoryCounter',
    templateUrl: 'src/app/features/inventory-counter/templates/inventory-counter.html',
})
export class InventoryCounterComponent {
    public captions: any;
    public passedStoreId;
    public pageNum;
    public isDateBad: boolean = false;

    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(InventoryCountApiService) private inventoryCountApiService: InventoryCountApiService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.queryParams.subscribe((params) => {
            this.passedStoreId = params['StoreID'];
            this.browserStorage.setLocalstorage('storeID', this.passedStoreId);
        });
        this.form = this.formBuilder.group({
            storeID: [this.passedStoreId, [Validators.required, Validators.maxLength(this.validationService.inventoryStoreIDMaxLength), Validators.minLength(this.validationService.inventoryStoreIDMinLength)]],
            userID: ['', []],
            empID: ['', [Validators.required]],
        });
    }

    ngOnInit(): void { }

    loginToCount(value: InventoryLogin): void {
        this.inventoryCountApiService.loginForInventoryCount(value.storeID, value.userID, value.empID).then((res: string) => {
            let resOBJ = JSON.parse(res);
            //   POS returning 1899 is not a valid date value
            if (resOBJ.minDate.includes("1899") || resOBJ.minDate.includes("1899")) {
                this.isDateBad = true
            } else {
                this.browserStorage.setLocalstorage('inventoryCounterToken', resOBJ.jwtString);
                this.browserStorage.setLocalstorage('invMinDate', resOBJ.minDate);
                this.browserStorage.setLocalstorage('invMaxDate', resOBJ.maxDate);

                this.router.navigate(['inventoryCounter/countType'], {
                    queryParams: {
                        minDate: resOBJ.minDate,
                        maxDate: resOBJ.maxDate,
                    },
                });

            }

        });
    }

    goToLogin(): void{
        // switch view back to login page
        this.isDateBad = false
    }
}
