import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs';
import { ConceptApiService } from '../../../../features/concepts/services/concept.resource';
import { WidgetWizardData } from '../../../../features/dashboard/widget/services/widget-wizard-data.service';
import { StoreGroupApiService } from '../../../../features/store-groups/services/store-group.resource';
import { StoreApiService } from '../../../../features/stores/services/store.resource';
import { TagApiService } from '../../../../features/tags/services/tag.resource';
import { UserAccessHandler } from '../../../../features/users/services/user-access-handler.service';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { ItemStorageService } from '../../../../utils/shared-services/item-storage.service';
import { SnackbarService } from '../../../../utils/shared-services/snackbar/snackbar.service';
import { ConfigureWidgetData } from '../../models/dialog.model';
import { DashboardEmitterService } from '../../services/dashboard-emitter.service';
import { DashboardApiService } from '../../services/dashboard.resource';
import { WijmoChartTypeService } from '../../services/wijmo-chart-type.service';
import { WijmoThemeService } from '../../services/wijmo-theme.service';
import { UserConstants } from '../../../../features/users/users.constants';
import { WidgetInstaceGet, WidgetInstance, WidgetInstancePut, WidgetTemplate } from '../../models/widget.model';
import { Concept } from '../../../concepts/models/concept.model';
import { UserAccess } from '../../../users/models/user-access.model';
import { UserRole } from '../../../users/enums/user-role.enum';
import { ValidationService } from '../../../../utils/shared-services/validation.service';

@Component({
    selector: 'configureWidget',
    templateUrl: 'src/app/features/dashboard/widget/templates/configure-widget.tpl.html',
})
export class ConfigureWidgetComponent {
    private USER_ACCESS: any;
    private currentUser: UserAccess;
    private groupingID: number;
    private dataSource: string = '';
    private concept: Concept;
    private allStoresChecked: boolean;
    private showStoreBox: boolean;
    private currentUserAccess: UserAccess;
    private selectedWidgetId: number;
    private widgetInstance: WidgetInstance;
    private parameters: any;
    private storeparameters: any;
    private selectedStores: any;

    public captions: Record<string, string>;
    public stores: any[];
    public concepts: any;
    public showStorePickerPopup: boolean;
    public customAllStoresName: string;
    public hideAllStoresBox: boolean;
    public customStoreCaption: string = '';
    public selectedConcept: Concept;
    public conceptDataLoaded: boolean;
    public showSelectConceptBox: boolean;
    public showConceptPickerPopup: boolean;
    public storeIds: string[];
    public showSelectStoresBox: boolean;
    public dateparameters: { description: string, key: string }[];
    public themes: { description: string, key: string }[];
    public chartTypes: { description: string, key: string }[];
    public showChartDropdown: boolean = false;
    public showGrouping: boolean = false;
    public storeGroups: Object;
    public selectedGroupID: number = 0;
    public selectedTagID: number = 0;
    public tags: Object;

    /* PARAM id/values : 
        id 1: concept,
        id 2: refreshInterval,
        id 3: colorThemeDropdown,
        id 4: chartTypeDropdown,
        id 5: currencyField,
        id 2001: storeList,
        id 2002: dateDropdown,
        id 6: groupingID
    */
    public widget: WidgetInstancePut = {
        parameters: [
            { id: 1, value: '' },
            { id: 2, value: '' },
            { id: 3, value: '' },
            { id: 4, value: '0' },
            { id: 5, value: '' },
            { id: 2001, value: '' },
            { id: 2002, value: '' },
            { id: 6, value: '' },
        ],
        displayName: '',
        conceptID: 0,
        storesSelected: 0,
        storesTotal: 0,
    };

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(DashboardEmitterService) private dashboardEmitterService: DashboardEmitterService,
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(MatDialogRef) private dialogRef: MatDialogRef<ConfigureWidgetComponent>,
        @Inject(MAT_DIALOG_DATA) private data: ConfigureWidgetData,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(WidgetWizardData) private widgetWizardData: WidgetWizardData,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(WijmoThemeService) private wjThemes: WijmoThemeService,
        @Inject(WijmoChartTypeService) private wjChartTypes: WijmoChartTypeService,
        @Inject(StoreGroupApiService) private storeGroupApiService: StoreGroupApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.captionService.captions;
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUser = this.userAccessHandler.getUserAccess();
        this.widgetWizardData.cleanUpData();
    }

    ngOnInit(): void {
        this.themes = this.wjThemes.getWijmoThemeCaptions();
        this.chartTypes = this.wjChartTypes.getWijmoChartTypesCaption();
        if (this.data.isWidgetInstance) {
            this.getParametersByWidgetInstanceId(this.data.selectedWidgetId);
        } 
        else {
            this.getParametersByWidgetTemplateId(this.data.selectedWidgetId);
        }
    }

    getParametersByWidgetTemplateId(widgetTemplatId: number): void {
        this.showSelectConceptBox = true;
        this.dashboardApiService.getWidgetTemplateById(widgetTemplatId).then((widgetTemplate) => {
            this.widgetWizardData.checkStoreConceptParameterOrder(widgetTemplate.parameters);
            this.widgetWizardData.setWidgetName(widgetTemplate.displayName);
            this.widget.displayName = this.widgetWizardData.getWidgetName();
            this.dashboardEmitterService.widgetInfoFetched(widgetTemplate);
            this.storeIds = [];
            this.showSelectStoresBox = true;
            this.populateparameters(widgetTemplate, this.data.isWidgetInstance);
        });
    }

    getParametersByWidgetInstanceId(widgetInstanceId: number): void {
        this.dashboardApiService.getWidgetInstanceById$(widgetInstanceId).pipe(take(1)).subscribe((widgetInstance) => {
            this.widgetInstance = widgetInstance;
            this.widgetWizardData.checkStoreConceptParameterOrder(widgetInstance.parameters);
            this.widgetWizardData.setWidgetName(widgetInstance.displayName);
            this.dashboardEmitterService.widgetInfoFetched(widgetInstance);
            this.populateparameters(widgetInstance, this.data.isWidgetInstance);
        });
    }

    populateparameters(widgetInstance: WidgetInstance | WidgetTemplate, isWidgetInstance: boolean) {
        this.dataSource = (widgetInstance as WidgetInstance).dataSource;
        this.widget.displayName = widgetInstance.displayName;

        for (let param of widgetInstance.parameters) {
            switch (param.id) {
                case 1: {
                    if (isWidgetInstance) {
                        this.widget.parameters[0].value = param.value;
                        this.showSelectConceptBox = false;
                        this.loadConcept(parseInt(param.value));
                    }
                    break;
                }
                case 2: {
                    this.widget.parameters[1].value = param.value;
                    break;
                }
                case 3: {
                    this.widget.parameters[2].value = param.value;
                    break;
                }
                case 4: {
                    this.widget.parameters[3].value = param.value;
                    this.showChartDropdown = true;
                    break;
                }
                case 5: {
                    this.widget.parameters[4].value = param.value;
                    break;
                }
                case 6: {
                    this.groupingID = parseInt(param.value);
                    if (this.groupingID.toString().startsWith("20")) {
                        this.selectedGroupID = this.groupingID;
                    } 
                    else if (this.groupingID.toString().startsWith("19")) {
                        this.selectedTagID = this.groupingID;
                    }

                    // security check for the current user
                    if (this.currentUser.role === UserRole.Global || this.currentUser.role === UserRole.Concept) {
                        this.showGrouping = true;
                    }
                    break;
                }
                case 2001: {
                    if (isWidgetInstance) {
                        this.widget.parameters[5].value = param.value;
                        this.storeIds = this.widget.parameters[5].value.split(',');
                        this.customStoreCaption = this.storeIds.length + ' ' + this.captions.storesSelected;
                        this.showSelectStoresBox = false;
                    }
                    break;
                }
                case 2002: {
                    this.widget.parameters[6].value = param.value;
                    this.dateparameters = param.options;
                    break;
                }
            }
        }
    }

    getStoreGroups(concept) {
        if (this.showGrouping) {
            this.storeGroupApiService.getAllStoreGroupsForConcept(concept.id).then(storeGroups => {
                this.storeGroups = storeGroups;
            });
        }
    }

    getTags(concept) {
        if (this.showGrouping) {
            this.tagApiService.getAllTagsForConcept(concept.id).then(tags => {
                this.tags = tags;
            })
        }
    }

    resetGroupSelection(): void {
        this.selectedGroupID = 0;
    }

    resetTagSelection(): void {
        this.selectedTagID = 0;
    }

    getDateDescription(key) {
        return this.dateparameters?.find(date => date.key === key).description;
    }

    loadConcept(conceptID: number) {
        this.conceptApiService.getConceptById(conceptID).then((concept: Concept) => {
            this.selectedConcept = concept;
            // security check for the current user
            if (this.currentUser.role === UserRole.Global || this.currentUser.role === UserRole.Concept) {
                this.getStoreGroups(concept);
                this.getTags(concept);
            }
            this.customAllStoresName = this.captions.allStores + '(' + concept.displayName + ')';
            this.conceptDataLoaded = true;
        });
    }

    openConceptPickerPopup() {
        this.conceptApiService.getAllActiveConcepts().then((concepts) => {
            this.concepts = concepts;
            this.showConceptPickerPopup = true;
        });
    }

    selectConceptBox(selectedConcept) {
        this.selectedConcept = selectedConcept;
        this.conceptDataLoaded = true;
        this.setConceptWithCurrency(selectedConcept);
        this.dashboardEmitterService.dashboardConceptChanged(selectedConcept);
        this.customAllStoresName = this.captions.allStores + '(' + selectedConcept.displayName + ')';
        this.showSelectConceptBox = false;
        this.resetStoreSelection();
        //security check
        if (this.currentUser.role === this.USER_ACCESS.GLOBAL || this.currentUser.role === this.USER_ACCESS.CONCEPT)
        {
            this.getStoreGroups(selectedConcept);
            this.getTags(selectedConcept);
        }
    }

    openStorePickerPopup() {
        if (this.showSelectConceptBox) {
            this.snackbarService.errorMessageTop(this.captions.pleaseSelectOrganization);
        } else {
            let storeList = this.widget.parameters[5].value.split(',');
            this.getStoresForConcept(this.selectedConcept.id).then((stores: any) => {
                this.stores = stores;
                this.hideAllStoresBox = stores.length == 0 ? true : false;
                this.showStorePickerPopup = true;
                if (this.widget.parameters[5].value) {
                    for (let store of stores) {
                        for (let widgetStoreId of storeList) {
                            if (store.id == widgetStoreId) {
                                store.isChecked = true;
                            }
                        }
                    }
                }
            });
        }
    }

    toggleAllStores() {
        let toggleOff = this.stores[0].isChecked ? true : false;
        this.storeIds = [];
        for (let store of this.stores) {
            if (toggleOff) {
                store.isChecked = false;
            } else {                
                this.storeIds = this.storeIds.concat(store.id.toString());
                store.isChecked = true;
                this.showSelectStoresBox = false;
            }
        }
    }
    selectStoreBox(store) {
        if (!store.isChecked) {
            store.isChecked = true;
            this.showSelectStoresBox = false;
            this.storeIds = this.storeIds.concat(store.id.toString());
        } else {
            store.isChecked = false;
            for (let i = 0; i < this.storeIds.length; i++) {
                if (this.storeIds[i] == store.id) {
                    this.storeIds.splice(i, 1);
                }
            }
        }
    }
    selectDashboardStores() {
        if (this.storeIds.length === 0) {
            this.snackbarService.errorMessageTop(this.captions.pickStoreForReportHint);
        } else {
            this.widget.parameters[5].value = this.storeIds.toString();
            this.widget.storesSelected = this.storeIds.length;
            this.showStorePickerPopup = false;
            this.customStoreCaption = this.storeIds.length + ' ' + this.captions.storesSelected;
        }
    }
    resetStoreSelection() {
        if (this.stores) {
            for (let store of this.stores) {
                store.isChecked = false;
            }
        }
        this.stores = [];
        this.storeIds = [];
        this.widget.parameters[5].value = '';
        this.showSelectStoresBox = true;
        this.widget.storesTotal = 0;
    }
    getStoresForConcept(conceptID) {
        return this.storeApiService.getStoresByConceptId(conceptID).then((stores: any) => {
            this.widget.storesTotal = stores.length;
            return stores;
        });
    }
    setConceptWithCurrency(concept) {
        this.widget.parameters[0].value = concept.id;
        this.widget.parameters[4].value = concept.currencyCode;
    }
    cancelChanges(concepts) {
        if (!concepts && typeof this.stores !== 'undefined') {
            for (let store of this.stores) {
                store.isChecked = false;
            }
            this.stores = [];
            this.showStorePickerPopup = false;
        } else if (concepts && typeof this.concepts !== 'undefined') {
            this.showConceptPickerPopup = false;
        }
    }
    cancel() {
        this.dialogRef.close();
    }

    submit(): void {
        this.groupingID = this.selectedGroupID != 0 ? 
            this.selectedGroupID : this.selectedTagID != 0 ? 
                this.selectedTagID : 0;

        this.widget.conceptID = parseInt(this.widget.parameters[0].value);

        // check if organization has been selected
        if (!this.widget.conceptID) {
            // complain that organization hasn't been selected
            this.snackbarService.errorMessageTop(this.captions.pleaseSelectOrganization);
        }
        // check if store has been selected
        else if (this.widget.parameters[5].value === '') {
            // complain that store hasn't been selected
            this.snackbarService.errorMessageTop(this.captions.pickStoreForReportHint);
        }
        // handle creating widget
        else {
            this.widget.parameters[7].value = this.groupingID.toString();
            if (!this.widget.storesTotal) {
                this.getStoresForConcept(this.widget.conceptID);
            }
            if (this.widget.storesSelected === 0) {
                this.widget.storesSelected = this.widget.parameters[5].value.split(',').length;
            }
            if (this.data.isWidgetInstance) {

                let updateWidgetInfo = {
                    ...this.widget,
                    ...{
                        id: this.data.selectedWidgetId,
                        groupingID: ''
                    },
                };

                this.dashboardApiService.updateWidgetInstance(this.widget, this.data.selectedWidgetId, this.dataSource).then(
                    () => {
                        if (this.groupingID && this.groupingID != 0) {
                            updateWidgetInfo.groupingID = this.widget.parameters[7].value;
                        }
                        this.dashboardEmitterService.widgetUpdated(updateWidgetInfo);
                        this.cancel();
                    },
                    () => {
                        this.cancel();
                    }
                );
            } else {
                let updateWidgetInfo = {
                    ...this.widget,
                    ...{
                        templateID: this.data.selectedWidgetId,
                        dashboardTabID: this.itemStorageService.tabId,
                        dataSource: this.dataSource
                    },
                };
                this.dashboardApiService.addWidget(updateWidgetInfo, this.dataSource).then((widgetInstance: WidgetInstaceGet) => {
                        if (this.groupingID && this.groupingID != 0) {
                            widgetInstance.groupingID = parseInt(this.widget.parameters[7].value);
                        }
                        this.dashboardEmitterService.widgetAdded(widgetInstance);
                        this.cancel();
                    },
                    () => {
                        this.cancel();
                    }
                );
            }
        }
    }

}
