import { Component, Inject, OnInit } from "@angular/core";
import { CaptionService } from "../../../utils/shared-services/caption.service";
import { PriceChangeStoreSelectionDialog } from "./dialogs/store-selection.component";
import { MatDialog } from "@angular/material/dialog";
import { StoreApiService } from "../../stores/services/store.resource";
import { ActivatedRoute, Router } from "@angular/router";
import { StoreBase } from "../../stores/models/store.model";
import { ReportCategorySelectionDialogData, StoreSelectionDialogData } from "../models/dialog.model";
import { PriceChangeService } from "../services/price-change.resource";
import { ReportCategory } from "../models/price-change.model";
import { PriceChangeReportCategorySelectionDialog } from "./dialogs/report-category-selection.component";


@Component({
    selector: "app-price-match",
    templateUrl: "./src/app/features/price-change/templates/price-match.component.html",
})
export class PriceMatchComponent implements OnInit {
    public captions: Record<string, string>;
    public stores: StoreBase[];
    public selectedStoresTo;
    public selectedStoreFrom;
    public showStoresLoading: Boolean = false;
    public storeButtonDisabled: Boolean = false;
    private conceptID: number;
    private reportCategories: ReportCategory[];
    private categorySelectedBy: "Descript" | "PLink";
    public selectedCategories: ReportCategory[];
    public numCategoryGroupsSelected: number;



    constructor(@Inject(CaptionService) private captionService: CaptionService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(PriceChangeService) private priceChangeService: PriceChangeService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(MatDialog) private dialog: MatDialog) {
        this.captions = this.captionService.captions;
        this.stores = [];
        this.selectedStoresTo = [];
        this.selectedStoreFrom = [];
    }

    ngOnInit(): void {
        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['pathId'];
        });

        if (this.stores.length <= 0) {
            this.storeApiService.getStoresByConceptId(this.conceptID).then((stores: StoreBase[]) => {
                this.stores = stores;
            });
        }
    }

    openStoreSelection(): void {
        const dialogRef = this.dialog.open(PriceChangeStoreSelectionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                stores: this.stores,
                isPriceMatch: true,
                storeGroups: [],
                tags: []
            },
        });

        dialogRef.afterClosed().subscribe((result: StoreSelectionDialogData) => {
            if (result) {
                // Set store to and from selections
                this.selectedStoresTo = result.selectedStores;
                this.selectedStoreFrom = result.selectedStoreFrom;

                // report cats 
                let storeIDs = this.selectedStoreFrom.map(store => store.id);
                this.priceChangeService.getReportCategories(storeIDs).then(reportCats => {
                    this.reportCategories = reportCats;
                });
                this.categorySelectedBy = null;
                this.numCategoryGroupsSelected = 0;
                this.selectedCategories = [];
            }
        });
    }

    openReportCategorySelection(): void {
        const dialogRef = this.dialog.open(PriceChangeReportCategorySelectionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                selectedStores: this.selectedStoreFrom,
                reportCategories: this.reportCategories,
                selectedBy: this.categorySelectedBy,
                maxSelection: 1000, //high value since price match doesn't have max
                numSelected: this.numCategoryGroupsSelected,
                isPriceMatch: true
            },
        });

        dialogRef.afterClosed().subscribe((result: ReportCategorySelectionDialogData) => {
            if (result) {
                // Set report category selection
                this.categorySelectedBy = result.selectedBy;
                this.numCategoryGroupsSelected = result.numSelected;
                this.reportCategories = result.reportCategories;
                this.selectedCategories = result.reportCategories.filter(rc => {
                    return rc.isSelected;
                });

                // Persist report category selection
                history.replaceState({
                    ...history.state,
                    categorySelectedBy: this.categorySelectedBy,
                    numCategoryGroupsSelected: this.numCategoryGroupsSelected,
                    reportCategories: this.reportCategories,
                    selectedCategories: this.selectedCategories,
                }, null);

                    
            }
        });
    }

    submitPriceMatch(){
            this.showStoresLoading = true;
                this.storeButtonDisabled = true;
                for (let selectedStoreTo of this.selectedStoresTo) {
                    selectedStoreTo.status = 'LOADING';
                    this.priceChangeService.priceMatch(this.selectedStoreFrom[0].id, selectedStoreTo.id, this.selectedCategories).then((response) => {
                        if (response.Status == 'success') {
                            selectedStoreTo.status = 'SUCCESS';
                            selectedStoreTo.numOfProd = response.numOfProd;
                        } else {
                            selectedStoreTo.status = 'FAILED';
                        }
                    })
                }
    }





    goBack(){
        this.router.navigate(['concepts/allConcepts']);
    }
}