import { ICellRendererComp, ICellRendererParams } from 'ag-grid-community';

export class IconCellRenderer implements ICellRendererComp {

    spanStore: HTMLElement = document.createElement('span');
    spanProducts: HTMLElement = document.createElement('span');

    init(params: ICellRendererParams) {

        // split store and product count if exists 
        const parts = params.value.split(/(?<=\s)/);

        // build cell info with store icon and value
        this.spanStore.innerHTML= `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#0e4e79"><path d="M160-720v-80h640v80H160Zm0 560v-240h-40v-80l40-200h640l40 200v80h-40v240h-80v-240H560v240H160Zm80-80h240v-160H240v160Zm-38-240h556-556Zm0 0h556l-24-120H226l-24 120Z"/></svg>`;
        this.spanStore.classList.add('flex');
        this.spanStore.classList.add('items-center');
        const textSpan = document.createElement('span');
        textSpan.textContent = parts[0];
        this.spanStore.appendChild(textSpan);


        // if second value exists, build product icon and value
        if (parts.length > 1) {
            const textSpan2 = document.createElement('span');
            this.spanProducts.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#0e4e79"><path d="M160-120q-33 0-56.5-23.5T80-200v-120h800v120q0 33-23.5 56.5T800-120H160Zm0-120v40h640v-40H160Zm320-180q-36 0-57 20t-77 20q-56 0-76-20t-56-20q-36 0-57 20t-77 20v-80q36 0 57-20t77-20q56 0 76 20t56 20q36 0 57-20t77-20q56 0 77 20t57 20q36 0 56-20t76-20q56 0 79 20t55 20v80q-56 0-75-20t-55-20q-36 0-58 20t-78 20q-56 0-77-20t-57-20ZM80-560v-40q0-115 108.5-177.5T480-840q183 0 291.5 62.5T880-600v40H80Zm400-200q-124 0-207.5 31T166-640h628q-23-58-106.5-89T480-760Zm0 520Zm0-400Z"/></svg>'
            textSpan2.textContent = parts[1];
            this.spanProducts.classList.add('flex');
            this.spanProducts.classList.add('items-center');
            this.spanProducts.classList.add('pl-14');

            this.spanProducts.appendChild(textSpan2);
            this.spanStore.appendChild(this.spanProducts);
        }

    }

    getGui() {
        return this.spanStore;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}