import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../../app.constants';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { ConceptApiService } from '../services/concept.resource';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Concept } from '../models/concept.model';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'editConcept',
    templateUrl: 'src/app/features/concepts/templates/concept.edit.html',
})
export class EditConceptComponent implements OnInit {

    private passedConceptId: number;
    private hasData: boolean = true;
    private formBuilder: FormBuilder = new FormBuilder();

    protected _onDestroy = new Subject<void>();

    public form = this.formBuilder.group({
        displayName: ['', [Validators.required, Validators.maxLength(this.validationService.conceptNameMaxLength)]],
        currencyCode: ['', [Validators.required]],
        localTimeZone: ['', [Validators.required]],
        dayOfWeek: ['', [Validators.required]],
    });

    public webBgColor: string;
    public timeZones: string[];
    public currencyCode: string;
    public concept: Concept;
    public filteredTimeZones: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
    public timeZonesCtrl: FormControl<string> = new FormControl<string>('');

    @Input() captions: Record<string, string>;
    @Input() currencyCodes: string[];

    constructor(
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.currencyCodes = AppConstants.CURRENCYCODES_NG;
        this.getTimeZones();
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe(params => {
            this.passedConceptId = +params['pathId'];
        });
        this.conceptApiService.hasData(this.passedConceptId).then((data: boolean) => {
            this.hasData = data !== null;
        });

        this.conceptApiService.getConceptById(this.passedConceptId).then((concept: Concept) => {
            this.concept = concept;
            this.webBgColor = this.concept.webBgColor;
            if (this.concept) {
                this.form.setValue({
                    displayName: this.concept.displayName,
                    currencyCode: this.concept.currencyCode,
                    localTimeZone: !this.concept.localTimeZone || this.concept.localTimeZone === '' ? 'UTC' : this.concept.localTimeZone,
                    dayOfWeek: this.concept.dayOfWeek.toString(),
                });
            }
        });

        // listen for search field value changes
        this.timeZonesCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterTimezones();
            });
    }

    updateConcept(): void {
        const values = this.form.value;
        for (const property in values) {
            this.concept[property] = values[property];
        }

        let trigger = false;
        this.conceptApiService.getAllActiveConcepts().then((concepts: Concept[]) => {
            for (let concept of concepts) {
                // displayName.toUpperCase is used in order to ignore case and any special Unicode characters
                // concept.id is being checked to exclude the concept that that is being modifying
                if (concept.displayName.toUpperCase() === this.concept.displayName.toUpperCase() && this.concept.id != concept.id) {
                    trigger = true;
                    this.snackbarService.errorMessageTop(this.captions.duplicateConceptName);
                    return;
                }
            }
            if (!trigger) {
                this.conceptApiService.updateConcept(this.concept).then(() => {
                    this.router.navigate(['concepts/allConcepts']);
                });
            }
        });
    }

    colorSelected(color: string): void {
        this.concept.webBgColor = color;
    }

    cancelChanges(): void {
        this.router.navigate(['concepts/allConcepts']);
    }

    protected filterTimezones(): void {
        if (!this.timeZones) {
            return;
        }
        // get the search keyword
        let search = this.timeZonesCtrl.value;
        if (!search) {
            this.filteredTimeZones.next(this.timeZones.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the time-zones
        this.filteredTimeZones.next(
            this.timeZones.filter(zone => zone.toLowerCase().indexOf(search) > -1)
        );
    }

    private getTimeZones(): void {
        this.conceptApiService.getTimeZones().then((result: string[]) => {
            this.timeZones = result;
            this.filteredTimeZones.next(this.timeZones.slice());
        });
    }
}
