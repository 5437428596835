import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from '../../utils/shared-services/validation.service';
import { AuthService } from '../services/auth.resource';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'eoiVendorRequest',
    templateUrl: 'src/app/auth/templates/eoi-vendor-request.html',
})
export class EoiVendorRequestComponent {
    public captions;
    form: FormGroup;
    ipAddress: string = '';
    private fb: FormBuilder = new FormBuilder();
    private currentDate: Date = new Date();
    mobileViewActive: boolean;
    showSidebar: boolean;
    showRequestForm: boolean = true;
    showResources: boolean;
    private fileUrl = 'https://pixelpointhqdocuments.s3.us-east-1.amazonaws.com/Collections/Realtime_data_extraction.json';


    constructor(@Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(HttpClient) private http: HttpClient,
        @Inject(ValidationService) public validationService: ValidationService,
        @Inject(AuthService) public authService: AuthService,
        @Inject(Router) private router: Router,
        @Inject(MatSnackBar) private snackBar: MatSnackBar
    ) {
        this.form = this.fb.group({
            operatingName: [''],
            legalName: ['', Validators.required],
            primaryContact: ['', Validators.required],
            phoneNumber: ['', [Validators.required, Validators.pattern(/^\(?[0-9]{3}\)?[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/)]],
            email: ['', [Validators.required, Validators.pattern(this.validationService.getEmailRegex())]],
            address1: [''],
            address2: [''],
            city: [''],
            country: [''],
            region: [''],
            existingPixelVendor: ['No'],
            pixelClients: [0],
            website: [''],
            integrationType: ['']
        });
    }

    ngOnInit(): void {
        this.mobileViewActive = window.innerWidth < 1024;
        this.showSidebar = !this.mobileViewActive;
        window.onresize = () => {
            this.mobileViewActive = window.innerWidth < 1024;
            if (!this.mobileViewActive) {
                this.showSidebar = true;
            }
        };
        
        this.getIpAddress();

        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
    }

    toggleSidebarView(): void {
        this.showSidebar = !this.showSidebar;
    }

    toggleSelection(selected): void {
        if(selected == "request"){
            this.showRequestForm = true;
            this.showResources = false;
        }
        else if (selected == "resources"){
            this.showRequestForm = false;
            this.showResources = true;
        }
    }

    onSubmit(): void {
        if (this.form.valid) {
            const formData = {
                ...this.form.value,
                ipAddress: this.ipAddress,
                currentDate: this.currentDate
            };
            this.authService.submitEoiVendorRequestForm(formData).then(() => {
                this.router.navigate(['/login']);
                this.snackBar.open('Form submitted successfully!', 'Close', {
                    duration: 3000, // milliseconds
                    verticalPosition: 'top',
                    horizontalPosition: 'center'
                  });
            });
        }
    }

    getIpAddress(): void {
        this.http.get<{ ip: string }>('https://ipinfo.io/json')
            .subscribe({
                next: (res) => {
                    this.ipAddress = res.ip;
                },
                error: (error) => {
                }
            });

    }

    // Opens the file in a new browser tab
    openFile() {
        window.open(this.fileUrl, '_blank');
    }

    downloadFile() {
        fetch(this.fileUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch file');
                }
                return response.blob(); // Convert to Blob
            })
            .then((blob) => {
                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Realtime_data_extraction.json'; // File name for download
                link.click();

                // Clean up the temporary URL
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Download failed:', error);
            });
    }
}
