import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { InventoryCounterService } from '../services/inventory-counter.service';

@Component({
    selector: 'countsheet',
    templateUrl: 'src/app/features/inventory-counter/templates/countsheet.html',
})
export class CountsheetComponent implements OnInit, OnDestroy {
    public captions: Record<string,string>;
    public subscriptions: Subscription[] = [];
    public countLocation: any;
    public locations: any;
    public parsedItems: any[] = []; // all items parsed to array of formgroups
    public activeItems: any[] = []; // parsed items filtered for the current location
    public searchStr = '';
    public filters = [];
    public activeFilter: any;
    public form: UntypedFormGroup;
    public formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(InventoryCounterService) private inventoryCounterService: InventoryCounterService
    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
            this.filters = [
                { id: 1, name: this.captions.filterAll },
                { id: 2, name: this.captions.filterCounted },
                { id: 3, name: this.captions.filterNotCounted },
            ];
        });
        this.subscriptions.push(
            this.inventoryCounterService.countLocations$.subscribe((locations) => {
                this.locations = locations;
            })
        );
        this.subscriptions.push(
            this.inventoryCounterService.countItems$.subscribe((items) => {
                this.parseItemsToFormArray(items);
            })
        );
        this.subscriptions.push(
            this.inventoryCounterService.activeCountLocation$.subscribe((loc) => {
                this.countLocation = loc;
                this.activeFilter = 1;
                this.filterParsedItemsByLocation();
                this.setCountsheetItems();
            })
        );

        this.subscriptions.push(
            this.form.get('searchStr').valueChanges.subscribe((val) => {
                this.searchStr = val;
            })
        );
    }

    get currentItems(): UntypedFormArray {
        return this.form.get('currentItems') as UntypedFormArray;
    }

    ngOnInit(): void {}

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    initializeForm(): void {
        this.form = this.formBuilder.group({
            searchStr: new UntypedFormControl(this.form?.value?.searchStr ?? ''),
            currentItems: this.formBuilder.array([]),
        });
    }

    getFormGroupForItem(item: any): UntypedFormGroup {
        let savedItem = this.inventoryCounterService.getSavedItemDataFromLS(item);

        // if the value is saved in local storage, assign it to item
        if (savedItem) {
            item.counted = savedItem.formData.counted;
            item.isExpansionPanelOpen = savedItem.formData.isExpansionPanelOpen;
            item.UnitCount._text = savedItem.formData.unitCount;
            item.ContCount._text = savedItem.formData.contCount;
            item.PackCount._text = savedItem.formData.packCount;
        }

        let formGroup = this.formBuilder.group({
            LID: item._attributes.LID,
            intCountFreq: item.InvCountFreq._text,
            locId: item.LocId._text,
            counted: item.counted,
            isExpansionPanelOpen: item.isExpansionPanelOpen,
            itemDesc: new UntypedFormControl(item.InvDesc._text),
            locDesc: new UntypedFormControl(item.LocDesc._text),
            unitCount: new UntypedFormControl(item.UnitCount._text),
            unitDesc: new UntypedFormControl(item.UnitDes._text),
            contCount: new UntypedFormControl(item.ContCount._text),
            contDesc: new UntypedFormControl(item.ContDes._text),
            packCount: new UntypedFormControl(item.PackCount._text),
            packDesc: new UntypedFormControl(item.PackDes._text),
        });

        formGroup.valueChanges.subscribe((formValue) => {
            let isCounted =
                formValue.unitCount !== null || formValue.contCount !== null || formValue.packCount !== null;
            formGroup.get('counted').setValue(isCounted, { emitEvent: false });
            this.inventoryCounterService.itemCountUpdated(formGroup.value);
            this.inventoryCounterService.saveItemDataToLS(formGroup.value);
        });

        return formGroup;
    }

    parseItemsToFormArray(items): void {
        this.parsedItems = [];
        items.forEach((item) => {
            let formGroup = this.getFormGroupForItem(item);
            this.parsedItems.push(formGroup);
        });
    }

    filterParsedItemsByLocation(): void {
        this.activeItems = [];
        this.parsedItems
            .filter((item) => item.value.locId === this.countLocation)
            .forEach((item) => {
                this.activeItems.push(item);
            });
    }

    setCountsheetItems(filterOpen: boolean = false): void {
        if (filterOpen) {
            return;
        }
        this.initializeForm();
        this.activeItems
            .filter((item) => this.matchesFilter(item.value))
            .forEach((item) => {
                this.currentItems.push(item);
            });
    }

    onTogglePanel(formGroup): void {
        formGroup.get('isExpansionPanelOpen').setValue(!formGroup.value.isExpansionPanelOpen, { emitEvent: false });
        this.inventoryCounterService.saveItemDataToLS(formGroup.value);
    }

    matchesQuery(idx: string | number): any {
        return (
            this.searchStr === '' ||
            this.currentItems.value[idx].itemDesc.toLowerCase().includes(this.searchStr.toLowerCase())
        );
    }

    matchesFilter(item): boolean {
        if (this.activeFilter === 1) {
            return true;
        } else if (this.activeFilter === 2) {
            return item.counted;
        } else if (this.activeFilter === 3) {
            return !item.counted;
        }
        return true;
    }

    navigate(url: any, locId = null): void {
        if (locId) {
            this.router.navigate([url], { queryParams: { location: locId } });
        } else {
            this.router.navigate([url]);
        }
    }
}
