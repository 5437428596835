import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { interval } from 'rxjs';
import { saveFile } from 'wijmo/wijmo';
import * as wjcGrid from 'wijmo/wijmo.grid';
import { FlexGridFilter } from 'wijmo/wijmo.grid.filter';
import * as wjGridPdf from 'wijmo/wijmo.grid.pdf';
import * as wijmoOlap from 'wijmo/wijmo.olap';
import { AppSettings } from '../../../app-settings.service';
import { AppConstants } from '../../../app.constants';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { UserInfoHandler } from '../../users/services/user-info-handler.service';
import { ManageReportsData, SaveReportAsJobDialog, SaveReportAsTemplateDialog, ShowDiscardDialog } from '../internal';
import { ReportTemplatesDataService } from '../services/report-templates-data.resource';
import { ReportApiService } from '../services/reports.resource';
import { WijmoFormatterService } from '../services/wijmo-formatter.service';
import { SharedWizardData } from '../wizard/services/report-shared-wizard-data.service';
import { ShowRedirectDialog } from './dialogs/show-redirect-dialog.component';
import { json2csv } from 'json-2-csv';
import { UsageLoggerApiService } from '../../../utils/shared-services/usageLogger.service';

export interface ViewReportData extends ManageReportsData {
    pivotPanelGrid1: wijmoOlap.PivotPanel;
    pivotPanelGrid400: wijmoOlap.PivotPanel;
    pivotPanelChart1: wijmoOlap.PivotPanel;
    pivotPanelChart400: wijmoOlap.PivotPanel;
    pivotGrid1: wijmoOlap.PivotGrid;
    pivotGrid400: wijmoOlap.PivotGrid;
}


@Component({
    selector: 'viewReport',
    templateUrl: 'src/app/features/reports/templates/view-report.html',
})
export class ViewReportComponent implements OnInit {
    public REPORT_TECHNOLOGY_TYPES;
    private savedTemplate: any = null; // Stores the currently loaded template
    source$ = interval(1000);
    public captions;
    public currentTab: number;
    public checkJobCompletion;
    public interval;
    public isWijmo;
    public showControl;

    public conceptID;
    public conceptName;
    public reportJobID;
    public maxInterval;
    public isReady: boolean = false;
    public showGroupItemOV;

    public reportDescriptions;
    public templateDescription;
    public flatReportTemplate;
    public selectedVisualization;
    public loadedJobInfo;
    public currencyTypeCaption;
    public currencyIcon;
    public storesOV;
    public storeOverflows;
    public storeHasOverflow;
    public groupItemsOV;
    public groupItemOverflows;
    public groupItemHasOverflow;
    public showTagOV;
    public tagsOV;
    public tagOverflows;
    public tagHasOverflow;
    public theEngine: wijmoOlap.PivotEngine;
    public jobDataSet: any;
    public info: any;
    public accessToken: any;
    public gridToExport;
    innitialized: boolean = false;
    subscription: any;
    pivotGrid1: wijmoOlap.PivotGrid;
    pivotGrid400: wijmoOlap.PivotGrid;
    pivotPanelGrid1: wijmoOlap.PivotPanel;
    pivotPanelGrid400: wijmoOlap.PivotPanel;
    pivotPanelChart1: wijmoOlap.PivotPanel;
    pivotPanelChart400: wijmoOlap.PivotPanel;
    public flatcsvdata: any;
    public flatCSVUrl: any;
    public flatCsvName: any;
    public sanitizedURL: any;

    constructor(
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(SharedWizardData) public sharedWizardData: SharedWizardData,
        @Inject(CaptionService) public captionService: CaptionService,
        @Inject(WijmoFormatterService) public wijmoFormatter: WijmoFormatterService,
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(DomSanitizer) public sanitize: DomSanitizer,
        @Inject(Router) public router: Router,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
        @Inject(ReportTemplatesDataService) public reportTemplatesDataService: ReportTemplatesDataService,
        @Inject(AppSettings) public appSettings: AppSettings,
        @Inject(UtilsApiService) public utilsApiService: UtilsApiService,
        @Inject(UserInfoHandler) public userInfoHandler: UserInfoHandler,
        @Inject(HttpClient) public httpClient: HttpClient,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer,
        @Inject(UsageLoggerApiService) public usageLogger: UsageLoggerApiService
    ) {
        this.REPORT_TECHNOLOGY_TYPES = AppConstants.REPORT_TECHNOLOGY_TYPES;
        this.captions = this.captionService.captions;
        this.currentTab = 0;
        this.isWijmo = false;
        this.showControl = false;
        this.maxInterval = 300;
        this.isReady = false;
        this.showGroupItemOV = false;

        // Initialize jobDataSet with a truthy value.
        // "No Data" should only show up in the report if jobDataSet is 
        // explicitly set to nothing
        this.jobDataSet = true;
    }

    ngOnInit() {
        // pathname is displayName, pathId is conceptID, jobID is jobID
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.conceptID = +params['pathId'];
            this.reportJobID = +params['jobID'];
            this.conceptName = params['pathName'];
            this.accessToken = params['accessToken'];
        });
        this.actRoute.queryParams.subscribe((params) => {
            this.isReady = params['isReady'];
        });

        this.subscription = this.source$.subscribe((interval) => {
            if (!this.isReady && !this.accessToken) {
                this.getJobCompletionInfo();
                if (interval < this.maxInterval) {
                    interval += 1;
                }
            } else {
                this.getJobInfo();
                this.destroyIntervalCheck();
            }
        });
    }

    ngOnDestroy() {
        this.destroyIntervalCheck();
    }

    destroyIntervalCheck() {
        this.subscription.unsubscribe();
    }

    getJobCompletionInfo() {
        this.reportApiService.getJobCompletionInfo(this.conceptID, this.reportJobID).then(
            (response) => {
                let result = response;
                if (result['status'] == 'Done') {
                    this.isReady = true;
                } else if (result['status'] == 'Pending') {
                } else if (result['status'] == 'Error') {
                    this.destroyIntervalCheck();

                    this.router.navigate(['reports/manageReports', this.conceptID, this.conceptName]);
                }
            },
            (error) => {
                if (error.status == 404) {
                    this.destroyIntervalCheck();
                    this.router.navigate(['404']);
                }
            }
        );
    }

    getJobInfo() {
        this.accessToken
            ? this.reportApiService.getScheduledJobInfo(this.accessToken, this.conceptID).then(
                (response) => this.getJobInfoSuccess(response),
                (error) => {
                    if (error.status == 404) {
                        this.destroyIntervalCheck();
                        this.router.navigate(['404']);
                    }
                }
            )
            : this.reportApiService.getJobInfo(this.conceptID, this.reportJobID).then(
                (response) => this.getJobInfoSuccess(response),
                (error) => {
                    if (error.status == 404) {
                        this.destroyIntervalCheck();
                        this.router.navigate(['404']);
                    }
                }
            );
    }

    getJobInfoSuccess(response) {
        let isWijmo: boolean;
        this.info = response;
        this.loadedJobInfo = true;
        if (this.info['info'].isCommonCurrency === true) this.currencyTypeCaption = this.captions.conceptCurrency;
        else this.currencyTypeCaption = this.captions.storeCurrency;

        let setCurrencyIconTitle = (currency) => {
            return currency == 'CAD' ? 'currency-usd' : 'currency-' + currency.toLowerCase();
        };
        this.currencyIcon = setCurrencyIconTitle(this.info['info'].currency);
        this._setOverviewStoreNames();
        this._setOverviewGroupItemNames();
        this._setOverviewTagNames();

        if (this.info.id === 10111) {
            for (let i = 0; i < this.info.parameters.length; i++) {
                if (this.info.parameters[i].id === 1002) this.info.parameters.splice(i, 1);
                if (this.info.parameters[i].id === 1001) this.info.parameters[i].description = this.captions.weekEnding;
            }
        }
        for (let visualization of this.info.visualizations) {
            if (visualization.technology.kind === this.REPORT_TECHNOLOGY_TYPES.FLAT_REPORT) {
                visualization.httpLinkUrl = this.sanitize.bypassSecurityTrustUrl(
                    this.reportApiService.getLinkForFlatReportHTML(this.conceptID, this.reportJobID, visualization.id)
                );

                this.httpClient
                    .get(visualization.httpLinkUrl.changingThisBreaksApplicationSecurity, { responseType: 'text' })
                    .subscribe((reportTemplate) => {
                        this.flatReportTemplate = reportTemplate;
                        this.setIFrameContent('#flatReport', this.flatReportTemplate);
                    });
                // get flat report dataset
                this.getFlatCSVData(this.info.visualizations);
            } else if (visualization.technology.kind === this.REPORT_TECHNOLOGY_TYPES.WIJMO_OLAP) {
                visualization.selectedView = visualization.technology.views[0].type;
                isWijmo = true;
            }
        }

        if (isWijmo) {
            this.getJobData(this.info.visualizations);
        }

        if (this.info.visualizations[0]) {
            this.refreshTab(0);
        }

        this.wijmoFormatter.setUpControlCaptionsForWijmo();
        if (!this.accessToken) this.getReportTemplateById(this.info.info.templateID);
    }

    getJobData(visualizations) {
        for (let visualization of visualizations) {
            this.accessToken
                ? this.reportApiService
                    .getScheduledJobDataSet(this.conceptID, this.accessToken, visualization.id)
                    .then((jobDataSet) => this.initDataSet(jobDataSet, visualization))
                : this.reportApiService
                    .getJobDataSet(this.conceptID, this.reportJobID, visualization.id)
                    .then((jobDataSet) => this.initDataSet(jobDataSet, visualization));
        }
    }

    getFlatCSVData(visualizations) {
        for (let visualization of visualizations) {
            this.reportApiService
                .getJobDataSet(this.conceptID, this.reportJobID, visualization.id)
                .then(async (jobData: any) => {
                    this.flatcsvdata = await json2csv(jobData);
                });
        }
        this.flatCsvName = this.info.info.jobDisplayName;
    }

    exportFlatCSV() {
        const blob = new Blob([this.flatcsvdata], { type: 'text' });
        this.flatCSVUrl = window.URL.createObjectURL(blob);
        this.sanitizedURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.flatCSVUrl);

        let jobName = this.info.info.jobDisplayName;
        let jobID = this.info.info.jobID;
        let msg = `User downloaded "${jobName}" report as CSV [job ${jobID}] [concept ${this.conceptID}]`;
        this.usageLogger.recordUsageLog("DOWNLOAD_REPORT", 1, msg)
    }

    initDataSet(jobDataSet, visualization) {
        this.jobDataSet = jobDataSet;
        this.setupWijmo(jobDataSet, visualization);
    }

    showLoginRedirectDialog() {
        this.dialog.open(ShowRedirectDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                captions: this.captions,
                redirectTo: '/login',
                header: this.captions.unauthorized,
                body: this.captions.redirectToLogin,
                confirm: this.captions.login,
            },
        });
    }

    showDiscardDialog(ev) {
        if (this.accessToken) {
            this.showLoginRedirectDialog();
            return;
        }

        this.dialog.open(ShowDiscardDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                captions: this.captions,
                conceptID: this.conceptID,
                conceptName: this.conceptName,
                reportJobID: this.reportJobID,
            },
        });
    }

    saveReportAsTemplate(ev) {
        if (this.accessToken) {
            this.showLoginRedirectDialog();
            return;
        }

        this.dialog.open(SaveReportAsTemplateDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                captions: this.captions,
                info: this.info,
                conceptID: this.conceptID,
                pivotPanelGrid1: this.pivotPanelGrid1,
                pivotPanelGrid400: this.pivotPanelGrid400,
                pivotPanelChart1: this.pivotPanelChart1,
                pivotPanelChart400: this.pivotPanelChart400,
                pivotGrid1: this.pivotGrid1,
                pivotGrid400: this.pivotGrid400
            },
        });
    }

    saveReportAsJob(ev) {
        if (this.accessToken) {
            this.showLoginRedirectDialog();
            return;
        }

        this.dialog.open(SaveReportAsJobDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                captions: this.captions,
                info: this.info,
                conceptID: this.conceptID,
            },
        });
    }

    exportCSV(ev) {
        let savedReportName = this.info.info.jobDisplayName + '-' + this.yyyymmdd() + '.csv';
        let grid = this.gridToExport,
            rng = new wjcGrid.CellRange(0, 0, grid.rows.length - 1, grid.columns.length - 1),
            csv = grid.getClipString(rng, true, true, true); // save CSV with column and row headers
        saveFile(csv, savedReportName);

        let jobName = this.info.info.jobDisplayName;
        let jobID = this.info.info.jobID;
        let msg = `User downloaded "${jobName}" report as CSV [job ${jobID}] [concept ${this.conceptID}]`;
        this.usageLogger.recordUsageLog("DOWNLOAD_REPORT", 1, msg)
    }

    exportPDF(ev) {
        let savedReportName = this.info.info.jobDisplayName + '-' + this.yyyymmdd() + '.pdf';
        wjGridPdf.FlexGridPdfConverter.export(this.gridToExport, savedReportName, {
            scaleMode: wjGridPdf.ScaleMode.PageWidth,
            documentOptions: {
                compress: true,
                header: { declarative: { text: '\t&[Page] of &[Pages]' } },
                footer: { declarative: { text: '\t&[Page] of &[Pages]' } },
                info: { author: 'PixelPointHQ', title: this.info.info.jobDisplayName },
            },
            styles: {
                cellStyle: { backgroundColor: '#ffffff', borderColor: '#c6c6c6' },
                altCellStyle: { backgroundColor: '#f9f9f9' },
                groupCellStyle: { backgroundColor: '#dddddd' },
                headerCellStyle: { backgroundColor: '#eaeaea' },
            },
        });

        let jobName = this.info.info.jobDisplayName;
        let jobID = this.info.info.jobID;
        let msg = `User downloaded "${jobName}" report as PDF [job ${jobID}] [concept ${this.conceptID}]`;
        this.usageLogger.recordUsageLog("DOWNLOAD_REPORT", 1, msg)
    }

    refresh(id, type) {
        for (let i = 0; i < this.info.visualizations.length; i++) {
            for (let j = 0; j < this.info.visualizations[i].technology.views.length; j++) {
                if (
                    this.info.visualizations[i].id == id &&
                    this.info.visualizations[i].technology.views[j].type == type
                ) {
                    this.refreshView();
                    return;
                }
            }
        }
    }

    refreshView() {
        let engine = this.theEngine;
        if (typeof engine != 'undefined') {
            setTimeout(function () {
                wijmoOlap.PivotPanel.invalidateAll();
                engine.refresh();
            }, 1);
        }
    }

    //Open configuration panel
    openThePanel() {
        let visual = this.info.visualizations[this.currentTab];
        this.refresh(visual.id, visual.selectedView);
    }

    initWijmo(engine, view, theGrid) {
        if (engine) {
            if (theGrid.columns) {
                theGrid.columns.defaultSize = 110;
            }
            if (theGrid.autoSizeColumns) {
                engine.refresh();
            }
            if (view.type == 'pivotgrid') {
                for (let field of engine.fields) {
                    field.width = 120;
                }
                engine.fields[0].width = 150;
            }
            this.wijmoFormatter.setMeasuresColor(engine);

            //This functionality is to hide the dropdown options for moving the field into the wrong area.
            let panelElements = document.getElementsByClassName('report__wijmo-panel');
            document.oncontextmenu = () => false;
            for (let i = 0; i < panelElements.length; i++) {
                (<HTMLElement>panelElements[i]).oncontextmenu = () => false;
                panelElements[i].addEventListener('contextmenu', this.removeDropdownOptions, false);
            }
        }
    }

    removeDropdownOptions(event) {
        if (event.button == 2) {
            let contextmenu = document.getElementsByClassName('wj-dropdown-panel');
            let dropdownitems = contextmenu[0].getElementsByClassName('wj-listbox-item');
            for (let i = 0; i < dropdownitems.length; i++) {
                let item = <HTMLElement>dropdownitems[i];
                //sets display of list options in dropdown to none
                if (
                    item.innerText.includes(this.captions.wijmoMoveToReportFilter) ||
                    item.innerText.includes(this.captions.wijmoMoveToRowLabel) ||
                    item.innerText.includes(this.captions.wijmoMoveToColumnLabel) ||
                    item.innerText.includes(this.captions.wijmoMoveToValue)
                ) {
                    item.style.setProperty('display', 'none');
                }
            }
        }
    }

    setIFrameContent(id, contentHTML) {
        let iframe = $(id),
            contents = iframe.contents(),
            body = contents.find('body'),
            head = contents.find('head');
        if (!this.accessToken && this.userInfoHandler.getUserInfo().theme === AppConstants.THEMES.dark) {
            head.append($('<link/>', { rel: 'stylesheet', href: 'src/css/iframe-dark.css', type: 'text/css' }));
        }
        body.html(contentHTML);
    }

    refreshTab(i) {
        if (i >= this.info.visualizations.length) {
            if (i === this.info.visualizations.length + 1) {
                setTimeout(() => this.setIFrameContent('#reportDesc', this.templateDescription), 150);
            }
            return;
        }
        setTimeout(() => this.setIFrameContent('#flatReport', this.flatReportTemplate), 150);
        this.currentTab = i;
        if (i == 1 && !this.innitialized) {
            this.setupWijmo(this.jobDataSet, this.info.visualizations[i]);
            this.innitialized = true;
        }
        let view = this.info.visualizations[i];
        this.selectedVisualization = this.info.visualizations[i].id;
        this.isWijmo = false;
        if (view.technology.kind === this.REPORT_TECHNOLOGY_TYPES.WIJMO_OLAP) {
            this.isWijmo = true;
            wijmoOlap.PivotPanel.invalidateAll();
            wijmoOlap.PivotGrid.invalidateAll();
        }
    }

    // not being used yet, need a work around with the new engine
    formatItem(s, e) {
        this.wijmoFormatter.formatItem(s, e);
    }
    _setOverviewStoreNames() {
        let storeNames = '';
        let storeIds = '';
        for (let i = 0; i < this.info.parameters.length; i++) {
            if (this.info.parameters[i].id === 2001) {
                storeNames = this.info.parameters[i].displayValue.split(',');
                storeIds = this.info.parameters[i].value.split(',');
            }
        }
        this.storesOV = {};
        this.storesOV.values = [];
        for (let i = 0; i < storeNames.length; i++) {
            this.storesOV.values[i] = { name: storeNames[i], id: storeIds[i] };
        }
        this.storeOverflows = storeNames.length > 3;
        this.storeHasOverflow = storeNames.length > 3;
    }
    _setOverviewGroupItemNames() {
        let groupItemDisplayNames = this.info.info.groupItemDisplayNames;
        let groupItemNames = '';
        if (typeof groupItemDisplayNames !== 'undefined' && groupItemDisplayNames !== '') {
            this.showGroupItemOV = true;
            groupItemNames = groupItemDisplayNames.split(',');
            this.groupItemsOV = {};
            this.groupItemsOV.values = [];
            for (let i = 0; i < groupItemNames.length; i++) {
                this.storesOV.values[i] = { name: groupItemNames[i] };
            }
            this.groupItemOverflows = groupItemNames.length > 3;
            this.groupItemHasOverflow = groupItemNames.length > 3;
        }
    }
    _setOverviewTagNames() {
        let tagDisplayNames = this.info.info.tagDisplayNames;
        let tagNames = '';
        if (typeof tagDisplayNames !== 'undefined' && tagDisplayNames !== '') {
            this.showTagOV = true;
            tagNames = tagDisplayNames.split(',');
            this.tagsOV = {};
            this.tagsOV.values = [];
            for (let i = 0; i < tagNames.length; i++) {
                this.storesOV.values[i] = { name: tagNames[i] };
            }
            this.tagOverflows = tagNames.length > 3;
            this.tagHasOverflow = tagNames.length > 3;
        }
    }

    setupWijmo(jobDataSet, visualization) {
        if (visualization.id === 1 && this.currentTab == 0) this.prepareVisualization(visualization, jobDataSet);
        else if (visualization.id === 400 && this.currentTab == 1) this.prepareVisualization(visualization, jobDataSet);
    }

    prepareVisualization(visualization, jobDataSet) {
        for (let view of visualization.technology.views) {
            let obj = JSON.parse(view.definition, (key, value) => {
                if (typeof value === "string" && value.startsWith("[code]")) {
                    // Convert function source code back to a function
                    return eval(value.substring(7));
                }
                return value;
            });
            this.initEngine(obj, jobDataSet, visualization, view, view.type);
        }
    }

    initEngine(obj, jobDataSet, visualization, view, dataType) {
        let valueFields = obj.valueFields.items,
            rowFields = obj.rowFields.items,
            columnFields = obj.columnFields.items,
            fields = obj.fields;

        if (jobDataSet.length > 0) {
            this.theEngine = new wijmoOlap.PivotEngine({
                autoGenerateFields: false,
                fields: fields,
                itemsSource: jobDataSet,
                showRowTotals: 'Subtotals',
                showColumnTotals: 'None',
                rowFields: rowFields,
                valueFields: valueFields,
                columnFields: columnFields,
            });
        }
        if (dataType === 'pivotgrid') this.initPivotGrid(visualization, view);
        else this.initPivotChart(visualization, view);
    }

    initPivotGrid(visualization, view) {
        let gridID = '#theGrid' + visualization.id,
            theGrid = new wijmoOlap.PivotGrid(gridID, {
                itemsSource: this.theEngine,
                allowResizing: 'Both',
                showSelectedHeaders: 'All',
            });

        // Apply saved column widths from the template
        this.applyColumnWidths(visualization, theGrid);
        this.initPivotPanelGrid(visualization, view, theGrid);
        this.gridToExport = theGrid;
    }

    private applyColumnWidths(visualization: any, pivotGrid: any) {
        if (!visualization || !visualization.columnWidths) return;
    
        let columnWidths: number[];
    
        try {
            columnWidths = visualization.columnWidths.split(",").map(num => parseInt(num.trim(), 10));
    
            if (columnWidths.some(isNaN)) throw new Error("Invalid format"); // Handle parsing errors
        } catch (error) {
            console.error("Failed to parse columnWidths:", visualization.columnWidths, error);
            return;
        }
    
        setTimeout(() => {    
            const rowHeaderColumns = pivotGrid.rowHeaders.columns.length;
            const valueColumns = pivotGrid.columns.length;
            const totalColumns = rowHeaderColumns + valueColumns;
    
            if (columnWidths.length === totalColumns) {
    
                // Apply Row Header Column Widths
                pivotGrid.rowHeaders.columns.forEach((col, index) => {
                    col.width = columnWidths[index] || col.width;
                });
    
                // Apply Value Column Widths
                pivotGrid.columns.forEach((col, index) => {
                    col.width = columnWidths[rowHeaderColumns + index] || col.width;
                });
    
                pivotGrid.invalidate(); // Force refresh after applying widths
            } else {
                console.warn("Column width mismatch! Parsed:", columnWidths.length, 
                             "Expected:", totalColumns);
            }
        }, 100);
    }

    initPivotChart(visualization, view) {
        let chartID = '#theChart' + visualization.id,
            theChart = new wijmoOlap.PivotChart(chartID, {
                itemsSource: this.theEngine,
            });
        this.initPivotPanelChart(visualization, view, theChart);
    }

    initPivotPanelGrid(visualization, view, wijmoType) {
        let panelID = '#pivotPanel' + visualization.id + view.type;
        if (visualization.id == 1) {
            this.pivotPanelGrid1 = this.populatePanelElement(panelID, view, wijmoType);
            this.pivotGrid1 = wijmoType;
        } else {
            this.pivotPanelGrid400 = this.populatePanelElement(panelID, view, wijmoType);
            this.pivotGrid400 = wijmoType;
        }
    }

    initPivotPanelChart(visualization, view, wijmoType) {
        let panelID = '#pivotPanel' + visualization.id + view.type;
        if (visualization.id == 1) {
            this.pivotPanelChart1 = this.populatePanelElement(panelID, view, wijmoType);
        } else {
            this.pivotPanelChart400 = this.populatePanelElement(panelID, view, wijmoType);
        }
    }

    populatePanelElement(panelID, view, wijmoType) {
        return new wijmoOlap.PivotPanel(panelID, {
            itemsSource: this.theEngine,
            initialize: this.initWijmo(this.theEngine, view, wijmoType),
        });
    }

    printReport() {
        this.wijmoFormatter.printReport(this);

        let jobName = this.info.info.jobDisplayName;
        let jobID = this.info.info.jobID;
        let msg = `User printed "${jobName}" report [job ${jobID}] [concept ${this.conceptID}]`;
        this.usageLogger.recordUsageLog("PRINT_REPORT", 1, msg)
    }

    changeHeadersToBindings(viewDefinition) {
        for (let k in viewDefinition.fields) {
            for (let l in viewDefinition.rowFields.items) {
                if (viewDefinition.rowFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.rowFields.items[l] = viewDefinition.fields[k].binding;
            }

            for (let l in viewDefinition.columnFields.items) {
                if (viewDefinition.columnFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.columnFields.items[l] = viewDefinition.fields[k].binding;
            }

            for (let l in viewDefinition.filterFields.items) {
                if (viewDefinition.filterFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.filterFields.items[l] = viewDefinition.fields[k].binding;
            }

            for (let l in viewDefinition.valueFields.items) {
                if (viewDefinition.valueFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.valueFields.items[l] = viewDefinition.fields[k].binding;
            }

            viewDefinition.fields[k].header = viewDefinition.fields[k].binding;
        }

        return viewDefinition;
    }

    getReportTemplateById(templateID) {
        let language = this.appSettings.getLanguage();
        this.utilsApiService.getLongCaptions(language, 'REPORT').then((reportLongCaptions) => {
            this.reportDescriptions = reportLongCaptions;
            this.reportTemplatesDataService.getDescriptionID(templateID, null).then((descriptionID) => {
                for (let description of this.reportDescriptions) {
                    if (descriptionID === description.id) {
                        this.templateDescription = description.caption;
                    }
                }
            });
        });
    }

    yyyymmdd() {
        var now = new Date();
        var y = now.getFullYear();
        var m = now.getMonth() + 1;
        var d = now.getDate();
        return '' + y + (m < 10 ? '0' : '') + m + (d < 10 ? '0' : '') + d;
    }
}
