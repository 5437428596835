import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, Inject, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';
import * as txml from 'txml';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { MobileViewService } from '../../../utils/shared-services/mobileView/mobileView.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { EoiStatusDialog } from '../../eoi/components/dialogs/eoi-status-dialog.component';
import { EoiApiService } from '../../eoi/services/eoi.resource';
import { eoiService as EoiService } from '../../eoi/services/eoi.service';
import { WidgetDescriptionDialog } from '../../store-portal/components/dialogs/widget-description-dialog.component';
import { StorePortalApiService } from '../../store-portal/services/store-portal.resource';
import { StorePortalService } from '../../store-portal/services/store-portal.service';
import { StoreApiService } from '../services/store.resource';
import { ConfirmDeleteViewDialog } from './dialogs/confirm-delete-view-dialog.component';
import { ConfirmDisablePortalDialog } from './dialogs/confirm-disable-portal-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BasicEoiInfo, EoiInformation, EOIQuickStatus, EOIQuickStatusResponse } from '../../eoi/models/eoi.model';
import { EnableStorePortal } from '../models/enable-store-portal.model';
import { WidgetInfo } from '../../store-portal/models/widget.model';
import { View, ViewForm } from '../../store-portal/models/view.model';
import { StorePortalData } from '../../store-portal/models/store-portal.model';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'app-store-portal',
    templateUrl: 'src/app/features/stores/templates/enable-store-portal.html'
})
export class EnableStorePortalComponent {

    private storeID: number;
    private baseURl: URL;
    private eoiInfo: BasicEoiInfo;
    private allWidgets: { id: string }[] = [];
    private fb: FormBuilder = new FormBuilder();

    public portalIDForm  = this.fb.group({
        pathName: ['', [Validators.required, Validators.maxLength(this.validationService.portalIDMaxLength), Validators.minLength(this.validationService.portalIDMinLength)]],
    });
    public viewsForm = this.fb.group({ 
        views: this.fb.array<FormGroup<ViewForm>>([]),
    });
    public widValueForm = this.fb.group({
        message: ['', []],
        labour1column1Selection: ['0', Validators.required],
        labour1column2Selection: ['0', Validators.required],
        labour1column3Selection: ['0', Validators.required],
        labour2column1Selection: ['0', Validators.required],
        labour2column2Selection: ['0', Validators.required],
        labour2column3Selection: ['0', Validators.required],
        openTable1column1Selection: ['0', Validators.required],
        openTable1column2Selection: ['4', Validators.required],
        openTable1column3Selection: ['5', Validators.required],
        openTable2column1Selection: ['0', Validators.required],
        openTable2column2Selection: ['6', Validators.required],
        openTable2column3Selection: ['9', Validators.required],
        quickSavedOrdersColumn1Selection: ['0', Validators.required],
        quickSavedOrdersColumn2Selection: ['6', Validators.required],
        quickSavedOrdersColumn3Selection: ['9', Validators.required],
        hourlySalesBreakdown1column1Selection: ['0', Validators.required],
        hourlyProfitBreakdown1column1Selection: ['0', Validators.required],
        sevenDaysPriorVsCurrentSales1column1Selection: ['0', Validators.required],
        closedVsAllSales1column1Selection: ['0', Validators.required],
        labourCostPercentageColumn1Selection: [25, Validators.required],
        labourCostPercentageColumn2Selection: [15, Validators.required],
    });

    public captions: Record<string, string>;
    public storePortalDemoLink: string;
    public eoiStatus: string = 'color-grey';
    public portalURL: string = '';
    public pathName: string = '';
    public enabled: boolean = false;
    public configActive: boolean = false;
    public isEditingPortalID: boolean = false;
    public widgetsInfo: Record<string, WidgetInfo> = {};
    public availableWidgets: { id: string }[] = [];
    public config: Partial<View>[] = [];
    public selectedViewConfigIdx = 0;
    public searchStr: string = '';
    public selectedTabIdx: number = 1;

    @ViewChildren('viewInput') viewInputs: QueryList<ElementRef>;

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(EoiApiService) private eoiApiService: EoiApiService,
        @Inject(EoiService) private eoiService: EoiService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(StorePortalService) private storePortalService: StorePortalService,
        @Inject(StorePortalApiService) private storePortalApiService: StorePortalApiService,
        @Inject(MobileViewService) private mobileViewService: MobileViewService,
        @Inject(ChangeDetectorRef) private cd: ChangeDetectorRef,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(DeviceDetectorService) private deviceService: DeviceDetectorService,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_MEDIUM);
        this.storeID = +this.actRoute.snapshot.paramMap.get('storeID');
        this.baseURl = new URL(window.location.toString().replace(this.router.url, '/storePortal'));
        this.storePortalDemoLink = 'https://www.youtube.com/embed/FNcv2eS-pGE?ecver=1';

        // Update EOI status
        this.eoiApiService.getEoiByID(this.storeID, this.getTimeRange(), 8, 1, null).then((eoi: EoiInformation) => {
            this.eoiApiService.getQuickStatus(this.storeID, 15000000).then((eoiStatusResponse: EOIQuickStatusResponse) => {
                const { Response } = txml.simplify(txml.parse(eoiStatusResponse.responseString));
                if (Response?.ErrorNum === '-1') {
                    return;
                }

                const eoiQuickStatus = Response.Status as EOIQuickStatus;
                this.eoiStatus = this.eoiService.getQuickStatusColor(eoiQuickStatus);
                if (this.eoiStatus === 'color-yellow') {
                    this.eoiStatus = 'color-green';
                }
                this.eoiInfo = {
                    okToOrder: eoiQuickStatus.OkToOrder,
                    posOnline: eoiQuickStatus.PosOnline,
                    eoiOnline: eoiQuickStatus.EoiOnline,
                    disconnectedTime: eoi?.disconnectedTime,
                };
            });
        });
        this.loadEnableStorePortal();
    }

    get views() {
        return this.viewsForm.controls['views'];
    }

    ngOnInit(): void {
        this.portalIDForm.get('pathName').disable();
        this.portalIDForm.get('pathName').markAsTouched();
    }

    eoiStatuses(): void {
        const dialogRef = this.dialog.open(EoiStatusDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                eoiOnline: this.eoiInfo?.eoiOnline,
                posOnline: this.eoiInfo?.posOnline,
                okToOrder: this.eoiInfo?.okToOrder,
                disconnectedtime: this.eoiInfo?.disconnectedTime,
                isStorePortal: true,
                isCountSheet: false,
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    // Populate the available widgets column on the left side of the screen
    // filter widgets that are selected for view
    setAvailableWidgets(): void {
        this.availableWidgets = this.allWidgets.filter(wid1 => {
            return (
                this.views.controls[this.selectedViewConfigIdx].value.widgets.findIndex((wid2) => wid1.id === wid2.id) <
                    0 && this.widgetsInfo[wid1.id].title.toLowerCase().includes(this.searchStr.toLowerCase())
            );
        });
    }

    enablePortal(): void {
        let storePortal: EnableStorePortal = {
            storeID: this.storeID,
            pathName: this.pathName,
            isActive: 1,
            config: null,
            widgetValueConfig: null,
        };

        this.storeApiService.enableOrUpdateStorePortal(storePortal).then((res: EnableStorePortal) => {
            this.pathName = res.pathName;
            this.portalIDForm.get('pathName').setValue(this.pathName);
            this.enabled = true;
            this.loadEnableStorePortal();
        });
    }

    disablePortal(): void {
        const dialogRef = this.dialog.open(ConfirmDisablePortalDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
        });

        dialogRef.afterClosed().subscribe((res: boolean) => {
            if (res) {
                this.storeApiService.disableStorePortal(this.storeID).then(() => {
                    this.enabled = false;
                });
            }
        });
    }

    toggleConfig(): void {
        this.configActive = !this.configActive;
    }

    toggleEditPortalID(): void {
        this.isEditingPortalID = !this.isEditingPortalID;
        this.portalIDForm.get('pathName').setValue(this.pathName);
        this.isEditingPortalID
            ? this.portalIDForm.get('pathName').enable()
            : this.portalIDForm.get('pathName').disable();
    }

    // submits the store portal pathName string to be modified in db
    submitPortalID(): void {
        if (this.portalIDForm.valid) {
            let storePortal: EnableStorePortal = {
                storeID: this.storeID,
                pathName: this.portalIDForm.value.pathName,
                isActive: this.enabled ? 1 : 0,
                config: JSON.stringify(this.config),
                widgetValueConfig: JSON.stringify(this.widValueForm.value),
            };
            try {
                this.storeApiService.enableOrUpdateStorePortal(storePortal).then((res: EnableStorePortal) => {
                    this.pathName = res.pathName;
                    this.toggleEditPortalID();
                    this.updatePortalURL(this.pathName);
                    this.snackbarService.successMessageTop(this.captions.success);
                });
            } 
            catch (err) {
                this.snackbarService.errorMessageTop('Error');
            }
        }
    }

    openInfoDialog(widgetID: string): void {
        this.storePortalApiService.getWidgetDescriptionHTMLByID(widgetID).then((desc) => {
            this.dialog.open(WidgetDescriptionDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '95vw',
                data: {
                    widgetID: widgetID,
                    descriptionHTML: desc,
                },
            });
        });
    }

    itemDropped(event: CdkDragDrop<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
    }

    // login to store portal api and forward to portal home page
    openHQPortal(): void {
        // checking if the app is running on a Desktop browser.
        const isDesktopDevice = this.deviceService.isDesktop();
        if (!isDesktopDevice) {
            this.snackBar.open(this.captions.enableNewTab, this.captions.ok, {
                duration: 10000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
            });
        }
        this.storePortalApiService
            .hqLoginForStorePortal(
                this.pathName,
                '',
                'HQUser',
                this.browserStorage.getSessionstorage('userAccess', null)
            )
            .then((res: StorePortalData) => {
                this.storePortalService.setStorePortalData(res);
                if (res?.AccessToken) {
                    window.open('storePortal/home/0', '_blank');
                }
            });
    }

    openPortal(): void {
        // checking if the app is running on a Desktop browser.
        const isDesktopDevice = this.deviceService.isDesktop();
        if (!isDesktopDevice) {
            this.snackBar.open(this.captions.enableNewTab, this.captions.ok, {
                duration: 10000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
            });
        }
        window.open(this.portalURL, '_blank');
    }

    addView(view: Partial<View>, focusLastElement: boolean = true): void {
        let nextNum: number; 
        if (focusLastElement) {
            this.viewInputs.changes.pipe(take(1)).subscribe({
                next: (changes) => {
                    changes.last.nativeElement.focus();
                    this.cd.detectChanges();
                },
            });
            // get value for next view based on last element in views array
            const lastElement = this.views.value[this.views.length - 1];
            nextNum = lastElement.id + 1;

        }
        let viewForm: FormGroup<ViewForm> = this.fb.group({
            id: [view.id ?? nextNum],
            title: [view.title ?? '', [Validators.required, Validators.maxLength(this.validationService.portalConfigTitleMaxLength), Validators.minLength(this.validationService.portalConfigTitleMinLength)]],
            widgets: [view.widgets ?? []],
        });
        this.views.push(viewForm);
    }

    deleteView(idx: number): void {
        if (this.views.controls.length <= 1) {
            this.snackbarService.errorMessageTop('Portal must have at least one view');
            return;
        }

        let viewInfo: Partial<View> = this.views.controls[idx].value;
        if (viewInfo.title.length === 0 && viewInfo.widgets.length === 0) {
            this.removeView(idx);
            return;
        }

        this.dialog
            .open(ConfirmDeleteViewDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    view: viewInfo,
                },
            })
            .afterClosed()
            .subscribe((res: boolean) => {
                if (res) {
                    this.removeView(idx);
                }
            });
    }

    saveConfig(): void {
        this.config = [];
        this.views.controls.forEach((control) => this.config.push(control.value));
        let storePortal: EnableStorePortal = {
            storeID: this.storeID,
            pathName: this.pathName,
            isActive: this.enabled ? 1 : 0,
            config: JSON.stringify(this.config),
            widgetValueConfig: JSON.stringify(this.widValueForm.value),
        };
        this.storeApiService.enableOrUpdateStorePortal(storePortal).then(() => {
            this.toggleConfig()
        });
    }

    clearWidgetValueField(value: string): void {
        switch (value) {
            case 'message':
                this.widValueForm.controls['message'].reset();
                break;
            default:
                break;
        }
    }

    calYellowMaxValue(green: string): number {
        if (!green) {
            green = '0';
        }
        let result = 100 - parseInt(green);
        return result < 0 ? 0 : result > 100 ? 100 : result;
    }

    calRedColorPercentage(green: string, yellow: string): number {
        if (!green) {
            green = '0';
        }
        if (!yellow) {
            yellow = '0';
        }
        let result = 100 - (parseInt(green) + parseInt(yellow));
        return result < 0 ? 0 : result > 100 ? 100 : result;
    }

    valueIsNegative(value: string): boolean {
        return parseInt(value) < 0;
    }

    private getTimeRange(): number {
        let timeRange = this.browserStorage.getSessionstorage('timeRange', undefined);
        return timeRange == null ? 7 : timeRange;
    }

    // Grab all unique datapoints specified in the widget array and fetch their datasets
    private loadEnableStorePortal(): void {
        this.allWidgets = this.storePortalService.getAllWidgets();
        this.widgetsInfo = this.storePortalService.getWidgetsInfo();
        this.storeApiService.getEnableStorePortal(this.storeID).then((enableStorePortal: EnableStorePortal) => {
            if (enableStorePortal) {
                this.updatePortalURL(enableStorePortal.pathName);
                this.enabled = enableStorePortal.isActive === 1;
                this.pathName = enableStorePortal.pathName;
                this.portalIDForm.get('pathName').setValue(this.pathName);
            }
            this.views.clear();
            this.config = this.storePortalService.parseSavedConfig(
                enableStorePortal?.config ? JSON.parse(enableStorePortal.config) : null
            );
            this.config.forEach(view => this.addView(view, false));
            if (enableStorePortal?.widgetValueConfig) {
                let parsedWidValues = JSON.parse(enableStorePortal.widgetValueConfig);

                this.widValueForm.patchValue({
                    message: parsedWidValues.message ? parsedWidValues.message : '',

                    // open and closed shifts (1) column index
                    labour1column1Selection: parsedWidValues.labour1column1Selection
                        ? parsedWidValues.labour1column1Selection
                        : '0',
                    labour1column2Selection: parsedWidValues.labour1column2Selection
                        ? parsedWidValues.labour1column2Selection
                        : '0',
                    labour1column3Selection: parsedWidValues.labour1column3Selection
                        ? parsedWidValues.labour1column3Selection
                        : '0',

                    // open and closed shifts (2) column index
                    labour2column1Selection: parsedWidValues.labour2column1Selection
                        ? parsedWidValues.labour2column1Selection
                        : '0',
                    labour2column2Selection: parsedWidValues.labour2column2Selection
                        ? parsedWidValues.labour2column2Selection
                        : '0',
                    labour2column3Selection: parsedWidValues.labour2column3Selection
                        ? parsedWidValues.labour2column3Selection
                        : '0',

                    // open table (1) column index
                    openTable1column1Selection: parsedWidValues.openTable1column1Selection
                        ? parsedWidValues.openTable1column1Selection
                        : '0',
                    openTable1column2Selection: parsedWidValues.openTable1column2Selection
                        ? parsedWidValues.openTable1column2Selection
                        : '4',
                    openTable1column3Selection: parsedWidValues.openTable1column3Selection
                        ? parsedWidValues.openTable1column3Selection
                        : '5',

                    // open table (2) column index
                    openTable2column1Selection: parsedWidValues.openTable2column1Selection
                        ? parsedWidValues.openTable2column1Selection
                        : '0',
                    openTable2column2Selection: parsedWidValues.openTable2column2Selection
                        ? parsedWidValues.openTable2column2Selection
                        : '6',
                    openTable2column3Selection: parsedWidValues.openTable2column3Selection
                        ? parsedWidValues.openTable2column3Selection
                        : '9',

                    // quick saved orders column index
                    quickSavedOrdersColumn1Selection: parsedWidValues.quickSavedOrdersColumn1Selection
                        ? parsedWidValues.quickSavedOrdersColumn1Selection
                        : '0',
                    quickSavedOrdersColumn2Selection: parsedWidValues.quickSavedOrdersColumn2Selection
                        ? parsedWidValues.quickSavedOrdersColumn2Selection
                        : '6',
                    quickSavedOrdersColumn3Selection: parsedWidValues.quickSavedOrdersColumn3Selection
                        ? parsedWidValues.quickSavedOrdersColumn3Selection
                        : '9',

                    // hourly sales breakdown column index
                    hourlySalesBreakdown1column1Selection: parsedWidValues.hourlySalesBreakdown1column1Selection
                        ? parsedWidValues.hourlySalesBreakdown1column1Selection
                        : '0',

                    // hourly profit breakdown column index
                    hourlyProfitBreakdown1column1Selection: parsedWidValues.hourlyProfitBreakdown1column1Selection
                        ? parsedWidValues.hourlyProfitBreakdown1column1Selection
                        : '0',

                    // 7 days prior vs current sales breakdown column index
                    sevenDaysPriorVsCurrentSales1column1Selection: parsedWidValues.sevenDaysPriorVsCurrentSales1column1Selection
                    ? parsedWidValues.sevenDaysPriorVsCurrentSales1column1Selection
                    : '0',

                    // closed vs all sales breakdown column index
                    closedVsAllSales1column1Selection: parsedWidValues.closedVsAllSales1column1Selection
                    ? parsedWidValues.closedVsAllSales1column1Selection
                    : '0',

                    // labour cost percentage column index
                    labourCostPercentageColumn1Selection: parsedWidValues.labourCostPercentageColumn1Selection
                        ? parsedWidValues.labourCostPercentageColumn1Selection
                        : 25,
                    labourCostPercentageColumn2Selection: parsedWidValues.labourCostPercentageColumn2Selection
                        ? parsedWidValues.labourCostPercentageColumn2Selection
                        : 15,
                });
            }

            this.setAvailableWidgets();
        });
    }

    private updatePortalURL(aPathName: string): void {
        this.baseURl.searchParams.set('PortalID', aPathName);
        this.portalURL = this.baseURl.toString();
    }

    private removeView(idx: number): void {
        this.views.removeAt(idx);
        if (this.selectedViewConfigIdx >= this.views.controls.length) {
            this.selectedViewConfigIdx = this.views.controls.length - 1;
        }
    }
}
