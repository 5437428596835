import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

interface Parameters {
    setCustomDate: any;
    updateTimeRange: any;
    tempConfig: any;
    localTimeZone: any;
    setTimeZone: any;
    setIdType: any;
    storeID: any;
    timeRange: any;
    reqType: any;
    vendorID: any;
    store: any;
}

@Component({
    selector: 'eoi-custom-date-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-custom-date.dialog.html',
})
export class EoiCustomDateDialog {
    public captions: Record<string,string>;
    public selectedDate: Date = new Date();
    public selectedDateStorage: Date;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EoiCustomDateDialog>,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
    ) {
        this.captions = this.captionService.captions;
        this.data.tempConfig.utcOrLocal = parseInt(this.data.tempConfig.utcOrLocal);
        this.data.tempConfig.queueOrTransferID = parseInt(this.data.tempConfig.queueOrTransferID);
    }

    ngOnInit(): void{
        this.getCustomDate()
    }

    getMaxDate(): Date {
        const maxAllowedDate: Date = new Date();
        maxAllowedDate.setDate(maxAllowedDate.getDate() + 1);
        return maxAllowedDate;
    }

    isInvalidDate(): boolean {
        return this.selectedDate > this.getMaxDate() || !this.selectedDate;
    }
    
    saveCustomDate(): void {
        this.data.setCustomDate(this.selectedDate);
        this.data.updateTimeRange(this.data.storeID, this.data.timeRange, this.data.reqType, this.data.vendorID);
        this.dialogRef.close(this.selectedDate);    
    }

    getCustomDate(): Date {
        this.selectedDateStorage = this.browserStorage.getSessionstorage('selectedDate', null) ;
        this.selectedDate = this.selectedDateStorage ? this.selectedDateStorage : this.selectedDate
        return this.selectedDate;
    }
}
