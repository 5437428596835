export enum UserPrivileges {
  ManageOrganizations = 'MANAGE_ORGANIZATIONS',
  ManageCoupons = 'MANAGE_COUPONS',
  PortalAdmin = 'PORTAL_ADMIN',
  PortalUser = 'PORTAL_USER',
  Organizations = 'Organizations',
  Stores = 'Stores',
  OrgType = 'ORGANIZATIONS',
  StoreType = 'STORES',
  AllPrivileges = '[]',
  ManageStoreTags = 'MANAGE_TAGS',
  ManageStoreGroupsAndSelections = 'MANAGE_STORE_GROUPS_AND_STORE_GROUP_SELECTIONS',
  ManageStores = 'MANAGE_STORES',
  AssignStoreGroupSelections = 'ASSIGN_STORE_GROUP_SELECTIONS',
  AssignStoreTags = 'ASSIGN_STORE_TAGS',
  ManageReportApi = 'MANAGE_REPORT_API',
  ManageEOIApi = 'MANAGE_EOI_API',
  ManageDataExtraction = 'MANAGE_DATA_EXTRACTION',
  ViewSharedDashOnly = 'VIEW_SHARED_DASH_ONLY',
  StorePortalOnly = 'STORE_PORTAL_ONLY',

  AdminUrl = '../data/adminUserPrivileges.json',
  ConceptUrl = '../data/conceptUserPrivileges.json',
  GroupItemUrl = '../data/groupItemUserPrivileges.json',
  TagUrl = '../data/tagUserPrivileges.json',
  storeUrl = '../data/storeUserPrivileges.json',
}

export enum PrivilegeName {
  ManageOrganizations = 'MANAGE_ORGANIZATIONS',
  ManageStoreTags = 'MANAGE_TAGS',
  ManageStoreGroupsAndSelections = 'MANAGE_STORE_GROUPS_AND_STORE_GROUP_SELECTIONS',
  ManageCoupons = 'MANAGE_COUPONS',
  ManageStores = 'MANAGE_STORES',
  AssignStoreGroupSelections = 'ASSIGN_STORE_GROUP_SELECTIONS',
  AssignStoreTags = 'ASSIGN_STORE_TAGS',
  ManageReportApi = 'MANAGE_REPORT_API',
  ManageEOIApi = 'MANAGE_EOI_API',
  ManageDataExtraction = 'MANAGE_DATA_EXTRACTION',
  ViewSharedDashOnly = 'VIEW_SHARED_DASH_ONLY',
  STORE_PORTAL_ONLY = 'STORE_PORTAL_ONLY',
  PortalAdmin = 'STORE_PORTAL_ADMIN_ONLY',
  PortalUser = 'STORE_PORTAL_USER_ONLY',
}

export enum PrivilegeType {
  Organizations = 'ORGANIZATIONS',
  Stores = 'STORES',
}
