import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { StoreBase } from "../../stores/models/store.model";
import { Tag } from "../../tags/models/tag.model";
import { GroupWithItems } from "../../store-groups/models/group-item.model";
import { StoreApiService } from "../../stores/services/store.resource";
import { PriceChange, Product, ProductGroup, ReportCategory } from "../models/price-change.model";
import { StoreSelectionDialogData, ReportCategorySelectionDialogData, ColumnSelectionDialogData, SubmitPriceChangeDialogData, SetPriceDialogData } from "../models/dialog.model";
import { PriceChangeService } from "../services/price-change.resource";
import { PriceChangeReportCategorySelectionDialog } from "./dialogs/report-category-selection.component";
import { PriceChangeStoreSelectionDialog } from "./dialogs/store-selection.component";
import { ColDef, GridApi, GridReadyEvent, ValueFormatterParams, CellContextMenuEvent, ITextFilterParams, INumberFilterParams, GridOptions, GetRowIdParams } from 'ag-grid-community';
import { PriceChangeColumnSelectionDialog } from "./dialogs/column-selection.component";
import { SubmitPriceChangeDialog } from "./dialogs/submit-price-change.component";
import { CdkContextMenuTrigger } from "@angular/cdk/menu";
import { PriceChangeRowCompDialog } from "./dialogs/row-comp-dialog.component";
import { StoreGroupApiService } from "../../store-groups/services/store-group.resource";
import { TagApiService } from "../../tags/services/tag.resource";
import { IconCellRenderer } from "./IconCellRenderer";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { SetPriceDialog } from "./dialogs/set-price.component";
import { lastValueFrom } from "rxjs/internal/lastValueFrom";

@Component({
    selector: "app-price-change",
    templateUrl: "./src/app/features/price-change/templates/price-change.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceChangeComponent implements OnInit {
    private conceptID: number
    private categorySelectedBy: "Descript" | "PLink"
    private reportCategories: ReportCategory[]
    private maxCategoryGroupSelection: number
    private products: Product[]
    private gridApi!: GridApi<ProductGroup>

    private modifiedCells: Map<string, boolean>;

    @ViewChild(CdkContextMenuTrigger)
    private contextMenuTrigger!: CdkContextMenuTrigger;

    public captions: Record<string, string>;
    public stores: StoreBase[];
    public selectedStores: StoreBase[];
    public storeGroups: GroupWithItems[];
    public tags: Tag[];
    public selectedCategories: ReportCategory[];
    public numCategoryGroupsSelected: number;
    public groupBy: string;
    public rowData: ProductGroup[] // The data to be displayed.
    public colDefs: ColDef[] // Defines the columns to be displayed.
    public numColsSelected: number;
    public filterText: string;
    public priceChange: PriceChange = {
        info: { PriceChangeDate: '', RollbackDate: '' },
        rollbackPrices: [],
        changedPrices: [],
        label: ''
    };
    public nodeIds: String[] = [];
    public contextMenuEvent: CellContextMenuEvent;
    public gridOptions: GridOptions
    public hideApplyPriceToRow: boolean = false;
    public orgProducts;
    isDoneProcessing: boolean = true;
    processedCount: number = 0;


    constructor(
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(StoreGroupApiService) private storeGroupApiService: StoreGroupApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(NgxIndexedDBService) private dbService: NgxIndexedDBService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(PriceChangeService) private priceChangeService: PriceChangeService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ChangeDetectorRef) private cd: ChangeDetectorRef
    ) {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.conceptID = null;
        this.stores = [];
        this.selectedStores = [];
        this.reportCategories = [];
        this.selectedCategories = [];
        this.categorySelectedBy = null;
        this.maxCategoryGroupSelection = 5;
        this.numCategoryGroupsSelected = 0;
        this.groupBy = "Descript";
        this.rowData = [];
        this.products = [];
        this.modifiedCells = new Map<string, boolean>();


        this.colDefs = [
            {
                headerName: this.captions.frontDescription, field: "Descript", cellStyle: { textAlign: "right" }, filter: "agTextColumnFilter", valueFormatter: this.nullFormatter, filterParams: {
                    buttons: ["reset"],
                } as ITextFilterParams
            },
            {
                headerName: "PLink", field: "PLink", cellStyle: { textAlign: "right" }, filter: "agTextColumnFilter", valueFormatter: this.nullFormatter, filterParams: {
                    buttons: ["reset"],
                } as ITextFilterParams
            },
            {
                headerName: this.captions.stores, field: "Stores", cellStyle: { textAlign: "right" }, filter: "agTextColumnFilter", tooltipValueGetter: () => this.getProductToolTip(),
                cellRenderer: IconCellRenderer,
                filterParams: {
                    buttons: ["reset"],
                } as ITextFilterParams,
                valueGetter: params => {
                    const products = params.data.products as Product[];
                    const uniqueStoreIDs = new Set();

                    products.forEach(product => {
                        uniqueStoreIDs.add(product.StoreID);
                    });

                    return (this.groupBy == "PLink") ? uniqueStoreIDs.size + "/" + this.selectedStores.length + " " + products.length : uniqueStoreIDs.size + "/" + this.selectedStores.length;


                }
            },
            {
                headerName: "PriceA", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams, editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceA"),
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceA: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceA"] = params.newValue != null ? params.newValue : product["PriceA"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceA"]
                }
            },
            {
                headerName: "PriceB", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceB"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceB: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceB"] = params.newValue != null ? params.newValue : product["PriceB"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceB"]
                }
            },
            {
                headerName: "PriceC", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceC"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceC: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceC"] = params.newValue != null ? params.newValue : product["PriceC"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceC"]
                }
            },
            {
                headerName: "PriceD", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceD"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceD: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceD"] = params.newValue != null ? params.newValue : product["PriceD"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceD"]
                }
            },
            {
                headerName: "PriceE", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceE"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceE: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceE"] = params.newValue != null ? params.newValue : product["PriceE"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceE"]
                }
            },
            {
                headerName: "PriceF", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceF"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        if (params.newValue == null) params.newValue = product["PriceF"]
                        let productNewPrice = { PriceF: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceF"] = params.newValue != null ? params.newValue : product["PriceF"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceF"]
                }
            },
            {
                headerName: "PriceG", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceG"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceG: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceG"] = params.newValue != null ? params.newValue : product["PriceG"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceG"]
                }
            },
            {
                headerName: "PriceH", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceH"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceH: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceH"] = params.newValue != null ? params.newValue : product["PriceH"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceH"]
                }
            },
            {
                headerName: "PriceI", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceI"),
                filterParams: {
                    buttons: ["reset"],
                } as INumberFilterParams,
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceI: params.newValue as number, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceI"] = params.newValue != null ? params.newValue : product["PriceI"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceI"]
                }
            },
            {
                headerName: "PriceJ", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceJ"),
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceJ: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceJ"] = params.newValue != null ? params.newValue : product["PriceJ"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceJ"]
                }
            },
            {
                headerName: "Price MOD", field: "products", cellStyle: { textAlign: "right" }, cellDataType: 'number', filter: "agNumberColumnFilter", editable: true, valueFormatter: this.groupPriceFormatter.bind(null, "PriceMode"),
                cellClass: (params) => {
                    // Generate a unique key for the cell based on the rowIndex and column field
                    const cellKey = `${params.node.rowIndex}-${params.column.getColId()}`;
                    return this.modifiedCells.get(cellKey) ? 'bg-yellow-100' : null;
                },
                valueSetter: params => {
                    for (let product of params.data.products) {
                        let productNewPrice = { PriceMode: params.newValue, ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
                        this.addProduct(productNewPrice, false);
                        product["PriceMode"] = params.newValue != null ? params.newValue : product["PriceMode"];
                    }
                    return true;
                },
                valueGetter: params => {
                    return params.data.products[0]["PriceMode"]
                }
            }
        ];
        this.numColsSelected = this.colDefs.length;


        this.gridOptions = {
            columnDefs: this.colDefs,
            rowData: this.rowData,
        };
    }

    onCellValueChanged(ev) {
        if (ev.oldValue != ev.newValue) {
            const cellKey = `${ev.node.rowIndex}-${ev.column.colId}`;
            this.modifiedCells.set(cellKey, true);
            ev.api.refreshCells({ rowNodes: [ev.node], columns: [ev.column.colId], force: true });
        }
    }

    private async addProduct(newProduct: any, isApplyRow: boolean): Promise<void> {
        // Check and update or remove the product in the changedPrices array
        await this.checkUpdateOrRemoveProduct(this.priceChange.changedPrices, newProduct, isApplyRow);
    }

    private async checkUpdateOrRemoveProduct(productsArray: any[], product: any, isApplyRow: boolean): Promise<void> {
        let existingProductIndex = productsArray.findIndex(p => p.StoreID === product.StoreID && p.ProdNum === product.ProdNum);
        if (existingProductIndex !== -1) {
            // get original products from storage

            const orgProducts = await lastValueFrom(this.dbService.getByKey('orgProducts', 1));
            this.orgProducts = orgProducts.products;
            this.orgProducts = orgProducts.products;

            // index of product in orginal products
            let originalIndex = this.orgProducts.findIndex(p => p.StoreID === product.StoreID && p.ProdNum === product.ProdNum);
            // Extract the price properties from the product
            const pricePropertyNames = Object.keys(product).filter(key => key.startsWith('Price'));
            let removeProduct = false;

            for (const propName of pricePropertyNames) {
                // if the product price equals the orginal product price, then remove the price from array for submission
                if (this.orgProducts[originalIndex][propName] === product[propName]) {
                    // if there was only 1 price then remove whole product object or just remove the price key/value
                    if (Object.keys(productsArray[existingProductIndex]).length == 3 && !isApplyRow) {
                        productsArray.splice(existingProductIndex, 1);
                    } else {
                        delete productsArray[existingProductIndex][propName]
                    }
                    removeProduct = true;
                }
            }
            // if product does not need to be removed, update the existing product in array with the new price information
            if (!removeProduct) {
                Object.assign(productsArray[existingProductIndex], product);
            }
        } else {
            // If the product does not exist in the array, add it
            productsArray.push(product);
        }

    }

    private createRollbackArray() {
        //reset rollback prices 
        this.priceChange.rollbackPrices = [];
        // loop through all products that are changed and get original values and set the rollback prices
        this.dbService.getByKey('orgProducts', 1).subscribe((orgProducts: any) => {
            this.orgProducts = orgProducts.products;

            this.priceChange.changedPrices.forEach(changedProduct => {
                let indexInOrg = this.orgProducts.findIndex(p => p.StoreID === changedProduct.StoreID && p.ProdNum === changedProduct.ProdNum);
                const pricePropertyNames = Object.keys(changedProduct).filter(key => key.startsWith('Price'));
                let rollbackProduct = {};

                for (const propName of pricePropertyNames) {
                    rollbackProduct[propName] = this.orgProducts[indexInOrg][propName];
                }
                rollbackProduct["ProdNum"] = changedProduct.ProdNum;
                rollbackProduct["StoreID"] = changedProduct.StoreID;

                this.priceChange.rollbackPrices.push(rollbackProduct);
            })

        });

    }


    ngOnInit(): void {
        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['pathId'];
        });

        this.stores = history.state.stores || [];
        this.storeGroups = history.state.storeGroups || [];
        this.tags = history.state.tags || [];

        this.selectedStores = history.state.selectedStores || [];
        if (this.stores.length <= 0) {
            this.storeApiService.getStoresByConceptId(this.conceptID).then((stores: StoreBase[]) => {
                this.stores = stores;
            });
        }
        if (this.storeGroups.length <= 0) {
            this.storeGroupApiService.getAllStoreGroupsWithItemsForConcept(this.conceptID).then((storeGroups: GroupWithItems[]) => {
                this.storeGroups = storeGroups;
            });
        }
        if (this.tags.length <= 0) {
            this.tagApiService.getAllTagsForConcept(this.conceptID).then((tags: Tag[]) => {
                this.tags = tags;
            });
        }

        if (this.selectedStores.length > 0) {
            if (history.state.reportCategories) {
                this.reportCategories = history.state.reportCategories;
                this.numCategoryGroupsSelected = history.state.numCategoryGroupsSelected || 0;
                this.categorySelectedBy = history.state.categorySelectedBy;
                this.selectedCategories = history.state.selectedCategories || [];
                this.products = history.state.products || [];
                this.rowData = history.state.rowData || [];
                this.groupBy = history.state.groupBy;
            }
            else {
                let storeIDs = this.selectedStores.map(store => store.id);
                this.priceChangeService.getReportCategories(storeIDs).then(reportCats => {
                    this.reportCategories = reportCats;
                });
            }
        }
    }

    onGridReady(params: GridReadyEvent<ProductGroup>): void {
        this.gridApi = params.api;
        this.applyGroupBy(this.groupBy);
    }

    onFilterTextBoxChanged() {
        this.gridApi.setGridOption('quickFilterText', this.filterText);
    }

    openStoreSelection(): void {
        const dialogRef = this.dialog.open(PriceChangeStoreSelectionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                stores: this.stores,
                storeGroups: this.storeGroups,
                tags: this.tags
            },
        });

        dialogRef.afterClosed().subscribe((result: StoreSelectionDialogData) => {
            if (result) {
                // Set store selection
                this.stores = result.stores;
                this.selectedStores = result.selectedStores;

                // Persist store selection
                history.replaceState({
                    stores: this.stores,
                    selectedStores: this.selectedStores,
                }, null);

                if (this.selectedStores.length > 0) {
                    // Reset and Fetch report categories
                    let storeIDs = this.selectedStores.map(store => store.id);
                    this.priceChangeService.getReportCategories(storeIDs).then(reportCats => {
                        this.reportCategories = reportCats;
                    });
                    this.categorySelectedBy = null;
                    this.numCategoryGroupsSelected = 0;
                    this.selectedCategories = [];

                    // Reset products
                    this.products = [];
                    this.rowData = [];
                    this.dbService.clear('orgProducts').subscribe(() => { });

                }
            }
            this.cd.detectChanges();
        });
    }

    openReportCategorySelection(): void {
        const dialogRef = this.dialog.open(PriceChangeReportCategorySelectionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                selectedStores: this.selectedStores,
                reportCategories: this.reportCategories,
                selectedBy: this.categorySelectedBy,
                maxSelection: this.maxCategoryGroupSelection,
                numSelected: this.numCategoryGroupsSelected,
                isPriceMatch: false
            },
        });

        dialogRef.afterClosed().subscribe((result: ReportCategorySelectionDialogData) => {
            if (result) {

                // Set report category selection
                this.categorySelectedBy = result.selectedBy;
                this.numCategoryGroupsSelected = result.numSelected;
                this.reportCategories = result.reportCategories;
                this.selectedCategories = result.reportCategories.filter(rc => {
                    return rc.isSelected;
                });

                // Persist report category selection
                history.replaceState({
                    ...history.state,
                    categorySelectedBy: this.categorySelectedBy,
                    numCategoryGroupsSelected: this.numCategoryGroupsSelected,
                    reportCategories: this.reportCategories,
                    selectedCategories: this.selectedCategories,
                }, null);

                // Fetch products
                this.priceChangeService.getProducts(this.selectedCategories).then(result => {
                    this.products = result;

                    // want to clear any objects in db first, then add new results
                    this.dbService.clear('orgProducts').subscribe(() => {
                        this.dbService.add('orgProducts', { id: 1, products: result }).subscribe(() => {
                        })
                    });

                    // reset price change array, cell colours and submit button
                    this.priceChange = {
                        info: { PriceChangeDate: '', RollbackDate: '' },
                        rollbackPrices: [],
                        changedPrices: [],
                        label: ''
                    };
                    this.modifiedCells.clear(); // Clear all modifications tracking
                    this.gridApi.redrawRows();
                    this.cd.detectChanges();


                    this.applyGroupBy(this.groupBy);

                    // Persist product data
                    history.replaceState({
                        ...history.state,
                        products: this.products,
                        rowData: this.rowData,
                    }, null);
                });
            }
            this.cd.detectChanges();
        });
    }

    applyGroupBy(groupBy: string): void {
        this.groupBy = groupBy;

        // Persist group by data
        history.replaceState({
            ...history.state,
            groupBy: this.groupBy,
        }, null);

        // Group products into rowData
        this.rowData = this.groupProducts(this.products, groupBy);

        // Update grid with rowData
        this.gridApi.setGridOption('rowData', this.rowData);

        //Update columns
        if (groupBy === "Descript") {
            this.gridApi.setColumnsVisible(["Stores"], true);
            this.colDefs.find(c => c.headerName === "Description").hide = false;
            this.colDefs.find(c => c.headerName === "PLink").hide = true;
        }
        else if (groupBy === "PLink") {
            this.gridApi.setColumnsVisible(["Stores"], true);
            this.colDefs.find(c => c.headerName === "Description").hide = true;
            this.colDefs.find(c => c.headerName === "PLink").hide = false;
        }
        else {
            this.gridApi.setColumnsVisible(["Stores"], false);
            this.colDefs.find(c => c.headerName === "Description").hide = false;
            this.colDefs.find(c => c.headerName === "PLink").hide = false;
        }
        this.numColsSelected = this.colDefs.filter(c => !c.hide).length;
        this.gridApi.setGridOption("columnDefs", this.colDefs);
    }

    openColumnSelection(): void {
        const dialogRef = this.dialog.open(PriceChangeColumnSelectionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                columns: this.colDefs,
                groupBy: this.groupBy
            },
        });

        dialogRef.afterClosed().subscribe((result: ColumnSelectionDialogData) => {
            if (result) {
                this.numColsSelected = result.columns.filter(c => !c.hide).length;
                this.gridApi.setGridOption("columnDefs", result.columns);
            }
        });


    }

    submitDialog(): void {
        this.createRollbackArray();
        const dialogRef = this.dialog.open(SubmitPriceChangeDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            minWidth: '25%',
            data: {
                priceChange: this.priceChange,
                selectedStores: this.selectedStores,
                conceptID: this.conceptID
            },
        });
        dialogRef.afterClosed().subscribe((result: SubmitPriceChangeDialogData) => {
            if (result) {
                this.priceChangeService.updatePrices(result.priceChange);
                setTimeout(() => {
                    this.resetSelection(this.priceChange);
                }, 2000);
            }
        });
    }

    resetSelection(priceChange: PriceChange): void {
        priceChange.info = { RollbackDate: '', PriceChangeDate: '' };
        priceChange.rollbackPrices = [];
        priceChange.changedPrices = [];
        this.modifiedCells.clear(); // Clear all modifications tracking
        this.gridApi.redrawRows(); // Redraw all rows to reset styles
        this.cd.detectChanges();
    }

    openCellMenu(contextMenuEvent: CellContextMenuEvent): void {
        this.contextMenuEvent = contextMenuEvent;

        // check if a cell is in edit mode and do not open menu, if not can open menu
        const rowNode = this.contextMenuEvent.node;
        const colId = this.contextMenuEvent.column.getId();
        const cellEditorInstances = this.gridApi.getCellEditorInstances({
            rowNodes: [rowNode],
            columns: [colId]
        });
        if (cellEditorInstances.length > 0) {
            this.contextMenuEvent.event.preventDefault();
            this.contextMenuEvent.event.stopPropagation();
        } else {


            const x = (this.contextMenuEvent.event as PointerEvent)?.pageX + 1;
            const y = (this.contextMenuEvent.event as PointerEvent)?.pageY + 1;

            // hide apply Price to Row if clicked on Decript/Plink/Store columns
            if (this.contextMenuEvent.colDef.field != 'products') {
                this.hideApplyPriceToRow = true;
                // hide apply Price to Row if clicked on PRICE MOD
            } else if (this.contextMenuEvent.colDef.headerName == "Price MOD") this.hideApplyPriceToRow = true;
            else this.hideApplyPriceToRow = false

            //  only groupby condition will show row comp option, other options will always show
            this.contextMenuTrigger.open({ x, y });

        }
    }


    viewRowComposition(rowData: ProductGroup): void {
        this.dialog.open(PriceChangeRowCompDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '40vw',
            data: rowData
        });
    }

    applyPricesToRow(rowData: ProductGroup): void {
        let value = this.contextMenuEvent.value;
        let newProduct: Product[] = [];

        // variables used to differentiate which columns were hidden and not to appy new price to  
        let applyToPriceA: boolean = false, applyToPriceB: boolean = false, applyToPriceC: boolean = false,
            applyToPriceD: boolean = false, applyToPriceE: boolean = false, applyToPriceF: boolean = false,
            applyToPriceG: boolean = false, applyToPriceH: boolean = false, applyToPriceI: boolean = false, applyToPriceJ: boolean = false;

        rowData.products.forEach(product => {
            this.colDefs.forEach(element => {
                if (element.headerName == 'PriceA' && !element.hide) {
                    this.createProductObject("A", value, product)
                    applyToPriceA = true;
                } else if (element.headerName == 'PriceB' && !element.hide) {
                    this.createProductObject("B", value, product)
                    applyToPriceB = true;
                } else if (element.headerName == 'PriceC' && !element.hide) {
                    this.createProductObject("C", value, product)
                    applyToPriceC = true;
                } else if (element.headerName == 'PriceD' && !element.hide) {
                    this.createProductObject("D", value, product)
                    applyToPriceD = true;
                } else if (element.headerName == 'PriceE' && !element.hide) {
                    this.createProductObject("E", value, product)
                    applyToPriceE = true;
                } else if (element.headerName == 'PriceF' && !element.hide) {
                    this.createProductObject("F", value, product)
                    applyToPriceF = true;
                } else if (element.headerName == 'PriceG' && !element.hide) {
                    this.createProductObject("G", value, product)
                    applyToPriceG = true;
                } else if (element.headerName == 'PriceH' && !element.hide) {
                    this.createProductObject("H", value, product)
                    applyToPriceH = true
                } else if (element.headerName == 'PriceI' && !element.hide) {
                    this.createProductObject("I", value, product)
                    applyToPriceI = true;
                } else if (element.headerName == 'PriceJ' && !element.hide) {
                    this.createProductObject("J", value, product)
                    applyToPriceJ = true;

                }
            });

            newProduct.push({
                StoreID: product.StoreID,
                ProdNum: product.ProdNum,
                Descript: product.Descript,
                PLink: product.PLink,
                PriceA: applyToPriceA ? value : product.PriceA,
                PriceB: applyToPriceB ? value : product.PriceB,
                PriceC: applyToPriceC ? value : product.PriceC,
                PriceD: applyToPriceD ? value : product.PriceD,
                PriceE: applyToPriceE ? value : product.PriceE,
                PriceF: applyToPriceF ? value : product.PriceF,
                PriceG: applyToPriceG ? value : product.PriceG,
                PriceH: applyToPriceH ? value : product.PriceH,
                PriceI: applyToPriceI ? value : product.PriceI,
                PriceJ: applyToPriceJ ? value : product.PriceJ,
                PriceMode: product.PriceMode
            })

        });

        let columns = this.gridApi.getColumns();
        columns.forEach((column: any) => {
            if (!column.colDef.hide && column.colDef.headerName != "Price MOD") {
                const colId = column.getColId();
                const cellKey = `${this.contextMenuEvent.node.rowIndex}-${column.colId}`;
                this.modifiedCells.set(cellKey, true);
                this.gridApi.refreshCells({ rowNodes: [this.contextMenuEvent.node], columns: [colId], force: true });
            }
        })


        this.contextMenuEvent.node.setData({
            StoreID: this.contextMenuEvent.data.StoreID,
            Descript: this.contextMenuEvent.data.Descript,
            products: newProduct
        })

        this.gridApi.refreshCells({ force: true });

    }

    async applyPriceToColumn(rowData: ProductGroup): Promise<void> {
        let value = this.contextMenuEvent.value;
        let colHeaderName = this.contextMenuEvent.colDef.headerName;
        let lastLetter = colHeaderName.slice(-1);
        this.processedCount = 0;
        document.body.classList.add("overlay-active");
        this.isDoneProcessing = false;


        this.gridApi.forEachNode(async (rowNode) => {
            let newProduct: Product[] = [];

            for (const product of rowNode.data.products) {

                await this.createProductObject(lastLetter, value, product)
                newProduct.push({
                    StoreID: product.StoreID,
                    ProdNum: product.ProdNum,
                    Descript: product.Descript,
                    PLink: product.PLink,
                    PriceA: (lastLetter == "A") ? value : product.PriceA,
                    PriceB: (lastLetter == "B") ? value : product.PriceB,
                    PriceC: (lastLetter == "C") ? value : product.PriceC,
                    PriceD: (lastLetter == "D") ? value : product.PriceD,
                    PriceE: (lastLetter == "E") ? value : product.PriceE,
                    PriceF: (lastLetter == "F") ? value : product.PriceF,
                    PriceG: (lastLetter == "G") ? value : product.PriceG,
                    PriceH: (lastLetter == "H") ? value : product.PriceH,
                    PriceI: (lastLetter == "I") ? value : product.PriceI,
                    PriceJ: (lastLetter == "J") ? value : product.PriceJ,
                    PriceMode: product.PriceMode
                })

                this.processedCount++;
                this.cd.detectChanges();

                if (this.processedCount == this.products.length) {
                    this.isDoneProcessing = true;
                    document.body.classList.remove("overlay-active");
                    this.cd.detectChanges();
                }

            }

            let columns = this.gridApi.getColumns();
            columns.forEach((column: any) => {
                if (column.colDef.headerName == colHeaderName) {
                    const colId = column.getColId();
                    const cellKey = `${rowNode.rowIndex}-${column.colId}`;
                    this.modifiedCells.set(cellKey, true);
                    this.gridApi.refreshCells({ rowNodes: [rowNode], columns: [colId], force: true });
                }
            })

            rowNode.setData({
                StoreID: rowNode.data.StoreID,
                Descript: rowNode.data.Descript,
                products: newProduct
            })


        })

        this.gridApi.refreshCells({ force: true });

    }

    resetRowPrices(rowData: ProductGroup) {
        let newProduct: Product[] = [];
        // get the org products
        this.dbService.getByKey('orgProducts', 1).subscribe(orgProducts => {
            this.orgProducts = orgProducts.products;
            // loop through products of row 
            rowData.products.forEach(product => {
                // find the product in changed products array and in original array 
                let existingProductIndex = this.priceChange.changedPrices.findIndex(p => p.StoreID === product.StoreID && p.ProdNum === product.ProdNum);
                let originalProductIndex = this.orgProducts.findIndex(p => p.StoreID === product.StoreID && p.ProdNum === product.ProdNum);

                // remove product from changed products 
                if (existingProductIndex !== -1) {
                    this.priceChange.changedPrices.splice(existingProductIndex, 1);
                }

                // put the orginal values back in 
                if (originalProductIndex !== -1) {
                    newProduct.push({
                        StoreID: product.StoreID,
                        ProdNum: product.ProdNum,
                        Descript: product.Descript,
                        PLink: product.PLink,
                        PriceA: this.orgProducts[originalProductIndex].PriceA,
                        PriceB: this.orgProducts[originalProductIndex].PriceB,
                        PriceC: this.orgProducts[originalProductIndex].PriceC,
                        PriceD: this.orgProducts[originalProductIndex].PriceD,
                        PriceE: this.orgProducts[originalProductIndex].PriceE,
                        PriceF: this.orgProducts[originalProductIndex].PriceF,
                        PriceG: this.orgProducts[originalProductIndex].PriceG,
                        PriceH: this.orgProducts[originalProductIndex].PriceH,
                        PriceI: this.orgProducts[originalProductIndex].PriceI,
                        PriceJ: this.orgProducts[originalProductIndex].PriceJ,
                        PriceMode: product.PriceMode
                    })
                }

            })

            // assign the orginal products back to row and remove yellow background
            let columns = this.gridApi.getColumns();
            columns.forEach(column => {
                const colId = column.getColId();
                const cellKey = `${this.contextMenuEvent.node.rowIndex}-${column.colId}`;
                this.modifiedCells.set(cellKey, false);
                this.gridApi.refreshCells({ rowNodes: [this.contextMenuEvent.node], columns: [colId], force: true });
            })

            this.contextMenuEvent.node.setData({
                StoreID: this.contextMenuEvent.data.StoreID,
                Descript: this.contextMenuEvent.data.Descript,
                products: newProduct
            })

            this.gridApi.refreshCells({ force: true });

        });

    }

    setRowPrice(rowData: ProductGroup) {
        const dialogRef = this.dialog.open(SetPriceDialog, {
            data: {
                isRow: true
            },
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
        });

        dialogRef.afterClosed().subscribe((result: SetPriceDialogData) => {
            if (result) {
                let rowChange = result.change;
                let isPercentage = rowChange.dropdown == 'Percentage' ? true : false
                let floatInput = parseFloat(rowChange.inputBox)
                let newProduct: Product[] = [];

                // variables used to differentiate which columns were hidden and not to appy new price to  
                let applyToPriceA: boolean = false, applyToPriceB: boolean = false, applyToPriceC: boolean = false,
                    applyToPriceD: boolean = false, applyToPriceE: boolean = false, applyToPriceF: boolean = false,
                    applyToPriceG: boolean = false, applyToPriceH: boolean = false, applyToPriceI: boolean = false, applyToPriceJ: boolean = false;

                let valueA, valueB, valueC, valueD, valueE, valueF, valueG, valueH, valueI, valueJ;

                rowData.products.forEach(product => {
                    this.colDefs.forEach(element => {
                        if (element.headerName == 'PriceA' && !element.hide) {
                            valueA = this.applyChange(rowChange.actionType, floatInput, product.PriceA, isPercentage)
                            this.createProductObject("A", valueA, product)
                            applyToPriceA = true;
                        } else if (element.headerName == 'PriceB' && !element.hide) {
                            valueB = this.applyChange(rowChange.actionType, floatInput, product.PriceB, isPercentage),
                                this.createProductObject("B", valueB, product)
                            applyToPriceB = true;
                        } else if (element.headerName == 'PriceC' && !element.hide) {
                            valueC = this.applyChange(rowChange.actionType, floatInput, product.PriceC, isPercentage)
                            this.createProductObject("C", valueC, product)
                            applyToPriceC = true;
                        } else if (element.headerName == 'PriceD' && !element.hide) {
                            valueD = this.applyChange(rowChange.actionType, floatInput, product.PriceD, isPercentage)
                            this.createProductObject("D", valueD, product)
                            applyToPriceD = true;
                        } else if (element.headerName == 'PriceE' && !element.hide) {
                            valueE = this.applyChange(rowChange.actionType, floatInput, product.PriceE, isPercentage)
                            this.createProductObject("E", valueE, product)
                            applyToPriceE = true;
                        } else if (element.headerName == 'PriceF' && !element.hide) {
                            valueF = this.applyChange(rowChange.actionType, floatInput, product.PriceF, isPercentage)
                            this.createProductObject("F", valueF, product)
                            applyToPriceF = true;
                        } else if (element.headerName == 'PriceG' && !element.hide) {
                            valueG = this.applyChange(rowChange.actionType, floatInput, product.PriceG, isPercentage)
                            this.createProductObject("G", valueG, product)
                            applyToPriceG = true;
                        } else if (element.headerName == 'PriceH' && !element.hide) {
                            valueH = this.applyChange(rowChange.actionType, floatInput, product.PriceH, isPercentage)
                            this.createProductObject("H", valueH, product)
                            applyToPriceH = true
                        } else if (element.headerName == 'PriceI' && !element.hide) {
                            valueI = this.applyChange(rowChange.actionType, floatInput, product.PriceI, isPercentage)
                            this.createProductObject("I", valueI, product)
                            applyToPriceI = true;
                        } else if (element.headerName == 'PriceJ' && !element.hide) {
                            valueJ = this.applyChange(rowChange.actionType, floatInput, product.PriceJ, isPercentage)
                            this.createProductObject("J", valueJ, product)
                            applyToPriceJ = true;
                        }
                    });

                    newProduct.push({
                        StoreID: product.StoreID,
                        ProdNum: product.ProdNum,
                        Descript: product.Descript,
                        PLink: product.PLink,
                        PriceA: applyToPriceA ? valueA : product.PriceA,
                        PriceB: applyToPriceB ? valueB : product.PriceB,
                        PriceC: applyToPriceC ? valueC : product.PriceC,
                        PriceD: applyToPriceD ? valueD : product.PriceD,
                        PriceE: applyToPriceE ? valueE : product.PriceE,
                        PriceF: applyToPriceF ? valueF : product.PriceF,
                        PriceG: applyToPriceG ? valueG : product.PriceG,
                        PriceH: applyToPriceH ? valueH : product.PriceH,
                        PriceI: applyToPriceI ? valueI : product.PriceI,
                        PriceJ: applyToPriceJ ? valueJ : product.PriceJ,
                        PriceMode: product.PriceMode
                    })

                });

                let columns = this.gridApi.getColumns();
                columns.forEach((column: any) => {
                    if (!column.colDef.hide && column.colDef.headerName != "Price MOD") {
                        const colId = column.getColId();
                        const cellKey = `${this.contextMenuEvent.node.rowIndex}-${column.colId}`;
                        this.modifiedCells.set(cellKey, true);
                        this.gridApi.refreshCells({ rowNodes: [this.contextMenuEvent.node], columns: [colId], force: true });
                    }
                })


                this.contextMenuEvent.node.setData({
                    StoreID: this.contextMenuEvent.data.StoreID,
                    Descript: this.contextMenuEvent.data.Descript,
                    products: newProduct
                })

                this.gridApi.refreshCells({ force: true });
                this.cd.detectChanges();

            }
        });

    }

    setColumnPrice(rowData: ProductGroup) {
        const dialogRef = this.dialog.open(SetPriceDialog, {
            data: {
                isRow: false
            },
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
        });

        dialogRef.afterClosed().subscribe((result: SetPriceDialogData) => {
            if (result) {
                let columnChange = result.change;
                let isPercentage = columnChange.dropdown == 'Percentage' ? true : false
                let floatInput = parseFloat(columnChange.inputBox)
                let colHeaderName = this.contextMenuEvent.colDef.headerName;
                let lastLetter = colHeaderName.slice(-1);
                this.processedCount = 0;
                document.body.classList.add("overlay-active");
                this.isDoneProcessing = false;

                this.gridApi.forEachNode(async (rowNode) => {
                    let newProduct: Product[] = [];

                    for (const product of rowNode.data.products) {
                        let value = this.applyChange(columnChange.actionType, floatInput, product[colHeaderName], isPercentage)
                        await this.createProductObject(lastLetter, value, product)
                        newProduct.push({
                            StoreID: product.StoreID,
                            ProdNum: product.ProdNum,
                            Descript: product.Descript,
                            PLink: product.PLink,
                            PriceA: (lastLetter == "A") ? value : product.PriceA,
                            PriceB: (lastLetter == "B") ? value : product.PriceB,
                            PriceC: (lastLetter == "C") ? value : product.PriceC,
                            PriceD: (lastLetter == "D") ? value : product.PriceD,
                            PriceE: (lastLetter == "E") ? value : product.PriceE,
                            PriceF: (lastLetter == "F") ? value : product.PriceF,
                            PriceG: (lastLetter == "G") ? value : product.PriceG,
                            PriceH: (lastLetter == "H") ? value : product.PriceH,
                            PriceI: (lastLetter == "I") ? value : product.PriceI,
                            PriceJ: (lastLetter == "J") ? value : product.PriceJ,
                            PriceMode: product.PriceMode
                        })

                        this.processedCount++;
                        this.cd.detectChanges();

                        if (this.processedCount == this.products.length) {
                            this.isDoneProcessing = true;
                            document.body.classList.remove("overlay-active");
                            this.cd.detectChanges();
                        }

                    }

                    let columns = this.gridApi.getColumns();
                    columns.forEach((column: any) => {
                        if (column.colDef.headerName == colHeaderName) {
                            const colId = column.getColId();
                            const cellKey = `${rowNode.rowIndex}-${column.colId}`;
                            this.modifiedCells.set(cellKey, true);
                            this.gridApi.refreshCells({ rowNodes: [rowNode], columns: [colId], force: true });
                        }
                    })

                    rowNode.setData({
                        StoreID: rowNode.data.StoreID,
                        Descript: rowNode.data.Descript,
                        products: newProduct
                    })


                })

                this.gridApi.refreshCells({ force: true });

            }
        });

    }





    applyChange(action, value, baseNumber, isPercentage) {
        if (typeof baseNumber !== 'number' || typeof value !== 'number') {
            throw new Error('Both value and baseNumber must be numbers');
        }

        if (action !== 'increase' && action !== 'decrease') {
            throw new Error('Action must be either "increase" or "decrease"');
        }

        // Calculate the amount to change
        let changeAmount = isPercentage ? (value / 100) * baseNumber : value;

        if (action === 'decrease') {
            changeAmount *= -1; // Reverse the change if action is 'decrease'
        }
        // Apply the change to the base number
        let result = baseNumber + changeAmount;

        // Ensure the result is not negative
        return result < 0 ? 0 : result;
    }

    private async createProductObject(productColumn, value, product): Promise<void> {
        let nameOfColumn = "Price" + productColumn
        let productNewPrice = { [nameOfColumn]: value.toFixed(2), ProdNum: product["ProdNum"], StoreID: product["StoreID"] };
        await this.addProduct(productNewPrice, true);
    }


    private groupProducts(products: Product[], groupBy: string): ProductGroup[] {
        let groupedProducts: ProductGroup[] = [];
        products.forEach(product => {
            if (groupBy === "Descript") {
                const existingProductGroup = groupedProducts.find(productGroup => {
                    const pgDescript = productGroup.Descript?.trim().toLowerCase();
                    const pDescript = product.Descript?.trim().toLowerCase();
                    return pgDescript === pDescript;
                });

                if (existingProductGroup) {
                    existingProductGroup.products.push(product);
                }
                else if (product.Descript) {
                    const newProductGroup: ProductGroup = {
                        StoreID: product.StoreID,
                        Descript: product.Descript,
                        products: [product],
                    }
                    groupedProducts.push(newProductGroup);
                }
            }
            else if (groupBy === "PLink") {
                const existingProductGroup = groupedProducts.find(productGroup => {
                    const pgPLink = productGroup.PLink?.trim().toLowerCase();
                    const pPLink = product.PLink?.trim().toLowerCase();
                    return pgPLink === pPLink;
                });

                if (existingProductGroup) {
                    existingProductGroup.products.push(product);
                }
                else if (product.PLink) {
                    const newProductGroup: ProductGroup = {
                        StoreID: product.StoreID,
                        PLink: product.PLink,
                        products: [product],
                    }
                    groupedProducts.push(newProductGroup);
                }
            }
            else {
                const newProductGroup: ProductGroup = {
                    StoreID: product.StoreID,
                    Descript: product.Descript,
                    PLink: product.PLink,
                    products: [product],
                }
                groupedProducts.push(newProductGroup);
            }
        });

        return groupedProducts;
    }

    private nullFormatter(params: ValueFormatterParams): string {
        // use (==) instead of (===) to match both null and undefined
        if (params.value == null) {
            return "N/A"
        }
        return params.value;
    }

    private groupPriceFormatter(priceType: string, params: ValueFormatterParams): any {
        const products = params.data.products as Product[];
        const firstProductPrice = products[0][priceType] as number;
        const allPricesAreEqual = products.every(p => p[priceType] === firstProductPrice)

        if (products.length > 1) {
            return allPricesAreEqual ? firstProductPrice.toFixed(2).toString() : "...";
        }
        else {
            return firstProductPrice.toFixed(2).toString();
        }
    }

    private getProductToolTip() {
        if (this.groupBy == "PLink") {
            return this.captions.numProdPLinkSelected;
        } else return;
    }

}
