import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { GroupWithItems } from '../models/group-item.model';

export interface StoreGroup {
    displayName: string,
    groupID: number,
    numberOfItems: number,
    currentEdit?: boolean,
    assignedItemId?: number,
    groupItems?: any[]
}

@Injectable()
export class StoreGroupApiService {
    private createStoreGroupUrl: string = 'groups';
    private getStoreGroupUrl: string = 'groups/{groupID}';
    private updateStoreGroupUrl: string = 'groups/{groupID}';
    private removeStoreGroupUrl: string = 'groups/{groupID}';

    private BASE_URL;

    constructor(@Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async createStoreGroup(storeGroup) {
        const url = this.BASE_URL + this.createStoreGroupUrl;
        const validStoreGroup = this.dataUtils.getCleanedObjectFields(storeGroup, ['conceptID', 'displayName']);
        const data$ = this.http.post(url, validStoreGroup);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getStoreGroupById(storeGroupId) {
        const getStoreGroupUrlValid = this.getStoreGroupUrl.replace('{groupID}', storeGroupId);
        const url = this.BASE_URL + getStoreGroupUrlValid;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateStoreGroup(storeGroup) {
        let updateStoreGroupUrlValid = this.updateStoreGroupUrl.replace('{groupID}', storeGroup.groupID);
        if (storeGroup.groupID === undefined)
            updateStoreGroupUrlValid = this.updateStoreGroupUrl.replace('{groupID}', storeGroup.id);
        const url = this.BASE_URL + updateStoreGroupUrlValid;
        const validStoreGroup = this.dataUtils.getCleanedObjectFields(storeGroup, ['displayName']);
        const data$ = this.http.put(url, validStoreGroup);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getAllStoreGroupsForConcept(conceptId) {
        const url = this.BASE_URL + this.createStoreGroupUrl;
        const data$ = this.http.get(url, {
            params: { conceptID: conceptId, view: 'numberOfItems' },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getAllStoreGroupsWithItemsForConcept(conceptId: number): Promise<GroupWithItems[]> {
        const url = this.BASE_URL + this.createStoreGroupUrl;
        const data$ = this.http.get(url, {
            params: { conceptID: conceptId, view: 'items' },
        });
        const value = await lastValueFrom(data$) as GroupWithItems[];
        return value;
    }

    async deleteStoreGroup(groupId) {
        const removeStoreGroupUrlValid = this.removeStoreGroupUrl.replace('{groupID}', groupId);
        const url = this.BASE_URL + removeStoreGroupUrlValid;
        const data$ = this.http.delete(url);
        const value = await lastValueFrom(data$);
        return value;
    }
}
