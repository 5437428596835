import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'no-stores-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/no-stores-dialog.html',
})
export class NoStoresDialog {

    public captions: Record<string, string>;
    
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<NoStoresDialog>,
        @Inject(CaptionService) private captionService: CaptionService
    ) {
        this.captions = this.captionService.captions;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
