import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { StoreGroupMembersApiService } from '../../../features/store-groups/services/store-group-member.resource';
import { StoreApiService } from '../../../features/stores/services/store.resource';
import { TagApiService } from '../../../features/tags/services/tag.resource';
import { UserAccessHandler } from '../../../features/users/services/user-access-handler.service';
import { UserEmitterService } from '../../../features/users/services/user-emitter.service';
import { UserSecurityApiService } from '../../../features/users/services/user-security.resource';
import { UserConstants } from '../../../features/users/users.constants';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { EmitterService } from '../../shared-services/emitter.service';
import { StorePickerDialog } from './store-picker-dialog.component';

@Component({
    selector: 'storePicker',
    templateUrl: 'src/app/utils/shared-templates/user-access/store-picker.html',
})
export class StorePickerComponent {
    @Input() selectedUserSecurity;
    @Input() isExtractionPage: boolean = false;
    @Input() storeItemMultiSelect: boolean = false;
    @Input() showSelectAllButton: boolean = false;
    @Output() storePicker: EventEmitter<any> = new EventEmitter();

    private USER_ACCESS;
    public captions;
    public currentUserAccess;
    public passedUserId;
    public stores;
    public showStoreBox;
    public showAllStoresBox;
    public eventName: String;
    public customAllStoresName;
    public concept;
    public groupItem;
    public tag;
    public showErrorStoresBox;
    public inactiveStoreMsg;
    public selectedStore;
    // this is where the stores that have been selected are stored
    public selectedStores;
    // if more than one store is selected, we need to consider for the case where we need to create tags and cancel
    // if we cancel, we can restore from selectedStoresBackup
    public selectedStoresBackup;
    public storeCheckboxes;
    public popUpForTagCreation;
    public allStores;
    public subscriptions: Subscription[] = [];
    public groupItemModel;
    public tagModel;
    public storePickerModel: number;
    public newTag: any;
    public storesForTag: any;

    constructor(
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UserSecurityApiService) private userSecurityApiService: UserSecurityApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(UserEmitterService) private userEmitterService: UserEmitterService,
        @Inject(EmitterService) private emitterService: EmitterService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUserAccess = userAccessHandler.getUserAccess();
        this.showStoreBox = false;
        this.popUpForTagCreation = false;
        this.storeCheckboxes = true;
        this.showErrorStoresBox = false;
        this.userEmitterService = userEmitterService;
        this.emitterService = emitterService;

        this.subscriptions.push(
            this.userEmitterService.conceptLoadedDirectiveItem$.subscribe((concept) => {
                this.concept = concept;
                this.eventName = 'conceptLoadedDirective';

                if (typeof this.selectedUserSecurity == 'undefined') {
                    this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
                        this.selectedUserSecurity = selectedUserSecurity;
                        if (
                            this.selectedUserSecurity.role === this.USER_ACCESS.GROUP_ITEM ||
                            this.currentUserAccess.role === this.USER_ACCESS.GROUP_ITEM
                        ) {
                            this.subscriptions.push(
                                this.userEmitterService.groupItemLoadedDirectiveItem$.subscribe((groupItem: any) => {
                                    this.groupItem = groupItem;
                                    this.groupItemModel = groupItem.id;
                                    this.tagModel = 0;
                                    this.getStoresByUserAccess();
                                })
                            );
                        } else if (
                            this.selectedUserSecurity.role === this.USER_ACCESS.TAG ||
                            this.currentUserAccess.role === this.USER_ACCESS.TAG
                        ) {
                            this.subscriptions.push(
                                this.userEmitterService.tagLoadedDirectiveItem$.subscribe((tag: any) => {
                                    this.tag = tag;
                                    this.tagModel = tag.id;
                                    this.groupItemModel = 0;
                                    this.getStoresByUserAccess();
                                })
                            );
                        } else this.getStoresByUserAccess();
                    });
                } else {
                    if (
                        this.selectedUserSecurity.role === this.USER_ACCESS.GROUP_ITEM ||
                        this.currentUserAccess.role === this.USER_ACCESS.GROUP_ITEM
                    ) {
                        this.subscriptions.push(
                            this.userEmitterService.groupItemLoadedDirectiveItem$.subscribe((groupItem: any) => {
                                this.groupItem = groupItem;
                                this.groupItemModel = groupItem.id;
                                this.tagModel = 0;
                                this.getStoresByUserAccess();
                            })
                        );
                    } else if (
                        this.selectedUserSecurity.role === this.USER_ACCESS.TAG ||
                        this.currentUserAccess.role === this.USER_ACCESS.TAG
                    ) {
                        this.subscriptions.push(
                            this.userEmitterService.tagLoadedDirectiveItem$.subscribe((tag: any) => {
                                this.tag = tag;
                                this.tagModel = tag.id;
                                this.groupItemModel = 0;
                                this.getStoresByUserAccess();
                            })
                        );
                    } else {
                        this.getStoresByUserAccess();
                    }
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.conceptChangedDirectiveItem$.subscribe((concept: any) => {
                this.stores = [];
                this.groupItem = undefined;
                this.tag = undefined;
                this.concept = concept;
                this.resetStoreIsChecked();
                if (concept.id !== 0 && typeof concept.id !== 'undefined') {
                    this.showAllStoresBox = true;
                    this.showStoreBox = true;
                    this.storePickerModel = 0;
                    this.storePicker.emit(this.storePickerModel);
                    storeApiService
                        .getStoresByConceptId(concept.id)
                        .then((stores) => this.getAllActiveStoresSuccess(stores));
                    this.customAllStoresName = this.captions.allStores + ' (' + concept.displayName + ')';
                } else if (concept.id === 0) {
                    this.showStoreBox = false;
                    this.storePickerModel = 0;
                    this.storePicker.emit(this.storePickerModel);
                }
            })
        );

        // subscribe to the groups being selected in manage user access
        this.subscriptions.push(
            this.userEmitterService.groupItemChangedDirectiveItem$.subscribe((groupItemAndConcept) => {
                this.resetStoreIsChecked();
                this.stores = [];
                this.tagModel = 0;
                this.groupItem = groupItemAndConcept[0];
                this.tag = null;
                this.groupItemModel = groupItemAndConcept[0].id;
                this.eventName = 'groupItemChangedDirective';

                if (groupItemAndConcept[0].id === 0 || groupItemAndConcept[0][0].id === 0) {
                    this.storePickerModel = 0;
                    this.storePicker.emit(this.storePickerModel);
                    this.customAllStoresName =
                        this.captions.allStores + ' (' + groupItemAndConcept[1].displayName + ')';
                    this.storeApiService
                        .getStoresByConceptId(this.concept.id)
                        .then((stores) => this.getAllActiveStoresSuccess(stores));
                } else if (groupItemAndConcept[0][0]?.id !== 0 && groupItemAndConcept[0][0]?.id !== 'undefined') {
                    this.showAllStoresBox = true;
                    this.showStoreBox = true;
                    this.storePickerModel = 0;
                    this.storePicker.emit(this.storePickerModel);
                    this.customAllStoresName =
                        this.captions.allStores + ' (' + groupItemAndConcept[0][0].displayName + ')';
                    storeGroupMembersApiService
                        .getGroupItemInfoById(groupItemAndConcept[0][0].id)
                        .then((stores) => this.getAllActiveStoresSuccess(stores));
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.tagChangedDirectiveItem$.subscribe((tagAndConcept) => {
                this.resetStoreIsChecked();
                if (tagAndConcept[0]) {
                    this.stores = [];
                    this.groupItemModel = 0;
                    this.tag = tagAndConcept[0][0] ?? tagAndConcept[0];
                    this.tagModel = this.tag.id;
                    this.groupItem = null;
                    if (this.tag.id !== 0 && this.tag.id !== 'undefined') {
                        this.showAllStoresBox = true;
                        this.showStoreBox = true;
                        this.storePickerModel = 0;
                        this.storePicker.emit(this.storePickerModel);
                        this.customAllStoresName = this.captions.allStores + ' (' + this.tag.displayName + ')';
                        tagApiService
                            .getTagInfoById(this.tag.id)
                            .then((stores) => this.getAllActiveStoresSuccess(stores));
                    } else if (this.tag.id === 0) {
                        this.storePickerModel = 0;
                        this.selectedStores = [];
                        this.showAllStoresBox = true;
                        this.customAllStoresName = this.captions.allStores + ' (' + tagAndConcept[1].displayName + ')';
                        this.storeApiService
                            .getStoresByConceptId(this.concept.id)
                            .then((stores) => this.getAllActiveStoresSuccess(stores));
                    }
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.createTagForSelectedStoresItem$.subscribe((tag: any) => {
                this.tag = tag;
                this.storePickerModel = 0;
                this.storePicker.emit(this.storePickerModel);
                this.emitterService.storeChangedForGroupItemCtrl(this.selectedStore, this.groupItem);
                this.customAllStoresName = this.captions.allStores + ' (' + tag.displayName + ')';
                this.showAllStoresBox = true;
            })
        );

        this.subscriptions.push(
            this.userEmitterService.cancelTagCreationItem$.subscribe((event) => {
                for (let aStore of this.selectedStores) {
                    delete aStore?.isChecked;
                }
                this.selectedStores = this.selectedStoresBackup;
            })
        );
    }

    ngOnDestroy() {
        for (let sub of this.subscriptions) {
            sub?.unsubscribe();
        }
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            if (this.isExtractionPage) {
                this.passedUserId = Number(this.itemStorageService.webUserID) ?? +params['pathId'];
            } else {
                this.passedUserId = +params['pathId'];
            }
        });
        if (this.currentUserAccess === null || typeof this.currentUserAccess == 'undefined') {
            this.currentUserAccess = this.userAccessHandler.getUserAccess();
        }
        this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
            if (
                this.currentUserAccess.role !== this.USER_ACCESS.GLOBAL &&
                this.currentUserAccess.role !== this.USER_ACCESS.CONCEPT
            )
                this.storeCheckboxes = false;
            this.selectedUserSecurity = selectedUserSecurity;
            this.customAllStoresName = this.captions.allStores;
        });
        this.allStores = {
            id: 0,
        };
        this.stores = [];
    }

    async getWebUserSecurity() {
        return this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
            this.selectedUserSecurity = selectedUserSecurity;
            return this.selectedUserSecurity;
        });
    }

    async getStoresByUserAccess() {
        if (typeof this.selectedUserSecurity == "undefined") {
            await this.getWebUserSecurity()
        }
        if (
            this.currentUserAccess.role === this.USER_ACCESS.GLOBAL &&
            this.selectedUserSecurity.role !== this.USER_ACCESS.GROUP_ITEM &&
            this.selectedUserSecurity.role !== this.USER_ACCESS.TAG &&
            this.isExtractionPage
        ) {
            let conceptID = Number(this.actRoute.snapshot.paramMap.get('pathId'));
            this.storeApiService
                .getStoresByConceptId(conceptID)
                .then((stores) => this.getAllActiveStoresSuccess(stores));
        } else if (
            this.currentUserAccess.role === this.USER_ACCESS.GLOBAL &&
            this.selectedUserSecurity.role !== this.USER_ACCESS.GROUP_ITEM &&
            this.selectedUserSecurity.role !== this.USER_ACCESS.TAG
        ) {
            this.storeApiService
                .getStoresByConceptId(this.selectedUserSecurity.conceptID)
                .then((stores) => this.getAllActiveStoresSuccess(stores));
        } else if (
            this.currentUserAccess.role === this.USER_ACCESS.CONCEPT &&
            this.selectedUserSecurity.role !== this.USER_ACCESS.GROUP_ITEM &&
            this.selectedUserSecurity.role !== this.USER_ACCESS.TAG
        ) {
            this.storeApiService
                .getStoresByConceptId(this.currentUserAccess.conceptID)
                .then((stores) => this.getAllActiveStoresSuccess(stores));
        } else if (this.currentUserAccess.role === this.USER_ACCESS.GROUP_ITEM) {
            this.storeGroupMembersApiService
                .getGroupItemInfoById(this.currentUserAccess.groupItemID)
                .then((stores) => this.getAllActiveStoresSuccess(stores));
        } else if (this.currentUserAccess.role === this.USER_ACCESS.TAG) {
            this.tagApiService
                .getTagInfoById(this.currentUserAccess.tagID)
                .then((stores) => this.getAllActiveStoresSuccess(stores));
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.GROUP_ITEM) {
            this.storeGroupMembersApiService
                .getGroupItemInfoById(this.selectedUserSecurity.groupItemID)
                .then((stores) => this.getAllActiveStoresSuccess(stores));
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.TAG) {
            this.tagApiService
                .getTagInfoById(this.selectedUserSecurity.tagID)
                .then((stores) => this.getAllActiveStoresSuccess(stores));
        } else {
            this.storeApiService
                .getStoreById(this.currentUserAccess.storeID)
                .then((stores) => this.getStoreByIdSuccess(stores));
        }
    }

    async getAllActiveStoresSuccess(stores) {
        if (typeof this.selectedUserSecurity == "undefined") {
            await this.getWebUserSecurity()
        }
        this.stores = stores;
        if (this.selectedUserSecurity.role === this.USER_ACCESS.GLOBAL) {
            this.showStoreBox = true;
            this.showAllStoresBox = true;
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.CONCEPT) {
            this.showStoreBox = true;
            this.showAllStoresBox = true;
            if (
                this.eventName !== 'conceptChangedDirective' &&
                this.eventName !== 'groupItemChangedDirective' &&
                this.eventName !== 'tagChangedDirective'
            )
                this.customAllStoresName = this.captions.allStores + ' (' + this.concept.displayName + ')';
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.GROUP_ITEM) {
            this.showStoreBox = true;
            this.showAllStoresBox = true;
            if (
                this.eventName !== 'conceptChangedDirective' &&
                this.eventName !== 'groupItemChangedDirective' &&
                this.eventName !== 'tagChangedDirective'
            )
                this.customAllStoresName = this.captions.allStores + ' (' + this.groupItem.displayName + ')';
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.TAG) {
            this.showStoreBox = true;
            this.showAllStoresBox = true;
            if (
                this.eventName !== 'conceptChangedDirective' &&
                this.eventName !== 'groupItemChangedDirective' &&
                this.eventName !== 'tagChangedDirective'
            )
                this.customAllStoresName = this.captions.allStores + ' (' + this.tag.displayName + ')';
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.STORE) {
            if (
                this.eventName === 'conceptChangedDirective' ||
                this.eventName === 'groupItemChangedDirective' ||
                this.eventName === 'tagChangedDirective'
            ) {
                this.showStoreBox = true;
                this.showAllStoresBox = true;
            } else {
                this.showStoreBox = true;
                this.showAllStoresBox = false;
                if (
                    !this.stores.some((store) => {
                        if (store.id === this.selectedUserSecurity.storeID) {
                            this.selectedStore = store;
                            return true;
                        }
                    })
                ) {
                    this.showErrorStoresBox = true;
                    this.inactiveStoreMsg = this.captions.storeIsNotActive;
                }
                if (this.currentUserAccess.role === this.USER_ACCESS.CONCEPT)
                    this.customAllStoresName = this.captions.allStores + ' (' + this.concept.displayName + ')';
                else if (this.currentUserAccess.role === this.USER_ACCESS.GROUP_ITEM)
                    this.customAllStoresName = this.captions.allStores + ' (' + this.groupItem.displayName + ')';
            }
        }
    }

    getStoreByIdSuccess(store) {
        this.stores = this.stores.concat(store);
        this.selectedStore = store;
        this.showStoreBox = true;
    }

    createTagForSelectedStores() {
        if (typeof this.selectedStores !== 'undefined' && this.selectedStores) {
            // selected more than 1 store
            if (this.selectedStores.length > 1) {
                // this.stores = this.selectedStores;
                this.emitterService.addTagForSelectedStoresPopUp(this.selectedStores, null);
                // selected 1 store
            } else if (this.selectedStores.length === 1) {
                var selectedStore = this.selectedStores[0];
                this.emitterService.storeChangedCtrl(selectedStore);
                this.selectedStore = selectedStore;
                this.showAllStoresBox = false;
                this.storePickerModel = this.selectedStore.id;
                this.storePicker.emit(this.storePickerModel);
            }
        }
        // selected reset
        else {
            var selectedStore = null;
            this.emitterService.storeChangedCtrl(selectedStore);
            this.selectedStore = selectedStore;
            this.showAllStoresBox = true;
            // this.showErrorStoresBox = true;
            // this.storePickerModel = this.selectedStore.id;
            this.storePicker.emit(0);
        }
    }

    storePickerDialog(ev) {
        for (let i = 0; i < this.selectedStores?.length; i++) {
            for (let j = 0; j < this.stores?.length; j++) {
                if (this.selectedStores[i].id == this.stores[j].id) {
                    this.stores[j].isChecked = true;
                }
            }
        }

        if (typeof this.selectedStores == 'undefined') {
            this.selectedStores = [];
        }

        // make a deep copy
        this.selectedStoresBackup = JSON.parse(JSON.stringify(this.selectedStores));

        const dialogRef = this.dialog.open(StorePickerDialog, {
            disableClose: true,
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                stores: this.stores,
                selectedStores: this.selectedStores,
                currentUserAccess: this.currentUserAccess,
                storeCheckboxes: this.storeCheckboxes,
                selectedUserSecurity: this.selectedUserSecurity,
                groupItem: this.groupItem,
                groupItemModel: this.groupItemModel,
                tagModel: this.tagModel,
                customAllStoresName: this.customAllStoresName,
                allStores: this.allStores,
                storeItemMultiSelect: this.storeItemMultiSelect,
                showSelectAllButton: this.showSelectAllButton,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (typeof res != 'undefined') {
                if (res.data.selectedStores && res.data.selectedStores.length) {
                    this.selectedStores = res.data.selectedStores;
                    // this.userEmitterService.storeChangedDirective(res.data.selectedStores);
                } else {
                    this.selectedStores = null;
                    this.resetStoreIsChecked();
                    this.showAllStoresBox = true;

                    if (this.groupItem) {
                        // return the values to group item
                        this.userEmitterService.groupItemChangedDirective(this.groupItem, this.concept);
                    } else if (this.tag) {
                        // return the values to tag item
                        this.userEmitterService.tagChangedDirective(this.tag, this.concept);
                    } else {
                        // neither tag was selected, so concept
                        this.userEmitterService.conceptChangedDirective(this.concept);
                    }
                }
                this.createTagForSelectedStores();
            }
        });
    }

    resetStoreIsChecked() {
        for (let aStore of this.stores) {
            delete aStore?.isChecked;
        }
        this.selectedStores = [];
    }
}
