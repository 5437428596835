import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ConceptApiService } from '../services/concept.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { StoreApiService } from '../../stores/services/store.resource';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { Concept } from '../models/concept.model';
import { ConceptDetailsDialog } from './dialogs/concept-details-dialog.component.ts';
import { ConceptOptionsComponent } from './concept-options.component';
import { StoreAndConceptConfirmDeleteDialog } from '../../stores/components/dialogs/confirm-delete-dialog.component';
import { NoConceptsDialog } from './dialogs/no-concepts-dialog.conponent';
import { UserRole } from '../../users/enums/user-role.enum';
import { PrivilegeName, PrivilegeType, UserPrivileges } from '../../users/enums/user-privileges.enum';
import { StoreBase } from '../../stores/models/store.model';
import { UserAccess } from '../../users/models/user-access.model'

@Component({
  selector: 'app-all-concepts',
  templateUrl: 'src/app/features/concepts/templates/concepts.all.html',
})
export class AllConceptsComponent implements OnInit {
  private currentUser = this.userAccessHandler.getUserAccess();
  private allStoresCount: number = 0;
  private userRole: UserRole;
  private conceptDetails: Concept;
  private manageConceptsPrivilege: boolean;
  private manageStoreTagsPrivilege: boolean;
  private manageGroupItemsPrivilege: boolean;
  private manageCouponsPrivilege: boolean;
  private storePortalPrivilege: boolean;
  private conceptOptionsPopUp: boolean;
  private selectedConcept: Concept;
  private conceptsWithUsers: Concept[];

  // currently unused variables:
  // private isGroupItemAccess: boolean;
  // private expandAdmin: boolean;
  // private confirmConceptDeletePopUp: boolean;
  // private noConceptsToDeletePopUp: boolean;

  public captions: Record<string, string>;
  public concept: Concept;
  public concepts: Concept[] = [];
  public icon: string;
  public conceptsLoaded: boolean = false;
  public emptyConceptMessage: boolean = false;
  public addConceptIcon: boolean = true;
  public recycleBinIcon: boolean = true;
  public editConceptIcon: boolean = true;
  public conceptInfoIcon: boolean = false;
  public deleteConceptCheckBox: boolean = false;
  public confirmDeleteIcon: boolean = false;
  public revertSelectionIcon: boolean = false;

  @Input() searchStr: string = '';

  constructor(
    @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
    @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
    @Inject(StoreApiService) private storeApiService: StoreApiService,
    @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
    @Inject(MatDialog) private dialog: MatDialog,
    @Inject(SnackbarService) private snackbarService: SnackbarService,
    @Inject(MatBottomSheet) private matBottomSheet: MatBottomSheet,
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private actRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.actRoute.data.subscribe((data: any) => {
      this.captions = data.captions;
    });
    this.loadConcepts();
  }

  changedSearchText(searchStr: string): void {
    this.searchStr = searchStr;
  }

  addOneConcept(): void {
    if (this.concepts.length >= this.currentUser.limitation.maxConcept) {
      this.snackbarService.errorMessageTop(
        this.captions.limitOf + this.currentUser.limitation.maxConcept + ' ' + this.captions.concepts
      );
    } else {
      this.router.navigate(['concepts/addConcept']);
    }
  }

  toggleCheckbox(): void {
    this.confirmDeleteIcon = !this.confirmDeleteIcon;
    this.revertSelectionIcon = !this.revertSelectionIcon;
    this.deleteConceptCheckBox = !this.deleteConceptCheckBox;
    this.recycleBinIcon = !this.recycleBinIcon;
    this.editConceptIcon = !this.editConceptIcon;
    this.addConceptIcon = !this.addConceptIcon;
  }

  openDeleteDialog(): void {
    const conceptsToDeactivate: Concept[] = this.concepts.filter(concept => concept.isChecked);
    const conceptIdsToDeactivate: number[] = conceptsToDeactivate.map(concept => concept.id);

    if (conceptIdsToDeactivate.length > 0) {
      this.conceptApiService.validateConceptDeletion(conceptIdsToDeactivate).then((conceptsWithUsers: Concept[]) => {
        this.conceptsWithUsers = conceptsWithUsers;
        const conceptsWithUsersSelected = this.conceptsWithUsers.length > 0 ? true : false;

        const dialogRef = this.dialog.open(StoreAndConceptConfirmDeleteDialog, {
          panelClass: 'app-full-bleed-dialog',
          maxWidth: '40em',
          data: {
            storesToDelete: conceptsToDeactivate, // Assuming this should be conceptsToDeactivate
            conceptsWithUsersSelectedToDelete: conceptsWithUsersSelected,
            conceptsWithUsersToDelete: this.conceptsWithUsers,
            storeOrConcept: 'Concept',
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.confirmDelete();
          }
        });

      }).catch(error => {
        console.error('Failed to validate concept deletion:', error);
        // Handle error appropriately
      });
    } 
    else {
      this.dialog.open(NoConceptsDialog, {
        panelClass: 'app-full-bleed-dialog',
        maxWidth: '90vw',
      });
    }
  }

  revertSelection(): void {
    this.revertSelectionIcon = false;
    this.confirmDeleteIcon = false;
    this.recycleBinIcon = true;
    this.deleteConceptCheckBox = false;
    this.editConceptIcon = true;
    this.addConceptIcon = true;
    // this.confirmConceptDeletePopUp = false;
    // this.noConceptsToDeletePopUp = false;
    for (let concept of this.concepts) {
      concept.isChecked = false;
    }
  }

  selectConceptBox(concept: Concept): void {
    if (!this.deleteConceptCheckBox) {
      this.itemStorageService.conceptLocalTimezone = concept.localTimeZone;
      this.router.navigate(['stores/allStores', concept.id, concept.displayName]);
    } 
    else { 
      concept.isChecked = !concept.isChecked;
    } 
  }

  optionClick(concept: Concept): void {
    this.itemStorageService.selectedConcept = concept;
    if (this.userRole === UserRole.Concept || this.userRole === UserRole.Global) {
      this.turnOnOptions(concept);
    } 
    else {
      this.triggerConceptDetails(concept);
    }
  }

  // cancelDelete(): void {
  //   for (let concept of this.concepts) {
  //     concept.isChecked = false;
  //   }
  //   this.revertSelection();
  // }

  // hideConfirmDelete(): void {
  //   this.confirmConceptDeletePopUp = false;
  // }

  private loadConcepts(): void {
    this.conceptApiService.getAllActiveConcepts().then((activeConcepts: Concept[]) => {
      this.concepts = activeConcepts;
      this.allStoresCount = 0;
      for (let concept of this.concepts) {
        this.storeApiService.getStoresByConceptId(concept.id).then((stores: StoreBase[]) => {
          this.allStoresCount = stores.length + this.allStoresCount;
          this.userAccessHandler.setStoreCount(this.allStoresCount);
          concept.numStores = stores.length;
        });
      }
      this.emptyConceptMessage = this.concepts.length === 0;
      this.manageConceptsBasedOnUserRole(this.currentUser);
      this.manageConceptsBasedOnUserPrivileges(this.currentUser);
      this.conceptsLoaded = true;
    }).catch(reason => {
      this.snackbarService.errorMessageTop(reason);
      this.concepts = [];
    });
  }

  private manageConceptsBasedOnUserRole(user: UserAccess): void {
    this.userRole = user.role;
    this.icon = 'list_alt'; // Default icon
    this.addConceptIcon = true; // Default state
    this.recycleBinIcon = true; // Default state
    this.editConceptIcon = true; // Default state
    this.conceptInfoIcon = false; // Default state
    // this.isGroupItemAccess = true; // Assuming default is true

    switch (user.role) {
      case UserRole.Concept:
        this.addConceptIcon = false;
        this.recycleBinIcon = false;
        break;
      case UserRole.GroupItem:
      case UserRole.Tag:
      case UserRole.Store:
        this.editConceptIcon = false;
        this.addConceptIcon = false;
        this.recycleBinIcon = false;
        this.conceptInfoIcon = true;
        this.icon = 'info';
        // if (user.role === UserRole.Store) {
        //   this.isGroupItemAccess = false;
        // }
        break;
      // Default case is already set by initial values
    }
  }

  private manageConceptsBasedOnUserPrivileges(user: UserAccess): void {
    this.resetPrivileges(); // Reset to default state before applying specific privileges

    if (user.userPrivilege && user.userPrivilege !== UserPrivileges.AllPrivileges) {
      const privileges = JSON.parse(user.userPrivilege) as { name: PrivilegeName, type: PrivilegeType }[];
      privileges.forEach(privilege => {
        this.applyPrivilege(privilege.name);
      });
      this.updateIconsBasedOnPrivileges();
    } 
    else if (user.userPrivilege === null) {
      // this.isGroupItemAccess = false;
      this.editConceptIcon = false;
      this.conceptInfoIcon = true;
      this.addConceptIcon = false;
      this.recycleBinIcon = false;
    }
  }

  private resetPrivileges(): void {
    this.manageConceptsPrivilege = false;
    this.manageStoreTagsPrivilege = false;
    this.manageGroupItemsPrivilege = false;
    this.manageCouponsPrivilege = false;
    this.storePortalPrivilege = false;
    this.addConceptIcon = true;
    this.recycleBinIcon = true;
    this.editConceptIcon = true;
    this.conceptInfoIcon = false;
    // this.isGroupItemAccess = true;
    // this.expandAdmin = false;
  }

  private applyPrivilege(privilegeName: PrivilegeName): void {
    switch (privilegeName) {
      case PrivilegeName.ManageOrganizations:
        this.manageConceptsPrivilege = true;
        break;
      case PrivilegeName.ManageCoupons:
        this.manageCouponsPrivilege = true;
        break;
      case PrivilegeName.PortalAdmin:
      case PrivilegeName.PortalUser:
        this.storePortalPrivilege = true;
        break;
      // Add more cases as needed.
    }
  }

  private updateIconsBasedOnPrivileges(): void {
    if (!this.manageConceptsPrivilege) {
      this.addConceptIcon = false;
      this.recycleBinIcon = false;
    }
    if (!this.manageConceptsPrivilege && !this.manageStoreTagsPrivilege && !this.manageGroupItemsPrivilege) {
      this.conceptInfoIcon = true;
      this.editConceptIcon = false;
    }
    // if (!this.manageCouponsPrivilege) {
    //   this.expandAdmin = true;
    // }
    if (this.storePortalPrivilege) {
      // this.isGroupItemAccess = false;
      this.editConceptIcon = false;
      this.conceptInfoIcon = false;
      this.addConceptIcon = false;
      this.recycleBinIcon = false;
    }
  }

  private confirmDelete(): void {
    this.deleteConcepts();
    this.revertSelection();
  }

  private deleteConcepts(): void {
    let conceptIdsToDeactivate: number[] = [];
    for (let concept of this.concepts) {
      if (concept.isChecked === true) {
        conceptIdsToDeactivate.push(concept.id);
      }
    }
    this.revertSelection();
    this.conceptApiService.deactivateConcepts(conceptIdsToDeactivate).then(() => {
      for (let id of conceptIdsToDeactivate) {
        this.concepts = this.concepts.filter((item) => item.id != id);
      }
      this.loadConcepts();
      this.snackbarService.successMessageBottom(this.captions.conceptDeleteSuccess);
    });
  }

  private turnOnOptions(concept: Concept): void {
    this.selectedConcept = concept;
    this.matBottomSheet.open(ConceptOptionsComponent, {
      panelClass: 'options-bottom-sheet',
    });
  }

  private triggerConceptDetails(concept: Concept): void {
    // Directly turn on options if conditions meet, and return early to simplify logic
    if (this.manageCouponsPrivilege && !this.conceptOptionsPopUp) {
      this.turnOnOptions(concept);
      return;
    }

    // Determine the concept details to use
    this.conceptDetails = this.conceptOptionsPopUp ? this.selectedConcept : concept;

    this.conceptDetailsDialog();
  }

  private conceptDetailsDialog(): void {
    const dialogRef = this.dialog.open(ConceptDetailsDialog, {
      panelClass: 'app-full-bleed-dialog',
      maxWidth: '90vw',
      data: {
        conceptDetail: this.conceptDetails,
      },
    });
  }
}
