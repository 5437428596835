import { Inject, Injectable } from '@angular/core';
import * as $ from 'jquery';
import { AppConstants } from './app.constants';
import { BrowserStorage } from './utils/shared-services/browser-storage.service';

@Injectable()
export class AppSettings {
    private LANGUAGES;
    private THEMES;

    constructor(@Inject(BrowserStorage) private browserStorage: BrowserStorage) {
        this.LANGUAGES = AppConstants.LANGUAGES;
        this.THEMES = AppConstants.THEMES;
    }

    setTheme(theme) {
        this.browserStorage.setLocalstorage('appTheme', theme);
    }

    setIsPhoneScreen(isPhoneScreenBoolean) {
        this.browserStorage.setLocalstorage('isPhoneScreen', isPhoneScreenBoolean);
    }

    getTheme() {
        let theme = this.browserStorage.getLocalstorage('appTheme', null);
        if (theme) return theme;
        this.setTheme(this.THEMES.light);
        return this.THEMES.light;
    }

    setLanguage(language) {
        this.browserStorage.setLocalstorage('appLanguage', language);
    }

    getLanguage() {
        let language = this.browserStorage.getLocalstorage('appLanguage', null);
        if (language != null && typeof language !== 'undefined') {
            return language;
        } else {
            this.setLanguage(this.LANGUAGES.english);
            return this.LANGUAGES.english;
        }
    }

    applyTheme() {
        let theme = this.getTheme();
        if (theme == this.THEMES.dark) {
            $(document).find('body').addClass('dark-theme');
            // update theme by data-theme attribute
            $(document).find('body').attr('data-theme', 'dark');
        } else {
            $(document).find('body').removeAttr('class');
            // update theme by data-theme attribute
            $(document).find('body').attr('data-theme', 'light');
        }
    }
}
