import { Component, Inject } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'confirm-message-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/confirmed-message-dialog.html',
})
export class ConfirmedMessageDialog { // Used in unused ConfirmResetQ2Dialog component

    public captions: Record<string, string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService
    ) {
        this.captions = this.captionService.captions;
    }

    refresh(): void {
        location.reload();
    }
}
