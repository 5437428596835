import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";

@Component({
    selector: "price-history-dialog",
    templateUrl: "./src/app/features/price-change/templates/dialogs/price-history-dialog.component.html",
})
export class PriceHistoryDialog implements OnInit {
    public historyData;
    public captions: Record<string, string>

      // Array of column names
   public columns = ['HQWhenSubmitted', 'When2Execute', 'HQUserInfo', 'PRICEA', 'PRICEB', 'PRICEC', 'PRICED', 'PRICEE', 'PRICEF', 'PRICEG', 
  'PRICEH', 'PRICEI', 'PRICEJ', 'PRICEMODE', 'Label'];
  public headers = ['Submitted Time', 'Execute Time', 'HQ User', 'PriceA', 'PriceB', 'PriceC', 'PriceD', 'PriceE', 'PriceF', 'PriceG', 
  'PriceH', 'PriceI', 'PriceJ', 'Price MOD', 'Label'];
    constructor(@Inject(MAT_DIALOG_DATA) public data,
    @Inject(CaptionService) private captionService: CaptionService,
    ) {
        this.historyData = this.data.historyData
        this.captions = this.captionService.captions
    }
    async ngOnInit(): Promise<void> { 
    }
}
