import { Inject, Injectable } from '@angular/core';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

@Injectable()
export class SharedWizardData {

    public sharedData;
    public publicData;
    public conceptID;
    public conceptName;
    public templateID;
    public templateName;
    public storeList;
    public groupItemList;
    public tagList;
    public currencyCode;
    public localCurrency;
    public commonCurrency;
    public scheduleParams;
    public scheduleDate;
    public sendOut;
    public caption;
    public state;
    public scheduleID;
    public groupItemIDList;
    public tagIDList;
    public startDate;
    public endDate;
    public reportTabIdx;

    constructor(@Inject(BrowserStorage) private browserStorage: BrowserStorage) {
        this.sharedData = {
            conceptID: null,
            conceptName: null,
            templateID: null,
            templateName: null,
            storeList: null,
            groupItemList: null,
            tagList: null,
            currencyCode: null,
            localCurrency: null,
            commonCurrency: null,
            startDate: null,
            endDate: null,
            scheduleParams: {
                repeats: null,
                list: [],
                recurs: null,
                option: null,
                time: null,
                timezone: null,
                reportsToHold: null
            },
            scheduleDate: null,
            scheduleID: null,
            sendOut: null,
            caption: null,
            groupItemIDList: null,
            tagIDList: null,
            reportTabIdx: null
        }

        //get sharedData if saved
        let sharedDataTemp = this.browserStorage.getSessionstorage('sharedData', null);
        this.sharedData = sharedDataTemp ? JSON.parse(sharedDataTemp) : this.sharedData;

        this.conceptID = this.sharedData.conceptID;
        this.conceptName = this.sharedData.conceptName;
        this.templateID = this.sharedData.templateID;
        this.templateName = this.sharedData.templateName;
        this.storeList = this.sharedData.storeList;
        this.groupItemList = this.sharedData.groupItemList;
        this.tagList = this.sharedData.tagList;
        this.currencyCode = this.sharedData.currencyCode;
        this.startDate = this.sharedData.startDate;
        this.endDate = this.sharedData.endDate;
        this.localCurrency = this.sharedData.localCurrency;
        this.commonCurrency = this.sharedData.commonCurrency;
        this.scheduleParams = this.sharedData.scheduleParams;
        this.scheduleDate = this.sharedData.scheduleDate;
        this.sendOut = this.sharedData.sendOut;
        this.caption = this.sharedData.caption;
        this.groupItemIDList = this.sharedData.groupItemIDList;
        this.tagIDList = this.sharedData.tagIDList;
    }

    //templateName,currencyCode are modifiable by user
    save() {
        //templateName,currencyCode are modifiable by user
        this.publicData = {
            conceptID: this.conceptID,
            conceptName: this.conceptName,
            templateID: this.templateID,
            templateName: this.templateName,
            storeList: this.storeList,
            groupItemList: this.groupItemList,
            tagList: this.tagList,
            currencyCode: this.currencyCode,
            localCurrency: this.localCurrency,
            commonCurrency: this.commonCurrency,
            startDate: this.sharedData.startDate,
            endDate: this.sharedData.endDate,
            scheduleParams: this.scheduleParams,
            scheduleDate: this.scheduleDate,
            sendOut: this.sendOut,
            caption: this.caption,
            groupItemIDList: this.groupItemIDList,
            tagIDList: this.tagIDList,
            reportTabIdx: this.reportTabIdx
        };
        this.browserStorage.setSessionstorage('sharedData', JSON.stringify(this.publicData));
    }

    delete() {
        this.browserStorage.removeSessionstorageItem('sharedData');
    }

    resetScheduleWizardData() {
        // reset the session storage for reportparams (specifically interested in store selection)
        this.browserStorage.removeSessionstorageItem('reportParams');
        // reset the values for date (ie previous day, current month etc), weekday (ie monday, tues) in store selection
        this.scheduleParams.option = null;
        this.scheduleDate = null;
    }
}