import { Component, Inject } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'eoi-fail-response-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-fail-response.dialog.html',
})
export class EoiFailResponseDialog {
    public captions: Record<string,string>;

    constructor(@Inject(CaptionService) private captionService: CaptionService) {
        this.captions = this.captionService.captions;
    }
}
