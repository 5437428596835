import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { EoiStatusDialogData } from '../../models/dialog.model';

@Component({
    selector: 'eoi-status-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-status.dialog.html',
})
export class EoiStatusDialog {
    public captions: Record<string, string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: EoiStatusDialogData
    ) {
        this.captions = this.captionService.captions;
    }
}
