import {
    Component,
    Inject,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import {
    CompactType,
    DisplayGrid,
    GridsterComponent,
    GridsterItem,
    GridsterItemComponentInterface,
    GridType,
} from 'angular-gridster2';
import * as $ from 'jquery';
import * as moment from 'moment';
import {Subscription, take} from 'rxjs';
import * as wijmoChart from 'wijmo/wijmo.chart';
import { ConceptApiService } from '../../../features/concepts/services/concept.resource';
import { UserApiService } from '../../../features/users/services/users.resource';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { EmitterService } from '../../../utils/shared-services/emitter.service';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { StoreGroupMembersApiService } from '../../store-groups/services/store-group-member.resource';
import { TagApiService } from '../../tags/services/tag.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserInfoHandler } from '../../users/services/user-info-handler.service';
import { DashboardEmitterService } from '../services/dashboard-emitter.service';
import { DashboardApiService } from '../services/dashboard.resource';
import { WijmoThemeService } from '../services/wijmo-theme.service';
import { ConfigureWidgetComponent } from '../widget/components/configure-widget.component';
import { WidgetCommonService } from '../widget/services/widget-common.service';
import { AddTabDialog } from './dialogs/add-tab.dialog';
import { AssignUserDialog } from './dialogs/assign-user.dialog';
import { ConfigureDateRangeSelectionDialog } from './dialogs/configureDateRangeSelection.dialog';
import { ConfigureDateSelectionDialog } from './dialogs/configureDateSelection.dialog';
import { DeleteTabDialog } from './dialogs/deleteTab.dialog';
import { DeleteWidgetDialog } from './dialogs/deleteWidget.dialog';
import { EditTabDialog } from './dialogs/edit-tab.dialog';
import { WidgetOpenDatesDialog } from './dialogs/widget-opendates.dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { UserAccess } from '../../users/models/user-access.model';
import { WidgetCustomDateOption, WidgetType } from '../enums/widget.enum';
import { DashboardTab, DashboardTabGetWithContent } from '../models/dashboard.model';
import { Widget, WidgetDefinitionData, WidgetOpenDates, WidgetTemplateGet } from '../models/widget.model';
import { GroupItem } from '../../store-groups/models/group-item.model';
import { StoreBase } from '../../stores/models/store.model';
import { Tag } from '../../tags/models/tag.model';
import { Concept } from '../../concepts/models/concept.model';
import { Event } from 'wijmo/wijmo';
import { PrivilegeName } from '../../users/enums/user-privileges.enum';
import { Safe } from '../models/gridster.model';

@Component({
    selector: 'mainDashboard',
    templateUrl: 'src/app/features/dashboard/templates/dashboard.html',
    encapsulation: ViewEncapsulation.None,
})
export class MainDashboardComponent implements OnInit {
    private event: MatTabChangeEvent;
    private dateSelectedVal: string;
    private dateRangeSelectedVal: string;
    private mobileBreakPointCounter: number = 0;
    private currentWidgetId: number = -1;
    private personAutoHideTimeout: NodeJS.Timeout;
    private supervisorAutoHideTimeout: NodeJS.Timeout;
    // this variable keeps track of which tabs have already been loaded to prevent loading when onTabChanged is executed
    private isTabAlreadyLoaded: boolean[] = [];
    // keeps track of the observables we subbed to
    private subscriptions: Subscription[] = [];
    private widgetSubscriptions: Subscription[] = [];
    // variables for user access and finding if user has viewDashboardOnly access
    private userAccess: UserAccess;
    private userInfo: any;

    public captions: Record<string, string>;
    public gridsterOpts: Safe;
    public lockCase: boolean = false;
    public tabs: DashboardTabGetWithContent[] = [];
    public currentTabIndex: number = 0;
    public mobileBreakPointBroke: boolean;
    public widgetTemplates: WidgetTemplateGet[] = [];
    public showRefreshButton: boolean = false;
    public favouriteTabIDIndex: number = -1;
    public favouriteTabID: number;
    public sharedTabs: number[] = [];
    public hasTabs: boolean = false;
    public toggleWelcome: boolean;
    public viewShared: boolean = false;
    public welcomeLink: string;
    public widget: Widget;
    public isWijmoWidget: boolean;
    public isFlatWidget: boolean;
    public isFlatWidgetToggled: boolean;
    public isWijmoWidgetToggled: boolean;
    public isWijmoTab: boolean = true;
    public mobileViewActive: boolean = true;
    public showDrawerBtn: boolean = false;
    public disableViewSharedToggle: boolean = false;
    // the time in ms between refreshes for current tab
    public refreshTimer: number[] = []; 

    @ViewChild('gridster') gridster: GridsterComponent;
    @ViewChild('drawer') drawer: MatDrawer;
    @ViewChildren('gridster') wijmoGridster: QueryList<GridsterItem>;
    @ViewChild('personTooltip') personTooltip: MatTooltip;
    @ViewChild('supervisorTooltip') supervisorTooltip: MatTooltip;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(DashboardEmitterService) private dashboardEmitterService: DashboardEmitterService,
        @Inject(EmitterService) private emitterService: EmitterService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(WijmoThemeService) private wijmoThemeService: WijmoThemeService,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(Router) public router: Router,
        @Inject(WidgetCommonService) public widgetCommonService: WidgetCommonService,
        @Inject(StoreGroupMembersApiService) public storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(UserAccessHandler) public userAccessHandler: UserAccessHandler,
        @Inject(TagApiService) private tagApiService: TagApiService
    ) {
        this.userAccess = this.userAccessHandler.getUserAccess();
        this.userInfo = this.userInfoHandler.getUserInfo();
        this.captions = this.captionService.captions;
        this.toggleWelcome = false;

        this.subscriptions.push(
            this.dashboardEmitterService.tabAddedItem$.subscribe((resultTab: DashboardTabGetWithContent) => {
                resultTab.selectedDropdownOption = WidgetCustomDateOption.Default;
                resultTab.content = '[]';
                this.tabs.push(resultTab);
                resultTab.content = JSON.parse(decodeURIComponent(resultTab.content));
                if (this.tabs.length === 1) {
                    this.mobileBreakPointCounter -= 2;
                    this.toggleWelcome = false;
                    this.onTabChanged(this.event, resultTab.id);
                }
            })
        );

        //Options other than Pick a Date and Pick a Date Range
        //Broadcasted from titlebar directive
        this.subscriptions.push(
            this.emitterService.dropdownSelectionItem$.subscribe((selectedOption) => {
                for (let widget of (this.tabs[this.currentTabIndex].content) as Widget[]) {
                    this.showRefreshButton = false;
                    if (selectedOption === WidgetCustomDateOption.Default || this.viewShared) {
                        this.showRefreshButton = true;
                    } 
                    else if (selectedOption === WidgetCustomDateOption.CurrentDay) {
                        widget.dateValue = this.captions.current_day_front;
                    } 
                    else if (selectedOption === WidgetCustomDateOption.PreviousDay) {
                        widget.dateValue = this.captions.previous_day_front;
                    } 
                    else if (selectedOption === WidgetCustomDateOption.CurrentWeek) {
                        widget.dateValue = this.captions.current_week_front;
                    } 
                    else if (selectedOption === WidgetCustomDateOption.PreviousWeek) {
                        widget.dateValue = this.captions.previous_week_front;
                    } 
                    else if (selectedOption === WidgetCustomDateOption.CurrentMonth) {
                        widget.dateValue = this.captions.current_month_front;
                    } 
                    else if (selectedOption === WidgetCustomDateOption.PreviousMonth) {
                        widget.dateValue = this.captions.previous_month_front;
                    }
                    this.getWidgetDefinition(widget);
                    widget.control?.refresh();
                    this.widget.control?.refresh();
                }

                let currentTab = this.tabs[this.currentTabIndex];
                currentTab.selectedDropdownOption = selectedOption;

                for (let widget of (this.tabs[this.currentTabIndex].content as Widget[])) {
                    if (currentTab.selectedDropdownOption === WidgetCustomDateOption.Default) {
                        this.getWidgetDataByPeriod(widget, null, false);
                    } 
                    else {
                        this.getWidgetDataByPeriod(widget, selectedOption, false);
                    }
                }
            })
        );

        this.subscriptions.push(
            this.emitterService.pickADateSelectionItem$.subscribe((selectedDateVal) => {
                //Set the selectedOption value for tab
                let currentTab = this.tabs[this.currentTabIndex];
                currentTab.selectedDropdownOption = selectedDateVal;
                itemStorageService.currentTab = currentTab;

                let dialogRef = this.dialog.open(ConfigureDateSelectionDialog, {
                    panelClass: 'app-full-bleed-dialog',
                    maxWidth: '90vw',
                });

                dialogRef.afterClosed().subscribe((res) => {
                    if (res) {
                        this.dashboardEmitterService.sendFormattedDateToTitlebar(
                            selectedDateVal,
                            this.formatDateSelection(this.itemStorageService.currentTab.pickADateValue, true),
                            null
                        );
                    }
                });
            })
        );

        this.subscriptions.push(
            dashboardEmitterService.dateSelectedItem$.subscribe((selectedDateVal) => {
                let currentTab = this.tabs[this.currentTabIndex];
                currentTab.pickADateValue = selectedDateVal;
                let dateStr = this.formatDateSelection(selectedDateVal, false);
                let formattedDateVal = this.formatDateSelection(selectedDateVal, true);

                for (let widget of (this.tabs[this.currentTabIndex].content as Widget[])) {
                    this.getWidgetDataByDateRange(widget, dateStr, dateStr);
                    widget.dateValue = formattedDateVal;
                    this.dateSelectedVal = formattedDateVal;
                    this.showRefreshButton = false;
                }
            })
        );

        this.subscriptions.push(
            this.emitterService.dateRangeSelectionItem$.subscribe((selectedDateVal) => {
                //store selectedDateVal in currentTab customDate field
                let currentTab = this.tabs[this.currentTabIndex];
                currentTab.selectedDropdownOption = selectedDateVal;
                itemStorageService.currentTab = currentTab;

                let dialogRef = this.dialog.open(ConfigureDateRangeSelectionDialog, {
                    panelClass: 'app-full-bleed-dialog',
                    maxWidth: '90vw',
                });

                dialogRef.afterClosed().subscribe((res) => {
                    if (res) {
                        this.dashboardEmitterService.sendFormattedDateToTitlebar(
                            selectedDateVal,
                            this.formatDateSelection(this.itemStorageService.currentTab.startDate, true),
                            this.formatDateSelection(this.itemStorageService.currentTab.endDate, true)
                        );
                    }
                });
            })
        );

        this.subscriptions.push(
            this.dashboardEmitterService.selectedDateRangeItem$.subscribe((dateVals) => {
                let currentTab = this.tabs[this.currentTabIndex];
                let startDateVal = dateVals[0];
                let endDateVal = dateVals[1];

                currentTab.startDate = startDateVal;
                currentTab.endDate = endDateVal;

                let startDateValue = this.formatDateSelection(startDateVal, false);
                let endDateValue = this.formatDateSelection(endDateVal, false);

                let formattedStartDateVal = this.formatDateSelection(startDateVal, true);
                let formattedEndDateVal = this.formatDateSelection(endDateVal, true);
                let formattedDateRange = formattedStartDateVal + ' ' + this.captions.toCustomDate + ' ' + formattedEndDateVal;

                for (let widget of (this.tabs[this.currentTabIndex].content as Widget[])) {
                    this.getWidgetDataByDateRange(widget, startDateValue, endDateValue);
                    widget.dateValue = formattedDateRange;
                    this.dateRangeSelectedVal = formattedDateRange;
                    this.showRefreshButton = false;
                }
            })
        );

        this.subscriptions.push(
            this.dashboardEmitterService.widgetAddedItem$.subscribe((widget) => {
                let widgetSizes = widget.defaultGridSize;
                let content = this.tabs[this.currentTabIndex].content as Widget[];
                let currentWidget: Widget = {
                    rows: widgetSizes.y,
                    cols: widgetSizes.x,
                    widgetID: widget.id,
                    displayName: widget.displayName,
                    widgetType: widget.widgetType,
                    conceptID: widget.conceptID,
                    storesSelected: widget.storesSelected,
                    storesTotal: widget.storesTotal,
                    dataSource: widget.dataSource,
                    groupingID: widget.groupingID,
                };
                // Trigger mobile breakpoint when the user has started on mobile
                if (this.tabs.length === 1 && content.length === 0 && this.mobileBreakPointCounter == 0) {
                    this.mobileBreakPointCounter += this.tabs.length;
                    this.mobileBreakPointBroke = true;
                }

                content.push(currentWidget);

                this.getWidgetDefinition(currentWidget).then(() => {
                    let currentTab = this.tabs[this.currentTabIndex];
                    let currentTabSelectedDate = currentTab.selectedDropdownOption;

                    if (currentTabSelectedDate === WidgetCustomDateOption.Default) {
                        this.postWidgetDataRefresh(currentWidget, false);
                    }
                    else if (currentTabSelectedDate === WidgetCustomDateOption.CustomDate) {
                        this.postWidgetDataByDateRange(
                            currentWidget,
                            this.formatDateSelection(currentTab.pickADateValue, false),
                            this.formatDateSelection(currentTab.pickADateValue, false)
                        );
                    }
                    else if (currentTabSelectedDate === WidgetCustomDateOption.CustomDateRange) {
                        this.postWidgetDataByDateRange(
                            currentWidget,
                            this.formatDateSelection(currentTab.startDate, false),
                            this.formatDateSelection(currentTab.endDate, false)
                        );
                    }
                    else {
                        this.postWidgetDataByPeriod(currentWidget, currentTabSelectedDate, false);
                    }

                    this.isWijmoTab = widget.widgetType === WidgetType.Wijmo;
                });
            })
        );

        this.subscriptions.push(
            this.dashboardEmitterService.widgetUpdatedItem$.subscribe((widget: any) => {
                let content = this.tabs[this.currentTabIndex].content as Widget[];

                let widgetToUpdate: Widget;

                for (let widgetInContent of content) {
                    if (widgetInContent.widgetID === widget.id) {
                        widgetToUpdate = widgetInContent;
                        widgetToUpdate.displayName = widget.displayName;
                        widgetToUpdate.conceptID = widget.conceptID;
                        widgetToUpdate.storesSelected = widget.storesSelected;
                        widgetToUpdate.storesTotal = widget.storesTotal;
                        widgetToUpdate.groupingID = widget.groupingID;
                        this.getWidgetDefinition(widgetToUpdate).then(() => {
                            this.getWidgetDataRefresh(widgetToUpdate, true);
                        });
                    }
                }
            })
        );
    } // constructor

    ngOnInit(): void {
        if (typeof this.itemStorageService.lockDash != 'undefined') {
            this.lockCase = this.itemStorageService.lockDash;
        }

        if (typeof this.itemStorageService.shareDash != 'undefined') {
            this.viewShared = this.itemStorageService.shareDash;
        }

        // check for  the viewSharedDashOnly privilege
        for (let priv of JSON.parse(this.userAccess.userPrivilege)) {
            if (priv.name == PrivilegeName.ViewSharedDashOnly) {
                this.viewShared = true;
                this.disableViewSharedToggle = true;
            }
        }

        this.gridsterOpts = {
            gridType: GridType.VerticalFixed,
            // when viewing shared widgets, we do not want gaps
            compactType: this.viewShared ? CompactType.CompactUpAndLeft : CompactType.CompactUp,
            margin: 10,
            outerMargin: true,
            useTransformPositioning: false,
            mobileBreakpoint: 640,
            minCols: 6,
            maxCols: 10,
            minRows: 4,
            maxRows: 25,
            maxItemCols: 10,
            minItemCols: 1,
            maxItemRows: 10,
            minItemRows: 1,
            maxItemArea: 2500,
            minItemArea: 1,
            fixedColWidth: 150,
            fixedRowHeight: 150,
            keepFixedHeightInMobile: false,
            keepFixedWidthInMobile: false,
            scrollSensitivity: 10,
            scrollSpeed: 20,
            emptyCellDragMaxCols: 50,
            emptyCellDragMaxRows: 50,
            ignoreMarginInRow: false,
            swap: true,
            pushItems: true,
            disablePushOnDrag: true,
            disablePushOnResize: false,
            pushDirections: { north: true, east: true, south: true, west: true },
            pushResizeItems: false,
            displayGrid: DisplayGrid.OnDragAndResize,
            disableWindowResize: false,
            disableWarnings: false,
            scrollToNewItems: false,
            itemChangeCallback: (item, itemComponent) => {
                let tabToUpdate = $.extend(true, {}, this.tabs[this.currentTabIndex]);
                this.itemChange(item, itemComponent, tabToUpdate);
            },

            draggable: {
                enabled: !this.lockCase,
                delayStart: 0,
            },
            resizable: {
                enabled: !this.lockCase,
                delayStart: 0,
            },
        };

        this.mobileViewActive = window.innerWidth < 640;
        this.showDrawerBtn = window.innerWidth > 300;
        window.onresize = () => {
            this.mobileViewActive = window.innerWidth < 640;
            this.showDrawerBtn = window.innerWidth > 300;
        };

        if (this.viewShared) {
            this.tabs = [];
            this.currentTabIndex = -1;
            this.favouriteTabIDIndex = -1;
            this.mobileBreakPointBroke = true;
            this.getAllSharedTabsForViewers();
            for (let i = 0; i < this.isTabAlreadyLoaded.length; ++i) {
                this.isTabAlreadyLoaded[i] = false;
            }
        } 
        else {
            // get all tabs
            this.getAllTabs().then(() => {
                this.getAllSharedTabs().then(() => {
                    this.getAllWidgetTemplates().then(() => {
                        // subscribe to all the tabs
                        for (let i = 0; i < this.tabs.length; ++i) {
                            if (this.tabs[i].content) {
                                let tabWidgetList = this.tabs[i].content as Widget[]
                                // call the service to get data for each widget and save refresh frequency for each tab
                                this.dashboardApiService
                                    .getWidgetInstanceById$(tabWidgetList[0].widgetID)
                                    .subscribe(() => {
                                        if (i == this.currentTabIndex) {
                                            for (let widgetInContent of tabWidgetList) {
                                                this.getWidgetDefinition(widgetInContent);
                                                this.getWidgetDataRefresh(widgetInContent, true); // }
                                            }
                                        }

                                        // * COMMENT OUT FOR NOW - refresh timer was hidden *
                                        // assign the refresh frequency to the respective element in the array (0-5)
                                        // this.refreshTimer[i] = Number(widget?.parameters[3]?.value);
                                        // this.widgetSubscriptions.push(
                                        //     timer(
                                        //         Number(this.refreshTimer[i]) * 1000 * 60,
                                        //         Number(this.refreshTimer[i]) * 1000 * 60
                                        //     )
                                        //         .pipe(
                                        //             map(() => {
                                        //                 // only refresh for the current tab
                                        //                 if (i == this.currentTabIndex) {
                                        //                     for (let widgetInContent of this.tabs[i]?.content) {
                                        //                         this.getWidgetDataRefresh(widgetInContent, true);
                                        //                     }
                                        //                 }
                                        //             })
                                        //         )
                                        //         .subscribe()
                                        // );
                                    });
                            }
                        }
                    });
                });
            });
        }

        this.favouriteTabID = this.userInfo.favouriteTabID;
        this.welcomeLink = 'https://www.youtube.com/embed/08LN3Z8boxc?ecver=1';
    }

    ngOnDestroy(): void {
        // unsubscribe to observables on destruction
        for (let sub of this.subscriptions) {
            sub?.unsubscribe();
        }

        for (let sub of this.widgetSubscriptions) {
            sub?.unsubscribe();
        }
    }

    filterMobileWidgets(widgetTemplate: WidgetTemplateGet): boolean {
        return widgetTemplate.widgetTemplateType == 2;
    }

    filterWijmoWidgets(widgetTemplate: WidgetTemplateGet): boolean {
        return widgetTemplate.widgetTemplateType == 1;
    }

    // on scroll, lazy load the widgets
    scrollFunction(e: Event): void {
        this.loadWidgetRecursive(this.tabs[this.currentTabIndex], e);
    }

    // This function is used for #reportTooltip
    toggleTooltipAndAutoHide(toolTip: MatTooltip): void {
        let autoHideTimeout = toolTip === this.personTooltip ? 
            this.personAutoHideTimeout : 
            this.supervisorAutoHideTimeout;

        toolTip.toggle(); // Toggle the tooltip

        // Clear any existing auto-hide timeout
        if (autoHideTimeout) {
            clearTimeout(autoHideTimeout);
        }

        // If the tooltip is shown, auto-hide it after 5 seconds
        // _isTooltipVisible() return false meaning that the tooltip is shown and vice versa
        if (!toolTip._isTooltipVisible()) {
            if(toolTip === this.personTooltip) {
                this.personAutoHideTimeout = setTimeout(() => {
                    toolTip.hide();
                }, 3000); // 3000 milliseconds
            }
            else {
                this.supervisorAutoHideTimeout = setTimeout(() => {
                    toolTip.hide();
                }, 3000); // 3000 milliseconds
            }
        }
    }

    // clear time out set by toggleTooltipAndAutoHide()
    // allow user to hover over tooltip without it disappearing
    hoverTooltipAndClearTimeOut(toolTip: MatTooltip): void {
        let autoHideTimeout = toolTip === this.personTooltip ? 
            this.personAutoHideTimeout : 
            this.supervisorAutoHideTimeout;
        toolTip.show();
        if (autoHideTimeout) {
            clearTimeout(autoHideTimeout);
        }
    }

    refreshTab(): void {
        for (let widgetInContent of this.tabs[this.currentTabIndex]?.content as Widget[]) {
            widgetInContent.isWidgetLoaded = false;
            if (widgetInContent.sentLoadRequest) {
                this.getWidgetDataRefresh(widgetInContent, true);
            }
        }
    }

    toggleShare(): void {
        this.itemStorageService.lockDash = this.viewShared ? this.viewShared : this.itemStorageService.lockDash;
        this.itemStorageService.shareDash = this.viewShared;
        this.reload();
    }

    toggleLock(): void {
        this.itemStorageService.lockDash = this.lockCase;
        this.reload();
    }

    onChangeWidgetRefreshInterval(newInterval: any): void {
        for (let widgetInContent of this.tabs[this.currentTabIndex]?.content as Widget[]) {
            if (true) {
                this.dashboardApiService.getWidgetInstanceById$(widgetInContent.widgetID).subscribe((widget) => {
                    // convert the widget
                    let widgetPut = this.widgetCommonService.updateWidgetParameters(widget);
                    // override the old interval
                    widgetPut.parameters[1].value = String(newInterval);
                    this.dashboardApiService.updateWidgetInstance(widgetPut, widgetInContent.widgetID, widget.dataSource);
                });
            }
        }
    }

    favetab(tabID: number): void {
        this.userApiService.updateFavouriteTab(this.userInfo, tabID).then(() => {
            this.favouriteTabID = tabID;
            this.userInfoHandler.setFavouriteTabID(this.favouriteTabID);
        });
    }

    showAssignUserDialog(): void {
        if (this.currentTabIndex < 0) return this.showNoTabErr();
        this.itemStorageService.currentTab = this.tabs[this.currentTabIndex];

        let dialogRef = this.dialog.open(AssignUserDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                tabs: this.tabs,
                currentTabIndex: this.currentTabIndex,
            },
        });

        dialogRef.afterClosed().subscribe(() => {
            document.body.style.cursor = 'default';
        });
    }

    showAddTabDialog(): void {
        this.dialog.open(AddTabDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
        });
    }

    showEditTabDialog(): void {
        if (this.currentTabIndex < 0) return this.showNoTabErr();
        this.itemStorageService.currentTab = this.tabs[this.currentTabIndex];
        this.dialog.open(EditTabDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
        });
    }

    isCustomDateSelected(dateVal: string): boolean {
        return ![
            this.captions.current_day_front,
            this.captions.previous_day_front,
            this.captions.current_week_front,
            this.captions.previous_week_front,
            this.captions.current_month_front,
            this.captions.previous_month_front,
        ].includes(dateVal);
    }

    getWidgetDataRefresh(widget: Widget, forceRefresh: boolean): void {
        // hide refresh and configure buttons when title bar option selected
        widget.isWidgetLoaded = false;
        this.getWidgetDataByPeriod(widget, null, forceRefresh);
    }

    getWidgetOpenDates(e, widget: Widget): void {
        this.getWidgetOpenDateNoDialog(widget).then((openDates: WidgetOpenDates) => {
            this.dialog.open(WidgetOpenDatesDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    widget: widget,
                    openDates: openDates,
                },
            });
        });
    }

    onTabChanged(tabChangeEvent: MatTabChangeEvent, tabId: number): void {
        let currentTab: DashboardTabGetWithContent;
        if (this.tabs) {
            this.hasTabs = true;
            for (let i = 0; i < this.tabs.length; i++) {
                if (i === tabChangeEvent.index || this.tabs[i].id === tabId) {
                    this.currentTabIndex = i;
                    this.itemStorageService.currentTab = this.currentTabIndex;
                    currentTab = this.tabs[i];
                }
            }

            if (this.tabs[this.currentTabIndex] && this.tabs[this.currentTabIndex].content.length > 0) {
                let currentTabWidgets = this.tabs[this.currentTabIndex].content as Widget[];
                if (currentTabWidgets[0].widgetType === WidgetType.Wijmo) {
                    this.isWijmoTab = true;
                } 
                else if (currentTabWidgets[0].widgetType === WidgetType.Flat) {
                    this.isWijmoTab = false;
                    // there is an issue with preserveContent when switching from flat widgets to wijmo widgets
                    // when opening a flat widget tab, reset the load states for wijmo widgets to fix temporarily
                    for (let i = 0; i < this.isTabAlreadyLoaded.length; ++i) {
                        let tabWidgets = this.tabs[i].content as Widget[];
                        for (let widget of tabWidgets) {
                            widget.isWidgetLoaded = false;
                            widget.sentLoadRequest = false;
                        }
                        if (tabWidgets[0]?.widgetType === WidgetType.Wijmo) {
                            this.isTabAlreadyLoaded[i] = false;
                        }
                    }
                }
            }

            if (!this.viewShared && currentTab) {
                let selectedDateVal = currentTab.selectedDropdownOption;
                if (selectedDateVal === WidgetCustomDateOption.CustomDate) {
                    this.dashboardEmitterService.changeCurrentDashboardTab(
                        selectedDateVal,
                        this.formatDateSelection(currentTab.pickADateValue, true),
                        null
                    );
                } 
                else if (selectedDateVal === WidgetCustomDateOption.CustomDateRange) {
                    this.dashboardEmitterService.changeCurrentDashboardTab(
                        selectedDateVal,
                        this.formatDateSelection(currentTab.startDate, true),
                        this.formatDateSelection(currentTab.endDate, true)
                    );
                } 
                else {
                    this.dashboardEmitterService.changeCurrentDashboardTab(selectedDateVal, null, null);
                }
            }

            // clear out all previous widget subscriptions
            for (let sub of this.widgetSubscriptions) {
                sub?.unsubscribe();
            }
            this.widgetSubscriptions = [];

            // if the tab hasn't been loaded before, load it
            if (!this.isTabAlreadyLoaded[this.currentTabIndex]) {
                this.isTabAlreadyLoaded[this.currentTabIndex] = true;
                // Load widgets one by one
                this.loadWidgetRecursive(currentTab);
            }

            // resize gridster in current tab so that the wijmo widgets show
            if (this.wijmoGridster.toArray().length) {
                this.wijmoGridster.toArray()[this.currentTabIndex]?.resize();
            }
        } 
        else {
            this.hasTabs = false;
        }
    }

    openTabConfigMenu(): void {
        if (this.currentTabIndex < 0) return this.showNoTabErr();

        let currentTabWidgets = this.tabs[this.currentTabIndex].content as Widget[];

        if (currentTabWidgets.length === 0) {
            this.isWijmoWidget = true;
            this.isFlatWidget = true;
            this.isFlatWidgetToggled = true;
            this.isWijmoWidgetToggled = true;
        } 
        else if (currentTabWidgets.length > 0 && currentTabWidgets[0].widgetType === WidgetType.Wijmo) {
            this.isWijmoWidget = true;
            this.isFlatWidget = false;
            this.isFlatWidgetToggled = false;
            this.isWijmoWidgetToggled = true;
        } 
        else if (currentTabWidgets.length > 0 && currentTabWidgets[0].widgetType === WidgetType.Flat) {
            this.isFlatWidget = true;
            this.isWijmoWidget = false;
            this.isFlatWidgetToggled = true;
            this.isWijmoWidgetToggled = false;
        }
    }

    showConfigureWidgetDialog(ev: Event, widgetData: Widget | WidgetTemplateGet, isWidgetInstance: boolean): void {
        if (this.currentTabIndex < 0) return this.showNoTabErr();
        this.drawer.close();
        this.itemStorageService.tabId = this.tabs[this.currentTabIndex].id;

        let widgetId: number;
        let isFlatWidgetSelected: boolean;

        if (isWidgetInstance) {
            const widget = widgetData as Widget;
            widgetId = widget.widgetID;
            isFlatWidgetSelected = widget.widgetType == WidgetType.Flat;
        } 
        else {
            const widget = widgetData as WidgetTemplateGet;
            widgetId = widget.id;
            isFlatWidgetSelected = widget.widgetTemplateType == WidgetType.Flat;
        }

        this.dialog.open(ConfigureWidgetComponent, {
            panelClass: 'app-full-bleed-dialog',
            height: '90%',
            data: {
                selectedWidgetId: widgetId,
                isWidgetInstance: isWidgetInstance,
                isFlatWidgetSelected: isFlatWidgetSelected,
            },
        });
    }

    deleteTabDialog(ev: Event): void {
        let dialogRef = this.dialog.open(DeleteTabDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                currentWidgetId: this.currentWidgetId,
                currentTabIndex: this.currentTabIndex,
                tabs: this.tabs,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.event == true) {
                this.toggleWelcome = true;
            } else this.toggleWelcome = false;
        });
    }

    deleteWidgetDialog(ev: Event, widget: Widget): void {
        this.currentWidgetId = widget.widgetID;

        this.dialog.open(DeleteWidgetDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                currentWidgetId: this.currentWidgetId,
                currentTabIndex: this.currentTabIndex,
                tabs: this.tabs,
            },
        });
    }

    //-----------------------------------------------------------
    // Currently unused functions
    //-----------------------------------------------------------
    
    // submitTab(): void {
    //     this.dashboardApiService.updateTab(this.currentTab);
    // }

    // revertDropdownOption(oldValueForDropdown, currentTab) {
    //     if (oldValueForDropdown === this.WIDGET_CUSTOM_DATE_DROPDOWN_OPTIONS.CUSTOM_DATE)
    //         this.dashboardEmitterService.revertToPreviousSelectedDropdownOption(
    //             oldValueForDropdown,
    //             this.formatDateSelection(currentTab.pickADateValue, true),
    //             null
    //         );
    //     else if (oldValueForDropdown === this.WIDGET_CUSTOM_DATE_DROPDOWN_OPTIONS.CUSTOM_DATE_RANGE)
    //         this.dashboardEmitterService.revertToPreviousSelectedDropdownOption(
    //             oldValueForDropdown,
    //             this.formatDateSelection(currentTab.startDate, true),
    //             this.formatDateSelection(currentTab.endDate, true)
    //         );
    //     else this.dashboardEmitterService.revertToPreviousSelectedDropdownOption(oldValueForDropdown, null, null);
    // }

    // showConfirmRemoveDialog(): void {
    //     if (this.currentTabIndex < 0) return this.showNoTabErr();
    // }

    private itemChange(item: GridsterItem, itemComponent: GridsterItemComponentInterface, tabToUpdate: DashboardTabGetWithContent): void {
        if (!this.viewShared) {
            if (this.currentTabIndex >= 0 && this.tabs[this.currentTabIndex]?.content) {
                let widgetList = this.tabs[this.currentTabIndex].content as Widget[];
                widgetList.sort(this.compareWidgetIndices);
            }
            this.clearControlFromWidgets(tabToUpdate, item);
        }
    }

    /**
     * @name compareWidgetIndices
     * @description is the comparator for itemComponents (widgets). We need this function to sort the widgets for mobile view
     * @param itemComponent1 first element
     * @param itemComponent2 second element
     * @returns number
     */
    private compareWidgetIndices(itemComponent1: Widget, itemComponent2: Widget): number {
        // if the row is the same (ie y is the same), compare the column (ie x coordinate)
        // otherwise, only compare the row
        if (itemComponent1.y == itemComponent2.y) {
            return itemComponent1.x - itemComponent2.x;
        } 
        else {
            return itemComponent1.y - itemComponent2.y;
        }
    }

    private reload(): void {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('dummy', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

    /**
     * @name clearControlFromWidgets
     * @description clears removes control from widgets, updates the widgets using the api, and then restores control
     * @param tabToUpdate the tab with all the widgets (including old item, which will be updated)
     * @param item the widget that is being updated/changed
     */
    private clearControlFromWidgets(tabToUpdate: DashboardTabGetWithContent, item: Widget): void {
        let widgetsToSubmit: Widget[] = [];
        let widgetToSubmit: Widget;
        let widgetsInTab = tabToUpdate?.content as Widget[];
        const widgetMatch = widgetsInTab.filter((wig) => wig.widgetID === item.widgetID);

        if (tabToUpdate && typeof widgetMatch != 'undefined' && widgetMatch.length > 0) {
            for (let widget of widgetsInTab) {
                if (widget.widgetID === item.widgetID) {
                    widgetToSubmit = item;
                    widgetToSubmit.control?.refresh();
                } 
                else {
                    widgetToSubmit = widget;
                }
                widgetsToSubmit.push(widgetToSubmit);
            }
            setTimeout(() => {
                this.updateTabWidgets(tabToUpdate, widgetsToSubmit);
            }, 500);
        }
    }

    private updateTabWidgets(tabToUpdate: DashboardTabGetWithContent, widgets: Widget[]): void {
        let tempStorage: any = []; // to temporarily hold widget controls

        for (let i = 0; i < widgets.length; ++i) {
            tempStorage[i] = widgets[i].control;
            widgets[i].control = null;
        }

        let jsonObj = JSON.stringify(widgets);
        tabToUpdate.content = jsonObj;

        this.dashboardApiService.updateTab(tabToUpdate).then(() => {
            this.compareWithServerContent(tabToUpdate);
        });

        for (let i = 0; i < widgets.length; ++i) {
            widgets[i].control = tempStorage[i];
        }
    }

    private getAllSharedTabs(): Promise<void> {
        return this.dashboardApiService.getOwnerSharedTabs().then((sharedTabs: number[]) => {
            this.sharedTabs = sharedTabs;
        });
    }

    private getAllTabs(): Promise<void> {
        return this.dashboardApiService.getTabs().then((tabs) => {
            this.tabs = tabs;
            this.hasTabs = this.tabs.length > 0;

            for (let i = 0; i < this.tabs.length; i++) {
                if (this.tabs[i].id === this.favouriteTabID) {
                    if (typeof this.itemStorageService.currentTab == 'number') {
                        this.favouriteTabIDIndex = this.itemStorageService.currentTab;
                    } 
                    else {
                        this.favouriteTabIDIndex = i;
                    }
                }
                this.tabs[i].selectedDropdownOption = WidgetCustomDateOption.Default;
                this.tabs[i].content = this.tabs[i].content ? JSON.parse(decodeURIComponent(this.tabs[i].content)) : [];
            }

            this.toggleWelcome = this.tabs.length === 0 ? true : false;
            if (this.tabs.length > 0 && this.favouriteTabIDIndex == -1) {
                this.favouriteTabIDIndex = 0;
            }
        });
    }

    private getAllSharedTabsForViewers(): Promise<void> {
        return this.dashboardApiService.getSharedDashboardTabs().then((tabs: DashboardTab[]) => {
            this.tabs = tabs;
            this.hasTabs = this.tabs?.length > 0;
            this.favouriteTabIDIndex = 0;

            if (this.hasTabs) {
                for (let i = 0; i < this.tabs?.length; i++) {
                    this.tabs[i].content = this.tabs[i].content ? JSON.parse(decodeURIComponent(this.tabs[i].content)) : [];
                    for (let content of this.tabs[i].content as Widget[]) {
                        if (content.widgetType === WidgetType.Flat) {
                            this.getFlatWidgetData(content, null, null, null, null);
                        }
                    }
                }
            }
        });
    }

    private getFlatWidgetData(content: Widget, period: WidgetCustomDateOption, startDate: string, endDate: string, forceRefresh: boolean): void {
        if (typeof content !== 'undefined' && content != null) {
            if (!period && !startDate) {
                if (this.viewShared) {
                    this.dashboardApiService
                        .getViewedWidgetDataByIdAndPeriod$(content, null, forceRefresh)
                        .pipe(take(1))
                        .subscribe((widgetData) => {
                            this.setWidgetData(widgetData, content);
                        });
                } 
                else {
                    this.dashboardApiService
                        .getWidgetDataByIdAndPeriod$(content, null, forceRefresh)
                        .pipe(take(1))
                        .subscribe((widgetData) => {
                            this.setWidgetData(widgetData, content);
                        });
                }
            } 
            else if (period && !startDate) {
                if (this.viewShared) {
                    this.dashboardApiService
                        .getViewedWidgetDataByIdAndPeriod$(content, period, forceRefresh)
                        .pipe(take(1))
                        .subscribe((widgetData) => {
                            this.setWidgetData(widgetData, content);
                        });
                } 
                else {
                    this.dashboardApiService
                        .getWidgetDataByIdAndPeriod$(content, period, forceRefresh)
                        .pipe(take(1))
                        .subscribe((widgetData) => {
                            this.setWidgetData(widgetData, content);
                        });
                }
            } 
            else {
                if (this.viewShared) {
                    this.dashboardApiService
                        .getViewedWidgetDataByIdAndDateRange$(content, startDate, endDate)
                        .pipe(take(1))
                        .subscribe((widgetData) => {
                            this.setWidgetData(widgetData, content);
                        });
                } 
                else {
                    this.dashboardApiService
                        .getWidgetDataByIdAndDateRange$(content, startDate, endDate)
                        .pipe(take(1))
                        .subscribe((widgetData) => {
                            this.setWidgetData(widgetData, content);
                        });
                }
            }

            if (!this.viewShared || content?.dataValues) {
                this.conceptApiService.getConceptById(content.conceptID).then((concept: Concept) => {
                    content.conceptName = concept.displayName;
                });
            }
        }
    }

    private setWidgetData(widgetData: Object[], content: Widget): Object {
        return (content.dataValues = widgetData[0]);
    }

    private postFlatWidgetData(content: Widget, period: WidgetCustomDateOption, startDate: string, endDate: string): void {
        if (typeof content !== 'undefined' && content != null) {
            this.conceptApiService.getConceptById(content.conceptID).then((concept: Concept) => {
                content.conceptName = concept.displayName;
            });

            if (!period && !startDate) {
                this.dashboardApiService.postWidgetDataByIdAndPeriod(content, null, null).then((widgetData) => {
                    this.setWidgetData(widgetData, content);
                });
            } else if (period && !startDate) {
                this.dashboardApiService.postWidgetDataByIdAndPeriod(content, period, null).then((widgetData) => {
                    this.setWidgetData(widgetData, content);
                });
            } 
            else {
                this.dashboardApiService.postWidgetDataByIdAndDateRange(content, startDate, endDate).then((widgetData) => {
                    this.setWidgetData(widgetData, content);
                });
            }
        }
    }

    private getAllWidgetTemplates(): Promise<void> {
        return this.dashboardApiService.getWidgetTemplates().then((widgetTemplates) => {
            this.widgetTemplates = widgetTemplates;
        });
    }

    private compareWithServerContent(tab: DashboardTabGetWithContent): Promise<void> {
        return new Promise<void>((resolve) => {
            let currentTabContent = this.tabs[this.currentTabIndex].content as Widget[];
            let serverContent = JSON.parse(unescape(tab.content)) as Widget[];
            let contentNotSame = !currentTabContent || !serverContent || currentTabContent.length != serverContent.length;

            if (contentNotSame) {
                this.getAllTabs().then(() => {
                    resolve();
                });
            }

            let refreshNeeded = false;
            let widgetFound: Widget;

            for (let widget of serverContent) {
                widgetFound = currentTabContent.find((currentTabWidget) => {
                    return currentTabWidget.widgetID == widget.widgetID;
                });
                if (!widgetFound) refreshNeeded = true;
            }

            if (refreshNeeded) {
                this.getAllTabs().then(() => {
                    resolve();
                });
            }
            if (!contentNotSame && !refreshNeeded) {
                resolve();
            }

            resolve();
        });
    }

    private getWidgetDefinition(widget: Widget): Promise<void> {
        widget.isWidgetLoaded = false;
        this.widget = widget;

        if (this.viewShared) {
            return new Promise((resolve) => this.dashboardApiService
                .getViewedWidgetDefinitionById$(widget)
                .pipe(take(1))
                .subscribe((widgetData) => { 
                    this.getDefinitionSuccess(widgetData, widget); 
                    resolve(); 
                }));
        } 
        else {
            return new Promise((resolve) => this.dashboardApiService
                .getWidgetDefinitionById$(widget)
                .pipe(take(1))
                .subscribe((widgetData) => {
                    this.getDefinitionSuccess(widgetData, widget);
                    resolve();
                }));
        }
    }

    private getDefinitionSuccess(widgetInfo: WidgetDefinitionData, widget: Widget): void {
        const currentTabSelectedDate = this.tabs[this.currentTabIndex].selectedDropdownOption;
        this.showRefreshButton = false;

        if (currentTabSelectedDate === WidgetCustomDateOption.Default || this.viewShared) {
            widget.dateValue = widgetInfo.dateValue;
            this.showRefreshButton = true;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.CurrentDay) {
            widget.dateValue = this.captions.current_day_front;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.PreviousDay) {
            widget.dateValue = this.captions.previous_day_front;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.CurrentWeek) {
            widget.dateValue = this.captions.current_week_front;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.PreviousWeek) {
            widget.dateValue = this.captions.previous_week_front;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.CurrentMonth) {
            widget.dateValue = this.captions.current_month_front;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.PreviousMonth) {
            widget.dateValue = this.captions.previous_month_front;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.CustomDate) {
            widget.dateValue = this.dateSelectedVal;
        } 
        else if (currentTabSelectedDate === WidgetCustomDateOption.CustomDateRange) {
            widget.dateValue = this.dateRangeSelectedVal;
        } 
        else {
            widget.dateValue = widgetInfo.dateValue;
        }

        if (widget.widgetType != WidgetType.Wijmo) {
            return;
        }

        widget?.control?.dispose();
        const widgetDefinition = JSON.parse(widgetInfo.definition);
        const widgetInfoChartType = parseInt(widgetInfo.chartType);

        if (widgetInfo.wijmoWidgetType == 'FlexPie') {
            widget.control = new wijmoChart.FlexPie('#widget' + widget.widgetID, widgetDefinition);
        } 
        else if (widgetInfo.wijmoWidgetType == 'FlexChart') {
            widget.control = new wijmoChart.FlexChart('#widget' + widget.widgetID, widgetDefinition);
            widget.control.chartType = widgetInfoChartType >= 0 && widgetInfoChartType <= 9 ? widgetInfoChartType : 0;
        } 
        else {
            console.error("No Such Wijmo Widget Type");
        }

        if (widget.control.chartType !== widgetInfoChartType) {
            widget.control.chartType = widgetInfoChartType >= 0 && widgetInfoChartType <= 9 ? widgetInfoChartType : 0;
        }

        widget.control.palette = this.wijmoThemeService.getThemeObjByName(widgetInfo.theme);
    }


    private postWidgetDataRefresh(widget: Widget, forceRefresh: boolean): void {
        this.postWidgetDataByPeriod(widget, null, forceRefresh);
    }

    private postWidgetDataByPeriod(widget: Widget, customPeriod: WidgetCustomDateOption, forceRefresh: boolean): void {
        if (widget.widgetType === WidgetType.Wijmo) {
            widget.isWidgetLoaded = false;
            this.dashboardApiService.postWidgetDataByIdAndPeriod(widget, customPeriod, forceRefresh).then((data) => {
                this.getDataSuccess(data, widget);
            });
        } 
        else if (widget.widgetType === WidgetType.Flat) {
            if (typeof customPeriod !== 'undefined' && customPeriod !== null) {
                this.postFlatWidgetData(widget, customPeriod, null, null);
            } 
            else {
                this.postFlatWidgetData(widget, null, null, null);
            }
        }
    }

    private getWidgetOpenDateNoDialog(widget: Widget): Promise<WidgetOpenDates> {
        if (this.viewShared) {
            return this.dashboardApiService.getViewedWidgetOpenDates(widget.widgetID);
        } 
        else {
            return this.dashboardApiService.getWidgetOpenDates(widget.widgetID);
        }
    }

    private getWidgetDataByPeriod(widget: Widget, customPeriod: WidgetCustomDateOption, forceRefresh: boolean): void {
        if (widget.widgetType === WidgetType.Wijmo) {
            widget.isWidgetLoaded = false;
            if (this.viewShared) {
                this.dashboardApiService.getViewedWidgetDataByIdAndPeriod$(widget, customPeriod, forceRefresh).pipe(take(1)).subscribe((data) => {
                    this.getDataSuccess(data, widget);
                });
            } 
            else {
                this.dashboardApiService.getWidgetDataByIdAndPeriod$(widget, customPeriod, forceRefresh).pipe(take(1)).subscribe((data) => { 
                    this.getDataSuccess(data, widget);
                });
            }
        } 
        else if (widget.widgetType === WidgetType.Flat) {
            if (typeof customPeriod !== 'undefined' && customPeriod !== null) {
                this.getFlatWidgetData(widget, customPeriod, null, null, forceRefresh);
            } 
            else {
                this.getFlatWidgetData(widget, null, null, null, forceRefresh);
            }
        }
    }


    private postWidgetDataByDateRange(widget: Widget, startDate: string, endDate: string): void {
        if (widget.widgetType === WidgetType.Wijmo) {
            this.dashboardApiService.postWidgetDataByIdAndDateRange(widget, startDate, endDate).then((data) => {
                this.getDataSuccess(data, widget);
            });
        } 
        else if (widget.widgetType === WidgetType.Flat) {
            this.postFlatWidgetData(widget, null, startDate, endDate);
        }
    }

    private getWidgetDataByDateRange(widget: Widget, startDate: string, endDate: string): void {
        this.widget.isWidgetLoaded = false;
        if (widget.widgetType === WidgetType.Wijmo) {
            this.dashboardApiService
                .getWidgetDataByIdAndDateRange$(widget, startDate, endDate)
                .pipe(take(1))
                .subscribe((data) => {
                    this.getDataSuccess(data, widget);
                });
        } 
        else if (widget.widgetType === WidgetType.Flat) {
            this.getFlatWidgetData(widget, null, startDate, endDate, null);
        }
    }

    private getDataSuccess(data: Object[], widget: Widget): void {
        if (widget.groupingID && widget.groupingID.toString().startsWith('20')) {
            this.storeGroupMembersApiService
                .getAllStoreGroupItemsForStoreGroup(widget.groupingID)
                .then((groupItems) => {
                    return this.getGroupItemsSuccess(groupItems, data, widget)
                });
        }
        else if (widget.groupingID && widget.groupingID.toString().startsWith('19')) {
            this.tagApiService.getTagById(widget.groupingID).then((tag: Tag) => {
                this.tagApiService.getStoresByTagID(widget.groupingID).then((stores) => this.getStoresByTagIDSuccess(tag, stores, data, widget));
            });
        } 
        else {
            widget.control.itemsSource = data;
            widget.isWidgetLoaded = true;
        }

        for (let element of data) {
            for (let key in element) {
                if (element[key] === '') {
                    element[key] = null;
                    element['StoreDisplayName'] += ' [' + this.captions.reportNoData + ']';
                }
            }
        }

        widget.control.legend._position = 4;
        widget.control.refresh();

        let allDataIs0: boolean = true;

        //loop through all the data, if all the data values contain 0, change the label to contain all 0s
        for (let i = 0; i < data?.length; ++i) {
            let theKeys = Object.keys(data[i]);
            let j = 0;
            // because the data isn't always the first element/key, we need to loop through all the elements in the object until we find one with type number
            // sometimes the object also does not have any element that is a number, so we must also consider that
            for (; j < theKeys.length && typeof data[i][theKeys[j]] !== 'number'; j++) {}
            if (j >= theKeys.length || typeof data[i][theKeys[j]] != 'number' || data[i][theKeys[j]] != 0) {
                allDataIs0 = false;
            }
        }

        if (allDataIs0) {
            widget.control.dataLabel.content = '0';
            widget.control.dataLabel.position = 2;
        }

        this.getWidgetOpenDateNoDialog(widget).then((openDates: WidgetOpenDates) => {
            widget.startDate = moment(openDates.startDate, 'YYYYMMDD');
            widget.endDate = moment(openDates.endDate, 'YYYYMMDD');
            if (widget.startDate.isSame(widget.endDate, 'date')) {
                widget.sameDate = true;
            } else {
                widget.sameDate = false;
            }
        });
    }

    private getGroupItemsSuccess(groupItems: GroupItem[], data: Object[], widget: Widget): void {
        let dataSetFinal: Promise<Object>[] = []

        let newDataSet = (item: GroupItem) => {
            return new Promise<Object>((resolve) => {
                this.storeGroupMembersApiService
                    .getGroupItemInfoById(item.id)
                    .then((storesWithThisItem) => resolve(this.groupDataPerItem(storesWithThisItem, item, data)));
            });
        };

        for (let item of groupItems) {
            dataSetFinal.push(newDataSet(item));
        }

        Promise.all(dataSetFinal).then((data) => {
            if (data[0] != null) {
                widget.control.itemsSource = data;
            }
        });
    }

    private getStoresByTagIDSuccess(tag: Tag, stores: StoreBase[], data: Object[], widget: Widget): void {
        let dataSetFinal: Promise<Object>[] = [];

        let newDataSet = (item: Tag) => {
            return new Promise((resolve) => {
                resolve(this.groupDataPerItem(stores, item, data));
            });
        };

        dataSetFinal.push(newDataSet(tag));

        Promise.all(dataSetFinal).then((data) => {
            widget.control.itemsSource = data;
        });
    }

    private groupDataPerItem(storesWithThisItem: StoreBase[], item: any, data: any[]): Object {
        let dataValue: number = 0;
        let dataKey: string = '';
        let newValue: number = 0;
        let newDataSet: any = {};

        for (let store of storesWithThisItem) {
            for (let info of data) {
                if (info.Store == store.id.toString()) {
                    Object.keys(info).forEach((key) => {
                        if (typeof info[key] === 'number') {
                            dataValue = info[key];
                            dataKey = key;
                        }
                    });

                    if (newDataSet && newDataSet.StoreDisplayName == item.displayName && dataValue != 0) {
                        newValue += dataValue;
                    } 
                    else if (dataValue == 0) {
                        break;
                    }
                    else {
                        newValue = dataValue;
                    }
                }
            }
            if (newValue != 0) {
                newDataSet.StoreDisplayName = item.displayName;
                newDataSet[dataKey] = newValue;
            }
        }

        if (JSON.stringify(newDataSet) != '{}') {
            return newDataSet;
        } 
        else {
            return null;
        } 
    }


    private showNoTabErr(): void {
        this.snackbarService.errorMessageTop(this.captions.errCreateTabFirst);
    }

    private formatDateSelection(date: Date, isForDisplayOption: boolean): string {
        if (!date) {
            return null;
        }

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        let monthString = month < 10 ? "0" + month : month.toString();
        let dayString = day < 10 ? "0" + day : day.toString();

        if (!isForDisplayOption) {
            return year.toString() + monthString + dayString;
        }
        else {
            return year.toString() + '-' + monthString + '-' + dayString;
        }
    }

    /**
     * @name loadWidgetRecursive this function calls _loadWidget on each widget on the current tab.
     * @param currentTab the tab that the user is on
     * @param scrollEvent scrollEvent is not null when we are scrolling, otherwise it is null
     */
    private loadWidgetRecursive(currentTab: DashboardTabGetWithContent, scrollEvent = null): void {
        if (currentTab) {
            let widgetList = currentTab.content as Widget[];
            // sort the widgets so that they load in order from top to bottom
            widgetList.sort(this.compareWidgetFn);
            for (let i = 0; i < widgetList.length; i++) {
                var widgetHeight = widgetList[i].y * this.gridster.curRowHeight;
                var bottom = scrollEvent?.target.offsetHeight + scrollEvent?.target.scrollTop;
                // if the widget is in view, and we haven't already requested to load the widget, send the load request
                // for when a scroll event happens
                if (scrollEvent && !widgetList[i]?.sentLoadRequest && widgetHeight < bottom) {
                    this._loadWidget(currentTab, widgetList[i]);
                    widgetList[i].sentLoadRequest = true;
                }
                // first loading the first few widgets, because no scroll has happened
                // being in this if statement means that the widget hasn't been loaded before
                else if (!scrollEvent && widgetHeight <= window.innerHeight) {
                    this._loadWidget(currentTab, widgetList[i]);
                    widgetList[i].sentLoadRequest = true;
                }
            }
        }
    }

    private _loadWidget(currentTab: DashboardTabGetWithContent, currentWidget: Widget): void {
        if (!currentWidget.blocked) {
            if (currentWidget.widgetType == WidgetType.Wijmo) {
                this.getWidgetDefinition(currentWidget).then(() => {
                    if (currentTab.selectedDropdownOption === WidgetCustomDateOption.Default) {
                        this.getWidgetDataByPeriod(currentWidget, null, false);
                    } 
                    else if (currentTab.selectedDropdownOption === WidgetCustomDateOption.CustomDate) {
                        this.getWidgetDataByDateRange(
                            currentWidget,
                            this.formatDateSelection(currentTab.pickADateValue, false),
                            this.formatDateSelection(currentTab.pickADateValue, false)
                        );
                    }
                    else if (currentTab.selectedDropdownOption === WidgetCustomDateOption.CustomDateRange) {
                        this.getWidgetDataByDateRange(
                            currentWidget,
                            this.formatDateSelection(currentTab.startDate, false),
                            this.formatDateSelection(currentTab.endDate, false)
                        );
                    }
                    else {
                        // get shared
                        this.getWidgetDataByPeriod(currentWidget, currentTab.selectedDropdownOption, false);
                    }
                });
            } 
            else if (currentWidget.widgetType == WidgetType.Flat) {
                this.getWidgetDefinition(currentWidget).then(() => {
                    if (currentTab.selectedDropdownOption === WidgetCustomDateOption.Default) {
                        this.getFlatWidgetData(currentWidget, null, null, null, null);
                    } 
                    else if (currentTab.selectedDropdownOption === WidgetCustomDateOption.CustomDate) {
                        let formattedDate = this.formatDateSelection(currentTab.pickADateValue, false);
                        this.getFlatWidgetData(currentWidget, null, formattedDate, formattedDate, null);
                    } 
                    else if (currentTab.selectedDropdownOption === WidgetCustomDateOption.CustomDateRange) {
                        let formattedStartDate = this.formatDateSelection(currentTab.startDate, false);
                        let formattedEndDate = this.formatDateSelection(currentTab.endDate, false);
                        this.getFlatWidgetData(currentWidget, null, formattedStartDate, formattedEndDate, null);
                    } 
                    else {
                        this.getFlatWidgetData(currentWidget, currentTab.selectedDropdownOption, null, null, null);
                    }
                });
            } 
            else if (!currentWidget.conceptID) {
                // if you do not have permissions
                let content = this.tabs[this.currentTabIndex].content as Widget[];
                let widget = {
                    sizeY: Array.isArray(currentWidget?.sizeY) ? 4 : currentWidget?.sizeY,
                    sizeX: Array.isArray(currentWidget?.sizeX) ? 4 : currentWidget?.sizeX,
                    widgetType: 1,
                    widgetID: currentWidget.widgetID[0],
                    displayName: 'You do not have permission to view this widget',
                    blocked: true,
                };
                // Trigger mobile breakpoint when the user has started on mobile
                if (this.tabs.length === 1 && content.length === 0 && this.mobileBreakPointCounter == 0) {
                    this.mobileBreakPointCounter += this.tabs.length;
                    this.mobileBreakPointBroke = true;
                }
                content.splice(content.indexOf(currentWidget), 1, widget);
                this.isWijmoTab = true;
            }
        }
    }

    private compareWidgetFn(widget1: Widget, widget2: Widget): number {
        if (widget1.y == widget2.y) {
            return widget1.x - widget2.x;
        } 
        else {
            return widget1.y - widget2.y;
        }
    }
}
