import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "tagsFilter",
    pure: false
})
export class TagsFilterPipe implements PipeTransform {
    transform(stores: any, tags?: any): any[] {
        let storesForChosenTags = tags;

        if (typeof stores === 'undefined' || stores.length <= 0) return [];
        /* we cannot check storesForChosenTags.length attribute. */
        if (typeof storesForChosenTags === 'undefined') return stores;
        let allStoresForChosenTags = [];

        /* so we will return all stores in case no tags are selected and we want to see all stores */
        let storesForChosenTagsCounter = 0;
        for (let tagIdKey in storesForChosenTags) {
            if (storesForChosenTags.hasOwnProperty(tagIdKey)) {
                allStoresForChosenTags = allStoresForChosenTags.concat(storesForChosenTags[tagIdKey]);
                storesForChosenTagsCounter++;
            }
        }

        if (storesForChosenTagsCounter === 0) return stores;

        let filteredStores = [];
        for (let store of stores) {
            /*if a store is inside array of allowable by tag filter array of store ids.. then put it on the screen*/
            if (allStoresForChosenTags.indexOf(store.id) >= 0) filteredStores.push(store);
        }
        return filteredStores;
    }
}