import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { StoreApiService } from "../../services/store.resource";

@Component({
    selector: 'store-download-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/store-download-dialog.html',
})
export class StoreDownloadDialog {

    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<StoreDownloadDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
    ) {
        this.captions = this.captionService.captions;
    }

    downloadInstaller(installerName: string): void {
        this.storeApiService.downloadInstaller(installerName).then((downloadLink: string) => {
            let uri = downloadLink;
            let link = document.createElement("a");
            link.href = uri.toString();
            link.download = downloadLink.toString();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }
}
