export class AppConstants {
    public static LANGUAGES = {
        english: 'ENG',
        chinese: 'CHN',
        arabic: 'ARA',
        spanish: 'SPA',
        french: 'FRA',
    };
    public static AUTH_EVENTS = {
        loginSuccess: 'auth-login-success',
        loginFailed: 'auth-login-failed',
        logoutSuccess: 'auth-logout-success',
        sessionTimeout: 'auth-session-timeout',
        notAuthenticated: 'auth-not-authenticated',
        notAuthorized: 'auth-not-authorized',
    };
    public static COLOR_PICKER = {
        url: '../data/colorCode.json',
    };
    public static USER_ROLES = {
        admin: 'admin',
        guest: 'guest',
    };
    public static THEMES = {
        light: 'light',
        dark: 'dark',
    };
    public static API_PACKAGE = {
        report: '16000001',
        EOI: '16000002',
    };
    public static BASE_URL = getBaseUrl();
    public static MAX_TAGS = 20;
    public static MAX_STOREGROUPS = 10;
    public static MAX_GROUPMEMBERS = 20;
    public static WIDGET_CUSTOM_DATE_DROPDOWN_OPTIONS = {
        DEFAULT: 'default',
        CUSTOM_DATE: 'customDate',
        CUSTOM_DATE_RANGE: 'customDateRange',
        CURRENT_DAY: 'currentDay',
        PREVIOUS_DAY: 'previousDay',
        CURRENT_WEEK: 'currentWeek',
        PREVIOUS_WEEK: 'previousWeek',
        CURRENT_MONTH: 'currentMonth',
        PREVIOUS_MONTH: 'previousMonth',
    };
    public static REPORT_HTML_STYLE = {
        DARK: "<style type='text/css'> body{background-color: #484848; color:rgba(255, 255, 255, 0.7);}b, h3{color: rgb(255,64,129)!important} </style>",
    };
    public static REPORT_TECHNOLOGY_TYPES = {
        WIJMO_OLAP: 'wijmo_olap',
        FLAT_REPORT: 'flat_report',
    };
    public static FLAT_SEVEN_DAY_LABOUR = 130;
    public static WIDGET_TYPES = {
        WIJMO: 1,
        FLAT: 2,
    };
    public static CURRENCYCODES = {
        CAD: 'CAD',
        USD: 'USD',
        AUD: 'AUD',
        EUR: 'EUR',
        MXN: 'MXN',
        GBP: 'GBP',
        AED: 'AED',
        PKR: 'PKR',
        PEN: 'PEN',
        GTQ: 'GTQ',
        BSD: 'BSD',
        BHD: 'BHD',
        SAR: 'SAR',
        KHR: 'KHR'
    };
    public static CURRENCYCODES_NG = ['CAD', 'USD', 'AUD', 'EUR', 'MXN', 'GBP', 'AED', 'PKR', 'PEN', 'GTQ', 'BSD', 'BHD', 'SAR', 'KHR'];
    public static BILLTYPES = {
        LIVE: 'Live',
        LAB: 'Lab',
    };
    public static BILLTYPES_NG = ['Live', 'Lab'];
    public static httpErrors = {
        0: 'The server is unreachable.',
        401: 'You are unauthorized to perform this action.', // UNAUTHORIZED
        400: 'The requested action is not valid.', // BAD REQUEST
        404: 'The requested data or service could not be found.',
        500: 'Unknown errors occurred at the server.',
    };

    public static WIJMO_LICENSE = {
        PPHQ: 'pixelpointhq.com|www.pixelpointhq.com|qa.pixelpointhq.com|ci.pixelpointhq.com|prod.pixelpointhq.com,567958826111236#B04OtYTNiojIklkIs4XXbpjInxmZiwiIyYnMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZCxUZ55Eew96dNpEOyElVFFDT6tmTxh6QipmZkVEVjFVVntmNtxGO7VjeklUZulUTvZUSYt6ZDF6UiJ7KN3WZzZlN9kkV5NUaEpGc8FVbrEjZsdTSrlHe5Z7daJTQjVUbpJ6MVhXYzsSUnR4aWlFWrkDTCFlQT5UMVRTW836cwIHVCNldNZTNDBjTMlHSmZkWpZWSHN6RClGMlFVM7UHbFFlV9I7VtpEWx86YmhVWadlMDpkY7QTM4pUTFZmVXhjTBNkQ8dUUjtmeOpERYVTcwEnQHR7KSRnQvo6T6kkWsZTY8cTUSBFMid5SDV4N4VlTrhzULZ6cHdmdnpXRoZGbwI6br2ERwhmWR9WdwAXQNFXMrgUdmhUeK34UltyUEpnYMJTOIdHTodHMidWWvIFVBRmVmp5Ku36Q6VjUxBXeCdHRyQFSOh5ZP3GS5InYIJ5LVZVbjllI0IyUiwiIGRDMCNEMwIjI0ICSiwiM9EzM6QjMxMTM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISN5QTNxADI7IzNwIjMwIjI0ICdyNkIsISbvNmLxhGdul6bwxWZ8lGcuQ6byBHLt36YuEHa49WavBHblhXaw9SajxSbvNmLxhGdul6bwxWZ8lGcuEWcs46bj9ScoRnbp3GcsVGepBnL7d7ds46bj9ScoRnbp3GcsVGepBnI0IyctRkIsIicllGa4VXYHBCblFGajlWTiojIh94QiwiI6MjMxETM6I5OdU',
    };
}

function getBaseUrl() {
    // return 'http://localhost:8080/api/v1/web/';
    // return 'https://qa.pixelpointhq.com/api/v1/web/';
    // return 'https://prod.pixelpointhq.com/api/v1/web/';
    let serverUrl = window.location.origin;
    if (typeof serverUrl === 'undefined' || serverUrl === null) {
        // Internet Explorer
        serverUrl =
            window.location.protocol +
            '//' +
            window.location.hostname +
            (window.location.port ? ':' + window.location.port : '');
    }

    if (serverUrl.indexOf('://172.') > 0 || serverUrl.indexOf('://192.') > 0 || serverUrl.indexOf('://localhost') > 0) {
        return 'https://ci.pixelpointhq.com/api/v1/web/';
    } else {
        return serverUrl + '/api/v1/web/';
    }
}