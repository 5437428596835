import { Component, Inject, Optional } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { EditColumnsDialogV2 } from './dialogs/wid-011-edit-columns-dialog.component';
import * as moment from 'moment';

export interface EditColumnsData {
    pos: number;
    punch: number;
    multiView: number;
}

@Component({
    selector: 'wid011',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-011.html',
})
export class Wid011 {
    public captions: any;
    public isTimedOut: boolean = false;
    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    public records: any;
    public recordData = [];
    public sortedData;
    public pos = 0;
    public punch = 0;
    public multiView = 0;
    public pageEvent: PageEvent;
    private pageIndex = 0;
    public pageSize = 18;
    public currentSort: Sort = { active: '', direction: '' };

    private readonly WIDGET_COLUMN1_KEY: string = 'Sx1_column1_V2';
    private readonly WIDGET_COLUMN2_KEY: string = 'Sx1_column2_V2';
    private readonly WIDGET_COLUMN3_KEY: string = 'Sx1_column3_V2';
    private readonly WIDGET_CURRENT_SORT_KEY: string = 'currentSort_V2';

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.captions = this.captionService.captions;
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    ngOnInit() {
        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }

        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }
        if (this.areDatapointsInvalid) {
            return;
        }
        this.records = this.widget.data[0].Record;
        if (!this.records) {
            this.records = [];
            return;
        }
        this.records = Array.isArray(this.records) ? this.records : [this.records];
        this.records.forEach((o) => {
            this.recordData.push(o);
        });
        this.getColumns();

        this.currentSort = JSON.parse(this.browserStorage.getSessionstorage(this.WIDGET_CURRENT_SORT_KEY, null));
        if (this.currentSort) {
            this.sortData(this.currentSort);
        } else {
            this.sortedData = this.recordData.slice();
            this.updateSortedData();
        }
    }

    getColumns() {
        let portalData = this.browserStorage.getSessionstorage('portalData', null);
        let data = JSON.parse(JSON.stringify(portalData));
        let config;
        if (data.WidgetValueConfig) {
            config = JSON.parse(data.WidgetValueConfig);
        }

        let browserStoragePos = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN1_KEY, this.pos);
        let browserStoragePunch = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN2_KEY, this.punch);
        let browserStorageMultiView = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN3_KEY, this.multiView);

        // open and closed shifts (2) column index
        this.pos = browserStoragePos
            ? browserStoragePos
            : config?.labour2column1Selection
            ? config.labour2column1Selection
            : 0;
        this.punch = browserStoragePunch
            ? browserStoragePunch
            : config?.labour2column2Selection
            ? config.labour2column2Selection
            : 0;
        this.multiView = browserStorageMultiView
            ? browserStorageMultiView
            : config?.labour2column3Selection
            ? config.labour2column3Selection
            : 0;
    }

    saveColumns() {
        this.browserStorage.setSessionstorage(this.WIDGET_COLUMN1_KEY, this.pos);
        this.browserStorage.setSessionstorage(this.WIDGET_COLUMN2_KEY, this.punch);
        this.browserStorage.setSessionstorage(this.WIDGET_COLUMN3_KEY, this.multiView);
    }

    handlePageEvent(e: PageEvent) {
        this.pageEvent = e;
        this.pageIndex = e.pageIndex;

        this.currentSort = JSON.parse(this.browserStorage.getSessionstorage(this.WIDGET_CURRENT_SORT_KEY, null));
        if (this.currentSort) {
            this.sortData(this.currentSort);
        } else {
            this.updateSortedData();
        }
    }

    updateSortedData(): any[] {
        const startIndex = this.pageIndex * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.sortedData = this.recordData.slice(startIndex, endIndex);

        return this.sortedData;
    }

    openEditColumnsDialog() {
        const dialogRef = this.dialog.open(EditColumnsDialogV2, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
                widgetTitle: this.widget?.title,
                pos: this.pos,
                punch: this.punch,
                multiView: this.multiView,
            },
        });

        dialogRef.afterClosed().subscribe((result: EditColumnsData) => {
            if (result) {
                this.pos = result.pos;
                this.punch = result.punch;
                this.multiView = result.multiView;
                this.saveColumns();
            }
        });
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    // Next 3 methods check for year 1899, if is then means shift is not closed
    parseDate(date) {
        let formattedDate = moment(date).format('YYYY-MM-DD hh:mm:ss');
        return moment(date).format('YYYY') == '1899' ? 'current' : formattedDate;
    }

    parseHours(date) {
        let hours = new Date(date);
        if (hours.getFullYear() == 1899) {
            return;
        }
        let hour = hours.getHours() < 10 ? '0' + hours.getHours() : hours.getHours();
        return hour;
    }

    parseMinutes(date) {
        let test = new Date(date);
        if (test.getFullYear() == 1899) {
            return 'current';
        }
        return test.getMinutes() < 10 ? ':0' + test.getMinutes() : ':' + test.getMinutes();
    }

    formatTime(number) {
        let time;
        let hour = Math.floor(number);
        let dec = number - hour;
        let min = 1 / 60;
        dec = min * Math.round(dec / min);
        let minute = Math.floor(dec * 60) + '';
        minute = minute.length < 2 ? '0' + minute : minute;
        //add days if more than 24h
        if (hour / 24 > 1) {
            time = Math.floor(number / 24) + 'd ' + Math.floor(number % 24) + 'h ' + +minute + 'm';
        } else {
            time = hour + 'h ' + minute + 'm';
        }
        return time;
    }

    private tooltipValues = {
        pos: [(data: any) => data.POSNAME, (data: any) => data.PUNCHINDEX],
        punch: [
            (data: any) =>
                this.parseHours(data.PUNCHIN) +
                this.parseMinutes(data.PUNCHIN) +
                ' - ' +
                (this.parseHours(data.PUNCHOUT) ? this.parseHours(data.PUNCHOUT) : '') +
                this.parseMinutes(data.PUNCHOUT),
            (data: any) => data.JOBPOSITION,
            (data: any) => this.formatTime(data.TOTALHOURS),
            (data: any) => this.parseDate(data.PUNCHIN),
            (data: any) => this.parseDate(data.PUNCHOUT),
        ],
        multiView: [
            (data: any) => data.BREAKCOUNT,
            (data: any) => this.formatTime(data.BREAKHOURS),
            (data: any) => this.formatTime(data.REGHOURS),
            (data: any) => data.QUANVOID,
            (data: any) => data.BREAKSCANCELLED,
            (data: any) => data.PUNCHINDEX,
            (data: any) => this.formatTime(data.TOTALHOURS),
            (data: any) => this.formatTime(data.OTHOURS),
            (data: any) => this.formatTime(data.PAIDHOURS),
            (data: any) => data.NUMNOSALE,
            (data: any) => data.JOBPOSITION,
        ],
    };

    getTooltipText(data: any, column: number): string {
        const tooltipCategory = ['pos', 'punch', 'multiView'][column];
        const tooltipIndex = [this.pos, this.punch, this.multiView][column];
        const toolTips = this.tooltipValues[tooltipCategory];

        if (toolTips) {
            const text = toolTips[tooltipIndex];
            if (text) {
                return text(data);
            }
        }
        return '';
    }

    sortData(sort: Sort) {
        const data = this.updateSortedData();
        if (!sort.active || sort.direction === '') {
            this.sortedData = data;
            this.browserStorage.removeSessionstorageItem(this.WIDGET_CURRENT_SORT_KEY);
            return;
        }

        this.sortedData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';

            switch (sort.active) {
                case 'POSNAME':
                    return this.compare(a.POSNAME, b.POSNAME, isAsc);
                case 'PUNCHIN':
                    return this.compare(a.PUNCHIN, b.PUNCHIN, isAsc);
                case 'JOBPOSITION':
                    return this.compare(a.JOBPOSITION, b.JOBPOSITION, isAsc);
                case 'TOTALHOURS':
                    return this.compare(a.TOTALHOURS, b.TOTALHOURS, isAsc);
                case 'PUNCHOUT':
                    return this.compare(a.PUNCHOUT, b.PUNCHOUT, isAsc);
                case 'BREAKCOUNT':
                    return this.compare(a.BREAKCOUNT, b.BREAKCOUNT, isAsc);
                case 'BREAKHOURS':
                    return this.compare(a.BREAKHOURS, b.BREAKHOURS, isAsc);
                case 'REGHOURS':
                    return this.compare(a.REGHOURS, b.REGHOURS, isAsc);
                case 'QUANVOID':
                    return this.compare(a.QUANVOID, b.QUANVOID, isAsc);
                case 'BREAKSCANCELLED':
                    return this.compare(a.BREAKSCANCELLED, b.BREAKSCANCELLED, isAsc);
                case 'PUNCHINDEX':
                    return this.compare(a.PUNCHINDEX, b.PUNCHINDEX, isAsc);
                case 'TOTALHOURS2':
                    return this.compare(a.TOTALHOURS, b.TOTALHOURS, isAsc);
                case 'OTHOURS':
                    return this.compare(a.OTHOURS, b.OTHOURS, isAsc);
                case 'PAIDHOURS':
                    return this.compare(a.PAIDHOURS, b.PAIDHOURS, isAsc);
                case 'NUMNOSALE':
                    return this.compare(a.NUMNOSALE, b.NUMNOSALE, isAsc);
                case 'JOBPOSITION2':
                    return this.compare(a.JOBPOSITION, b.JOBPOSITION, isAsc);
                default:
                    return 0;
            }
        });

        this.browserStorage.setSessionstorage(this.WIDGET_CURRENT_SORT_KEY, JSON.stringify(sort));
    }

    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
