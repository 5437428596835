import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { AuthService } from '../../../auth/services/auth.resource';
import { ValidationService } from '../../../utils/shared-services/validation.service';
import { FormValidatorService } from '../../../utils/shared-services/form-validation.service';
import { UserAccessHandler } from '../services/user-access-handler.service';
import { UserInfoHandler } from '../services/user-info-handler.service';

@Component({
    selector: 'changePassword',
    templateUrl: 'src/app/features/users/templates/change-password.html',
})
export class PasswordChangeComponent {
    public captions;
    public userMustResetPass;
    public form: UntypedFormGroup;
    public hidePassword: boolean = true;

    constructor(
        @Inject(AuthService) private authService: AuthService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(UserInfoHandler) private userInfoHandler,
        @Inject(UserAccessHandler) private userAccessHandler,
        @Inject(FormValidatorService) private formValidationService: FormValidatorService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.userMustResetPass = userInfoHandler.getUserInfo().resetPassOnLogin;
        this.form = new UntypedFormGroup({
            oldPassword: new UntypedFormControl('', [Validators.required]),
            newPassword: new UntypedFormControl('', [
                Validators.required,
                Validators.pattern(this.validationService.getPasswordRegex()),
                Validators.maxLength(this.validationService.maxPasswordLength),
                Validators.minLength(this.validationService.minPasswordLength)
            ]),
            confirmNewPassword: new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(this.validationService.maxPasswordLength),
                Validators.minLength(this.validationService.minPasswordLength)]),
        }, {
            validators: [this.formValidationService.matchPassword('newPassword', 'confirmNewPassword') as any],
        });
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
    }

    saveChanges() {
        if (this.form.valid) {
            this.authService
                .changePassword(this.form.value.oldPassword, this.form.value.newPassword)
                .then(() => {
                    if (this.userMustResetPass) {
                        this.exitToLogin();
                    } 
                    else {
                        this.router.navigate(['users/myProfile']).then(() => {
                            this.snackbarService.successMessageBottom('Successfully changed password');
                        });
                    }
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
    }

    exitToLogin() {
        if (this.userMustResetPass) {
            this.userInfoHandler.clearUser();
            this.userAccessHandler.clearUser();
            this.router.navigate(['login']);
        } 
        else {
            this.router.navigate(['users/myProfile']);
        }
    }

    togglePasswordVisibility(): void {
        this.hidePassword = !this.hidePassword;
    }

    onNewPasswordChange() {
        this.form.controls.newPassword.markAsTouched();
    }

    onConfirmPasswordChange() {
        this.form.controls.confirmNewPassword.markAsTouched();
    }
}