import { Component, Inject, Injectable, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ValidationService } from '../../../../utils/shared-services/validation.service';
import { UserAccessHandler } from '../../../users/services/user-access-handler.service';
import { ReportDataWizard } from '../services/report-data-wizard.service';
import { SharedWizardData } from '../services/report-shared-wizard-data.service';

@Component({
    selector: 'reportSchedule',
    templateUrl: 'src/app/features/reports/wizard/templates/report-wizard-schedule.html',
})

@Injectable()
export class ReportScheduleComponent implements OnInit {
    [x: string]: any;
    public captions: any;
    public option: any;
    public days: { id: string; name: any; }[];
    public ofEvery: { id: string; name: string; }[];
    public showDayError: boolean;
    public maxJobs: any;
    public choiceTimes: any[];
    public timezones: { id: any; }[] | { id: string; name: any; }[];
    public list: any[];
    public filteredResults;
    public timeInput: NgControl;

    public conceptID: any;
    public conceptName: any;

    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    constructor(
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData,
        @Inject(ReportDataWizard) private reportDataWizard: ReportDataWizard,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.conceptID = this.sharedWizardData.conceptID;
        this.conceptName = this.sharedWizardData.conceptName;
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.showDayError = false;
        let currentUser = this.userAccessHandler.getUserAccess();
        this.maxJobs = 7;
        if (currentUser.limitation.maxJobs && currentUser.limitation.maxJobs <= 7) {
            this.maxJobs = currentUser.limitation.maxJobs;
        }

        /* 
        Get Times - ADD CUSTOM TIME RANGE HERE  
        INVALID ARRAY LENGTH > eachHalfHour('20:00', '23:30', true); 
        FAILING DUE TO EXPANSION AND CONTRACTION ON ... JHINT ISSUE
        */
        // /*
        // Spreads the time and splits each element in time while converting hours and minutes into seperate floats.
        // Calculates amount of Intervals from 0:00 for each time.
        // */
        // let toInt = time => ((h, m) => h * 2 + m / 30)(...time.split(':').map(parseFloat)),
        // // Converts the Interval amount to (24 Hour Time).
        // to24Time = int => [Math.floor(int / 2), int % 2 ? '30' : '00'].join(':'),
        // // Converts the Interval amount to (12 Hour Time).
        // to12Time = int => {
        //     let hour = Math.floor(int / 2);
        //     return [
        //         hour >= 13 ? hour - 12 : hour == 0 ? 12 : hour,
        //         (int % 2 ? '30' : '00') + (hour >= 12 ? ' PM' : ' AM')
        //     ].join(':');
        // },
        // // Calculates the interval amount between from and to range.
        // range = (from, to) =>
        //     Array(to - from + 1)
        //         .fill()
        //         .map((_, i) => from + i),
        // // Gets an array of hours and minutes between two dates in intervals of 30 minutes
        // eachHalfHour = (t1, t2, meridiem) => range(...[t1, t2].map(toInt)).map(meridiem ? to12Time : to24Time);

        this.days = [
            { id: '1', name: this.captions.monday },
            { id: '2', name: this.captions.tuesday },
            { id: '3', name: this.captions.wednesday },
            { id: '4', name: this.captions.thursday },
            { id: '5', name: this.captions.friday },
            { id: '6', name: this.captions.saturday },
            { id: '7', name: this.captions.sunday },
        ];
        this.ofEvery = [
            { id: '1', name: this.captions.ofEvery + ' ' + this.captions.monthFront.toLowerCase() },
            // { id: '2', name: $scope.this.captions.ofEvery + ' 2 ' + $scope.this.captions.monthsFront.toLowerCase() },
            // { id: '3', name: $scope.this.captions.ofEvery + ' 3 ' + $scope.this.captions.monthsFront.toLowerCase() },
            // { id: '4', name: $scope.this.captions.ofEvery + ' 4 ' + $scope.this.captions.monthsFront.toLowerCase() }
        ];
        this.choiceTimes = [
            '1:00 AM',
            '2:00 AM',
            '3:00 AM',
            '4:00 AM',
            '5:00 AM',
            '6:00 AM',
            '7:00 AM',
            '8:00 AM',
            '9:00 AM',
            '10:00 AM',
            '11:00 AM',
            '12:00 PM',
            '1:00 PM',
            '2:00 PM',
            '3:00 PM',
            '4:00 PM',
            '5:00 PM',
            '6:00 PM',
            '7:00 PM',
            '8:00 PM',
            '9:00 PM',
            '10:00 PM',
            '11:00 PM',
            '12:00 AM',
        ];
        this.filteredResults = [... this.choiceTimes];
        this.timezones = [
            { id: 'HAST', name: this.captions.tzHAST },
            { id: 'AKST', name: this.captions.tzAKST },
            { id: 'PST', name: this.captions.tzPST },
            { id: 'MST', name: this.captions.tzMST },
            { id: 'CST', name: this.captions.tzCST },
            { id: 'EST', name: this.captions.tzEST },
            { id: 'AST', name: this.captions.tzAST },
            { id: 'UTC', name: this.captions.tzUST },
            { id: 'UTC+2', name: this.captions.tzUTCp2 },
            { id: 'UTC+3', name: this.captions.tzUTCp3 },
            { id: 'UTC+4', name: this.captions.tzUTCp4 },
            { id: 'UTC+5', name: this.captions.tzUTCp5 },
            { id: 'UTC+6', name: this.captions.tzUTCp6 },
            { id: 'UTC+7', name: this.captions.tzUTCp7 },
        ];
        this.list = this.sharedWizardData.scheduleParams.list ? this.sharedWizardData.scheduleParams.list : [];
        this.option = this.sharedWizardData.scheduleParams.option
            ? this.sharedWizardData.scheduleParams.option
            : { firstOne: 'first', firstTwo: 'first', secondTwo: '1', third: '1' };
        this.form = this.formBuilder.group({
            repeats: [this.sharedWizardData.scheduleParams.repeats ? this.sharedWizardData.scheduleParams.repeats : 'monthly', [Validators.required]],
            firstOne: [this.option.firstOne],
            firstTwo: [this.option.firstTwo],
            secondTwo: [this.option.secondTwo],
            recurs: [this.sharedWizardData.scheduleParams.recurs ? this.sharedWizardData.scheduleParams.recurs : false],
            time: [this.sharedWizardData.scheduleParams.time ? this.sharedWizardData.scheduleParams.time : this.choiceTimes[9], [Validators.required, Validators.pattern(/^(((([0-1]?[0-9])|(2[0-3])):?[0-5][0-9])|(23:?59)|(((0?[1-9])|(1[0-2])):([0-5])([0-9])\s?(A|P|a|p)(M|m)))$/)]],
            timezone: [this.sharedWizardData.scheduleParams.timezone ? this.sharedWizardData.scheduleParams.timezone : this.timezones[7].id, [Validators.required]],
            reportsToHold: [this.sharedWizardData.scheduleParams.reportsToHold ? this.sharedWizardData.scheduleParams.reportsToHold : 1, [Validators.required, Validators.min(1), Validators.max(this.maxJobs)]],
            sendOut: [this.sharedWizardData.sendOut ? this.sharedWizardData.sendOut : null, [Validators.pattern(this.validationService.getEmailRegex()), Validators.max(255)]],
        });
        this.form.get("reportsToHold").valueChanges.subscribe(newVal => {
            this.form.patchValue({
                reportsToHold: newVal > this.maxJobs ? this.maxJobs : newVal < 1 ? 1 : newVal,
            }, {
                emitEvent: false,
                onlySelf: true
            });
        })
    }

    toggle(item: { id: any; }) {
        let idx = this.list.indexOf(item.id);
        if (idx > -1) this.list.splice(idx, 1);
        else this.list.push(item.id);
        this.showDayError = this.list.length === 0;
    }

    exists(item: { id: any; }) {
        return this.list.indexOf(item.id) > -1;
    }

    filterTimeResults(query: string) {
        let lowercaseQuery = query.toLowerCase();
        this.filteredResults = this.choiceTimes.filter((choice: string) => choice.toLowerCase().includes(lowercaseQuery));
    }

    submitScheduleParams() {
        this.showDayError = this.list.length === 0;
        if (this.form.valid && (!this.showDayError || this.form.value.repeats === 'monthly')) {
            // Repeat Option
            this.sharedWizardData.scheduleParams.repeats = this.form.value.repeats;
            // Weekly
            this.list.sort();
            this.sharedWizardData.scheduleParams.list = this.list;
            // Monthly
            this.sharedWizardData.scheduleParams.recurs = this.form.value.recurs;
            let option = { firstOne: this.form.value.firstOne, firstTwo: this.form.value.firstTwo, secondTwo: this.form.value.secondTwo, third: '1' };
            this.sharedWizardData.scheduleParams.option = option;
            // All
            this.sharedWizardData.scheduleParams.time = this.form.value.time;
            this.sharedWizardData.scheduleParams.timezone = this.form.value.timezone;
            this.sharedWizardData.scheduleParams.reportsToHold = this.form.value.reportsToHold;
            this.sharedWizardData.sendOut = this.form.value.sendOut;

            // this.reportDataWizard.moveToNextStep();
        }
    }

    cancelWizard() {
        // on cancel, reset the data
        this.sharedWizardData.resetScheduleWizardData();
        this.router.navigate(['reports/manageReports',
            this.sharedWizardData.conceptID,
            this.sharedWizardData.conceptName
        ]);
    }
}