import { Component, Inject, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'wid022',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-022.html',
})
export class Wid022 {
    public captions: any;
    public isTimedOut: boolean = false;
    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    public headers: string[];
    public labels: string[];
    public datasets: any[];

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.captions = this.captionService.captions;
        this.headers = [this.captions.payMethodFront, this.captions.amount];
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    ngOnInit() {
        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }
        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }

        let paymentData = this.widget.data[0].Record;
        if (!paymentData) {
            this.labels = [];
            this.datasets = [];
            return;
        }

        let labels = [],
            dataset = [];
        paymentData = Array.isArray(paymentData) ? paymentData : [paymentData];
        paymentData.forEach((item) => {
            if (item.PAYMETHOD1 === '[OTHER]') {
                item.PAYMETHOD1 = this.captions.other;
            }
            labels.push(item.PAYMETHOD1);
            dataset.push(item.PAYMENT.toFixed(2));
        });
        this.labels = labels;
        this.datasets = [{ data: dataset }];
    }
}
