import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { json2xml, xml2json } from 'xml-js';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { Builder } from 'xml2js';

@Injectable()
export class InventoryCounterService {
    private readonly COUNT_LOCATIONS_KEY: string = 'countLocations';
    private readonly COUNT_ITEMS_KEY: string = 'countItems';

    private readonly ACCESS_TOKEN: string = 'inventoryCounterToken';
    private readonly STORE_ID_KEY: string = 'storeID';

    private readonly WIZARD_STEP_KEY: string = 'currentCountingWizardStep';
    private readonly ACTIVE_LOCATION_KEY: string = 'activeCountLocation';

    // key to keep track of saved items in local storage
    private readonly BASE_INVENTORY_COUNTER_KEY: string = 'inventoryCounterLID';
    private readonly ALL_INVENTORY_KEYS: string = 'allInventoryCounterLIDs';
    private readonly ACTIVATED_COUNT_TYPE: string = 'activatedCountType';
    private readonly ACTIVATED_COUNT_DATE: string = 'activatedCountDate';
    private readonly COUNT_FREQ_TYPE: string = 'countFreqType';
    private readonly INV_MIN_DATE: string = 'invMinDate';
    private readonly INV_MAX_DATE: string = 'invMaxDate';

    private allLIDs: any = {};

    private countItems = new BehaviorSubject([]);
    private countLocations = new BehaviorSubject([]);
    private activeCountLocation = new BehaviorSubject('');

    public countItems$ = this.countItems.asObservable();
    public countLocations$ = this.countLocations.asObservable();
    public activeCountLocation$ = this.activeCountLocation.asObservable();

    public captions: Record<string,string>;

    constructor(
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router
    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        let locations = this.browserStorage.getLocalstorage(this.COUNT_LOCATIONS_KEY, null);
        let items = this.browserStorage.getLocalstorage(this.COUNT_ITEMS_KEY, null);
        let activeLocation = this.browserStorage.getLocalstorage(this.ACTIVE_LOCATION_KEY, null);

        if (locations && items) {
            this.countItems.next(items);
            this.countLocations.next(locations);
            if (this.getActiveCountType()) {
                this.loadCounts();
            }
        }

        if (activeLocation) {
            this.activeCountLocation.next(activeLocation);
        }
    }

    /**
    * @name getActiveCountDate
    * @description gets the currently selected count date from local storage
    * @returns date
    */
    getCountFreqType(): any {
        return this.browserStorage.getLocalstorage(this.COUNT_FREQ_TYPE, null);
    }


    /**
     * @name getActiveCountType
     * @description gets the currently selected count type from local storage
     * @return object
     */
    getActiveCountType(): any {
        return this.browserStorage.getLocalstorage(this.ACTIVATED_COUNT_TYPE, 0);
    }

    /**
     * @name setActiveCountType
     * @description sets the specified count type in local storage
     */
    setActiveCountType(type: any) {
        this.browserStorage.setLocalstorage(this.ACTIVATED_COUNT_TYPE, type);
    }

    /**
     * @name getActiveCountDate
     * @description gets the currently selected count date from local storage
     * @returns date
     */
    getActiveCountDate(): any {
        return this.browserStorage.getLocalstorage(this.ACTIVATED_COUNT_DATE, 0);
    }

    /**
     * @name setActiveCountDate
     * @description sets the specified count date in local storage
     * @param date date to save in LS
     */
    setActiveCountDate(date: Date) {
        this.browserStorage.setLocalstorage(this.ACTIVATED_COUNT_DATE, date);
    }

    /**
     * @name getCountLocationByID
     * @description gets the count location corresponding to the id passed as param
     * @returns count location
     */
    getCountLocationByID(locId: any) {
        return this.countLocations.getValue().find((loc) => loc.locId === locId);
    }

    /**
     * @name setActiveCountLocation
     * @description sets the specified count location in local storage
     */
    setActiveCountLocation(locId: any) {
        this.activeCountLocation.next(locId);
        this.browserStorage.setLocalstorage(this.ACTIVE_LOCATION_KEY, locId);
    }

    /**
     * @name getCurrentStep
     * @description gets the currently active inventory counter wizard step
     * @return active step as number
     */
    getCurrentStep() {
        return this.browserStorage.getLocalstorage(this.WIZARD_STEP_KEY, 1);
    }

    /**
     * @name setCurrentStep
     * @description sets the currently active inventory counter wizard step
     */
    setCurrentStep(step: number) {
        this.browserStorage.setLocalstorage(this.WIZARD_STEP_KEY, step);
    }

    /**
     * @name getAccessToken
     * @description gets the access token from local storage
     * @return string
     */
    getAccessToken() {
        return this.browserStorage.getLocalstorage(this.ACCESS_TOKEN, null);
    }

    /**
     * @name getSavedStoreID
     * @description gets the saved store ID from local storage
     * @return string
     */
    getSavedStoreID() {
        return this.browserStorage.getLocalstorage(this.STORE_ID_KEY, null);
    }

    /**
     * @name getBaseInventoryCounterKey
     * @description this method returns the base inventory counter key
     * @returns string
     */
    getBaseInventoryCounterKey(): string {
        return this.getActiveCountType().id + this.BASE_INVENTORY_COUNTER_KEY;
    }

    /**
     * @name getAllInventoryKeys
     * @description this method returns all the inventory keys
     * @returns string
     */
    getAllInventoryKeys(): string {
        return this.getActiveCountType().id + this.ALL_INVENTORY_KEYS;
    }

    /**
     * @name clearAccessToken
     * @description clears the local storage for the access token
     */
    clearAccessToken() {
        this.browserStorage.removeLocalstorageItem(this.ACCESS_TOKEN);
    }

    /**
     * @name clearInventoryStorage
     * @description clears the local storage for the LS related to inventory the current location
     */
    clearInventoryStorage() {
        let allIDs = this.browserStorage.getLocalstorage(this.getAllInventoryKeys(), null);
        if (allIDs) {
            let keys = Object.keys(allIDs);
            if (keys) {
                for (let key of keys) {
                    this.browserStorage.removeLocalstorageItem(key);
                }
            }
        }
        this.browserStorage.removeLocalstorageItem(this.getAllInventoryKeys());
        this.browserStorage.removeLocalstorageItem(this.ACTIVATED_COUNT_TYPE);
        this.browserStorage.removeLocalstorageItem(this.ACTIVATED_COUNT_DATE);
        this.browserStorage.removeLocalstorageItem(this.COUNT_ITEMS_KEY);
        this.browserStorage.removeLocalstorageItem(this.COUNT_LOCATIONS_KEY);
        this.browserStorage.removeLocalstorageItem(this.ACTIVE_LOCATION_KEY);
        this.browserStorage.removeLocalstorageItem(this.WIZARD_STEP_KEY);
        this.browserStorage.removeLocalstorageItem(this.COUNT_FREQ_TYPE);
    }

    /**
     * @name clearInventoryDatesStorage
     * @description clears min and max Dates related to Inv
     */

    clearInventoryDatesStorage() {
        this.browserStorage.removeLocalstorageItem(this.INV_MIN_DATE);
        this.browserStorage.removeLocalstorageItem(this.INV_MAX_DATE);
    }


    /**
     * @name getSavedItemDataFromLS
     * @description gets an item's saved count information from local storage
     * @return saved item in local storage
     */
    getSavedItemDataFromLS(item: any) {
        this.allLIDs = this.browserStorage.getLocalstorage(this.getAllInventoryKeys(), {});
        // check if this item can be restored from storage
        let itemFromLocalstorage = this.browserStorage.getLocalstorage(
            this.getBaseInventoryCounterKey() + item._attributes.LID,
            null
        );

        return itemFromLocalstorage;
    }

    /**
     * @name saveItemDataToLS
     * @description saves an item's count data to local storage
     */
    saveItemDataToLS(formValue: any) {
        let key = this.getBaseInventoryCounterKey() + formValue.LID;

        // check if this key has been saved to local storage yet, if not save the LID to
        // array of keys browser storage using this.ALL_INVENTORY_KEYS
        if (!this.allLIDs[key]) {
            this.allLIDs[key] = true;
            this.browserStorage.setLocalstorage(this.getAllInventoryKeys(), this.allLIDs);
        }

        let objectForLS = {
            formData: formValue,
        };

        // save to localStorage, using the base string and the unique LID
        this.browserStorage.setLocalstorage(this.getBaseInventoryCounterKey() + formValue.LID, objectForLS);
    }

    /**
     * @name parseXMLData
     * @description parses the received XML data to a valid list of count items and count locations,
     * emits values from their observables and saves the values to local storage
     */
    parseXMLData(xmlString: string) {
        let locations = [];

        let jsonData = JSON.parse(xml2json(xmlString, { compact: true, spaces: 2 }));
        let items = jsonData.Response.InvCountSheet.Item;
        items = items instanceof Array ? items : [items];   
        let countFreqType = jsonData.Response.InvCountSheet.InvCountFreqType._text;
        this.browserStorage.setLocalstorage(this.COUNT_FREQ_TYPE, countFreqType);

        items.forEach((item: any) => {
            // assign a new property 'counted' whose value is based on the original xml data
            let isCounted =
                item.PackCount.hasOwnProperty('_text') ||
                item.UnitCount.hasOwnProperty('_text') ||
                item.ContCount.hasOwnProperty('_text');
            item['counted'] = isCounted;
    
            // check if the item's location has been accounted for
            let idx = locations.findIndex((loc: any) => loc.locId === item.LocId._text);

            if (idx === -1) {
                // item's location has not been accounted for, create new location, and add item to list of counted if it is counted
                locations.push({
                    locId: item.LocId._text,
                    locDesc: item.LocDesc._text,
                    counted: isCounted ? [item._attributes.LID] : [],
                    total: 1,
                });
            } else {
                // item was accounted for, increment total number of items at this location
                if (isCounted) {
                    // item is also counted, add to list of counted items for location
                    locations[idx].counted.push(item._attributes.LID);
                }
                locations[idx].total++;
            }
        });

        // sort the data
        items.sort(this.compareItems);
        locations.sort(this.compareCountLocations);

        this.countItems.next(items);
        this.countLocations.next(locations);

        // save to LS so it does not need to be parsed again
        this.browserStorage.setLocalstorage(this.COUNT_ITEMS_KEY, items);
        this.browserStorage.setLocalstorage(this.COUNT_LOCATIONS_KEY, locations);
    }

    /**
     * @name compareCountLocations
     * @description custom comparison method that compares count locations for sorting ascending (case insensitive)
     * @param a first element to compare
     * @param b second element to compare
     * @returns integer
     */
    compareCountLocations(a, b) {
        let aLC: string = a.locDesc.toLowerCase();
        let bLC: string = b.locDesc.toLowerCase();
        return aLC < bLC ? -1 : aLC > bLC ? 1 : 0;
    }

    /**
     * @name compareItems
     * @description custom comparison method that compares items for sorting ascending (case insensitive)
     * @param a first element to compare
     * @param b second element to compare
     * @returns integer
     */
    compareItems(a, b) {
        let aLC: string = a.InvDesc._text.toLowerCase();
        let bLC: string = b.InvDesc._text.toLowerCase();
        return aLC < bLC ? -1 : aLC > bLC ? 1 : 0;
    }

    /**
     * @name loadCounts
     * @description gets the current selected count type from local storage
     */
    loadCounts() {
        let allIDs = this.browserStorage.getLocalstorage(this.getAllInventoryKeys(), null);
        if (allIDs) {
            let keys = Object.keys(allIDs);
            if (keys) {
                for (let key of keys) {
                    let item = this.browserStorage.getLocalstorage(key, null);
                    if (item) {
                        this.itemCountUpdated(item.formData);
                    }
                }
            }
        }
    }

    /**
     * @name itemCountUpdated
     * @description updates count progress of locations and item counts to new updated item,
     * calling this function with an updated item will emit new values from count locations and items.
     */
    itemCountUpdated(item: any) {
        let updatedLocations = this.countLocations.getValue().map((loc) => {
            if (loc.locId === item.locId) {
                let idx = loc.counted.indexOf(item.LID);
                if (item.counted) {
                    // item is counted -> add to list of counted items for location (if not added already)
                    if (idx === -1) {
                        loc.counted.push(item.LID);
                    }
                } else {
                    // not counted -> remove from counted items for location
                    if (idx > -1) {
                        loc.counted.splice(idx, 1);
                    }
                }
            }
            return loc;
        });

        this.countItems.getValue().map((countItem) => {
            // update items list with values of new item
            if (countItem._attributes.LID === item.LID) {
                countItem.counted = item.counted;
                countItem.ContCount._text = item.contCount;
                countItem.PackCount._text = item.packCount;
                countItem.UnitCount._text = item.unitCount;
            }
        });

        // emit new data
        this.countLocations.next(updatedLocations);
    }

    /**
     * @name getItemsAsXML
     * @description uses json2xml to convert count items from JSON to XML String
     * @returns string
     */
    getItemsAsXML(): String {
        let items = this.countItems.getValue();
        items.map((item) => {
            if (item.ContCount?._text === null) {
                item.ContCount = {};
            }
            if (item.UnitCount?._text === null) {
                item.UnitCount = {};
            }
            if (item.PackCount?._text === null) {
                item.PackCount = {};
            }
            delete item.counted;
            return item;
        });

        let countsheet = {
            Item: items,
        };
        let countString = JSON.stringify(countsheet)

        let xmlString = json2xml(countString, { compact: true });
        return xmlString;
        //** leaving in temp **/
        // convert json back to xml without prolog
        // var xmlBuilder = new Builder();
        // let xmlData = xmlBuilder.buildObject(countsheet);
        // var itemXML = this.parseXml(xmlData);
        // let xmlString = new XMLSerializer().serializeToString(itemXML.childNodes[0]);

    }

    /**
     * @name logout
     * @description this method logs the user out and navigates them to the login page of the
     * inventory counter, and clears all saved data.
     */
    logout() {
        let savedStoreID = this.getSavedStoreID();
        this.router.navigate(['/inventoryCounter'], {
            queryParams: {
                ...(savedStoreID ? { StoreID: savedStoreID } : {}),
            },
        });
        this.clearAccessToken();
        this.clearInventoryStorage();
        this.clearInventoryDatesStorage();
    }

    // Cross-browser function to parse an XML string
    parseXml(xmlString) {
        var doc;
        var parser = new DOMParser();
        doc = parser.parseFromString(xmlString, 'text/xml');
        return doc;
    }
}
