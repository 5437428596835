import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppConstants } from '../../../app.constants';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { lastValueFrom } from 'rxjs';
import { HeadOfficePortalData } from '../models/head-office-portal.model';
import { UserAccess } from '../../users/models/user-access.model';

@Injectable()
export class HOPortalApiService {
    public captions: any;
    private readonly BASE_URL: string;
    private readonly portalUrl: string = 'hoPortal';
    private readonly loginParam: string = '/login';
    private readonly hqLoginParam: string = '/hqlogin';
    private readonly validateParam: string = '/validate';
    private readonly configParam: string = '/config';
    private readonly dataParam: string = '/data';
    private readonly descriptionParam: string = '/description';
    private readonly PORTAL_DATA_KEY: string = 'portalData';
    private readonly gatwayOrder: string = "/gatewayOrder"

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
    }

    /**
 * @name loginForHOPortal
 * @description this method authenticates the user to access remote ho portal
 * @param storeID this is a unique string identifier for ho portal (a.k.a alias) [required]
 * @param user username [optional]
 * @param password password for login [required]
 * @returns access token and store information
 */
    async loginForHoPortal(alias, userID, password, userAccess) {
        let url = this.BASE_URL + this.portalUrl + this.loginParam;
        const data$ = this.http.post(
            url,
            JSON.stringify({
                storeSID: alias,
                user: userID ?? '',
                empID: password,
                userAccess: userAccess ? userAccess : {}, // for switching between stores
            })
        );
        const value = await lastValueFrom(data$);
        return value;
    }

        /**
         * @name hqLoginForHOPortal
         * @description this method authenticates the user to access remote ho portal. This method
         * requires a valid HQ token.
         * @param storeID this is a unique string identifier for ho portal (a.k.a alias) [required]
         * @param user username [optional]
         * @param password password for login [required]
         * @returns access token and store information
         */
        async hqLoginForHOPortal(storeID: string, userID: string, password: string, userAccess: UserAccess): Promise<HeadOfficePortalData> {
            let url = this.BASE_URL + this.portalUrl + this.hqLoginParam;
            const data$ = this.http.post<HeadOfficePortalData>(
                url,
                JSON.stringify({
                    storeSID: storeID,
                    user: userID ?? '',
                    empID: password,
                    userAccess: userAccess,
                })
            );
            const value = await lastValueFrom(data$);
            return value;
        }
}