import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppSettings } from '../../app-settings.service';
import { UserInfoHandler } from '../../features/users/services/user-info-handler.service';
import { CaptionService } from '../../utils/shared-services/caption.service';
import { SnackbarService } from '../../utils/shared-services/snackbar/snackbar.service';
import { UtilsApiService } from '../../utils/shared-services/utils.resource';
import { AuthService } from '../services/auth.resource';
import { ValidationService } from '../../utils/shared-services/validation.service';

@Component({
    selector: 'enterCode',
    templateUrl: 'src/app/auth/templates/enter-code.html',
})
export class EnterCodeComponent {
    public captions;

    public credentials: { email: any; password: string };
    public form;

    constructor(
        @Inject(CaptionService) private captionService,
        @Inject(AuthService) private authService,
        @Inject(UserInfoHandler) private userInfoHandler,
        @Inject(UtilsApiService) private utilsApiService,
        @Inject(AppSettings) private appSettings,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(ValidationService) public validationService: ValidationService,
    ) { }

    ngOnInit() {
        this.actRoute.params.subscribe((params: Params) => {
            this.credentials = {
                email: params['email'],
                password: '',
            }
        });
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.form = new UntypedFormGroup({
            code: new UntypedFormControl('', [Validators.required, Validators.maxLength(this.validationService.passwordRecoveryCodeLength), Validators.minLength(this.validationService.passwordRecoveryCodeLength)]),
        });
    }

    loginWithCode() {
        this.credentials.password = this.form.value.code;
        this.authService.login(this.credentials).then(
            (login) => {
                if (typeof login.token !== 'undefined') {
                    this.userInfoHandler.setToken(login.token);
                    this.utilsApiService.getUserInfo().then((userInfo) => {
                        this.userInfoHandler.setEmail(userInfo.email);
                        if (this.appSettings.getLanguage() !== userInfo.language) {
                            this.appSettings.setLanguage(userInfo.language);
                            this.captionService.assignCaptions(userInfo.language, 'FRONT');
                        }
                        this.userInfoHandler.setLanguage(userInfo.language);
                    });
                    this.router.navigate(['login/newPassword']);
                }
            },
            (reason) => {
                this.snackbarService.errorMessageTop(this.captions.invalidCode);
            }
        );
    }

    navigate(state) {
        this.router.navigate([state]);
    }
}