import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpCacheService } from '../../../app/auth/services/cache.service';
import { UserInfoHandler } from '../../features/users/services/user-info-handler.service';
import { CaptionService } from '../../utils/shared-services/caption.service';
import { SnackbarService } from '../../utils/shared-services/snackbar/snackbar.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    token: any;

    // routes added to this list will not have their requests trigger a snackbar
    public readonly ROUTE_EXCEPTIONS = ['enterCode']

    constructor(
        @Inject(HttpCacheService) private cacheService: HttpCacheService,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(Router) private router: Router,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(CaptionService) private captions: CaptionService,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let cachedResponse;
        if (req.method === 'GET') {
            cachedResponse = this.cacheService.get(req);
        }
        else if (req.method === 'POST' || req.method === 'PUT' || req.method === 'DELETE') {
            this.cacheService.delete(req);
        }

        if (this.userInfoHandler.getUserInfo() == null) {
            this.token = '';
        }
        else {
            this.token = this.userInfoHandler.getUserInfo().token;
        }

        const authReq = req.clone({
            headers: req.headers.set('X-Auth-Token', this.token),
        });

        return next.handle(authReq).pipe(
            tap((evt) => {
                if (evt instanceof HttpResponse) {
                    this.cacheService.put(authReq, evt);
                }
                return cachedResponse ? cachedResponse : evt;
            }),
            catchError((response: HttpErrorResponse) => {
                if (response.url.includes('hq-crystal-reports')) throw response; 
                if (this.ROUTE_EXCEPTIONS.some(route => this.router.url.includes(route))) {
                    throw response;
                }
                if (response.status === 401 || response.status === 403) {
                    this.triggerSnackBar(response);
                    this.userInfoHandler.clearUser();
                    this.router.navigate(['login']);
                }
                else {
                    this.triggerSnackBar(response);
                }
                throw response;
            })
        );
    }

    triggerSnackBar(response) {
        // see if there is an error response
        let res = response.error?.reason ?? response.error;
        
        // check if error response is in captions, if not pass through the original value from backend
        res = this.captions.captions[res] ?? res;
        this.snackbarService.errorMessageTop(res);
    }
}
