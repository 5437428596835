import { Inject, Injectable } from '@angular/core';
import { StoreGroupMembersApiService } from '../../../store-groups/services/store-group-member.resource';
import { TagApiService } from '../../../tags/services/tag.resource';

@Injectable({
    providedIn: 'root',
})
export class ReportHelperMethodsService {
    constructor(
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(TagApiService) private tagApiService: TagApiService
    ) {}

    /**
     * @name pushAndExecuteGroupItemPromises
     * @description pushAndExecuteGroupItemPromises receives an array of groups and returns corresponding stores to that group
     * @param groupItems array of GroupItems
     * @returns Promise<store[]>
     */
    pushAndExecuteGroupItemPromises(groupItems: any[]) {
        let selectedGroupItemStores = [],
            groupItemPromises = [];
        groupItems.forEach((groupItem: { id: any }) => {
            if (typeof groupItem?.id != 'undefined') {
                groupItemPromises.push(this.storeGroupMembersApiService.getGroupItemInfoById(groupItem.id));
            }
        });
        return Promise.all(groupItemPromises).then((promiseObject) => {
            promiseObject.forEach((stores) => {
                selectedGroupItemStores.push(...stores);
            });
            return selectedGroupItemStores;
        });
    }

    /**
     * @name pushAndExecuteTagPromises
     * @description pushAndExecuteTagPromises receives an array of tags and returns corresponding stores to that tag.
     * @param tags array of tags
     * @returns Promise<store[]>
     */
    pushAndExecuteTagPromises(tags: any[]) {
        let tagPromises = [],
            selectedTagStores = [];
        tags.forEach((tag: { id: any }) => {
            tagPromises.push(this.tagApiService.getTagInfoById(tag.id));
        });
        return Promise.all(tagPromises).then((promiseObject) => {
            promiseObject.forEach((stores) => {
                selectedTagStores.push(...stores);
            });
            return selectedTagStores;
        });
    }
}
