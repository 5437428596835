import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { ItemStorageService } from '../../../../utils/shared-services/item-storage.service';
import { DashboardTabGetWithContent } from "../../models/dashboard.model";
import { DashboardApiService } from "../../services/dashboard.resource";
import { ValidationService } from "../../../../utils/shared-services/validation.service";

@Component({
    selector: 'editTab',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/edit-tab.tpl.html',
})
export class EditTabDialog {
    public captions: Record<string, string>;
    public tabToUpdate: DashboardTabGetWithContent = {
        tabName: '',
        id: -1
    }

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EditTabDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.captionService.captions;
        this.tabToUpdate = {
            tabName: this.itemStorageService.currentTab.tabName,
            id: this.itemStorageService.currentTab.id
        };
    }

    submitTab(): void {
        this.dashboardApiService.updateTab(this.tabToUpdate).then(() => {
            this.itemStorageService.currentTab.tabName = this.tabToUpdate.tabName;
        }); 
        this.cancel();
    }

    Space(event: KeyboardEvent): void {
        if ((event.target as HTMLInputElement).selectionStart === 0 && event.key === ' ') {
            event.preventDefault();
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
