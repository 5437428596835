import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import * as txml from 'txml';
import * as wijmoChart from 'wijmo/wijmo.chart';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { ConceptApiService } from '../../concepts/services/concept.resource';
import { StoreApiService } from '../../stores/services/store.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserInfoHandler } from '../../users/services/user-info-handler.service';
import { ApiPackageService } from '../../vendor-apis/services/api-package.resource';
import { Vendor, VendorApiService } from '../../vendor-apis/services/vendor.resource';
import { EoiApiService } from '../services/eoi.resource';
import { eoiService as EoiService } from '../services/eoi.service';
import { EoiConfigDialog } from './dialogs/eoi-config-dialog.component';
import { EoiFailDetailsDialog } from './dialogs/eoi-fail-details-dialog.component';
import { EoiStatusDialog } from './dialogs/eoi-status-dialog.component';
import { EoiSubscriptionDialog } from './dialogs/eoi-subscription-dialog.component';
import { EoiVendorDialog } from './dialogs/eoi-vendor-dialog.component';
import { UserConstants } from '../../users/users.constants';
import { EoiCustomDateDialog } from './dialogs/eoi-custom-date-dialog.component';
import { MatTooltip } from '@angular/material/tooltip';
import { UserAccess } from '../../users/models/user-access.model';
import { Subscription, OrderSumApiResponse } from '../models/eoi.model';
import { Store } from '../../stores/models/store.model';

@Component({
    selector: 'eoiDetail',
    templateUrl: 'src/app/features/eoi/templates/eoi-detail.html',
})
export class EoiDetailComponent implements OnInit {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('customDateTooltip') customDateTooltip: MatTooltip;

    private USER_ACCESS = UserConstants.USER_ACCESS;

    private userAccessInfo: UserAccess;
    public captions: Record<string, string>;
    public storeID: number;
    public timeRange: number;
    public virtualVendor: number;
    public reqType: number;
    public vendorID: number;
    public apiPackages: {
        id: number;
        displayName: string;
        description: string;
        controlPoint: string;
    }[];
    public connectedtime: string;
    public disconnectedtime: string;
    public response: any;
    public subscription: Subscription;
    public subscribed: boolean;
    public showEmailFields: boolean;
    public tempSub: Subscription;
    public previewMessage: string;
    public eoiOnline: string;
    public posOnline: string;
    public okToOrder: string;
    public openDateValid: string;
    public connectedString: string;
    public vendors: Vendor[][]
    public EOIArraySelect: any;
    public duration: number;
    public orderTotalAmount: any;
    public orderLost: any;
    public successTotal: number;
    public failTotal: number;
    public total: number;
    public eoiSummary: {
        requestType: string;
        success: string;
        failure: string;
        total: number;
    }[];
    public storeLocalTime: string;
    public EOIArray: any;
    public storeName: string;
    public data: any;
    public widgetChart: any;
    public tempConfig: {
        utcOrLocal: string | number,
        queueOrTransferID: string | number,
    };
    public idType: number;
    public timeZone: number | string;
    public utcOrLocal: any;
    public selectedDate: any;
    public store: Store;
    public localTimeZone: any;
    public failDetailsForSelectedEOI: any;
    public orderProperty: string;
    public failedEOIClicked: any;
    public vendorClicked: boolean = false;
    public customDateSelected: boolean = false;
    public dataSource: any;
    public displayedColumns: string[] = [];
    private eoiRefreshInterval: ReturnType<typeof setInterval>;
    private eoiRefreshIntervalDuration: number = 30000;
    private eoiRefreshIntervalLimit: number = 7200;
    public refreshing: boolean = false;
    public defaultCustomDate: string = "1899-01-01";
    private autoHideTimeout: any;
    public statusStyle: String = 'color-grey';
    public currentUser;

    constructor(
        @Inject(EoiApiService) private eoiApiService: EoiApiService,
        @Inject(VendorApiService) private vendorApiService: VendorApiService,
        @Inject(ApiPackageService) private apiPackageService: ApiPackageService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(EoiService) private eoiService: EoiService
    ) {
        this.storeApiService = storeApiService;
        this.conceptApiService = conceptApiService;
        this.tempConfig = {
            utcOrLocal: '',
            queueOrTransferID: '',
        };
        this.orderProperty = '-whenSubmitted';
        this.userAccessInfo = this.userAccessHandler.getUserAccess();
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUser = userAccessHandler.getUserAccess();
    }

    ngOnInit() {
        // remove incase saved from last option picked
        this.browserStorage.removeSessionstorageItem('selectedDate');
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.storeID = +params['storeID'];
            this.storeName = params['displayName'];
        });
        this.timeRange = this.getTimeRange();
        this.virtualVendor = 15000000;
        this.reqType = 9;
        this.vendorID = 1;
        this.previewMessage = this.captions.subscribe;
        this.getLocalTimeZone().then(() => {
            this.populateTable();
        });
        this.getInitialSubscription();
        this.getActiveVendorsToSelect();
        this.watchEoiStatus(this.eoiRefreshIntervalDuration, this.eoiRefreshIntervalLimit);
    }

    watchEoiStatus(duration: number, limit: number): void {
        clearInterval(this.eoiRefreshInterval);
        this.eoiRefreshInterval = setInterval(() => {
            this.getQuickStatus();
            this.updateTimeRange(this.storeID, this.timeRange, this.reqType, this.vendorID);
        }, duration * 1000);
        setTimeout(() => {
            clearInterval(this.eoiRefreshInterval);
        }, (limit + 1) * 1000);
    }

    refreshEoiData(): void {
        this.refreshing = true;
        setTimeout(() => {
            this.refreshing = false;
        }, 0);
        this.getQuickStatus();
        this.updateTimeRange(this.storeID, this.timeRange, this.reqType, this.vendorID);
        this.watchEoiStatus(this.eoiRefreshIntervalDuration, this.eoiRefreshIntervalLimit);
    }

    ngOnDestroy() {
        clearInterval(this.eoiRefreshInterval);
    }

    populateTable(): void {
        this.displayedColumns = [];
        this.displayedColumns.push('statusOrderType');
        this.displayedColumns.push(Number(this.getIdType()) === 0 ? 'id' : 'referenceId');
        this.displayedColumns.push('requestType');
        this.displayedColumns.push(Number(this.store.utcOrLocal) === 0 ? 'whenSubmitted' : 'localTime');
        this.dataSource = new MatTableDataSource<any>(this.response.eoiDetailsList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    getInitialTimeRange(storeID, timeRange, reqType, vendorID): Promise<void> {
        return new Promise<void>((resolve) => {
            this.eoiApiService.getEoiByID(storeID, timeRange, reqType, vendorID, this.getCustomDate()).then((response) => {
                this.response = response;
                this.setLocalTimeAndTranferId();
                this.setIdType(0);
                if (this.tempConfig.utcOrLocal) {
                    this.setTimeZone(1);
                } else {
                    this.setTimeZone(0);
                }

                if (this.response.isConnected == true) {
                    this.connectedString = 'Connected';
                } else {
                    this.connectedString = 'Disconnected';
                }

                let widgetDefinition = JSON.parse(this.response.queryResults);
                for (let i = 0; i < widgetDefinition.length; i++) {
                    widgetDefinition[i].Time =
                        widgetDefinition[i].TimeRangeLocal && this.store.utcOrLocal == 1
                            ? widgetDefinition[i].TimeRangeLocal
                            : widgetDefinition[i].TimeRange;
                }

                this.widgetChart = new wijmoChart.FlexChart('#theChart', {
                    itemsSource: widgetDefinition,
                    bindingX: 'Time',
                    chartType: wijmoChart.ChartType.Column,
                    stacking: wijmoChart.Stacking.Stacked,
                    palette: ['green', 'yellow', 'orange', 'red', 'grey', 'purple'],
                    series: [
                        { name: this.captions.success, binding: this.captions.success },
                        { name: this.captions.posFailed, binding: this.captions.posFailed },
                        { name: this.captions.failure, binding: this.captions.failure },
                        { name: this.captions.lostOrder, binding: this.captions.lostOrder },
                        { name: this.captions.inProgress, binding: this.captions.inProgress },
                        { name: this.captions.storeDisconnected, binding: this.captions.storeDisconnected },
                    ],
                });

                this.widgetChart.axisX.labelAngle = -45;

                this.orderSummary(this.response);
                this.setOrderStatusType();
                this.getQuickStatus();
                this.updateTheme();
                this.setTimeRange(timeRange);
                resolve(null);
            });
        });
    }

    getInitialSubscription(): void {
        this.eoiApiService.getSubscriptionByID(this.storeID).then((response: Subscription) => {
            this.subscription = response;
            this.checkSubscription();
        });
    }

    setOrderStatusType(): void {
        for (let i = 0; i < this.response.eoiDetailsList.length; i++) {
            if (this.response.eoiDetailsList[i].status == 'STORE_PROCESSED' && this.response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS') {
                this.response.eoiDetailsList[i].statusOrderType = 1;
            } else {
                this.response.eoiDetailsList[i].statusOrderType = 0;
            }
        }
    }

    getLocalTimeZone(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.storeApiService
                .getStoreById(this.storeID)
                .then((store: Store) => {
                    this.store = store;
                    if (store.localTimeZone) {
                        this.localTimeZone = store.localTimeZone;
                    } else {
                        this.conceptApiService
                            .getConceptById(store.conceptID)
                            .then((concept: any) => {
                                this.localTimeZone = concept.localTimeZone;
                            })
                            .catch((exception) => reject(exception));
                    }

                    this.getInitialTimeRange(this.storeID, this.timeRange, this.reqType, this.vendorID)
                        .then(() => {
                            resolve(null);
                        })
                        .catch((exception) => reject(exception));
                })
                .catch((exception) => reject(exception));
        });
    }

    getTimeZone(): number | string {
        let timeZone = this.browserStorage.getSessionstorage('timeZone', undefined);
        return timeZone == null ? 0 : timeZone;
    }

    setTimeRange(timeRange): void {
        this.timeRange = timeRange;
        this.browserStorage.setSessionstorage('timeRange', timeRange);
    }

    getTimeRange(): number {
        let timeRange = this.browserStorage.getSessionstorage('timeRange', undefined);
        return timeRange == null || timeRange == 8 ? 7 : Number(timeRange);
    }

    handleTimeRangeChange(storeID, timeRange, reqType, vendorID): void {
        if (timeRange !== 8) {
            this.removeCustomDate();
            this.updateTimeRange(storeID, timeRange, reqType, vendorID);
        }
    }

    getDateSelectedText(): string {
        return this.getCustomDate() !== this.defaultCustomDate ? new Date(this.getCustomDate()).toISOString().split('T')[0] : this.captions.pick_a_date;
    }

    toggleCustomDateToolTip(): void {
        this.customDateTooltip.toggle();
        if (this.autoHideTimeout) {
            clearTimeout(this.autoHideTimeout);
        }

        if (!this.customDateTooltip._isTooltipVisible()) {
            this.autoHideTimeout = setTimeout(() => {
                this.customDateTooltip.hide();
            }, 3000); // close tooltip after 3 seconds (3000 ms)
        }
    }

    hoverTooltipAndClearTimeOut(): void {
        this.customDateTooltip.show();
        if (this.autoHideTimeout) {
            clearTimeout(this.autoHideTimeout);
        }
    }

    updateTimeRange(storeID: number, timeRange: number, reqType: number, vendorID: number): void {
        this.eoiApiService.getEoiByID(storeID, timeRange, reqType, vendorID, this.getCustomDate()).then((response) => {
            this.response = response;
            let widgetDefinition = JSON.parse(this.response.queryResults);
            this.widgetChart.itemsSource = widgetDefinition;
            for (let i = 0; i < widgetDefinition.length; i++) {
                widgetDefinition[i].Time =
                    widgetDefinition[i].TimeRangeLocal && this.store.utcOrLocal == 1
                        ? widgetDefinition[i].TimeRangeLocal
                        : widgetDefinition[i].TimeRange;
            }
            this.widgetChart.refresh();
            this.setLocalTimeAndTranferId();
            this.orderSummary(this.response);
            this.setOrderStatusType();
            this.setTimeRange(timeRange);
            this.checkSubscription();
            this.populateTable();
        });
    }

    setLocalTimeAndTranferId(): void {
        this.tempConfig.utcOrLocal = this.store.utcOrLocal;
        this.tempConfig.queueOrTransferID = this.getIdType();
        for (let i = 0; i < this.response.eoiDetailsList.length; i++) {
            this.response.eoiDetailsList[i].localTime = this.response.localTimeLists[i];
            if (!this.response.eoiDetailsList[i].referenceId) {
                this.response.eoiDetailsList[i].referenceId = 'N/A';
            }
        }
    }

    setIdType(idType): void {
        this.idType = idType;
        this.browserStorage.setSessionstorage('idType', idType);
    }

    getIdType(): number | string {
        let idType = this.browserStorage.getSessionstorage('idType', undefined);
        return idType === undefined ? 0 : idType;
    }

    setTimeZone(timeZone): void {
        this.timeZone = timeZone;
        this.store.utcOrLocal = timeZone;
    }

    getCustomDate(): string {
        this.selectedDate = this.browserStorage.getSessionstorage('selectedDate', this.defaultCustomDate);
        return this.selectedDate;
    }

    setCustomDate(selectedDate): void {
        this.customDateSelected = true;
        this.selectedDate = selectedDate;
        this.browserStorage.setSessionstorage('selectedDate', this.selectedDate);
        this.updateTimeRange(this.storeID, this.timeRange, this.reqType, this.vendorID);
    }

    removeCustomDate(): void {
        this.customDateSelected = false;
        this.selectedDate = null;
        this.browserStorage.removeSessionstorageItem('selectedDate');
    }

    failDetailsDialog(eoi) {
        this.eoiApiService.getOrderRequest(this.storeID, eoi.id).then((response) => {
            eoi.orderXML = response[0][0];
            eoi.responseXML = response[0][1];
            let eoiXML = new DOMParser().parseFromString(eoi.responseXML, 'text/xml');

            if (eoi.orderXML != null && eoi.responseXML == null) {
                if (eoi.status == 'VENDOR_SUBMITTED') {
                    errorMessage = this.captions.orderInProgress;
                } else {
                    errorMessage = this.captions.lostOrder;
                }
            } else if (eoi.responseXML.includes('<ErrorNum>-1</ErrorNum>')) {
                if (eoi.status == 'STORE_DISCONNECTED') {
                    errorMessage = eoiXML.getElementsByTagName('EOIMessage')[0].childNodes[0].nodeValue;
                } else {
                    errorMessage = eoiXML.getElementsByTagName('Message')[0].childNodes[0].nodeValue;
                }
            } else if (eoi.vendorRetrieved == null || eoi.vendorRetrieved == 'NOT_READY') {
                errorMessage = this.captions.vendorNotReady;
            }
        });
        let submittedDate;
        let errorMessage;
        this.failedEOIClicked = eoi;


        if (this.store.utcOrLocal == 1) {
            submittedDate = eoi.localTime;
        } else {
            submittedDate = eoi.whenSubmitted;
        }
        this.failDetailsForSelectedEOI = {
            message: errorMessage,
            whenSubmitted: submittedDate,
        };

        const dialogRef = this.dialog.open(EoiFailDetailsDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                failDetailsForSelectedEOI: this.failDetailsForSelectedEOI,
                userAccessInfo: this.userAccessInfo,
                failedEOIClicked: this.failedEOIClicked,
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    getEOIVendors() {
        let vendorArray: Promise<Vendor[]>[] = [];
        this.apiPackages.forEach((pack) => {
            if (this.storeID)
                vendorArray.push(this.vendorApiService.getVendorsByStoreIdPackageId(this.storeID, pack.id));
        });
        return Promise.all(vendorArray).then((vendors) => {
            return vendors;
        });
    }

    getActiveVendorsToSelect() {
        let EOIVendorArray = [];
        let apiType = this.storeID ? 'store' : 'concept';
        this.apiPackageService.getApiPackages(apiType).then((apis) => {
            this.apiPackages = apis;
            this.getEOIVendors().then((vendors) => {
                this.vendors = vendors;
                for (let i = 0; i < vendors.length; i++) {
                    for (let j = 0; j < vendors[i].length; j++) {
                        if (vendors[i][j].apiPackageID == 16000002 && vendors[i][j].isTokenActive == 1) {
                            EOIVendorArray.push(vendors[i][j]);
                        }
                    }
                }

                if (EOIVendorArray.length > 0) {
                    EOIVendorArray.push({ apiConsumerID: 1, apiConsumerDisplayName: 'All Vendors' });
                } else {
                    EOIVendorArray.push({ apiConsumerID: 0, apiConsumerDisplayName: 'No Vendors' });
                    this.vendorID = 0;
                }

                this.EOIArraySelect = EOIVendorArray;
            });
        });
    }

    // replaces getActiveVendors in HTML
    makeVirtualOrder() {
        this.vendorPopup();
    }

    // ***No Longer going to allow user to pick vendor ***

    // getActiveVendors() {
    //     this.vendorClicked = false;
    //     let EOIVendorArray = [];
    //     let apiType = this.storeID ? 'store' : 'concept';
    //     this.apiPackageService.getApiPackages(apiType).then((apis) => {
    //         this.apiPackages = apis;
    //         this.getEOIVendors().then((vendors) => {
    //             this.vendors = vendors;
    //             for (let i = 0; i < vendors.length; i++) {
    //                 for (let j = 0; j < vendors[i].length; j++) {
    //                     if (vendors[i][j].apiPackageID == 16000002 && vendors[i][j].isTokenActive == 1) {
    //                         EOIVendorArray.push(vendors[i][j]);
    //                     }
    //                 }
    //             }
    //             this.EOIArray = EOIVendorArray;
    //             this.vendorPopup();
    //         });
    //     });
    // }

    getQuickStatus(): void {
        this.connectedtime = this.response.connectedTime;
        this.disconnectedtime = this.response.disconnectedTime;
        this.eoiApiService.getQuickStatus(this.storeID, this.virtualVendor).then((response: any) => {
            const { Response } = txml.simplify(txml.parse(response.responseString));
            this.okToOrder = Response.Status.OkToOrder;
            this.openDateValid = Response.Status.OpenDateValid;
            this.posOnline = Response.Status.PosOnline;
            this.eoiOnline = Response.Status.EoiOnline;
            this.storeLocalTime = Response.Status.StoreLocalDateTime;
            this.statusStyle = this.eoiService.getQuickStatusColor(Response.Status);
        });


    }

    checkSubscription(): void {
        if (this.subscription && this.subscription.email && this.subscription.email[0]) {
            this.subscription.storeID = this.storeID;
            this.subscribed = true;
        } else {
            this.subscribed = false;
        }
    }

    getSubscription(): void {
        this.showEmailFields = false;
        if (!this.subscription) {
            this.subscription = {
                id: 0,                       // Assign a default id
                storeID: 0,                 // Assign a default storeID
                email: [],                  // Initialize as an empty array
                subscriptionType: 0,        // Initialize with a default value
                delayInterval: '0',             // Initialize as a number
                lastSentUTC: ''
            };
        } else {
            this.getInitialSubscription();
        }
        this.tempSub = JSON.parse(JSON.stringify(this.subscription));
        const dialogRef = this.dialog.open(EoiSubscriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            minWidth: '25%',
            data: {
                tempSub: this.tempSub,
                getInitialSubscription: this.getInitialSubscription.bind(this),
                subscription: this.subscription,
                subscribed: this.subscribed,
                storeID: this.storeID,
                timeRange: this.timeRange,
                reqType: this.reqType,
                vendorID: this.vendorID,
                updateTimeRange: this.updateTimeRange.bind(this),
            },
        });
        this.updateTimeRange(this.storeID, this.timeRange, this.reqType, this.vendorID);
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    showEmailPreview(): void {
        if (this.subscription && this.subscription.email && this.subscription.email[0]) {
            this.previewMessage = this.subscription.email.toString();
        }
    }

    eoiStatuses(): void {
        const dialogRef = this.dialog.open(EoiStatusDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                eoiOnline: this.eoiOnline,
                posOnline: this.posOnline,
                okToOrder: this.okToOrder,
                disconnectedtime: this.disconnectedtime,
            },
        });

        this.updateTimeRange(this.storeID, this.timeRange, this.reqType, this.vendorID);

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    vendorPopup(): void {
        const dialogRef = this.dialog.open(EoiVendorDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                virtualVendor: this.virtualVendor,
                EOIArray: this.EOIArray,
                vendorClicked: this.vendorClicked,
                storeID: this.storeID,
                timeRange: this.timeRange,
                reqType: this.reqType,
                vendorID: this.vendorID,
                duration: this.duration,
                updateTimeRange: this.updateTimeRange.bind(this),
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    eoiDisplayCustomDate(): void {
        let currentTimeRange = this.getTimeRange();

        const dialogRef = this.dialog.open(EoiCustomDateDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                tempConfig: this.tempConfig,
                localTimeZone: this.localTimeZone,
                storeID: this.storeID,
                timeRange: this.timeRange,
                reqType: this.reqType,
                vendorID: this.vendorID,
                store: this.store,
                setCustomDate: this.setCustomDate.bind(this),
                setTimeZone: this.setTimeZone.bind(this),
                setIdType: this.setIdType.bind(this),
                updateTimeRange: this.updateTimeRange.bind(this),
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close(false);
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result !== false) {
                this.setTimeRange(8);
            } else {
                // do nothing
            }
        });
    }

    eoiDisplayConfig(): void {
        const dialogRef = this.dialog.open(EoiConfigDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                tempConfig: this.tempConfig,
                localTimeZone: this.localTimeZone,
                storeID: this.storeID,
                timeRange: this.timeRange,
                reqType: this.reqType,
                vendorID: this.vendorID,
                store: this.store,
                setTimeZone: this.setTimeZone.bind(this),
                setIdType: this.setIdType.bind(this),
                updateTimeRange: this.updateTimeRange.bind(this),
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    updateTheme(): void {
        if (this.userInfoHandler.getUserInfo().theme === 'dark') {
            let addDarkTheme = document.getElementById('theChart');
            addDarkTheme.setAttribute('style', 'background-color: #222; border-color: #222');
        }
    }

    orderSummary(response: OrderSumApiResponse): void {
        let summary = function (requestType, success, failure, total) {
            this.requestType = requestType;
            this.success = success;
            this.failure = failure;
            this.total = total;
        };

        let status = new summary('', 0, 0, 0);
        let order = new summary('', 0, 0, 0);
        let evalu = new summary('', 0, 0, 0);
        let info = new summary('', 0, 0, 0);
        let purOrder = new summary('', 0, 0, 0);
        let posCallBack = new summary('', 0, 0, 0);
        let realTime_data = new summary('', 0, 0, 0);
        let other = new summary('', 0, 0, 0);

        status.requestType = this.captions.status;
        order.requestType = this.captions.order;
        evalu.requestType = this.captions.eval;
        info.requestType = this.captions.info;
        purOrder.requestType = this.captions.purchaseOrder;
        posCallBack.requestType = this.captions.posCallback;
        realTime_data.requestType = this.captions.realtimeData;
        other.requestType = this.captions.other;

        this.orderTotalAmount = 0;
        this.orderLost = 0;

        for (let i in response.eoiDetailsList) {
            if (response.eoiDetailsList[i].requestType == 'STATUS') {
                status.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    status.success++;
                } else {
                    status.failure++;
                }
            } else if (response.eoiDetailsList[i].requestType == 'ORDER') {
                order.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    order.success++;
                    this.orderTotalAmount += response.eoiDetailsList[i].orderTotal;
                } else {
                    order.failure++;
                    if (response.eoiDetailsList[i].orderXML != null && response.eoiDetailsList[i].responseXML == null) {
                        this.orderLost++;
                    }
                }
            } else if (response.eoiDetailsList[i].requestType == 'INFO') {
                info.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    info.success++;
                } else {
                    info.failure++;
                }
            } else if (response.eoiDetailsList[i].requestType == 'EVAL') {
                evalu.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    evalu.success++;
                } else {
                    evalu.failure++;
                }
            } else if (response.eoiDetailsList[i].requestType == 'PURORDER') {
                purOrder.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    purOrder.success++;
                } else {
                    purOrder.failure++;
                }
            } else if (response.eoiDetailsList[i].requestType == 'POSCALLBACK') {
                posCallBack.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    posCallBack.success++;
                } else {
                    posCallBack.failure++;
                }
            } else if (response.eoiDetailsList[i].requestType == 'REALTIME_DATA') {
                realTime_data.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    realTime_data.success++;
                } else {
                    realTime_data.failure++;
                }
            }
            else {
                other.total++;
                if (
                    response.eoiDetailsList[i].status == 'STORE_PROCESSED' &&
                    response.eoiDetailsList[i].vendorRetrieved == 'SUCCESS'
                ) {
                    other.success++;
                } else {
                    other.failure++;
                }
            }
        }

        this.successTotal =
            order.success +
            evalu.success +
            info.success +
            status.success +
            purOrder.success +
            posCallBack.success +
            realTime_data.success +
            other.success;
        this.failTotal =
            order.failure +
            evalu.failure +
            info.failure +
            status.failure +
            purOrder.failure +
            posCallBack.failure +
            realTime_data.failure +
            other.failure;
        this.total = this.successTotal + this.failTotal;

        order.failure = order.failure + ' ($ ' + parseFloat(this.orderLost).toFixed(2) + ' Lost)';
        order.success = order.success + ' ($ ' + parseFloat(this.orderTotalAmount).toFixed(2) + ')';
        this.eoiSummary = [order, evalu, info, status, purOrder, posCallBack, realTime_data, other];

        this.updateTheme();
    }

    sortData(sort: Sort): void {
        this.response.eoiDetailsList = this.response.eoiDetailsList.sort((a: any, b: any) => {
            let isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'statusOrderType':
                    return this.compare(a.statusOrderType, b.statusOrderType, isAsc);
                case 'id':
                    return this.compare(a.id, b.id, isAsc);
                case 'referenceId':
                    return this.compare(a.referenceId, b.referenceId, isAsc);
                case 'requestType':
                    return this.compare(a.requestType, b.requestType, isAsc);
                case 'localTime':
                    return this.compare(a.localTime, b.localTime, isAsc);
                case 'whenSubmitted':
                    return this.compare(a.whenSubmitted, b.whenSubmitted, isAsc);
                default:
                    return 0;
            }
        });
        this.populateTable();
    }

    compare(a: number | string, b: number | string, isAsc: boolean): number {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    userIsAuthorizedToTestEOI(): boolean {
        return !(this.currentUser.role === this.USER_ACCESS.STORE || this.currentUser.role === this.USER_ACCESS.GROUP_ITEM);
    }
}
