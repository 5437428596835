import { formatDate } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorePortalApiService } from '../services/store-portal.resource';
import { StorePortalService } from '../services/store-portal.service';

import * as moment from 'moment';

// import & install Swiper core and required modules
import { MatDialog } from '@angular/material/dialog';
import { ChartOptions } from 'chart.js';
import { ThemeService } from 'ng2-charts';
import SwiperCore, { Mousewheel, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { AppSettings } from '../../../app-settings.service';
import { AppConstants } from '../../../app.constants';
import { MobileViewService } from '../../../utils/shared-services/mobileView/mobileView.service';
import { WidgetConstants } from '../constants/widget.constants';
import { DateSelectionDialog } from './dialogs/date-selection-dialog.component';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { ReportViewerDialog } from './dialogs/report-viewer-dialog.component';
import { SelectedDateData } from '../models/store-portal.model';

SwiperCore.use([Mousewheel, Navigation, Pagination, Scrollbar]);

@Component({
    selector: 'storePortal',
    templateUrl: 'src/app/features/store-portal/templates/store-portal.html'
})
export class StorePortalComponent implements OnInit {
    public captions: Record<string, string>;
    public THEMES = AppConstants.THEMES;
    public themeOptions: ChartOptions;

    public storeID: string;
    public selectedStoreName: string;
    public portalID: string;

    public storeInfo: any = {};
    public widgetsInfo: any = {};

    public data = {};
    public passedDate: SelectedDateData;
    public viewsConfig: any = [];
    public stores: any = [];
    public currentStore: string;
    public selectedViewIdx: number = 0;
    public selectedStoreIdx: number = 0;
    public swiperConfig: SwiperOptions = {};

    public myViewsToggled: boolean;
    public myStoresToggled: boolean;

    public priorSevenDays: string[] = [];
    public datePickerValue: string;

    public loading: boolean = false;
    public hideProd: boolean = false;
    public EmpNum: string;


    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(StorePortalService) private storePortalService: StorePortalService,
        @Inject(StorePortalApiService) private storePortalApiService: StorePortalApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(MobileViewService) private mobileViewService: MobileViewService,
        @Inject(AppSettings) private appSettings: AppSettings,
        @Inject(ThemeService) private themeService: ThemeService,
        @Inject(Injector) private injector: Injector,
        @Inject(BrowserStorage) private browserStorage,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) private router: Router
    ) {
        this.storeID = this.storePortalService.getStorePortalData().StoreID;
        this.portalID = this.storePortalService.getStorePortalData().Alias;
        this.widgetsInfo = this.storePortalService.getWidgetsInfo();
        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_MEDIUM);
        this.swiperConfig = {
            breakpoints: {
                650: { direction: 'horizontal', slidesPerView: 2 },
                960: { direction: 'horizontal', slidesPerView: 3 },
            },
            centerInsufficientSlides: true,
            initialSlide: 0,
            direction: 'vertical',
            mousewheel: true,
            pagination: { clickable: true },
            slidesPerView: 1,
            spaceBetween: 20,
            noSwiping: true, // add swiper-no-swiping class to elements
        };
        this.myViewsToggled = true;
        this.myStoresToggled = true;
        this.updateChartColorScheme();
    }

    toggleTheme() {
        this.appSettings.setTheme(
            this.appSettings.getTheme() === this.THEMES.dark ? this.THEMES.light : this.THEMES.dark
        );
        this.appSettings.applyTheme();
        this.updateChartColorScheme();
    }

    updateChartColorScheme() {
        this.themeOptions = {
            scales: {
                x: {
                    suggestedMin: 0,
                    grid: {
                        color: getComputedStyle(document.body).getPropertyValue('--tertiary'),
                    },
                    ticks: {
                        color: getComputedStyle(document.body).getPropertyValue('--text-color-primary'),
                    },
                },
                y: {
                    suggestedMin: 0,
                    grid: {
                        color: getComputedStyle(document.body).getPropertyValue('--tertiary'),
                    },
                    ticks: {
                        color: getComputedStyle(document.body).getPropertyValue('--text-color-primary'),
                    },
                },
            },
        };
        this.themeService.setColorschemesOptions(this.themeOptions);
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe(params => {
            this.EmpNum = params['empNum'];
        });
        this.selectedStoreName = this.storePortalService.getStorePortalData().StoreName;
        this.loadStoreConfig();
        this.storeInfo.status = -1;

        let data = this.storePortalService.getStorePortalData().DataSets.DataSet;
        if (!data) {
            return;
        }

        this.storeInfo.userType = data[0].Record?.POSNAME;

        // store list is only visible to HQ users
        if (!this.storeInfo.userType) {
            this.getStoreList();
        }
        let record = data[1].Record;
        if (!record) {
            return;
        }

        this.checkWidgetSelectedDateExists();

        this.storeInfo.status = 1;
        if (record?.OPENDATE) {
            this.storeInfo.openDate = formatDate(data[1].Record.OPENDATE, WidgetConstants.DATE_FORMAT_SIMPLE, 'en-US');
        }
        if (record?.TIMESTART) {
            this.storeInfo.openTime = formatDate(data[1].Record.TIMESTART, WidgetConstants.DATE_FORMAT_LONG, 'en-US');
        }
        if (record?.TIMEEND) {
            this.storeInfo.closeTime = formatDate(data[1].Record.TIMEEND, WidgetConstants.DATE_FORMAT_LONG, 'en-US');
            this.storeInfo.status = 0;
        }
        if (record?.WHOOPENNAME) {
            this.storeInfo.whoOpenName = data[1].Record.WHOOPENNAME;
        }
        if (record?.WHOCLOSENAME) {
            this.storeInfo.whoCloseName = data[1].Record.WHOCLOSENAME;
        }

        let startTime = new Date(data[1].Record.TIMESTART).getTime();
        for (var i = 0; i < 7; i++) {
            this.priorSevenDays[i] = formatDate(
                startTime - i * 24 * 60 * 60 * 1000,
                WidgetConstants.DATE_FORMAT_SIMPLE,
                'en-US'
            );
        }
        this.datePickerValue = this.priorSevenDays[0];
        this.passedDate = {
            date1: moment(this.datePickerValue, "YYYY-MM-DD").toDate(),
            date2: moment(this.datePickerValue, "YYYY-MM-DD").toDate(),
            selectedDate: ""
        };

        // hide from PROD only -- currently nothing is hidden
        if (
            window.location.origin.indexOf('localhost') > -1 ||
            window.location.origin.indexOf('ci') > -1 ||
            window.location.origin.indexOf('qa') > -1
        ) {
            this.hideProd = true;
        }
    }

    checkWidgetSelectedDateExists(): void {
        const widgetSelectedDate = this.browserStorage.getSessionstorage('widgetSelectedDate', null);
        if (widgetSelectedDate) {
            this.browserStorage.removeSessionstorageItem('widgetSelectedDate');
        }
    }

    openDateDialog(): void {
        const dialogRef = this.dialog.open(DateSelectionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                selectedDate: this.datePickerValue,
            },
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            if (result) {
                this.changeDate(formatDate(result, WidgetConstants.DATE_FORMAT_SIMPLE, 'en-US'));
            }
        });
    }

    openReportViewerDialog(): void {
        const dialogRef = this.dialog.open(ReportViewerDialog, {
            disableClose: true,
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '200vw',
            data: {
                StoreID: this.storeID,
                EmpNum: this.EmpNum,
                PassedStartDate: this.passedDate.date1,
                PassedEndDate: this.passedDate.date2
            },
        });

        dialogRef.afterClosed().subscribe((result: SelectedDateData) => {
            if (result) {
                this.passedDate = { date1: result.date1, date2: result.date2, selectedDate: "" }
            }
        });
    }

    slideChange(ev) {
        this.swiperConfig.initialSlide = ev[0].activeIndex;
    }

    getStoreList() {
        this.stores = this.browserStorage.getSessionstorage('portalData', null)?.StoreList;
        this.currentStore = this.browserStorage.getSessionstorage('portalData', null)?.StoreName;
    }

    loadStoreConfig() {
        this.storePortalApiService.getPortalConfigByStoreID(this.storeID).then((config: any) => {
            this.viewsConfig = this.storePortalService.parseSavedConfig(config);
            if (this.selectedViewIdx >= this.viewsConfig.length) {
                this.selectedViewIdx = this.viewsConfig.length - 1;
            }
            this.getData();
        });
    }

    getData() {
        let widgetsInView = this.viewsConfig[this.selectedViewIdx].widgets;
        let uniqueDatapoints = new Set();
        uniqueDatapoints.add('STORE_Info_Current');
        widgetsInView.forEach((widget) => {
            this.widgetsInfo[widget.id].datapoints.forEach((datapoint) => {
                if (!this.data[datapoint]) {
                    uniqueDatapoints.add(datapoint);
                }
            });
            this.browserStorage.setSessionstorage(widget.id, this.widgetsInfo[widget.id].datapoints);
        });
        let datapoints = Array.from(uniqueDatapoints);

        if (datapoints.length === 1) {
            this.setWidgetsData(widgetsInView);
            return;
        }

        const opendate = this.datePickerValue ? formatDate(this.datePickerValue, WidgetConstants.DATE_FORMAL_COMPACT, 'en-US') : formatDate("1899-01-01", WidgetConstants.DATE_FORMAL_COMPACT, 'en-US');
        const params = JSON.stringify({ opendate });
        this.storePortalApiService.getDatasetsByDatapoints(this.storeID, params, datapoints.toString()).then(
            (res: any) => {
                if (!res?.DataSet) {
                    return;
                }
                let datasets = Array.isArray(res.DataSet) ? res.DataSet : [res.DataSet];
                datasets.forEach((dataset) => (this.data[dataset.CodeName] = dataset));
                let storeInfo = this.data['STORE_Info_Current'].Record;
                if (storeInfo) {
                    this.storeInfo.localTime = formatDate(new Date(), WidgetConstants.DATE_FORMAT_LONG, 'en-US');
                    this.storeInfo.storeTime = formatDate(
                        storeInfo.STOREDATETIME,
                        WidgetConstants.DATE_FORMAT_LONG,
                        'en-US'
                    );
                }
                this.setWidgetsData(widgetsInView);
            },
            (error) => {
                widgetsInView.forEach((widget) => {
                    widget.title = this.widgetsInfo[widget.id].title;
                    widget.data = [];
                    widget.isTimedOut = true;
                    widget.injector = Injector.create({
                        providers: [{ provide: Object, useValue: widget }],
                        parent: this.injector,
                    });
                });
            }
        );
    }

    setWidgetsData(widgets) {
        widgets.forEach((widget) => {
            widget.title = this.widgetsInfo[widget.id].title;
            widget.data = [];
            this.widgetsInfo[widget.id].datapoints.forEach((datapoint) => widget.data.push(this.data[datapoint]));
            widget.injector = Injector.create({
                providers: [{ provide: Object, useValue: widget }],
                parent: this.injector,
            });
            widget.isLoaded = true;
            widget.isError = false;
        });
    }

    checkWidgetType(widget: any): boolean {
        switch (widget.id) {
            case 'wid-012':
                return this.isWidgetVisible(widget);
            case 'wid-013':
                return this.isWidgetVisible(widget);
            case 'wid-014':
                return this.isWidgetVisible(widget);
            case 'wid-015':
                return this.isWidgetVisible(widget);
            case 'wid-016':
                return this.isWidgetVisible(widget);
            case 'wid-017':
                return this.isWidgetVisible(widget);
            default:
                return true;
        }
    }

    // the widgets are only visible when the store is open date matches the selected date
    isWidgetVisible(widget): boolean {
        const widgetsToShow = ['wid-012', 'wid-013', 'wid-014', 'wid-015', 'wid-016', 'wid-017'];
        return this.datePickerValue === this.storeInfo.openDate && widgetsToShow.includes(widget.id);
    }

    optionClicked(idx) {
        this.selectedViewIdx = idx;
        this.getData();
    }

    selectStore(store) {
        if (this.loading) return;
        if (store.storeName === this.currentStore) {
            return;
        } else {
            this.loading = true;
            let userAccess;
            if (this.browserStorage.getSessionstorage('userAccess', null)) {
                userAccess = this.browserStorage.getSessionstorage('userAccess', null);
                this.storePortalApiService
                    .hqLoginForStorePortal(store.pathName, '', 'HQUser', userAccess)
                    .then((res: any) => {
                        this.loading = false;
                        this.storePortalService.setStorePortalData(res);
                        if (res?.AccessToken) {
                            this.router.navigate(['storePortal/home/0']).then(() => {
                                window.location.reload();
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            } else if (this.storePortalService.getStorePortalData()) {
                userAccess = this.storePortalService.getStorePortalData().userAccess;
                this.storePortalApiService
                    .loginForStorePortal(store.pathName, '', 'HQUser', userAccess)
                    .then((res: any) => {
                        res.userAccess = userAccess;
                        this.loading = false;
                        this.storePortalService.setStorePortalData(res);
                        if (res?.AccessToken) {
                            this.router.navigate(['storePortal/home/0']).then(() => {
                                window.location.reload();
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                    });
            }
        }
    }

    clearSavedData() {
        this.data = {};
        this.viewsConfig[this.selectedViewIdx].widgets.forEach((widget) => {
            widget.title = '';
            widget.isLoaded = false;
        });
    }

    changeDate(date: string): void {
        this.datePickerValue = date;
        this.passedDate = {
            date1: moment(date, "YYYY-MM-DD").toDate(),
            date2: moment(date, "YYYY-MM-DD").toDate(),
            selectedDate: ""
        };
        this.browserStorage.setSessionstorage('widgetSelectedDate', date);
        this.clearSavedData();
        this.getData();
    }

    refresh(): void {
        this.clearSavedData();
        this.loadStoreConfig();
    }
}
