import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CaptionService } from '../../../utils/shared-services/caption.service';

interface Parameters {
    passedUserId: any;
}

@Component({
    selector: 'editUser',
    templateUrl: 'src/app/features/users/templates/confirm-reset-password-dialog.html',
})
export class ConfirmResetPasswordDialog {
    public captions: any;

    constructor(
        @Inject(CaptionService) private captionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfirmResetPasswordDialog>,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(Router) private router: Router,
    ) {
        this.captions = this.captionService.captions;
    }

    confirmPasswordReset() {
        this.dialogRef.close();
        this.router.navigate(['users/resetPassword', this.data.passedUserId]);
    }
}
