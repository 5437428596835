import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CaptionService } from '../../../../app/utils/shared-services/caption.service';
import { AppConstants } from '../../../app.constants';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { UserAccessHandler } from '../services/user-access-handler.service';
import { UserInfoHandler } from '../services/user-info-handler.service';
import { UserApiService } from '../services/users.resource';
import { UserConstants } from '../users.constants';
import { SubscriptionsDialog } from './subscriptions.component';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'userSettings',
    templateUrl: 'src/app/features/users/templates/user-settings.html',
})
export class UserSettingsComponent {
    private LANGUAGES = AppConstants.LANGUAGES;
    private THEMES = AppConstants.THEMES;
    private USER_ACCESS = UserConstants.USER_ACCESS;
    private USER_PRIVILEGES;

    public isAdmin: boolean = false;
    public captions: any;
    public defaultHomeDashboard: boolean = false;
    public currentUserSecurity: any;
    public user: any;
    public notificationConsent: any;
    public languages: any;
    public themes: any;
    public selectedLang: any;
    public selectedTheme: any;
    public subscriptions: any;

    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    userAccess: any;
    userInfo: any;
    portalOnlyView: boolean = false;

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(UserInfoHandler) private userInfoHandler,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(UserAccessHandler) private userAccessHandler,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(CaptionService) private captionService,
        @Inject(ValidationService) public validationService: ValidationService,

    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.USER_PRIVILEGES = UserConstants.USER_PRIVILEGES;
        this.userAccess = this.userAccessHandler.getUserAccess();
        this.userInfo = userInfoHandler.getUserInfo();
        // check for  the viewSharedDashOnly privilege
        if (this.userAccess) {
            for (let priv of JSON.parse(this.userAccess.userPrivilege)) {
                if (priv.name == this.USER_PRIVILEGES.PORTAL_ADMIN || priv.name == this.USER_PRIVILEGES.PORTAL_USER)
                    this.portalOnlyView = true;
            }
        }
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.user = this.userInfoHandler.getUserInfo();
        this.currentUserSecurity = this.userAccessHandler.getUserAccess();
        if (this.currentUserSecurity.role === this.USER_ACCESS.GLOBAL) this.isAdmin = true;
        this.notificationConsent = this.user.notificationConsent === 1 ? true : false;
        this.user.fullName = this.user.firstName + ' ' + this.user.lastName;
        this.defaultHomeDashboard = this.user.showDashboardByDefault;

        this.languages = [
            {
                caption: this.captions.chinese,
                language: this.LANGUAGES.chinese,
            },
            {
                caption: this.captions.english,
                language: this.LANGUAGES.english,
            },
            {
                caption: this.captions.arabic,
                language: this.LANGUAGES.arabic,
            },
            {
                caption: this.captions.spanish,
                language: this.LANGUAGES.spanish,
            },
            {
                caption: this.captions.french,
                language: this.LANGUAGES.french,
            },
        ];

        this.themes = [
            {
                caption: this.captions.dark,
                theme: this.THEMES.dark,
            },
            {
                caption: this.captions.light,
                theme: this.THEMES.light,
            },
        ];

        for (let langObj of this.languages) {
            if (langObj.language === this.user.language) this.selectedLang = langObj;
        }

        for (let themeObj of this.themes) {
            if (themeObj.theme === this.user.theme) this.selectedTheme = themeObj;
        }

        this.form = this.formBuilder.group({
            email: [this.user.email, [Validators.maxLength(45)]],
            selectedTheme: [this.selectedTheme, [Validators.required]],
            language: [this.selectedLang, Validators.required],
            defaultHomeDashboard: [this.defaultHomeDashboard, [Validators.required]],
        });
    }

    manageAlertSubscriptions(ev) {
        this.userApiService.getSubscriptionsPerAccount().then((subs: any) => {
            if (!subs) subs = [];
            for (let sub of subs) {
                let cSubIndex = subs.findIndex((k) => k == sub);
                if (sub == '') {
                    subs.splice(cSubIndex, 1);
                }
            }
            this.subscriptions = subs;
            const dialogRef = this.dialog.open(SubscriptionsDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                disableClose: true,
                data: {
                    user: this.user,
                    subscriptions: this.subscriptions,
                    notificationConsent: this.user.notificationConsent === 1 ? true : false,
                    emailSub: '',
                },
            });

            dialogRef.backdropClick().subscribe(() => {
                dialogRef.close();
            });
        });
    }

    cancelChanges() {
        this.router.navigate(['home']);
    }

    saveChanges(value: any) {
        const { language, defaultHomeDashboard, selectedTheme } = value;
        let userLanguage = this.userInfoHandler.getUserInfo().language;
        this.selectedLang.language = language.language;
        this.selectedTheme.theme = selectedTheme.theme;
        this.defaultHomeDashboard = defaultHomeDashboard;
        this.utilsApiService
            .updateCurrentUser(this.selectedLang.language, this.selectedTheme.theme, this.defaultHomeDashboard)
            .then(() => {
                this.userInfoHandler.setShowDashboardByDefault(this.defaultHomeDashboard);
                this.userInfoHandler.setTheme(this.selectedTheme.theme);
                this.userInfoHandler.setLanguage(this.selectedLang.language);
                this.userInfoHandler.setNotificationConsent(this.user.notificationConsent);
                if (userLanguage !== this.selectedLang.language) {
                    delete this.captions;
                    this.captions = this.captionService.assignCaptions(this.selectedLang.language, 'FRONT');
                }
                this.router.navigate(['home']);
            });
    }

    resetPassword() {
        this.router.navigate(['users/changePassword']);
    }
}