import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { ReportCategory, ReportCategoryGroup } from "../../models/price-change.model";
import { ReportCategorySelectionDialogData } from "../../models/dialog.model";

@Component({
    selector: "report-category-selection",
    templateUrl: "./src/app/features/price-change/templates/dialogs/report-category-selection.component.html"
})
export class PriceChangeReportCategorySelectionDialog implements OnInit {

    public maxSelection: number;
    public numSelected: number;
    public captions: Record<string, string>
    public reportCategories: ReportCategory[];
    public categoriesByDescription: ReportCategoryGroup[]
    public categoriesByPLink: ReportCategoryGroup[]
    public selectedBy: "Descript" | "PLink";
    public tabIdx: number;
    public searchReportStr: string;
    public isPriceMatch: Boolean;

    @ViewChild(MatTabGroup) matTabGroup: MatTabGroup;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<PriceChangeReportCategorySelectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ReportCategorySelectionDialogData,
        @Inject(ChangeDetectorRef) private cd: ChangeDetectorRef
    ) {
        this.captions = this.captionService.captions;
        this.maxSelection = 0;
        this.numSelected = 0;
        this.selectedBy = null;
        this.tabIdx = 0;
        this.reportCategories = [];
        this.categoriesByDescription = [];
        this.categoriesByPLink = [];
    }

    ngOnInit(): void {
        this.maxSelection = this.data.maxSelection;
        this.numSelected = this.data.numSelected;
        this.selectedBy = this.data.selectedBy;
        this.tabIdx = this.selectedBy === "PLink" ? 1 : 0;
        this.isPriceMatch = this.data.isPriceMatch;
        this.reportCategories = structuredClone(this.data.reportCategories);
        this.reportCategories.forEach(reportCat => {
            // Populate categoriesByDescription
            if (reportCat.Descript) {
                const existingDescriptCategory = this.categoriesByDescription.find(category => {
                    return category.value === reportCat.Descript;
                });
                if (existingDescriptCategory) {
                    existingDescriptCategory.reportCategories.push(reportCat);
                }
                else {
                    const newDescriptCategory: ReportCategoryGroup = {
                        type: "Descript",
                        value: reportCat.Descript,
                        isSelected: this.selectedBy === "Descript" && reportCat.isSelected,
                        reportCategories: [reportCat]
                    };
                    this.categoriesByDescription.push(newDescriptCategory);
                }
            }

            // Populate categoriesByPLink
            if (reportCat.PLink) {
                const existingPLinkCategory = this.categoriesByPLink.find(category => {
                    return category.value === reportCat.PLink;
                });
                if (existingPLinkCategory) {
                    existingPLinkCategory.reportCategories.push(reportCat);
                }
                else {
                    const newPLinkCategory: ReportCategoryGroup = {
                        type: "PLink",
                        value: reportCat.PLink,
                        isSelected: this.selectedBy === "PLink" && reportCat.isSelected,
                        reportCategories: [reportCat]
                    };
                    this.categoriesByPLink.push(newPLinkCategory);
                }
            }
        });
    }

    selectCategory(reportCategoryGroup: ReportCategoryGroup): void {
        // Enforce selection for one group only
        if (reportCategoryGroup.type !== this.selectedBy) {
            this.selectedBy = reportCategoryGroup.type;
            this.resetAllSelection();
            this.numSelected = 0;
        }

        // Select group
        if (!reportCategoryGroup.isSelected && this.numSelected < this.maxSelection) {
            this.numSelected++;
            reportCategoryGroup.isSelected = true;
            reportCategoryGroup.reportCategories.forEach(reportCategory => {
                reportCategory.isSelected = true;
            });
        }

        //Deselect group
        else if (reportCategoryGroup.isSelected) {
            this.numSelected--;
            reportCategoryGroup.isSelected = false;
            reportCategoryGroup.reportCategories.forEach(reportCategory => {
                reportCategory.isSelected = false;
            });
        }
    }

    selectAll(categoryGroups: ReportCategoryGroup[]): void {
        this.resetAllSelection();

        //get the selected by from one group as all will be the same 
        this.selectedBy = categoryGroups[0].type

        categoryGroups.forEach(reportCategoryGroup => {
            reportCategoryGroup.isSelected = true;
            reportCategoryGroup.reportCategories.forEach(reportCategory => {
                reportCategory.isSelected = true;
            });
        });
        this.numSelected = categoryGroups.length;
        this.cd.detectChanges();

    }

    confirmSelection(): void {
        this.dialogRef.close({
            selectedBy: this.selectedBy,
            numSelected: this.numSelected,
            reportCategories: this.reportCategories,
        })
    }

    resetSelection(categoryGroups: ReportCategoryGroup[]): void {
        categoryGroups.forEach(reportCategoryGroup => {
            reportCategoryGroup.isSelected = false;
            reportCategoryGroup.reportCategories.forEach(reportCategory => {
                reportCategory.isSelected = false;
            });
        });

        if (categoryGroups[0]?.type === this.selectedBy) {
            this.numSelected = 0;
        }
    }

    private resetAllSelection(): void {
        this.resetSelection(this.categoriesByDescription);
        this.resetSelection(this.categoriesByPLink);
    }

    filterReportCat(reportCat: ReportCategoryGroup, searchStr: string) {
        return !searchStr || reportCat.value.toLowerCase().includes(searchStr);
    }

    tabSwitched(ev){
        this.searchReportStr = '';
    }
}

