import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {
    transform(array: any, field: string): any[] {
        array.sort((a: any, b: any) => {
            if (a[field] < b[field]) {
                return -1;
            } else if (a[field] > b[field]) {
                return 1;
            } else {
                return 0;
            }
        });
        return array;
    }
}


@Pipe({
    name: 'orderByDate'
})
export class SortByDatePipe implements PipeTransform {
    transform(value: any, field: string): any {
        let dateDescending = value.sort((a: any, b: any) => {
            let date1 = new Date(a[field]);
            let date2 = new Date(b[field]);
            if (date1 < date2) {
                return 1;
            } else if (date1 > date2) {
                return -1;
            } else {
                return 0;
            }
        });
        return dateDescending;
    }
}
