import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SnackbarService } from "../../../../utils/shared-services/snackbar/snackbar.service";
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { StoreDetailsDialogData } from "../../models/dialog.model";

@Component({
    selector: 'store-details-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/store-details-dialog.html',
})
export class StoreDetailsDialog {

    private defaultTimezone = '(GMT-5:00) America/Toronto';
    private formBuilder: FormBuilder = new FormBuilder();

    public form = this.formBuilder.group({
        replicationToken: [''],
        displayName: [''],
        country: [''],
        currencyCode: [''],
        billType: [''],
        timezone: [this.defaultTimezone],
        corpStoreNum: [''],
        addressLine1: [''],
        addressLine2: [''],
        code: [''],
        state: [''],
        city: [''],
        phone: [''],
    });

    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<StoreDetailsDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: StoreDetailsDialogData,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Clipboard) private clipBoard: Clipboard
    ) {
        this.captions = this.captionService.captions;
        let store = data.storeDetail;

        this.form.setValue({
            replicationToken: store.replicationToken ? store.replicationToken : '',
            displayName: store.displayName ? store.displayName : '',
            country: store.address.country ? store.address.country : '',
            currencyCode: store.currencyCode ? store.currencyCode : '',
            billType: store.billType ? store.billType : '',
            timezone: store.localTimeZone ? store.localTimeZone : this.defaultTimezone,
            corpStoreNum: store.corpStoreNum ? store.corpStoreNum : '',
            addressLine1: store.address.line1 ? store.address.line1 : '',
            addressLine2: store.address.line2 ? store.address.line2 : '',
            code: store.address.code ? store.address.code : '',
            state: store.address.state ? store.address.state : '',
            city: store.address.city ? store.address.city : '',
            phone: store.phone1 ? store.phone1 : '',
        });
    }

    copyToken(): void {
        this.clipBoard.copy(this.form.value.replicationToken);
        this.snackbarService.successMessageBottom('Copied');
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
