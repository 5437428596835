import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, } from '@angular/material/dialog';
import { ValidationService } from '../../../../utils/shared-services/validation.service';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { ConfirmDeleteDialogData } from '../../models/dialog.model';
import { Store, StoreBase } from '../../models/store.model';

@Component({
    selector: 'confim-delete-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/confirm-delete-dialog.html',
})
export class StoreAndConceptConfirmDeleteDialog {

    public captions: Record<string, string>;
    public storesToDelete: StoreBase[];
    public storesWithUsersSelected: boolean;
    public storesWithUsers: Store[];
    public hint: string;
    public warningMessage: string;
    public form = new FormGroup({
        deleteInput: new FormControl('', [ Validators.required ]),
    });

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<StoreAndConceptConfirmDeleteDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteDialogData,
        @Inject(ValidationService) public validationService: ValidationService
    ) {
        this.captions = this.captionService.captions;
        this.storesToDelete = this.data.storesToDelete;
        this.storesWithUsersSelected = this.data.storesWithUsersSelectedToDelete;
        this.storesWithUsers = this.data.storesWithUsersToDelete;

        this.hint = this.captions['deleteHint' + this.data.storeOrConcept].replace(
            '{deleteKeyWord}',
            this.captions.deleteKeyWord
        );

        this.warningMessage = this.captions['deleteConfirmation' + this.data.storeOrConcept].replace(
            '{deleteKeyWord}',
            this.captions.deleteKeyWord
        );

        // Add custom validator for deleteInput
        this.form.controls.deleteInput
            .addValidators(validationService.deleteStoreConceptConfirmation());
    }
}
