import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { DataExchangeInfo } from '../models/data-exchange-info.model';
import { ReplicationTask } from '../models/replication-task.model';

@Injectable()
export class DataExchangeApiService {
    private allDataExchanges: string = 'data-exchange/{conceptID}/{storeID}';
    private allDirectUploads: string = 'data-exchange/direct-upload/{conceptID}/{storeID}';
    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getAllDataExchanges(conceptId: number, storeID: number): Promise<DataExchangeInfo> {
        const url =
            this.BASE_URL + this.allDataExchanges.replace('{conceptID}', conceptId.toString()).replace('{storeID}', storeID.toString());
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$) as Promise<DataExchangeInfo>;
        return value;
    }

    async getAllDirectUploads(conceptId: number, storeID: number): Promise<ReplicationTask> {
        const url =
            this.BASE_URL + this.allDirectUploads.replace('{conceptID}', conceptId.toString()).replace('{storeID}', storeID.toString());
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$) as Promise<ReplicationTask>;
        return value;
    }
}
