import { Inject, Injectable } from '@angular/core';
import * as wijmo from 'wijmo/wijmo';
import * as wijmoGrid from 'wijmo/wijmo.grid';
import { CaptionService } from '../../../utils/shared-services/caption.service';

@Injectable()
export class WijmoFormatterService {
    public captions;

    constructor(@Inject(CaptionService) private captionService: CaptionService) {
        this.captions = this.captionService.captions;
    }

    formatItem(s, e) {
        if (e.panel.cellType != wijmoGrid.CellType.Cell) {
            return;
        }
        ////Next line retrieves the column headers for the current cell
        let binds = e.panel.columns[e.col].binding.split(';');
        //Next 2 lines are used to determine whether the current row in this report contain any weighted average or seconds. Specific column names are used
        let isNotSeconds =
            binds.indexOf('Average Make Time:0') < 0 &&
            binds.indexOf('Average Rack Time:0') < 0 &&
            binds.indexOf('Average OTD Time:0') < 0 &&
            binds.indexOf('Average TTD Time:0') < 0;
        let isNotWeightedAverage =
            binds.indexOf('Actual Food Cost %:0') < 0 && binds.indexOf('Target Food Cost %:0') < 0;

        if (isNotWeightedAverage && isNotSeconds) {
            return;
        }
        //Get the data for the whole row (using the current cell)
        let rowData = e.panel.rows[e.row]._data;
        let toDisplayOnCell;

        if (isNotWeightedAverage) {
            //If cell is not holding an average that means it is holding seconds
            let seconds;
            //If statements determine which column this cell belongs to so that correct data can be retreived
            if (binds.indexOf('Average Make Time:0') >= 0) {
                seconds = this.getCellsSpecificValue(binds, 'Average Make Time:0;', rowData);
            } else if (binds.indexOf('Average Rack Time:0') >= 0) {
                seconds = this.getCellsSpecificValue(binds, 'Average Rack Time:0;', rowData);
            } else if (binds.indexOf('Average OTD Time:0') >= 0) {
                seconds = this.getCellsSpecificValue(binds, 'Average OTD Time:0;', rowData);
            } else if (binds.indexOf('Average TTD Time:0') >= 0) {
                seconds = this.getCellsSpecificValue(binds, 'Average TTD Time:0;', rowData);
            }

            if (seconds != null) {
                let date = new Date(null);
                date.setSeconds(seconds);
                toDisplayOnCell = date.toISOString().substr(11, 8);
            } else {
                toDisplayOnCell = '';
            }
        } else {
            let netSales = this.getCellsSpecificValue(binds, 'Net Sales:0;', rowData);
            let totalValue;
            //If statements determine which column this cell belongs to so that correct data can be retreived
            if (binds.indexOf('Actual Food Cost %:0') >= 0) {
                totalValue = this.getCellsSpecificValue(binds, 'Actual Food Cost Value:0;', rowData);
            } else if (binds.indexOf('Target Food Cost %:0') >= 0) {
                totalValue = this.getCellsSpecificValue(binds, 'Target Food Cost Value:0;', rowData);
            }

            if (netSales == null && totalValue == null) toDisplayOnCell = '';
            else toDisplayOnCell = netSales > 0 ? ((totalValue / netSales) * 100).toFixed(2) : 'N/A';
        }
        e.cell.innerHTML = toDisplayOnCell;
    }

    getCellsSpecificValue(cellHeaders, headerToFind, rowData) {
        /*Since there may be multiple stores, this line will make sure that correct data is return for specific stores
        "cellHeaders[0]" holds name of store, "headerToFind" holds the name of the column we are looking for*/
        return cellHeaders.length == 2 ? rowData[headerToFind] : rowData[cellHeaders[0] + ';' + headerToFind];
    }

    setUpControlCaptionsForWijmo() {
        wijmo.culture.olap.PivotPanel = {
            fields: this.captions.chooseFieldsToAddToReport,
            drag: this.captions.dragFieldsReport,
            filters: this.captions.filtersLabel,
            cols: this.captions.columnsLabel,
            rows: this.captions.rowsLabel,
            vals: this.captions.valuesLabel,
            defer: this.captions.deferUpdates,
            update: this.captions.updateLabel,
        };

        wijmo.culture.olap.PivotFieldEditor = {
            dialogHeader: this.captions.wijmoFieldSettings,
            header: this.captions.wijmoHeader,
            summary: this.captions.wijmoSummary,
            showAs: this.captions.wijmoShowAs,
            weighBy: this.captions.wijmoWeighBy,
            sort: this.captions.wijmoSort,
            filter: this.captions.wijmoFilter,
            format: this.captions.wijmoFormat,
            sample: this.captions.wijmoSample,
            edit: this.captions.wijmoEdit,
            clear: this.captions.wijmoclear,
            ok: this.captions.ok,
            cancel: this.captions.cancelFront,
            none: this.captions.wijmonone,
            sorts: {
                asc: this.captions.wijmoAscending,
                desc: this.captions.wijmoDescending,
            },
            aggs: {
                sum: this.captions.wijmSum,
                cnt: this.captions.wijmoCount,
                avg: this.captions.wijmoAverage,
                max: this.captions.wijmoMax,
                min: this.captions.wijmoMin,
                rng: this.captions.wijmoRange,
                std: this.captions.wijmoStdDev,
                let: this.captions.wijmoVar,
                stdp: this.captions.wijmoStdDevPop,
                varp: this.captions.wijmoVarPop,
            },
            calcs: {
                noCalc: this.captions.wijmoNoCalc,
                dRow: this.captions.wijmoDiffFromPrevRow,
                dRowPct: this.captions.wijmoPercDiffFromDiffRow,
                dCol: this.captions.wijmoDiffFromPrevCol,
                dColPct: this.captions.wijmoPercDiffFromPrevCol,
            },
            formats: {
                n0: this.captions.wijmoInt,
                n2: this.captions.wijmoFloat,
                c: this.captions.wijmoCurrency,
                p0: this.captions.wijmoPercentage1,
                p2: this.captions.wijmoPercentage2,
                n2c: this.captions.wijmoThousands,
                n2cc: this.captions.wijmoMillions,
                n2ccc: this.captions.wijmoBillions,
                d: this.captions.wijmoDate,
                MMMMddyyyy: this.captions.wijmoMonthDayYear,
                dMyy: this.captions.wijmoDayMonthYear1,
                ddMyy: this.captions.wijmoDayMonthYear2,
                dMyyyy: this.captions.wijmoDayMonthYear3,
                MMMyyyy: this.captions.wijmoMonthYear1,
                MMMMyyyy: this.captions.wijmoMonthYear2,
                yyyyQq: this.captions.wijmoYearQuarter,
                FYEEEEQU: this.captions.wijmoFiscalYearQuarter,
            },
        };

        wijmo.culture.olap._ListContextMenu = {
            up: this.captions.wijmoMoveUp,
            down: this.captions.wijmoMoveDown,
            first: this.captions.wijmoMoveToBegin,
            last: this.captions.wijmoMoveToEnd,
            filter: this.captions.wijmoMoveToReportFilter,
            rows: this.captions.wijmoMoveToRowLabel,
            cols: this.captions.wijmoMoveToColumnLabel,
            vals: this.captions.wijmoMoveToValue,
            remove: this.captions.wijmoRemoveField,
            edit: this.captions.wijmoFieldSettings,
            detail: this.captions.wijmoShowDetail,
        };

        wijmo.culture.olap.DetailDialog = {
            header: this.captions.wijmoDetailView,
            ok: this.captions.ok,
            items: this.captions.wijmoItems,
            item: this.captions.wijmoItem,
            row: this.captions.wijmoRow,
            col: this.captions.wijmoColumn,
        };
    }

    printReport(vm) {
        let visualization = getVisualizationOfCurrentTab();
        let httpLinkUrl = visualization.httpLinkUrl.changingThisBreaksApplicationSecurity;

        let openWindow = window.open(httpLinkUrl, '_blank', 'height=800,width=800');
        openWindow.document.close();
        openWindow.focus();
        openWindow.print();

        function getVisualizationOfCurrentTab() {
            if (!vm.info || !vm.selectedVisualization) return;
            for (let i = 0; i < vm.info.visualizations.length; i++) {
                if (vm.info.visualizations[i].id == vm.selectedVisualization) {
                    return vm.info.visualizations[i];
                }
            }
        }
    }

    setMeasuresColor(engine) {
        if (engine) return;

        //For setting the colour of "Values" fields red since it only sets it for the first panel in above code.
        let divForValueArea = engine._dVals.childNodes;
        if (divForValueArea !== undefined) {
            if (divForValueArea[0] !== undefined) {
                let valuesDiv = divForValueArea[0].childNodes;
                let roleRows = divForValueArea[0].querySelectorAll('[role=row]');
                let results = [];
                for (let y = 0; y < roleRows.length; y++) {
                    results[y] = roleRows[y].innerText.replace(/ *\([^)]*\) */g, '');
                    if (this.isType(results[y], engine, 2))
                        roleRows[y].style.setProperty('color', 'red', 'important');
                    else roleRows[y].style.setProperty('color', 'black', 'important');
                }
            }
        }

        let listboxes = document.getElementsByClassName('report__wijmo-panel');
        for (let i = 0; i < listboxes.length; i++) {
            let childNodes = listboxes[i].childNodes;
            let div = listboxes[i].querySelectorAll('[wj-part=d-fields]');
            if (div !== undefined) {
                if (div[0] !== undefined) {
                    let innerChildNodes = div[0].querySelectorAll('[role=gridcell]');
                    for (let j = 0; j < innerChildNodes.length; j++) {
                        processNodes(innerChildNodes[j], engine);
                    }
                }
            }
        }

        function processNodes(nodeOfListBox, engine) {
            let text = nodeOfListBox.innerText;
            nodeOfListBox.oncontextmenu = function () {
                return false;
            };
            if (this.isType(text, engine, 0)) {
                //set draggable to false
                nodeOfListBox.draggable = false;
                //set onclick to false to avoid it from being added to field areas
                nodeOfListBox.style.setProperty('pointer-events', 'none');
                //remove the input element and the span element
                let inputElement = nodeOfListBox.getElementsByTagName('input');
                inputElement[0].style.display = 'none';
                let spanElement = nodeOfListBox.getElementsByTagName('span');
                spanElement[0].style.display = 'none';

                //get label element and bold the font
                let labelElement = nodeOfListBox.getElementsByTagName('label');
                labelElement[0].style.setProperty('font-weight', 'bold');
                labelElement[0].style.setProperty('text-transform', 'uppercase');
            } else {
                //set pointer-events back to default
                nodeOfListBox.style.setProperty('pointer-events', 'auto');
            }
            if (this.isType(text, engine, 2)) nodeOfListBox.style.setProperty('color', 'red', 'important');
            else {
                if (this.userInfoHandler.getUserInfo().theme === "dark") {
                    nodeOfListBox.style.setProperty('color', 'rgba(255, 255, 255, 0.7)', 'important');
                }
                else nodeOfListBox.style.setProperty('color', 'black', 'important');
            }
        }
    }

    extractFieldName(innerText) {
        //fixed String's match vs RegExp's exec
        let result = new RegExp('([^()]*)').exec(innerText);
        return result[0];
    }

    isType(name, engine, type) {
        name = this.extractFieldName(name);
        if (engine.fields) {
            for (let i = 0; i < engine.fields.length; i++) {
                let field = engine.fields[i];
                if (name.trim() == field._header.trim()) return field._dataType == type;
            }
        }
        return false;
    }
}