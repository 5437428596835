import { Component, Input } from '@angular/core';
import { ChartData, ChartType } from 'chart.js';
import { WidgetConstants } from '../../../constants/widget.constants';

@Component({
    selector: 'chart-gauge',
    templateUrl: 'src/app/features/store-portal/widgets/charts/templates/chart-gauge.html',
})
export class ChartGauge {
    public type: ChartType = 'doughnut';
    public data: ChartData<'doughnut'> = { labels: [], datasets: [] };

    @Input() public labels: string[];
    @Input() public datasets: any[];
    @Input() public hasLegend = false;
    @Input() public colors: any = WidgetConstants.CHART_COLORS_GAUGE;
    @Input() public needleValue: number = 0;
    @Input() public options: any = {
        aspectRatio: 2,
        circumference: 189,
        rotation: -90,
        plugins: {
            tooltip: {
                callbacks: {
                    title: () => null,
                    label: (context: any) => context.label
                }
            }
        },
        scales: {
            x: { display: false },
            y: { display: false },
        }
    };
    @Input() public plugins: any = [{
        id: 'gaugeNeedle',
        afterDraw: chart2 => {
            var angle = Math.PI + (1 / 100 * Math.min(this.needleValue, 105) * Math.PI);

            var ctx = chart2.ctx;
            var cx = chart2.canvas.offsetWidth / 2;
            var cy = chart2.canvas.offsetHeight * .865;

            ctx.beginPath();
            ctx.arc(cx, cy, 7, 0, Math.PI * 2);
            ctx.fillStyle = '#202020';
            ctx.fill();
        
            ctx.translate(cx, cy);
            ctx.rotate(angle);

            ctx.beginPath();
            ctx.moveTo(0, -5);
            ctx.lineTo(cy, 0);
            ctx.lineTo(0, 5);
            ctx.fillStyle = '#202020';
            ctx.fill();

            ctx.rotate(-angle);
            ctx.translate(-cx, -cy);

            ctx.beginPath();
            ctx.arc(cx, cy, 5, 0, Math.PI * 2);
            ctx.fillStyle = getComputedStyle(document.body).getPropertyValue('--icon-default-tertiary');
            ctx.fill();
            
            ctx.translate(cx, cy);
            ctx.rotate(angle);

            ctx.beginPath();
            ctx.moveTo(0, -3);
            ctx.lineTo(cy * 0.7, 0);
            ctx.lineTo(0, 3);
            ctx.fillStyle = getComputedStyle(document.body).getPropertyValue('--icon-default-tertiary');
            ctx.fill();

            ctx.rotate(-angle);
            ctx.translate(-cx, -cy);
        },
    }];

    constructor() { }

    ngOnChanges() {
        this.data.labels = this.labels;
        this.datasets.forEach(dataset => {
            this.data.datasets.push({
                data: dataset.data,
                borderWidth: 0,
                backgroundColor: this.colors.backgroundColor,
                hoverBackgroundColor: this.colors.hoverBackgroundColor
            });
        });
    }
}
