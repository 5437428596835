import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { Vendor } from '../services/vendor.resource';

interface ConfirmDeleteTokenDialogArgument {
    tokenHint: string;
    vendorToDelete: Vendor;
    tokenChanged: (v: Vendor) => void;
}

@Component({
    selector: 'confirm-delete-token-dialog',
    templateUrl: 'src/app/features/vendor-apis/templates/remove-token-dialog.html',
})
export class ConfirmDeleteTokenDialog {
    public captions: any;

    constructor(
        @Inject(CaptionService) private captionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfirmDeleteTokenDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDeleteTokenDialogArgument
    ) {
        this.captions = this.captionService.captions;
    }

    confirm() {
        this.data.tokenChanged(this.data.vendorToDelete);
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }
}
