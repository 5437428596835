import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { StoreBackupStatus } from '../../stores/models/store-backup.model';
import { ConceptApiService } from '../services/concept.resource';
import { Store } from '../../stores/models/store.model';

@Component({
    selector: 'eoiStatus',
    templateUrl: 'src/app/features/concepts/templates/backup-status.html'
})
export class BackupStatusComponent {
    private conceptID: number;
    private conceptName: string;
    private dateFormat: string = 'YYYY-MM-DD HH:mm Z z';

    public captions: Record<string, string>;
    public stores: StoreBackupStatus[] = [];
    public displayNameAscending: boolean = true;
    public statusAscending: boolean = true;
    public lastUpdatedAscending: boolean = true;
    public mobileViewActive: boolean = true;
    public showLegend: boolean = false;
    public localTimeActive: boolean = false;
    public showTimeConversion: boolean = false;

    @Input() searchStr: string;

    constructor(
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.actRoute.params.subscribe((params) => {
            this.conceptID = +params['conceptID'];
            this.conceptName = params['displayName'];
        });

        this.mobileViewActive = window.innerWidth < 960;
        this.showLegend = !this.mobileViewActive;
        window.onresize = () => {
            this.mobileViewActive = window.innerWidth < 960;
            if (!this.mobileViewActive) {
                this.showLegend = true;
            }
        };

        this.conceptApiService.getBackupStatusOfStores(this.conceptID).then((stores: StoreBackupStatus[]) => {
            this.stores = stores;
            let currDate = new Date();
            this.stores.forEach((store) => {
                if (!store.lastUpdated) {
                    return;
                }
                // Updated date format is yyyy-MM-dd HH:mm:ss, in UTC
                let date = (store.lastUpdated as string).split(/[- :]/);
                store.lastUpdated = {};
                // parse last updated date to UTC
                let parsedDate = new Date(
                    Date.UTC(
                        parseInt(date[0]),
                        parseInt(date[1]) - 1,
                        parseInt(date[2]),
                        parseInt(date[3]),
                        parseInt(date[4]),
                        parseInt(date[5])
                    )
                );
                let momentDate = moment(parsedDate, this.dateFormat);
                // calculate difference between now and last uploaded (in days)
                store.backupDaysElapsed = Math.round((currDate.getTime() - parsedDate.getTime()) / (1000 * 3600 * 24));
                if (store.timezone) {
                    store.lastUpdated.store = momentDate.tz(store.timezone).format(this.dateFormat);
                    this.showTimeConversion = true;
                }
                else{
                    store.lastUpdated.store = momentDate.format(this.dateFormat);
                    return;
                }
                store.lastUpdated.local = momentDate.tz(moment.tz.guess()).format(this.dateFormat);
            });
        });
    }

    changeOrder(sortType: string): void {
        if (sortType === 'displayName') {
            this.displayNameAscending = !this.displayNameAscending;
        } 
        else if (sortType === 'status') {
            this.statusAscending = !this.statusAscending;
        } 
        else if (sortType === 'lastUpdated') {
            this.lastUpdatedAscending = !this.lastUpdatedAscending;
        }
    }

    changedSearchText(searchStr: string): void {
        this.searchStr = searchStr;
    }

    filterStores(store: Store, searchStr: string) {
        return !searchStr || store.displayName.toLowerCase().includes(searchStr.toLowerCase());
    }

    goToStoreBackupPage(storeID: number, storeName: string): void {
        this.router.navigate(['stores/storeBackup', this.conceptID, this.conceptName, storeID, storeName]);
    }

    toggleLegendView(): void {
        this.showLegend = !this.showLegend;
    }

    toggleLocalTime(): void {
        this.localTimeActive = !this.localTimeActive;
    }
}
