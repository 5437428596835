import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'error',
    template: `
        <div class="h-full flex items-center justify-between mx-auto w-auto max-w-20">
            <div class="flex-col flex items-center justify-between">
                <h1>{{ captions.notFound404 }}</h1>
                <p>{{ captions.errMsg404 }}</p>
                <mat-icon class="icon404css">directions</mat-icon>
                <div class="w-full flex justify-around">
                    <button mat-button class="mat-stroked mat-rounded mr-2" onclick="window.history.back()">
                        {{ captions.back }}
                    </button>
                    <button mat-button class="mat-raised mat-rounded" (click)="navigate('home')">
                        {{ captions.home }}
                    </button>
                </div>
            </div>
        </div>
    `,
})
export class ErrorRedirectionComponent {
    public captions;

    constructor(@Inject(ActivatedRoute) private actRoute: ActivatedRoute, @Inject(Router) private router: Router) {}

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
    }
    navigate(state) {
        this.router.navigate([state]);
    }
}
