import { Component, Inject } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'confirm-delete-user-dialog',
    templateUrl: 'src/app/features/recycle-bin/components/dialogs/templates/no-item-selected.dialog.html',
})
export class NoItemSelectedDialog {
    public captions: any;

    constructor(@Inject(CaptionService) private captionService: CaptionService) {
        this.captions = this.captionService.captions;
    }
}
