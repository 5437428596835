import { Injectable } from '@angular/core';
import { EOIQuickStatus } from '../models/eoi.model';

@Injectable()
export class eoiService {
    constructor() {}

    /**
     *  @description gets an array of stores and their last time connected
     * @param storeInfo
     * @param storeOnline
     * @param posOffline
     * @param storeOffline
     * @param storeOffline1hr
     * @param storeNotConnected
     * @returns a json response with store status counts and stores
     */
    getStatusColor(
        storeInfo: Omit<EOIQuickStatus, "LastAliveFromStoreUTC" | "WebEmployeeValid">,
        storeOnline: number = 0,
        posOffline: number = 0,
        storeOffline: number = 0,
        storeOffline1hr: number = 0,
        storeNotConnected: number = 0,
        storeNoOrder: number = 0
    ) {
        storeInfo.statusColor = 'color-grey';
        if (
            storeInfo.EoiOnline == '1' &&
            storeInfo.PosOnline == '1' &&
            storeInfo.OkToOrder == '1' &&
            storeInfo.OpenDateValid == '1'
        ) {
            storeInfo.statusColor = 'color-green';
            storeOnline += 1;
        } else if (storeInfo.EoiOnline == '1' && storeInfo.PosOnline == '0') {
            storeInfo.statusColor = 'color-yellow';
            posOffline += 1;
        } else if (
            storeInfo.EoiOnline == 'x' &&
            storeInfo.PosOnline == 'x' &&
            storeInfo.OkToOrder == '0' &&
            storeInfo.OpenDateValid == 'x' &&
            storeInfo.StoreLocalDateTime == 'x'
        ) {
            storeInfo.statusColor = 'color-grey';
            storeNotConnected += 1;
        } else if (storeInfo.StoreLocalDateTime == 'x' || storeInfo.StoreLocalDateTime == '') {
            // if store has been disconnected for less than an hour
            if (storeInfo?.ElapsedTime < 1 * 60 * 60 * 1000) {
                storeInfo.statusColor = 'color-orange';
                storeOffline1hr += 1;
            }
            // if store has been disconnected for more than 1 hour
            else {
                storeInfo.statusColor = 'color-red';
                storeOffline += 1;
            }
        } else if (
            storeInfo.EoiOnline == '1' &&
            storeInfo.PosOnline == '1' &&
            storeInfo.OkToOrder == "0"
        ) {
            storeInfo.statusColor = 'color-lime';
            storeNoOrder += 1;
        }
        return {
            storeInfo,
            storeOnline,
            posOffline,
            storeOffline,
            storeOffline1hr,
            storeNotConnected,
            storeNoOrder
        };
    }

    /**
     * @description returns the color class given the inputs storeInfo
     * @param storeInfo
     * @returns color class
     */
    getQuickStatusColor(storeInfo: EOIQuickStatus): string {
        // calculate how long it has been since store disconnected and pass it to getStatusColor
        // backend is store in UTC time
        const now = new Date();

        let nowUTC: any = new Date(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds()
        );

        let lastSeen: any = new Date(storeInfo.LastAliveFromStoreUTC);
        storeInfo.ElapsedTime = nowUTC - lastSeen;
        let info = this.getStatusColor(storeInfo);
        return info.storeInfo.statusColor;
    }
}
