import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterPipe',
    pure: false
})
export class MyFilterPipe implements PipeTransform {
    transform(items: any[], callback: (item: any, searchArg?: any) => boolean, searchArg?: any): any {
        if (!items || !callback) {
            return items;
        }
        return items.filter(item => callback(item, searchArg));
    }
}
