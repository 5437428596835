export enum UserRole {
    Concept = 'CONCEPT_ACCESS',
    Global = 'GLOBAL_ACCESS',
    GroupItem = 'GROUP_ITEM_ACCESS',
    Tag = 'TAG_ACCESS',
    Store = 'STORE_ACCESS',
    // - Unused -
    // AllConcepts = 0,
    // AllStores = 0,
}

export enum UserManagement {
    Admin = 1,
}
