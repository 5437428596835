import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from "rxjs";
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { WebUserSecurityWithStoreList } from "../models/user-security.model";

@Injectable()

export class UserSecurityApiService {
    private allUsersSecurityUrl: string = 'webuser-security';
    private BASE_URL: string;

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getSelectedUserById(userId: number): Promise<WebUserSecurityWithStoreList> {
        let url = this.BASE_URL + this.allUsersSecurityUrl + '/' + userId;
        const data$ = this.http.get<WebUserSecurityWithStoreList>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getCurrentUserSecurity(): Promise<WebUserSecurityWithStoreList>{
        let url = this.BASE_URL + this.allUsersSecurityUrl;
        const data$ = this.http.get<WebUserSecurityWithStoreList>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateUserSecurity(user) {
        let url = this.BASE_URL + this.allUsersSecurityUrl + '/' + user.webUserID;
        let validWebUserSecurity = this.dataUtils.getCleanedObjectFields(user, [
            'conceptID',
            'groupItemID',
            'tagID',
            'storeID'
        ]) as any;
        validWebUserSecurity.conceptID = user.conceptID;
        validWebUserSecurity.groupItemID = user.groupItemID;
        validWebUserSecurity.tagID = user.tagID;
        validWebUserSecurity.storeID = user.storeID;
        const data$ = this.http.put(url, validWebUserSecurity)
        const value = await lastValueFrom(data$);
        return value;
    }
}
