import { Injectable } from '@angular/core';
import { delayWhen, filter, finalize, map, Observable, of, shareReplay, startWith, Subject, switchMapTo } from 'rxjs';

// reference: https://levahim.medium.com/serializing-single-use-observables-with-rxjs-ab6df2bc0c64

@Injectable()
export class SerializerService {
    private readonly serveNextTicket = new Subject<void>();
    private readonly currentTicket: Observable<number>;
    private nextTicket = 0;
    constructor() {
        this.currentTicket = this.serveNextTicket.pipe(
            startWith(0), // start with "showing" ticket number 0
            map((_, i) => i), // count "button" events, increment ticket number
            shareReplay(1) // multicast it for subscribers
        );
    }

    /**
     * @name queueUp
     * @description pass an observable to queueUp. The observable will wait until the queue in front of it is empty before running. (FIFO)
     * @param call an observable that issues a request
     * @returns an observable with the response
     */
    public queueUp<T>(call: Observable<T>): Observable<T> {
        // get the ticket at the "dispenser"
        return of(this.nextTicket++).pipe(
            // wait until the it is our turn (delays this observable until currentTicket === ticket)
            delayWhen((ticket) => this.currentTicket.pipe(filter((currentTicket) => currentTicket === ticket))),
            // proceed with our api call
            switchMapTo(
                call.pipe(
                    // get the next "ticket" and call to do the next api call that is queued
                    finalize(() => {
                        this.serveNextTicket.next();
                    })
                )
            )
        );
    }
}
