import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
    selector: 'showRedirectDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/show-redirect.html',
})
export class ShowRedirectDialog {
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ShowRedirectDialog>,
        @Inject(Router) public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
    }
    ngOnInit() { }

    closeDialog() {
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close();
        this.router.navigate([this.data.redirectTo]);
    }
} 