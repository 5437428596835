import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'app-loading-screen',
    templateUrl: 'src/app/features/store-portal/templates/dialogs/loading-screen-dialog.html'
})
export class LoadingScreenComponent implements OnInit {
    public captions: Record<string, string>;
    bars: { height: number; delay: number; color: string }[] = [];
    initialBarCount: number = 1; // Initial number of bars
    maxBarCount: number = 10;
    loadingInProgress: boolean = true; // Track if loading is in progress
    interval: any; // Store the interval so we can clear it later
    regenerate: boolean = false;

    constructor(
        @Inject(MatDialogRef) private dialogRef: MatDialogRef<LoadingScreenComponent>,
        @Inject(CaptionService) private captionService,) {
        this.captions = this.captionService.captions;
    }

    ngOnInit(): void {
        this.generateInitialBars();
        this.addBarAtIntervals();
    }

    generateInitialBars(): void {
        for (let i = 0; i < this.initialBarCount; i++) {
            this.bars.push(this.createBar(i));
        }
    }

    addBarAtIntervals(): void {
        let interval = setInterval(() => {
            if (this.bars.length < this.maxBarCount) {
                this.bars.push(this.createBar(this.bars.length));
            }
        }, 1250); // Add a bar every 1.25 seconds

        // Clear the interval after 15 seconds
        setTimeout(() => {
            clearInterval(interval);
            this.regenerate = true;
        }, 15000); // Total time is 15 seconds
    }

    createBar(index: number): { height: number; delay: number; color: string } {
        return {
            height: this.getRandomInt(20, 80), // Random height percentage between 20% and 80%
            delay: index * 0.2, // Initial delay for bar animation
            color: this.getRandomColor() // Assign a random color for the bar
        };
    }

    getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getRandomColor(): string {
        // Return a random color in hex format
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    startLoadingScreen(): void {
        this.bars = []; // Reset bars
        this.generateInitialBars();
        this.addBarAtIntervals();
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }

    cancelLoading(): void {
        clearInterval(this.interval); // Clear the interval to stop adding bars
        this.dialogRef.close(false);
        this.loadingInProgress = false; // Set loading to false to stop any further checks
        this.bars = []; // Optionally clear the bars from the screen
    }
}