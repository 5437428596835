import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreEOIStatus } from '../../eoi/models/eoi.model';
import { eoiService } from '../../eoi/services/eoi.service';
import { ConceptApiService } from '../services/concept.resource';
import { Store } from '../../stores/models/store.model';

@Component({
    selector: 'eoiStatus',
    templateUrl: 'src/app/features/concepts/templates/eoi-status.html',
})
export class EoiStatusComponent {
    private conceptID: number;
    private conceptName: string;

    public captions: Record<string, string>;
    public stores: StoreEOIStatus[] = [];
    public storeOnline: number;
    public posOffline: number;
    public storeOffline: number;
    public storeNoOrder: number;
    public storeOffline1hr: number;
    public storeNotConnected: number;
    public dpAscending: boolean = true;
    public statusAscending: boolean = true;
    public lastSeen: boolean = true;
    public mobileViewActive: boolean = true;
    public showLegend: boolean = false;

    @Input() searchStr: string;

    constructor(
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(eoiService) private eoiService: eoiService
    ) {
        this.conceptApiService = conceptApiService;
        this.storeOnline = 0;
        this.posOffline = 0;
        this.storeOffline = 0;
        this.storeOffline1hr = 0;
        this.storeNotConnected = 0;
        this.storeNoOrder = 0
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.actRoute.params.subscribe((params) => {
            this.conceptID = +params['conceptID'];
            this.conceptName = params['displayName'];
        });

        this.mobileViewActive = window.innerWidth < 1024;
        this.showLegend = !this.mobileViewActive;
        window.onresize = () => {
            this.mobileViewActive = window.innerWidth < 1024;
            if (!this.mobileViewActive) {
                this.showLegend = true;
            }
        };

        this.conceptApiService.getEoiStatusOfStores(this.conceptID).then((stores: StoreEOIStatus[]) => {
            this.stores = stores;
            for (let storeInfo of this.stores) {
                // match logic to eoi-detail if changes made
                let response = this.eoiService.getStatusColor(
                    storeInfo,
                    this.storeOnline,
                    this.posOffline,
                    this.storeOffline,
                    this.storeOffline1hr,
                    this.storeNotConnected,
                    this.storeNoOrder
                );

                this.storeOnline = response.storeOnline;
                this.posOffline = response.posOffline;
                this.storeOffline = response.storeOffline;
                this.storeOffline1hr = response.storeOffline1hr;
                this.storeNotConnected = response.storeNotConnected;
                this.storeNoOrder = response.storeNoOrder;
            }
        });
    }

    goToStore(storeID: number, storeName: string): void {
        this.router.navigate(['stores/eoiDetail', this.conceptID, this.conceptName, storeID, storeName]);
    }

    changeOrder(sortType: string): void {
        if (sortType == 'displayName') {
            this.dpAscending = !this.dpAscending;
        }
        else if (sortType == 'statusColor') {
            this.statusAscending = !this.statusAscending;
        }
        else if (sortType == 'date') {
            this.lastSeen = !this.lastSeen;
        }
    }

    changedSearchText(searchStr: string): void {
        this.searchStr = searchStr;
    }

    filterStoreInfo(store: StoreEOIStatus, searchStr: string) {
        return !searchStr || store.DisplayName.toLowerCase().includes(searchStr.toLowerCase());
    }

    toggleLegendView(): void {
        this.showLegend = !this.showLegend;
    }

    formatLastConnectionTime(time: number | 'x', statusColor: string): string {
        // Add type check because time could be 'x' if store has no ElapsedTime
        if (typeof time === "number") {
            let savedTime = time;
            time /= 1000;
            if (statusColor === 'color-green' || statusColor === 'color-yellow' || statusColor === 'color-grey' || statusColor === 'color-lime') {
                return '';
            }
            else if (time < 60) {
                return Math.floor(time) + ' ' + this.captions.secondsAgo;
            }
            else if (time < 60 * 60) {
                return Math.floor(time / 60) + ' ' + this.captions.minutesAgo;
            }
            else if (time < 24 * 60 * 60) {
                return Math.floor(time / 3600) + ' ' + this.captions.hoursAgo;
            }
            else if (time < 7 * 24 * 60 * 60) {
                return Math.floor(time / 24 / 3600) + ' ' + this.captions.daysAgo;
            }
            else {
                let returnDate = new Date(new Date().getTime() - savedTime);
                return returnDate.toString() == 'Invalid Date' ? '' : returnDate.toLocaleDateString();
            }
        }
    }
}
