import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from "rxjs";
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { StoreEOIStatus } from "../../eoi/models/eoi.model";
import { StoreBackupStatus } from "../../stores/models/store-backup.model";
import { Concept, ConceptAdd } from "../models/concept.model";
import { EnableHOPortal } from "../models/enable-ho-portal.model";

@Injectable()
export class ConceptApiService {
    private allConceptsUrl: string = 'concepts';
    private conceptByIdUrl: string = 'concepts/{conceptID}';
    private validateDeletionUrl: string = 'concepts/validateDeletion';
    private conceptTimezones: string = 'concepts/timeZones';
    private eoiStatusUrl: string = 'eoi/concept/{conceptID}';
    private backupStatusUrl: string = 'storebackup/allDetails/{conceptID}';
    private conceptPortalURL : string = 'enableConceptPortal/{conceptID}';
    private enableConceptPortalUrl: string = 'enableConceptPortal/{conceptID}/{storeID}';
    private disableConceptPortalUrl: string = 'enableConceptPortal/disable/{conceptID}';

    private BASE_URL: string;

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getAllActiveConcepts(): Promise<Concept[]> {
        let url = this.BASE_URL + this.allConceptsUrl;
        let data$ = this.http.get<Concept[]>(url, {
            params: {
                isActive: 'true',
            }
        })
        const value = await lastValueFrom(data$);
        return value;
    }

    async getConceptById(conceptID: number): Promise<Concept> {
        let conceptByIdUrlValid = this.conceptByIdUrl.replace('{conceptID}', conceptID.toString());
        let url = this.BASE_URL + conceptByIdUrlValid;
        const data$ = this.http.get<Concept>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateConcept(concept: Concept): Promise<void> {
        let updateConceptUrlValid = this.conceptByIdUrl.replace('{conceptID}', concept.id.toString());
        let url = this.BASE_URL + updateConceptUrlValid;
        let validConcept = this.dataUtils.getCleanedObjectFields(concept, [
            'displayName',
            'webBgColor',
            'currencyCode',
            'dayOfWeek',
            'weekOfYear',
            'localTimeZone'
        ]);
        const data$ = this.http.put<void>(url, validConcept);
        const value = await lastValueFrom(data$);
        return value; // Return NO CONTENT on success
    }

    async addConcept(concept: ConceptAdd): Promise<Concept> {
        let url = this.BASE_URL + this.allConceptsUrl;
        const data$ = this.http.post<Concept>(url, concept);
        const value = await lastValueFrom(data$);
        return value;
    }

    async validateConceptDeletion(conceptIds: number[]): Promise<Concept[]> {
        let url = this.BASE_URL + this.validateDeletionUrl;
        if (typeof conceptIds !== 'object') return Promise.reject('Invalid Request');

        const data$ = this.http.post<Concept[]>(url, conceptIds);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deactivateConcepts(conceptIds: number[]): Promise<void> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: conceptIds
        };
        let url = this.BASE_URL + this.allConceptsUrl;
        if (typeof conceptIds !== 'object') return Promise.reject('Invalid Request');

        const data$ = this.http.delete<void>(url, httpOptions);
        const value = await lastValueFrom(data$);
        return value; // Returns NO CONTENT on success
    }

    async hasData(conceptID: number): Promise<boolean> {
        let url = this.BASE_URL + 'concepts/dataCheck?conceptID=' + conceptID;
        const data$ = this.http.get<boolean>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getTimeZones(): Promise<string[]> {
        let url = this.BASE_URL + this.conceptTimezones;
        const data$ = this.http.get<string[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getEoiStatusOfStores(conceptID: number): Promise<StoreEOIStatus[]> {
        let url = this.BASE_URL + this.eoiStatusUrl;
        let urlValid = url.replace('{conceptID}', conceptID.toString());
        const data$ = this.http.get<StoreEOIStatus[]>(urlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getBackupStatusOfStores(conceptID: number): Promise<StoreBackupStatus[]> {
        let url = this.BASE_URL + this.backupStatusUrl;
        let urlValid = url.replace('{conceptID}', conceptID.toString());
        const data$ = this.http.get<StoreBackupStatus[]>(urlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    // get enable store portal for provided store id
    async getEnableConceptPortal(conceptID: number): Promise<EnableHOPortal> {
        let enableConceptPortalURLValid = this.conceptPortalURL.replace('{conceptID}', conceptID.toString());
        let url = this.BASE_URL + enableConceptPortalURLValid;
        const data$ = this.http.get<EnableHOPortal>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    // enables concept portal and assigns a random string to the path
    // currently unused in favor of enableOrUpdateconceptPortal()
    async enableConceptPortal(conceptID: number, primaryStore: number): Promise<EnableHOPortal> {
        let url = this.BASE_URL + this.enableConceptPortalUrl
            .replace('{conceptID}', conceptID.toString())
            .replace('{storeID}', primaryStore.toString());
        const data$ = this.http.post<EnableHOPortal>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    // disables store portal and assigns a random string to the path
    async disableConceptPortal(conceptID: number): Promise<EnableHOPortal> {
        let disableconceptPortalURLValid = this.disableConceptPortalUrl.replace('{conceptID}', conceptID.toString());
        let url = this.BASE_URL + disableconceptPortalURLValid;
        const data$ = this.http.put<EnableHOPortal>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }
}
