import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../../app.constants';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { StoreApiService } from '../services/store.resource';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { StoreAdd } from '../models/store.model';
import { AddressAdd } from '../models/address.model';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'addStore',
    templateUrl: 'src/app/features/stores/templates/add-store.html',
})
export class AddStoreComponent {
    private currentUser: any;
    private formBuilder: FormBuilder = new FormBuilder();

    protected _onDestroy = new Subject<void>();

    public form = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(this.validationService.storeDescMaxLength)]],
        currency: ['', [Validators.required]],
        country: ['', [Validators.required]],
        billType: ['', [Validators.required]],
        timezone: ['', []],
        storeNumber: ['', [Validators.maxLength(this.validationService.storeDescMaxLength)]],
        line1: ['', [Validators.maxLength(this.validationService.storeLineCityMaxLength)]],
        line2: ['', [Validators.maxLength(this.validationService.storeLineCityMaxLength)]],
        city: ['', [Validators.maxLength(this.validationService.storeLineCityMaxLength)]],
        state: ['', [Validators.maxLength(this.validationService.storeDescMaxLength)]],
        stateDropdown: ['', []],
        code: ['', [Validators.maxLength(this.validationService.storeDescMaxLength)]],
        phone: ['', [Validators.maxLength(this.validationService.storeDescMaxLength)]],
    });

    public conceptID: number;
    public store: StoreAdd;
    public billTypes: string[];
    public timeZones: string[];
    public conceptLocalTimezone: string;
    public states: string[];
    public countries: string[];
    public showStateDropdown: boolean = false;
    public countriesWithStates = ['USA', 'Canada'];
    public webBgColor = '#808080';
    public conceptName: string;
    public filteredTimeZones: ReplaySubject<any> = new ReplaySubject<any>(1);
    public timeZonesCtrl: FormControl<string> = new FormControl<string>('');

    @Input() public captions: Record<string, string>;
    @Input() public currencyCodes: string[];

    constructor(
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.currencyCodes = AppConstants.CURRENCYCODES_NG;
        this.billTypes = AppConstants.BILLTYPES_NG;
        this.currentUser = userAccessHandler.getUserAccess();
        this.getTimeZones();
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });

        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['pathId'];
            this.conceptName = params['pathName'];
        });

        this.utilsApiService.getCountryList().then(countries => {
            this.countries = countries.countries;
        });

        if (typeof (this.itemStorageService.conceptLocalTimezone) === 'undefined') {
            this.conceptLocalTimezone = this.captions.utc;
        }
        else {
            this.conceptLocalTimezone = this.itemStorageService.conceptLocalTimezone;
        }

        this.getTimeZones();

        // listen for search field value changes
        this.timeZonesCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterTimezones();
        });
    }

    countryChanged(country: string): void {
        if (this.countriesWithStates.indexOf(country) >= 0) {
            this.utilsApiService.getStates(country).then(states => {
                this.states = states.states;
                this.showStateDropdown = true;
            });
        } else {
            this.showStateDropdown = false;
        }
    }

    saveChanges(): void {
        const values = this.form.value;
        
        let address: AddressAdd = {
            line1: values.line1,
            line2: values.line2,
            city: values.city,
            state: this.showStateDropdown ? values.stateDropdown : values.state,
            code: values.code,
            country: values.country
        };

        this.store = {
            displayName: values.name,
            webBgColor: this.webBgColor,
            currencyCode: values.currency,
            billType: values.billType,
            phone1: values.phone,
            localTimeZone: values.timezone,
            corpStoreNum: values.storeNumber,
            conceptID: this.conceptID,
            address: address,
        };

        let trigger = false;
        this.storeApiService.getStoresByConceptId(this.conceptID).then(stores => {
            for (let store of stores) {
                // displayName.toUpperCase is used in order to ignore case and any special Unicode characters
                if (store.displayName.toUpperCase() === this.store.displayName.toUpperCase()) {
                    trigger = true;
                    this.snackbarService.errorMessageTop(this.captions.duplicateStoreName);
                    return;
                }
            }
            if (!trigger) {
                this.storeApiService.addStore(this.store, this.conceptID).then(() => {
                    let newStoreCount = this.currentUser.storeCount + 1;
                    this.userAccessHandler.setStoreCount(newStoreCount);
                    this.router.navigate(['stores/allStores', this.store.conceptID, this.conceptName]);
                });
            }
        });
    }

    cancelChanges(): void {
        this.router.navigate(['stores/allStores', this.conceptID, this.conceptName]);
    }

    colorSelected(color: string): void {
        this.webBgColor = color;
    }

    private getTimeZones(): void {
        this.storeApiService.getTimeZones().then((result) => {
            this.timeZones = result;
            this.filteredTimeZones.next(this.timeZones.slice());
        });
    }

    protected filterTimezones(): void {
        if (!this.timeZones) {
            return;
        }
        // get the search keyword
        let search = this.timeZonesCtrl.value;
        if (!search) {
            this.filteredTimeZones.next(this.timeZones.slice());
            return;
        } 
        else {
            search = search.toLowerCase();
        }
        // filter the time-zones
        this.filteredTimeZones.next(
            this.timeZones.filter(zone => zone.toLowerCase().indexOf(search) > -1)
        );
    }

}
