import { Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { UserAccessHandler } from '../services/user-access-handler.service';
import { UserApiService } from '../services/users.resource';
import { UserConstants } from '../users.constants';
import { ConfirmDeleteUserDialog } from './confirm-delete-user-dialog.component';
import { ManageWebUsersGet } from '../models/user.model';

@Component({
    selector: 'allUsers',
    templateUrl: 'src/app/features/users/templates/all-users.html',
})
export class AllUsersComponent {
    public userInfoPredicate: string = 'firstName';
    public columnAscOrDesc = 1;
    public userInfoReverse: boolean = true;
    public storeUser: boolean = false;
    public captions;
    public userInfo;
    public email;
    public cuAccessType;
    public cuAccessTo;
    public usersToShow = [];
    public user;

    private currentUser = {
        showGroupItemIcon: true,
        showTagIcon: true,
        id: '',
    };
    private userSortColumn;
    private currentUserSecurity;
    private USER_ACCESS;

    @Input() public searchStr: any;

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(BrowserStorage) private browserStorage,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(UserAccessHandler) private userAccessHandler,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute
    ) {
        this.userSortColumn = this.browserStorage.getSessionstorage('usersSortColumn');
        this.currentUserSecurity = this.userAccessHandler.getUserAccess();
        this.USER_ACCESS = UserConstants.USER_ACCESS;
    }

    ngOnInit() {
        // clear the old newUserForm so that it doesn't use old data from previous sessions
        this.browserStorage.removeSessionstorageItem('newUserForm');

        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.utilsApiService.getUserInfo().then((userInfo: any) => {
            this.userInfo = userInfo;
            this.email = userInfo.email;
        });
        this.currentUser = this.currentUserSecurity;
        if (this.currentUserSecurity.role === this.USER_ACCESS.GROUP_ITEM) {
            this.currentUser.showGroupItemIcon = true;
            this.currentUser.showTagIcon = false;
        } else if (this.currentUserSecurity.role === this.USER_ACCESS.TAG) {
            this.currentUser.showGroupItemIcon = false;
            this.currentUser.showTagIcon = true;
        }
        this.getAllUsers();
    }

    getAllUsers() {
        this.userApiService.getUsers().then((users: any) => {
            for (let user of users) {
                if (user.id === this.currentUserSecurity.webUserID) {
                    if (user.role === this.USER_ACCESS.GLOBAL) {
                        this.cuAccessType = this.captions.fullAccess;
                        this.cuAccessTo = this.captions.allStores;
                    } else if (user.role === this.USER_ACCESS.CONCEPT) {
                        this.cuAccessType = this.captions.organization;
                        this.cuAccessTo = user.conceptName;
                    } else if (user.role === this.USER_ACCESS.GROUP_ITEM) {
                        this.cuAccessType = this.captions.groupItem;
                        this.cuAccessTo = user.groupItemName;
                    } else if (user.role === this.USER_ACCESS.TAG) {
                        this.cuAccessType = this.captions.tag;
                        this.cuAccessTo = user.tagName;
                    } else {
                        this.cuAccessType = this.captions.store;
                        this.cuAccessTo = user.storeName;
                    }
                } else {
                    user.storeUser = false;
                    user.globalAccess = false;
                    this.user = user;
                    this.usersToShow.push(user);
                    if (user.role === this.USER_ACCESS.GLOBAL) {
                        user.globalAccess = true;
                        user.accessType = this.captions.fullAccess;
                        user.accessTo = this.captions.allStores;
                    } else if (user.role === this.USER_ACCESS.CONCEPT) {
                        user.accessType = this.captions.organization;
                        user.accessTo = user.conceptName;
                    } else if (user.role === this.USER_ACCESS.GROUP_ITEM) {
                        user.accessType = this.captions.groupItem;
                        user.accessTo = user.groupItemName;
                    } else if (user.role === this.USER_ACCESS.TAG) {
                        user.accessType = this.captions.tag;
                        user.accessTo = user.tagName;
                    } else {
                        user.storeUser = true;
                        user.accessType = this.captions.storeList;
                        user.accessTo = user.storeName;
                    }
                }
                if (this.userSortColumn !== undefined)
                    this.setSavedOrder(this.userSortColumn.predicate, this.userSortColumn.columnAscOrDesc);
            }
        });
    }

    setSavedOrder(predicate, columnAscOrDesc) {
        this.userInfoReverse = columnAscOrDesc !== 0;
        this.userInfoPredicate = predicate;
        this.columnAscOrDesc = columnAscOrDesc;
    }

    changeOrder(predicate) {
        if (this.userInfoPredicate !== predicate) this.columnAscOrDesc = 0;
        else {
            if (this.columnAscOrDesc === 0) this.columnAscOrDesc = 1;
            else this.columnAscOrDesc = 0;
        }

        this.userInfoReverse = this.userInfoPredicate === predicate ? !this.userInfoReverse : false;
        this.userInfoPredicate = predicate;
    }

    editUser(user) {
        let userSortColumn = {
            predicate: this.userInfoPredicate,
            columnAscOrDesc: this.columnAscOrDesc,
        };
        this.browserStorage.setSessionstorage('usersSortColumn', userSortColumn);
        this.router.navigate(['users/editUser', user.userID]);
    }

    addUser() {
        let userSortColumn = {
            predicate: this.userInfoPredicate,
            columnAscOrDesc: this.columnAscOrDesc,
        };
        this.browserStorage.setSessionstorage('usersSortColumn', userSortColumn);
        this.router.navigate(['users/addUser']);
    }

    deleteUser(user) {
        this.currentUser = user;
        const dialogRef = this.dialog.open(ConfirmDeleteUserDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                currentUser: this.currentUser,
                userInfoPredicate: this.userInfoPredicate,
                columnAscOrDesc: this.columnAscOrDesc,
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                // clear previous usersToShow array to prevent duplication when delete confirmed
                this.usersToShow = [];
                this.getAllUsers();

                // retain sort order after usersToShow array refresh
                let currentPredicate = this.browserStorage.getSessionstorage('usersSortColumn', this.userSortColumn);
                this.changeOrder(currentPredicate);
            }
        });
    }

    changedSearchText(searchStr: string) {
        this.searchStr = searchStr;
    }

    filterUsers(user: ManageWebUsersGet, searchStr: string) {
        return !searchStr || user.firstName.toLowerCase().includes(searchStr.toLowerCase()) 
        || user.lastName.toLowerCase().includes(searchStr.toLowerCase()) 
        || user.email.toLowerCase().includes(searchStr.toLowerCase());
    }
}
