import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-report-tooltip',
    templateUrl: 'src/app/features/reports/templates/dialogs/report-tooltip.component.html',
})
export class ReportTooltipComponent implements OnInit {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ReportTooltipComponent,
        @Inject(MatDialogRef) public dialog: MatDialogRef<ReportTooltipComponent>
    ) {}

    ngOnInit() {}

    onClose() {
        this.dialog.close();
    }
}
