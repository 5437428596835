import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { ItemStorageService } from '../../../../utils/shared-services/item-storage.service';
import { DashboardEmitterService } from "../../services/dashboard-emitter.service";

@Component({
    selector: 'configureDateSelection',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/date-range-selection.tpl.html',
})
export class ConfigureDateRangeSelectionDialog {
    private formBuilder: FormBuilder = new FormBuilder();

    public form = this.formBuilder.group({
        selectedStartDate: [new Date(), [Validators.required]],
        selectedEndDate: [new Date(), [Validators.required]]
    });

    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfigureDateRangeSelectionDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(DashboardEmitterService) private dashboardEmitterService: DashboardEmitterService
    ) {
        this.captions = this.captionService.captions;

        const startDate = this.itemStorageService.currentTab.startDate;
        const endDate = this.itemStorageService.currentTab.endDate;

        if (startDate && endDate) {
            this.form.setValue({
                selectedStartDate: startDate,
                selectedEndDate: endDate
            })
        }
    }

    ngOnInit(): void {
        this.form.controls.selectedStartDate.markAsTouched();
        this.form.controls.selectedEndDate.markAsTouched();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    submitDate(): void {
        let selectedStartDate = new Date(this.form.get("selectedStartDate").value);
        let selectedEndDate = new Date(this.form.get("selectedEndDate").value);
        this.itemStorageService.currentTab.startDate = selectedStartDate
        this.itemStorageService.currentTab.endDate = selectedEndDate
        this.dashboardEmitterService.selectedDateRange(selectedStartDate, selectedEndDate);
        this.dialogRef.close(true);
    }
}
