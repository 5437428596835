import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { UserRole } from '../enums/user-role.enum';
import { Privileges } from '../models/user-access.model';
import { UserConstants } from '../users.constants';

@Injectable()

export class UserApiService {
    private BASE_URL: any;
    private USER_ACCESS: any;
    private USER_PRIVILEGES: any;
    private allUsersUrl: string;
    private favouriteTabUrl: string;
    private privilegesUrl: string;
    private inheritPrivilegesUrl: string;
    private subscriptionsUrl: string;
    private notificationConsentUrl: string;

    constructor(@Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils) {
        this.BASE_URL = AppConstants.BASE_URL;
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.USER_PRIVILEGES = UserConstants.USER_PRIVILEGES;
        this.allUsersUrl = 'webusers';
        this.favouriteTabUrl = this.allUsersUrl + '/favouriteTabID';
        this.privilegesUrl = this.allUsersUrl + '/privileges';
        this.inheritPrivilegesUrl = this.privilegesUrl + '/{webUserID}/inherit';
        (this.subscriptionsUrl = this.allUsersUrl + '/subscriptions'),
            (this.notificationConsentUrl = this.subscriptionsUrl + '/consent');
    }

    async addUser(user) {
        let url = this.BASE_URL + this.allUsersUrl;
        let userToSubmit = this.dataUtils.getCleanedObjectFields(user, [
            'email',
            'firstName',
            'lastName',
            'language',
            'password',
            'userManagement',
            'resetPassOnLogin',
            'userPrivilege',
            'access',
        ]) as any;
        userToSubmit.language = user.language;
        const data$ = this.http.post(url, userToSubmit)
        const value = await lastValueFrom(data$);
        return value;
    }

    async getUsers() {
        let url = this.BASE_URL + this.allUsersUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getUsersToShare() {
        let url = this.BASE_URL + this.allUsersUrl + '/share';
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteUser(userId) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: userId,
        };
        let url = this.BASE_URL + this.allUsersUrl + '/' + userId;
        const data$ = this.http.delete(url, httpOptions)
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateUser(user, language) {
        let userToSubmit = this.dataUtils.getCleanedObjectFields(user, [
            'email',
            'firstName',
            'lastName',
            'language',
            'userManagement',
            'resetPassOnLogin',
        ]) as any;
        userToSubmit.language = language;
        let url = this.BASE_URL + this.allUsersUrl + '/' + user.id;
        const data$ = this.http.put(url, userToSubmit);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getUserById(userId) {
        let url = this.BASE_URL + this.allUsersUrl + '/' + userId;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateFavouriteTab(user, favouriteTabID) {
        let userToSubmit = this.dataUtils.getCleanedObjectFields(user, ['favouriteTabID']) as any;
        userToSubmit.favouriteTabID = favouriteTabID;
        let url = this.BASE_URL + this.favouriteTabUrl;
        const data$ = this.http.put(url, userToSubmit);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getUserPrivileges(user: {role: UserRole}): Promise<Privileges> {
        let url = '';
        if (user.role === this.USER_ACCESS.GLOBAL) url = this.USER_PRIVILEGES.adminUrl;
        else if (user.role === this.USER_ACCESS.CONCEPT) url = this.USER_PRIVILEGES.conceptUrl;
        else if (user.role === this.USER_ACCESS.GROUP_ITEM) url = this.USER_PRIVILEGES.groupItemUrl;
        else if (user.role === this.USER_ACCESS.TAG) url = this.USER_PRIVILEGES.tagUrl;
        else url = this.USER_PRIVILEGES.storeUrl;
        const data$ = this.http.get<Privileges>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateUserPrivileges(userPrivileges, webUserID) {
        let url = this.BASE_URL + this.privilegesUrl + '/' + webUserID;
        const data$ = this.http.put(url, userPrivileges);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getUsersForPrivilegeInheritance(userId) {
        let inheritPrivilegesUrlValid = this.inheritPrivilegesUrl.replace('{webUserID}', userId);
        const data$ = this.http.get(this.BASE_URL + inheritPrivilegesUrlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateNotificationConsent(notificationConsent) {
        if (typeof notificationConsent === 'boolean') notificationConsent = notificationConsent === true ? 1 : 0;
        let url = this.BASE_URL + this.notificationConsentUrl;
        const data$ = this.http.put(url, notificationConsent);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getSubscriptionsPerAccount() {
        const data$ = this.http.get(this.BASE_URL + this.subscriptionsUrl);
        const value = await lastValueFrom(data$);
        return value;
    }

    async addSubscription(sub) {
        const data$ = this.http.post(this.BASE_URL + this.subscriptionsUrl, sub);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateSubscriptions(subToRemove) {
        const data$ = this.http.put(this.BASE_URL + this.subscriptionsUrl, subToRemove);
        const value = await lastValueFrom(data$);
        return value;
    }
}
