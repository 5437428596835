export class UserConstants {
    public static readonly USER_ACCESS = {
        ALL_CONCEPTS: 0,
        ALL_GROUP_ITEMS: 0,
        ALL_STORES: 0,
        GLOBAL: 'GLOBAL_ACCESS',
        CONCEPT: 'CONCEPT_ACCESS',
        GROUP_ITEM: 'GROUP_ITEM_ACCESS',
        TAG: 'TAG_ACCESS',
        STORE: 'STORE_ACCESS',
        ADMIN: 1,
    };
    public static readonly USER_PRIVILEGES = {
        adminUrl: '../data/adminUserPrivileges.json',
        conceptUrl: '../data/conceptUserPrivileges.json',
        groupItemUrl: '../data/groupItemUserPrivileges.json',
        tagUrl: '../data/tagUserPrivileges.json',
        storeUrl: '../data/storeUserPrivileges.json',
        ORGANIZATIONS: 'Organizations',
        STORES: 'Stores',
        ORG_TYPE: 'ORGANIZATIONS',
        STORE_TYPE: 'STORES',
        MANAGE_ORGANIZATIONS: 'MANAGE_ORGANIZATIONS',
        MANAGE_COUPONS: 'MANAGE_COUPONS',
        MANAGE_STORE_TAGS: 'MANAGE_TAGS',
        MANAGE_STORE_GROUPS_AND_STORE_GROUP_SELECTIONS: 'MANAGE_STORE_GROUPS_AND_STORE_GROUP_SELECTIONS',
        MANAGE_STORES: 'MANAGE_STORES',
        ASSIGN_STORE_GROUP_SELECTIONS: 'ASSIGN_STORE_GROUP_SELECTIONS',
        ASSIGN_STORE_TAGS: 'ASSIGN_STORE_TAGS',
        MANAGE_REPORT_API: 'MANAGE_REPORT_API',
        MANAGE_EOI_API: 'MANAGE_EOI_API',
        MANAGE_DATA_EXTRACTION: 'MANAGE_DATA_EXTRACTION',
        VIEW_SHARED_DASH_ONLY: 'VIEW_SHARED_DASH_ONLY',
        STORE_PORTAL_ONLY: 'STORE_PORTAL_ONLY',
        PORTAL_ADMIN: 'STORE_PORTAL_ADMIN_ONLY',
        PORTAL_USER: 'STORE_PORTAL_USER_ONLY',
        ALL_PRIVILEGES: '[]',
    };
}
