import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataExchangeApiService } from '../services/data-exchange.resource';
import { RFTable, ReplicationTask } from '../models/replication-task.model';
import { ConceptCollection, DataExchangeInfo, StoreCollection } from '../models/data-exchange-info.model';

@Component({
    selector: 'exchangeData',
    templateUrl: 'src/app/features/data-exchange/templates/data-exchange.html',
})
export class DataExchangeComponent {
    public conceptCollectionsInfoPredicate: string = 'CloudDate';
    public conceptCollectionsInfoReverse: boolean = true;
    public conceptCollectionsColumnAscOrDesc: number = 1;
    public storeCollectionsInfoPredicate: string = 'CloudDate';
    public storeCollectionsInfoReverse: boolean = true;
    public storeCollectionsColumnAscOrDesc: number = 1;
    public storeDirectUploadsInfoPredicate: string = 'tableName';
    public storeDirectUploadsInfoReverse: boolean = true;
    public storeDirectUploadsColumnAscOrDesc: number = 1;
    public conceptCollections: ConceptCollection[] = [];
    public conceptSuccessCount: number = 0;
    public conceptCollectionCount: number = 0;
    public storeCollections: StoreCollection[] = [];
    public storeSuccessCount: number = 0;
    public storeCollectionCount: number = 0;
    public globalSuccessCount: number = 0;
    public globalCollectionCount: number = 0;
    public storeDirectUploads: RFTable[] = [];
    public storeDirectUploadsSummary: RFTable;
    public storeReplicationTaskLevel: string;
    public storeOpenDate: string;
    public latestOpenDateUpdate: string;
    public globalStatus: string;
    public conceptStatus: string;
    public storeStatus: string;
    public captions: Record<string, string>;
    private conceptID: number;
    private storeID: number;
    public storeName: string;
    
    @Input() public searchStr: string;

    constructor(
        @Inject(DataExchangeApiService) private dataExchangeApiService: DataExchangeApiService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
    ) {
        this.conceptStatus = 'LOADING';
        this.storeStatus = 'LOADING';
        this.globalStatus = 'LOADING';
        this.storeReplicationTaskLevel = 'LOADING';
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['pathId'];
            this.storeID = +params['storeID'];
            this.storeName = params['pathName'];
        });
        this.setupCollections();
        this.setupDirectData();
    }

    setupDirectData(): void {
        this.dataExchangeApiService
            .getAllDirectUploads(this.conceptID, this.storeID)
            .then((ddInfo: ReplicationTask) => {
                this.storeDirectUploads = ddInfo.rfTables;
                this.storeDirectUploadsSummary = ddInfo.totalSummary;
                this.storeReplicationTaskLevel = ddInfo.replicationTaskLevel;
            });
    }

    setupCollections(): void {
        this.dataExchangeApiService
            .getAllDataExchanges(this.conceptID, this.storeID)
            .then((deInfo: DataExchangeInfo) => {
                let conceptSuccessCount = 0;
                let storeSuccessCount = 0;
                let conceptCollections = deInfo.conceptCollections;
                let storeCollections = deInfo.storeCollections;

                let conceptCollectionCount = 0;
                conceptCollectionCount += conceptCollections.length;
                let storeCollectionCount = 0;
                storeCollectionCount += storeCollections.length;

                if (deInfo.openDate === null || deInfo.openDate === undefined) {
                    this.storeOpenDate = 'N/A';
                } else {
                    this.storeOpenDate = deInfo.openDate.split(' ')[0];
                }

                if (deInfo === null || deInfo == undefined) {
                    this.globalStatus = 'FAILED';
                    this.storeStatus = 'FAILED';
                    this.conceptStatus = 'FAILED';
                } else {
                    if (
                        deInfo.conceptCollections === null ||
                        deInfo.conceptCollections === undefined ||
                        conceptCollectionCount === 0
                    ) {
                        this.conceptStatus = 'PENDING';
                    } else {
                        // VERIFY CONCEPT COLLECTIONS' STATUSES
                        for (let i = 0; i < conceptCollectionCount; i++) {
                            if (conceptCollections[i].apiName === 'StoreOpenDate') {
                                this.latestOpenDateUpdate = conceptCollections[i].cloudWhenAddedUTC;
                            }

                            if (conceptCollections[i].status === 'Success') {
                                conceptSuccessCount++;
                            } else if (
                                conceptCollections[i].status === null ||
                                conceptCollections[i].status === undefined
                            ) {
                                conceptCollections[i].status = 'N/A';
                            }
                        }

                        switch (conceptSuccessCount) {
                            case 0:
                                this.conceptStatus = 'FAILED';
                                break;
                            case conceptCollectionCount:
                                this.conceptStatus = 'SUCCESS';
                                break;
                            default:
                                this.conceptStatus = 'ATTENTION';
                        }
                    }

                    if (
                        deInfo.storeCollections === null ||
                        deInfo.storeCollections === undefined ||
                        storeCollectionCount === 0
                    ) {
                        this.storeStatus = 'PENDING';
                    } else {
                        // VERIFY STORE COLLECTIONS' STATUSES
                        for (let i = 0; i < storeCollectionCount; i++) {
                            if (storeCollections[i].status === 'Success') {
                                storeSuccessCount++;
                            } else if (
                                storeCollections[i].status === null ||
                                storeCollections[i].status === undefined
                            ) {
                                storeCollections[i].status = 'N/A';
                            }
                        }

                        switch (storeSuccessCount) {
                            case 0:
                                this.storeStatus = 'FAILED';
                                break;
                            case storeCollectionCount:
                                this.storeStatus = 'SUCCESS';
                                break;
                            default:
                                this.storeStatus = 'ATTENTION';
                        }
                    }
                }

                // check both statuses for global status
                if (this.storeStatus === 'SUCCESS' && this.conceptStatus === 'SUCCESS') {
                    this.globalStatus = 'SUCCESS';
                } else if (this.storeStatus === 'FAILED' && this.conceptStatus === 'FAILED') {
                    this.globalStatus = 'FAILED';
                } else if (this.storeStatus === 'PENDING' && this.conceptStatus === 'PENDING') {
                    this.globalStatus = 'PENDING';
                } else {
                    this.globalStatus = 'ATTENTION';
                }

                this.conceptCollections = conceptCollections;
                this.conceptSuccessCount = conceptSuccessCount;
                this.conceptCollectionCount = conceptCollectionCount;

                this.storeCollections = storeCollections;
                this.storeSuccessCount = storeSuccessCount;
                this.storeCollectionCount = storeCollectionCount;

                this.globalSuccessCount = storeSuccessCount + conceptSuccessCount;
                this.globalCollectionCount = storeCollectionCount + conceptCollectionCount;
            });
    }

    changeOrder(table: string, predicate: string): void {
        switch (table) {
            case 'concept':
                if (this.conceptCollectionsInfoPredicate !== predicate) this.conceptCollectionsColumnAscOrDesc = 0;
                else {
                    if (this.conceptCollectionsColumnAscOrDesc === 0) this.conceptCollectionsColumnAscOrDesc = 1;
                    else this.conceptCollectionsColumnAscOrDesc = 0;
                }

                this.conceptCollectionsInfoReverse =
                    this.conceptCollectionsInfoPredicate === predicate ? !this.conceptCollectionsInfoReverse : false;
                this.conceptCollectionsInfoPredicate = predicate;
                break;

            case 'store':
                if (this.storeCollectionsInfoPredicate !== predicate) this.storeCollectionsColumnAscOrDesc = 0;
                else {
                    if (this.storeCollectionsColumnAscOrDesc === 0) this.storeCollectionsColumnAscOrDesc = 1;
                    else this.storeCollectionsColumnAscOrDesc = 0;
                }

                this.storeCollectionsInfoReverse =
                    this.storeCollectionsInfoPredicate === predicate ? !this.storeCollectionsInfoReverse : false;
                this.storeCollectionsInfoPredicate = predicate;
                break;

            case 'store-directup':
                if (this.storeDirectUploadsInfoPredicate !== predicate) this.storeDirectUploadsColumnAscOrDesc = 0;
                else {
                    if (this.storeDirectUploadsColumnAscOrDesc === 0) this.storeDirectUploadsColumnAscOrDesc = 1;
                    else this.storeDirectUploadsColumnAscOrDesc = 0;
                }

                this.storeDirectUploadsInfoReverse =
                    this.storeDirectUploadsInfoPredicate === predicate ? !this.storeDirectUploadsInfoReverse : false;
                this.storeDirectUploadsInfoPredicate = predicate;
                break;
        }
    }

    filterAPICollections(collection, searchStr: string) {
        return !searchStr || collection.apiName.toLowerCase().includes(searchStr.toLowerCase());
    }

    filterTableCollections(collection, searchStr: string){
        return !searchStr || collection.tableName.toLowerCase().includes(searchStr.toLowerCase());
    }

    changedSearchText(searchStr: string): void {
        this.searchStr = searchStr;
    }
}