import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppConstants } from "../../../../app.constants";
import { ReportApiService } from "../../services/reports.resource";
import { ManageReportsData } from "../manage.reports.component";
import { ValidationService } from "../../../../utils/shared-services/validation.service";

@Component({
    selector: 'saveJabDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/save-job.tpl.html',
})
export class SaveReportAsJobDialog {
    public captions;
    public form;
    public conceptID;
    public templateID;

    reportName: any;
    info: any;
    REPORT_TECHNOLOGY_TYPES: { WIJMO_OLAP: string; FLAT_REPORT: string; };

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<SaveReportAsJobDialog>,
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: ManageReportsData,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.REPORT_TECHNOLOGY_TYPES = AppConstants.REPORT_TECHNOLOGY_TYPES;
        this.captions = this.data.captions;
        this.conceptID = this.data.conceptID;
        this.info = this.data.info;
        this.form = new UntypedFormGroup({
            jobName: new UntypedFormControl(this.info.info.displayName ?? ''),
        });
    }

    ngOnInit(): void { }

    saveJob(formValue: any) {
        let job = {
            conceptID: parseInt(this.conceptID),
            displayName: formValue.value.jobName,
            visualizations: []
        };
        this.editViewDefinition(job);
        this.reportApiService.updateJob(this.info.info.jobID, job).then(() => {
            this.closeDialog();
        });
    }

    editViewDefinition(jobOrTemplate) {
        for (let visualization of this.info.visualizations) {
            let jobVisualization = {
                id: visualization.id,
                views: []
            };

            if (visualization.technology.kind === this.REPORT_TECHNOLOGY_TYPES.WIJMO_OLAP) {
                for (let view of visualization.technology.views) {
                    let viewDefinition = this.changeHeadersToBindings(JSON.parse(view.definition));
                    let jobView = {
                        type: view.type,
                        definition: JSON.stringify(viewDefinition)
                    };
                    jobVisualization.views.push(jobView);
                }

                jobOrTemplate.visualizations.push(jobVisualization);
            }
        }
    }

    changeHeadersToBindings(viewDefinition) {
        for (let k in viewDefinition.fields) {
            for (let l in viewDefinition.rowFields.items) {
                if (viewDefinition.rowFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.rowFields.items[l] = viewDefinition.fields[k].binding;
            }

            for (let l in viewDefinition.columnFields.items) {
                if (viewDefinition.columnFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.columnFields.items[l] = viewDefinition.fields[k].binding;
            }

            for (let l in viewDefinition.filterFields.items) {
                if (viewDefinition.filterFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.filterFields.items[l] = viewDefinition.fields[k].binding;
            }

            for (let l in viewDefinition.valueFields.items) {
                if (viewDefinition.valueFields.items[l] == viewDefinition.fields[k].header)
                    viewDefinition.valueFields.items[l] = viewDefinition.fields[k].binding;
            }

            viewDefinition.fields[k].header = viewDefinition.fields[k].binding;
        }

        return viewDefinition;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}