import { Component, OnInit } from "@angular/core";




@Component({
    selector: "app-price-explorer",
    templateUrl: "./src/app/features/price-change/templates/price-explorer.component.html",
})
export class PriceExplorerComponent implements OnInit {
    ngOnInit(): void {
    }
}