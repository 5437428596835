import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../utils/shared-services/caption.service";
import { NotificationData } from "./login.component";
import * as $ from 'jquery';

@Component({
    selector: 'notifications',
    templateUrl: 'src/app/auth/templates/notifications.tpl.html',
})
export class NotificationsDialog {
    public captions;
    notifications: any;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<NotificationsDialog>,
        @Inject(CaptionService) private captionService,
        @Inject(MAT_DIALOG_DATA) private data: NotificationData
    ) {
        this.captions = this.captionService.captions;
        this.notifications = this.data.notifications
    }

    ngOnInit() {
        this.populateNotificationTextMessage(this.notifications);
    }

    populateNotificationTextMessage(notis) {
        setTimeout(function () {
            $('.textMessage').each(function (index) {
                $(this).html(notis[index].textMessage);
            });
        }, 1);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}