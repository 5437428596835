import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ConceptApiService } from '../../../features/concepts/services/concept.resource';
import { UserAccessHandler } from '../../../features/users/services/user-access-handler.service';
import { UserSecurityApiService } from '../../../features/users/services/user-security.resource';
import { UserConstants } from '../../../features/users/users.constants';
import { EmitterService } from '../../shared-services/emitter.service';
import { ConceptPickerDialog } from './concept-picker-dialog.component';

@Component({
    selector: 'conceptPicker',
    templateUrl: 'src/app/utils/shared-templates/user-access/concept-picker.html',
})
export class ConceptPickerComponent {
    @Output() conceptPicker: EventEmitter<any> = new EventEmitter();

    public captions;
    public USER_ACCESS;
    public passedUserId;
    public currentUserAccess;
    public selectedUserSecurity;
    public isAdmin;
    public concepts;
    public showAllConceptsBox;
    public selectedConcept;
    public allConcepts;
    public conceptPickerModel: any;

    constructor(
        @Inject(ConceptApiService) private conceptApiService,
        @Inject(UserAccessHandler) private userAccessHandler,
        @Inject(UserSecurityApiService) private userSecurityApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(EmitterService) private emitterService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUserAccess = this.userAccessHandler.getUserAccess();
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.passedUserId = +params['pathId'];
        });
        this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
            this.selectedUserSecurity = selectedUserSecurity;
            this.getAndSetUserValues();
        });
        this.allConcepts = {
            id: 0,
        };
        this.concepts = [];
    }

    getAndSetUserValues() {
        if (this.currentUserAccess === null || this.currentUserAccess == 'undefined') {
            this.currentUserAccess = this.userAccessHandler.getUserAccess();
        } else if (this.currentUserAccess.role === this.USER_ACCESS.GLOBAL) {
            this.isAdmin = true;
            this.conceptApiService
                .getAllActiveConcepts()
                .then((concepts) => this.getAllActiveConceptsSuccess(concepts));
        } else if (this.currentUserAccess.role !== this.USER_ACCESS.GLOBAL)
            this.conceptApiService
                .getConceptById(this.currentUserAccess.conceptID)
                .then((concept) => this.getConceptByIdSuccess(concept));
    }

    getAllActiveConceptsSuccess(concepts) {
        this.concepts = concepts;
        if (this.selectedUserSecurity.role === this.USER_ACCESS.GLOBAL) this.showAllConceptsBox = true;
        else {
            for (let concept of this.concepts) {
                if (concept.id === this.selectedUserSecurity.conceptID) {
                    this.selectedConcept = concept;
                    this.emitterService.conceptLoadedCtrl(concept);
                }
            }
        }
    }

    getConceptByIdSuccess(concept) {
        this.concepts = this.concepts.concat(concept);
        this.selectedConcept = concept;
        this.emitterService.conceptLoadedCtrl(concept);
    }

    selectConceptBox(selectedConcept) {
        this.emitterService.conceptChangedCtrl(selectedConcept);
        this.selectedConcept = selectedConcept;
        this.conceptPickerModel = selectedConcept.id;
        this.conceptPicker.emit(this.conceptPickerModel);
        if (selectedConcept.id === 0) {
            this.showAllConceptsBox = true;
        } else this.showAllConceptsBox = false;
    }

    conceptPickerDialog(ev) {
        if (this.currentUserAccess.role === this.USER_ACCESS.GLOBAL) {
            const dialogRef = this.dialog.open(ConceptPickerDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    concepts: this.concepts,
                },
            });

            dialogRef.afterClosed().subscribe((res) => {
                if (typeof res != 'undefined') {
                    if (res.data.selectedConcept == 0) {
                        this.selectedConcept = this.allConcepts;
                    } else {
                        this.selectedConcept = res.data.selectedConcept;
                    }

                    this.emitterService.conceptChangedCtrl(this.selectedConcept);
                    this.conceptPickerModel = this.selectedConcept.id;
                    this.conceptPicker.emit(this.conceptPickerModel);
                    if (this.selectedConcept.id === 0) {
                        this.showAllConceptsBox = true;
                    } else this.showAllConceptsBox = false;
                }
            });
        }
    }
}
