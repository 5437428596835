import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";

@Component({
    selector: 'no-concepts-dialog',
    templateUrl: 'src/app/features/concepts/templates/no-concepts-dialog.html',
})
export class NoConceptsDialog {
    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<NoConceptsDialog>,
        @Inject(CaptionService) private captionService: CaptionService
    ) {
        this.captions = this.captionService.captions;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
