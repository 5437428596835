import { Inject, Injectable } from '@angular/core';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';

import { Router } from '@angular/router';
import { Wid001 } from '../widgets/components/wid-001';
import { Wid002 } from '../widgets/components/wid-002';
import { Wid003 } from '../widgets/components/wid-003';
import { Wid004 } from '../widgets/components/wid-004';
import { Wid005 } from '../widgets/components/wid-005';
import { Wid006 } from '../widgets/components/wid-006';
import { Wid007 } from '../widgets/components/wid-007';
import { Wid008 } from '../widgets/components/wid-008';
import { Wid021 } from '../widgets/components/wid-021';
import { Wid022 } from '../widgets/components/wid-022';
import { Wid023 } from '../widgets/components/wid-023';
import { Wid024 } from '../widgets/components/wid-024';
import { Wid025 } from '../widgets/components/wid-025';
import { Wid009 } from '../widgets/components/wid-009';
import { Wid010 } from '../widgets/components/wid-010';
import { Wid011 } from '../widgets/components/wid-011';
import { Wid012 } from '../widgets/components/wid-012';
import { Wid013 } from '../widgets/components/wid-013';
import { Wid014 } from '../widgets/components/wid-014';
import { Wid015 } from '../widgets/components/wid-015';
import { Wid016 } from '../widgets/components/wid-016';
import { Wid017 } from '../widgets/components/wid-017';
import { StorePortalData } from '../models/store-portal.model';
import { WidgetInfo } from '../models/widget.model';
import { View } from '../models/view.model';

@Injectable()
export class StorePortalService {
    public captions: any = {};

    private readonly ACCESS_TOKEN_KEY: string = 'accessToken';
    private readonly PORTAL_DATA_KEY: string = 'portalData';

    // information set corresponding to currently available widgets
    private readonly WIDGETS: Record<string, WidgetInfo> = {
        'wid-001': {
            title: 'Overview',
            datapoints: ['STORE_Info', 'SALES_Day'],
            component: Wid001,
        },
        'wid-002': {
            title: 'Net Sales & Final Sales',
            datapoints: ['SALES_Day'],
            component: Wid002,
        },
        'wid-003': {
            title: 'Hourly Sales Breakdown',
            datapoints: ['SALES_Hour', 'SALES_Day', 'STORE_Info_Current'],
            component: Wid003,
        },
        'wid-004': {
            title: 'Top 5 Sales [Quantity]',
            datapoints: ['SALES_Top5'],
            component: Wid004,
        },
        'wid-005': {
            title: 'Top 5 Sales [Net]',
            datapoints: ['SALES_Top5_Net'],
            component: Wid005,
        },
        'wid-006': {
            title: 'Hourly Profit Breakdown',
            datapoints: ['PROFIT_Hour', 'PROFIT_Day', 'STORE_Info_Current'],
            component: Wid006,
        },
        'wid-007': {
            title: '7 Days Prior vs Current Sales',
            datapoints: ['sales_byhour_7d_vs_curr', 'sales_total_7d_vs_curr', 'STORE_Info_Current'],
            component: Wid007,
        },
        'wid-008': {
            title: 'Closed vs All Sales Breakdown',
            datapoints: ['sales_byhour_7d_vs_curr', 'sales_total_7d_vs_curr', 'STORE_Info_Current'],
            component: Wid008,
        },
        'wid-009': {
            title: 'Open and Closed Shifts (1)',
            datapoints: ['shifts_1', 'STORE_Info_Current'],
            component: Wid009,
        },
        'wid-010': {
            title: 'Message',
            datapoints: ['STORE_Info_Current'],
            component: Wid010,
        },
        'wid-011': {
            title: 'Open and Closed Shifts (2)',
            datapoints: ['shifts_1', 'STORE_Info_Current'],
            component: Wid011,
        },
        'wid-012': {
            title: 'Open Table (1)',
            datapoints: ['OpenTables1', 'STORE_Info_Current'],
            component: Wid012,
        },
        'wid-013': {
            title: 'Open Table (2)',
            datapoints: ['OpenTables1', 'STORE_Info_Current'],
            component: Wid013,
        },
        'wid-014': {
            title: 'Application Versions',
            datapoints: ['VersionApps', 'STORE_Info_Current'],
            component: Wid014,
        },
        'wid-015': {
            title: 'DLL Versions',
            datapoints: ['VersionDLLs', 'STORE_Info_Current'],
            component: Wid015,
        },
        'wid-016': {
            title: 'Quick Saved Orders',
            datapoints: ['OpenTables2', 'STORE_Info_Current'],
            component: Wid016,
        },
        'wid-017': {
            title: 'Items on Countdown',
            datapoints: ['CountDown1', 'STORE_Info_Current'],
            component: Wid017,
        },
        'wid-021': {
            title: 'Top 5 Payment Methods [Count]',
            datapoints: ['PAYMENT_Top5'],
            component: Wid021,
        },
        'wid-022': {
            title: 'Top 5 Payment Methods [Amount]',
            datapoints: ['PAYMENT_Top5'],
            component: Wid022,
        },
        'wid-023': {
            title: 'Top 5 Labour Hours',
            datapoints: ['LABOR_Top5_Hours'],
            component: Wid023,
        },
        'wid-024': {
            title: 'Top 5 Labour Wages',
            datapoints: ['LABOR_top5_wage'],
            component: Wid024,
        },
        'wid-025': {
            title: 'Labour Cost Percentage',
            datapoints: ['LABOR_Cost_Percent'],
            component: Wid025,
        },
    };

    /*
     * This is the default configuration JSON which maintains the basic information and initial
     * state of a store portal.
     */
    private VIEWS_CONFIG: View[] = [
        {
            id: 0,
            title: 'View 1',
            widgets: [
                { id: 'wid-001' },
                { id: 'wid-002' },
                { id: 'wid-003' },
                { id: 'wid-004' },
                { id: 'wid-005' },
                { id: 'wid-006' },
                { id: 'wid-007' },
                { id: 'wid-008' },
                { id: 'wid-021' },
                { id: 'wid-022' },
                { id: 'wid-023' },
                { id: 'wid-024' },
                { id: 'wid-025' },
            ],
        },
        {
            id: 1,
            title: 'View 2',
            widgets: [
                { id: 'wid-001' },
                { id: 'wid-021' },
                { id: 'wid-022' },
                { id: 'wid-023' },
                { id: 'wid-024' },
                { id: 'wid-025' },
            ],
        },
        {
            id: 2,
            title: 'View 3',
            widgets: [{ id: 'wid-001' }, { id: 'wid-003' }, { id: 'wid-006' }],
        },
        {
            id: 3,
            title: 'View 4',
            widgets: [{ id: 'wid-001' }],
        },
    ];

    constructor(
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(Router) private router: Router
    ) {}

    /**
     * @name getDefaultPortalConfig
     * @description gets the default store portal config (temporary for testing)
     * @return store portal default config
     */
    getDefaultPortalConfig(): View[] {
        return this.VIEWS_CONFIG;
    }

    /**
     * @name getWidgetsInfo
     * @description gets a JSON object with details for every widget
     * @return information of all available widgets
     */
    getWidgetsInfo(): Record<string, WidgetInfo> {
        return this.WIDGETS;
    }

    /**
     * @name getAllWidgets
     * @description gets a parsed list of all widgets
     * @return all parsed available widgets
     */
    getAllWidgets(): {id: string}[] {
        return Object.keys(this.WIDGETS).map((key) => {
            return { id: key };
        });
    }

    /**
     * @name clearAccessToken
     * @description clears the session storage for the access token
     */
    clearAccessToken() {
        this.browserStorage.removeSessionstorageItem(this.ACCESS_TOKEN_KEY);
    }

    /**
     * @name getStorePortalData
     * @description gets the store portal data from session storage
     * @return saved store portal data
     */
    getStorePortalData() {
        return this.browserStorage.getSessionstorage(this.PORTAL_DATA_KEY, null);
    }

    /**
     * @name setStorePortalData
     * @description sets the store portal data into session
     * @param data this is the store portal data to be saved for the session
     */
    setStorePortalData(data: StorePortalData): void {
        this.browserStorage.setSessionstorage(this.PORTAL_DATA_KEY, data);
    }

    /**
     * @name clearStorePortalData
     * @description clears the session storage for the store portal data
     */
    clearStorePortalData() {
        this.browserStorage.removeSessionstorageItem(this.PORTAL_DATA_KEY);
    }

    /**
     * @name logout
     * @description this method logs the user out and navigates them to the login page of the
     * inventory counter, and clears all saved data.
     */
    logout() {
        let alias = this.getStorePortalData().Alias;
        this.router.navigate(['/storePortal/login'], {
            queryParams: {
                ...(alias ? { PortalID: alias } : {}),
            },
        });
        this.clearAccessToken();
        this.clearStorePortalData();
    }

    /**
     * @name parseSavedConfig
     * @description this method receives a store portal config JSON that may be outdated and needs to
     * be updated to include any new changes made to the global config (add/remove views/widgets)
     * @param config this is the store portal config JSON that will be parsed into the latest config
     * @return latest store portal config
     */
    parseSavedConfig(savedConfig: View[]): View[] {
        if (!savedConfig) {
            return this.getDefaultPortalConfig();
        }

        for (const view of savedConfig) {
            let updatedWidgets = [];
            for (const widget of view.widgets) {
                if (this.WIDGETS[widget.id]) {
                    updatedWidgets.push(widget);
                }
            }
            view.widgets = updatedWidgets;
        }

        return savedConfig;
    }
}
