import { Subject } from 'rxjs';

export class UserEmitterService {
    private loadedConcept = new Subject();
    conceptLoadedDirectiveItem$ = this.loadedConcept.asObservable();

    private changedConcept = new Subject();
    conceptChangedDirectiveItem$ = this.changedConcept.asObservable();

    private loadedGroupItem = new Subject();
    groupItemLoadedDirectiveItem$ = this.loadedGroupItem.asObservable();

    private changedGroupItem = new Subject();
    groupItemChangedDirectiveItem$ = this.changedGroupItem.asObservable();

    private loadedTag = new Subject();
    tagLoadedDirectiveItem$ = this.loadedTag.asObservable();

    private changedTag = new Subject();
    tagChangedDirectiveItem$ = this.changedTag.asObservable();

    private storeChangedGroupItem = new Subject();
    storeChangedForGroupItemDirectiveItem$ = this.storeChangedGroupItem.asObservable();

    private storeChangedTag = new Subject();
    storeChangedForTagDirectiveItem$ = this.storeChangedTag.asObservable();

    private storesChanged = new Subject();
    storeChangedDirectiveItem$ = this.storesChanged.asObservable();

    private tagCreated = new Subject();
    createTagForSelectedStoresItem$ = this.tagCreated.asObservable();

    private cancelTag = new Subject();
    cancelTagCreationItem$ = this.cancelTag.asObservable();

    conceptLoadedDirective(concept) {
        this.loadedConcept.next(concept);
    }

    conceptChangedDirective(concept) {
        this.changedConcept.next(concept);
    }

    groupItemLoadedDirective(groupItem) {
        this.loadedGroupItem.next(groupItem);
    }

    groupItemChangedDirective(group, concept) {
        this.changedGroupItem.next([group, concept]);
    }

    tagLoadedDirective(tag) {
        this.loadedTag.next(tag);
    }

    tagChangedDirective(tag, concept) {
        this.changedTag.next([tag, concept]);
    }

    storeChangedForGroupItemDirective(store, groupItem) {
        this.storeChangedGroupItem.next([store, groupItem]);
    }

    storeChangedForTagDirective(store, tag) {
        this.storeChangedTag.next([store, tag]);
    }

    storeChangedDirective(allStores) {
        this.storesChanged.next(allStores);
    }

    createTagForSelectedStores(tag) {
        this.tagCreated.next(tag);
    }

    cancelTagCreation(cancelledStores) {
        this.cancelTag.next(cancelledStores);
    }
}
