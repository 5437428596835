import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as txml from 'txml';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileViewService } from '../../../utils/shared-services/mobileView/mobileView.service';
import { ConceptApiService } from '../services/concept.resource';
import { ConfirmDisableHOPortalDialog } from './dialogs/confirm-disable-portal-dialog.component';
import { ValidationService } from '../../../utils/shared-services/validation.service';
import { EnableHOPortal } from '../models/enable-ho-portal.model';
import { SelectPrimaryStoreDialog } from './dialogs/select-primary-store-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EoiApiService } from '../../eoi/services/eoi.resource';
import { BasicEoiInfo, EoiInformation, EOIQuickStatus, EOIQuickStatusResponse } from '../../eoi/models/eoi.model';
import { eoiService as EoiService } from '../../eoi/services/eoi.service';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { EoiStatusDialog } from '../../eoi/components/dialogs/eoi-status-dialog.component';
import { HOPortalApiService } from '../../head-office-portal/services/head-office-portal.resource';
import { HOPortalService } from '../../head-office-portal/services/head-office-portal.service';
import { HeadOfficePortalData } from '../../head-office-portal/models/head-office-portal.model';


@Component({
    selector: 'app-store-portal',
    templateUrl: 'src/app/features/concepts/templates/enable-ho-portal.html'
})
export class EnableHOPortalComponent {

    private baseURl: URL;
    private fb: FormBuilder = new FormBuilder();

    public portalIDForm = this.fb.group({
        pathName: ['', [Validators.required, Validators.maxLength(this.validationService.portalIDMaxLength), Validators.minLength(this.validationService.portalIDMinLength)]],
        storeName: ['']
    });

    public captions: Record<string, string>;
    private eoiInfo: BasicEoiInfo;
    public eoiStatus: string = 'color-grey';
    public portalURL: string = '';
    public pathName: string = '';
    public enabled: boolean = false;
    public configActive: boolean = false;
    public isEditingPortalID: boolean = false;

    public searchStr: string = '';
    hoPortalDemoLink: string;
    conceptID: number;
    primaryStoreID: number;

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(EoiService) private eoiService: EoiService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(EoiApiService) private eoiApiService: EoiApiService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(MobileViewService) private mobileViewService: MobileViewService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(DeviceDetectorService) private deviceService: DeviceDetectorService,
        @Inject(ValidationService) public validationService: ValidationService,
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(HOPortalApiService) private hoPortalApiService: HOPortalApiService,
        @Inject(HOPortalService) private hoPortalService: HOPortalService
    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_MEDIUM);
        this.conceptID = +this.actRoute.snapshot.paramMap.get('pathId');
        this.baseURl = new URL(window.location.toString().replace(this.router.url, '/hoPortal'));
        this.hoPortalDemoLink = 'https://www.youtube.com/embed/FNcv2eS-pGE?ecver=1';
    }

    ngOnInit(): void {
        this.portalIDForm.get('pathName').disable();
        this.portalIDForm.get('storeName').disable();
        this.portalIDForm.get('pathName').markAsTouched();

        this.loadEnableConceptPortal();
    }

    private loadEnableConceptPortal(): void {
        this.conceptApiService.getEnableConceptPortal(this.conceptID).then((response: EnableHOPortal) => {
            if (response) {
                this.pathName = response.pathName;
                this.updatePortalURL(response.pathName);
                this.enabled = response.isActive === 1;
                this.primaryStoreID = response.primaryStore;
                this.portalIDForm.get('storeName').setValue(response.storeName);
                this.portalIDForm.get('pathName').setValue(this.pathName);
            }
            if (this.enabled) {
                // Update EOI status
                this.eoiApiService.getEoiByID(this.primaryStoreID, this.getTimeRange(), 8, 1, null).then((eoi: EoiInformation) => {
                    this.eoiApiService.getQuickStatus(this.primaryStoreID, 15000000).then((eoiStatusResponse: EOIQuickStatusResponse) => {
                        const { Response } = txml.simplify(txml.parse(eoiStatusResponse.responseString));
                        if (Response?.ErrorNum === '-1') {
                            return;
                        }

                        const eoiQuickStatus = Response.Status as EOIQuickStatus;
                        this.eoiStatus = this.eoiService.getQuickStatusColor(eoiQuickStatus);
                        if (this.eoiStatus === 'color-yellow') {
                            this.eoiStatus = 'color-green';
                        }
                        this.eoiInfo = {
                            okToOrder: eoiQuickStatus.OkToOrder,
                            posOnline: eoiQuickStatus.PosOnline,
                            eoiOnline: eoiQuickStatus.EoiOnline,
                            disconnectedTime: eoi?.disconnectedTime,
                        };
                    });
                });
            }
        });
    }


    private updatePortalURL(aPathName: string): void {
        this.baseURl.searchParams.set('PortalID', aPathName);
        this.portalURL = this.baseURl.toString();
    }

    private getTimeRange(): number {
        let timeRange = this.browserStorage.getSessionstorage('timeRange', undefined);
        return timeRange == null ? 7 : timeRange;
    }

    enablePortal(): void {
        const dialogRef = this.dialog.open(SelectPrimaryStoreDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '950vw',
            minWidth: '50vw',
            data: {
                conceptID: this.conceptID,
            },
        });

        dialogRef.afterClosed().subscribe((res: EnableHOPortal) => {
            if (res.primaryStore) {
                let hoPortal: EnableHOPortal = {
                    conceptID: this.conceptID,
                    primaryStore: res.primaryStore,
                    storeName: res.storeName,
                    pathName: this.pathName,
                    isActive: 1,
                };
                this.conceptApiService.enableConceptPortal(hoPortal.conceptID, hoPortal.primaryStore).then((res: any) => {
                    this.pathName = res.pathName;
                    this.portalIDForm.get('pathName').setValue(this.pathName);
                    this.portalIDForm.get('storeName').setValue(hoPortal.storeName);
                    this.enabled = true;
                });
            }
        });
    }


    disablePortal(): void {
        const dialogRef = this.dialog.open(ConfirmDisableHOPortalDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
        });

        dialogRef.afterClosed().subscribe((res: boolean) => {
            if (res) {
                this.conceptApiService.disableConceptPortal(this.conceptID).then(() => {
                    this.enabled = false;
                });
            }
        });
    }

    toggleEditPortalID(): void {
        this.isEditingPortalID = !this.isEditingPortalID;
        this.portalIDForm.get('pathName').setValue(this.pathName);
        this.isEditingPortalID
            ? this.portalIDForm.get('pathName').enable()
            : this.portalIDForm.get('pathName').disable();
    }

    openPortal(): void {
        // checking if the app is running on a Desktop browser.
        const isDesktopDevice = this.deviceService.isDesktop();
        if (!isDesktopDevice) {
            this.snackBar.open(this.captions.enableNewTab, this.captions.ok, {
                duration: 10000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
            });
        }
        window.open(this.portalURL, '_blank');
    }

    // login to store portal api and forward to portal home page
    openHQPortal(): void {
        // checking if the app is running on a Desktop browser.
        const isDesktopDevice = this.deviceService.isDesktop();
        if (!isDesktopDevice) {
            this.snackBar.open(this.captions.enableNewTab, this.captions.ok, {
                duration: 10000,
                verticalPosition: 'top',
                horizontalPosition: 'center',
            });
        }
        this.hoPortalApiService
            .hqLoginForHOPortal(
                this.pathName,
                '',
                'HQUser',
                this.browserStorage.getSessionstorage('userAccess', null)
            )
            .then((res: HeadOfficePortalData) => {
                this.hoPortalService.setHoPortalData(res);
                if (res?.AccessToken) {
                    window.open('storePortal/home/0', '_blank');
                }
            });
    }

    eoiStatuses(): void {
        const dialogRef = this.dialog.open(EoiStatusDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                eoiOnline: this.eoiInfo?.eoiOnline,
                posOnline: this.eoiInfo?.posOnline,
                okToOrder: this.eoiInfo?.okToOrder,
                disconnectedtime: this.eoiInfo?.disconnectedTime,
                isStorePortal: true,
                isCountSheet: false,
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }
}
