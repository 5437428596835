import { Component, Inject, Injectable, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConstants } from '../../../../app.constants';
import { SevenDayDatePickerComponent } from '../../../../utils/date-picker/seven-day-date-picker.component';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { ReportDataWizard } from '../services/report-data-wizard.service';
import { ReportParamsHandler } from '../services/report-params-handler.service';
import { SharedWizardData } from '../services/report-shared-wizard-data.service';


@Component({
    selector: 'report-date-widget',
    templateUrl: 'src/app/features/reports/wizard/templates/report-date-picker.html',
})

@Injectable()
export class ReportWizardDateComponent {
    @ViewChild(SevenDayDatePickerComponent, { static: false }) sevenDayDatePicker: SevenDayDatePickerComponent;
    captions: Record<string, string>;
    templateID: any;
    step: any;
    scheduleDateSelection: any;

    isSchedule: boolean = false;
    isSevenDayReport: boolean = false;

    range: UntypedFormGroup;
    selectDate: UntypedFormGroup;

    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    minDate: Date;
    maxDate: Date;
    values: any;
    jobParameters: any;

    constructor(
        @Inject(ReportDataWizard) public reportDataWizard: ReportDataWizard,
        @Inject(SharedWizardData) public sharedWizardData: SharedWizardData,
        @Inject(CaptionService) public captionService: CaptionService,
        @Inject(ReportParamsHandler) public reportParamsHandler: ReportParamsHandler
    ) {
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 20, 0, 1);
        this.maxDate = new Date(currentYear + 5, 11, 31);
        this.captions = this.captionService.captions;
        this.templateID = this.sharedWizardData.templateID;
        this.selectDate = this.formBuilder.group({
            option: ['', [Validators.required]]
        });
        this.range = this.formBuilder.group({
            startDate: [new Date(), [Validators.required]],
            endDate: [new Date(), [Validators.required]]
        });
        this.jobParameters = reportParamsHandler.getJobParams().parameters;
    }

    ngOnInit() {
        this.isSevenDayReport = this.templateID === AppConstants.FLAT_SEVEN_DAY_LABOUR ? true : false;
        // if (this.reportDataWizard.containsStep(this.reportDataWizard.STEP_TYPE.SCHEDULE) != -1) {
        //     this.isSchedule = true;
        //     // sets the dropdown JSON Object
        //     this.scheduleDateSelection = {
        //         value: this.templateID !== AppConstants.FLAT_SEVEN_DAY_LABOUR ?
        //             this.sharedWizardData.scheduleDate ? this.sharedWizardData.scheduleDate : 'currentDay' : 'currentWeek',
        //         options: this.templateID !== AppConstants.FLAT_SEVEN_DAY_LABOUR ? [{
        //             value: 'currentDay',
        //             description: this.captions.current_day_front,
        //             info: this.captions.currentDayDescription
        //         },
        //         {
        //             value: 'previousDay',
        //             description: this.captions.previous_day_front,
        //             info: this.captions.previousDayDescription
        //         },
        //         {
        //             value: 'currentWeek',
        //             description: this.captions.current_week_front,
        //             info: this.captions.currentWeekDescription
        //         },
        //         {
        //             value: 'previousWeek',
        //             description: this.captions.previous_week_front,
        //             info: this.captions.previousWeekDescription
        //         },
        //         {
        //             value: 'currentMonth',
        //             description: this.captions.current_month_front,
        //             info: this.captions.currentMonthDescription
        //         },
        //         {
        //             value: 'previousMonth',
        //             description: this.captions.previous_month_front,
        //             info: this.captions.previousMonthDescription
        //         }
        //         ] : [{
        //             value: 'currentWeek',
        //             description: this.captions.current_week_front,
        //             info: this.captions.currentWeekDescription
        //         },
        //         {
        //             value: 'previousWeek',
        //             description: this.captions.previous_week_front,
        //             info: this.captions.previousWeekDescription
        //         }]
        //     };
        //     // checks wizard data if there is a value and set it to option if there is
        //     if (this.sharedWizardData?.scheduleDate) {
        //         for (let option of this.scheduleDateSelection.options) {
        //             if (option.value == this.sharedWizardData.scheduleDate) {
        //                 this.selectDate.get('option').setValue(option);
        //             }
        //         }

        //     }
        //     // otherwise set it to the default value
        //     else {
        //         this.selectDate.get('option').setValue(this.scheduleDateSelection.options[0]);
        //     }
        // } else {
        if (this.sharedWizardData.startDate != null) {
            // this.range.value.startDate = this.sharedWizardData.startDate;
            // this.range.value.endDate = this.sharedWizardData.endDate;
        }
        if (this.reportParamsHandler.jobParams.parameters.length != 0) {
            this.range = this.formBuilder.group({
                startDate: [this.reportParamsHandler.jobParams.parameters[0].params[0].value, [Validators.required]],
                endDate: [this.reportParamsHandler.jobParams.parameters[0].params[1].value, [Validators.required]]
            });
        }
    }

    ngAfterViewInit() {
        if (this.isSevenDayReport) {
            this.sevenDayDatePicker.range = this.formBuilder.group({
                startDate: [this.reportParamsHandler.jobParams.parameters[0].params[0].value, [Validators.required]],
                endDate: [this.reportParamsHandler.jobParams.parameters[0].params[1].value, [Validators.required]]
            });
        }
    }

    submitDate() {
        const startDateSelected = this.range.controls.startDate.value;
        const endDateSelected = this.range.controls.endDate.value;

        // If no end date is selected, set the end date equal to the start date
        if (!endDateSelected && startDateSelected) {
            this.range.controls.endDate.setValue(startDateSelected);
        }
        this.range.controls.startDate.updateValueAndValidity();
        this.range.controls.endDate.updateValueAndValidity();
        
        let sevenDayValues = {
            startDate: null,
            endDate: null
        };

        if (this.isSevenDayReport) {
            sevenDayValues.startDate = this.sevenDayDatePicker.range.value.startDate;
            sevenDayValues.endDate = this.sevenDayDatePicker.range.value.endDate;
            this.submitValues(sevenDayValues);
        } else {
            this.submitValues(this.range.value);
        }
    }

    parentFunction() {
        this.submitDate();
    }


    submitValues(dateObject) {
        // Checks if the form is valid
        let validForm = true;
        if (this.range.controls.startDate.hasError('matStartDateInvalid') ||
            this.range.controls.endDate.hasError('matEndDateInvalid')) {
            validForm = false;
        }
        // if valid calls setParams
        if (validForm) {
            for (let param of this.jobParameters[0].params) {
                if (param.id === 1001) {
                    param.value = dateObject.startDate;
                }
                else {
                    param.value = dateObject.endDate;
                }
            }
            this.reportParamsHandler.setJobParams(this.jobParameters);
        }
    }

    setParams(dateObject) {
        // Checks if schedule, sets values as is if not
        // if (this.reportDataWizard.containsStep(this.reportDataWizard.STEP_TYPE.SCHEDULE) != -1) {
        // // gets value from FormName which currently is the dropdown value
        // let value = dateObject.value;
        // // Saves the value in sharedWizardData
        // this.sharedWizardData.scheduleDate = value;
        // // Filters for the startDate and endDate, sets them according to the option given
        // this.reportDataWizard.setParamsForCurrentStep(
        //     this.wizardSteps[0].params.filter(param => param.id === 1001 || param.id === 1002).map(function (param) {
        //         param.parameterType = 'dateschedule';
        //         switch (value) {
        //             case 'currentDay':
        //             case 'previousDay':
        //                 param.value = value;
        //                 break;
        //             default:
        //                 /* 
        //                     if the param value is 1001 refer to it as start date (add start to value) 
        //                     else if dropdown value starts with a p (previous) 
        //                     add end to value else end it at currentDay 
        //                 */
        //                 param.value =
        //                     param.id === 1001 ?
        //                         value + 'Start' :
        //                         value.startsWith('p') ?
        //                             value + 'End' :
        //                             'currentDay';
        //         }
        //     })
        // );
        // } else {

        // }
    }
}