import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ReportApiService } from "../../services/reports.resource";
import { ManageReportsData } from "../manage.reports.component";

@Component({
    selector: 'showDiscardDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/show-discard.html',
})
export class ShowDiscardDialog {
    public reportJobID;
    public captions;
    public conceptID;
    public tabToAdd = {
        tabName: ''
    }
    templateID: any;
    conceptName: any;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ShowDiscardDialog>,
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(Router) public router: Router,
        @Inject(MAT_DIALOG_DATA) public data: ManageReportsData,
    ) {
    }
    ngOnInit(): void {
        this.captions = this.data.captions;
        this.conceptID = this.data.conceptID;
        this.conceptName = this.data.conceptName;
        this.reportJobID = this.data.reportJobID;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    cancelDelete() {
        this.dialogRef.close();
    }

    deleteReport() {
        this.reportApiService.deleteJob(this.reportJobID, this.conceptID).then(() => {
            this.dialogRef.close();
            this.router.navigate(['reports/manageReports', this.conceptID, this.conceptName]);
        });
    }
}