import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { PrivilegeName, PrivilegeType } from '../../users/enums/user-privileges.enum';
import { UserAccess } from '../../users/models/user-access.model';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserConstants } from '../../users/users.constants';
import { FileDetails, PlanInfoSummary, PlanSummary, TotalFolderDetails, UsageSummary } from '../models/store-backup.model';
import { StoreApiService } from '../services/store.resource';
import { BackupDeleteDialog } from './dialogs/backup-delete-dialog.component';
import { BackupDeleteOneDialog } from './dialogs/backup-deleteOne-dialog.component';
import { BackupInfoDialog } from './dialogs/backup-info-dialog.component';

@Component({
    selector: 'storeBackup',
    templateUrl: 'src/app/features/stores/templates/store-backup.html'
})
export class StoreBackupComponent {

    private currentUser: UserAccess;
    private conceptID: number;
    private conceptName: string;
    private storeID: number;
    private backupInfo: PlanInfoSummary<string[]>;
    private selectedPlan: PlanSummary;

    public captions: Record<string, string>;
    public manageStoresPrivilege: boolean = false;
    public isBackedUp: boolean = false;
    public totalPlans = 0;
    public totalSize: string;
    public allPlans: PlanSummary[] = [];

    constructor(
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(MatDialog) private dialog: MatDialog
    ) {
        this.currentUser = this.userAccessHandler.getUserAccess();
    }

    ngOnInit(): void {
        this.conceptID = +this.actRoute.snapshot.paramMap.get('pathId');
        this.conceptName = this.actRoute.snapshot.paramMap.get('pathName');
        this.storeID = +this.actRoute.snapshot.paramMap.get('storeID');

        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });

        // if user has full access or manage store access then show page
        if (
            this.currentUser.userPrivilege !== UserConstants.USER_PRIVILEGES.ALL_PRIVILEGES &&
            this.currentUser.userPrivilege !== null
        ) {
            let privileges = JSON.parse(this.currentUser.userPrivilege) as {name: PrivilegeName, type: PrivilegeType}[];
            for (let privilege of privileges) {
                if (privilege.name === PrivilegeName.ManageStores) {
                    this.manageStoresPrivilege = true;
                }
            }
        }
        else {
            this.manageStoresPrivilege = true;
        }

        this.isBackedUp = false;
        this.storeApiService.isBackUpEnabled(this.storeID).then((response: boolean) => {
            if (response) {
                this.isBackedUp = true;
            }
        });

        this.storeApiService.getStoreBackupSummaryData(this.storeID).then((plans: false | UsageSummary) => {
            if (plans) {
                for (let plan in plans) {
                    if ('TotalFolderName' in plans[plan]) {
                        const totalFolder = plans[plan] as TotalFolderDetails;

                        this.totalSize = totalFolder.TotalSize;
                    }
                    else if ('planFiles' in plans[plan]) {
                        const planSummary = plans[plan] as PlanSummary;

                        planSummary.planDetails = false;
                        this.totalPlans++;

                        let counter = 0;
                        let limitedPlanFiles: FileDetails[] = [];

                        // want to remove log files from client view
                        for (let file in planSummary.fileNameAndSize) {
                            if (
                                planSummary.fileNameAndSize[file].fileName.split('.').pop().toLowerCase() == "zip" || 
                                planSummary.fileNameAndSize[file].fileName.split('.').pop().toLowerCase() == "db"
                            ) {
                                limitedPlanFiles.push(planSummary.fileNameAndSize[file]);
                                counter++;
                            }
                        }
                        planSummary.limitedFileCount = counter;
                        planSummary.fileNameAndSize = limitedPlanFiles;
                        this.allPlans.push(planSummary);
                    }
                }
            }
        });
    }

    enableBackup(): void {
        if (!this.isBackedUp) {
            this.storeApiService.addStoreBackup(this.storeID).then(() => {
                this.isBackedUp = true;
                this.snackbarService.successMessageBottom(this.captions.backupEnabled);
            });
        }
    }

    confirmDelete(): void {
        const dialogRef = this.dialog.open(BackupDeleteDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
        });

        dialogRef.afterClosed().subscribe((result: string) => {
            if (result === "DELETE BACKUP") {
                this.storeApiService.removeStoreBackup(this.storeID).then(() => {
                    this.isBackedUp = false;
                    this.snackbarService.successMessageBottom(this.captions.backupDeleted);
                });
            } else if (!result) {
                this.snackbarService.successMessageBottom(this.captions.cancelBackupDelete);
            } else {
                this.snackbarService.successMessageBottom(this.captions.retryBackupDelete);
            }

            dialogRef.close();
        });
    }

    downloadOneFile(planID: number, fileName: string): void {
        this.storeApiService.downloadStoreBackUpFile(planID + "/" + fileName, this.storeID).then((downloadLink: string) => {
            let uri = downloadLink;
            let link = document.createElement("a");
            link.href = uri.toString();
            link.download = downloadLink.toString();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    removeOneBackupPlan(planID: number): void {
        const dialogRef = this.dialog.open(BackupDeleteOneDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
        });

        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.storeApiService.removeSingleStoreBackup(this.storeID, planID);
                this.snackbarService.successMessageBottom(this.captions.backupDeleted);
            }
            else {
                this.snackbarService.successMessageBottom(this.captions.cancelBackupDelete);
            }
            dialogRef.close();
        });
    }

    getInfo(plan: PlanSummary): void {
        this.storeApiService.getInfoDetails(plan.planID, this.storeID).then((info: PlanInfoSummary) => {
            if (Object.keys(info).length > 0) {
                this.selectedPlan = plan;
                this.backupInfo.status = info.status;
                this.backupInfo.dateTime = info.dateTime;
                this.backupInfo.logFileContents = info.logFileContents.split(/\r\n|\r|\n/)

                const dialogRef = this.dialog.open(BackupInfoDialog, {
                    panelClass: 'app-full-bleed-dialog',
                    maxWidth: '90vw',
                    data: {
                        selectedPlan: this.selectedPlan,
                        backupInfo: this.backupInfo,
                    },
                });
            }
            else {
                console.error("Plan info is empty");
            }
        });
    }
}
