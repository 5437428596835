import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { StoreGroupItemEdit } from '../../store-groups/models/store-group-item.model';
import { EnableCountsheet } from '../models/enable-countsheet.model';
import { EnableStorePortal } from '../models/enable-store-portal.model';
import { PlanInfoSummary, StoreBackups, UsageSummary } from '../models/store-backup.model';
import { ScheduledUpdate, SoftwareVersion } from '../models/store-software.mode';
import { 
    Store, 
    StoreAdd, 
    StoreBase, 
    StoreBaseWithAddress, 
    StoreEditWithAddress, 
    StoreWithGroupsAndSelections, 
    StoreWithTags 
} from '../models/store.model';

@Injectable()
export class StoreApiService {

    private storeUrl: string = 'stores/{storeID}';
    private storesByConceptUrl: string = 'stores';
    private postTagsByStoreUrl: string = 'store-tags';
    private postGroupsByStoreUrl: string = 'store-group-items';
    private tagsByStoreUrl: string = 'store-tags/{storeID}';
    private validateStoresDeletionUrl: string = 'stores/validateDeletion';
    private storesTimeZone: string = 'stores/timeZones';
    private storeBackupUrl: string = 'storebackup/{storeID}';
    private removeStoreBackupUrl: string = 'storebackup/disable/{storeID}';
    private getStoreBackupSummaryUrl: string = 'storebackup/usageDetails/{storeID}';
    private getStoreBackupDownloadUrl: string = 'storebackup/download/{storeID}';
    private removeSingleStoreBackUpUrl: string = 'storebackup/{storeID}/{planID}';
    private getStoreBackupInfoUrl: string = 'storebackup/info/{storeID}';
    private enableCountsheetUrl: string = 'enableCountsheet/{storeID}';
    private disableCountsheetUrl: string = 'enableCountsheet/disable/{storeID}';
    private enableStorePortalUrl: string = 'enableStorePortal/{storeID}';
    private disableStorePortalUrl: string = 'enableStorePortal/disable/{storeID}';
    private downloadInstallerUrl: string = 'download/{installerName}';
    private getStoreSoftwareUrl: string = 'storeSoftware/{storeID}';
    private getScheduledUpdatesUrl: string = 'storeSoftware/scheduled/{storeID}';
    private updateStoreSoftwareUrl: string = 'storeSoftware/update/{storeID}';

    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getStoresByConceptId(conceptID: number): Promise<StoreBase[]> {
        let url = this.BASE_URL + this.storesByConceptUrl;
        const data$ = this.http.get<StoreBase[]>(url, {
            params: {
                conceptID: conceptID,
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getStoreById(storeID: number): Promise<StoreBaseWithAddress> {
        let storesByIdUrl = this.storeUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + storesByIdUrl;
        const data$ = this.http.get<StoreBaseWithAddress>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getTagsByStoreId(storeID: number): Promise<StoreWithTags> {
        let tagsByStoreIDUrl = this.tagsByStoreUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + tagsByStoreIDUrl;
        const data$ = this.http.get<StoreWithTags>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getGroupsByStoreId(storeID: number): Promise<StoreWithGroupsAndSelections>{
        let groupsByStoreUrl = this.storeUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + groupsByStoreUrl;
        const data$ = this.http.get<StoreWithGroupsAndSelections>(url, {
            params: {
                view: 'groups',
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateTagsForStore(store: StoreWithTags): Promise<StoreWithTags>{
        let url = this.BASE_URL + this.postTagsByStoreUrl;
        const data$ = this.http.post<StoreWithTags>(url, store);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateGroupsForStore(itemList: StoreGroupItemEdit): Promise<Object> {
        let url = this.BASE_URL + this.postGroupsByStoreUrl;
        const data$ = this.http.post(url, itemList); 
        const value = await lastValueFrom(data$); 
        return value; // returns NO_CONTENT on success
    }

    async addStore(store: StoreAdd, conceptID: number): Promise<Store> {
        store.conceptID = conceptID;
        let url = this.BASE_URL + this.storesByConceptUrl;
        const data$ = this.http.post<Store>(url, store);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateStoreWithAddress(updatedStore: StoreBaseWithAddress): Promise<StoreEditWithAddress> {
        let validStore = this.getValidStoreForApi(true, updatedStore);
        let storeUrlValid = this.storeUrl.replace('{storeID}', updatedStore.id.toString());
        let url = this.BASE_URL + storeUrlValid;
        const data$ = this.http.put<StoreEditWithAddress>(url, validStore);
        const value = await lastValueFrom(data$);
        return value;
    }

    async validateStoresDeletion(storeIds: number[]): Promise<Store[]> {
        let url = this.BASE_URL + this.validateStoresDeletionUrl;
        if (typeof storeIds !== 'object') return Promise.reject('Invalid Request');
        const data$ = this.http.post<Store[]>(url, storeIds);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deactivateStores(storeIds: number[]): Promise<Object> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: storeIds,
        };
        let url = this.BASE_URL + this.storesByConceptUrl;
        const data$ = this.http.delete(url, httpOptions);
        const value = await lastValueFrom(data$);
        return value; // returns NO_CONTENT on success
    }

    async hasData(conceptID: number, storeID: number): Promise<boolean> {
        let url = this.BASE_URL + 'stores/dataCheck?conceptID=' + conceptID + '&storeID=' + storeID;
        const data$ = this.http.get<boolean>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async resetReplicationToken(storeID: number, replicationToken: string): Promise<Store> {
        let storeUrlValid = this.storeUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + storeUrlValid;
        const data$ = this.http.post<Store>(url, replicationToken);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getTimeZones(): Promise<string[]> {
        let url = this.BASE_URL + this.storesTimeZone;
        const data$ = this.http.get<string[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    getValidStoreForApi(forUpdate: boolean, store: Object): Object {
        let address = 'address';
        let validStoreFields = ['corpStoreNum', 'displayName', 'phone1', address, 'webBgColor', 'currencyCode'];

        if (forUpdate) {
            validStoreFields.push('localTimeZone', 'utcOrLocal');
        }
        else {
            validStoreFields.push('conceptID', 'billType');
        }

        let validAddressFields = ['line1', 'line2', 'city', 'state', 'code', 'country'];

        if (forUpdate) {
            validAddressFields.push('id');
        }

        let validUpdatedStore = {
            address: {
                country: '',
                state: '',
            },
        };

        /*Check if every field is valid for api call and is in valid format*/
        for (let prop in store) {
            if (store.hasOwnProperty(prop) && validStoreFields.indexOf(prop) >= 0) {
                if (typeof store[prop] === 'boolean' || typeof store[prop] == 'number' || typeof store[prop] == 'string' || store[prop] === null) {
                    validUpdatedStore[prop] = store[prop];
                }
                else if (typeof store[prop] === 'object' && prop == 'address') {
                    for (let addrProp in store[prop]) {
                        if (store[prop].hasOwnProperty(addrProp) && validAddressFields.indexOf(addrProp) >= 0) {
                            validUpdatedStore[address][addrProp] = store[address][addrProp];
                        }
                    }
                }
            }
        }

        return validUpdatedStore;
    }

    async addStoreBackup(storeID: number): Promise<void | StoreBackups> {
        let storeBackupUrlValid = this.storeBackupUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + storeBackupUrlValid;
        const data$ = this.http.post<void | StoreBackups>(url, {
            params: null,
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async isBackUpEnabled(storeID: number): Promise<boolean> {
        let storeBackupUrlValid = this.storeBackupUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + storeBackupUrlValid;
        const data$ = this.http.get<boolean>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async removeStoreBackup(storeID: number): Promise<string> { // returns success message
        let removeStoreBackupUrlValid = this.removeStoreBackupUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + removeStoreBackupUrlValid;
        const data$ = this.http.delete(url, { responseType: 'text' });
        const value = await lastValueFrom(data$);
        return value;
    }

    async removeSingleStoreBackup(storeID: number, planID: number): Promise<string> { // returns success message
        let removeSingleStoreBackupUrlValid = this.removeSingleStoreBackUpUrl
            .replace('{storeID}', storeID.toString())
            .replace('{planID}', planID.toString());
        let url = this.BASE_URL + removeSingleStoreBackupUrlValid;
        const data$ = this.http.delete(url, { responseType: 'text' });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getStoreBackupSummaryData(storeID: number): Promise<false | UsageSummary> {
        let getStoreBackupSummaryUrlValid = this.getStoreBackupSummaryUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + getStoreBackupSummaryUrlValid;
        const data$ = this.http.get<false | UsageSummary>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async downloadStoreBackUpFile(filePath: string, storeID: number): Promise<string> { // returns download link as string
        let getStoreBackupDownloadUrlValid = this.getStoreBackupDownloadUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + getStoreBackupDownloadUrlValid;
        const data$ = this.http.get(url, {
            params: {
                filePath: filePath,
            },
            responseType: 'text',
        });

        const value = await lastValueFrom(data$);
        return value;
    }

    async getInfoDetails(planID: string, storeID: number): Promise<PlanInfoSummary> {
        let getStoreBackupInfoUrlValid = this.getStoreBackupInfoUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + getStoreBackupInfoUrlValid;
        const data$ = this.http.get<PlanInfoSummary>(url, {
            params: {
                planID: planID,
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getEnableCountsheet(storeID: number): Promise<EnableCountsheet> {
        let enableCountsheetURLValid = this.enableCountsheetUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + enableCountsheetURLValid;
        const data$ = this.http.get<EnableCountsheet>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    // enables count sheet and assigns a random string to the path
    // currently unused in favor of enableOrUpdateCountsheet()
    async enableCountsheet(storeID: number): Promise<EnableCountsheet> {
        let enableCountsheetURLValid = this.enableCountsheetUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + enableCountsheetURLValid;
        const data$ = this.http.post<EnableCountsheet>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    async disableCountsheet(storeID: number): Promise<EnableCountsheet> {
        let disableCountsheetURLValid = this.disableCountsheetUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + disableCountsheetURLValid;
        const data$ = this.http.put<EnableCountsheet>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    // this method enables the count sheet if it has never been enabled before
    // it also updates the count sheet if it has already been created
    async enableOrUpdateCountsheet(aCountSheet: EnableCountsheet): Promise<EnableCountsheet> {
        let enableCountsheetURLValid = this.enableCountsheetUrl.replace('{storeID}', aCountSheet.storeID.toString());
        let url = this.BASE_URL + enableCountsheetURLValid;
        const data$ = this.http.put<EnableCountsheet>(url, {
            storeID: aCountSheet.storeID,
            pathName: aCountSheet.pathName,
            isActive: aCountSheet.isActive,
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    // get enable store portal for provided store id
    async getEnableStorePortal(storeID: number): Promise<EnableStorePortal> {
        let enableStorePortalURLValid = this.enableStorePortalUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + enableStorePortalURLValid;
        const data$ = this.http.get<EnableStorePortal>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    // enables store portal and assigns a random string to the path
    // currently unused in favor of enableOrUpdateStorePortal()
    async enableStorePortal(storeID: number): Promise<EnableStorePortal> {
        let enableStorePortalURLValid = this.enableStorePortalUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + enableStorePortalURLValid;
        const data$ = this.http.post<EnableStorePortal>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    // disables store portal and assigns a random string to the path
    async disableStorePortal(storeID: number): Promise<EnableStorePortal> {
        let disableStorePortalURLValid = this.disableStorePortalUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + disableStorePortalURLValid;
        const data$ = this.http.put<EnableStorePortal>(url, {});
        const value = await lastValueFrom(data$);
        return value;
    }

    // this method enables the store portal if it has never been enabled before
    // it also updates the store portal if it has already been created
    async enableOrUpdateStorePortal(storePortal: EnableStorePortal): Promise<EnableStorePortal> {
        let enableStorePortalURLValid = this.enableStorePortalUrl.replace('{storeID}', storePortal.storeID.toString());
        let url = this.BASE_URL + enableStorePortalURLValid;
        const data$ = this.http.put<EnableStorePortal>(url, {
            storeID: storePortal.storeID,
            pathName: storePortal.pathName,
            isActive: storePortal.isActive,
            config: storePortal.config,
            widgetValueConfig: storePortal.widgetValueConfig,
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async downloadInstaller(installerName: string): Promise<string> { // returns the download link as a string
        let downloadInstallerUrlValid = this.downloadInstallerUrl.replace('{installerName}', installerName);
        let url = this.BASE_URL + downloadInstallerUrlValid;
        const data$ = this.http.get(url, { responseType: 'text' });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getStoreSoftwareVersions(storeID: number): Promise<SoftwareVersion[]> {
        let getStoreSoftwareUrlValid = this.getStoreSoftwareUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + getStoreSoftwareUrlValid;
        const data$ = this.http.get<SoftwareVersion[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getScheduledStoreUpdates(storeID: number): Promise<ScheduledUpdate[]> {
        let getScheduledUpdatesUrlValid = this.getScheduledUpdatesUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + getScheduledUpdatesUrlValid;
        const data$ = this.http.get<ScheduledUpdate[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateStoreSoftwareVersions(storeID: number, date: string): Promise<string> { // returns success message
        let updateStoreSoftwareUrlValid = this.updateStoreSoftwareUrl.replace('{storeID}', storeID.toString());
        let url = this.BASE_URL + updateStoreSoftwareUrlValid;
        const data$ = this.http.post(
            url,
            { header: { 'Content-Type': 'application/json' } },
            { params: { date: date }, responseType: 'text' }
        );
        const value = await lastValueFrom(data$);
        return value;
    }
}
