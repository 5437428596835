import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import * as moment from "moment";
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'open-cal-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/open-cal-dialog.html',
})
export class OpenCalDialog {

    private choiceTimes: string[] = [];

    public captions: Record<string, string>;
    public filteredResults: string[] = [];
    public minDate: Date;
    public date: moment.Moment;
    public time: string;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<OpenCalDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
    ) {
        this.captions = this.captionService.captions;
        this.minDate = new Date();
        this.date = moment(this.minDate);
        this.choiceTimes = [
            '1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM',
            '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM',
            '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM', '12:00 AM'
        ];
        this.time = this.choiceTimes[23]; // last item in choiceTime
        this.filteredResults = [...this.choiceTimes];
    }

    filterTimeResults(query: string): void {
        let lowercaseQuery = query.toLowerCase();
        this.filteredResults = this.choiceTimes.filter((choice: string) => choice.toLowerCase().includes(lowercaseQuery));
    }

    confirm(): void {
        let selectedTime = moment(this.time, 'h:mm a');
        this.date.set({ hour: selectedTime.hour(), minute: selectedTime.minute(), second: 0 });
        this.dialogRef.close(this.date);
    }
}
