import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../shared-services/caption.service';

interface DialogData {
    tags: any;
    tagItemMultiSelect: boolean;
    showSelectAllButton: boolean;
    selectedTagsForRenderingStores: any;
    allTags: any;
}

@Component({
    selector: 'tag-picker-dialog',
    templateUrl: 'src/app/utils/shared-templates/user-access/tag-picker-dialog.html',
})
export class TagPickerDialog {
    public captions: any;
    public tags;
    // public selectedTag;
    public tagItemMultiSelect: boolean;
    public selectedStoreTags;
    public allTags;
    public leadingTag: string = '';
    public newSelectedStoreTags: any = [];
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<TagPickerDialog>
    ) {
        this.captions = this.captionService.captions;
        this.tags = data.tags;
        this.tagItemMultiSelect = data.tagItemMultiSelect;
        this.selectedStoreTags = data.selectedTagsForRenderingStores;
        this.allTags = data.allTags;
        if (this.tagItemMultiSelect)
        {
            this.leadingTag = this.captions.tagUserAccess

        }else{
            this.leadingTag = this.captions.resetTagSelection;
        }
        

        this.dialogRef.keydownEvents().subscribe((event) => {
            //call cancel on escape
            if (event.key === 'Escape') {
                this.onCancel();
            }
        });

        this.dialogRef.backdropClick().subscribe(() => {
            //call cancel on clicking outside of dialog
            this.onCancel();
        });
    }
    selectTagBox(selectedTagItem) {
        // handle selecting tags
        if (typeof this.selectedStoreTags === 'undefined') {
            this.selectedStoreTags = [];
        }

        // check if we select multiple groups or just one group
        if (this.tagItemMultiSelect) {
            // if select all tags is clicked
            if (selectedTagItem == 0) {
                let counter = 0;
                for (let tag of this.tags) {
                    if (tag.isChecked) {
                        ++counter;
                    }
                }
                if (counter < this.tags.length) {
                    for (let tag of this.tags) {
                        if (!tag.isChecked) {
                            this.handleClick(tag);
                        }
                    }
                }
                // if all the buttons are clicked, turn them off
                else {
                    for (let tag of this.tags) {
                        if (tag.isChecked) {
                            this.handleClick(tag);
                        }
                    }
                }
            } else {
                this.handleClick(selectedTagItem);
            }
        } else {
            if (selectedTagItem == 0) {
                this.dialogRef.close({ data: { selectedTagsForRenderingStores: null } });
            } else {
                // return an array with a single value that was selected
                this.dialogRef.close({ data: { selectedTagsForRenderingStores: [selectedTagItem] } });
            }
        }
    }

    handleClick(selectedTagItem) {
        //keep track of which buttons were pressed and undo them if we do not click select
        let newTagItemIndex = this.newSelectedStoreTags.indexOf(selectedTagItem);
        if (newTagItemIndex < 0) {
            this.newSelectedStoreTags.push(selectedTagItem);
        } else {
            this.newSelectedStoreTags.splice(newTagItemIndex, 1);
        }
        selectedTagItem.isChecked = !selectedTagItem.isChecked;
    }

    onCancel() {
        // on cancelling out, we only undo the checkmarks
        for (let aStore of this.newSelectedStoreTags) {
            aStore.isChecked = !aStore.isChecked;
        }
        this.dialogRef.close();
    }
    submitTagsForStoreRendering() {
        //on submit, update the array and return the array of stores that have been selected
        for (let aTag of this.newSelectedStoreTags) {
            //handle single group being clicked
            if (aTag.isChecked) {
                this.selectedStoreTags.push(aTag);
            } else {
                let tagItemIndex = this.selectedStoreTags.indexOf(aTag);
                this.selectedStoreTags.splice(tagItemIndex, 1);
            }
        }
        this.dialogRef.close({ data: { selectedTagsForRenderingStores: this.selectedStoreTags } });
    }
}
