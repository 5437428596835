import { Component, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { AppConstants } from '../../../app.constants';
import { StoreGroupApiService, StoreGroup } from '../services/store-group.resource';
import { DeleteStoreGroupDialog } from './delete-store-group-dialog.component';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'allStoreGroups',
    templateUrl: 'src/app/features/store-groups/templates/store-groups.manage.html',
})
export class AllStoreGroupsComponent {
    @ViewChild('form')
    public form: NgForm;

    constructor(
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(StoreGroupApiService) private storeGroupApiService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {}

    private MAX_STOREGROUPS;
    public captions;
    public passedConceptId;
    public editNameBackup;
    public conceptName;
    public newStoreGroup;
    public searchStr;
    public showStoreGroupOptions: boolean = true;
    public showConfirmDelete: boolean = false;
    public currentStoreGroupToDelete;
    public currentStoreGroupToDeleteDependentStores;
    public storeGroups;
    public addStoreGroup;

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.MAX_STOREGROUPS = AppConstants.MAX_STOREGROUPS;
        this.actRoute.params.subscribe((params) => {
            this.passedConceptId = +params['pathId'];
            this.conceptName = params['pathName'];
        });
        this.editNameBackup = '';

        this.storeGroupApiService
            .getAllStoreGroupsForConcept(this.passedConceptId)
            .then((storeGroups) => {
                for (let storeGroup of storeGroups) {
                    storeGroup.currentEdit = false;
                }
                this.storeGroups = storeGroups;
            })
            .catch((response) => {
                this.snackbarService.errorMessageBottom(response.error.reason);
            });

        this.newStoreGroup = {
            displayName: '',
            conceptID: this.passedConceptId,
        };
    }

    createStoreGroup() {
        if (this.storeGroups.length >= this.MAX_STOREGROUPS) {
            this.snackbarService.errorMessageBottom(
                this.captions.limitOf + this.MAX_STOREGROUPS + ' ' + this.captions.groupItemsCreated
            );
        } else {
            this.storeGroupApiService
                .createStoreGroup(this.newStoreGroup)
                .then((value) => {
                    let newStoreGroup = {
                        groupID: value.id,
                        displayName: value.displayName,
                        currentEdit: false,
                        numberOfItems: 0,
                    };
                    this.storeGroups.push(newStoreGroup);
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
            this.newStoreGroup.displayName = '';
            this.form.resetForm();
        }
    }

    startStoreGroupEdit(storeGroup) {
        this.showStoreGroupOptions = false;
        storeGroup.currentEdit = true;
        this.editNameBackup = storeGroup.displayName;
    }

    updateStoreGroup(storeGroup) {
        if (storeGroup && storeGroup.displayName) {
            this.storeGroupApiService
                .updateStoreGroup(storeGroup)
                .then((value) => {
                    setTimeout(() => {
                        this.showStoreGroupOptions = true;
                    }, 50);
                    storeGroup.currentEdit = false;
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
    }

    revertEdit(storeGroup) {
        setTimeout(() => {
            this.showStoreGroupOptions = true;
        }, 50);
        storeGroup.currentEdit = false;
        storeGroup.displayName = this.editNameBackup;
    }

    deleteStoreGroupDialog(ev, storeGroup) {
        this.currentStoreGroupToDelete = storeGroup;
        const dialogRef = this.dialog.open(DeleteStoreGroupDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            disableClose: true,
            data: {
                currentStoreGroupToDelete: this.currentStoreGroupToDelete,
                storeGroups: this.storeGroups,
                currentStoreGroupToDeleteDependentStores: this.currentStoreGroupToDeleteDependentStores,
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    startGroupMembersManage(storeGroup) {
        this.router.navigate(['concepts/groupMembers', this.passedConceptId, this.conceptName, storeGroup.groupID]);
    }

    changedSearchText(searchStr: string) {
        this.searchStr = searchStr;
    }

    cancelChanges() {
        this.router.navigate(['concepts/allConcepts']);
    }

    filterStoreGroup(storeGroup: StoreGroup, searchStr: string) {
        return !searchStr || storeGroup.displayName.toLowerCase().includes(searchStr.toLowerCase());
    }
}