import { Component, Inject, Optional } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'wid019',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-019.html',
})
export class Wid019 {
    public captions: any;
    public isTimedOut: boolean = false;
    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.captions = this.captionService.captions;
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    ngOnInit() {
        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }

        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }

        // parse data from this.widget
    }
}
