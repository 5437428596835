import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { StoreGroupMembersApiService } from '../services/store-group-member.resource';

interface DeleteStoreGroupMemberDialogData {
    currentGroupMemberToDelete: any;
    currentGroupMemberToDeleteDependentStore: any;
    groupMembers: any;
}

@Component({
    selector: 'deleteStoreGroupMemberDialog',
    templateUrl: 'src/app/features/store-groups/templates/delete-group-member-dialog.html',
})
export class DeleteStoreGroupMemberDialog {
    public captions;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DeleteStoreGroupMemberDialogData,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService,
        @Inject(CaptionService) private captionService,
        @Inject(SnackbarService) private snackbarService: SnackbarService
    ) {
        this.captions = this.captionService.captions;
    }

    deleteGroupMember() {
        if (typeof this.data.currentGroupMemberToDelete !== 'undefined' && this.data.currentGroupMemberToDelete != {}) {
            this.storeGroupMembersApiService
                .deleteStoreGroupItem(this.data.currentGroupMemberToDelete.id)
                .then(() => {
                    let counter = 0;
                    for (let groupMember of this.data.groupMembers) {
                        if (this.data.currentGroupMemberToDelete.id == groupMember.id) {
                            this.data.groupMembers.splice(counter, 1);
                        }
                        counter++;
                    }
                    this.data.currentGroupMemberToDelete = {};
                    this.data.currentGroupMemberToDeleteDependentStore = [];
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
    }
}
