import { formatDate } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChartType } from 'chart.js';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';
import { WidgetConstants } from '../../constants/widget.constants';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'wid007',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-007.html',
})
export class Wid007 {
    public captions: any;
    public isTimedOut: boolean = false;
    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    public typeLine: ChartType = 'line';
    public hasLegend = true;

    public headers: string[];
    public labels: string[];
    public datasets: any[];

    public hourData: any[];
    public dayData: string[];

    public activeHour: number = -1;
    public activeView: number = 0;

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage
    ) {
        this.captions = this.captionService.captions;
        this.headers = [this.captions.sevenDaysPrior, this.captions.currentSales];
        this.labels = [
            '01:00',
            '02:00',
            '03:00',
            '04:00',
            '05:00',
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
            '24:00',
        ];

        this.hourData = Array(24).fill(['0.00', '0.00']);
        this.dayData = ['0.00', '0.00'];
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    cycleViews() {
        this.activeView = (this.activeView + 1) % 3;
    }

    ngOnInit() {
        //adding the configured value for the activeView
        let portalData = this.browserStorage.getSessionstorage('portalData', null);
        let data = JSON.parse(JSON.stringify(portalData));
        let config;
        if (data.WidgetValueConfig) {
            config = JSON.parse(data.WidgetValueConfig);
        }
        this.activeView = config?.sevenDaysPriorVsCurrentSales1column1Selection
            ? parseInt(config.sevenDaysPriorVsCurrentSales1column1Selection)
            : 0;

        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }
        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }
        if (this.areDatapointsInvalid) {
            return;
        }

        let hourDataset = this.widget.data[0].Record;
        this.hourData;
        if (!hourDataset) {
            this.datasets = [];
            this.hourData = Array(24).fill(['0.00', '0.00']);
            this.dayData = ['0.00', '0.00'];
            return;
        }

        let sevendayAgo = Array(24).fill('0');
        let closedSales = Array(24).fill('0');

        hourDataset = Array.isArray(hourDataset) ? hourDataset : [hourDataset];
        hourDataset.forEach((hour) => {
            this.hourData[hour.HOURTIMEORD] = [
                hour.U_7DAYSAGOCLOSED ? hour.U_7DAYSAGOCLOSED.toFixed(2) : '0.00',
                hour.X_GROSSSALESCLOSED ? hour.X_GROSSSALESCLOSED.toFixed(2) : '0.00',
            ];
            sevendayAgo[hour.HOURTIMEORD] = hour.U_7DAYSAGOCLOSED ? hour.U_7DAYSAGOCLOSED.toFixed(2) : '0.00';
            closedSales[hour.HOURTIMEORD] = hour.X_GROSSSALESCLOSED ? hour.X_GROSSSALESCLOSED.toFixed(2) : '0.00';
        });

        this.datasets = [
            { label: this.headers[0], data: sevendayAgo },
            { label: this.headers[1], data: closedSales },
        ];

        let dayDataset = this.widget.data[1].Record;
        if (!dayDataset) {
            this.dayData = ['0.00', '0.00'];
            return;
        }

        this.dayData = [
            dayDataset.U_7DAYSAGOCLOSED ? dayDataset.U_7DAYSAGOCLOSED.toFixed(2) : '0.00',
            dayDataset.X_GROSSSALESCLOSED ? dayDataset.X_GROSSSALESCLOSED.toFixed(2) : '0.00',
        ];

        let storeInfoDataset = this.widget.data[2].Record;
        if (storeInfoDataset && dayDataset) {
            let currentOpendate = formatDate(dayDataset.OPENDATE, WidgetConstants.DATE_FORMAT_SIMPLE, 'en-US');
            let latestOpendate = formatDate(storeInfoDataset.OPENDATE, WidgetConstants.DATE_FORMAT_SIMPLE, 'en-US');

            this.activeHour = -1;
            if (currentOpendate === latestOpendate) {
                this.activeHour = new Date(storeInfoDataset.STOREDATETIME).getHours();
            }
        }
    }
}
