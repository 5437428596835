import { Component, Inject, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';

@Component({
    selector: 'wid025',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-025.html',
})
export class Wid025 {
    public captions: any;
    public isTimedOut: boolean = false;
    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    public headers: string[];
    public labels: string[];
    public datasets: any[];
    public needleValue: number;

    public gaugeLabels: any[];
    public gaugeDatasets: any[];

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.captions = this.captionService.captions;
        this.headers = [this.captions.category, this.captions.valueFront];
        this.labels = [this.captions.salesFront, this.captions.labour, this.captions.labourCostPercent];

        this.gaugeDatasets = [{ data: [] }];
        this.gaugeDatasets[0].data = this.calGaugeDatasets();

        this.gaugeLabels = [];
        this.gaugeLabels[0] = '0% - ' + this.gaugeDatasets[0].data[0] + '%';
        this.gaugeLabels[1] =
            this.gaugeDatasets[0].data[0] +
            1 +
            '% - ' +
            (this.gaugeDatasets[0].data[0] + this.gaugeDatasets[0].data[1]) +
            '%';
        this.gaugeLabels[2] = this.gaugeDatasets[0].data[0] + this.gaugeDatasets[0].data[1] + 1 + '% - 100%';
        this.gaugeLabels[3] = '100% - 105%';
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    calGaugeDatasets() {
        let portalData = this.browserStorage.getSessionstorage('portalData', null);
        let data = JSON.parse(JSON.stringify(portalData));
        let config;
        if (data.WidgetValueConfig) {
            config = JSON.parse(data.WidgetValueConfig);
        }

        let dataset = [];
        dataset[0] = config?.labourCostPercentageColumn1Selection ? config.labourCostPercentageColumn1Selection : 25;
        dataset[1] = config?.labourCostPercentageColumn2Selection ? config.labourCostPercentageColumn2Selection : 15;
        dataset[2] = 100 - (dataset[0] + dataset[1]);
        dataset[3] = 5;

        return dataset;
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    ngOnInit() {
        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }

        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }

        let labourData = this.widget.data[0].Record;
        if (!labourData) {
            this.labels = [];
            this.datasets = [];
            this.needleValue = 0;
            return;
        }

        //Getting values from the widget value
        this.datasets = [
            {
                data: [
                    labourData.SALES ? labourData.SALES.toFixed(2) : '0.00',
                    labourData.LABOUR ? labourData.LABOUR.toFixed(2) : '0.00',
                    labourData.LABOURCOSTPERCENT ? labourData.LABOURCOSTPERCENT.toFixed(2) : '0.00',
                ],
            },
        ];

        this.needleValue = labourData.LABOURCOSTPERCENT;
    }
}
