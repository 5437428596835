import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmationDialogData {
    title: string;
    message: string;
    confirm: string;
    cancel: string;
}

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: 'src/app/utils/shared-templates/confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent implements OnInit {
    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
    ) { }

    openDialog() {
        this.dialog.open(ConfirmationDialogComponent);
    }

    ngOnInit(): void { }
}
