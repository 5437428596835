import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { StoreGroupApiService } from '../services/store-group.resource';

interface DeleteStoreGroupDialogData {
    currentStoreGroupToDelete: any;
    storeGroups: any;
    currentStoreGroupToDeleteDependentStores: any;
}

@Component({
    selector: 'deleteStoreGroupDialog',
    templateUrl: 'src/app/features/store-groups/templates/delete-store-group-dialog.html',
})
export class DeleteStoreGroupDialog {
    public captions: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DeleteStoreGroupDialogData,
        @Inject(StoreGroupApiService) private storeGroupApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(CaptionService) private captionService
    ) {
        this.captions = this.captionService.captions;
    }

    deleteStoreGroup() {
        if (typeof this.data.currentStoreGroupToDelete !== 'undefined' && this.data.currentStoreGroupToDelete != {}) {
            this.storeGroupApiService
                .deleteStoreGroup(this.data.currentStoreGroupToDelete.groupID)
                .then(() => {
                    let counter = 0;
                    for (let storeGroup of this.data.storeGroups) {
                        if (this.data.currentStoreGroupToDelete.groupID == storeGroup.groupID) {
                            this.data.storeGroups.splice(counter, 1);
                        }
                        counter++;
                    }
                    this.data.currentStoreGroupToDelete = {};
                    this.data.currentStoreGroupToDeleteDependentStores = [];
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
    }
}
