import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth/services/auth.resource';
import { CaptionService } from '../../utils/shared-services/caption.service';

// data to share with hash dialog Component
export interface HashData {
    templates: any;
}

@Component({
    selector: 'aboutUs',
    templateUrl: 'src/app/auth/templates/about-us.html',
})
export class AboutUsComponent {
    public captions;
    public profile;
    public templates;
    isDataAvailable: boolean;
    public categories: any = [];

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(AuthService) private authService,
        @Inject(MatDialog) private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.authService.getAboutUsInfo().then((response) => {
            this.profile = response.aboutTeamMembers;
            this.groupTeamByCategory();
        });
        this.authService.getTemplatesHashs().then((templates) => {
            this.templates = templates;
        });
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
    }

    displayIP() {
        this.authService.getPing().then((response) => {
            alert(response);
        });
    }

    showHashedTemplates() {
        const dialogRef = this.dialog.open(TemplateHashDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                templates: this.templates,
            },
        });
    }

    groupTeamByCategory() {
        const teamMembers = this.profile.teamMembers;

        if (teamMembers.length > 0) {
            this.isDataAvailable = true;

            teamMembers.forEach(teamMember => {
                const categories = teamMember.category;

                categories.forEach(category => {
                    let foundCategory = this.categories.find(c => c.type === category);

                    if (!foundCategory) {
                        foundCategory = {
                            type: category,
                            teamMembers: []
                        };
                        this.categories.push(foundCategory);
                    }

                    foundCategory.teamMembers.push(teamMember);
                });
            });
        } else {
            this.isDataAvailable = false;
        }
    }
}

@Component({
    selector: 'template-hash-dialog',
    templateUrl: 'src/app/auth/templates/template-hash-dialog.html',
})
export class TemplateHashDialog {
    public captions;
    templates: any;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<TemplateHashDialog>,
        @Inject(CaptionService) private captionService,
        @Inject(MAT_DIALOG_DATA) public data: HashData
    ) {
        this.captions = this.captionService.captions;
        this.templates = data.templates;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
