import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';

export interface ApiConsumer {
    id: number,
    displayName: string,
    isInternal: number,
    prefixToken: string,
    tokenHint: string
    apiPackageID: number,
}

@Injectable()
export class ApiConsumerService {
    private getApiConsumersUrl: string = 'api-consumers';
    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getApiConsumerById(ID: number): Promise<ApiConsumer> {
        const url = this.BASE_URL + this.getApiConsumersUrl;
        const data$ = this.http.get(url, { params: { ID } });
        const value = await lastValueFrom(data$) as ApiConsumer;
        return value;
    }
}
