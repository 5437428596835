import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageReportsData } from "../../internal";
import { ReportApiService } from '../../services/reports.resource';

@Component({
    selector: 'deleteTemplateDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/delete-template.tpl.html',
})
export class DeleteTemplateDialog {
    selectedTemplateID: any;
    captions: any;
    reportTemplates: any;
    selectedTemplates: any;
    showJobs: boolean;
    showIframe: boolean;
    showBackButton: boolean;
    showDeleteButton: boolean;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteTemplateDialog>,
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(MAT_DIALOG_DATA) public data: ManageReportsData,
    ) {
    }

    ngOnInit(): void {
        this.captions = this.data.captions;
        this.selectedTemplates = this.data.selectedTemplates;
        this.reportTemplates = this.data.reportTemplates;
    }

    deleteCustomTemplate() {
        let updatedCustomReports = [];
        for (let template of this.selectedTemplates) {
            if (template.isChecked) {
                this.reportApiService.deleteCustomTemplate(template.id);
                for (let customReport of this.reportTemplates.customTemplates) {
                    if (customReport.id != template.id) {
                        updatedCustomReports.push(customReport);
                    }
                }
            }
        }
        this.reportTemplates.customTemplates = updatedCustomReports;
        this.showJobs = true;
        this.showIframe = false;
        this.showBackButton = false;
        this.showDeleteButton = false;
        this.closeDialog(true);
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    closeDialog(state = false) {
        this.dialogRef.close(state);
    }
}