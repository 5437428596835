import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ExtractionAPIService } from '../../../utils/shared-services/extraction.resource';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { ConceptApiService } from '../../concepts/services/concept.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserEmitterService } from '../../users/services/user-emitter.service';
import { UserSecurityApiService } from '../../users/services/user-security.resource';
import { Builder } from 'xml2js';
import { json2csv } from 'json-2-csv';
import { StepperOrientation } from '@angular/cdk/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { WebUserSecurityWithStoreList } from '../../users/models/user-security.model';
import { ExtractionData, ExtractionTables } from 'src/app/utils/shared-models/extraction.model';
import { Moment } from 'moment';
import { Concept } from '../../concepts/models/concept.model';

@Component({
    selector: 'app-extract-data',
    templateUrl: 'src/app/features/stores/templates/extract-data.component.html',
})
export class ExtractDataComponent implements OnInit {
    private conceptID: number;
    private conceptName: string;
    private withOpenDate: string[];
    private completeExtractionResponseXML: string;
    private formBuilder: FormBuilder = new FormBuilder();

    public firstFormGroup = this.formBuilder.group({
        firstCtrl: ['', Validators.required],
    });
    public secondFormGroup = this.formBuilder.group({
        secondCtrl: [null as Moment, Validators.required],
    });

    public captions: Record<string, string>;
    public storeID: string;
    public extractionOptions: string[];
    public isDateNeeded: boolean = false;
    public extractionXMLUrl: SafeResourceUrl;
    public extractionJSONUrl: SafeResourceUrl;
    public extractionCSVUrl: SafeResourceUrl;
    public partialExtractionResponseXML: string;
    public isConceptLevel: boolean = false;
    public selectedUserSecurity: any;
    public isExtractionPage: boolean = true;
    public storeStep: number = 1;
    public typeStep: number = 1;
    public dateStep: number = 2;
    public maxDate: Date;
    public showMore: boolean = false;
    public moreOrLess: string;
    public readyForDownload: boolean = false;
    public stepperOrientation: Observable<StepperOrientation>;
    private fileUrl = 'https://pixelpointhqdocuments.s3.us-east-1.amazonaws.com/Collections/Realtime_data_extraction.json';

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ExtractionAPIService) private extractionAPIService: ExtractionAPIService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) private router: Router,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UserSecurityApiService) private userSecurityApiService: UserSecurityApiService,
        @Inject(UserEmitterService) private userEmitterService: UserEmitterService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver
    ) {
        this.conceptID = +this.actRoute.snapshot.paramMap.get('pathId');
        this.conceptName = this.actRoute.snapshot.paramMap.get('pathName');
        this.storeID = this.actRoute.snapshot.paramMap.get('storeID');
        this.itemStorageService.selectedConcept = this.conceptID;
        this.maxDate = new Date();
        this.maxDate.setDate(this.maxDate.getDate() + 1);

        // get selected user security
        this.userSecurityApiService
            .getSelectedUserById(this.userAccessHandler.getUserAccess().webUserID)
            .then((selectedUserSecurity: WebUserSecurityWithStoreList) => {
                this.itemStorageService.webUserID = selectedUserSecurity.webUserID.toString();
                selectedUserSecurity.conceptID = this.conceptID;
                this.selectedUserSecurity = selectedUserSecurity;

                // get concept
                this.conceptApiService
                    .getConceptById(this.conceptID)
                    .then((concept: Concept) => {
                        this.userEmitterService.conceptLoadedDirective(concept);
                        // In case actRoute doesn't have 'pathName'
                        this.conceptName = concept.displayName;
                    });
            });

        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.stepperOrientation = this.breakpointObserver
            .observe('(min-width: 640px)')
            .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
    }

    ngOnInit(): void {
        this.moreOrLess = this.captions.showMore;
        this.extractionAPIService.getExtractionTables().then((value: ExtractionTables) => {
            this.extractionOptions = [...value.withOpenDate, ...value.withoutOpenDate];
            this.withOpenDate = value.withOpenDate;
        });

        if (!this.storeID) {
            this.isConceptLevel = true;
            this.typeStep++;
            this.dateStep++;
        }
    }

    checkOpenDate(): void {
        this.isDateNeeded = this.isOpenDate(this.firstFormGroup?.controls?.firstCtrl?.value);
    }

    // function that calls the api and gets xml response
    onSubmit(): void {
        if (this.firstFormGroup.controls?.firstCtrl?.value) {
            if (this.isDateNeeded) {
                if (this.secondFormGroup.controls?.secondCtrl?.value) {
                    this.extractionAPIService
                        .extractTable(
                            this.firstFormGroup.controls?.firstCtrl?.value,
                            this.storeID,
                            this.secondFormGroup.controls?.secondCtrl?.value?.format('YYYY-MM-DD')
                        )
                        .then(response => this.handleResponse(response));
                } else {
                    this.snackbarService.errorMessageTop(this.captions.selectDateMessage);
                }
            } else {
                this.extractionAPIService
                    .extractTable(this.firstFormGroup.controls?.firstCtrl?.value, this.storeID)
                    .then(response => this.handleResponse(response));
            }
        } else {
            this.snackbarService.errorMessageTop(this.captions.selectExtractionMessage);
        }
    }

    // Opens the file in a new browser tab
    openFile() {
        window.open(this.fileUrl, '_blank');
    }

    downloadFile() {
        fetch(this.fileUrl)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch file');
                }
                return response.blob(); // Convert to Blob
            })
            .then((blob) => {
                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'Realtime_data_extraction.json'; // File name for download
                link.click();

                // Clean up the temporary URL
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Download failed:', error);
            });
    }

    // returns the user to the default page
    onCancel(): void {
        if (this.isConceptLevel) {
            this.router.navigate(['concepts/manageConceptApis/', this.conceptID, this.conceptName]);
        } else {
            this.router.navigate(['stores/allStores/', this.conceptID, this.conceptName]);
        }
    }

    storePickerHandler(store: string): void {
        this.storeID = store;
    }

    noStoreHandler(): void {
        this.snackbarService.errorMessageTop(this.captions.selectStoreMessage);
    }

    // swaps the short and long xml responses
    swapXML(): void {
        var temp = this.partialExtractionResponseXML;
        this.partialExtractionResponseXML = this.completeExtractionResponseXML;
        this.completeExtractionResponseXML = temp;
        this.moreOrLess = this.moreOrLess == this.captions.showMore ? this.captions.showLess : this.captions.showMore;
    }

    // checks to see if you need a date
    private isOpenDate(extractionOption: string): boolean {
        return this.withOpenDate.indexOf(extractionOption) >= 0;
    }

    // method that handles the xml response and converts it to the appropriate format
    private async handleResponse(response: ExtractionData): Promise<void> {
        const extractionResponseJSON = JSON.stringify(response);
        const csv = await json2csv(response['data']);

        var xmlBuilder = new Builder();
        this.completeExtractionResponseXML = xmlBuilder.buildObject(response);

        // get first 25 lines of the xml response
        this.partialExtractionResponseXML = this.completeExtractionResponseXML.substring(
            0,
            this.getPosition(this.completeExtractionResponseXML, '\n', 25)
        );
        if (this.partialExtractionResponseXML.length < this.completeExtractionResponseXML.length) {
            this.showMore = true;
            this.partialExtractionResponseXML += '\n...';
        }
        this.extractionJSONUrl = this.extractionAPIService.generateURL(extractionResponseJSON);
        this.extractionXMLUrl = this.extractionAPIService.generateURL(this.completeExtractionResponseXML);
        this.extractionCSVUrl = this.extractionAPIService.generateURL(csv);
        this.readyForDownload = true;
    }

    /**
     * @name getPosition gets the index of the nth instance of a string
     * @param string original string that is being split
     * @param delimiter delimiter that splits the string
     * @param nthOccurence nth instance of delimiter
     * @returns
     */
    private getPosition(string: string, delimiter: string, nthOccurence: number): number {
        return string.split(delimiter, nthOccurence).join(delimiter).length;
    }
}
