import { Inject, Injectable } from '@angular/core';
import * as wijmo from 'wijmo/wijmo';
import { AppSettings } from './app-settings.service';
import { AppConstants } from './app.constants';

@Injectable()
export class AppInitService {
    constructor(
        @Inject(AppSettings) private appSettings) { }

    Init() {
        return new Promise<void>((resolve) => {
            wijmo.setLicenseKey(AppConstants.WIJMO_LICENSE.PPHQ);
            this.appSettings.applyTheme();
            this.redirectHttpToHttps();
            resolve();
        });
    }
    redirectHttpToHttps() {
        let url = window.location.href.substring(window.location.protocol.length);
        let urlSubstring = 'pixelpointhq';

        if (location.protocol != 'https:' && !_isLocalhost()) {
            location.href = 'https:' + window.location.href.substring(window.location.protocol.length);
        }

        function _isLocalhost() {
            if (url.includes(urlSubstring)) return false;
            else return true;
        }
    }
}