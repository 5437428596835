import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { StoreApiService } from '../../../stores/services/store.resource';

interface Parameters {
    tempConfig: any;
    localTimeZone: any;
    setTimeZone: any;
    setIdType: any;
    updateTimeRange: any;
    storeID: any;
    timeRange: any;
    reqType: any;
    vendorID: any;
    store: any;
}

@Component({
    selector: 'eoi-config-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-config.dialog.html',
})
export class EoiConfigDialog {
    public captions: Record<string,string>;

    constructor(
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EoiConfigDialog>
    ) {
        this.captions = this.captionService.captions;
        this.data.tempConfig.utcOrLocal = parseInt(this.data.tempConfig.utcOrLocal);
        this.data.tempConfig.queueOrTransferID = parseInt(this.data.tempConfig.queueOrTransferID);
    }

    saveConfig() {
        this.data.setTimeZone(this.data.tempConfig.utcOrLocal.toString());
        this.data.setIdType(this.data.tempConfig.queueOrTransferID.toString());
        this.storeApiService.updateStoreWithAddress(this.data.store).then(() => {
            this.dialogRef.close();
        });
        this.data.updateTimeRange(this.data.storeID, this.data.timeRange, this.data.reqType, this.data.vendorID);
    }
}
