import { Component, Input } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { WidgetConstants } from '../../../constants/widget.constants';

@Component({
    selector: 'chart-bar',
    templateUrl: 'src/app/features/store-portal/widgets/charts/templates/chart-bar.html',
})
export class ChartBar {
    @Input() public type: ChartType = 'bar';
    public data: ChartDataset[] = [];

    @Input() public title: string;
    @Input() public labels: string[];
    @Input() public datasets: any[];
    @Input() public direction: string = 'vertical';
    @Input() public hasLegend = false;
    @Input() public colors: any = WidgetConstants.CHART_COLORS_DEFAULT;
    @Input() public options: ChartOptions = {
        maintainAspectRatio: false,
        indexAxis: 'x',
        plugins: {
            tooltip: {
                callbacks: { label: (tooltipItem) => (Number)(tooltipItem.raw).toFixed(2) }
            }
        },
        scales: {
            x: { suggestedMin: 0 },
            y: { suggestedMin: 0 }
        },
    };

    constructor() { }

    ngOnChanges() {
        if (!this.datasets) {
            return;
        }
        if (this.direction === 'horizontal') {
            this.options.indexAxis = 'y';
        }
        this.data = [];
        if(this.datasets.length > 1) {
            this.datasets.forEach((dataset, i) => {
                this.data.push({
                    label: dataset.label,
                    data: dataset.data,
                    backgroundColor: this.colors.backgroundColor[i],
                    hoverBackgroundColor: this.colors.hoverBackgroundColor[i]
                });
            });
        }
        else {
            this.datasets.forEach(dataset => {
                this.data.push({
                    label: dataset.label,
                    data: dataset.data,
                    backgroundColor: this.colors.backgroundColor,
                    hoverBackgroundColor: this.colors.hoverBackgroundColor
                });
            });
        }
    }
}
