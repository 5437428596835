import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { AppConstants } from '../../../app.constants';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { StoreApiService } from '../services/store.resource';
import { ConfirmResetQ1Dialog } from './dialogs/confirm-reset-Q1-dialog.component';
import { StoreDownloadDialog } from './dialogs/store-download-dialog.component';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Store, StoreBase, StoreBaseWithAddress } from '../models/store.model';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'editStore',
    templateUrl: 'src/app/features/stores/templates/edit-store.html',
})
export class EditStoreComponent {

    private conceptID: number;
    private storeID: number;
    private conceptName: string;

    protected _onDestroy = new Subject<void>();

    public captions: Record<string, string>;
    public timeZones: string[];
    public currencyCodes: string[];
    public billTypes: string[];
    public store: StoreBaseWithAddress;
    public hasData: boolean;
    public countries: string[];
    public states: string[];
    public showStateDropdown: boolean = false;
    public countriesWithStates = ['USA', 'Canada'];
    public conceptLocalTimezone: string;
    public filteredTimeZones: ReplaySubject<any> = new ReplaySubject<any>(1);
    public timeZonesCtrl: FormControl<string> = new FormControl<string>('');

    constructor(
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(Clipboard) private clipboard: Clipboard,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.currencyCodes = AppConstants.CURRENCYCODES_NG;
        this.billTypes = AppConstants.BILLTYPES_NG;
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });

        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['pathId'];
            this.conceptName = params['pathName'];
            this.storeID = +params['storeID'];
        });

        if (!this.storeID) {
            this.router.navigate(['home']);
        }

        this.storeApiService.getStoreById(this.storeID).then((store: StoreBaseWithAddress) => {
            this.store = store;
            this.storeApiService.hasData(store.conceptID, store.id).then((hasData: boolean) => {
                this.hasData = hasData;
            });
            if (store.address.country === 'USA' || store.address.country === 'Canada') {
                this.showStateDropdown = true;
                this.countryChanged();
            }
        });

        this.utilsApiService.getCountryList().then((countryList) => {
            this.countries = countryList.countries;
        });

        if (typeof this.itemStorageService.conceptLocalTimezone == 'undefined') {
            this.conceptLocalTimezone = this.captions.utc;
        }
        else {
            this.conceptLocalTimezone = this.itemStorageService.conceptLocalTimezone;
        }

        this.getTimeZones();

        this.timeZonesCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterTimezones();
        });
    }

    countryChanged(): void {
        let stateForCountry = this.store.address.state;
        if (this.countriesWithStates.indexOf(this.store.address.country) >= 0) {
            this.utilsApiService.getStates(this.store.address.country).then((states) => {
                this.states = states.states;
                this.showStateDropdown = true;
                this.store.address.state = stateForCountry;
            });
        }
        else {
            this.showStateDropdown = false;
            this.store.address.state = '';
        }
    }

    saveChanges(): void {
        let trigger = false;
        this.storeApiService.getStoresByConceptId(this.store.conceptID).then((stores: StoreBase[]) => {
            for (let store of stores) {
                if (
                    store.displayName.toUpperCase() === this.store.displayName.toUpperCase() &&
                    this.store.id != store.id
                ) {
                    trigger = true;
                    this.snackbarService.errorMessageBottom(this.captions.duplicateStoreName);
                    return;
                }
            }
            if (!trigger) {
                this.userAccessHandler.setRoleItem(this.store);
                this.storeApiService.updateStoreWithAddress(this.store).then(() => {
                    this.router.navigate(['stores/allStores', this.store.conceptID, this.conceptName]);
                });
            }
        });
    }

    cancelChanges(): void {
        let conceptID = this.store.conceptID;
        if (typeof conceptID === 'undefined') {
            this.router.navigate(['home']);
        }
        else {
            this.router.navigate(['stores/allStores', conceptID, this.conceptName]);
        }
    }

    copyToClipboard(content: string): void {
        this.clipboard.copy(content);
        this.snackbarService.successMessageBottom(this.captions.copied);
    }

    colorSelected(color: string): void {
        this.store.webBgColor = color;
    }

    confirmReset(): void {
        const dialogRef = this.dialog.open(ConfirmResetQ1Dialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            disableClose: true,
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close(false);
        });

        dialogRef.afterClosed().subscribe((res: boolean) => {
            if (res) {
                this.storeApiService.resetReplicationToken(this.store.id, this.store.replicationToken).then((store: Store) => {
                    this.store.replicationToken = store.replicationToken;
                });
            }
        })

    }

    showDownloadDialog(): void {
        this.dialog.open(StoreDownloadDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
        });
    }

    protected filterTimezones(): void {
        if (!this.timeZones) {
            return;
        }
        // get the search keyword
        let search = this.timeZonesCtrl.value;
        if (!search) {
            this.filteredTimeZones.next(this.timeZones.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the time-zones
        this.filteredTimeZones.next(
            this.timeZones.filter(zone => zone.toLowerCase().indexOf(search) > -1)
        );
    }

    private getTimeZones(): void {
        this.storeApiService.getTimeZones().then((result) => {
            this.timeZones = result;
            this.filteredTimeZones.next(this.timeZones.slice());
        });
    }
}
