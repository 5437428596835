import { Inject, Injectable } from '@angular/core';
import { StoreBase } from '../../../stores/models/store.model';
import { StoreApiService } from '../../../../features/stores/services/store.resource';
import { GeneralParameterPost, WidgetInstance, WidgetInstancePut } from '../../models/widget.model';
import { DashboardApiService } from '../../services/dashboard.resource';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class WidgetCommonService {

    constructor(
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(StoreApiService) private storeApiService: StoreApiService
    ) {}

    getWidgetRefreshInterval(widgetInstanceId: number): Promise<number> {
        return lastValueFrom(this.dashboardApiService.getWidgetInstanceById$(widgetInstanceId)).then((widgetInstance: any) => {
            for (let param of widgetInstance?.parameters) {
                if (param?.type === 'refreshInterval') {
                    return param?.value;
                }
            }
        });
    }

    updateWidgetParameters(widget: WidgetInstance): WidgetInstancePut {
        let widgetInstancePut: WidgetInstancePut;

        let parameters: GeneralParameterPost[] = [];
        for (let par of widget?.parameters) {
            parameters.push({ id: par?.id, value: par?.value });
        }
        if (parameters.length <= 5) {
            parameters.push({ id: 3, value: '' }, { id: 4, value: '' });
        }

        widgetInstancePut.parameters = parameters.sort(this.updateWidgetHelperCompare);
        widgetInstancePut.displayName = widget.displayName;
        widgetInstancePut.conceptID = parseInt(widget?.parameters[0].value);
        widgetInstancePut.storesSelected = widget.parameters[5].value.split(',').length;

        this.storeApiService.getStoresByConceptId(widgetInstancePut.conceptID).then((stores: StoreBase[]) => {
            widgetInstancePut.storesTotal = stores.length;
        });

        return widgetInstancePut;
    }

    updateWidgetHelperCompare(a: GeneralParameterPost, b: GeneralParameterPost): -1 | 0 | 1 {
        if (a.id < b.id) {
            return -1;
        } else if (a.id > b.id) {
            return 1;
        } else if (a.id == b.id) {
            return 0;
        }
    }
}
