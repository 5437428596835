import { Component, Inject, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserConstants } from '../../users/users.constants';
import { RecycleBinApiService } from '../services/recycle-bin.resource';
import { ConfirmPermanentDeleteDialog } from './dialogs/confirm-permanent-delete-dialog.component';
import { NoItemSelectedDialog } from './dialogs/no-item-selected-dialog.component';
import { RestoreItemsConfirmationDialog } from './dialogs/restore-items-confirmation-dialog.component';

@Component({
    selector: 'recycleBin',
    templateUrl: 'src/app/features/recycle-bin/templates/recycle-bin.html',
})
export class RecycleBinComponent {
    private USER_ACCESS;
    private USER_PRIVILEGES;
    public conceptsWithInactiveStores;
    public inactiveStoreForConceptDelete;
    public currentUser;
    public inactiveItems = [];
    public allConceptsSelect;
    public allStoresSelect;
    public itemsToRestore;
    public itemsToRemove;
    public conceptsToCheck;
    public inactiveStoresToDelete;
    public captions;
    public conceptsPredicate: string = 'displayName';
    public conceptsReverse: boolean = true;
    public storesPredicate: string = 'displayName';
    public storesReverse: boolean = true;
    public showDeletedConcepts: boolean = true;
    public showDeletedStores: boolean = true;
    @Input() searchStr: any;

    constructor(
        @Inject(RecycleBinApiService) private recycleBinApiService: RecycleBinApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(UserAccessHandler) private userAccessHandler,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.USER_PRIVILEGES = UserConstants.USER_PRIVILEGES;
        this.currentUser = this.userAccessHandler.getUserAccess();
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        if (this.currentUser.role !== this.USER_ACCESS.GLOBAL) this.showDeletedConcepts = false;
        this.getRecycledItems();
        if (
            this.currentUser.userPrivilege !== this.USER_PRIVILEGES.ALL_PRIVILEGES &&
            this.currentUser.userPrivilege !== null
        ) {
            let privileges = JSON.parse(this.currentUser.userPrivilege),
                deletedConceptsAccess = false,
                deletedStoresAccess = false;
            for (let privilege of privileges) {
                if (privilege.name === this.USER_PRIVILEGES.MANAGE_STORES) deletedStoresAccess = true;
                else if (privilege.name === this.USER_PRIVILEGES.MANAGE_ORGANIZATIONS) deletedConceptsAccess = true;
            }
            if (!deletedStoresAccess) this.showDeletedStores = false;
            if (!deletedConceptsAccess) this.showDeletedConcepts = false;
        }
    }

    getRecycledItems() {
        this.recycleBinApiService.getRecycledItems().then((items: any) => {
            this.inactiveItems = items;
            for (let item of this.inactiveItems) {
                item.isChecked = false;
                let oneDay = 24 * 60 * 60 * 1000;
                if (item.lastChangedUTC === null || typeof item.lastChangedUTC !== 'string') {
                    item.history = 0;
                } else {
                    let properDateFormat = item.lastChangedUTC.substr(0, item.lastChangedUTC.indexOf(' '));
                    item.history = Math.round(
                        Math.abs((new Date().getTime() - new Date(properDateFormat).getTime()) / oneDay)
                    );
                }
            }
        });
    }

    changeOrder(entity, predicate) {
        if (entity === 'Concept') {
            this.conceptsReverse = this.conceptsPredicate === predicate ? !this.conceptsReverse : false;
            this.conceptsPredicate = predicate;
        } else if (entity === 'Store') {
            this.storesReverse = this.storesPredicate === predicate ? !this.storesReverse : false;
            this.storesPredicate = predicate;
        }
    }

    selectAllConcepts() {
        if (this.allConceptsSelect) {
            this.allConceptsSelect = true;
        } else {
            this.allConceptsSelect = false;
        }
        for (let conceptItem of this.inactiveItems) {
            if (conceptItem.type === 'Concept') conceptItem.isChecked = this.allConceptsSelect;
        }
    }

    selectAllStores() {
        if (this.allStoresSelect) {
            this.allStoresSelect = true;
        } else {
            this.allStoresSelect = false;
        }
        for (let storeItem of this.inactiveItems) {
            if (storeItem.type === 'Store') storeItem.isChecked = this.allStoresSelect;
        }
    }

    selectItem(id) {
        this.inactiveItems.map((item) => {
            if (item.id === id) item.isChecked = !item.isChecked;
        });
    }

    restoreItemsDialog() {
        this.itemsToRestore = [];
        for (let item of this.inactiveItems) {
            if (item.isChecked) this.itemsToRestore.push(item);
        }
        if (this.itemsToRestore.length > 0) {
            this.openRestoreItemsConfirmationDialog();
        } else {
            this.openNoItemSelectedDialog();
        }
    }

    getInactiveItemsToBeRemoved() {
        if (this.inactiveItems.length === 0) {
            this.openNoItemSelectedDialog();
        } else {
            let itemsToRemove = [];
            let conceptsToCheck = [];
            for (let inactiveItem of this.inactiveItems) {
                if (inactiveItem.isChecked && inactiveItem.type !== 'Concept') itemsToRemove.push(inactiveItem);
                if (inactiveItem.isChecked && inactiveItem.type === 'Concept') conceptsToCheck.push(inactiveItem);
                let inactiveItemIndex = this.inactiveItems.findIndex((k) => k == inactiveItem);
                if (inactiveItemIndex === this.inactiveItems.length - 1) {
                    this.commenceDelete(itemsToRemove, conceptsToCheck);
                }
            }
        }
    }

    commenceDelete(itemsToRemove, conceptsToCheck) {
        this.itemsToRemove = itemsToRemove;
        this.conceptsToCheck = conceptsToCheck;
        this.inactiveStoresToDelete = [];
        if (conceptsToCheck.length > 0) {
            this.recycleBinApiService
                .getListOfInactiveStoresForConcepts(conceptsToCheck)
                .then((conceptsWithInactiveStores: any) => {
                    this.conceptsWithInactiveStores = conceptsWithInactiveStores;
                    for (let concept of conceptsWithInactiveStores) {
                        for (let inactiveStore of concept.inActiveStores) {
                            this.inactiveStoresToDelete.push(inactiveStore);
                            this.itemsToRemove.push(inactiveStore);
                        }
                    }
                })
                .then(() => {
                    if (this.conceptsWithInactiveStores.length > 0) this.inactiveStoreForConceptDelete = true;
                    this.openPermanentDeleteDialog();
                });
        } else if (itemsToRemove.length > 0) {
            this.openPermanentDeleteDialog();
        } else {
            this.openNoItemSelectedDialog();
        }
    }

    changedSearchText(searchStr) {
        this.searchStr = searchStr;
    }

    openNoItemSelectedDialog() {
        const dialogRef = this.dialog.open(NoItemSelectedDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    openPermanentDeleteDialog() {
        const dialogRef = this.dialog.open(ConfirmPermanentDeleteDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                itemsToRemove: this.itemsToRemove,
                conceptsToCheck: this.conceptsToCheck,
                inactiveStoresToDelete: this.inactiveStoresToDelete,
            },
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.getRecycledItems();
                this.snackbarService.successMessageTop(this.captions.successfulPermanentDelete);
            }
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    openRestoreItemsConfirmationDialog() {
        const dialogRef = this.dialog.open(RestoreItemsConfirmationDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                itemsToRestore: this.itemsToRestore,
            },
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.getRecycledItems();
                this.snackbarService.successMessageTop(this.captions.successfulRestore);
            }
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }
}