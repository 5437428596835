import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { ValidationService } from '../../../utils/shared-services/validation.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { ConceptApiService } from '../../concepts/services/concept.resource';
import { StoreGroupMembersApiService } from '../../store-groups/services/store-group-member.resource';
import { StoreApiService } from '../../stores/services/store.resource';
import { TagApiService } from '../../tags/services/tag.resource';
import { UserAccessHandler } from '../services/user-access-handler.service';
import { UserInfoHandler } from '../services/user-info-handler.service';
import { UserSecurityApiService } from '../services/user-security.resource';
import { UserApiService } from '../services/users.resource';
import { UserConstants } from '../users.constants';
import { ConfirmResetPasswordDialog } from './confirm-reset-password-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'editUser',
    templateUrl: 'src/app/features/users/templates/edit-user.html',
})
export class EditUserComponent {
    public currentUser;
    public passedUserId;
    public hideAccess;
    public hidePrivilegeBtn;
    public showUserManagementField;
    public showResetPassOnLoginField;
    public isStoreActive;
    public accessType;
    public store;
    public captions;
    public user;
    public selectedUserSecurity;
    public globalUser;
    public email;
    public concept;
    public accessTypeName;
    public storeUser;
    public languages;
    public whenAdded;
    public form: UntypedFormGroup = null;
    private USER_ACCESS;
    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(UserSecurityApiService) private userSecurityApiService: UserSecurityApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) private validationService: ValidationService,
        @Inject(DatePipe) private datePipe: DatePipe,
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUser = this.userAccessHandler.getUserAccess();

        this.hideAccess = false;
        this.hidePrivilegeBtn = false;
        this.showUserManagementField = true;
        this.showResetPassOnLoginField = true;
        this.isStoreActive = true;
        this.accessType = '';
        this.store = {};
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
            this.languages = this.captionService.languages;
        });
        this.actRoute.params.subscribe(params => {
            this.passedUserId = +params['pathId'];
        });
        this.getUserById(this.passedUserId).then(() => {
            this.getUserSecurityById(this.passedUserId).then(() => {
                this.form = new UntypedFormGroup({
                    email: new UntypedFormControl({ value: this.user.email, disabled: true }, [
                        Validators.pattern(this.validationService.getEmailRegex()),
                        Validators.required,
                        Validators.maxLength(45),
                    ]),
                    firstName: new UntypedFormControl(this.user.firstName, [Validators.required, Validators.maxLength(45)]),
                    lastName: new UntypedFormControl(this.user.lastName, [Validators.required, Validators.maxLength(45)]),
                    language: new UntypedFormControl(this.user.language, [Validators.required]),
                    isAdmin: new UntypedFormControl(this.user.isAdmin),
                    resetVal: new UntypedFormControl(this.user.resetVal),
                });
            });
        });
    }

    getUserById(userId) {
        return this.userApiService.getUserById(userId).then((user: any) => {
            this.user = user;
            const originalDate = this.user.whenAdded;
            this.whenAdded = this.datePipe.transform(originalDate, 'yyyy-MM-dd');
            this.setUserLanguage();
            if (user.userManagement === this.USER_ACCESS.ADMIN) {
                user.isAdmin = true;
            }
            user.resetVal = !!user.resetPassOnLogin;
            this.getCurrentUserInfo();
            if(!user.userPrivilege) {
                this.showUserManagementField = false;
                return;
            }
            for (let priv of JSON.parse(user.userPrivilege)) {
                if (priv.name == UserConstants.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY) {
                    this.showUserManagementField = false;
                    break;
                }
            }
        });
    }

    getUserSecurityById(userId) {
        return this.userSecurityApiService.getSelectedUserById(userId).then((user: any) => {
            this.selectedUserSecurity = user;
            if (user.role === this.USER_ACCESS.GLOBAL) {
                this.globalUser = true;
            }
            else if (user.role === this.USER_ACCESS.CONCEPT) {
                this.globalUser = false;
                this.getConceptById(user.conceptID);
            }
            else if (user.role === this.USER_ACCESS.GROUP_ITEM) {
                this.globalUser = false;
                this.getConceptById(user.conceptID);
                this.getStoreGroupItemById(user.groupItemID);
            }
            else if (user.role === this.USER_ACCESS.TAG) {
                this.globalUser = false;
                this.getConceptById(user.conceptID);
                this.getTagById(user.tagID);
            }
            else {
                this.globalUser = false;
                this.getConceptById(user.conceptID);
                this.getStoreById(user.storeID);
            }
        });
    }

    getCurrentUserInfo() {
        this.utilsApiService.getUserInfo().then((userInfo: any) => {
            this.email = userInfo.email;
            if (userInfo.userPrivilege === null) {
                this.hidePrivilegeBtn = true;
            }
            if (this.email === this.user.email) {
                this.hideAccess = true;
                this.hidePrivilegeBtn = true;
                this.showUserManagementField = false;
                this.showResetPassOnLoginField = false;
            }
            if (this.currentUser.role === this.USER_ACCESS.STORE) {
                this.hideAccess = true;
            }
        });
    }

    getConceptById(conceptId) {
        this.conceptApiService.getConceptById(conceptId).then((concept: any) => {
            this.concept = concept;
            if (this.selectedUserSecurity.role === this.USER_ACCESS.CONCEPT) {
                this.accessType = this.captions.organization;
                this.accessTypeName = concept.displayName;
            }
        });
    }

    getStoreGroupItemById(groupItemId) {
        this.storeGroupMembersApiService.getStoreGroupItemById(groupItemId).then((groupItem: any) => {
            this.accessType = this.captions.groupItem;
            this.accessTypeName = groupItem.displayName;
        });
    }

    getTagById(tagId) {
        this.tagApiService.getTagById(tagId).then((tagObj) => {
            let tag = tagObj as any;
            this.accessType = this.captions.tag;
            this.accessTypeName = tag.displayName;
        });
    }

    getStoreById(storeId) {
        this.storeApiService.getStoreById(storeId).then((store: any) => {
            this.storeUser = true;
            this.accessType = this.captions.storeList;
            this.accessTypeName = store.displayName;
        }).catch(() => {
            this.isStoreActive = false;
            this.snackbarService.errorMessageBottom(this.captions.inactiveStoreError);
        });
    }

    manageUserAccess() {
        this.router.navigate(['users/manageUserAccess', this.passedUserId], { queryParams: { state: 'editUser' } });
    }

    manageUserPrivileges() {
        this.router.navigate(['users/manageUserPrivileges', this.passedUserId], { queryParams: { state: 'editUser' } });
    }

    setUserLanguage() {
        for (let language of this.languages) {
            if (language.language === this.user.language) {
                this.user.language = language;
            }
        }
    }

    submitUser() {
        const formValues = this.form.value;
        const userLanguage = this.userInfoHandler.getUserInfo().language;

        this.user.firstName = formValues.firstName;
        this.user.lastName = formValues.lastName;
        this.user.language = formValues.language;
        this.user.userManagement = formValues.isAdmin ? this.USER_ACCESS.ADMIN : 0;
        this.user.resetPassOnLogin = formValues.resetVal ? 1 : 0;

        if (this.user.userManagement === null) {
            this.user.userManagement = 0;
        }

        this.userApiService.updateUser(this.user, this.user.language.language).then(() => {
            if (this.currentUser.webUserID === this.user.id) {
                this.userInfoHandler.setLanguage(this.user.language.language);
                if (userLanguage !== this.user.language.language) {
                    delete this.captions;
                    this.captionService.assignCaptions(this.user.language.language, 'FRONT');
                }
            }
            else {
                this.router.navigate(['users/allUsers']);
            }
        }).catch((response) => {
            this.snackbarService.errorMessageBottom(response.error.reason);
        });
    }

    changePassword() {
        const dialogRef = this.dialog.open(ConfirmResetPasswordDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                passedUserId: this.passedUserId,
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    cancelChanges() {
        this.router.navigate(['users/allUsers']);
    }
}