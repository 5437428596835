import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

interface Parameters {
    duration: any;
}

@Component({
    selector: 'eoi-success-response-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-success-response.dialog.html',
})
export class EoiSuccessResponseDialog {
    public captions: Record<string,string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters
    ) {
        this.captions = this.captionService.captions;
    }
}
