import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { RecycleBinApiService } from '../../services/recycle-bin.resource';

interface Parameters {
    itemsToRemove: any;
    conceptsToCheck: any;
    inactiveStoresToDelete: any;
}

@Component({
    selector: 'confirm-permanent-delete-dialog',
    templateUrl: 'src/app/features/recycle-bin/components/dialogs/templates/confirm-permanent-delete.dialog.html',
})
export class ConfirmPermanentDeleteDialog {
    public captions: any;

    constructor(
        @Inject(RecycleBinApiService) private recycleBinApiService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfirmPermanentDeleteDialog>
    ) {
        this.captions = this.captionService.captions;
    }

    deleteConceptsAndStores() {
        let deleteItems = [];
        for (let itemToRemove of this.data.itemsToRemove) {
            deleteItems.push(itemToRemove);
        }
        for (let concept of this.data.conceptsToCheck) {
            deleteItems.push(concept);
        }
        this.recycleBinApiService.deleteRecycledItems(deleteItems).then(() => {
            this.dialogRef.close(true);
            //reload page
        });
    }
}
