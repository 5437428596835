import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CaptionService } from "../../utils/shared-services/caption.service";
import { AllConceptsReportsComponent } from "./components/all-concepts-reports.component";
import { ManageReportsComponent } from "./components/manage.reports.component";
import { ViewReportComponent } from "./internal";
import { AllStoresReportComponent } from "./wizard/components/all-stores-report.component";
import { ReportWizardConfirmationComponent } from "./wizard/components/report-wizard-confirmation.component";
import { ReportWizardDateComponent } from "./wizard/components/report-wizard-date.component";
import { ReportScheduleComponent } from "./wizard/components/report-wizard-schedule.component";
import { ReportWizardComponent } from "./wizard/report-wizard";

export const routes: Routes = [
    {
        path: 'allConcepts',
        component: AllConceptsReportsComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "allConcepts",
            parent: {
                path: "home",
                name: "home"
            }
        }
    },
    {
        path: 'manageReports/:conceptID/:displayName',
        component: ManageReportsComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "manageReports",
            parent: {
                path: "reports/allConcepts",
                name: "allConcepts"
            },
        }
    },
    {
        // :pathID is conceptID
        // :pathExtra is the displayName for the concept/store
        // :pathName is displayName for the report
        // :jobID is jobID 
        path: 'viewReport/:pathId/:pathExtra/:pathName/:jobID',
        component: ViewReportComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "viewReport",
            parent: {
                path: "reports/manageReports/:pathId/:pathExtra",
                name: "manageReports",
            }
        }
    },
    {
        path: 'wizard/:conceptID/:displayName',
        component: ReportWizardComponent,
        data: {
            breadcrumb: "wizard",
            parent: {
                path: "reports/allConcepts",
                name: "manageReports",
            },
        }
    },
    // {
    //     path: 'wizard/name/:pathId/:pathName/:displayName',
    //     component: ReportWizardNameComponent,
    //     resolve: {
    //         captions: CaptionService
    //     },
    //     data: {
    //         breadcrumb: "reportWizard",
    //         parent: {
    //             path: "reports/manageReports/:pathId/:pathName",
    //             name: "manageReports"
    //         },
    //     }
    // },
    {
        path: 'wizard/date/:pathId/:pathName/:displayName',
        component: ReportWizardDateComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "reportWizard",
            parent: {
                path: "reports/manageReports/:pathId/:pathName",
                name: "manageReports"
            },
        }
    },
    {
        path: 'wizard/schedule/:pathId/:pathName/:displayName',
        component: ReportScheduleComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "reportWizard",
            parent: {
                path: "reports/manageReports/:pathId/:pathName",
                name: "manageReports"
            },
        }
    },
    {
        path: 'wizard/stores/:pathId/:pathName/:displayName',
        component: AllStoresReportComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "reportWizard",
            parent: {
                path: "reports/manageReports/:pathId/:pathName",
                name: "manageReports"
            },
        }
    },
    {
        path: 'wizard/confirm/:pathId/:pathName',
        component: ReportWizardConfirmationComponent,
        resolve: {
            captions: CaptionService
        },
        data: {
            breadcrumb: "reportWizard",
            parent: {
                path: "reports/manageReports/:pathId/:pathName",
                name: "manageReports"
            },
        }
    }
];

@NgModule({
    declarations: [],
    providers: [],
    imports: [RouterModule.forChild(routes)]
})

export class ReportsModule { }