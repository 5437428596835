import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportEmitterService } from '../../../features/reports/services/reportEmitter.service';
import { SharedWizardData } from '../../../features/reports/wizard/services/report-shared-wizard-data.service';
import { TagApiService } from '../../../features/tags/services/tag.resource';
import { UserAccessHandler } from '../../../features/users/services/user-access-handler.service';
import { UserEmitterService } from '../../../features/users/services/user-emitter.service';
import { UserSecurityApiService } from '../../../features/users/services/user-security.resource';
import { UserConstants } from '../../../features/users/users.constants';
import { EmitterService } from '../../shared-services/emitter.service';
import { TagPickerDialog } from './tag-picker-dialog.component';

@Component({
    selector: 'tagPicker',
    templateUrl: 'src/app/utils/shared-templates/user-access/tag-picker.html',
})
export class TagPickerComponent {
    @Output() tagPicker: EventEmitter<any> = new EventEmitter();
    @Input() tagItemMultiSelect: boolean = false;
    @Input() showSelectAllButton: boolean = false;
    private USER_ACCESS;

    public captions;
    public passedUserId;
    public currentUserAccess;
    public selectedUserSecurity;
    public showTagBox;
    public showSingleItemBox;
    public showAllTagsBox;
    public showNoTagsBox;
    public customAllTagsName;
    // tags appears to be an array of all the tags
    public tags;
    public concept;
    public eventName: String;
    public isTagUser;
    public selectedTag;
    // public tagMultiSelect;
    // selectedTagsForRenderingStores appears to be the tags that have been reloaded from wizard cache
    public selectedTagsForRenderingStores;
    public showButtonLabels;
    public allTags;
    // track subscriptions
    public subscriptions: Subscription[] = [];
    previousPath: any;

    tagPickerModel: number;
    conceptName: string;

    constructor(
        @Inject(UserAccessHandler) private userAccessHandler,
        @Inject(UserSecurityApiService) private userSecurityApiService,
        @Inject(TagApiService) private tagApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(UserEmitterService) private userEmitterService,
        @Inject(EmitterService) private emitterService,
        @Inject(SharedWizardData) private sharedWizardData,
        @Inject(ReportEmitterService) private reportEmitterService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUserAccess = userAccessHandler.getUserAccess();
        this.showTagBox = false;
        this.isTagUser = false;
        this.showButtonLabels = true;

        this.subscriptions.push(
            this.userEmitterService.tagChangedDirectiveItem$.subscribe((tagAndConcept) => {
                this.selectedTag = tagAndConcept[0];

                if (this.selectedTagsForRenderingStores.id === 0) {
                    this.showSingleItemBox = false;
                    this.showAllTagsBox = true;
                    this.showNoTagsBox = false;
                    this.customAllTagsName = this.captions.tagUserAccess + ' (' + this.concept.displayName + ')';
                } else if (this.selectedTagsForRenderingStores.length === 1) {
                    this.customAllTagsName =
                        "'" +
                        this.selectedTagsForRenderingStores[0].displayName +
                        "'" +
                        ' ' +
                        this.captions.tagsSelected;
                } else {
                    this.customAllTagsName =
                        this.selectedTagsForRenderingStores.length + ' ' + this.captions.tagsSelected;
                }
            })
        );

        this.subscriptions.push(
            this.reportEmitterService.getTagsForReportStoreSelectionItem$.subscribe((conceptIDAndRole) => {
                this.tagApiService.getAllTagsForConcept(conceptIDAndRole[0]).then((response) => {
                    let tags = response;
                    if (tags.length > 0) {
                        this.tags = tags;
                        if (
                            this.sharedWizardData !== null &&
                            this.sharedWizardData.tagList !== null &&
                            this.sharedWizardData.tagList.length > 0
                        ) {
                            let tempTag = [];
                            for (let selectedTag of this.sharedWizardData.tagList) {
                                if (typeof this.tags !== 'undefined') {
                                    for (let tag of this.tags) {
                                        if (selectedTag.id === tag.id) {
                                            tag.isChecked = true;
                                            tempTag.push(tag);
                                        }
                                    }
                                } else {
                                    this.tags = [];
                                }
                            }
                            //check if the array is undefined
                            if (typeof this.selectedTagsForRenderingStores === 'undefined') {
                                this.selectedTagsForRenderingStores = [];
                                this.reportEmitterService.reportLeadLabelChanged(true);
                            }
                            this.selectedTagsForRenderingStores = tempTag;
                        }
                        if (
                            typeof this.selectedTagsForRenderingStores !== 'undefined' &&
                            this.selectedTagsForRenderingStores.length !== 0
                        ) {
                            this.reportEmitterService.reportLeadLabelChanged(true);
                        }
                        this.showButtonLabels = false;
                        this.isTagUser = true;
                        this.showTagBox = true;
                        this.showSingleItemBox = false;
                        this.showAllTagsBox = true;
                        this.showNoTagsBox = false;
                        if (typeof this.customAllTagsName == 'undefined') {
                            this.customAllTagsName = this.captions.selectByTags;
                        }
                    } else this.showTagBox = false;
                });
            })
        );

        this.subscriptions.push(
            this.reportEmitterService.resetTagSelectionLabelItem$.subscribe((ev) => {
                this.showSingleItemBox = false;
                this.showAllTagsBox = true;
                this.showNoTagsBox = false;
                this.customAllTagsName = this.captions.selectByTags;
                if (typeof this.tags !== 'undefined') {
                    for (let tag of this.tags) {
                        tag.isChecked = false;
                    }
                }
                this.selectedTagsForRenderingStores = [];
            })
        );

        this.subscriptions.push(
            this.userEmitterService.conceptLoadedDirectiveItem$.subscribe((concept) => {
                this.concept = concept;
                this.eventName = 'conceptLoadedDirective';
                if (typeof this.selectedUserSecurity == 'undefined') {
                    this.getWebUserSecurity();
                    this.getTagsByUserAccess();
                } else {
                    this.getTagsByUserAccess();
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.groupItemChangedDirectiveItem$.subscribe((groupItemAndConcept) => {
                this.eventName = 'groupItemChangedDirective';
                if (groupItemAndConcept[0].id === 0) {
                    this.tagPickerModel = 0;
                    this.tagPicker.emit(this.tagPickerModel);
                    this.showSingleItemBox = false;
                    this.showAllTagsBox = true;
                    this.showNoTagsBox = false;
                    this.customAllTagsName = this.captions.groupItemUserAccess + ' (' + this.conceptName + ')';
                } else {
                    this.tagPickerModel = 0;
                    this.tagPicker.emit(this.tagPickerModel);
                    this.showSingleItemBox = false;
                    this.showAllTagsBox = false;
                    this.showNoTagsBox = true;
                    this.customAllTagsName = this.captions.noStoreGroups;
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.conceptChangedDirectiveItem$.subscribe((concept) => {
                this.concept = concept;
                this.eventName = 'conceptChangedDirective';
                if (concept.id !== 0 && typeof concept.id !== 'undefined') {
                    this.showAllTagsBox = true;
                    this.showSingleItemBox = false;
                    this.showNoTagsBox = false;
                    this.showTagBox = true;
                    this.tagPickerModel = 0;
                    this.tagPicker.emit(this.tagPickerModel);
                    this.customAllTagsName = this.captions.tagUserAccess + ' (' + concept.displayName + ')';
                    this.conceptName = concept.displayName;
                    this.tagApiService
                        .getAllTagsForConcept(concept.id)
                        .then((tags) => this.getAllTagsForConceptSuccess(tags));
                } else if (concept.id === 0) {
                    this.showTagBox = false;
                    this.tagPickerModel = 0;
                    this.tagPicker.emit(this.tagPickerModel);
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.storeChangedForTagDirectiveItem$.subscribe((storeAndtag) => {
                this.eventName = 'storeChangedForTagDirective';
                if (storeAndtag[0].id === 0) {
                    if (typeof storeAndtag[1] !== 'undefined' && storeAndtag[1].id !== 0) {
                        this.tagPickerModel = this.selectedTag.id;
                        this.tagPicker.emit(this.tagPickerModel);
                        this.showSingleItemBox = true;
                        this.showAllTagsBox = false;
                        this.showNoTagsBox = false;
                    } else if (typeof storeAndtag[1] == 'undefined' || storeAndtag[1].id === 0) {
                        this.tagPickerModel = 0;
                        this.tagPicker.emit(this.tagPickerModel);
                        this.showSingleItemBox = false;
                        this.showAllTagsBox = true;
                        this.showNoTagsBox = false;
                        this.customAllTagsName = this.captions.tagUserAccess + ' (' + this.concept.displayName + ')';
                    }
                } else {
                    this.tagPickerModel = 0;
                    this.tagPicker.emit(this.tagPickerModel);
                    this.showSingleItemBox = false;
                    this.showAllTagsBox = false;
                    this.showNoTagsBox = true;
                    this.customAllTagsName = this.captions.noStoreGroups;
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.storeChangedDirectiveItem$.subscribe((allStoresSelected) => {
                this.tagPickerModel = 0;
                this.tagPicker.emit(this.tagPickerModel);
                this.showSingleItemBox = false;
                if (allStoresSelected) {
                    this.showAllTagsBox = false;
                    this.showNoTagsBox = true;
                    this.customAllTagsName = this.captions.noStoreGroups;
                } else {
                    this.showAllTagsBox = true;
                    this.showNoTagsBox = false;
                    this.customAllTagsName = this.captions.tagUserAccess + ' (' + this.conceptName + ')';
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.createTagForSelectedStoresItem$.subscribe((tag) => {
                this.tags = this.tags.concat(tag);
                this.showSingleItemBox = true;
                this.showAllTagsBox = false;
                this.showNoTagsBox = false;
                this.selectedTag = tag;
                this.tagPickerModel = tag.id;
                this.tagPicker.emit(this.tagPickerModel);
            })
        );

        this.subscriptions.push(
            this.reportEmitterService.triggerSubmitTagsItem$.subscribe((tags) => {
                this.customAllTagsName = tags.length + ' ' + this.captions.tagsSelected;
            })
        );
    }

    ngOnDestroy() {
        // unsubscribe to all the subscriptions
        for (let sub of this.subscriptions) {
            sub?.unsubscribe();
        }
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.passedUserId = +params['pathId'];
            this.conceptName = params['conceptName'];
        });
        this.actRoute.queryParams.subscribe((params) => {
            this.previousPath = params['state'];
        });
        if (this.passedUserId === 'undefined') {
            this.passedUserId = this.currentUserAccess.webUserID;
        }
        if (this.currentUserAccess === null || typeof this.currentUserAccess == 'undefined') {
            this.currentUserAccess = this.userAccessHandler.getUserAccess();
        }
        if (this.previousPath === 'addUser') {
            this.getWebUserSecurity();
        }
        this.allTags = {
            id: 0,
        };
        this.tags = [];
        this.selectedTagsForRenderingStores = [];
    }

    async getWebUserSecurity() {
        return this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
            this.selectedUserSecurity = selectedUserSecurity;
            return this.selectedUserSecurity;
        });
    }

    async getTagsByUserAccess() {
        if (typeof this.selectedUserSecurity == 'undefined') {
            await this.getWebUserSecurity();
        }
        if (
            this.currentUserAccess.role === this.USER_ACCESS.GLOBAL ||
            this.currentUserAccess.role === this.USER_ACCESS.CONCEPT
        ) {
            this.tagApiService
                .getAllTagsForConcept(this.selectedUserSecurity.conceptID)
                .then((tags) => this.getAllTagsForConceptSuccess(tags));
        } else if (this.currentUserAccess.role === this.USER_ACCESS.TAG) {
            this.tagApiService.getTagById(this.currentUserAccess.tagID).then((tag) => this.getTagByIdSuccess(tag));
        } else {
            this.tagPickerModel = 0;
            this.tagPicker.emit(this.tagPickerModel);
            this.showTagBox = false;
            this.showSingleItemBox = false;
            this.showAllTagsBox = false;
            this.showNoTagsBox = true;
            this.customAllTagsName = this.captions.noStoreGroups;
        }
    }

    async getAllTagsForConceptSuccess(tags) {
        if (typeof this.selectedUserSecurity == 'undefined') {
            await this.getWebUserSecurity();
        }
        this.tags = tags;
        if (this.selectedUserSecurity.role === this.USER_ACCESS.CONCEPT) {
            this.showTagBox = true;
            this.showSingleItemBox = false;
            this.showAllTagsBox = true;
            this.showNoTagsBox = false;
            this.customAllTagsName = this.captions.tagUserAccess + ' (' + this.concept.displayName + ')';
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.TAG) {
            if (this.eventName === 'conceptChangedDirective') {
                this.showTagBox = true;
                this.showSingleItemBox = false;
                this.showAllTagsBox = true;
                this.showNoTagsBox = false;
            } else {
                this.showTagBox = true;
                this.showSingleItemBox = true;
                this.showAllTagsBox = false;
                this.showNoTagsBox = false;
                for (let tag of this.tags) {
                    if (tag.id === this.selectedUserSecurity.tagID) {
                        this.selectedTag = tag;
                        this.emitterService.tagLoadedCtrl(this.selectedTag);
                    }
                }
            }
        } else if (
            this.selectedUserSecurity.role === this.USER_ACCESS.GROUP_ITEM ||
            this.selectedUserSecurity.role === this.USER_ACCESS.STORE
        ) {
            if (this.eventName === 'conceptChangedDirective') {
                this.tagPickerModel = 0;
                this.tagPicker.emit(this.tagPickerModel);
                this.showTagBox = true;
                this.showSingleItemBox = false;
                this.showAllTagsBox = true;
                this.showNoTagsBox = false;
            } else {
                this.tagPickerModel = 0;
                this.tagPicker.emit(this.tagPickerModel);
                this.showTagBox = true;
                this.showSingleItemBox = false;
                this.showAllTagsBox = false;
                this.showNoTagsBox = true;
                this.customAllTagsName = this.captions.noStoreGroups;
            }
        }
    }

    async getTagByIdSuccess(tag) {
        if (typeof this.selectedUserSecurity == 'undefined') {
            await this.getWebUserSecurity();
        }
        this.tags = this.tags.concat(tag);
        this.isTagUser = true;
        this.selectedTag = tag;
        if (this.selectedUserSecurity.role === this.USER_ACCESS.TAG) {
            this.emitterService.tagChangedCtrl(this.selectedTag);
            this.showTagBox = true;
            this.showSingleItemBox = true;
            this.showAllTagsBox = false;
            this.showNoTagsBox = false;
        } else {
            this.emitterService.tagLoadedCtrl(tag);
            this.showTagBox = true;
            this.showSingleItemBox = false;
            this.showAllTagsBox = false;
            this.showNoTagsBox = true;
            this.customAllTagsName = this.captions.noStoreGroups;
        }
    }

    toggleTagSelection(tag) {
        let tagIndex = this.selectedTagsForRenderingStores.indexOf(tag);
        if (tag.isChecked) this.selectedTagsForRenderingStores.push(tag);
        else this.selectedTagsForRenderingStores.splice(tagIndex, 1);
    }

    submitTagsForStoreRendering() {
        this.emitterService.tagChangedCtrl(this.selectedTagsForRenderingStores, this.concept);
        this.reportEmitterService.reportTagsSelected(this.selectedTagsForRenderingStores, this.concept);
        if (this.selectedTagsForRenderingStores.id === 0) {
            this.showSingleItemBox = false;
            this.showAllTagsBox = true;
            this.showNoTagsBox = false;
            this.customAllTagsName = this.captions.tagUserAccess + ' (' + this.concept.displayName + ')';
        } else if (this.selectedTagsForRenderingStores.length === 1) {
            this.customAllTagsName =
                "'" + this.selectedTagsForRenderingStores[0].displayName + "'" + ' ' + this.captions.tagsSelected;
        } else {
            this.customAllTagsName = this.selectedTagsForRenderingStores.length + ' ' + this.captions.tagsSelected;
        }
    }

    stopPropagation(ev) {
        ev.stopPropagation();
    }

    tagPickerDialog(ev) {
        if (
            this.currentUserAccess.role === this.USER_ACCESS.GLOBAL ||
            this.currentUserAccess.role === this.USER_ACCESS.CONCEPT ||
            this.currentUserAccess.role === this.USER_ACCESS.GROUP_ITEM ||
            this.currentUserAccess.role === this.USER_ACCESS.TAG
        ) {
            const dialogRef = this.dialog.open(TagPickerDialog, {
                disableClose: true,
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    tags: this.tags,
                    tagItemMultiSelect: this.tagItemMultiSelect,
                    showSelectAllButton: this.showSelectAllButton,
                    selectedTagsForRenderingStores: this.selectedTagsForRenderingStores,
                    allTags: this.allTags,
                },
            });

            dialogRef.afterClosed().subscribe((res) => {
                // check if cancelled out of the window
                if (typeof res != 'undefined') {
                    // check if lead/all tags was selected
                    if (res.data.selectedTagsForRenderingStores) {
                        // received data from dialog-component
                        this.selectedTagsForRenderingStores = res.data.selectedTagsForRenderingStores;
                    } else {
                        this.selectedTagsForRenderingStores = this.allTags;
                    }
                    this.submitTagsForStoreRendering();
                }
            });
        }
    }
}
