import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { BackupInfoDialogData } from "../../models/dialog.model";
import { PlanInfoSummary, PlanSummary } from "../../models/store-backup.model";

@Component({
    selector: 'backup-info-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/backup-info-dialog.html',
})
export class BackupInfoDialog {

    public captions: Record<string, string>;
    public selectedPlan: PlanSummary;
    public backupInfo: PlanInfoSummary<string[]>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<BackupInfoDialog>,
        @Inject(MAT_DIALOG_DATA) public data: BackupInfoDialogData,
        @Inject(CaptionService) private captionService: CaptionService,
    ) {
        this.selectedPlan = this.data.selectedPlan;
        this.backupInfo = this.data.backupInfo;
        this.captions = this.captionService.captions;
    }
}
