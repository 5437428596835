import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { MobileViewService } from '../../../utils/shared-services/mobileView/mobileView.service';
import { StoreApiService } from '../services/store.resource';
import { OpenCalDialog } from './dialogs/open-cal-dialog.component';
import { ScheduledUpdatesDialog } from './dialogs/scheduled-updates-dialog.component';
import { ScheduledUpdate, SoftwareVersion } from '../models/store-software.mode';
import { Moment } from 'moment';

@Component({
    selector: 'storeSoftware',
    templateUrl: 'src/app/features/stores/templates/store-software.html',
})
export class StoreSoftwareComponent {

    private storeID: number;
    private scheduledUpdates: ScheduledUpdate[] = [];

    public captions: Record<string, string>;
    public softwareVersions: (SoftwareVersion & {imageUrl?: string} )[] = [];

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(MobileViewService) public mobileViewService: MobileViewService
    ) {}

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_LARGE);

        this.storeID = +this.actRoute.snapshot.paramMap.get('storeID');

        this.storeApiService.getStoreSoftwareVersions(this.storeID).then((res: SoftwareVersion[]) => {
            this.softwareVersions = res;
            for (let i = 0; i < this.softwareVersions.length; i++) {
                if (this.softwareVersions[i].name.toLowerCase() == 'pixelpointhqconnector') {
                    this.softwareVersions[i].imageUrl = 'icons/PixelPointHQConnector.png';
                } else if (this.softwareVersions[i].name.toLowerCase() == 'pixelpointpos') {
                    this.softwareVersions[i].imageUrl = 'icons/PixelPointPOS.png';
                } else if (this.softwareVersions[i].name.toLowerCase() == 'pixelpointhqeoi') {
                    this.softwareVersions[i].imageUrl = 'icons/PixelPointHQEOI.png';
                } else if (this.softwareVersions[i].name.toLowerCase() == 'backoffice') {
                    this.softwareVersions[i].imageUrl = 'icons/BackOffice.png';
                } else if (this.softwareVersions[i].name.toLowerCase() == 'licensemanager') {
                    this.softwareVersions[i].imageUrl = 'icons/LicenseManager.png';
                } else {
                    this.softwareVersions[i].imageUrl = 'icons/Other.png';
                }
            }
            this.storeApiService.getScheduledStoreUpdates(this.storeID).then((res: ScheduledUpdate[]) => {
                this.scheduledUpdates = res;
            });
        });
    }

    openCalDialog(): void {
        const dialogRef = this.dialog.open(OpenCalDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90%',
        });

        dialogRef.afterClosed().subscribe((result: Moment) => {
            if (result) {
                this.storeApiService
                    .updateStoreSoftwareVersions(this.storeID, result.format('YYYY-MM-DD HH:mm:ss'))
                    .then(() => {
                        this.storeApiService.getScheduledStoreUpdates(this.storeID).then((res: ScheduledUpdate[]) => {
                            this.scheduledUpdates = res;
                            this.snackbarService.successMessageBottom(this.captions.success);
                        });
                    });
            }
        });
    }

    openUpdateSchedule(): void {
        this.dialog.open(ScheduledUpdatesDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90%',
            minWidth: '45%',
            data: {
                scheduledUpdates: this.scheduledUpdates,
            },
        });
    }
}
