import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { SubmitPriceChangeDialogData } from "../../models/dialog.model";
import { FormBuilder, Validators } from "@angular/forms";
import { PriceChangeService } from "../../services/price-change.resource";
import { PriceChange, PriceChangeLabel } from "../../models/price-change.model";
import { ReviewPriceChangeDialog } from "./review-price-changes.component";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { ConfirmPriceChangeDialog } from "./confirm-dialog.component";
import { SnackbarService } from "../../../../utils/shared-services/snackbar/snackbar.service";

@Component({
    selector: "submit-price-change",
    templateUrl: "./src/app/features/price-change/templates/dialogs/submit.dialog.html"
})
export class SubmitPriceChangeDialog implements OnInit {

    private formBuilder: FormBuilder = new FormBuilder();

    public captions: Record<string, string>
    public showRollbackDate: Boolean = false;
    public executeMinDate: Date;
    public rollbackMinDate: Date;
    public minTime: string;
    public is24: boolean = true;
    public selectedOption: number = 1;
    public timeFormat: string = '00:00';
    public filteredLabels: any[] = [];
    public isUniqueLabel: boolean = true;
    //change to real data
    public priceChangeLabels: any[] = [];

    public formGroup = this.formBuilder.group({
        executeDate: [new Date(), Validators.required],
        rollbackDate: [new Date(), Validators.required],
        selectedTime: ['00:00'],
        rollBackSelectTime: ['00:00'],
        labelInput: [""],
    });
    isLabelSelected: boolean = false;
    labelObject: PriceChangeLabel = {
        displayName: '',
        conceptID: 0,
        when2Execute: '',
        when2Rollback: ''
    };

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<SubmitPriceChangeDialog>,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: SubmitPriceChangeDialogData,
        @Inject(PriceChangeService) public priceChangeService: PriceChangeService
    ) {
        this.captions = this.captionService.captions;
        this.showRollbackDate = false;

        this.executeMinDate = new Date();
        this.executeMinDate.setDate(this.executeMinDate.getDate() - 1);
    }

    ngOnInit(): void {
        this.formGroup.get('labelInput')!.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((value) => {
                this.filterLabels(value);
            });
        this.priceChangeService.getPriceChangeLabels(this.data.conceptID).then(labels => {
            this.priceChangeLabels = labels;
        });
    }

    onExucuteDateChange(newDate: Date) {
        this.rollbackMinDate = newDate;
        this.formGroup.get('rollbackDate').setValue(null);
        this.formGroup.get('executeDate').setValue(newDate);
        this.formGroup.get('rollBackSelectTime').setValue('00:00');
        this.formGroup.get('selectedTime').setValue('00:00');
        this.minTime = '00:00';
    }

    onExecuteTimeChange(time: string) {
        if (this.isUniqueLabel) {
            this.formGroup.get('rollBackSelectTime').setValue(time);
            this.minTime = time;
        } else return;
    }

    onRollbackDateChange(newDate: Date) {
        this.formGroup.get('rollbackDate').setValue(newDate);
        let eDate = new Date(this.formGroup.value.executeDate);
        let rDate = new Date(this.formGroup.value.rollbackDate);

        if (eDate.getTime() !== rDate.getTime()) {
            this.formGroup.get('rollBackSelectTime').setValue('00:00');
            this.minTime = '00:00';
        }
    }

    enableRollbackSelection(): void {
        this.showRollbackDate = !this.showRollbackDate;
    }

    onClose() {
        this.dialogRef.close();
    }

    confirmDialog(){
        const dialogRef = this.dialog.open(ConfirmPriceChangeDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data:{
                changedPricesCount: this.data.priceChange.changedPrices.length
            }
        });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result.confirm === "Confirm") {
                this.onSubmit();
                this.snackbarService.successMessageBottom("Price Change Submitted");
            }
        });
    }

    onSubmit() {
        let formattedTime = this.is24 ? this.formGroup.value.selectedTime : this.convertTo24HourTime(this.formGroup.value.selectedTime);
        let formattedRollbackTime = this.is24 ? this.formGroup.value.rollBackSelectTime : this.convertTo24HourTime(this.formGroup.value.rollBackSelectTime);
        const pricesToChange: PriceChange = this.data.priceChange;
        const labelName = this.formGroup.get('labelInput').value;
        pricesToChange.info.PriceChangeDate = this.formatDateToYYYYMMDD(this.formGroup.value.executeDate) + ' ' + formattedTime;
        pricesToChange.info.RollbackDate = this.showRollbackDate ? this.formatDateToYYYYMMDD(this.formGroup.value.rollbackDate) + ' ' + formattedRollbackTime : '';
        if (this.isUniqueLabel) {
            this.labelObject.displayName = labelName;
            this.labelObject.conceptID = this.data.conceptID;
            this.labelObject.when2Execute = pricesToChange.info.PriceChangeDate;
            this.labelObject.when2Rollback = pricesToChange.info.RollbackDate;
            this.priceChangeService.addPriceChangeLabel(this.labelObject);
        }
        pricesToChange.label = labelName;
        if (!this.showRollbackDate) pricesToChange.rollbackPrices = [];
        this.dialogRef.close({
            priceChange: pricesToChange
        });
    }

    filterLabels(value: string) {
        const filterValue = value.toLowerCase();
        this.filteredLabels = this.priceChangeLabels.filter(label =>
            label.displayName.toLowerCase().includes(filterValue)
        );
    }
    // Triggered when the user clicks "Apply"
    applyLabel() {
        const currentLabel = this.formGroup.get('labelInput')!.value;
        this.isUniqueLabel = !this.filteredLabels.some(
            (label) => label.displayName.toLowerCase() === currentLabel.trim().toLowerCase()
          );
        if (!currentLabel) {
            alert('Please select or type a label first.');
            return;
        }
        this.isLabelSelected = true; // Show the rest of the form
        this.onLabelSelect(currentLabel);
    }
    // Triggered when a label is selected
    onLabelSelect(selectedLabel: string) {
        // Find the selected label object from the filteredLabels list
        const label = this.filteredLabels.find(lbl => lbl.displayName === selectedLabel);
        this.isUniqueLabel = !this.filteredLabels.some(
            (label) => label.displayName.toLowerCase() === selectedLabel.trim().toLowerCase()
          );

        if (label && !this.isUniqueLabel) {
            // Set form controls based on the selected label
            this.formGroup.get('executeDate')?.setValue(new Date(label.when2Execute));
            this.formGroup.get('selectedTime')?.setValue(this.formatTime(label.when2Execute));
            if (label.when2Rollback) {
                this.showRollbackDate = true;
                this.formGroup.get('rollbackDate')?.setValue(new Date(label.when2Rollback));
                this.formGroup.get('rollBackSelectTime')?.setValue(this.formatTime(label.when2Rollback));
            }
            this.isLabelSelected = true;
        }
    }

    // Utility method to format time (HH:mm) from when2Execute
    private formatTime(when2Execute: string): string {
        const date = new Date(when2Execute);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    onLabelInput(value: string) {
        this.filterLabels(value);
    }

    private convertTo24HourTime(time12h: string): string {
        const [time, modifier] = time12h.split(' ');
        let [hours, minutes] = time.split(':').map(Number);
        // Handle the AM/PM modifier
        if (modifier === 'PM' && hours !== 12) {
            hours += 12;
        }
        if (modifier === 'AM' && hours === 12) {
            hours = 0;
        }
        // Format the hours and minutes as a two-digit string
        const hoursStr = hours.toString().padStart(2, '0');
        const minutesStr = minutes.toString().padStart(2, '0');
        return `${hoursStr}:${minutesStr}`;
    }

    private formatDateToYYYYMMDD(date: Date): string {
        let newDate = new Date(date)
        const year = newDate.getFullYear();
        const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
        const day = ('0' + newDate.getDate()).slice(-2);

        return `${year}-${month}-${day}`;
    }

    onSelectionChange(event: any) {
        const selectedValue = event.value;
        if (selectedValue == 1) {
            this.is24 = true;
            this.timeFormat = '00:00';
        } else {
            this.is24 = false;
            this.timeFormat = '12:00 am';
        }
        this.formGroup.get('selectedTime').setValue('00:00');
        this.formGroup.get('rollBackSelectTime').setValue('00:00');
    }

    reviewPriceChanges() {
        const dialogRef = this.dialog.open(ReviewPriceChangeDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            minWidth: '25%',
            data: {
                priceChange: this.data.priceChange,
                selectedStores: this.data.selectedStores
            },
        });
    }
}


