import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreApiService } from '../../../stores/services/store.resource';
import { MobileViewService } from '../../../../utils/shared-services/mobileView/mobileView.service';
import { StoreBase } from '../../../stores/models/store.model';
import { EnableHOPortal } from '../../models/enable-ho-portal.model';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'selectPrimaryStore',
    templateUrl: 'src/app/features/concepts/templates/dialogs/select-primary-store.dialog.html',
})
export class SelectPrimaryStoreDialog {
    public availableSearchStr: string;
    public allStores: any;
    public concept: any;
    public conceptID: any;
    public conceptName: any;
    public stores: any;
    public captions: any;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<SelectPrimaryStoreDialog>,
        @Inject(MAT_DIALOG_DATA) public data: EnableHOPortal,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(Router) public router: Router,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
        @Inject(MobileViewService) private mobileViewService: MobileViewService,
    ) {
        this.availableSearchStr = '';
        this.captions = this.captionService.captions;
        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_MEDIUM);
        this.conceptID = this.data.conceptID;
    }

    ngOnInit() {
        // get all store data
        this.storeApiService.getStoresByConceptId(this.conceptID).then((stores) => {
            this.allStores = stores;
        });
    }

    selectPrimaryStore(store) {
        this.dialogRef.close({ primaryStore: store.id, storeName: store.displayName })
    }

    cancelClicked() {
        this.dialogRef.close();

    }

    filterStore(store: StoreBase, searchTerm?: string) {
        return !searchTerm || store.displayName.toLowerCase().includes(searchTerm);
    }

}
