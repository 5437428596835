import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";

@Component({
    selector: "price-list-dialog",
    templateUrl: "./src/app/features/price-change/templates/dialogs/price-list-dialog.component.html",
})
export class PriceListDialog implements OnInit {
    public priceList;
    public storeName;
    public captions: Record<string, string>

      // Array of column names
  public headers = ['PriceA', 'PriceB', 'PriceC', 'PriceD', 'PriceE', 'PriceF', 'PriceG', 
  'PriceH', 'PriceI', 'PriceJ', 'Price MOD'];
  public columns = ['PriceA', 'PriceB', 'PriceC', 'PriceD', 'PriceE', 'PriceF', 'PriceG', 
    'PriceH', 'PriceI', 'PriceJ', 'PriceMode'];
    constructor(@Inject(MAT_DIALOG_DATA) public data,
    @Inject(CaptionService) private captionService: CaptionService,

    
    ) {
        this.priceList = this.data.priceList;
        this.storeName = this.data.storeName;
        this.captions = this.captionService.captions;
    }


    async ngOnInit(): Promise<void> { 
    }
}
