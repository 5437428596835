import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../shared-services/caption.service';

interface DialogData {
    storeGroups: any;
    groupItemMultiSelect: boolean;
    showSelectAllButton: boolean;
    selectedGroupItemsForRenderingStores: any[];
}

@Component({
    selector: 'group-item-picker-dialog',
    templateUrl: 'src/app/utils/shared-templates/user-access/group-item-picker-dialog.html',
})
export class GroupItemPickerDialog {
    public captions: any = {};
    public storeGroups;
    public showAllConceptsBox;
    public groupItemMultiSelect;
    public selectedStoreGroups: any = [];
    public newSelectedStoreGroups: any = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<GroupItemPickerDialog>
    ) {
        this.captions = this.captionService.captions;
        this.storeGroups = data.storeGroups;
        this.groupItemMultiSelect = data.groupItemMultiSelect;
        this.selectedStoreGroups = data.selectedGroupItemsForRenderingStores;
        this.newSelectedStoreGroups = [];

        this.dialogRef.keydownEvents().subscribe((event) => {
            //call cancel on escape
            if (event.key === 'Escape') {
                this.onCancel();
            }
        });

        this.dialogRef.backdropClick().subscribe(() => {
            //call cancel on clicking outside of dialog
            this.onCancel();
        });
    }

    // method is called when you select an item in the group item modal
    selectGroupItemBox(selectedGroupItem) {
        if (typeof this.selectedStoreGroups === 'undefined') {
            this.selectedStoreGroups = [];
        }
        // check if we select multiple groups or just one group
        if (this.groupItemMultiSelect) {
            if (selectedGroupItem == 0) {
                // TODO: Handle all selected
            } else {
                //keep track of which buttons were pressed and undo them if we do not click select
                let newGroupItemIndex = this.newSelectedStoreGroups.indexOf(selectedGroupItem);
                if (newGroupItemIndex < 0) {
                    this.newSelectedStoreGroups.push(selectedGroupItem);
                } else {
                    this.newSelectedStoreGroups.splice(newGroupItemIndex, 1);
                }
                selectedGroupItem.isChecked = !selectedGroupItem.isChecked;
            }
        }
        else{
            if (selectedGroupItem == 0) {
                this.dialogRef.close({ data: { selectedGroupItem: null } });
            }
            else{
                // return an array with a single value that was selected
                this.dialogRef.close({ data: { selectedGroupItem: [selectedGroupItem] } });
            }
        }
    }

    onCancel() {
        // make sure that we reset the selection when cancelled out
        for (let aStore of this.newSelectedStoreGroups) {
            aStore.isChecked = !aStore.isChecked;
        }
        this.dialogRef.close();
    }

    submitGroupItemsForStoreRendering() {
        //on submit, update the array and return the array of stores that have been selected
        for (let aGroup of this.newSelectedStoreGroups) {
            //handle single group being clicked
            if (aGroup.isChecked) {
                this.selectedStoreGroups.push(aGroup);
            } else {
                let groupItemIndex = this.selectedStoreGroups.indexOf(aGroup);
                this.selectedStoreGroups.splice(groupItemIndex, 1);
            }
        }
        this.dialogRef.close({ data: { selectedGroupItem: this.selectedStoreGroups } });
    }
}
