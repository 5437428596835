import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'privacy-policy',
    templateUrl: 'src/app/auth/templates/privacy-policy.html',
})
export class PrivacyPolicyComponent {
    captions: any;

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute
    ) { }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
    }
}
