import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';

export interface ApiPackage {
    id: number,
    controlPoint: string,
    description: string,
    displayName: string
}

@Injectable()
export class ApiPackageService {
    private getApiPackagesUrl: string = 'api-packages';
    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getApiPackages(controlPoint: string): Promise<ApiPackage[]> {
        const url = this.BASE_URL + this.getApiPackagesUrl;
        const data$ = this.http.get(url, { params: { controlPoint } })
        const value = await lastValueFrom(data$) as ApiPackage[];
        return value;
    }
}
