
import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { AuthService } from '../../../auth/services/auth.resource';
import { ValidationService } from '../../../utils/shared-services/validation.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { FormValidatorService } from '../../../utils/shared-services/form-validation.service';

@Component({
    selector: 'resetPassword',
    templateUrl: 'src/app/features/users/templates/reset-password.html',
})
export class PasswordResetComponent {
    public passedUserId;
    public captions;
    public form: UntypedFormGroup;
    public hidePassword: boolean = true;

    constructor(
        @Inject(AuthService) private authService: AuthService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(FormValidatorService) private formValidationService: FormValidatorService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService
    ) {
        this.captions = this.captionService.captions;
        this.form = new UntypedFormGroup(
            {
                password: new UntypedFormControl('', [
                    Validators.required,
                    Validators.pattern(this.validationService.getPasswordRegex()),
                    Validators.minLength(this.validationService.minPasswordLength),
                    Validators.maxLength(this.validationService.maxPasswordLength),
                ]),
                confirmPassword: new UntypedFormControl('', [Validators.required, Validators.pattern(this.validationService.getConfirmPasswordRegex())]),
            },
            {
                validators: [this.formValidationService.matchPassword('password', 'confirmPassword') as any],
            }
        );
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe(params => {
            this.passedUserId = +params["pathId"];
        })
    }

    saveChanges() {
        if (this.form.valid) {
            this.authService
                .resetPassword(this.passedUserId, this.form.value.password)
                .then(() => {
                    this.router.navigate(['users/editUser', this.passedUserId]).then(() => {
                        this.snackbarService.successMessageBottom(this.captions.successfulPassReset);
                    });
                })
                .catch((exception) => {
                    this.snackbarService.errorMessageBottom(this.captions.newPasswordError);
                });
        }
    }

    cancelChanges() {
        this.router.navigate(['users/editUser', this.passedUserId]);
    }

    togglePasswordVisibility(): void {
        this.hidePassword = !this.hidePassword;
    }

    onConfirmPasswordChange()
    {
        this.form.controls.confirmPassword.markAsTouched();
    }
}