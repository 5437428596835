import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { PrivilegeName, PrivilegeType } from '../../users/enums/user-privileges.enum';
import { UserAccess } from '../../users/models/user-access.model';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserConstants } from '../../users/users.constants';
import { BottomSheetData } from '../models/bottom-sheet.model';
import { StoreBase } from '../models/store.model';

@Component({
    selector: 'storeOptions',
    templateUrl: 'src/app/features/stores/templates/store-options.tpl.html',
})
export class StoreOptionsComponent {
    private conceptName: string;
    private conceptID: number;
    private currentUser: UserAccess;

    public captions: Record<string, string>;
    public selectedStore: StoreBase;
    public manageStoresPrivilege: boolean = false; // MANAGE_STORES
    public assignStoreTagsPrivilege: boolean = false; // ASSIGN_STORE_TAGS
    public assignGroupItemsPrivilege: boolean = false; // ASSIGN_STORE_GROUP_SELECTIONS
    public manageDataExtractionPrivilege: boolean = false; // MANAGE_DATA_EXTRACTION
    public manageEOIPrivilege: boolean = false; // MANAGE_EOI_API
    public manageReportPrivilege: boolean = false; // MANAGE_REPORT_API
    public hideProd: boolean = false;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(MatBottomSheetRef) private _bottomSheetRef: MatBottomSheetRef<StoreOptionsComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) private bottomSheetData: BottomSheetData,
        @Inject(Router) private router: Router
    ) {
        this.currentUser = this.userAccessHandler.getUserAccess();
        this.captions = this.captionService.captions;
    }

    ngOnInit(): void {
        this.selectedStore = this.itemStorageService.selectedStore;
        this.manageStoresPrivilege = false;
        this.assignStoreTagsPrivilege = false;
        this.assignGroupItemsPrivilege = false;

        this.conceptID = this.bottomSheetData.conceptID;
        this.conceptName = this.bottomSheetData.conceptName;

        if (
            this.currentUser.userPrivilege !== UserConstants.USER_PRIVILEGES.ALL_PRIVILEGES &&
            this.currentUser.userPrivilege !== null
        ) {
            let privileges = JSON.parse(this.currentUser.userPrivilege) as {name: PrivilegeName; type: PrivilegeType}[];
            for (let privilege of privileges) {
                if (privilege.name === PrivilegeName.ManageStores) {
                    this.manageStoresPrivilege = true;
                } 
                else if (privilege.name === PrivilegeName.AssignStoreTags) {
                    this.assignStoreTagsPrivilege = true;
                } 
                else if (privilege.name === PrivilegeName.AssignStoreGroupSelections) {
                    this.assignGroupItemsPrivilege = true;
                } 
                else if (privilege.name === PrivilegeName.ManageDataExtraction) {
                    this.manageDataExtractionPrivilege = true;
                } 
                else if (privilege.name === PrivilegeName.ManageEOIApi) {
                    this.manageEOIPrivilege = true;
                } 
                else if (privilege.name === PrivilegeName.ManageReportApi) {
                    this.manageReportPrivilege = true;
                }
            }
        } 
        else if (this.currentUser.userPrivilege !== null) {
            this.manageStoresPrivilege = true;
            this.assignStoreTagsPrivilege = true;
            this.assignGroupItemsPrivilege = true;
            this.manageDataExtractionPrivilege = true;
            this.manageEOIPrivilege = true;
            this.manageReportPrivilege = true;
        }

        // hide count sheet from PROD only
        if (
            window.location.origin.indexOf('localhost') > -1 ||
            window.location.origin.indexOf('ci') > -1 ||
            window.location.origin.indexOf('qa') > -1
        ) {
            this.hideProd = true;
        }
    }

    closePopup(): void {
        this._bottomSheetRef.dismiss();
    }

    listItemClick(item: string): void {
        this._bottomSheetRef.dismiss();
        if (item === 'manageStore') {
            this.router.navigate([
                'stores/editStore', 
                this.conceptID, 
                this.conceptName, 
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'storeGroups') {
            this.router.navigate([
                'stores/assignStoreGroups',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'storeTags') {
            this.router.navigate([
                'stores/assignStoreTags',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'apis') {
            this.router.navigate([
                'stores/manageStoreApis',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'eoiDetail') {
            this.router.navigate([
                'stores/eoiDetail',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'storeBackup') {
            this.router.navigate([
                'stores/storeBackup',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'dataExchange') {
            this.router.navigate([
                'stores/dataExchange',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'extractData') {
            this.router.navigate([
                'stores/extractData',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'enableCountsheet') {
            this.router.navigate([
                'stores/enableCountsheet',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if (item === 'storePortal') {
            this.router.navigate([
                'stores/enableStorePortal',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        } else if(item === 'storeSoftware'){
            this.router.navigate([
                'stores/storeSoftware',
                this.conceptID,
                this.conceptName,
                this.selectedStore.id,
                this.selectedStore.displayName,
            ]);
        }
    }
}
