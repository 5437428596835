import { Component, Input } from '@angular/core';

@Component({
    selector: 'infoListGroup',
    template: '<div class="flex-col flex justify-center items-center">' +
        '<h2 class="text-text-color-primary">{{label}}</h2>' +
        '<mat-divider style="width: 80%"></mat-divider>' +
        '<ng-content></ng-content>' +
        '</div>',
})

export class InfoListGroupComponent {
    @Input() public label;
    @Input() public showCheckbox;

    ngOnInit() {

    }
}