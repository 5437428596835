import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { NgxIndexedDBService } from "ngx-indexed-db";
import { ProductGroup } from "../../models/price-change.model";
import { ReviewStoreNamesDialog } from "./review-store-names.component";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "price-history-dialog",
    templateUrl: "./src/app/features/price-change/templates/dialogs/review-price-changes.component.html",
})
export class ReviewPriceChangeDialog implements OnInit {
    public changeData;
    public captions: Record<string, string> = this.captionService.captions;
    public orgProducts;

    public beforeAndAfterPrices = [];
    public groupedProducts: ProductGroup[] = [];
    public loading: boolean;

    public headers = [this.captions.frontDescription, this.captions.stores, 'PriceA', 'PriceB', 'PriceC', 'PriceD', 'PriceE', 'PriceF', 'PriceG',
        'PriceH', 'PriceI', 'PriceJ', 'Price MOD'];
    constructor(@Inject(MAT_DIALOG_DATA) public data,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(NgxIndexedDBService) private dbService: NgxIndexedDBService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ChangeDetectorRef) private cd: ChangeDetectorRef

    ) {
    }


    async ngOnInit(): Promise<void> {
        this.loading = true
        await this.createTableArray();
        this.loading = false;
        this.cd.detectChanges();
    }

    async createTableArray(): Promise<void> {

        const orgProducts = await lastValueFrom(this.dbService.getByKey('orgProducts', 1));
        this.orgProducts = orgProducts.products;
        this.data.priceChange.changedPrices.forEach(changedProduct => {
            let indexInOrg = this.orgProducts.findIndex(p => p.StoreID === changedProduct.StoreID && p.ProdNum === changedProduct.ProdNum);
            const pricePropertyNames = Object.keys(changedProduct).filter(key => key.startsWith('Price'));
            let rollbackProduct = {};

            for (const propName of pricePropertyNames) {
                let prodNameChanged = propName + "Changed";
                rollbackProduct[propName] = changedProduct[propName];
                rollbackProduct[prodNameChanged] = changedProduct[propName] - this.orgProducts[indexInOrg][propName];
            }
            rollbackProduct["ProdNum"] = changedProduct.ProdNum;
            rollbackProduct["StoreID"] = changedProduct.StoreID;
            rollbackProduct["Descript"] = this.orgProducts[indexInOrg]["Descript"];

            this.beforeAndAfterPrices.push(rollbackProduct);

        })

        this.groupProducts(this.beforeAndAfterPrices)

    }

    groupProducts(products) {

        products.forEach(product => {

            const existingProductGroup = this.groupedProducts.find(productGroup => {
                const pgDescript = productGroup.Descript?.trim().toLowerCase();
                const pDescript = product.Descript?.trim().toLowerCase();
                return pgDescript === pDescript;
            });

            if (existingProductGroup) {
                existingProductGroup.products.push(product);
            }
            else if (product.Descript) {
                const newProductGroup: ProductGroup = {
                    StoreID: product.StoreID,
                    Descript: product.Descript,
                    products: [product],
                }
                this.groupedProducts.push(newProductGroup);
            }

        })
    }


    formatNumber(num) {
        if (num) {
            let formattedNum;
            if (num > 0) {
                formattedNum = `<span class="text-green-500">(+${num.toFixed(2)})</span>`;
            } else if (num < 0) {
                formattedNum = `<span class="text-red-500">(${num.toFixed(2)})</span>`;
            } else {
                formattedNum = num.toString(); // No color for zero
            }
            return formattedNum;
        } else return;
    }

    getDisplayValue(value: any) {
        return value ? value : '-';
    }

    openWhichStores(products) {
        let storeNames = [];
        products.forEach(product => {
            let index = this.data.selectedStores.findIndex(p => p.id === product.StoreID);
            storeNames.push(this.data.selectedStores[index].displayName);
        })
        const dialogRef = this.dialog.open(ReviewStoreNamesDialog, {
            data: storeNames,
        });
    }
}
