import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmitterService } from '../../../../utils/shared-services/emitter.service';
import { SnackbarService } from '../../../../utils/shared-services/snackbar/snackbar.service';
import { ConceptApiService } from '../../../concepts/services/concept.resource';
import { StoreApiService } from '../../../stores/services/store.resource';
import { StoreBase } from '../../../stores/models/store.model';
import { UserAccessHandler } from '../../../users/services/user-access-handler.service';
import { UserConstants } from '../../../users/users.constants';
import { ReportEmitterService } from '../../services/reportEmitter.service';
import { SharedWizardData } from '../../wizard/services/report-shared-wizard-data.service';
import { ReportHelperMethodsService as GroupAndTagHelperService } from '../services/report-common-helper-methods.service';
import { ReportParamsHandler } from '../services/report-params-handler.service';
import { MobileViewService } from '../../../../utils/shared-services/mobileView/mobileView.service';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
    selector: 'allStoresReports',
    templateUrl: 'src/app/features/reports/wizard/templates/report-wizard-stores.html',
})
export class AllStoresReportComponent {
    private USER_ACCESS;

    public selectedSearchStr: string;
    public availableSearchStr: string;
    public allStores: any;
    public concept: any;
    public conceptID: any;
    public conceptName: any;
    public selectedStoresForReporting: any[];
    public finishedLoading: boolean;
    public allStoresSelected: boolean;
    public hideAllStoresBtn: boolean;
    public hideFilterButton: boolean;
    public isStoreUser: boolean;
    public showCheckbox: boolean;
    public groupItems: any;
    public tags: any;
    public currentUser: { role: any };
    public subscriptions: any = [];
    public stores: any;
    public singleStoreSelection: boolean = false;
    public lastStoreSelected;
    public captions: any;

    // variable used for styling
    public leftColumnStoreList: any[] = [];
    public rightColumnStoreList: any[] = [];
    public rightColumnSelectedStoreList: any[] = [];
    public leftColumnSelectedStoreList: any[] = [];
    private breakpointReached: boolean = false;

    constructor(
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(ReportEmitterService) private reportEmitterService: ReportEmitterService,
        @Inject(EmitterService) private emitterService: EmitterService,
        @Inject(Router) public router: Router,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
        @Inject(GroupAndTagHelperService) private groupTagHelperService: GroupAndTagHelperService,
        @Inject(ReportParamsHandler) private reportParamsHandler: ReportParamsHandler,
        @Inject(MobileViewService) private mobileViewService: MobileViewService,
        @Inject(BreakpointObserver) private breakpointObserver: BreakpointObserver
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;

        this.conceptName = sharedWizardData.conceptName;
        this.availableSearchStr = '';
        this.selectedSearchStr = ''
        this.conceptID = sharedWizardData.conceptID;
        this.concept = conceptApiService.getConceptById(this.conceptID);

        this.finishedLoading = false;
        this.allStoresSelected = false;
        this.hideAllStoresBtn = false;
        this.hideFilterButton = false;
        this.isStoreUser = false;
        this.showCheckbox = true;
        this.currentUser = this.userAccessHandler.getUserAccess();

        this.subscriptions[0] = this.reportEmitterService.reportGroupItemsSelectedItem$.subscribe((groupItems) => {
            this.selectedStoresForReporting = [];
            this.groupItems = groupItems;
            this.sharedWizardData.groupItemList = groupItems;
            this.hideAllStoresBtn = this.pushAndExecute();
            this.reportEmitterService.reportLeadLabelChanged(this.hideAllStoresBtn);
            if (this.groupItems && this.groupItems.length > 0) {
                this.showCheckbox = false;
            }
        });
        this.subscriptions[1] = this.reportEmitterService.reportTagsSelectedItem$.subscribe((tags) => {
            this.selectedStoresForReporting = [];
            this.tags = tags;
            this.sharedWizardData.tagList = tags;
            this.hideAllStoresBtn = this.pushAndExecute();
            this.reportEmitterService.reportLeadLabelChanged(this.hideAllStoresBtn);
            if (this.tags && this.tags.length > 0) this.showCheckbox = false;
        });

        this.subscriptions[2] = this.emitterService.loadedStoresItem$.subscribe((data) => {
            this.reportEmitterService.toggleSearch(this.availableSearchStr);
        });

        // subscribe to see when the group selection changes in the group dialog so we can filter
        this.subscriptions[3] = this.emitterService.groupItemChangedCtrlItem$.subscribe((groupitemAndConcept: any) => {
            //emit the selection to reportemitterservice
            this.selectedStoresForReporting = [];
            this.reportEmitterService.reportGroupItemsSelected(groupitemAndConcept[0]);
        });

        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_MEDIUM);
    }

    ngOnInit() {
        const singleStoreReports = [123, 138, 139, 140, 141, 142]
        if (singleStoreReports.includes(this.sharedWizardData.templateID)) {
            this.singleStoreSelection = true;
        }

        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        // check breakpoint
        this.breakpointObserver.observe(['(max-width: 1100px)']).subscribe((result) => {
            this.breakpointReached = result.matches;
            if (Array.isArray(this.stores) && Array.isArray(this.selectedStoresForReporting)) {
                // if the screen size <= 1100, show all stores in one column
                if (this.breakpointReached) {
                    // all store column
                    this.leftColumnStoreList = this.leftColumnStoreList.concat(this.rightColumnStoreList);
                    this.rightColumnStoreList = [];
                    // update the general store list
                    this.stores = this.leftColumnStoreList;
                    // selected stores column
                    this.leftColumnSelectedStoreList = this.leftColumnSelectedStoreList.concat(
                        this.rightColumnSelectedStoreList
                    );
                    this.rightColumnSelectedStoreList = [];
                    // update the selected store list
                    this.selectedStoresForReporting = this.leftColumnSelectedStoreList;
                }
                // split stores into two columns
                else {
                    if (this.stores.length < this.leftColumnStoreList.length) {
                        this.stores = this.leftColumnStoreList;
                    }
                    this.splitStores();
                    this.selectedStoresForReporting = this.leftColumnSelectedStoreList;
                    this.splitSelectedStores();
                }
            }
        });

        // get all store data
        this.storeApiService.getStoresByConceptId(this.conceptID).then((stores) => {
            this.selectedStoresForReporting = [];
            this.allStores = stores;
            this.stores = stores;
            this.breakpointReached ? (this.leftColumnStoreList = this.stores) : this.splitStores();
            this.finishedLoading = true;
            if (this.currentUser.role === this.USER_ACCESS.TAG) this.hideFilterButton = true;
            else if (this.currentUser.role === this.USER_ACCESS.GROUP_ITEM)
                this.reportEmitterService.getTagsForReportStoreSelection(this.conceptID, this.currentUser.role);
            else if (this.currentUser.role != this.USER_ACCESS.STORE) {
                this.reportEmitterService.getGroupItemsForReportStoreSelection(this.conceptID);
                this.reportEmitterService.getTagsForReportStoreSelection(this.conceptID, this.currentUser.role);
            } else this.isStoreUser = true;
            if (this.sharedWizardData != null) {
                this.setWizardParams();
            } else this.resetStoreSelection();
        });
    }

    ngOnDestroy() {
        // unsubscribe to all the subscriptions
        for (let sub of this.subscriptions) {
            sub?.unsubscribe();
        }
    }

    nextClicked() {
        this.combineSelectedStore();
        this.sharedWizardData.storeList = this.selectedStoresForReporting;
        if (this.selectedStoresForReporting.length <= 0) {
            this.snackbarService.errorMessageTop(this.captions.pickStoreForReportHint);
            return;
        }
        let selectedStoreIDs = '';
        let sampleLocalCurrency = this.selectedStoresForReporting[0].currencyCode;
        let isLocalCurrencyApplicable = true;
        for (let store of this.selectedStoresForReporting) {
            selectedStoreIDs += store.id.toString() + ',';
            if (sampleLocalCurrency != store.currencyCode) isLocalCurrencyApplicable = false;
            if (sampleLocalCurrency != store.currencyCode) isLocalCurrencyApplicable = false;
        }
        selectedStoreIDs = selectedStoreIDs.slice(0, -1); // getting rid of last comma
        this.reportParamsHandler.jobParams.parameters[1].params[0].value = selectedStoreIDs;
        this.reportParamsHandler.setJobParams(this.reportParamsHandler.jobParams.parameters);
        this.conceptApiService.getConceptById(this.sharedWizardData.conceptID).then((response) => {
            this.concept = response;
            this.sharedWizardData.currencyCode = this.concept.currencyCode;
            if (isLocalCurrencyApplicable && sampleLocalCurrency != this.concept.currencyCode) {
                this.sharedWizardData.localCurrency = sampleLocalCurrency;
                this.sharedWizardData.commonCurrency = this.concept.currencyCode;
            } else {
                this.sharedWizardData.localCurrency = null;
                this.sharedWizardData.commonCurrency = null;
            }
            this.router.navigate(['reports/wizard/confirm', this.conceptID, this.conceptName]);
            this.sharedWizardData.save();
        });
    }

    cancelClicked() {
        this.router.navigate([
            'reports/manageReports',
            this.sharedWizardData.conceptID,
            this.sharedWizardData.conceptName,
        ]);
    }

    setWizardParams() {
        let check = false;
        if (this.sharedWizardData.tagList !== null && this.sharedWizardData.tagList.length > 0) {
            this.tags = this.sharedWizardData.tagList;
            this.reportEmitterService.reportTagsSelected(this.sharedWizardData.tagList);
            this.reportEmitterService.triggerSubmitTags(this.sharedWizardData.tagList);
            check = true;
        }
        if (this.sharedWizardData.groupItemList !== null && this.sharedWizardData.groupItemList.length > 0) {
            this.groupItems = this.sharedWizardData.groupItemList;
            this.reportEmitterService.reportGroupItemsSelected(this.sharedWizardData.groupItemList);
            this.reportEmitterService.triggerSubmitGroupItems(this.sharedWizardData.groupItemList);
            check = true;
        }
        if (
            !check &&
            !this.singleStoreSelection &&
            this.sharedWizardData.storeList != null &&
            this.sharedWizardData.storeList.length > 0
        ) {
            this.hideAllStoresBtn = false;
            this.updateStoreSelection(null, null, this.sharedWizardData.storeList);
        } else if (!check) {
            this.resetStoreSelection();
        }
        if (this.singleStoreSelection) {
            this.lastStoreSelected = this.sharedWizardData.storeList[0];
        }
    }

    pushAndExecute() {
        if (
            typeof this.tags !== 'undefined' &&
            this.tags.length > 0 &&
            typeof this.groupItems !== 'undefined' &&
            this.groupItems.length > 0
        ) {
            let groupItems = this.groupTagHelperService.pushAndExecuteGroupItemPromises(this.groupItems);
            let tagItems = this.groupTagHelperService.pushAndExecuteTagPromises(this.tags);
            Promise.all([groupItems, tagItems]).then((groupStores) => {
                this.updateStoreSelection(groupStores[0], groupStores[1], null);
            });
        } else if (typeof this.groupItems !== 'undefined' && this.groupItems.length > 0) {
            this.reportEmitterService.resetTagSelectionLabel([]);
            if (typeof this.tags !== 'undefined') {
                for (let tag of this.tags) {
                    tag.isChecked = false;
                }
                this.tags = [];
            }
            this.groupTagHelperService.pushAndExecuteGroupItemPromises(this.groupItems).then((groupItems) => {
                this.updateStoreSelection(groupItems, null, null);
            });
        } else if (typeof this.tags !== 'undefined' && this.tags.length > 0) {
            this.reportEmitterService.resetGroupItemSelectionLabel(null);
            if (typeof this.groupItems !== 'undefined') {
                for (let groupItem of this.groupItems) {
                    groupItem.isChecked = false;
                }
                this.groupItems = [];
            }
            this.groupTagHelperService.pushAndExecuteTagPromises(this.tags).then((tagItems) => {
                this.updateStoreSelection(null, tagItems, null);
            });
        } else {
            this.resetStoreSelection();
            return false;
        }
        return true;
    }

    // addObject allows you to search through an array of objects and see if an array already contains it.
    addObject(array: string | any[], id: any) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].id === id) {
                return false;
            }
        }
        return true;
    }

    /*updateStoreSelection will receive three arguments
    groupItemStores represents stores selected in a "group" tag.
    tagStores represents stores selected in a "tagStores" tag.
    stores represents stores selected from the Wizard Data.
    This function will let you see the new stores. 
    */

    updateStoreSelection(groupItemStores: any[], tagStores: any[], stores: any[]) {
        let allSelectedStores: any[];
        // if there is no store
        if (stores === null) {
            this.stores = [];
            this.selectedStoresForReporting = [];
            allSelectedStores = [];
        }
        if (stores) {
            for (let store of this.stores) {
                store.isChecked = stores.some((selectedStore: { id: any }) => {
                    return selectedStore.id === store.id;
                });
            }
            // let orderedStores = this.stores.slice().sort((a, b) => {
            //     return a.isChecked === b.isChecked ? 0 : a.isChecked ? -1 : 1;
            // });
            let orderedStores = this.stores.filter(
                (store) => !stores.find((selectedStore) => selectedStore.id === store.id)
            );
            this.stores = orderedStores;
            this.selectedStoresForReporting = stores;
            // update the store list
            if (this.breakpointReached) {
                this.leftColumnStoreList = this.stores;
                this.leftColumnSelectedStoreList = this.selectedStoresForReporting;
            } else {
                this.splitStores();
                this.splitSelectedStores();
            }
        }

        // filter stores with a group item
        if (groupItemStores) {
            for (let x in groupItemStores) {
                if (this.addObject(allSelectedStores, groupItemStores[x].id)) {
                    groupItemStores[x].isChecked = true;
                    allSelectedStores.push(groupItemStores[x]);
                }
            }
        }

        // filter stores with a tag
        if (tagStores) {
            for (let x in tagStores) {
                if (this.addObject(allSelectedStores, tagStores[x].id)) {
                    tagStores[x].isChecked = true;
                    allSelectedStores.push(tagStores[x]);
                }
            }
        }

        // check if there is any store matches the filter
        // if yes, then update the store list
        if (typeof allSelectedStores !== 'undefined') {
            // grab all already selected stores
            this.selectedStoresForReporting = this.leftColumnSelectedStoreList.concat(
                this.rightColumnSelectedStoreList
            );
            this.stores = allSelectedStores.filter((store) => {
                for (let selectedStore of this.selectedStoresForReporting) {
                    if (selectedStore.id === store.id) {
                        return false;
                    }
                }
                return true;
            });
            this.breakpointReached ? (this.leftColumnStoreList = this.stores) : this.splitStores();
        }
        this.finishedLoading = true;
    }

    selectAllStores() {
        this.selectedStoresForReporting = [];
        this.allStoresSelected = !this.allStoresSelected;
        for (let store of this.stores) {
            if (this.allStoresSelected) {
                store.isChecked = true;
                this.selectedStoresForReporting.push(store);
            } else {
                store.isChecked = false;
                this.selectedStoresForReporting = [];
            }
        }
    }

    resetStoreSelection() {
        this.showCheckbox = true;
        this.selectedStoresForReporting = [];
        this.sharedWizardData.storeList = [];
        this.sharedWizardData.tagList = [];
        this.sharedWizardData.groupItemList = [];
        this.storeApiService.getStoresByConceptId(this.conceptID).then((allStores) => {
            this.stores = allStores;
            this.reportEmitterService.reportLeadLabelChanged(this.singleStoreSelection);
            this.reportEmitterService.resetGroupItemSelectionLabel([]);
            this.reportEmitterService.resetTagSelectionLabel([]);
            this.hideAllStoresBtn = this.singleStoreSelection;
            this.finishedLoading = true;
            this.splitSelectedStores();

            this.breakpointReached ? (this.leftColumnStoreList = this.stores) : this.splitStores();
        });
        this.selectedSearchStr = '';
        this.groupItems = [];
        this.tags = [];

        this.sharedWizardData.save();
    }

    stopPropagation(ev: { stopPropagation: () => void }) {
        ev.stopPropagation();
    }
    
    selectAllPresentedStore() {
        let filteredStoresToPush = []
        /**
             * if available stores has been filtered using search bar:
             * - check which stores match the search string (ignoring case)
             * - add stores to `filteredStoresToPush`
         * */
        if (this.availableSearchStr) {
            for (let store of this.stores) {
                let string = store.displayName.toLowerCase();
                let subString = this.availableSearchStr.toLowerCase();
                if (string.includes(subString)) {
                    filteredStoresToPush.push(store);
                }
            }
            /**
             * - check if there's already stores in the selected column
             * - if there is then concat the 2 arrays
             * - if not then just apply the searched array (filteredStoresToPush)
             */
            if (this.selectedStoresForReporting.length > 0) {
                let combinedStores = filteredStoresToPush.concat(this.selectedStoresForReporting)
                filteredStoresToPush = combinedStores
            }
        }
        /**
             * if no search string detected:
             * - check if there's already stores selected before pushing
             * - if there is then concat available and selected stores
             * - empty available stores `this.stores`
        * */
        else {
            if (this.selectedStoresForReporting.length > 0) {
                let combinedStores = this.stores.concat(this.selectedStoresForReporting)
                filteredStoresToPush = combinedStores
            } else filteredStoresToPush = this.stores;
            this.stores = [];
        }
        if (filteredStoresToPush.length > 0) {
            /**
                 * if search string detected:
                 * -remove filtered stores from the available stores `this.stores`
            * */
            if (this.availableSearchStr) {
                for (let store of filteredStoresToPush) {
                    const index = this.stores.indexOf(store, 0);
                    if (index > -1) {
                        this.stores.splice(index, 1);
                    }
                }
            }
            this.selectedStoresForReporting = filteredStoresToPush;
            if (this.breakpointReached) {
                this.leftColumnSelectedStoreList = this.selectedStoresForReporting;
                this.rightColumnSelectedStoreList = [];
            } else {
                this.splitSelectedStores();
            }
            // reset search string
            this.availableSearchStr = '';
            this.splitStores();
        }
    }

    // split all stores into two columns for styling
    splitStores() {
        this.leftColumnStoreList = [];
        this.rightColumnStoreList = [];
        for (let i = 0; i < this.stores.length; i++) {
            if (i % 2 == 0) {
                this.leftColumnStoreList.push(this.stores[i]);
            } else {
                this.rightColumnStoreList.push(this.stores[i]);
            }
        }
    }

    // split selected stores into two columns for styling
    splitSelectedStores() {
        this.leftColumnSelectedStoreList = [];
        this.rightColumnSelectedStoreList = [];
        for (let i = 0; i < this.selectedStoresForReporting.length; i++) {
            if (i % 2 == 0) {
                this.leftColumnSelectedStoreList.push(this.selectedStoresForReporting[i]);
            } else {
                this.rightColumnSelectedStoreList.push(this.selectedStoresForReporting[i]);
            }
        }
    }

    // combine selected stores from two columns into one array
    combineSelectedStore() {
        this.selectedStoresForReporting = this.leftColumnSelectedStoreList.concat(this.rightColumnSelectedStoreList);
    }

    moveStoreToTheOppositeColumn(store: any) {
        // check which column the store is currently in
        let isInAvailableStoreColumn: boolean = false;
        if (this.stores.includes(store)) {
            isInAvailableStoreColumn = true;
        }
        // toss the store to the opposite column
        if (isInAvailableStoreColumn) {
            // only toss store to selectedStoresForReporting if 
            // report allows multi-store selection or
            // report is for single store, and Selected Stores is empty
            if (!this.singleStoreSelection || this.selectedStoresForReporting.length < 1) {
                this.stores = this.stores.filter((s) => s.id != store.id);
                this.selectedStoresForReporting.push(store);
            }
        } else {
            this.selectedStoresForReporting = this.selectedStoresForReporting.filter((s) => s.id != store.id);
            this.stores.push(store);
        }
        // update the store list
        if (this.breakpointReached) {
            this.leftColumnStoreList = this.stores;
            this.leftColumnSelectedStoreList = this.selectedStoresForReporting;
        } else {
            this.splitStores();
            this.splitSelectedStores();
        }
    }

    filterStore(store: StoreBase, searchTerm?: string) {
        return !searchTerm || store.displayName.toLowerCase().includes(searchTerm);
    }

}
