import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryCountApiService } from '../services/inventory-counter.resource';
import { InventoryCounterService } from '../services/inventory-counter.service';

@Component({
    selector: 'countTypes',
    templateUrl: 'src/app/features/inventory-counter/templates/count-types.html',
})
export class CountTypesComponent implements OnInit {
    public captions: Record<string, string>;
    public passedMaxDate: string;
    public passedMinDate: string;
    public form;
    public formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
    public countTypes = [];
    // the date that will be selected (defaults to today's date)
    public selectedDate: Date;
    public maxDate: Date;
    public minDate: Date;

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(InventoryCountApiService) private inventoryCountApiService: InventoryCountApiService,
        @Inject(InventoryCounterService) private inventoryCounterService: InventoryCounterService,
        @Inject(Router) private router: Router
    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.queryParams.subscribe(params => {
            this.passedMinDate = params['minDate'];
            this.passedMaxDate = params['maxDate'];
        });
        this.form = this.formBuilder.group({
            picker: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {

        this.minDate = new Date(this.passedMinDate + 'T00:00:00');

        //Setting the value of maxDate
        if (this.passedMaxDate === '0') {
            this.maxDate = new Date();
        }
        else {
            this.maxDate = new Date(this.passedMaxDate + 'T00:00:00');
        }

        //Making maxDate the default selected date
        this.selectedDate = this.maxDate;
        this.form.get('picker').touched = true;
        this.form.get('picker').setValue(this.selectedDate);

    }

    backClicked(): void {
        this.inventoryCounterService.logout();
    }

    submitDate(): void {
        let selectedDate = this.form.value.picker;

        this.inventoryCountApiService
            .getInventoryCountsheetXMLByDate(selectedDate)
            .then((res: string) => {
                this.inventoryCounterService.parseXMLData(res);
                this.inventoryCounterService.setActiveCountType(selectedDate);
                this.inventoryCounterService.setActiveCountDate(selectedDate);
                this.router.navigate(['inventoryCounter/countWizard']);
            })
            .catch((err) => {
                if (err.status === 401) {
                    this.router.navigate(['inventoryCounter']);
                }
            });
    }
}
