import { Component, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import * as wijmoOlap from 'wijmo/wijmo.olap';
import { AppConstants } from "../../../../app.constants";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { SnackbarService } from "../../../../utils/shared-services/snackbar/snackbar.service";
import { ReportTemplatesDataService } from "../../services/report-templates-data.resource";
import { ReportApiService } from "../../services/reports.resource";
import { WijmoFormatterService } from "../../services/wijmo-formatter.service";
import { SharedWizardData } from "../../wizard/services/report-shared-wizard-data.service";
import { ViewReportData } from "../view-report.component";
import { ValidationService } from "../../../../utils/shared-services/validation.service";

@Component({
    selector: 'saveReportAsTemplateDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/save-report-as-template.html',
})
export class SaveReportAsTemplateDialog {
    public form;
    public captions;
    public conceptID;
    public info: any;
    REPORT_TECHNOLOGY_TYPES: any;
    pivotPanelGrid1: wijmoOlap.PivotPanel;
    pivotPanelGrid400: wijmoOlap.PivotPanel;
    pivotGrid1: wijmoOlap.PivotGrid;
    pivotGrid400: wijmoOlap.PivotGrid;
    pivotPanelChart1: wijmoOlap.PivotPanel;
    pivotPanelChart400: wijmoOlap.PivotPanel;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<SaveReportAsTemplateDialog>,
        @Inject(SnackbarService) public snackbarService: SnackbarService,
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(SharedWizardData) public sharedWizardData: SharedWizardData,
        @Inject(CaptionService) public captionService: CaptionService,
        @Inject(WijmoFormatterService) public wijmoFormatter: WijmoFormatterService,
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(DomSanitizer) public sanitize: DomSanitizer,
        @Inject(ReportTemplatesDataService) private reportTemplatesDataService: ReportTemplatesDataService,
        @Inject(MAT_DIALOG_DATA) public data: ViewReportData,
        @Inject(Router) public router: Router,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.data.captions;
        this.conceptID = this.data.conceptID;
        this.info = this.data.info;
        this.pivotGrid1 = this.data.pivotGrid1;
        this.pivotGrid400 = this.data.pivotGrid400
        this.pivotPanelGrid1 = this.data.pivotPanelGrid1;
        this.pivotPanelGrid400 = this.data.pivotPanelGrid400;
        this.pivotPanelChart1 = this.data.pivotPanelChart1;
        this.pivotPanelChart400 = this.data.pivotPanelChart400;
        this.REPORT_TECHNOLOGY_TYPES = AppConstants.REPORT_TECHNOLOGY_TYPES;
        this.form = new UntypedFormGroup({
            templateName: new UntypedFormControl(this.info.info.displayName ?? ''),
        });
    }

    ngOnInit(): void { }

    async saveTemplate(formValue: any) {
        try {
            const reportName = formValue.value.templateName;
            let templateID = String(this.info.id).substring(2);

            const isUnique = await this.reportTemplatesDataService.isTemplateUniqueName(reportName);
            if (!isUnique) {
                this.snackbarService.errorMessageTop(this.captions.templateNotUnique);
                return;
            }

            const descriptionID = await this.reportTemplatesDataService.getDescriptionID(templateID, null);

            const template = {
                jobID: this.info.info.jobID,
                descriptionID: descriptionID,
                conceptID: parseInt(this.conceptID),
                displayName: reportName,
                visualizations: this.info.visualizations.map(visualization => ({
                    visualizationID: visualization.id,
                    technology: visualization.technology,
                    columnWidths: []
                }))
            };
            await this.reportApiService.updateTemplate(this.editViewDefinition(template));
            this.closeDialog();
        } catch (error) {
            console.error("Error saving template:", error);
        }
    }

    private captureColumnWidths(visualization: any) {
        if (visualization.technology?.kind === this.REPORT_TECHNOLOGY_TYPES.WIJMO_OLAP) {
            const pivotGrid = this.getPivotGrid(visualization.id);
            if (!pivotGrid) return [];

            pivotGrid.invalidate(); // Force refresh to capture latest column sizes

            // Capture Value Columns (Quantity, Gross Sales)
            let valueColumnWidths = pivotGrid.columns.map(col => col.renderSize);

            // Capture Row Header Columns (Report Category, Product)
            let rowHeaderWidths = pivotGrid.rowHeaders.columns.map(col => col.renderSize);

            // Merge Row Headers & Value Column Widths
            visualization.columnWidths = [...rowHeaderWidths, ...valueColumnWidths];
            return visualization.columnWidths;
        }
    }
    private getPivotGrid(id: number): any {
        switch (id) {
            case 1: return this.pivotGrid1;
            case 400: return this.pivotGrid400;
            default: return null;
        }
    }

    editViewDefinition(jobOrTemplate: any) {
        jobOrTemplate.visualizations = this.info.visualizations
            .filter(visualization => visualization.technology.kind === this.REPORT_TECHNOLOGY_TYPES.WIJMO_OLAP)
            .map(visualization => ({
                id: visualization.id,
                views: visualization.technology.views
                    .map(view => {
                        const viewDefinition = this.getPanelType(visualization.id, view.type)?.viewDefinition;
                        return viewDefinition ? { type: view.type, definition: viewDefinition } : null;
                    })
                    .filter(view => view !== null),
                columnWidths: this.captureColumnWidths(visualization)
            }));
        return jobOrTemplate;
    }


    getPanelType(id: number, type: string) {
        const panelMap = new Map([
            ['1_pivotgrid', this.pivotPanelGrid1],
            ['1_pivotchart', this.pivotPanelChart1],
            ['400_pivotgrid', this.pivotPanelGrid400],
            ['400_pivotchart', this.pivotPanelChart400],
        ]);

        return panelMap.get(`${id}_${type}`) || null;
    }


    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}