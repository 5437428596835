import { Component, Input } from '@angular/core';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { WidgetConstants } from '../../../constants/widget.constants';

@Component({
    selector: 'chart-doughnut',
    templateUrl: 'src/app/features/store-portal/widgets/charts/templates/chart-doughnut.html',
})
export class ChartDoughnut {
    public type: ChartType = 'doughnut';
    public data: ChartData<'doughnut'> = { labels: [], datasets: [] };

    @Input() public labels: string[];
    @Input() public datasets: any[];
    @Input() public hasLegend = false;
    @Input() public colors: any = WidgetConstants.CHART_COLORS_DEFAULT;
    @Input() public options: ChartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: { display: false },
            y: { display: false },
        },
        responsive: true,
    };
    @Input() public plugins: any = [];

    constructor() { }

    ngOnChanges() {
        this.data.labels = this.labels;
        this.datasets.forEach(dataset => {
            this.data.datasets.push({
                data: dataset.data,
                borderWidth: 0,
                backgroundColor: this.colors.backgroundColor,
                hoverBackgroundColor: this.colors.hoverBackgroundColor
            });
        });
    }
}
