import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from "rxjs";
import { AppConstants } from '../../app.constants';

@Injectable()
export class UsageLoggerApiService {

    private usageLoggerUrl: string = 'usageLogger';
    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    // Note: 
    // Try to record usages from the backend side as much as possible
    // If not possible because usage only involves frontend, then go aheand and 
    // use this function
    async recordUsageLog(action: string, factorX: number, message?: string) {
        let url = this.BASE_URL + this.usageLoggerUrl;
        let data$ = this.http.post(url, null, { 
            params: {
                action,
                factorX,
                ...(message ? { message } : {}) // only include 'message' param if it has a value
            }, 
            responseType: "text" 
        });
        const value = await lastValueFrom(data$);
        return value;
    }
}
