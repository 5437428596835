import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { HelpItem } from '../models/help-item.model';

@Component({
    selector: 'help',
    templateUrl: 'src/app/features/help/templates/help.dialog.html'
})
export class HelpDialogComponent {
    public action: string = '';
    public catName: string = '';
    public url: SafeResourceUrl;
    private itemName: HelpItem = {
        action: '',
        link: '',
    };

    constructor(
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(DomSanitizer) private sanitizer: DomSanitizer
    ) {
        this.catName = this.itemStorageService.helpCatName;
        this.itemName = this.itemStorageService.helpItemName;
        this.action = this.itemName.action;
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(
            'https://www.youtube-nocookie.com/embed/' + this.itemName.link + '?rel=0'
        );
    }
}
