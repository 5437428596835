import { Inject, Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { UserInfoHandler } from "../../features/users/services/user-info-handler.service";
import { CaptionService } from "../../utils/shared-services/caption.service";
import { SnackbarService } from "../../utils/shared-services/snackbar/snackbar.service";

@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate {
    constructor(
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(Router) private router: Router,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(CaptionService) private captionService: CaptionService) { };

    canActivate() {
        let userInfo = this.userInfoHandler.getUserInfo();
        if (userInfo === null || userInfo.token === null) {
            this.router.navigate(['login']);
            this.captionService.assignCaptions('ENG', 'FRONT').then(data => {
                let captions: any = data;
                this.snackbarService.errorMessageTop(captions.sessionExpired);
            })
            return false;
        } else {
            return true;
        }
    }
}