import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { Store, StoreBase } from '../../stores/models/store.model';
import { Tag } from '../models/tag.model';

@Injectable()
export class TagApiService {

    private createTagUrl: string = 'tags';
    private updateTagUrl: string = 'tags/{tagID}';
    private getTagInfoByIdUrl: string = 'stores';
    private getStoresByTagIDUrl: string = "store-tags/stores/{tagID}";

    private BASE_URL;

    constructor(@Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getTagById(tagId: number): Promise<Tag> {
        const url = this.BASE_URL + this.updateTagUrl.replace('{tagID}', tagId.toString());
        const data$ = this.http.get<Tag>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async createTag(tag) {
        const url = this.BASE_URL + this.createTagUrl;
        let validTag = this.dataUtils.getCleanedObjectFields(tag, ['conceptID', 'displayName']);
        const data$ = this.http.post(url, validTag);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateTag(tag) {
        const url = this.BASE_URL + this.updateTagUrl.replace('{tagID}', tag.id);;
        let validTag = this.dataUtils.getCleanedObjectFields(tag, ['conceptID', 'displayName']);
        const data$ = this.http.put(url, validTag);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteTag(tagId) {
        const url = this.BASE_URL + this.updateTagUrl.replace('{tagID}', tagId);
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: tagId
        };
        const data$ = this.http.delete(url, httpOptions);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getAllTagsForConcept(conceptId: number): Promise<Tag[]> {
        const url = this.BASE_URL + this.createTagUrl;
        const data$ = this.http.get(url, {
            params: { conceptID: conceptId }
        })
        const value = await lastValueFrom(data$) as Tag[];
        return value;
    }

    async getTagInfoById(tagId: number): Promise<StoreBase[]> {
        const url = this.BASE_URL + this.getTagInfoByIdUrl;
        const data$ = this.http.get(url, {
            params: { tagID: tagId },
        })
        const value = await lastValueFrom(data$) as StoreBase[];
        return value;
    }

    async getStoresByTagID(tagId: number): Promise<Store[]> {
        const url = this.BASE_URL + this.getStoresByTagIDUrl.replace('{tagID}', tagId.toString());
        const data$ = this.http.get<Store[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

}
