import { Component, Inject } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'confirm-disable-portal-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/confirm-disable-portal-dialog.html',
})
export class ConfirmDisablePortalDialog {

    public captions: Record<string, string> ;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
    ) {
        this.captions = this.captionService.captions;
    }
}
