import { Inject, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { CaptionService } from '../caption.service';

@Injectable({
    providedIn: 'root',
})
export class SnackbarService {
    private readonly DURATION: number = 4 * 1000;
    public captions: any;
    successHorizontalPosition: MatSnackBarHorizontalPosition = 'center';
    successVerticalPositionTop: MatSnackBarVerticalPosition = 'top';
    successVerticalPositionBottom: MatSnackBarVerticalPosition = 'bottom';

    constructor(
        @Inject(MatSnackBar) private snackBar: MatSnackBar,
        @Inject(CaptionService) private readonly captionsService: CaptionService
    ) {
        // this.captions = captionsService.captions;
    }

    successMessageTop(message: string) {
        this.snackBar.open(message, this.captionsService.captions.ok.toUpperCase(), {
            horizontalPosition: this.successHorizontalPosition,
            verticalPosition: this.successVerticalPositionTop,
            duration: this.DURATION,
        });
    }

    successMessageBottom(message: string) {
        this.snackBar.open(message, this.captionsService.captions.ok.toUpperCase(), {
            horizontalPosition: this.successHorizontalPosition,
            verticalPosition: this.successVerticalPositionBottom,
            duration: this.DURATION,
        });
    }

    errorMessageTop(message: string) {
        this.snackBar.open(message, this.captionsService.captions.ok.toUpperCase(), {
            horizontalPosition: this.successHorizontalPosition,
            verticalPosition: this.successVerticalPositionTop,
            duration: this.DURATION,
        });
    }

    errorMessageBottom(message: string) {
        this.snackBar.open(message, this.captionsService.captions.ok.toUpperCase(), {
            horizontalPosition: this.successHorizontalPosition,
            verticalPosition: this.successVerticalPositionBottom,
            duration: this.DURATION,
        });
    }
}
