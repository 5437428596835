import { Inject, Injectable } from '@angular/core';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { GroupItem } from '../../store-groups/models/group-item.model';
import { Tag } from '../../tags/models/tag.model';

@Injectable()
export class StoreTagFilterStorage {

    public keyType = {
        CONCEPT_KIND: 'concept',
        REPORT_KIND: 'report'
    }

    constructor(@Inject(BrowserStorage) private browserStorage: BrowserStorage) { }

    /*jshint validthis:true */

    /*
    Format of saved object in session store:
    {
        "concept{{conceptId}}tags":{ tag{{tagId}}:true, tag{{tagId}}:true}
    }
    Example:
    {
        "concept14000001tags":{tag190001:true, tag190005:false}
    }
    */

    saveTagActive(tag: Tag, type: string): void {
        let storeTagSessionKey = this.getSessionKeyName(type, tag.conceptID.toString(), 'tags');
        let savedTagsForConcept = this.browserStorage.getSessionstorage(storeTagSessionKey, {});

        let tagKey = tag.id.toString();
        savedTagsForConcept[tagKey] = tag.isChecked;
        this.browserStorage.setSessionstorage(storeTagSessionKey, savedTagsForConcept);
    }

    getSavedTagFiltersForConcept(conceptId: number, type: string): Object {
        let storeTagSessionKey = this.getSessionKeyName(type, conceptId.toString(), 'tags');
        return this.browserStorage.getSessionstorage(storeTagSessionKey, {});
    }

    removeTagFiltersForConcept(conceptId: number, type: string): void {
        let storeTagSessionKey = this.getSessionKeyName(type, conceptId.toString(), 'tags');
        this.browserStorage.setSessionstorage(storeTagSessionKey, {});
    }

    saveStoreGroupActive(conceptId: number, item: GroupItem, type: string): void {
        let storeItemSessionKey = this.getSessionKeyName(type, conceptId.toString(), 'groupitems');
        let savedItemsForConcept = this.browserStorage.getSessionstorage(storeItemSessionKey, {});

        let itemKey = item.id.toString() + '.' + item.groupID.toString();
        savedItemsForConcept[itemKey] = item.isChecked;

        this.browserStorage.setSessionstorage(storeItemSessionKey, savedItemsForConcept);
    }

    getSavedItemFiltersForConcept(conceptId: number, type: string): Object {
        let storeItemSessionKey = this.getSessionKeyName(type, conceptId.toString(), 'groupitems');
        return this.browserStorage.getSessionstorage(storeItemSessionKey, {});
    }

    removeItemFiltersForConcept(conceptId: number, type: string): void {
        let storeItemSessionKey = this.getSessionKeyName(type, conceptId.toString(), 'groupitems');
        this.browserStorage.setSessionstorage(storeItemSessionKey, {});
    }

    /* private getter of key names*/
    /* kind: conept or report; id: concept id; selection: tags or groupItems;*/
    private getSessionKeyName(type: string, id: string, selection: string): string {
        if (type != this.keyType.CONCEPT_KIND && type != this.keyType.REPORT_KIND)
            throw 'bad key type for session storage';
        return type + id + selection;
    }
}
