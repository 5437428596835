import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { StorePortalApiService } from '../../features/store-portal/services/store-portal.resource';
import { StorePortalService } from '../../features/store-portal/services/store-portal.service';
import { HOPortalApiService } from '../../features/head-office-portal/services/head-office-portal.resource';
import { HOPortalService } from '../../features/head-office-portal/services/head-office-portal.service';

@Injectable()
export class OnlyLoggedInStorePortalUsersGuard implements CanActivate {
    constructor(
        @Inject(StorePortalApiService) private storePortalApiService: StorePortalApiService,
        @Inject(StorePortalService) private storePortalService: StorePortalService,
        @Inject(Router) private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        let savedAccessToken = this.storePortalService.getStorePortalData().AccessToken;
        if (!savedAccessToken) {
            this.router.navigate(['storePortal/login']);
            return false;
        }

        return this.storePortalApiService
            .validateAccessToken(savedAccessToken)
            .then((res) => {
                if (res) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(() => {
                this.router.navigate(['storePortal/home']);
                this.storePortalService.clearAccessToken();
                return false;
            });
    }
}

export class OnlyLoggedInHOPortalUsersGuard {
    constructor(
        // @Inject(HOPortalApiService) private hoPortalApiService: HOPortalApiService,
        // @Inject(HOPortalService) private hoPortalService: HOPortalService,
        // @Inject(Router) private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        // let savedAccessToken = this.hoPortalService.getStorePortalData().AccessToken;
        // if (!savedAccessToken) {
        //     this.router.navigate(['storePortal/login']);
        //     return false;
        // }

        // return this.hoPortalApiService
        //     .validateAccessToken(savedAccessToken)
        //     .then((res) => {
        //         if (res) {
        //             return true;
        //         } else {
        //             return false;
        //         }
        //     })
        //     .catch(() => {
        //         this.router.navigate(['storePortal/home']);
        //         // this.hoPortalService.clearAccessToken();
        //         return false;
        //     });
    }
}
