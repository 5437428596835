import { Component, Inject, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserConstants } from '../../users/users.constants';
import { StoreApiService } from '../services/store.resource';
import { StoreTag, StoreWithTags } from '../models/store.model';
import { UserAccess } from '../../users/models/user-access.model';
import { UserRole } from '../../users/enums/user-role.enum';
import { PrivilegeName, PrivilegeType } from '../../users/enums/user-privileges.enum';
import { TagPerStore } from '../../tags/models/tag.model';

@Component({
    selector: 'assignStoreTags',
    templateUrl: 'src/app/features/stores/templates/assign-store-tags.html'
})
export class StoreTagsComponent {

    private conceptName: any;
    private conceptID: number;
    private storeName: any;
    private storeID: number;
    private currentUser: UserAccess;
    private formBuilder: FormBuilder = new FormBuilder;

    public form = this.formBuilder.group({
        displayName: [''],
        isActive: [false]
    });

    public captions: Record<string, string>;
    public showTagSliders = true;
    public store: StoreWithTags;
    public tags: TagPerStore[];
    public emptyList: boolean;

    @Input() public searchStr: string;

    constructor(
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
    ) {
        this.currentUser = this.userAccessHandler.getUserAccess();
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['pathId'];
            this.conceptName = params['pathName'];
            this.storeID = +params['storeID'];
            this.storeName = params['displayName']
        });
        this.manageAssignTagsPageBasedOnUserPrivilege(this.currentUser);
        this.getTagsByStoreId(this.storeID);
    }

    saveChanges(): void {
        this.storeApiService.updateTagsForStore(this.store).then(() => {
            this.router.navigate(['stores/allStores', this.conceptID, this.conceptName]);
        });
    }

    cancelChanges(): void {
        let conceptID = this.store.conceptID;
        if (typeof conceptID === 'undefined') {
            this.router.navigate(['home']);
        }
        else {
            this.router.navigate(['stores/allStores', this.conceptID, this.conceptName]);
        }
    }

    changedSearchText(searchStr: string): void {
        this.searchStr = searchStr;
    }

    goBackToStores(): void {
        this.router.navigate(['stores/allStores', this.conceptID, this.conceptName]);
    }

    filterTag(tag: StoreTag, searchStr: string): boolean {
        return !searchStr || tag.displayName.toLowerCase().includes(searchStr);
    }

    private manageAssignTagsPageBasedOnUserPrivilege(user: UserAccess): void {
        if (user.role === UserRole.Store || user.role === UserRole.Tag) {
            this.showTagSliders = false;
        }
        else if (
            user.userPrivilege !== null &&
            user.userPrivilege !== UserConstants.USER_PRIVILEGES.ALL_PRIVILEGES
        ) {
            let privileges = JSON.parse(user.userPrivilege) as {name: PrivilegeName, type: PrivilegeType}[];
            let assignStoreTagsPrivilege = false;

            for (let privilege of privileges) {
                if (privilege.name === PrivilegeName.AssignStoreTags) {
                    assignStoreTagsPrivilege = true;
                }
            }
            if (!assignStoreTagsPrivilege) {
                this.showTagSliders = false;
            }
        }
        else if (user.userPrivilege === null) {
            this.showTagSliders = false;
        }
        else {
            this.showTagSliders = true;
        }
    }

    private getTagsByStoreId(storeID: number): void {
        this.storeApiService.getTagsByStoreId(storeID).then((tags: StoreWithTags) => {
            this.store = tags;
            this.tags = tags.tags;
            this.emptyList = this.store.tags.length === 0;

            for (let tag of this.tags) {
                this.form.setValue({
                    displayName: tag.displayName,
                    isActive: tag.isActive
                })
            };
        });
    }
}
