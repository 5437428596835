import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../../utils/shared-services/snackbar/snackbar.service';
import { ScheduleApiService } from '../../services/report-schedules.resource';
import { ReportApiService } from '../../services/reports.resource';
import { ManageReportsData } from '../manage.reports.component';

@Component({
    selector: 'deleteJobDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/delete-job.tpl.html',
})
export class DeleteJobDialog {
    jobToDelete: any;
    captions: any;
    conceptID: any;
    drawer: any;
    reportSchedules: any;
    specificJobs: any;
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteJobDialog>,
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(ScheduleApiService) public scheduleApiService: ScheduleApiService,
        @Inject(MAT_DIALOG_DATA) public data: ManageReportsData,
        @Inject(SnackbarService) public snackbarService: SnackbarService,
    ) { }

    ngOnInit(): void {
        this.jobToDelete = this.data.currentJobToDelete;
        this.captions = this.data.captions;
        this.conceptID = this.data.conceptID;
        this.reportSchedules = this.data.reportSchedules;
        this.specificJobs = this.data.jobs;
    }

    deleteJob(job) {
        if (job.scheduleName) this.deleteSchedule(job);
        else {
            this.reportApiService
                .deleteJob(job.id, this.conceptID)
                .then(() => {
                    for (let jobToDelete of this.specificJobs) {
                        if (jobToDelete.id == job.id) {
                            let jobIndex = this.specificJobs.findIndex((k) => k == job);
                            this.specificJobs.splice(jobIndex, 1);
                            if (this.specificJobs.length == 0) {
                                this.drawer.toggle();
                            }
                        }
                    }
                })
                .catch((err) => {
                    this.snackbarService.errorMessageBottom("Failed to delete job");
                })
        }
        this.closeDialog();
    }

    deleteSchedule(schedule) {
        this.scheduleApiService
            .deleteSchedule(schedule.id, this.conceptID)
            .then((response) => {
                if (response === "Deletion successful" && this.reportSchedules) {
                    for (let scheduleToDelete of this.reportSchedules) {
                        if (scheduleToDelete.id === schedule.id) {
                            let schduleIndex = this.reportSchedules.findIndex((k) => k == schedule);
                            this.reportSchedules.splice(schduleIndex, 1);
                        }
                    }
                }
            })
            .catch((err) => {
                this.snackbarService.errorMessageBottom('Failed to delete schedule ' + schedule.scheduleName,)
            }),
            this.dialog.closeAll();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}