import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { StoreBaseWithAddress } from '../../models/store.model';
import { StoreApiService } from '../../services/store.resource';
import { ResetDialogData } from '../../models/dialog.model';
import { ConfirmedMessageDialog } from './confirmed-message-dialog.component';

@Component({
    selector: 'confirmResetQ2-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/confirm-reset-Q2-dialog.html',
})
export class ConfirmResetQ2Dialog { // Component is currently unused

    private store: StoreBaseWithAddress;
    private storeID: number;

    public captions: Record<string, string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfirmResetQ2Dialog>,
        @Inject(MAT_DIALOG_DATA) private data: ResetDialogData
    ) {
        this.captions = this.captionService.captions;
        this.storeID = this.data.storeID;
    }

    ngOnInit(): void {
        this.storeApiService.getStoreById(this.storeID).then((store: StoreBaseWithAddress) => {
            this.store = store;
        });
    }

    confirmReset(): void {
        this.dialogRef.close();
        this.storeApiService.resetReplicationToken(this.store.id, this.store.replicationToken).then(() => {
            const dialogRef = this.dialog.open(ConfirmedMessageDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                disableClose: true,
            });

            dialogRef.backdropClick().subscribe(() => {
                dialogRef.close();
            });
        });
    }
}
