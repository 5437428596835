import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WebUserSecurityWithStoreList } from '../../features/users/models/user-security.model';
import { AuthService } from '../../auth/services/auth.resource';
import { ConceptApiService } from '../../features/concepts/services/concept.resource';
import { StoreGroupMembersApiService } from '../../features/store-groups/services/store-group-member.resource';
import { StoreApiService } from '../../features/stores/services/store.resource';
import { TagApiService } from '../../features/tags/services/tag.resource';
import { UserAccessHandler } from '../../features/users/services/user-access-handler.service';
import { UserInfoHandler } from '../../features/users/services/user-info-handler.service';
import { UserSecurityApiService } from '../../features/users/services/user-security.resource';
import { UserConstants } from '../../features/users/users.constants';
import { UtilsApiService } from '../../utils/shared-services/utils.resource';
import { ValidationService } from '../../utils/shared-services/validation.service';
import { NotificationsDialog } from './notifications-dialog.component';
import { UserRole } from '../../features/users/enums/user-role.enum';

export interface NotificationData {
    notifications: any;
}

@Component({
    selector: 'login',
    templateUrl: 'src/app/auth/templates/login.html',
})
export class LoginComponent {
    public credentials;
    public passwordChangeObj;
    public captions;
    public portalLogin: boolean = false;
    public hidePassword: boolean = true;

    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
    notifications: Object;
    updates: number;

    constructor(
        @Inject(AuthService) private authService: AuthService,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UserSecurityApiService) private userSecurityApiService: UserSecurityApiService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(ValidationService) public validationService: ValidationService
    ) {
        this.credentials;
        this.passwordChangeObj = {
            newPassword: '',
            confirmNewPassword: '',
        };
        this.form = this.formBuilder.group({
            email: [
                '',
                [Validators.required, Validators.maxLength(this.validationService.maxEmailLength), Validators.pattern(this.validationService.getEmailRegex())],
            ],
            password: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(this.validationService.maxPasswordLength),
                    Validators.minLength(this.validationService.minPasswordLength),
                ],
            ],
        });
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.authService.getGeneralMaintenanceNotifications().then((response: any) => {
            if (response.length > 0) {
                this.notifications = response;
                this.updates = 1;
            }
        });
    }
    performLogin(values: any) {
        let credentials = {
            email: values.email,
            password: values.password,
        };
        this.authService.login(credentials).then((login: any) => {
            if (login.token) {
                this.userInfoHandler.setToken(login.token);
                this.utilsApiService.getUserInfo().then((userInfo: any) => {
                    let showDashboardByDefault = userInfo.showDashboardOnLogin === 1 ? true : false;
                    let userMustResetPassword = !!userInfo.resetPassOnLogin;
                    this.userInfoHandler.setFirstName(userInfo.firstName);
                    this.userInfoHandler.setLastName(userInfo.lastName);
                    this.userInfoHandler.setShowDashboardByDefault(showDashboardByDefault);
                    this.userInfoHandler.setEmail(userInfo.email);
                    this.userInfoHandler.setTheme(userInfo.theme);
                    this.userInfoHandler.setResetPassOnLogin(userMustResetPassword);
                    this.userAccessHandler.setUserPrivilege(userInfo.userPrivilege);
                    this.userAccessHandler.setUserManagement(userInfo.userManagement);

                    this.userInfoHandler.setLanguage(userInfo.language);
                    this.userInfoHandler.setFavouriteTabID(userInfo.favouriteTabID);

                    this.userSecurityApiService.getCurrentUserSecurity().then((currentUserSecurity: WebUserSecurityWithStoreList) => {
                        this.userAccessHandler.setUserID(currentUserSecurity.webUserID);
                        this.userAccessHandler.setConceptID(currentUserSecurity.conceptID);
                        this.userAccessHandler.setGroupItemID(currentUserSecurity.groupItemID);
                        this.userAccessHandler.setTagID(currentUserSecurity.tagID);
                        this.userAccessHandler.setStoreID(currentUserSecurity.storeID);
                        this.userAccessHandler.setRole(currentUserSecurity.role);
                        this.userAccessHandler.setLimitation(currentUserSecurity.limitation);
                        this.getAndSetRoleItem(currentUserSecurity);
                    });
                    let privileges = JSON.parse(userInfo.userPrivilege);
                    for (let privilege of privileges) {
                        if (privilege.name == "STORE_PORTAL_ADMIN_ONLY" || privilege.name == "STORE_PORTAL_USER_ONLY")
                            this.portalLogin = true
                    }

                    if (userMustResetPassword) {
                        this.router.navigate(['users/changePassword']);
                    } else if (showDashboardByDefault && !this.portalLogin) {
                        this.router.navigate(['/dashboard']);
                    } else if (this.portalLogin) {
                        this.router.navigate(['concepts/allConcepts']);
                    } else {
                        this.router.navigate(['home']);
                    }
                });
            }
        });
    }

    getAndSetRoleItem(userSecurity: WebUserSecurityWithStoreList) {
        if (userSecurity.role === UserRole.Concept) {
            this.conceptApiService.getConceptById(userSecurity.conceptID).then((concept) => {
                this.userAccessHandler.setRoleItem(concept);
            });
        } else if (userSecurity.role === UserRole.GroupItem) {
            this.storeGroupMembersApiService.getStoreGroupItemById(userSecurity.groupItemID).then((groupItem) => {
                this.userAccessHandler.setRoleItem(groupItem);
            });
        } else if (userSecurity.role === UserRole.Tag) {
            this.tagApiService.getTagById(userSecurity.tagID).then((tag) => {
                this.userAccessHandler.setRoleItem(tag);
            });
        } else if (userSecurity.role === UserRole.Store) {
            this.storeApiService.getStoreById(userSecurity.storeID).then((store) => {
                this.userAccessHandler.setRoleItem(store);
            });
        } else {
            this.userAccessHandler.setRoleItem(null);
        }
    }

    openMaintainMsg(ev) {
        const dialogRef = this.dialog.open(NotificationsDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                notifications: this.notifications
            },
        });
    }

    togglePasswordVisibility(): void {
        this.hidePassword = !this.hidePassword;
    }

    navigate(state) {
        this.router.navigate([state]);
    }
}
