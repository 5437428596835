import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'review-store-names-dialog',
  templateUrl: "./src/app/features/price-change/templates/dialogs/review-store-names.component.html",
})
export class ReviewStoreNamesDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {
  }
}