import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserAccessHandler } from '../../features/users/services/user-access-handler.service';
import { UserConstants } from '../../features/users/users.constants';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(Router) private router: Router,
    ) {}

    canActivate() {
        let currentUserPrivilege = JSON.parse(this.userAccessHandler.getUserAccess().userPrivilege);
        // check for  the viewSharedDashOnly privilege
        for (let priv of currentUserPrivilege) {
            if (priv.name === UserConstants.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY) {
                this.router.navigate(['home']);
                return false;
            }
        }
        return true;
    }
}
