import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { DashboardApiService } from "../../services/dashboard.resource";
import type { DashboardDialogData } from "../../models/dialog.model";
import { Widget } from "../../models/widget.model";

@Component({
    selector: 'deleteWidgetDialog',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/delete-widget-confirmation.tpl.html',
})
export class DeleteWidgetDialog {
    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteWidgetDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(MAT_DIALOG_DATA) public passedData: DashboardDialogData,
    ) {
        this.captions = this.captionService.captions;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    removeWidget(): void {
        this.dashboardApiService.deleteWidgetInstance(this.passedData.currentWidgetId).then(() => {
            let widgetArr = this.passedData.tabs[this.passedData.currentTabIndex].content as Widget[];
            let indexOfDeleted: number;
            widgetArr.find((widget, index) => {
                if (widget.widgetID === this.passedData.currentWidgetId) indexOfDeleted = index;
            });
            widgetArr.splice(indexOfDeleted, 1);
            this.cancel();
        });
    }
}
