import { Component, Inject, Input, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { AppConstants } from '../../../app.constants';
import { StoreGroupMembersApiService } from '../services/store-group-member.resource';
import { DeleteStoreGroupMemberDialog } from './delete-group-member-dialog.component';
import { ValidationService } from '../../../utils/shared-services/validation.service';

export interface StoreGroupMember {
    id: number
    displayName: string,
    groupID: number,
    currentEdit: boolean
}

@Component({
    selector: 'groupMembers',
    templateUrl: 'src/app/features/store-groups/templates/group-members.manage.html',
})
export class AllGroupMembersComponent {
    @ViewChild('form')
    public form: NgForm;

    private MAX_GROUPMEMBERS;

    public captions;
    public passedConceptId;
    public passedStoreGroupId;
    public editNameBackup = '';
    public groupMembers;
    public newGroupMember;
    public addGroupMember;
    public showGroupMemberOptions;
    public currentGroupMemberToDelete;
    public conceptID;
    public currentGroupMemberToDeleteDependentStore;
    public storeGroupName;
    public conceptName;
    public showConfirmDelete;

    @Input() public searchStr;

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {}

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.passedConceptId = +params['pathId'];
            this.passedStoreGroupId = +params['groupID'];
            this.conceptName = params['pathName'];
        });
        this.MAX_GROUPMEMBERS = AppConstants.MAX_GROUPMEMBERS;
        this.editNameBackup = '';

        this.storeGroupMembersApiService
            .getAllStoreGroupItemsForStoreGroup(this.passedStoreGroupId)
            .then((groupMembers) => {
                for (let groupMember of groupMembers) groupMember.currentEdit = false;
                this.groupMembers = groupMembers;
            })
            .catch((response) => {
                this.snackbarService.errorMessageBottom(response.error.reason);
            });

        this.newGroupMember = {
            displayName: '',
            groupID: this.passedStoreGroupId,
        };

        this.conceptID = this.passedConceptId;
        this.showGroupMemberOptions = true;
        this.currentGroupMemberToDelete = {};
        this.currentGroupMemberToDeleteDependentStore = [];
    }

    createGroupMember() {
        if (this.groupMembers.length >= this.MAX_GROUPMEMBERS) {
            this.snackbarService.errorMessageBottom(
                this.captions.limitOf + this.MAX_GROUPMEMBERS + this.captions.groupMemebersCreated
            );
        } else {
            this.storeGroupMembersApiService
                .createStoreGroupItem(this.newGroupMember)
                .then((value) => {
                    this.groupMembers.push(value);
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
            this.newGroupMember.displayName = '';
            this.form.resetForm();
        }
    }

    startGroupMemberEdit(groupMember) {
        this.showGroupMemberOptions = false;
        groupMember.currentEdit = true;
        this.editNameBackup = groupMember.displayName;
    }

    updateGroupMember(storeGroupItem) {
        if (storeGroupItem && storeGroupItem.displayName) {
            this.storeGroupMembersApiService
                .updateStoreGroupItem(storeGroupItem)
                .then(() => {
                    setTimeout(() => {
                        this.showGroupMemberOptions = true;
                    }, 50);
                    storeGroupItem.currentEdit = false;
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
    }

    revertEdit(groupMember) {
        setTimeout(() => {
            this.showGroupMemberOptions = true;
        }, 50);
        groupMember.currentEdit = false;
        groupMember.displayName = this.editNameBackup;
    }

    deleteGroupMemberDialog(ev, groupMember) {
        this.currentGroupMemberToDelete = groupMember;
        const dialogRef = this.dialog.open(DeleteStoreGroupMemberDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            disableClose: true,
            data: {
                currentGroupMemberToDelete: this.currentGroupMemberToDelete,
                currentGroupMemberToDeleteDependentStore: this.currentGroupMemberToDeleteDependentStore,
                groupMembers: this.groupMembers,
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    changedSearchText(searchStr) {
        this.searchStr = searchStr;
    }

    cancelChanges() {
        this.router.navigate(['concepts/storeGroups', this.passedConceptId, this.conceptName]);
    }

    filterGroupMember(groupMember: StoreGroupMember, searchStr: string) {
        return !searchStr || groupMember.displayName.toLowerCase().includes(searchStr);
    }
}
