import { UserConstants } from '../../features/users/users.constants';

export class DataUtils {
    private USER_ACCESS;
    public httpSuccess;
    public httpError;
    constructor() {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
    }

    getCleanedObjectFields(obj, validFields) {
        let validObj = {};
        for (let prop in obj) {
            if (obj.hasOwnProperty(prop) && validFields.indexOf(prop) >= 0) {
                validObj[prop] = obj[prop];
            }
        }
        return validObj;
    }

    ResponseHttpWrapper($mdToast) {
        this.httpSuccess = (response) => {
            return new Promise((resolve) => {
                resolve(response.data);
            });
        };

        this.httpError = (reason) => {
            return new Promise((resolve, reject) => {
                let toast = $mdToast
                    .simple()
                    .textContent((reason.data) ? reason.data.reason : "Unknown errors have occured at the server.")
                    .action("ok")
                    .highlightAction(true)
                    .hideDelay(3000)
                    .position('center');

                $mdToast.show(toast);

                reject(reason);

            })
        };

    }


    /* array of numbers (ids) accepted*/
    quickSort(a) {
        if (a.length <= 1) return a;
        let left = [] as any,
            right = [] as any,
            pivot = a[0];
        for (let i = 1; i < a.length; i++) {
            if (a[i] < pivot) left.push(a[i]);
            else right.push(a[i]);
        }
        return this.quickSort(left).concat(pivot, this.quickSort(right));
    }

    getRoleFromIDs(conceptID, groupItemID, tagID, storeID) {
        if (conceptID == 0 && groupItemID == 0 && tagID == 0 && storeID == 0) return this.USER_ACCESS.GLOBAL;
        else if (conceptID != 0 && groupItemID == 0 && tagID == 0 && storeID == 0) return this.USER_ACCESS.CONCEPT;
        else if (conceptID != 0 && groupItemID != 0 && tagID == 0 && storeID == 0) return this.USER_ACCESS.GROUP_ITEM;
        else if (conceptID != 0 && groupItemID == 0 && tagID != 0 && storeID == 0) return this.USER_ACCESS.TAG;
        else return this.USER_ACCESS.STORE;
    }
}