import { Component, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { ConceptConstants } from "../../concepts.constants";
import { DialogData } from "../../models/dialog-data.model";

@Component({
    selector: 'concept-details-dialog',
    templateUrl: 'src/app/features/concepts/templates/concept-details-dialog.html',
})
export class ConceptDetailsDialog {
    private DAY_OF_WEEK_MAP = ConceptConstants.DAY_OF_WEEK_MAP;
    private formBuilder: FormBuilder = new FormBuilder();

    public form = this.formBuilder.group({
        displayName: '',
        currencyCode: '',
        dayOfWeek: ''
    });

    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConceptDetailsDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.captions = this.captionService.captions;
        
        const dayOfWeek = this.data.conceptDetail.dayOfWeek;
        this.form.setValue({
            displayName: this.data.conceptDetail.displayName,
            currencyCode: this.data.conceptDetail.currencyCode,
            dayOfWeek: this.DAY_OF_WEEK_MAP[dayOfWeek].STRING || dayOfWeek.toString()
        })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
