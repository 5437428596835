import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.resource';
import { ValidationService } from '../../utils/shared-services/validation.service';

@Component({
    selector: 'passwordRecovery',
    templateUrl: 'src/app/auth/templates/password-recovery.html',
})
export class PasswordRecoveryComponent {
    public captions: any;
    public form = new UntypedFormGroup({
        email: new UntypedFormControl('', [
            Validators.required,
            Validators.pattern(this.validationService.getEmailRegex()),
            Validators.maxLength(this.validationService.maxEmailLength),
        ]),
    });

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(AuthService) private authService: AuthService,
        @Inject(ValidationService) public validationService: ValidationService
    ) {}

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
    }

    triggerMsg() {
        if (this.form.status !== 'INVALID') {
            this.authService.sendRecoveryCode(this.form.value.email).then(() => {
                this.router.navigate(['login/enterCode', this.form.value.email]);
            });
        }
    }

    cancelChanges() {
        this.router.navigate(['/login']);
    }
}
