import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from '../../../../utils/shared-services/caption.service';

@Component({
    selector: 'backup-delete-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/backup-delete-dialog.html',
})
export class BackupDeleteDialog {

    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<BackupDeleteDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
    ) {
        this.captions = this.captionService.captions;
    }
}
