import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '../../../utils/shared-components/confirmation-dialog.component';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { InventoryCountApiService } from './inventory-counter.resource';
import { InventoryCounterService } from './inventory-counter.service';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';

@Injectable({
    providedIn: 'root',
})
export class InventoryCounterDialogService {
    constructor(
        @Inject(InventoryCountApiService) private inventoryCountApiService: InventoryCountApiService,
        @Inject(InventoryCounterService) private inventoryCounterService: InventoryCounterService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(CaptionService) public readonly captionService: CaptionService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) private router: Router,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) { }

    countSubmitClicked(saveOnly) {
        let title = (saveOnly == 1) ? this.captionService?.captions?.saveCounts : this.captionService?.captions?.submitCounts
        let message = (saveOnly == 1) ? this.captionService?.captions?.inventorySaveMessage : this.captionService?.captions?.inventorySubmitMessage
        
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            panelClass: 'app-full-bleed-dialog',
            data: {
                title: title,
                message: message,
                confirm: this.captionService?.captions?.confirm,
                cancel: this.captionService?.captions?.cancel,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                let xmlData = this.inventoryCounterService.getItemsAsXML();
                // Submit to POS
                let date = this.inventoryCounterService.getActiveCountDate().split('T')[0];
                this.inventoryCountApiService
                    .submitXMLtoPOS(xmlData, date, saveOnly)
                    .then((postRes) => {
                        this.snackbarService.successMessageBottom('Saved Count to POS.');
                        this.inventoryCounterService.logout();
                    })
                    .catch((err) => {
                        if (err.status === 401) {
                            this.router.navigate(['inventoryCounter']);
                        }
                    });
            }
        });
    }

    countCancelClicked() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            panelClass: 'app-full-bleed-dialog',
            data: {
                title: this.captionService?.captions?.cancelCounts,
                message: this.captionService?.captions?.cancelInventorySession,
                confirm: this.captionService?.captions?.confirm,
                cancel: this.captionService?.captions?.cancel,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.router.navigate(['inventoryCounter/countType'],{
                    queryParams: {
                        minDate: this.browserStorage.getLocalstorage('invMinDate', null),
                        maxDate: this.browserStorage.getLocalstorage('invMaxDate', null),
                    }
                });
                this.inventoryCounterService.clearInventoryStorage();
            }
        });
    }

    disconnectedBtnClicked() {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            panelClass: 'app-full-bleed-dialog',
            data: {
                title: this.captionService?.captions?.noInternetConnection,
                message: this.captionService?.captions?.countSubmitFailed,
                confirm: this.captionService?.captions?.tryAgain,
                cancel: this.captionService?.captions?.cancel,
            },
        });
        return dialogRef.afterClosed();
    }
}
