import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { SelectedDateData, StorePortalData } from "../../models/store-portal.model";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { EoiApiService } from "../../../../features/eoi/services/eoi.resource";
import { xml2json } from "xml-js";
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { CacheService } from "../../services/store-portal-cache.service";
import { StorePortalApiService } from "../../services/store-portal.resource";
import { LoadingScreenComponent } from "./loading-screen-dialog.component";
import { parseStringPromise } from 'xml2js';
import * as moment from 'moment';

interface ReportNode {
    name: string;
    hasDate: string;
    source: string;
    children?: ReportNode[];
}

export interface ReportData {
    reportName: string;
    source: string;
    date1: string;
    date2: string;
    detailLevel: string;
    storeID: number;
}

@Component({
    selector: 'reportViewer',
    templateUrl: 'src/app/features/store-portal/templates/dialogs/report-viewer-dialog.html',
})
export class ReportViewerDialog {
    public captions: Record<string, string>;
    range: UntypedFormGroup;
    selectDate: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
    treeControl = new NestedTreeControl<ReportNode>(node => node.children);
    treeData = new MatTreeNestedDataSource<ReportNode>();
    filteredTreeData: ReportNode[];

    storeID;

    minDate: Date;
    maxDate: Date;
    values: any;
    reportNodeClicked: boolean;
    reportHasDate: boolean;
    showDetails: boolean = false;
    reportNodeName: string;
    isDateChanged: boolean = false;
    sourceName: string;

    fileUrl: string = 'https://hq-crystal-reports.s3.amazonaws.com/';
    fileName: string;
    maxRetries: number = 12;  // 12 retries for 1 minute at 5 seconds interval
    loading: boolean = false;
    cancelInterval: boolean = false;
    empNum: string;
    errorNum: any;
    errorMessage: any;
    showError: boolean = false;
    loadingDialogRef: MatDialogRef<LoadingScreenComponent, any>;
    passedStartDate: Date;
    passedEndDate: Date;
    xmlError: boolean = false;

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(MatDialogRef) private reportViewerDialogRef: MatDialogRef<ReportViewerDialog>,
        @Inject(MAT_DIALOG_DATA) private data: StorePortalData,
        @Inject(StorePortalApiService) private storePortalApiService,
        @Inject(CaptionService) private captionService,
        @Inject(EoiApiService) private eoiApiService,
        @Inject(CacheService) private cacheService: CacheService,
    ) {
        this.passedStartDate = this.data.PassedStartDate;
        this.passedEndDate = this.data.PassedEndDate;
        this.captions = this.captionService.captions;
        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 20, 0, 1);
        this.maxDate = new Date(currentYear + 5, 11, 31);
        this.storeID = this.data.StoreID;
        this.empNum = this.data.EmpNum;
    }

    hasChild = (_: number, node: ReportNode) => !!node.children && node.children.length > 0;

    ngOnInit(): void {
        this.range = this.formBuilder.group({
            startDate: [this.passedStartDate, [Validators.required]],
            endDate: [this.passedEndDate, [Validators.required]]
        });
        this.loading = true;
        const cachedData = this.cacheService.get(this.storeID);
        if (cachedData) {
            this.processResponse(cachedData);
        } else {
            this.eoiApiService.getReportViewerInfo(this.storeID, this.empNum).then((response) => {
                // Save response in cache
                this.cacheService.set(this.storeID, response);
                this.processResponse(response);
            });
        }
    }

    onNodeClick(node: ReportNode): void {
        this.reportNodeName = node.name;
        this.reportNodeClicked = true;
        this.reportHasDate = node.hasDate == "1" ? true : false;
        this.sourceName = node.source;
    }

    dateChanged() {
        const startDateSelected = this.range.controls.startDate.value;
        const endDateSelected = this.range.controls.endDate.value;

        const startDateMoment = this.range.get("startDate")?.value;
        const endDateMoment = this.range.get("endDate")?.value;
        // If no end date is selected, set the end date equal to the start date
        if (!endDateSelected && startDateSelected) {
            this.range.controls.endDate.setValue(startDateSelected);
        }
        this.range.controls.startDate.updateValueAndValidity();
        this.range.controls.endDate.updateValueAndValidity();

        let startDate = moment.isMoment(startDateMoment) ? startDateMoment.toDate() : startDateMoment instanceof Date ? startDateMoment : startDateMoment;
        let endDate = moment.isMoment(endDateMoment) ? endDateMoment.toDate() : endDateMoment instanceof Date ? endDateMoment : endDateMoment;

        this.passedStartDate = startDate;
        this.passedEndDate = endDate;
        this.isDateChanged = true;
    }

    isDateRangeValid(): boolean {
        const startDate = this.range.controls.startDate.value;
        const endDate = this.range.controls.endDate.value;

        // Check if both dates are filled and valid
        const hasErrors = this.range.controls.startDate.hasError('matStartDateInvalid') ||
            this.range.controls.endDate.hasError('matEndDateInvalid');

        return startDate && endDate && !hasErrors;
    }

    cancelChanges() {
        this.reportNodeClicked = false;
        this.showError = false;
    }

    async openReport() {
        const reportData: ReportData[] = [];
        let date_1 = new Date(this.range.get("startDate")?.value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');;
        let date_2 = new Date(this.range.get("endDate")?.value).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/(\d+)\/(\d+)\/(\d+)/, '$3-$1-$2');
        let showDetails = this.showDetails ? "1" : "0";
        if (this.isDateChanged) {
            const startDate = this.range.get("startDate")?.value;
            const endDate = this.range.get("endDate")?.value;

            // Format startDate
            if (moment.isMoment(startDate)) {
                date_1 = startDate.format('YYYY-MM-DD');
            } else if (startDate instanceof Date) {
                date_1 = moment(startDate).format('YYYY-MM-DD');
            } else {
                date_1 = null; // Handle the case if it's neither a Moment nor Date
            }

            // Format endDate
            if (moment.isMoment(endDate)) {
                date_2 = endDate.format('YYYY-MM-DD');
            } else if (endDate instanceof Date) {
                date_2 = moment(endDate).format('YYYY-MM-DD');
            } else {
                date_2 = null; // Handle the case if it's neither a Moment nor Date
            }
        }
        reportData.push({ reportName: this.reportNodeName, source: this.sourceName, date1: date_1, date2: date_2, detailLevel: showDetails, storeID: this.storeID });
        this.openLoadingDialog();
        try {
            const response = await this.storePortalApiService.viewCrystalReport(reportData[0]);

            // Parse the XML string into an object
            const parsedXml = await parseStringPromise(response.responseString);

            // Check if "ErrorNum" exists in the parsed object
            this.fileName = response.output;
            this.startCheckingFile(this.fileName);
            if (parsedXml && parsedXml.Response.ErrorNum[0] != "0") {
                this.errorNum = parsedXml.Response.ErrorNum[0];
                this.errorMessage = parsedXml.Response.Status ? parsedXml.Response.Status[0] : 'No additional information';
                this.cancelInterval = true;
                this.loadingDialogRef.close();
                this.showError = true;
                return;
            }
        } catch (error) {
            console.error("Error in openReport:", error);
        }
    }

    openLoadingDialog(): void {
        this.reportNodeClicked = false;
        this.loadingDialogRef = this.dialog.open(LoadingScreenComponent, {
            disableClose: true,
            panelClass: 'loading-dialog',
            backdropClass: 'backdrop-background',
        });
        this.loadingDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.openLoadingDialog();
                this.startCheckingFile(this.fileName); // Restart the loading screen if user confirms
            } else this.cancelInterval = true;
        });
    }

    closeReportDialog(): void {
        let passedDate: SelectedDateData = {
            date1: this.passedStartDate, date2: this.passedEndDate,
            selectedDate: ""
        };
        this.reportViewerDialogRef.close(passedDate);
    }

    startCheckingFile(filename: string): void {
        this.cancelInterval = false;
        let attempts = 0;

        const intervalId: number = window.setInterval(() => {
            if (attempts >= this.maxRetries || this.cancelInterval) {
                clearInterval(intervalId);
                return;
            }

            this.checkFile(intervalId, filename);
            attempts++;
        }, 2000); // 2000 milliseconds = 2 seconds

        // Stop the check after 1 minute regardless of attempts
        setTimeout(() => {
            clearInterval(intervalId);
        }, 15000); // 15000 milliseconds = 15 seconds
    }

    checkFile(intervalId: number, filename: string): void {
        let s3FileName = this.fileUrl + filename;
        this.storePortalApiService.checkFileExists(s3FileName).subscribe(exists => {
            if (exists) {
                window.open(s3FileName, '_blank');
                this.loadingDialogRef.close();
                clearInterval(intervalId);  // Stop checking if the file exists
            }
        })
    }

    processResponse(response: any): void {
        this.xmlError = false;
        try {
            // Convert the XML response to a JSON object for easier manipulation
            const jsonObject = JSON.parse(xml2json(response, { compact: true, spaces: 2 }));

            // Check if the response contains an error message
            if (jsonObject.Response?.Error) {
                throw new Error(jsonObject.Response.Error._text || "Unknown error in response");
            }

            let reportTreeXML = jsonObject.Response?.DataSets?.DataSet?.[0]?.Record?.REPORTTREEXML?._text;
            let maxJobSec = jsonObject.Response?.DataSets?.DataSet?.[1]?.Record?.MAXJOBSEC?._text || "99";

            // Validate if REPORTTREEXML exists
            if (!reportTreeXML) {
                throw new Error("Report Tree XML is missing or invalid in response");
            }

            // Attempt to parse the nested XML inside REPORTTREEXML
            let reportTreejsonObject;
            try {
                reportTreejsonObject = JSON.parse(xml2json(reportTreeXML, { compact: true, spaces: 2 }));
            } catch (nestedError) {
                throw new Error("Invalid nested XML in Report Tree XML: " + nestedError.message);
            }

            // Transform the JSON into the desired format
            const REPORT_DATA: ReportNode[] = this.transformJson(reportTreejsonObject.PIXELREPORTS, maxJobSec);

            // Assign transformed data
            this.treeData.data = REPORT_DATA;
            this.filteredTreeData = REPORT_DATA;
            this.loading = false;
        } catch (error: any) {
            this.loading = false;
            this.xmlError = true;
            this.errorMessage = "Report XML is invalid - " + error.message; 
        }
    }

    transformJson(jsonData: any, maxJobSec: string): ReportNode[] {
        const transformedData: ReportNode[] = [];

        if ("REPORT" in jsonData) {
            const reports = Array.isArray(jsonData["REPORT"]) ? jsonData["REPORT"] : [jsonData["REPORT"]];

            transformedData.push(...reports.map(report => ({
                name: report["_text"],
                hasDate: report.ASKDATE ? report.ASKDATE["_text"] : "",
                source: report.SOURCE ? report.SOURCE["_text"] : ""
            })));
        }

        if ("GROUP" in jsonData) {
            const groups = Array.isArray(jsonData["GROUP"]) ? jsonData["GROUP"] : [jsonData["GROUP"]];
            transformedData.push(...groups.map(group => this.transformNode(group, maxJobSec)).filter(Boolean));
        }

        return transformedData;
    }

    transformNode(node: any, maxJobSec: string, depth = 1): ReportNode | null {
        if (depth > 4) return null;

        // Initialize children array to collect all children nodes
        const children: ReportNode[] = [];

        // Process REPORT if it exists
        if ("REPORT" in node && (Array.isArray(node["REPORT"]) || typeof node["REPORT"] === 'object')) {
            const reports = Array.isArray(node["REPORT"]) ? node["REPORT"] : [node["REPORT"]];
            const reportNodes = reports
                .filter(report => this.checkSecurityLevel(report, maxJobSec))
                .map(report => ({
                    name: report["_text"],
                    hasDate: report.ASKDATE ? report.ASKDATE["_text"] : "",
                    source: report.SOURCE ? report.SOURCE["_text"] : ""
                }));

            children.push(...reportNodes);
        }

        // Process GROUP if it exists
        if ("GROUP" in node && (Array.isArray(node["GROUP"]) || typeof node["GROUP"] === 'object')) {
            const groups = Array.isArray(node["GROUP"]) ? node["GROUP"] : [node["GROUP"]];
            const groupNodes = groups
                .map(child => this.transformNode(child, maxJobSec, depth + 1))
                .filter(Boolean) as ReportNode[];

            children.push(...groupNodes);
        }

        // If there are no children and no meaningful text, return null
        if (children.length === 0 && !node["_text"]) return null;

        return {
            name: node["_text"],
            hasDate: "1",
            source: "",
            children: children.length > 0 ? children : undefined,
        };
    }
    checkSecurityLevel(node: any, maxJobSec: string): boolean {
        let parsedJobSec = parseInt(maxJobSec, 10);
        const securityLevel = node["SECURITYLEVEL"] ? node["SECURITYLEVEL"]["_text"] : null;
        // check to see if the report security is less than the employee security
        if (securityLevel && parseInt(securityLevel) <= parsedJobSec) {
            return true;
        }
        return false;
    }

    // Function to filter the tree nodes based on the search term
    filterTree(node: ReportNode, searchTerm: string): ReportNode | null {
        // Check if the current node's name includes the search term (case-insensitive)
        const matches = node.name.toLowerCase().includes(searchTerm.toLowerCase());
        // If the current node matches the search term, return the node as is
        if (matches) {
            return node;
        }
        // If the current node has children, attempt to filter the children recursively
        if (node.children) {
            const filteredChildren = node.children
                .map(child => this.filterTree(child, searchTerm)) // Recursively filter each child
                .filter(child => child !== null); // Remove any null results (non-matching nodes)

            // If there are any matching children, return the current node with the filtered children
            if (filteredChildren.length > 0) {
                return { ...node, children: filteredChildren }; // Return the node with its matching children
            }
        }
        // If neither the current node nor its children match the search term, return null
        return null;
    }

    // Function to apply the filter to the entire tree based on the search term
    applyFilter(searchTerm: string): void {
        // If the search term is empty, reset the filtered data to the original tree data
        if (!searchTerm) {
            this.filteredTreeData = this.treeData.data;
        } else {
            // Otherwise, filter the tree data based on the search term
            this.filteredTreeData = this.treeData.data
                .map(node => this.filterTree(node, searchTerm)) // Apply the filter to each node in the tree
                .filter(node => node !== null); // Remove any null results (non-matching nodes)
        }
    }
}