import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HOPortalApiService } from '../services/head-office-portal.resource';
import { HOPortalService } from '../services/head-office-portal.service';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'headOfficePortalLogin',
    templateUrl: 'src/app/features/head-office-portal/templates/head-office-portal-login.html',
})
export class HOPortalLoginComponent {
    public captions: any;
    public passedStoreAlias;

    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(HOPortalService) private hoPortalService: HOPortalService,
        @Inject(HOPortalApiService) private hoPortalApiService: HOPortalApiService,
        @Inject(ValidationService) public validationService: ValidationService

    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.actRoute.queryParams.subscribe((params) => {
            this.passedStoreAlias = params['PortalID'];
        });

        this.form = this.formBuilder.group({
            storeAlias: ['', [Validators.required, Validators.maxLength(this.validationService.portalAliasMaxLength), Validators.minLength(this.validationService.portalAliasMinLength)]],
            userID: ['', []],
            empID: ['', [Validators.required, Validators.maxLength(this.validationService.portalEmpIDMaxLength)]],
        });
    }

    ngOnInit() { }

    login(value) {
        this.hoPortalApiService.loginForHoPortal(value.storeAlias, value.userID, value.empID, {}).then((res: any) => {
            let empNum = 0;
            this.hoPortalService.setHoPortalData(res);
            if (res.DataSets.DataSet[3].Record) {
                empNum = res.DataSets.DataSet[3].Record.EMPNUM;
            }
            this.router.navigate(['hoPortal/home', empNum]);
        });
    }
}