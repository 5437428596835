import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'recycledItemsFilter',
})
export class RecycledItemsFilterPipe implements PipeTransform {
    transform(array: any, typeName: string): any[] {
        return array.filter((each: any) => each.type.toLowerCase() === typeName.toLowerCase());
    }
}
