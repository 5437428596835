import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';

interface Parameters {
    failDetailsForSelectedEOI: any;
    userAccessInfo: any;
    failedEOIClicked: any;
}

@Component({
    selector: 'eoi-fail-details-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-fail-details.dialog.html',
})
export class EoiFailDetailsDialog {
    public captions: Record<string,string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EoiFailDetailsDialog>
    ) {
        this.captions = this.captionService.captions;
    }

    showResponseXML(): void {
        // parse and show responseXML if it exists, or create and show not found message
        if (this.data.failedEOIClicked.responseXML) {
            let blob = new Blob([this.data.failedEOIClicked.responseXML], { type: 'text/xml' });
            let url = URL.createObjectURL(blob);
            window.open(url);
            URL.revokeObjectURL(url);
        }
        else {
            const htmlContent = `
                                    <html>
                                        <head><title>Response XML Not Found</title></head>
                                        <body><h3>Response XML Not Found</h3></body>
                                    </html>
                                `;

            const blob = new Blob([htmlContent], { type: 'text/html' });
            let url = URL.createObjectURL(blob);
            window.open(url);
            URL.revokeObjectURL(url);
        }
    }

    showRequestXML():void {
        let parser = new DOMParser();
        let doc = parser.parseFromString(this.data.failedEOIClicked.orderXML, 'text/xml');
        doc.getElementsByTagName('EOIAuthenticationToken')[0].childNodes[0].nodeValue = 'xxxxxx-xxxxxxxxxx';
        let xmlToString = new XMLSerializer().serializeToString(doc);
        let blob = new Blob([xmlToString], { type: 'text/xml' });
        let url = URL.createObjectURL(blob);
        window.open(url);
        URL.revokeObjectURL(url);
    }
}
