import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    constructor(
        @Inject(SpinnerService) private spinnerOverlayService: SpinnerService,
        @Inject(Router) private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // ignore these routes
        if (
            this.router.url.includes('/dashboard') || 
            this.router.url.includes('/storePortal/home') ||
            this.router.url.includes('/reports/viewReport') ||
            this.router.url.includes('/priceMatch')
        ) {
            return next.handle(req);
        } else {
            const spinnerSubscription: Subscription = this.spinnerOverlayService.spinner$.subscribe();
            return next.handle(req).pipe(finalize(() => setTimeout(() => spinnerSubscription.unsubscribe(), 100)));
        }
    }
}
