import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstants } from '../../../../src/app/app.constants';
import { HelpDialogComponent } from '../../../../src/app/features/help/components/help.component';
import { DashboardEmitterService } from '../../features/dashboard/services/dashboard-emitter.service';
import { InventoryCounterService } from '../../features/inventory-counter/services/inventory-counter.service';
import { ReportParamsHandler } from '../../features/reports/wizard/services/report-params-handler.service';
import { SharedWizardData } from '../../features/reports/wizard/services/report-shared-wizard-data.service';
import { UserAccessHandler } from '../../features/users/services/user-access-handler.service';
import { UserInfoHandler } from '../../features/users/services/user-info-handler.service';
import { UserConstants } from '../../features/users/users.constants';
import { BrowserStorage } from '../shared-services/browser-storage.service';
import { CaptionService } from '../shared-services/caption.service';
import { EmitterService } from '../shared-services/emitter.service';
import { ItemStorageService } from '../shared-services/item-storage.service';
import { MatTooltip } from '@angular/material/tooltip';
import { HelpCategory } from '../shared-models/help-categories.model';
import { CustomDateOption } from '../shared-models/custom-date-option.model';
import { UserInfo } from '../shared-models/user-info.model';
import { RefreshIntervalTimes } from '../shared-models/refresh-interval-times.model';
import { Categories } from '../shared-models/categories-json.model';

@Component({
    selector: 'titleBar',
    templateUrl: 'src/app/utils/shared-templates/titlebar.html',
})
export class TitleBarComponent {
    private USER_ACCESS;
    private USER_PRIVILEGES;
    private userAccess;
    public captions: Record<string, string>;

    public searchStr: string = '';
    public dateSelectorView: { display: string };
    public showRecycleBinOption: boolean = false;
    public helpCategories: HelpCategory[] = [];
    private categoriesJSON: Categories;
    public currentYear: number = 0;
    public isMobile: boolean = false;
    public isDashboard: boolean = false;
    public isAdmin: boolean = false;
    public userInfo: UserInfo;
    public customDateList: CustomDateOption[] = [];
    public selectedDate: any = '';
    public selectedCustomDate: string = '';
    public captionsLoaded: boolean = false;
    public viewSharedDashOnly: boolean = false;
    public portalOnlyView: boolean = false;
    private refreshIntervalTimes: RefreshIntervalTimes[] = [];
    private optionAutoHideTimeout: NodeJS.Timeout;

    @Input() public isWizard: boolean;
    @Input() public opened: boolean;
    @Input() public showSearchBar: boolean;
    @Input() public dateSelector: boolean;
    @Input() public hideRightIcons: boolean;
    @Input() public inventoryCounterActive: boolean;
    @Input() public unauthorized: boolean;
    @Input() public refreshInterval: number;

    @Output() public searchStrChanged = new EventEmitter();
    @Output() public intervalChanged = new EventEmitter();
    @ViewChild('optionTooltip') optionTooltip: MatTooltip;
    constructor(
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData,
        @Inject(ReportParamsHandler) private reportParamsHandler: ReportParamsHandler,
        @Inject(InventoryCounterService) private inventoryCounterService: InventoryCounterService,
        @Inject(DashboardEmitterService) private dashboardEmitterService: DashboardEmitterService,
        @Inject(EmitterService) private emitterService: EmitterService,
        @Inject(Router) private router: Router
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.USER_PRIVILEGES = UserConstants.USER_PRIVILEGES;
        this.userAccess = this.userAccessHandler.getUserAccess();
        this.userInfo = userInfoHandler.getUserInfo();

        // check for  the viewSharedDashOnly privilege
        if (this.userAccess) {
            for (let priv of JSON.parse(this.userAccess.userPrivilege)) {
                if (priv.name == UserConstants.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY) {
                    this.viewSharedDashOnly = true;
                } else if (
                    priv.name == UserConstants.USER_PRIVILEGES.PORTAL_ADMIN ||
                    priv.name == UserConstants.USER_PRIVILEGES.PORTAL_USER
                )
                    this.portalOnlyView = true;
            }
        }

        if (!this.captionService.captions) {
            this.captionService.assignCaptions('ENG', 'FRONT').then((captions) => {
                this.captions = captions;
            });
        } else this.captions = this.captionService.captions;
        this.dateSelectorView = {
            display: 'none',
        };
        this.showRecycleBinOption = true;
        this.categoriesJSON = {
            appdashboard: false,
            appconcepts: false,
            appstores: false,
            appconceptsmanage_tags: false,
            appstoresassignStoreTags: false,
            appconceptsstore_groups: false,
            appconceptsgroup_members: false,
            appstoresassignStoreGroups: false,
            appEOI: false,
            appusersallUsers: false,
            appprofile_settings: false,
        };
        for (const prop in AppConstants.WIDGET_CUSTOM_DATE_DROPDOWN_OPTIONS) {
            let option = AppConstants.WIDGET_CUSTOM_DATE_DROPDOWN_OPTIONS[prop];
            this.customDateList.push({
                key: option,
                value: this.captions[option],
            });
        }
        // list of refreshIntervals to use
        this.refreshIntervalTimes = [
            {
                // Every 15 min
                desc: this.captionService.captions.refreshIntervalDescription1,
                shortDesc: this.captionService.captions.refreshIntervalDescription1Short,
                value: 15,
            },
            {
                // Every 30 min
                desc: this.captionService.captions.refreshIntervalDescription2,
                shortDesc: this.captionService.captions.refreshIntervalDescription2Short,
                value: 30,
            },
            {
                // Every 45 min
                desc: this.captionService.captions.refreshIntervalDescription3,
                shortDesc: this.captionService.captions.refreshIntervalDescription3Short,
                value: 45,
            },
            {
                // Every 1 hour
                desc: this.captionService.captions.refreshIntervalDescription4,
                shortDesc: this.captionService.captions.refreshIntervalDescription4Short,
                value: 60,
            },
            {
                // Every 1.5 hour
                desc: this.captionService.captions.refreshIntervalDescription5,
                shortDesc: this.captionService.captions.refreshIntervalDescription5Short,
                value: 90,
            },
        ];
    }
    ngOnInit() {
        if (this.router.url.includes('/login')) {
            this.hideRightIcons = true;
        }
        //setHelpKeysAndStates();
        this.helpCategories = this.captions.Help_ALL.split('\n').map((categoryString: string) => {
            const [key, ...states] = categoryString.split(',');
            return {
                key: key,
                states: states,
                header: '',
                toggled: false,
                items: []
            };
        });

        for (let i = 0; i < this.helpCategories.length; i++) {
            let keyAndState = this.helpCategories[i].states;
            for (let j = 0; j < keyAndState.length; j++) {
                this.helpCategories[i].states[j] = keyAndState[j];
                let stateWithoutPeriod = keyAndState[j].split('.').join('');
                this.categoriesJSON[stateWithoutPeriod] = true;
            }
        }
        
        //setHelpBarTitles();
        for (let cat of this.helpCategories) {
            let cat_info = this.captions[cat.key];
            let cat_links = this.captions[cat.key + '_links'];
            let info = [];
            let links = [];
            if (!cat_info || !cat_links) return;
            info = cat_info.split('\n');
            links = cat_links.split('\n');
            cat.header = info[0];
            cat.items = [];
            for (let o = 1; o < info.length; o++) {
                let pair = {
                    action: info[o],
                    link: links[o - 1],
                };
                cat.items.push(pair);
            }
        }
        //toggleHelpCurrentState();
        // let state = this.$state.current.name;
        // let stateLength = state.split('.').length;
        let helpCategoryResult: string = '';

        // for (let i = stateLength; i > 1; i--) {
        //     let stateWithoutPeriod = state.split('.').slice(0, i).join('');
        //     if (this.categoriesJSON[stateWithoutPeriod] !== undefined) {
        //         helpCategoryResult = state.split('.').slice(0, i).join('.');
        //         break;
        //     }
        // }

        if (helpCategoryResult !== '') {
            for (let j = 0; j < this.helpCategories.length; j++) {
                let helpCategory = this.helpCategories[j].states;
                for (let k = 0; k < helpCategory.length; k++) {
                    if (helpCategory[k] === helpCategoryResult) {
                        this.helpCategories[j].toggled = true;
                        break;
                    }
                }
            }
        }
        //set current Year
        let date = new Date();
        this.currentYear = date.getFullYear();
        //check if mobile
        if (this.browserStorage.getLocalstorage('isPhoneScreen', false) === true) {
            this.isMobile = true;
        }

        if (this.userAccess) this.manageTitleBarOptionsBasedOnPrivileges();

        this.dashboardEmitterService.sendFormattedDateToTitlebarItem$.subscribe((dateString) => {
            this.selectedCustomDate = dateString[1] + (dateString[2] ? ' - ' + dateString[2] : '');
        });

        //For changing the value inside of the date selection dropdown
        this.dashboardEmitterService.changeCurrentDashboardTabItem$.subscribe((tabVals) => {
            let date = this.getDateOption(tabVals[0]);
            this.selectedDate = date.key;
            if (!tabVals[1]) {
                this.selectedCustomDate = date.value;
            } else {
                this.selectedCustomDate = tabVals[1] + (tabVals[2] ? ' - ' + tabVals[2] : '');
            }
        });
    }

    navigate(state: string): void {
        if (state === 'inventoryCounter') {
            this.inventoryCounterService.logout();
        } else {
            this.router.navigate([state]);
        }
    }

    changedSearchText(): void {
        this.searchStrChanged.emit(this.searchStr);
    }

    expandItem(itemName: string, catName: string): void {
        this.itemStorageService.helpItemName = itemName;
        this.itemStorageService.helpCatName = catName;

        const dialogRef = this.dialog.open(HelpDialogComponent, {
            panelClass: 'app-full-bleed-dialog',
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    toggleTooltipAndAutoHide(toolTip: MatTooltip): void {
        let autoHideTimeout: NodeJS.Timeout;
        if (toolTip === this.optionTooltip) {
            autoHideTimeout = this.optionAutoHideTimeout;
        }
        //  continue condition here
        toolTip.toggle(); // Toggle the tooltip

        // Clear any existing auto-hide timeout
        if (autoHideTimeout) {
            clearTimeout(autoHideTimeout);
        }

        // If the tooltip is shown, auto-hide it after 3 seconds
        // _isTooltipVisible() return false meaning that the tooltip is shown and vice versa
        if (!toolTip._isTooltipVisible()) {
            if (toolTip === this.optionTooltip) {
                this.optionAutoHideTimeout = setTimeout(() => {
                    toolTip.hide();
                }, 3000); // 3000 milliseconds
            }
            // continue here if there are more tooltips
        }
    }

    // clear time out set by toggleTooltipAndAutoHide()
    // allow user to hover over tooltip without it disappearing
    hoverTooltipAndClearTimeOut(toolTip: MatTooltip): void {
        let autoHideTimeout: NodeJS.Timeout;
        if (toolTip === this.optionTooltip) {
            autoHideTimeout = this.optionAutoHideTimeout;
        }
        // continue condition here
        toolTip.show();
        if (autoHideTimeout) {
            clearTimeout(autoHideTimeout);
        }
    }

    manageTitleBarOptionsBasedOnPrivileges(): void {
        this.isAdmin = this.userAccess.userManagement === this.USER_ACCESS.ADMIN;
        if (
            this.userAccess.userPrivilege !== this.USER_PRIVILEGES.ALL_PRIVILEGES &&
            this.userAccess.userPrivilege !== null &&
            this.userAccess.role !== this.USER_ACCESS.STORE &&
            this.userAccess.role !== this.USER_ACCESS.TAG
        ) {
            let recycleBinAccess = false;
            let privileges = JSON.parse(this.userAccess.userPrivilege);
            for (let privilege of privileges) {
                if (
                    (this.userAccess.role === this.USER_ACCESS.GLOBAL &&
                        privilege.name === this.USER_PRIVILEGES.MANAGE_ORGANIZATIONS) ||
                    privilege.name === this.USER_PRIVILEGES.MANAGE_STORES
                )
                    recycleBinAccess = true;
                else if (
                    this.userAccess.role === this.USER_ACCESS.CONCEPT &&
                    privilege.name === this.USER_PRIVILEGES.MANAGE_STORES
                )
                    recycleBinAccess = true;
                else if (
                    this.userAccess.role === this.USER_ACCESS.GROUP_ITEM &&
                    privilege.name === this.USER_PRIVILEGES.MANAGE_STORES
                )
                    recycleBinAccess = true;
            }
            this.showRecycleBinOption = recycleBinAccess;
        } else if (this.userAccess.userPrivilege === null) {
            this.showRecycleBinOption = false;
        }
    }

    logout(): void {
        this.userInfoHandler.clearUser();
        this.userAccessHandler.clearUser();
        this.reportParamsHandler.clearParams();
        this.sharedWizardData.delete();
        let usersSortColumnItem = this.browserStorage.getSessionstorage('usersSortColumn', undefined);
        this.browserStorage.removeSessionstorageItem('timeRange');
        this.browserStorage.clearAllSessionStorage();
        if (usersSortColumnItem !== undefined) this.browserStorage.removeSessionstorageItem('usersSortColumn');
        this.router.navigate(['login']);
    }

    dropdownSelectedOption(): void {
        const newSelectedOption = this.getDateOption(this.selectedDate).value;
        if (this.selectedCustomDate != newSelectedOption) {
            this.selectedCustomDate = newSelectedOption;
            if (this.selectedDate == AppConstants.WIDGET_CUSTOM_DATE_DROPDOWN_OPTIONS.CUSTOM_DATE) {
                this.emitterService.pickADateSelection(this.selectedDate);
            } else if (this.selectedDate === AppConstants.WIDGET_CUSTOM_DATE_DROPDOWN_OPTIONS.CUSTOM_DATE_RANGE) {
                this.emitterService.dateRangeSelection(this.selectedDate);
            } else {
                this.emitterService.dropdownSelection(this.selectedDate);
            }
        }
    }

    getDateOption(date: string): CustomDateOption {
        return this.customDateList.find((option) => option.key === date);
    }

    getInterval(interval: number): RefreshIntervalTimes {
        return this.refreshIntervalTimes.find((time) => time.value === interval);
    }

    intervalSelected(interval: number): void {
        this.intervalChanged.emit(interval);
    }

    openS3Index(){
        window.open('https://pixelpointhqdocuments.s3.us-east-1.amazonaws.com/pixelpointhqDoc.html', '_blank');
    }
}
