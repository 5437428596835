export class WidgetConstants {
    public static readonly WIDGET_DESCRIPTION_URL = "http://store-portal.s3-website-us-west-2.amazonaws.com/";

    public static readonly DATE_FORMAL_COMPACT = 'yyyyMMdd';
    public static readonly DATE_FORMAT_SIMPLE = 'yyyy-MM-dd';
    public static readonly DATE_FORMAT_LONG = 'yyyy-MM-dd hh:mm a';

    public static readonly CHART_COLORS_DEFAULT: any = {
        backgroundColor: ['#ff829d', '#5eb5ef', '#ffd778', '#6fcdcd', '#ffb266', '#a4c2d2', '#ad85ff'],
        hoverBackgroundColor: ['#ff5b7e', '#3fa7eb', '#ffcb52', '#57c4c4', '#fea042', '#8ab1c5', '#925efe'],
    };

    public static readonly CHART_COLORS_GAUGE: any = {
        backgroundColor: ['#60d394', '#ffd778', '#f2675d', '#aa4344'],
    };
}