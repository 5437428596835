import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MobileViewService {
    public captions: any;
    public mobileViewActive: boolean;
    
    public static readonly MOBILE_BREAKPOINT_SMALL: number = 500;
    public static readonly MOBILE_BREAKPOINT_MEDIUM: number = 650;
    public static readonly MOBILE_BREAKPOINT_LARGE: number = 960;

    breakpoint: number;

    constructor() {
        this.breakpoint = MobileViewService.MOBILE_BREAKPOINT_LARGE;
        this.mobileViewActive = window.innerWidth <= this.breakpoint;

        window.onresize = () => {
            this.mobileViewActive = window.innerWidth <= this.breakpoint;
        };
    }

    setBreakpoint(width: number) {
        this.breakpoint = width;
        this.mobileViewActive = window.innerWidth <= this.breakpoint;
    }
}
