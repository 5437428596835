import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';

@Injectable()
export class ScheduleApiService {
    private BASE_URL;
    private scheduleUrl: string = 'schedule/';
    private allSchedulesUrl: string = 'schedule/all';
    private storeNamesUrl: string = 'store-names';

    constructor(
        @Inject(HttpClient) private http: HttpClient,
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async submitSchedule(schedule, id) {
        if (id) {
            schedule.id = id;
        }
        let url = this.BASE_URL + this.scheduleUrl;
        if (schedule.id) {
            const data$ = this.http.put(url, schedule,
                {
                    responseType: 'text'
                });
            const value = await lastValueFrom(data$);
            return value;
        } else {
            const data$ = this.http.post(url, schedule,
                {
                    responseType: 'text'
                }
            );
            const value = await lastValueFrom(data$);
            return value;
        }
    }

    async getStoreNames(schedule) {
        let url = this.BASE_URL + this.scheduleUrl + this.storeNamesUrl;
        const data$ = this.http.get(url, {
            params: {
                scheduleID: schedule.id,
                conceptID: schedule.conceptID
            }
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getAllSchedulesByConceptID(conceptID) {
        let url = this.BASE_URL + this.allSchedulesUrl;
        const data$ = this.http.get(url, {
            params: {
                conceptID: conceptID
            }
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getSchedule(scheduleID, conceptID) {
        let url = this.BASE_URL + this.scheduleUrl + scheduleID;
        const data$ = this.http.get(url, {
            params: {
                conceptID: conceptID
            }
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteSchedule(scheduleID, conceptId) {
        let url = this.BASE_URL + this.scheduleUrl + scheduleID;
        const data$ = this.http
            .delete(url, {
                responseType: 'text',
                params: {
                    conceptID: conceptId
                }
            });
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteAllSchedulesByConceptID(conceptId) {
        let url = this.BASE_URL + this.allSchedulesUrl;
        const data$ = this.http.delete(url, {
            params: {
                conceptID: conceptId
            }
        });
        const value = await lastValueFrom(data$);
        return value
    }
}