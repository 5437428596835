import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { UserApiService } from '../services/users.resource';

interface Parameters {
    currentUser: any;
    userInfoPredicate: any;
    columnAscOrDesc: any;
}

@Component({
    selector: 'confirm-delete-user-dialog',
    templateUrl: 'src/app/features/users/templates/confirm-delete-user-dialog.html',
})
export class ConfirmDeleteUserDialog {
    public captions: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(CaptionService) private captionService,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(BrowserStorage) private browserStorage,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfirmDeleteUserDialog>
    ) {
        this.captions = this.captionService.captions;
    }

    deleteUser() {
        this.userApiService.deleteUser(this.data.currentUser.id).then(() => {
            let userSortColumn = {
                predicate: this.data.userInfoPredicate,
                columnAscOrDesc: this.data.columnAscOrDesc,
            };
            this.browserStorage.setSessionstorage('usersSortColumn', userSortColumn);
            this.dialogRef.close(true);
        });
    }
}
