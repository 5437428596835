import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { ChartOptions } from 'chart.js';
import { ThemeService } from 'ng2-charts';
import SwiperCore, { Mousewheel, Navigation, Pagination, Scrollbar, SwiperOptions } from 'swiper';
import { AppSettings } from '../../../app-settings.service';
import { AppConstants } from '../../../app.constants';


SwiperCore.use([Mousewheel, Navigation, Pagination, Scrollbar]);

@Component({
    selector: 'headOfficePortal',
    templateUrl: 'src/app/features/head-office-portal/templates/head-office-portal.html'
})
export class HOPortalComponent implements OnInit {
    public captions: Record<string, string>;
    public THEMES = AppConstants.THEMES;
    public themeOptions: ChartOptions;
    EmpNum: any;
    hideProd: boolean;

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(AppSettings) private appSettings: AppSettings,
        @Inject(ThemeService) private themeService: ThemeService,
    ) {
    }

    toggleTheme() {
        this.appSettings.setTheme(
            this.appSettings.getTheme() === this.THEMES.dark ? this.THEMES.light : this.THEMES.dark
        );
        this.appSettings.applyTheme();
        this.updateChartColorScheme();
    }

    updateChartColorScheme() {
        this.themeOptions = {
            scales: {
                x: {
                    suggestedMin: 0,
                    grid: {
                        color: getComputedStyle(document.body).getPropertyValue('--tertiary'),
                    },
                    ticks: {
                        color: getComputedStyle(document.body).getPropertyValue('--text-color-primary'),
                    },
                },
                y: {
                    suggestedMin: 0,
                    grid: {
                        color: getComputedStyle(document.body).getPropertyValue('--tertiary'),
                    },
                    ticks: {
                        color: getComputedStyle(document.body).getPropertyValue('--text-color-primary'),
                    },
                },
            },
        };
        this.themeService.setColorschemesOptions(this.themeOptions);
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe(params => {
            this.EmpNum = params['empNum'];
        });

        // hide from PROD only -- currently nothing is hidden
        if (
            window.location.origin.indexOf('localhost') > -1 ||
            window.location.origin.indexOf('ci') > -1 ||
            window.location.origin.indexOf('qa') > -1
        ) {
            this.hideProd = true;
        }
    }
}
