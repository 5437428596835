export enum WidgetCustomDateOption {
    Default = 'default',
    CustomDate = 'customDate',
    CustomDateRange = 'customDateRange',
    CurrentDay = 'currentDay',
    PreviousDay = 'previousDay',
    CurrentWeek = 'currentWeek',
    PreviousWeek = 'previousWeek',
    CurrentMonth = 'currentMonth',
    PreviousMonth = 'previousMonth'
}

export enum WidgetType { 
    Wijmo = 1,
    Flat = 2,
}

