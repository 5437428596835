import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManageReportsData } from '../manage.reports.component';

@Component({
    selector: 'deleteAllJobsDialog',
    templateUrl: 'src/app/features/reports/templates/dialogs/delete-all-jobs.tpl.html',
})
export class DeleteAllJobsDialog {
    captions: any;
    conceptID: any;
    
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteAllJobsDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ManageReportsData,
    ) {
    }

    ngOnInit(): void {
        this.captions = this.data.captions;
        this.conceptID = this.data.conceptID;
    }

    deleteAllJobs() {
        this.dialogRef.close(true);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog() {
        this.dialogRef.close();
    }
}