import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { ItemStorageService } from '../../../../utils/shared-services/item-storage.service';
import { DashboardEmitterService } from '../../services/dashboard-emitter.service';

@Component({
    selector: 'configureDateSelection',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/date-selection.tpl.html',
})
export class ConfigureDateSelectionDialog {
    private formBuilder: FormBuilder = new FormBuilder();

    public form = this.formBuilder.group({
        picker: [new Date(), [Validators.required]]
    });

    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfigureDateSelectionDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(DashboardEmitterService) private dashboardEmitterService: DashboardEmitterService
    ) {
        this.captions = this.captionService.captions;

        const dateValue = this.itemStorageService.currentTab.pickADateValue;

        if (dateValue) {
            this.form.setValue({
                picker: dateValue
            });
        }
    }

    ngOnInit(): void {
        this.form.controls.picker.markAsTouched();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    submitDate(): void {
        let selectedDate = new Date(this.form.get("picker").value);
        this.itemStorageService.currentTab.pickADateValue = selectedDate;
        this.dashboardEmitterService.dateSelected(selectedDate);
        this.dialogRef.close(true);
    }
}
