import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'contactUs',
    templateUrl: 'src/app/auth/templates/contact-us.html',
})
export class ContactUsComponent {
    public captions;

    constructor(@Inject(ActivatedRoute) private actRoute: ActivatedRoute) {}

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
    }
}
