import { StorePortalApiService } from './../../services/store-portal.resource';
import { AfterViewInit, Component, Inject, NgZone, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';

// update this interface to match the data you want to pass from parent component
export interface SelectedTableData {
    selectedTransactNumber: any;
}

// interface based on table record
export interface TableDetailData {
    DESCRIPT: string;
    POSNAME: string;
    TIMEORD: string;
    QUAN: number;
    VALUE: number;
    PRODTYPE: number;
    TRANSACT: number;
    RECPOS: number;
}

@Component({
    selector: 'tableDetailDialog',
    templateUrl: 'src/app/features/store-portal/templates/dialogs/table-detail-dialog.html',
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ display: 'none' })),
            state('expanded', style({ height: '*', padding: '0.2rem 0.5rem' })),
        ]),
    ],
})
export class TableDetailDialog {
    captions: any;
    transact: number;
    data: any;
    datapoints: string[] = ['Transaction1A', 'Transaction2A', 'Transaction3A'];
    storeID: string = '';
    itemList: any[] = [];
    total: any;
    paymentLog: any[] = [];
    // specify which columns to display, each one will be use in matColumnDef
    columnsToDisplay = ['quantity', 'name', 'price', 'expand'];
    // to switch from loading to table
    dataLoaded: boolean = false;
    // used for displaying data in table
    dataSource: MatTableDataSource<TableDetailData> = new MatTableDataSource<TableDetailData>([]);

    expandedElement: TableDetailData | null;

    // get access to paginator
    @ViewChild(MatPaginator)
    set paginator(value: MatPaginator) {
        // set paginator to dataSource to perform pagination
        // Idk how this one works but the one specified in angular material doesn't
        // Check this link for more info: https://github.com/angular/components/issues/11692
        this.dataSource.paginator = value;
    }
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<TableDetailDialog>,
        // Get data passed from parent component
        @Inject(MAT_DIALOG_DATA) private passingData: SelectedTableData,
        @Inject(CaptionService) private captionService,
        @Inject(StorePortalApiService) private storePortalApiService: StorePortalApiService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(NgZone) private ngZone: NgZone
    ) {
        this.captions = this.captionService.captions;
        this.transact = this.passingData.selectedTransactNumber;
        this.storeID = this.browserStorage.getSessionstorage('portalData', null).StoreID;
    }

    async ngOnInit() {
        const params = JSON.stringify({ transact: this.transact });
        try {
            // fetching all data based on datapoints
            this.data = await this.storePortalApiService.getDatasetsByDatapoints(
                this.storeID,
                params,
                this.datapoints.toString()
            );
            this.dataLoaded = true;
            this.handleRetrievedDataCalledFromAPI();
            // reload the component after fetching all data
            this.ngZone.run(() => {});
        } catch (err) {
            // for now, delete later
            console.log(err);
        }
    }

    handleRetrievedDataCalledFromAPI() {
        // Transaction1A could be an object or an array of object or undefined
        if (this.data.DataSet[0].Record instanceof Array) {
            this.itemList = this.data.DataSet[0].Record;
        } else if (this.data.DataSet[0].Record instanceof Object) {
            this.itemList.push(this.data.DataSet[0].Record);
        }
        this.total = this.data.DataSet[1].Record;
        // Transaction3A could be an object or an array of object or undefined
        if (this.data.DataSet[2].Record instanceof Array) {
            this.paymentLog = this.data.DataSet[2].Record;
        } else if (this.data.DataSet[2].Record instanceof Object) {
            this.paymentLog.push(this.data.DataSet[2].Record);
        }
        this.dataSource = new MatTableDataSource<TableDetailData>(this.itemList);
    }

    convertProductTypeIDToName(id: number) : string {
        switch (id) {
            case 0:
                return this.captions.orderingProduct;
            case 1:
                return this.captions.option;
            case 2:
                return this.captions.kitchenCommand;
            case 3:
                return this.captions.bussingCommand;
            case 4:
                return this.captions.seatingPosition;
            case 5:
                return this.captions.ratedPerHourProduct;
            case 6:
                return this.captions.delayPrintCommand;
            case 7:
                return this.captions.merchandise;
            case 8:
                return this.captions.minimumCharge;
            case 9:
                return this.captions.ticketNumber;
            case 10:
                return this.captions.coverCharge;
            case 11:
                return this.captions.recipeItem;
            case 12:
                return this.captions.manualKeyboard;
            case 14:
            case 101:
                return this.captions.refund;
            case 100:
                return this.captions.manageCoupon;
            default:
                return 'Unknown';
        }
    }
}
