import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { EoiApiService } from '../services/eoi.resource';

@Component({
    selector: 'unsubscribeEoiAlert',
    templateUrl: 'src/app/features/eoi/templates/eoi-unsubscribe.tpl.html',
})
export class EoiUnsubscribeComponent {
    public captions: Record<string,string>;
    public unsubscribed: boolean;
    public subIDHash: string;
    public emailHash: string;

    constructor(
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(EoiApiService) private eoiApiService: EoiApiService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
    ) {}

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.subIDHash = params['hash1'];
            this.emailHash = params['hash2'];
        });
    }

    unsubscribe(): void {
        this.eoiApiService.deactivateSubscriptionFromEmail(this.subIDHash, this.emailHash).then(
            () => this.confirmUnsub(),
            () => this.error()
        );
    }

    confirmUnsub(): void {
        this.unsubscribed = true;
    }

    error(): void {
        this.snackbarService.errorMessageTop(this.captions.alreadyUnsubscribed);
    }

    navigate(state): void {
        this.router.navigate([state]);
    }

}
