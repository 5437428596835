import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { SnackbarService } from "../../../../utils/shared-services/snackbar/snackbar.service";
import { DashboardTabGetWithContent, DashboardTabPost } from "../../models/dashboard.model";
import { DashboardEmitterService } from "../../services/dashboard-emitter.service";
import { DashboardApiService } from "../../services/dashboard.resource";
import { ValidationService } from "../../../../utils/shared-services/validation.service";

@Component({
    selector: 'addTab',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/add-tab.tpl.html',
})
export class AddTabDialog {
    public captions: Record<string, string>;
    public tabToAdd: DashboardTabPost = {
        tabName: ''
    }

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<AddTabDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(DashboardEmitterService) private dashboardEmitterService: DashboardEmitterService,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.captionService.captions;
    }

    submitTab(): void {
        this.dashboardApiService.addTab(this.tabToAdd).then((resultTab: DashboardTabGetWithContent) => {
            if (typeof resultTab == 'undefined') {
                this.snackbarService.errorMessageBottom(this.captions.maxTabs);
            } 
            else {
                this.dashboardEmitterService.tabAdded(resultTab);
            }
        }, 
        (err) => {
            this.snackbarService.errorMessageBottom(err?.error?.reason);
        });
        this.cancel();
    }

    Space(event: KeyboardEvent): void {
        if ((event.target as HTMLInputElement).selectionStart === 0 && event.key === ' ') {
            event.preventDefault();
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }
}
