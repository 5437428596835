import { Component, EventEmitter, Inject, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from "@angular/material/datepicker";
import { SevenDayRangeSelectionStrategy } from "./date-range-selection-strategy.service";
import { CaptionService } from "../shared-services/caption.service";

@Component({
  selector: 'seven-day-date-picker',
  templateUrl: 'src/app/utils/date-picker/seven-day-date-picker.html',
  providers: [{
    provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
    useClass: SevenDayRangeSelectionStrategy
  }]
})

export class SevenDayDatePickerComponent {
  minDate: Date;
  maxDate: Date;
  captions: Record<string,string>;

  @Output() callParent: EventEmitter<string> = new EventEmitter<string>();
  
  range: UntypedFormGroup;
  formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

  constructor(@Inject(CaptionService) public captionService: CaptionService
) {
    const currentYear = new Date().getFullYear();
    const today = new Date();
    this.range = this.formBuilder.group({
      startDate: [new Date(), [Validators.required]],
      endDate: [today.getDate() - 7, [Validators.required]]
    });
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date(currentYear + 1, 11, 31);
    this.captions = this.captionService.captions;
  }

  triggerParentFunction() {
    this.callParent.emit();
  } 

}