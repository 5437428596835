import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { View } from '../../../store-portal/models/view.model';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { ConfirmDeleteViewDialogData } from '../../models/dialog.model';

@Component({
    selector: 'confirm-delete-view-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/confirm-delete-view-dialog.html',
})
export class ConfirmDeleteViewDialog {

    public captions: Record<string, string>;
    public viewInfo: Partial<View>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) private data: ConfirmDeleteViewDialogData,
    ) {
        this.captions = this.captionService.captions;
        this.viewInfo = this.data.view;
    }
}
