import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccessHandler } from '../../features/users/services/user-access-handler.service';
import { UserInfoHandler } from '../../features/users/services/user-info-handler.service';
import { SnackbarService } from '../../utils/shared-services/snackbar/snackbar.service';
import { AuthService } from '../services/auth.resource';
import { ValidationService } from '../../utils/shared-services/validation.service';

@Component({
    selector: 'newPassword',
    templateUrl: 'src/app/auth/templates/new-pass.html',
})
export class NewPasswordComponent {
    public captions;
    public passwordChangeObj;
    public hidePassword: boolean = true;

    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    constructor(
        @Inject(AuthService) private authService,
        @Inject(UserInfoHandler) private userInfoHandler,
        @Inject(UserAccessHandler) private userAccessHandler,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.passwordChangeObj = {
            newPassword: '',
            confirmNewPassword: '',
        };
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.form = this.formBuilder.group({
                password: [this.passwordChangeObj.newPassword, [
                    Validators.required,
                    Validators.pattern(this.validationService.getPasswordRegex()),
                    Validators.minLength(this.validationService.minPasswordLength),
                    Validators.maxLength(this.validationService.maxPasswordLength),
                ]],
                confirmedPassword: [
                    this.passwordChangeObj.confirmNewPassword,
                    [Validators.required, Validators.pattern(this.validationService.getConfirmPasswordRegex())],
                ],
            },
            { validators: this.matched('password', 'confirmedPassword') }
        );
    }

    // Validation function to validate if passwords are matched
    private matched(password: string, confirmedPassword: string) {
        return (formGroup: UntypedFormGroup) => {
            const _password = formGroup.controls[password];
            const _confirmedPassword = formGroup.controls[confirmedPassword];
            if (_confirmedPassword.errors && !_confirmedPassword.errors.matched) return;
            if (_password.value !== _confirmedPassword.value) _confirmedPassword.setErrors({ matched: true });
            else _confirmedPassword.setErrors(null);
        };
    }

    changeNewPassword() {
        if (this.form.status !== 'INVALID') {
            this.passwordChangeObj.newPassword = this.form.value.password;
            this.passwordChangeObj.confirmNewPassword = this.form.value.confirmedPassword;
            this.authService.changePasswordFromRecovery(this.passwordChangeObj.newPassword).then(() => {
                this.userInfoHandler.clearUser();
                this.userAccessHandler.clearUser();
                this.router.navigate(['login']).then(() => {
                    this.snackbarService.successMessageTop(this.captions.successfulPassReset);
                });
            });
        }
    }

    cancelChangePassword() {
        this.userInfoHandler.clearUser();
        this.userAccessHandler.clearUser();
        this.router.navigate(['login/passwordRecovery']);
    }

    togglePasswordVisibility(): void {
        this.hidePassword = !this.hidePassword;
    }

    onConfirmPasswordChange() {
        this.form.controls.confirmedPassword.markAsTouched();
    }
}