import { Inject, Injectable } from '@angular/core';
import { CaptionService } from '../../../utils/shared-services/caption.service';

@Injectable()
export class WijmoChartTypeService {
    private WIJMO_FLEXCHART_CAPTIONS: { description: string, key: string }[] = [];

    public captions: Record<string, string>;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService
    ) {
        this.captions = this.captionService.captions;
        this.WIJMO_FLEXCHART_CAPTIONS = [
            { description: this.captions.chartTypeColumn, key: '0' },
            { description: this.captions.chartTypeBar, key: '1' },
            { description: this.captions.chartTypeScatter, key: '2' },
            { description: this.captions.chartTypeLine, key: '3' },
            { description: this.captions.chartTypeLineSymbols, key: '4' },
            { description: this.captions.chartTypeArea, key: '5' },
            { description: this.captions.chartTypeSpline, key: '6' },
            { description: this.captions.chartTypeSplineSymbols, key: '7' },
            { description: this.captions.chartTypeSplineArea, key: '8' },
        ];
    }

    getWijmoChartTypesCaption() {
        return this.WIJMO_FLEXCHART_CAPTIONS;
    }
}
