import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CaptionService } from '../../shared-services/caption.service';
import { ValidationService } from '../../shared-services/validation.service';

@Component({
    selector: 'create-tag-selected-stores-dialog',
    templateUrl: 'src/app/utils/shared-templates/user-access/create-tag-selected-stores-dialog.html',
})
export class CreateTagSelectedStoresDialog {
    public captions: any;
    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    constructor(
        @Inject(CaptionService) private captionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<CreateTagSelectedStoresDialog>,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.captionService.captions;
        this.form = this.formBuilder.group({
            displayName: ['', [Validators.required, Validators.maxLength(this.validationService.storeTagNameMaxLength)]],
        })
    }

    createTagForSelectedStores(tag) {
        this.dialogRef.close({ data: { tag: tag } })
    }

    cancelTagCreation() {
        this.dialogRef.close();
    }
}
