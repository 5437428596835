import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { Product, ProductGroup } from "../../models/price-change.model";
import { StoreApiService } from "../../../stores/services/store.resource";
import { StoreBaseWithAddress } from "src/app/features/stores/models/store.model";
import { PriceHistoryDialog } from "./price-history-dialog.component";
import { PriceListDialog } from "./price-list-dialog.component";
import { PriceChangeService } from "../../services/price-change.resource";

@Component({
    selector: "row-comp-dialog",
    templateUrl: "./src/app/features/price-change/templates/dialogs/row-comp-dialog.component.html",
})
export class PriceChangeRowCompDialog implements OnInit {
    private products: Product[]

    public captions: Record<string, string>
    public groupName: string;
    public compositionByStore: {
        id: number;
        displayName: string;
        products: Product[]
    }[];
    public compositionByProduct: {
        id: number;
        name: string;
        stores: StoreBaseWithAddress[];
    }[];

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<PriceChangeRowCompDialog>,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: ProductGroup,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(PriceChangeService) private priceChangeService: PriceChangeService,

    ) {
        this.captions = this.captionService.captions;
        this.groupName = this.data.Descript || this.data.PLink || null;
        this.products = this.data.products;
        this.compositionByStore = [];
        this.compositionByProduct = [];
    }

    async ngOnInit(): Promise<void> {
        // used for compositionByProduct
        let stores: StoreBaseWithAddress[] = [];

        for (let product of this.products) {
            // Develop composition by store
            let storeComp = this.compositionByStore.find(s => s.id === product.StoreID);
            if (storeComp) {
                storeComp.products.push(product);
            }
            else {
                let store = await this.storeApiService.getStoreById(product.StoreID);
                stores.push(store);
                this.compositionByStore.push({
                    id: product.StoreID,
                    displayName: store.displayName,
                    products: [product]
                });
            }

            // Develop composition by product
            let productComp = this.compositionByProduct.find(p => p.name === product.Descript);
            if (productComp) {
                let storeInProductComp = productComp.stores.find(store => store.id === product.StoreID);
                if (!storeInProductComp) {
                    let store = stores.find(store => store.id === product.StoreID);
                    productComp.stores.push(store);
                }
            }
            else {
                let store = stores.find(store => store.id === product.StoreID);
                this.compositionByProduct.push({
                    id: product.ProdNum,
                    name: product.Descript,
                    stores: [store]
                })
            }
        }
    }
    openPriceHistoryDialog(storeID, prodNum): void {
        this.priceChangeService.getProductHistory(storeID, prodNum).then((history) => {
            const dialogRef = this.dialog.open(PriceHistoryDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '95vw',
                minWidth: '25%',
                data: {
                    historyData: history,
                },
            });
        })
    }

    openPriceListDialog(storeName, prodNum): void {
        let productPrice = {};
        for (let product of this.data.products) {
            if (product.ProdNum == prodNum) productPrice = product;
        }
        const dialogRef = this.dialog.open(PriceListDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            minWidth: '25%',
            data: {
                priceList: productPrice,
                storeName : storeName
            },
        });
    }
}