import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { SelectedDateData } from "../../models/store-portal.model";

@Component({
    selector: 'dateSelectionDialog',
    templateUrl: 'src/app/features/store-portal/templates/dialogs/date-selection-dialog.html',
})
export class DateSelectionDialog {
    public captions: Record<string, string>;
    public selectedDate: string;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DateSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) private data: SelectedDateData,
        @Inject(CaptionService) private captionService,
    ) {
        this.captions = this.captionService.captions;
        this.selectedDate = this.data.selectedDate;
    }

    ngOnInit(): void {
    }
}
