import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { MobileViewService } from '../../../utils/shared-services/mobileView/mobileView.service';
import { ApiConsumerService } from '../services/api-consumer.resource';
import { ApiPackage, ApiPackageService } from '../services/api-package.resource';
import { Option, Vendor, VendorApiService } from '../services/vendor.resource';
import { ConfirmDeleteTokenDialog } from './remove-token-dialog.component';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'manageApis',
    templateUrl: 'src/app/features/vendor-apis/templates/manage-apis.html',
})
export class ManageApisComponent {
    public captions;
    public options: Option[];
    public currentTabIndex: number;
    public conceptName: string;
    public storeName: string;
    public vendors: Vendor[][];
    public tokenHint: string;
    public vendorToDelete: Vendor;
    public apiPackages: ApiPackage[];
    public passedConceptId: number;
    public passedStoreId: number;
    public disableSaving: boolean = false;
    public editTokenBackup: string;

    constructor(
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(Clipboard) private clipBoard: Clipboard,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(VendorApiService) private vendorApiService: VendorApiService,
        @Inject(ApiPackageService) private apiPackageService: ApiPackageService,
        @Inject(ApiConsumerService) private apiConsumerService: ApiConsumerService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(MobileViewService) public mobileViewService: MobileViewService,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.currentTabIndex = 0;
        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_MEDIUM);
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.passedConceptId = +params['pathId'];
            this.passedStoreId = +params['storeID'];
            if (this.passedStoreId) {
                this.conceptName = params['pathName'];
                this.storeName = params['displayName'];
            }
            else {
                this.conceptName = params['displayName'];
            }
        });
        this.options = [
            { id: '1', name: this.captions.optionAccounting },
            { id: '2', name: this.captions.optionInventory },
            { id: '3', name: this.captions.optionSales },
            { id: '4', name: this.captions.optionTimeManagement },
        ];

        this.editTokenBackup = '';

        this.getVendors();
    }

    getVendors() {
        let apiType = this.passedStoreId ? 'store' : 'concept';
        this.apiPackageService.getApiPackages(apiType).then((apis) => {
            this.apiPackages = apis;
            this.getVendorsBasedOnControlPoint().then((vendors) => {
                this.vendors = vendors;
                this.setVendorTokens(this.vendors);
            });
        });
    }

    getVendorsBasedOnControlPoint(): Promise<Vendor[][]> {
        let vendorArray = [];
        this.apiPackages.map((pack, index) => {
            this.passedStoreId
                ? vendorArray.push(this.vendorApiService.getVendorsByStoreIdPackageId(this.passedStoreId, pack.id))
                : vendorArray.push(this.vendorApiService.getVendorsByConceptIdPackageId(this.passedConceptId, pack.id));
        });
        let arrayPromise = Promise.all(vendorArray).then((promiseObject) => promiseObject);
        return arrayPromise;
    }

    changeCurrentTabIndex(tabId: number) {
        if (typeof this.vendors !== 'undefined') {
            for (const { index, pack } of this.apiPackages.map((pack, index) => ({ index, pack }))) {
                if (pack.id === this.apiPackages[tabId].id) {
                    this.currentTabIndex = index;
                    this.setVendorTokens(this.vendors);
                }
            }
        }
        this.disableSaving = false;
    }

    setVendorTokens(vendors: Vendor[][]) {
        if (vendors[this.currentTabIndex].length > 0) {
            for (let vendor of vendors[this.currentTabIndex]) {
                vendor.hasToken = typeof vendor.readToken !== 'undefined';
                vendor.isGeneric = vendor.apiPackageID == 16000006 || vendor.apiPackageID == 16000005;
                if (vendor.isGeneric) {
                    vendor.genericOption = vendor.subFamily != null ? vendor.subFamily.split('') : [];
                }
                vendor.isActive = vendor.isTokenActive == 1;
            }
        }
    }

    selectAll(vendor: Vendor) {
        vendor.genericOption = [];
        for (let option of this.options) {
            vendor.genericOption.push(option.id);
        }
    }

    saveChanges() {
        let vendorType = this.passedStoreId ? 'store' : 'concept';
        for (let vendor of this.vendors[this.currentTabIndex]) {
            if (!vendor.hasToken) {
                vendor.readToken = null;
            }
            vendor.isTokenActive = vendor.isActive ? 1 : 0;

            if (vendor.isGeneric) {
                vendor.subFamily = vendor.genericOption.length > 0 ? vendor.genericOption.join('') : null;
            }
            if (vendor.apiPackageID == 16000009 && vendor.isTokenActive) {
                vendor.readToken = vendor.readToken ? vendor.readToken : '';
            }
        }
        this.vendorApiService.updateVendor(this.vendors[this.currentTabIndex], vendorType);
    }

    goToPreviousPage() {
        if (this.passedStoreId && this.passedConceptId) {
            this.router.navigate(['stores/allStores', this.passedConceptId, this.conceptName]);
        }
        else if (!this.passedStoreId && this.passedConceptId) {
            this.router.navigate(['concepts/allConcepts']);
        }
        else {
            this.router.navigate(['home']);
        }
    }

    tokenEnabled(vendor: Vendor) {
        vendor.isActive = !vendor.isActive;
        vendor.enabled = vendor.isActive ? !(vendor.disabled = false) : !(vendor.disabled = true);
        this.disableSaving = false;

        //when toggling off a vendor that has no token, set hasToken to false
        if (!vendor.readToken) {
            vendor.readToken = undefined;
            vendor.hasToken = false;
        }
    }

    copyToken(value: string) {
        this.clipBoard.copy(value);
        this.snackbarService.successMessageBottom('Copied');
    }

    createToken() {
        let validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F'];
        let tokenHex = [];
        for (let i = 0; i < 14; i++) {
            let randomNum = Math.ceil(Math.random() * 15);
            tokenHex.push(validChars[randomNum]);
        }
        return tokenHex.join('');
    }

    tokenChanged(vendor: Vendor) {
        if (!vendor.hasToken) {
            if (typeof vendor.readToken === 'undefined' || 'object') {
                this.apiConsumerService.getApiConsumerById(vendor.apiConsumerID).then((result: any) => {
                    this.tokenHint = result.tokenHint;
                    if (vendor.apiPackageID == 16000009) {
                        vendor.currentEdit = true;
                        vendor.hasToken = true;
                    } else {
                        vendor.readToken = this.tokenHint.toUpperCase() + this.createToken();
                        vendor.hasToken = true;
                    }
                }).catch(() => {
                    if (vendor.apiPackageID != 16000009) {
                        vendor.readToken = this.createToken();
                    }
                });
                vendor.isActive = true;
            }
        }

        //if vendor has token, delete it
        else {
            vendor.readToken = undefined;
            vendor.hasToken = vendor.isActive = false;
        }
    }
    editVendorToken(vendor: Vendor) {
        vendor.currentEdit = true;
        this.editTokenBackup = vendor.readToken;
    }

    revertEdit(vendor: Vendor) {
        vendor.currentEdit = false;
        vendor.readToken = this.editTokenBackup;
    }

    applyToken(vendor: Vendor) {
        vendor.currentEdit = false;
        vendor.readToken = vendor.readToken;
    }

    confirmDeleteToken(vendor: Vendor) {
        this.vendorToDelete = vendor;
        const dialogRef = this.dialog.open(ConfirmDeleteTokenDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                tokenHint: this.tokenHint,
                vendorToDelete: this.vendorToDelete,
                tokenChanged: this.tokenChanged,
            },
        });
        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
        dialogRef.afterClosed().subscribe(() => {
            this.disableSaving = false;
        })
    }

    navigateToExtractDataStore() {
        this.router.navigate(['stores/extractData', this.passedConceptId, this.passedStoreId, this.storeName]);
    }

    navigateToExtractDataConcept() {
        this.router.navigate(['concepts/extractData', this.passedConceptId]);
    }
}
