import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { EoiInformation, EOIQuickStatusResponse } from '../models/eoi.model';

@Injectable()
export class EoiApiService {
    private eoiByIdUrl: string = 'eoi/{storeID}';
    private lazyApiUrl: string = 'eoi/{apiConsumerID}';
    private quickStatusUrl: string = 'eoi/{storeID}/{virtualVendor}';
    private orderRequestUrl: string = 'eoi/{storeID}/id';
    private gatewayReportTreeUrl: string = "gateway/reportTree"
    private BASE_URL: string;

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getEoiByID(storeID: number, timeRange: number, reqType: number, vendorID: number, dateSelected: string): Promise<EoiInformation> {
        let eoiByIdUrlValid = this.eoiByIdUrl.replace('{storeID}', storeID.toString());
        const data$ = this.http.get<EoiInformation>(this.BASE_URL + eoiByIdUrlValid, {
            params: {
                timeRange: timeRange,
                reqType: reqType,
                vendorID: vendorID,
                dateSelected: dateSelected
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getSubscriptionByID(storeID) {
        let eoiByIdUrlValid = this.eoiByIdUrl.replace('{storeID}', 'subscriptions/' + storeID);
        const data$ = this.http.get(this.BASE_URL + eoiByIdUrlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateSubscriptionInfo(subscription) {
        let validSub = this.dataUtils.getCleanedObjectFields(subscription, [
            'email',
            'emailSubject',
            'subscriptionType',
            'delayInterval',
        ]);
        let eoiByIdUrlValid = this.eoiByIdUrl.replace('{storeID}', 'subscriptions/' + subscription.id);
        let url = this.BASE_URL + eoiByIdUrlValid;
        const data$ = this.http.put(url, validSub);
        const value = await lastValueFrom(data$);
        return value;
    }

    getValidSubscriptionForApi(subscription) {
        let validSubscriptionFields = ['storeID', 'email', 'emailSubject', 'subscriptionType', 'delayInterval'];
        let validUpdatedSubscription = {};

        for (let prop in subscription) {
            if (subscription.hasOwnProperty(prop) && validSubscriptionFields.indexOf(prop) >= 0) {
                if (
                    typeof subscription[prop] == 'boolean' ||
                    typeof subscription[prop] == 'number' ||
                    typeof subscription[prop] == 'string' ||
                    typeof subscription[prop] == 'object' ||
                    subscription[prop] === null
                ) {
                    validUpdatedSubscription[prop] = subscription[prop];
                }
            }
        }
        return validUpdatedSubscription;
    }

    async addSubscription(subscription, storeID) {
        subscription.storeID = storeID;
        let validStore = this.getValidSubscriptionForApi(subscription);
        let eoiByIdUrlValid = this.eoiByIdUrl.replace('{storeID}', 'subscriptions');
        const data$ = this.http.post(this.BASE_URL + eoiByIdUrlValid, validStore);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deactivateSubscription(subID) {
        if (typeof subID !== 'number') return Promise.reject('Invalid Request');
        let eoiByIdUrlValid = this.eoiByIdUrl.replace('{storeID}', 'subscriptions/' + subID);
        const data$ = this.http.delete(this.BASE_URL + eoiByIdUrlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deactivateSubscriptionFromEmail(subIDHash, emailHash) {
        let eoiByIdUrlValid = this.eoiByIdUrl.replace('{storeID}', 'unsubscribe/' + subIDHash + '/' + emailHash);
        const data$ = this.http.delete(this.BASE_URL + eoiByIdUrlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getLazyApiResponseByXMLString(apiConsumerID, readToken, storeID) {
        let lazyApiUrlValid = this.lazyApiUrl.replace('{apiConsumerID}', apiConsumerID);
        const data$ = this.http.post(this.BASE_URL + lazyApiUrlValid, null, {
            params: { readToken: readToken, storeID: storeID },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getReportViewerInfo(storeID, empNum) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/xml',
            'Accept': 'application/xml' // Ensure the server responds with XML
        });

        const options = {
            headers: headers,
            params: { storeID: storeID, empNum: empNum },
            responseType: 'text' as 'json' // This is necessary to avoid parsing error
        };
        const data$ = this.http.post(this.BASE_URL + this.gatewayReportTreeUrl, null, options);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getQuickStatus(storeID: number, virtualVendor: number): Promise<EOIQuickStatusResponse> {
        let quickStatusUrlValid = this.quickStatusUrl
            .replace('{storeID}', storeID.toString())
            .replace('{virtualVendor}', virtualVendor.toString());
        const data$ = this.http.get<EOIQuickStatusResponse>(this.BASE_URL + quickStatusUrlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getOrderRequest(storeID: number, eoiQueueId: number): Promise<EOIQuickStatusResponse> {
        let orderRequestUrlValid = this.orderRequestUrl
            .replace('{storeID}', storeID.toString())
        const data$ = this.http.get<EOIQuickStatusResponse>(this.BASE_URL + orderRequestUrlValid, {
            params: { eoiQueueID: eoiQueueId }
        });
        const value = await lastValueFrom(data$);
        return value;
    }
}
