import { Subject } from 'rxjs';
import { WidgetCustomDateOption } from '../enums/widget.enum';
import { DashboardTabGetWithContent } from '../models/dashboard.model';
import { WidgetInstaceGet, WidgetInstance, WidgetTemplate } from '../models/widget.model';

export class DashboardEmitterService {

    // add tab component
    private addedTab = new Subject<DashboardTabGetWithContent>();
    tabAddedItem$ = this.addedTab.asObservable();

    // configure date range selection component
    private selectedDates = new Subject<[Date, Date]>();
    selectedDateRangeItem$ = this.selectedDates.asObservable();

    //configure date selection component 
    private selectedDate = new Subject<Date>();
    dateSelectedItem$ = this.selectedDate.asObservable();

    //main dashboard component 
    private dateToTitlebar = new Subject<[WidgetCustomDateOption, string, string]>();
    sendFormattedDateToTitlebarItem$ = this.dateToTitlebar.asObservable();

    private revertToDropdownOption = new Subject();
    revertToPreviousSelectedDropdownOptionItem$ = this.revertToDropdownOption.asObservable();

    private conceptChanged = new Subject();
    dashboardConceptChangedItem$ = this.conceptChanged.asObservable();

    private storesSelected = new Subject();
    dashboardStoresSelectedItem$ = this.storesSelected.asObservable();

    private conceptLoaded = new Subject();
    dashboardConceptLoadedItem$ = this.conceptLoaded.asObservable();

    private currentDashboardTab = new Subject<[WidgetCustomDateOption, string, string]>();
    changeCurrentDashboardTabItem$ = this.currentDashboardTab.asObservable();

    //configure widget component
    private widgetTemplate = new Subject<WidgetInstance | WidgetTemplate>();
    widgetInfoFetchedItem$ = this.widgetTemplate.asObservable();

    private subWizEvent = new Subject();
    submitWizardItem$ = this.subWizEvent.asObservable();

    private updatedWidget = new Subject();
    widgetUpdatedItem$ = this.updatedWidget.asObservable();

    private addedWidget = new Subject<WidgetInstaceGet>();
    widgetAddedItem$ = this.addedWidget.asObservable();

    // dashboard concept picker directive 
    private conceptLoadedCtrl = new Subject();
    dashboardConceptLoadedCtrlItem$ = this.conceptLoadedCtrl.asObservable();

    private pickerPopupModel = new Subject();
    openConceptPickerPopupItem$ = this.pickerPopupModel.asObservable();

    //dashboard store picker directive
    private storeLength = new Subject();
    hideAllStoresBoxItem$ = this.storeLength.asObservable();

    private storePickerPopup = new Subject();
    openStorePickerPopupItem$ = this.storePickerPopup.asObservable();

    // widget wizard params directive 
    private conceptNameChange = new Subject();
    changeConceptNameItem$ = this.conceptNameChange.asObservable();

    // add tab component
    tabAdded(tab: DashboardTabGetWithContent): void {
        this.addedTab.next(tab);
    }

    //configure date range selection component 
    selectedDateRange(startDate: Date, endDate: Date): void {
        this.selectedDates.next([startDate, endDate]);
    }

    //configure date selection component 
    dateSelected(selectedDateIn: Date): void {
        this.selectedDate.next(selectedDateIn);
    }

    // main dashboard component 
    sendFormattedDateToTitlebar(selectedDateVal: WidgetCustomDateOption, dateValueOne: string, dateValueTwo: string): void {
        this.dateToTitlebar.next([selectedDateVal, dateValueOne, dateValueTwo]);
    }

    revertToPreviousSelectedDropdownOption(oldValueForDropdown, dateOne, dateTwo) {
        this.revertToDropdownOption.next([oldValueForDropdown, dateOne, dateTwo]);
    }

    dashboardConceptChanged(concept) {
        this.conceptChanged.next(concept);
    }

    dashboardStoresSelected(storesSelected) {
        this.storesSelected.next(storesSelected);
    }

    dashboardConceptLoaded(concept) {
        this.conceptLoaded.next(concept);
    }

    changeCurrentDashboardTab(selectedDateVal: WidgetCustomDateOption, dateOne: string, dateTwo: string): void {
        this.currentDashboardTab.next([selectedDateVal, dateOne, dateTwo]);
    }

    //configure widget component
    widgetInfoFetched(widgetTemplate: WidgetInstance | WidgetTemplate): void {
        this.widgetTemplate.next(widgetTemplate);
    }

    submitWizard(): void {
        this.subWizEvent.next(null);
        this.subWizEvent.observers = [];
    }

    widgetUpdated(widget) {
        this.updatedWidget.next(widget);
    }

    widgetAdded(widget: WidgetInstaceGet): void {
        this.addedWidget.next(widget);
    }

    // dashboard concept picker directive
    dashboardConceptLoadedCtrl(concept) {
        this.conceptLoadedCtrl.next(concept);
    }

    openConceptPickerPopup(pickerpopup) {
        this.pickerPopupModel.next(pickerpopup);
    }

    //dashboard store picker directive
    hideAllStoresBox(storeLength) {
        this.storeLength.next(storeLength);
    }

    openStorePickerPopup(stores, pickerpopup) {
        this.storePickerPopup.next([stores, pickerpopup]);
    }

    // widget wizard params directive 
    changeConceptName(concept) {
        this.conceptNameChange.next(concept);
    }

}
