export class ItemStorageService {
    public selectedConcept: any = {};
    public selectedStore: any = {};
    public selectedCoupon: any = {};
    public couponDisplayName: any;
    public helpItemName: any = {};
    public helpCatName: any = {};
    public currentTab: any = {};
    public reportWizard: any = null;
    public isWidgetInstance: any = null;
    public widgetId: any = null;
    public tabId: any = null;
    public conceptLocalTimezone: any = null;
    public lockDash: boolean;
    public shareDash: boolean;
    public webUserID: string = null;

    constructor() {
        let publicData = {
            selectedConcept: this.selectedConcept,
            selectedStore: this.selectedStore,
            selectedCoupon: this.selectedCoupon,
            couponDisplayName: this.couponDisplayName,
            helpItemName: this.helpItemName,
            helpCatName: this.helpCatName,
            currentTab: this.currentTab,
            reportWizard: this.reportWizard,
            isWidgetInstance: this.isWidgetInstance,
            widgetId: this.widgetId,
            tabId: this.tabId,
            conceptLocalTimezone: this.conceptLocalTimezone,
            lockDash: this.lockDash,
            shareDash: this.shareDash,
            webUserID: this.webUserID,
        };
        return publicData;
    }
}
