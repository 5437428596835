import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OnlineStatusService } from 'ngx-online-status';
import { Subscription } from 'rxjs';
import { InventoryCounterDialogService } from '../services/inventory-counter-dialog.service';
import { InventoryCounterService } from '../services/inventory-counter.service';
import * as moment from 'moment';

@Component({
    selector: 'countWizard',
    templateUrl: 'src/app/features/inventory-counter/templates/count-wizard.html',
})
export class CountWizardComponent {
    public captions: Record<string,string>;
    public subscriptions: Subscription[] = [];
    public isOnline: boolean = true;
    public countType: string;
    public countDate: Date;
    public countDateParsed: any;
    public activeLocation: Location;
    public countLocations: Location[] = [];
    public countFreqType: String;

    public wizardStep;

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(InventoryCounterService) private inventoryCounterService: InventoryCounterService,
        @Inject(OnlineStatusService) private onlineStatusService: OnlineStatusService,
        @Inject(InventoryCounterDialogService) private inventoryCounterDialogService: InventoryCounterDialogService
    ) {
        this.wizardStep = this.inventoryCounterService.getCurrentStep();
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.subscriptions.push(
            this.onlineStatusService.status.subscribe((status: boolean) => {
                this.isOnline = status;
            })
        );
        this.subscriptions.push(
            this.inventoryCounterService.activeCountLocation$.subscribe((loc) => {
                this.activeLocation = this.inventoryCounterService.getCountLocationByID(loc);
            })
        );
        this.subscriptions.push(
            this.inventoryCounterService.countLocations$.subscribe((locations) => {
                this.countType = this.inventoryCounterService.getActiveCountType();
                this.countDate = this.inventoryCounterService.getActiveCountDate();
                this.countDateParsed = moment(this.countDate).format('YYYY-MM-DD');
                this.countLocations = locations;
            })
        );
    }

    ngOnInit(): void {
        this.countFreqType = this.inventoryCounterService.getCountFreqType();
     }

    locationClicked(loc = null): void {
        if (!loc) {
            this.backClicked();
            return;
        }
        this.wizardStep = 2;
        this.inventoryCounterService.setActiveCountLocation(loc.locId);
        this.inventoryCounterService.setCurrentStep(this.wizardStep);
    }

    backClicked(): void {
        this.wizardStep--;
        this.inventoryCounterService.setCurrentStep(this.wizardStep);
    }

    cancelClicked(): void {
        this.inventoryCounterDialogService.countCancelClicked();
    }

    submitCount(saveOnly): void {
        if (this.isOnline) {
            this.inventoryCounterDialogService.countSubmitClicked(saveOnly);
        }
        else {
            this.inventoryCounterDialogService.disconnectedBtnClicked().subscribe((res) => {
                if (res) {
                    this.submitCount(saveOnly);
                }
            });
        }
    }
}
