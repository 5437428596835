import { formatDate } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { WidgetConstants } from '../../constants/widget.constants';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'wid006',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-006.html',
})
export class Wid006 {
    public captions: any;
    public isTimedOut: boolean = false;
    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    public headers: string[];
    public labels: string[];
    public datasets: any[];

    public hourData: any[];
    public dayData: string[];

    public activeHour: number = -1;
    public activeView: number = 0;
    public activeDataset: number = 0;

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage
    ) {
        this.captions = this.captionService.captions;
        this.headers = [this.captions.revenue, this.captions.cog, this.captions.grossProfit];
        this.labels = [
            '01:00',
            '02:00',
            '03:00',
            '04:00',
            '05:00',
            '06:00',
            '07:00',
            '08:00',
            '09:00',
            '10:00',
            '11:00',
            '12:00',
            '13:00',
            '14:00',
            '15:00',
            '16:00',
            '17:00',
            '18:00',
            '19:00',
            '20:00',
            '21:00',
            '22:00',
            '23:00',
            '24:00',
        ];

        this.hourData = Array(24).fill(['0', '0.00', '0.00']);
        this.dayData = ['0', '0.00', '0.00'];
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    cycleViews() {
        this.activeView = (this.activeView + 1) % 2;
    }

    setDataset(val: number) {
        this.activeDataset = val;
        let dataset = [];
        for (let i = 0; i < this.hourData.length; i++) {
            dataset.push(this.hourData[i][val]);
        }
        this.datasets = [{ data: dataset }];
    }

    ngOnInit() {
        //adding the configured value for the activeView and activeDataset
        let portalData = this.browserStorage.getSessionstorage('portalData', null);
        let data = JSON.parse(JSON.stringify(portalData));
        let config;
        if (data.WidgetValueConfig) {
            config = JSON.parse(data.WidgetValueConfig);
        }
        let selectedView = config?.hourlyProfitBreakdown1column1Selection
            ? parseInt(config.hourlyProfitBreakdown1column1Selection)
            : 0;
        if (selectedView === 0) {
            this.activeView = 0;
        } else {
            this.activeView = 1;
            this.activeDataset = selectedView - 1;
        }

        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }

        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }
        if (this.areDatapointsInvalid) {
            return;
        }

        let hourDataset = this.widget.data[0].Record;
        this.hourData = Array(24).fill(['0', '0.00', '0.00']);
        if (!hourDataset) {
            this.datasets = [];
            this.hourData = Array(24).fill(['0', '0.00', '0.00']);
            this.dayData = ['0', '0.00', '0.00'];
            return;
        }

        hourDataset = Array.isArray(hourDataset) ? hourDataset : [hourDataset];
        hourDataset.forEach((hour) => {
            this.hourData[hour.SALESHOUR] = [
                hour.REVENUE ? hour.REVENUE.toFixed(2) : '0.00',
                hour.COG ? hour.COG.toFixed(2) : '0.00',
                hour.PROFIT ? hour.PROFIT.toFixed(2) : '0.00',
            ];
        });
        this.setDataset(this.activeDataset);

        let dayDataset = this.widget.data[1].Record;
        if (!dayDataset) {
            this.dayData = ['0', '0.00', '0.00'];
            return;
        }

        this.dayData = [
            dayDataset.REVENUE ? dayDataset.REVENUE.toFixed(2) : '0.00',
            dayDataset.COG ? dayDataset.COG.toFixed(2) : '0.00',
            dayDataset.PROFIT ? dayDataset.PROFIT.toFixed(2) : '0.00',
        ];

        let storeInfoDataset = this.widget.data[2].Record;
        let browserOpenDate = this.browserStorage.getSessionstorage('portalData', null)?.DataSets.DataSet[1].Record
            .OPENDATE;
        let widgetSelectedDate = this.browserStorage.getSessionstorage('widgetSelectedDate', null);

        if (storeInfoDataset && dayDataset) {
            let currentOpendate;

            if (!widgetSelectedDate || widgetSelectedDate === browserOpenDate) {
                currentOpendate = formatDate(browserOpenDate, WidgetConstants.DATE_FORMAT_SIMPLE, 'en-US');
            } else {
                currentOpendate = formatDate(widgetSelectedDate, WidgetConstants.DATE_FORMAT_SIMPLE, 'en-US');
            }

            let latestOpendate = formatDate(storeInfoDataset.OPENDATE, WidgetConstants.DATE_FORMAT_SIMPLE, 'en-US');

            this.activeHour = -1;
            if (currentOpendate === latestOpendate) {
                this.activeHour = new Date(storeInfoDataset.STOREDATETIME).getHours();
            }
        }
    }
}
