import { Inject, Injectable } from '@angular/core';
import { AppSettings } from '../../../app-settings.service';
import { AppConstants } from '../../../app.constants';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';

@Injectable()
export class UserInfoHandler {
    private THEMES;
    private LANGUAGES;
    public userInfo;

    constructor(@Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(AppSettings) private appSettings: AppSettings) {
        this.LANGUAGES = AppConstants.LANGUAGES;
        this.THEMES = AppConstants.THEMES;
        this.userInfo = {
            token: null,
            firstName: null,
            lastName: null,
            email: null,
            language: null,
            theme: null,
            showDashboardByDefault: false,
            resetPassOnLogin: null,
            favouriteTabID: null,
            notificationConsent: null
        };
    }

    getUserInfo() {
        if (!this.userInfo.token) {
            let userInfoTemp = this.browserStorage.getSessionstorage('userInfo', null);

            if (!userInfoTemp) return null;

            this.userInfo = userInfoTemp;
            return userInfoTemp;
        } else return this.userInfo;
    }

    setToken(token) {
        this.userInfo.token = token;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    setFirstName(firstName) {
        this.userInfo.firstName = firstName;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    setLastName(lastName) {
        this.userInfo.lastName = lastName;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    setEmail(email) {
        this.userInfo.email = email;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    /*setting user language should only happen here. For ALL storages: session, local, service */
    setLanguage(lang) {
        let language = lang || this.LANGUAGES.english;

        this.userInfo.language = language;
        this.appSettings.setLanguage(language);
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    setShowDashboardByDefault(showDashboardByDefault) {
        if (showDashboardByDefault !== true) showDashboardByDefault = false;

        this.userInfo.showDashboardByDefault = showDashboardByDefault;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    setFavouriteTabID(favouriteTabID) {
        this.userInfo.favouriteTabID = favouriteTabID;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo)
    }

    setResetPassOnLogin(resetPassOnLogin) {
        this.userInfo.resetPassOnLogin = resetPassOnLogin;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    setTheme(theme) {
        theme = theme || this.THEMES.light;

        this.userInfo.theme = theme;
        this.appSettings.setTheme(theme);
        this.appSettings.applyTheme();
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    setNotificationConsent(notificationConsent) {
        this.userInfo.notificationConsent = notificationConsent;
        this.browserStorage.setSessionstorage('userInfo', this.userInfo);
    }

    clearUser() {
        this.userInfo.token = null;
        this.browserStorage.removeSessionstorageItem('userInfo');
    }
}