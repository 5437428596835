import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from "rxjs";
import { AppConstants } from '../../../app.constants';
import { ReportCategory, Product, PriceChange, PriceChangeLabel } from "../models/price-change.model";

@Injectable()
export class PriceChangeService {
    private reportCatsUrl: string = "priceChange/reportCat";
    private productsUrl: string = "priceChange/products";
    private updatePricesUrl: string = "priceChange/updatePrices";
    private productHistoryUrl: string = "priceChange/product/history";
    private priceMatchUrl: string  = "priceChange/priceMatch";
    private priceChangeLabelsUrl: string = "priceChange/labels/{conceptID}";
    private addPriceChangeLabelUrl: string = "priceChange/label";

    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getReportCategories(storeIDs: number[]): Promise<ReportCategory[]> {
        let url = this.BASE_URL + this.reportCatsUrl;
        let data$ = this.http.post<ReportCategory[]>(url, storeIDs)
        const value = await lastValueFrom(data$);
        return value;
    }

    async getProducts(storeIDsAndReportCat: ReportCategory[]): Promise<Product[]> {
        let url = this.BASE_URL + this.productsUrl;
        let data$ = this.http.post<Product[]>(url, storeIDsAndReportCat)
        const value = await lastValueFrom(data$);
        return value;
    }

    async updatePrices(priceChanges: PriceChange): Promise<any> {
        let url = this.BASE_URL + this.updatePricesUrl;
        let data$ = this.http.post(url, priceChanges, {responseType: 'text'})
        const value = await lastValueFrom(data$);
        return value;
    }

    async getProductHistory(storeID: number, prodNum: number): Promise<any> {
        let url = this.BASE_URL + this.productHistoryUrl;
        let data$ = this.http.get(url,{
            params: { 
                storeID: storeID, 
                prodNum: prodNum, 
            }
        })
        const value = await lastValueFrom(data$);
        return value;
    }

    
    async priceMatch(storeFrom, storeTo, selectedCategories): Promise<any> {
        let url = this.BASE_URL + this.priceMatchUrl;
        const body = {
            storeIDFrom: storeFrom,
            storeIDTo: storeTo,
            selectedCategories: selectedCategories
        };
    
        let data$ = this.http.post(url, body);

        const value = await lastValueFrom(data$);
        return value;
    }

    async getPriceChangeLabels(conceptID: number): Promise<any> {
        let url = this.BASE_URL + this.priceChangeLabelsUrl;
        let priceChangeLabelsUrlValid = url.replace('{conceptID}', conceptID.toString());
        let data$ = this.http.get(priceChangeLabelsUrlValid);
        const value = await lastValueFrom(data$);
        return value;
    }

    async addPriceChangeLabel(priceChange: PriceChangeLabel): Promise<any> {
        let url = this.BASE_URL + this.addPriceChangeLabelUrl;
        let data$ = this.http.post(url, priceChange)
        const value = await lastValueFrom(data$);
        return value;
    }

    
}
