import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../../utils/shared-services/caption.service';
import { EditColumnsData } from '../wid-012';
import { BrowserStorage } from '../../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'editColumnsDialogWid012',
    templateUrl: 'src/app/features/store-portal/widgets/templates/dialogs/wid-012-edit-columns-dialog.html',
})
export class EditColumnsDialogWid012 {
    public captions;
    public columnSelectForm: any;
    public wid012_col1 = 0;
    public wid012_col2 = 4;
    public wid012_col3 = 5;

    private readonly WIDGET_COLUMN1_KEY: string = 'wid012_col1';
    private readonly WIDGET_COLUMN2_KEY: string = 'wid012_col2';
    private readonly WIDGET_COLUMN3_KEY: string = 'wid012_col3';

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EditColumnsDialogWid012>,
        @Inject(CaptionService) private captionService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(UntypedFormBuilder) private formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: EditColumnsData
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit() {
        this.wid012_col1 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN1_KEY, this.data.wid012_col1);
        this.wid012_col2 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN2_KEY, this.data.wid012_col2);
        this.wid012_col3 = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN3_KEY, this.data.wid012_col3);
        this.columnSelectForm = this.formBuilder.group({
            column1Selection:
                this.wid012_col1 != 0 ? [this.wid012_col1.toString(), Validators.required] : ['0', Validators.required],
            column2Selection:
                this.wid012_col2 != 4 ? [this.wid012_col2.toString(), Validators.required] : ['4', Validators.required],
            column3Selection:
                this.wid012_col3 != 5 ? [this.wid012_col3.toString(), Validators.required] : ['5', Validators.required],
        });
    }

    saveColumns() {
        const dialogData: EditColumnsData = {
            wid012_col1: this.columnSelectForm.value.column1Selection,
            wid012_col2: this.columnSelectForm.value.column2Selection,
            wid012_col3: this.columnSelectForm.value.column3Selection,
        };

        this.dialogRef.close(dialogData);
    }
}
