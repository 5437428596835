import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { RecycleBinApiService } from '../../services/recycle-bin.resource';

interface Parameters {
    itemsToRestore: any;
}

@Component({
    selector: 'confirm-permanent-delete-dialog',
    templateUrl: 'src/app/features/recycle-bin/components/dialogs/templates/restore-items-confirmation.dialog.html',
})
export class RestoreItemsConfirmationDialog {
    public captions: any;

    constructor(
        @Inject(RecycleBinApiService) private recycleBinApiService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<RestoreItemsConfirmationDialog>
    ) {
        this.captions = this.captionService.captions;
    }

    restoreItems() {
        this.recycleBinApiService.restoreItems(this.data.itemsToRestore).then(() => {
            this.dialogRef.close(true);
            //reload page
        });
    }
}
