import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, lastValueFrom, map, Observable, of, throwError } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { UserAccess } from '../../users/models/user-access.model';
import { StorePortalData } from '../models/store-portal.model';
import { ReportData } from '../components/dialogs/report-viewer-dialog.component';
import { report } from 'process';

@Injectable()
export class StorePortalApiService {
    public captions: any;
    private readonly BASE_URL: string;
    private readonly portalUrl: string = 'storePortal';
    private readonly loginParam: string = '/login';
    private readonly hqLoginParam: string = '/hqlogin';
    private readonly validateParam: string = '/validate';
    private readonly configParam: string = '/config';
    private readonly dataParam: string = '/data';
    private readonly descriptionParam: string = '/description';
    private readonly PORTAL_DATA_KEY: string = 'portalData';
    private readonly gatwayOrder: string = "/gatewayOrder";
    private readonly storeName: string = "/storeName";

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
    }
    /**
     * @name validateAccessToken
     * @description this method validates an access token for remote store portal
     * @param token this is the access token [required]
     * @returns boolean indicating validity status
     */
    async validateAccessToken(token: string) {
        let url = this.BASE_URL + this.portalUrl + this.validateParam;
        const data$ = this.http.get(url, {
            params: {
                token: token,
            },
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name loginForStorePortal
     * @description this method authenticates the user to access remote store portal
     * @param storeID this is a unique string identifier for store portal (a.k.a alias) [required]
     * @param user username [optional]
     * @param password password for login [required]
     * @returns access token and store information
     */
    async loginForStorePortal(storeID, userID, password, userAccess) {
        let url = this.BASE_URL + this.portalUrl + this.loginParam;
        const data$ = this.http.post(
            url,
            JSON.stringify({
                storeSID: storeID,
                user: userID ?? '',
                empID: password,
                userAccess: userAccess ? userAccess : {}, // for switching between stores
            })
        );
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name hqLoginForStorePortal
     * @description this method authenticates the user to access remote store portal. This method
     * requires a valid HQ token.
     * @param storeID this is a unique string identifier for store portal (a.k.a alias) [required]
     * @param user username [optional]
     * @param password password for login [required]
     * @returns access token and store information
     */
    async hqLoginForStorePortal(storeID: string, userID: string, password: string, userAccess: UserAccess): Promise<StorePortalData> {
        let url = this.BASE_URL + this.portalUrl + this.hqLoginParam;
        const data$ = this.http.post<StorePortalData>(
            url,
            JSON.stringify({
                storeSID: storeID,
                user: userID ?? '',
                empID: password,
                userAccess: userAccess,
            })
        );
        const value = await lastValueFrom(data$);
        return value;
    }

    async getStoreName(alias:string){
        let url = this.BASE_URL + this.portalUrl + this.storeName;
        const data$ = this.http.get(url, {
            params: {
                alias: alias
            },
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name getPortalConfigByStoreID
     * @description this method retrieves the widget configurations for a given store. This method requires a
     * valid Store Portal token
     * @param storeID this is the id of the store whose widget configurations need to be retrieved [required]
     * @returns store remote portal configuration json
     */
    async getPortalConfigByStoreID(storeID) {
        let url = this.BASE_URL + this.portalUrl + this.configParam;
        const data$ = this.http.get(url, {
            params: {
                storeID: storeID,
                token: this.browserStorage.getSessionstorage(this.PORTAL_DATA_KEY, null).AccessToken,
            },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
     * @name getDatasetsByDatapoints
     * @description this method retrieves the widget datasets for a given store and a given datapoints list. This
     * method requires a valid Store Portal token
     * @param string storeID: this is the id of the store whose widget datasets need to be retrieved [required]
     * @param string params: a stringified json object that includes all parameters used to filter data for 1 or more datapoints [required]
     * @param string datapoints: this is a list of datapoints for which the datasets need to be retrieved [required]
     * @returns datasets from store based on given list of datapoints

    */
    async getDatasetsByDatapoints(storeID, params, datapoints) {
        let url = this.BASE_URL + this.portalUrl + this.dataParam;
        const data$ = this.http
            .get(url, {
                params: {
                    storeID: storeID,
                    params: params,
                    datapoints: datapoints,
                    token: this.browserStorage.getSessionstorage(this.PORTAL_DATA_KEY, null).AccessToken,
                },
            })
            .pipe(catchError(this.handleError));
        const value = await lastValueFrom(data$);
        return value;
    }

    handleError(error: HttpErrorResponse) {
        return throwError(() => error);
    }

    /**
     * @name getWidgetDescriptionHTMLByID
     * @description this method retrieves the widget description template HTML for a given widget by id.
     * @param widgetID this is the id of the widget whose description template HTML needs to be retrieved [required]
     * @returns description template HTML for a given widget
     */
    async getWidgetDescriptionHTMLByID(widgetID: string): Promise<string> {
        let url = this.BASE_URL + this.portalUrl + this.descriptionParam;
        const data$ = this.http.get(url, {
            params: {
                widgetID: widgetID,
            },
            responseType: 'text',
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    /**
 * @name viewCrystalReport
 * @description this method calls API to upload file to S3 and then open it in new tab
 * @param requestXML this is a unique string identifier for store portal (a.k.a alias) [required]
 * @returns url from S3
 */
    async viewCrystalReport(reportData: ReportData, accessToken: string) {
        const data$ = this.http.post(this.BASE_URL + this.portalUrl + this.gatwayOrder, null, {
            params: {
                reportName: reportData.reportName,
                reportFile: reportData.source,
                date1: reportData.date1,
                date2: reportData.date2,
                detailLevel: reportData.detailLevel,
                storeID: reportData.storeID,
                token: this.browserStorage.getSessionstorage(this.PORTAL_DATA_KEY, null).AccessToken,
            }
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    checkFileExists(fileUrl: string): Observable<boolean> {
        // Making a HEAD request to check if the file exists
        return this.http.head(fileUrl, { observe: 'response' }).pipe(
            map((response: HttpResponse<any>) => {
                // If the status is 200, the file exists
                return response.status === 200;
            })
        );
    }
}