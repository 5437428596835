import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CaptionService } from '../shared-services/caption.service';

export interface BreadCrumb {
    position: number;
    label: string;
    url: string;
};
@Component({
    selector: 'app-breadcrumb',
    templateUrl: 'src/app/utils/breadcrumb/breadcrumb.html',
    styleUrls: ['src/app/utils/breadcrumb/breadcrumb.css']
})
export class BreadcrumbComponent implements OnInit {
    breadcrumbs: BreadCrumb[] = [];
    paramName: any;
    id: any;
    name: any;
    extra: any;
    constructor(
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
        @Inject(CaptionService) private captions: CaptionService
    ) {
        this.captions = captions.captions;
        this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute, 0);
    }
    public ngOnInit(): void { }

    buildBreadCrumb(route: ActivatedRoute, position: number): BreadCrumb[] {
        this.id = route.snapshot.params.pathId ? route.snapshot.params.pathId : null;
        this.name = route.snapshot.params.pathName ? route.snapshot.params.pathName : null;
        this.extra = route.snapshot.params.pathExtra ? route.snapshot.params.pathExtra : null;
        let label, path;
        this.paramName = route.snapshot.params['displayName'] ? route.snapshot.params['displayName'] : route.snapshot.params['pathName'];
        if (position == 1) {
            path = route.routeConfig ? route.routeConfig.data['parent'].path : '/home';
            label = route.routeConfig ? this.captions[route.routeConfig.data['parent'].name] : 'Null';
        } else {
            if (this.paramName)
                label = route.routeConfig && route.routeConfig.data ?
                    this.captions[route.routeConfig.data.breadcrumb] + "  "
                    + this.captions["for"] + "  "
                    + this.paramName :
                    'Null';
            else label = route.routeConfig && route.routeConfig.data ? this.captions[route.routeConfig.data.breadcrumb] : 'Null'
            path = route.routeConfig && route.routeConfig.data ? route.routeConfig.path : '/home';
        }
        const breadcrumb = {
            label: label,
            url: path,
            position: position
        };
        this.breadcrumbs.push(breadcrumb);

        if (route.routeConfig.data['parent'].name != '' && position != 1) {
            return this.buildBreadCrumb(route, 1);
        }
        return this.breadcrumbs;
    }

    navigate(crumb) {
        if(crumb.url.includes("inventoryCounter")) {
            return;
        }
        if (crumb.url.includes(":pathId")) {
            crumb.url = crumb.url.replace(':pathId', this.id);
        }
        if (crumb.url.includes(":pathName")) {
            crumb.url = crumb.url.replace(':pathName', this.name);
        }
        if (crumb.url.includes(":pathExtra")) {
            crumb.url = crumb.url.replace(':pathExtra', this.extra);
        }
        if (this.breadcrumbs.length > 1) {
            this.router.navigate([crumb.url]);
        }
    }
}