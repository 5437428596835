import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserAccessHandler } from "../../../../features/users/services/user-access-handler.service";
import { UserApiService } from "../../../../features/users/services/users.resource";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { SnackbarService } from "../../../../utils/shared-services/snackbar/snackbar.service";
import { UtilsApiService } from "../../../../utils/shared-services/utils.resource";
import { DashboardApiService } from "../../services/dashboard.resource";
import { DashboardDialogData } from "../../models/dialog.model";
import { DashboardTabGetWithContent } from "../../models/dashboard.model";
import { UserAccess } from "../../../users/models/user-access.model";
import { UserRole } from "../../../users/enums/user-role.enum";
import { ManageWebUsersGet } from "../../../users/models/user.model";

interface AssignableUser extends ManageWebUsersGet {
    selected?: boolean;
    storeUser?: boolean;
    globalAccess?: boolean;
    accessType?: string;
    accessTo?: string;
}

@Component({
    selector: 'assignUser',
    templateUrl: 'src/app/features/dashboard/templates/dialogs/assign-user.tpl.html',
})
export class AssignUserDialog {

    private currentUser: UserAccess & { showGroupItemIcon?: boolean; showTagIcon?: boolean };
    private columnAscOrDesc: number = 1;
    private selectAll: boolean;
    private currentUserSecurity: UserAccess;
    private sharedUsersFromDB: number[] = [];
    private sharedUsersNew: number[] = [];
    private unsharedUsers: number[] = [];
    private filteredUsers: AssignableUser[] = [];
    private found: any;
    private user: AssignableUser;
    private userInfo: any;
    private email: string;
    private cuAccessType: string;
    private cuAccessTo: string;

    public captions: Record<string, string>;
    public userInfoPredicate: string = 'firstName';
    public userInfoReverse = true;
    public users: AssignableUser[] = [];
    public currentTab: DashboardTabGetWithContent;
    public searchStr: string = '';

    constructor(
        @Inject(MAT_DIALOG_DATA) public passedData: DashboardDialogData,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<AssignUserDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(DashboardApiService) private dashboardApiService: DashboardApiService,
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
    ) {
        this.captions = this.captionService.captions;
        this.currentTab = this.passedData.tabs[this.passedData.currentTabIndex];
        this.currentUserSecurity = this.userAccessHandler.getUserAccess();
    }

    ngOnInit(): void {
        this.getCurrentUserInfo();
        this.getSharedUsers();
    }

    changeOrder(predicate: string): void {
        if (this.userInfoPredicate !== predicate) {
            this.columnAscOrDesc = 0;
        }
        else {
            if (this.columnAscOrDesc === 0) {
                this.columnAscOrDesc = 1;
            }
            else {
                this.columnAscOrDesc = 0;
            }
        }
        this.userInfoReverse = this.userInfoPredicate === predicate ? !this.userInfoReverse : false;
        this.userInfoPredicate = predicate;
    }

    toggleAll(notSearching: boolean): void {
        if (notSearching) {
            this.selectAll = true;

            for (let user of this.users) {
                if (user.selected) {
                    this.selectAll = false;
                    break;
                }
            }

            for (let j = 0; j < this.users.length; j++) {
                if (this.users[j].selected != this.selectAll) {
                    this.users[j].selected = this.selectAll;
                }
                this.selectUser(this.users[j]);
            }
        }
        else {
            // Need to find another way to store the filtered users
            // In the meantime, toggleAll filtered users does not work
            this.filteredUsers = this.found;

            this.selectAll = true;

            for (let k = 0; k < this.filteredUsers.length; k++) {
                if (!this.filteredUsers[k].selected) {
                    this.selectAll = false;
                    break;
                }
            }

            for (let l = 0; l < this.filteredUsers.length; l++) {
                if (this.filteredUsers[l].selected != this.selectAll) {
                    this.filteredUsers[l].selected = this.selectAll;
                }
                this.selectUser(this.filteredUsers[l]);
            }
        }
    }

    save(): void {
        let message: string;
        if (this.sharedUsersNew.length > 0 || this.unsharedUsers.length > 0) {
            if (this.sharedUsersNew.length > 0) {
                this.dashboardApiService.addSharedDashboardTab(this.currentTab.id, this.sharedUsersNew);
            }
            if (this.unsharedUsers.length > 0) {
                this.dashboardApiService.deleteShareDashboardTab(this.currentTab.id, this.unsharedUsers);
            }
            message = this.captions.shareTabUpdate + ' (' + this.currentTab.tabName + ')';
            this.dialogRef.close();
        }
        else {
            message = this.captions.updateFail;
        }
        this.snackbarService.successMessageBottom(message);
    }

    private getCurrentUserInfo(): void {
        this.utilsApiService.getUserInfo().then((userInfo: any) => {
            this.userInfo = userInfo;
            this.email = userInfo.email;
            this.getCurrentUserSecurity(this.currentUserSecurity);
            this.getAllUsers();
        })
    }

    private getCurrentUserSecurity(currentUserSecurity: UserAccess) {
        this.currentUser = currentUserSecurity;
        if (currentUserSecurity.role === UserRole.GroupItem) {
            this.currentUser.showGroupItemIcon = true;
            this.currentUser.showTagIcon = false;
        }
        else if (currentUserSecurity.role === UserRole.Tag) {
            this.currentUser.showGroupItemIcon = false;
            this.currentUser.showTagIcon = true;
        }
    }

    private getSharedUsers(): void {
        this.dashboardApiService.getSharedDashboardUsers(this.currentTab.id).then((response) => {
            for (let i = 0; i < response.length; i++) {
                this.sharedUsersFromDB.push(response[i]);
            }
        });
    }

    private getAllUsers(): void {
        this.userApiService.getUsersToShare().then((users: ManageWebUsersGet[]) => {
            for (let user of users) {
                // Look for current user
                if (user.id === this.currentUserSecurity.webUserID) {
                    let cUserIndex = users.findIndex((k) => k == user);

                    // Remove current user from list becuase we don't need to 
                    // share the tab with ourselves
                    users.splice(cUserIndex, 1);

                    if (user.role === UserRole.Global) {
                        this.cuAccessType = this.captions.fullAccess;
                        this.cuAccessTo = this.captions.allStores;
                    }
                    else if (user.role === UserRole.Concept) {
                        this.cuAccessType = this.captions.organization;
                        this.cuAccessTo = user.conceptName;
                    }
                    else if (user.role === UserRole.GroupItem) {
                        this.cuAccessType = this.captions.groupItem;
                        this.cuAccessTo = user.groupItemName;
                    }
                    else if (user.role === UserRole.Tag) {
                        this.cuAccessType = this.captions.tag;
                        this.cuAccessTo = user.tagName;
                    }
                    else {
                        this.cuAccessType = this.captions.store;
                        this.cuAccessTo = user.storeName;
                    }

                    this.users = users;

                    // Select currently shared users
                    for (let j = 0; j < this.users.length; j++) {
                        if (this.sharedUsersFromDB.includes(this.users[j].id)) {
                            this.users[j].selected = true;
                        }
                    }

                }
            }
            this.getUpdatedUsers(users);
        })
    }

    private getUpdatedUsers(users: AssignableUser[]): void {
        for (let user of users) {
            user.storeUser = false;
            user.globalAccess = false;
            this.user = user;
            if (user.role === UserRole.Global) {
                user.globalAccess = true;
                user.accessType = this.captions.fullAccess;
                user.accessTo = this.captions.allStores;
            }
            else if (user.role === UserRole.Concept) {
                user.accessType = this.captions.organization;
                user.accessTo = user.conceptName;
            }
            else if (user.role === UserRole.GroupItem) {
                user.accessType = this.captions.groupItem;
                user.accessTo = user.groupItemName;
            }
            else if (user.role === UserRole.Tag) {
                user.accessType = this.captions.tag;
                user.accessTo = user.tagName;
            }
            else {
                user.storeUser = true;
                user.accessType = this.captions.storeList;
                user.accessTo = user.storeName;
            }
        }
    }

    private selectUser(user: AssignableUser) {
        if (user.selected) {
            let index = this.sharedUsersNew.indexOf(user.id);
            if (index > -1) {
                this.sharedUsersNew.splice(index, 1);
            }
            user.selected = false;
            if (this.sharedUsersFromDB.includes(user.id)) {
                this.unsharedUsers.push(user.id);
            }
        }
        else {
            let index2 = this.unsharedUsers.indexOf(user.id);
            if (index2 > -1) {
                this.unsharedUsers.splice(index2, 1);
            }
            user.selected = true;
            if (!this.sharedUsersFromDB.includes(user.id)) {
                this.sharedUsersNew.push(user.id);
            }
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }


    filterSharedTabs(user: ManageWebUsersGet, searchStr: string) {
        return !searchStr || user.firstName.toLowerCase().includes(searchStr.toLowerCase()) || user.lastName.toLowerCase().includes(searchStr.toLowerCase()) || user.email.toLowerCase().includes(searchStr.toLowerCase());
    }
}
