import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { SnackbarService } from '../../../../utils/shared-services/snackbar/snackbar.service';
import { EoiApiService } from '../../services/eoi.resource';
import { ValidationService } from '../../../../utils/shared-services/validation.service';
import { Subscription } from '../../models/eoi.model';

interface Parameters {
    tempSub: any;
    getInitialSubscription: any;
    subscription: any;
    subscribed: any;
    storeID: any;
    timeRange: any;
    reqType: any;
    vendorID: any;
    updateTimeRange: any;
}

@Component({
    selector: 'eoi-subscription-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-subscription.dialog.html',
})
export class EoiSubscriptionDialog {
    public captions: Record<string,string>;

    constructor(
        @Inject(EoiApiService) private eoiApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EoiSubscriptionDialog>,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.captionService.captions;
        data.tempSub.delayInterval = parseInt(data.tempSub.delayInterval);
    }

    removeEmail(index:number): void {
        this.data.tempSub.email.splice(index, 1);
        this.data.getInitialSubscription();
    }

    cancelSubscription(): void {
        if (this.data.subscription.id) {
            this.deleteSubscription();
            this.dialogRef.close();
            this.snackbarService.successMessageTop(this.captions.subCancel);
        }
    }

    deleteSubscription(): void {
        this.eoiApiService.deactivateSubscription(this.data.subscription.id).then((response) => {
            this.data.subscription = { delayInterval: '0' };
            this.data.getInitialSubscription();
            this.data.updateTimeRange(this.data.storeID, this.data.timeRange, this.data.reqType, this.data.vendorID);
        });
    }

    addSubscription(subscription: Subscription): void {
        this.eoiApiService.addSubscription(subscription, this.data.storeID).then((response) => {
            this.data.getInitialSubscription();
            this.data.updateTimeRange(this.data.storeID, this.data.timeRange, this.data.reqType, this.data.vendorID);
        });
    }

    saveSubscription(email: string[], interval: string, subject: string): void {
        let validEmail = [];
        let message;

        if (email) {
            for (let e of email) {
                if (this.validationService.eoiValidateEmail(e)) {
                    validEmail.push(this.validationService.eoiValidateEmail(e)[0]);
                }
            }
        }

        if (validEmail && validEmail.length > 0 && validEmail.length === email.length) {
            let newSub = !this.data.subscription.email[0];

            this.data.subscription.subscriptionType = 1;
            this.data.subscription.email = email.filter(function (e) {
                return e.length > 0;
            });
            this.data.subscription.delayInterval = parseInt(interval);
            this.data.subscription.emailSubject = subject;

            let noDuplicateEmails = this.data.subscription.email.length === new Set(this.data.subscription.email).size;

            if (noDuplicateEmails) {
                if (newSub) {
                    message = this.captions.subscribed;
                    this.addSubscription(this.data.subscription);
                } else {
                    message = this.captions.updateSubscription;
                    this.eoiApiService.updateSubscriptionInfo(this.data.subscription);
                }
            } else {
                message = this.captions.duplicateEmails;
                this.data.getInitialSubscription();
                this.data.updateTimeRange(
                    this.data.storeID,
                    this.data.timeRange,
                    this.data.reqType,
                    this.data.vendorID
                );
            }
        } else {
            message = this.captions.invalidEmailHint;
        }
        this.dialogRef.close();
        this.snackbarService.errorMessageTop(message);
    }
}
