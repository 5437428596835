import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';
import { StoreBase } from '../../stores/models/store.model';
import { GroupItem } from '../models/group-item.model';

@Injectable()
export class StoreGroupMembersApiService {
    private createStoreGroupItemUrl: string = 'group-items';
    private getStoreGroupItemsUrl: string = 'group-items';
    private getStoreGroupItemUrl: string = 'group-items/{groupItemID}';
    private updateStoreGroupItemUrl: string = 'group-items/{groupItemID}';
    private removeStoreGroupItemUrl: string = 'group-items/{groupItemID}';
    private getGroupItemInfoUrl: string = 'stores';

    private BASE_URL;

    constructor(@Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async createStoreGroupItem(storeGroupItem) {
        const url = this.BASE_URL + this.createStoreGroupItemUrl;
        const validStoreGroupItem = this.dataUtils.getCleanedObjectFields(storeGroupItem, ['displayName', 'groupID']);
        const data$ = this.http.post(url, validStoreGroupItem);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getAllStoreGroupItemsForStoreGroup(groupId: number): Promise<GroupItem[]> {
        const url = this.BASE_URL + this.getStoreGroupItemsUrl;
        const data$ = this.http.get<GroupItem[]>(url, {
            params: { groupID: groupId },
        });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getStoreGroupItemById(storeGroupItemId) {
        const getStoreGroupItemUrlValid = this.getStoreGroupItemUrl.replace('{groupItemID}', storeGroupItemId);
        const url = this.BASE_URL + getStoreGroupItemUrlValid;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateStoreGroupItem(storeGroupItem) {
        const updateStoreGroupItemUrlValid = this.updateStoreGroupItemUrl.replace('{groupItemID}', storeGroupItem.id);
        const url = this.BASE_URL + updateStoreGroupItemUrlValid;
        const validStoreGroupItem = this.dataUtils.getCleanedObjectFields(storeGroupItem, ['displayName']);
        const data$ = this.http.put(url, validStoreGroupItem);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getGroupItemInfoById(groupItemId: number): Promise<StoreBase[]> {
        const url = this.BASE_URL + this.getGroupItemInfoUrl;
        const data$ = this.http.get(url, {
            params: { groupItemID: groupItemId },
        });
        const value = await lastValueFrom(data$) as StoreBase[];
        return value;
    }

    async deleteStoreGroupItem(storeGroupItemId) {
        const deleteStoreGroupItemUrlValid = this.removeStoreGroupItemUrl.replace('{groupItemID}', storeGroupItemId);
        const url = this.BASE_URL + deleteStoreGroupItemUrlValid;
        const data$ = this.http.delete(url);
        const value = await lastValueFrom(data$);
        return value;
    }
}
