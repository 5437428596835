import { Component, Inject, Injectable, Input, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppSettings } from '../../../app-settings.service';
import { AppConstants } from '../../../app.constants';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { MobileViewService } from '../../../utils/shared-services/mobileView/mobileView.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { ConceptApiService } from '../../concepts/services/concept.resource';
import { StoreApiService } from '../../stores/services/store.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserInfoHandler } from '../../users/services/user-info-handler.service';
import {
    DeleteAllJobsDialog,
    DeleteAllSchedulesDialog,
    DeleteJobDialog,
    DeleteTemplateDialog,
    ScheduleJobsDialog,
} from '../internal';
import { ScheduleApiService } from '../services/report-schedules.resource';
import { ReportTemplatesDataService } from '../services/report-templates-data.resource';
import { ReportApiService } from '../services/reports.resource';
import { ReportDataWizard } from '../wizard/services/report-data-wizard.service';
import { SharedWizardData } from '../wizard/services/report-shared-wizard-data.service';
import { ReportTooltipComponent } from './dialogs/report-tooltip.component';
import { MatTooltip } from '@angular/material/tooltip';

export interface ManageReportsData {
    selectedSchedule: any;
    currentJobToDelete: any;
    jobs: any;
    captions: any;
    conceptID: any;
    conceptName: any;
    selectedTemplates: any;
    reportTemplates: any;
    reportSchedules: any;
    info: any;
    reportJobID: any;
}

@Component({
    selector: 'manageReports',
    templateUrl: 'src/app/features/reports/templates/manage-reports.html',
})
@Injectable()
export class ManageReportsComponent implements OnInit {
    @ViewChild('drawer') drawer: MatDrawer;
    @ViewChild('reportTooltip') reportTooltip: MatTooltip;
    @ViewChild('jobInfoTooltip') jobInfoTooltip: MatTooltip;
    @Input() public searchStr;

    public _scheduleInfo = [] as any;
    public scheduleInfo = [];
    public reportDescription = '';
    public currentTemplateUrl = '';
    public delay = 1;
    public counter = 0;
    public hasJobsPending: boolean = false;
    public showJobs: boolean = true;
    public showIframe: boolean = false;
    public showBackButton: boolean = false;
    public showDeleteButton: boolean = false;
    public conceptName;
    public currentUser;
    public conceptID;
    public showTab;
    public refreshJobs;
    public manageReportState;
    public lastItemPassed;
    public jobs = [] as any;
    private jobInfoTooltipDesc = {};
    public reportDescriptions;
    public captions;
    public specificJobs;
    public selectedSchedule;
    public reportSchedules;
    public selectedTemplate;
    public scheduledTimeZone;
    public trueTimeZone;
    public newDate;
    public sign;
    public difference;
    public offset;
    public reportTemplates;
    public showJobInfo: boolean = false;
    public showSchduleInfo: boolean = false;
    THEMES: { light: string; dark: string };
    myReportsToggled: boolean;
    pixelReports: boolean;
    passedConceptId: number;
    reportTabIdx: number;

    private autoHideTimeout: any;
    templatesLoaded: boolean;
    toggleCheckbox: boolean;
    showDrawer: boolean = false;

    constructor(
        @Inject(UserAccessHandler) public userAccessHandler: UserAccessHandler,
        @Inject(UserInfoHandler) public userInfoHandler: UserInfoHandler,
        @Inject(StoreApiService) public storeApiService: StoreApiService,
        @Inject(ConceptApiService) public conceptApiService: ConceptApiService,
        @Inject(ItemStorageService) public itemStorageService: ItemStorageService,
        @Inject(UtilsApiService) public utilsApiService: UtilsApiService,
        @Inject(AppSettings) public appSettings: AppSettings,
        @Inject(MatDialog) public dialog: MatDialog,
        @Inject(SnackbarService) public snackbarService: SnackbarService,
        @Inject(MatBottomSheet) public matBottomSheet: MatBottomSheet,
        @Inject(ReportApiService) public reportApiService: ReportApiService,
        @Inject(ScheduleApiService) public scheduleApiService: ScheduleApiService,
        @Inject(ReportDataWizard) public reportDataWizard: ReportDataWizard,
        @Inject(SharedWizardData) public sharedWizardData: SharedWizardData,
        @Inject(ReportTemplatesDataService) public reportTemplatesDataService: ReportTemplatesDataService,
        @Inject(Router) public router: Router,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
        @Inject(MobileViewService) public mobileViewService: MobileViewService
    ) {
        this.THEMES = AppConstants.THEMES;
        this.currentUser = userAccessHandler.getUserAccess();
        this.mobileViewService.setBreakpoint(MobileViewService.MOBILE_BREAKPOINT_LARGE);
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.conceptID = +params['conceptID'];
            this.conceptName = params['displayName'];
        });
        this.sharedWizardData.conceptID = this.conceptID;
        this.sharedWizardData.conceptName = this.conceptName;
        this.sharedWizardData.save();
        this.refreshJobs = timer(2000, 1000)
            .pipe(take(this.counter))
            .subscribe((x) => {
                if (!this.hasJobsPending) {
                    return;
                }
                this.counter++;
                if (this.counter >= this.delay) {
                    this.counter = 0;
                    this.getJobsByConceptId(false);
                }
            });
        this.getJobsByConceptId(true);
        this.getReportTemplates();
        this.getReportSchedules();
        this.getLongCaptions();
        this.reportTabIdx = this.sharedWizardData.reportTabIdx ? this.sharedWizardData.reportTabIdx : 0;
    }
    ngOnDestroy(): void {
        if (this.refreshJobs) this.refreshJobs.unsubscribe();
    }
    cleanTooltip() {
        if (this.lastItemPassed) {
            this.lastItemPassed.tooltip = false;
        }
    }

    // This function is used for #reportTooltip
    toggleTooltipAndAutoHide() {
        this.reportTooltip.toggle(); // Toggle the tooltip

        // Clear any existing auto-hide timeout
        if (this.autoHideTimeout) {
            clearTimeout(this.autoHideTimeout);
        }

        // If the tooltip is shown, auto-hide it after 3 seconds
        // _isTooltipVisible() return false meaning that the tooltip is shown and vice versa
        if (!this.reportTooltip._isTooltipVisible()) {
            this.autoHideTimeout = setTimeout(() => {
                this.reportTooltip.hide();
            }, 3000); // 3000 milliseconds
        }
    }

    // clear time out set by toggleTooltipAndAutoHide()
    // allow user to hover over tooltip without it disappearing
    hoverTooltipAndClearTimeOut() {
        this.reportTooltip.show();
        if (this.autoHideTimeout) {
            clearTimeout(this.autoHideTimeout);
        }
    }

    getJobsByConceptId(spin) {
        this.reportApiService.getJobsByConceptId(this.conceptID).then((jobs) => {
            this.jobs = jobs;
            if (this.jobs.length == 0) {
                this.showDrawer = true;
            }
            if (spin) {
                this.delay = 1;
                for (let i = 0; i < this.jobs.length; i++) {
                    if (this.jobs[i].status == 'Pending') {
                        this.hasJobsPending = true;
                        break;
                    }
                }
            }
            if (!spin) {
                for (let k = 0; k < this.jobs.length; k++) {
                    if (this.jobs[k].status == 'Pending') {
                        for (let j = 0; j < this.jobs.length; j++) {
                            if (jobs[j].id == this.jobs[k].id && jobs[j].status != this.jobs[k].status) {
                                this.jobs[k] = jobs[j];
                            }
                        }
                    }
                }
                this.hasJobsPending = false;
                for (let l = 0; l < this.jobs.length; l++) {
                    if (this.jobs[l].status == 'Pending') {
                        this.hasJobsPending = true;
                        break;
                    }
                }
                if (this.delay < 30) {
                    this.delay++;
                }
            }
        });
    }

    getLongCaptions() {
        let language = this.appSettings.getLanguage();
        this.utilsApiService.getLongCaptions(language, 'REPORT').then((reportLongCaptions) => {
            this.reportDescriptions = reportLongCaptions;
        });
    }

    tooltip(itemPassed, itemType) {
        itemPassed.tooltip = !itemPassed.tooltip;
        if (this.lastItemPassed && this.lastItemPassed != itemPassed) this.lastItemPassed.tooltip = false;
        this.lastItemPassed = itemPassed;
        if (itemType == 'job') {
            this.setStoreNames(itemPassed, 'job');
            itemPassed.tooltip = false;
        } else if (itemType == 'schedule') {
            this._scheduleInfo = [];
            this.setStoreNames(itemPassed, 'schedule');
            this._scheduleInfo.push({ caption: this.captions.scheduleName, value: itemPassed.scheduleName });
            // Parse the 'parameters' object into a JSON object and pass them to get parsed. Object[0] is id1001(startDate), object[1] is id1002(endDate);
            this._scheduleInfo.push({
                caption: this.captions.reportStartDate,
                value: this.getDates(JSON.parse(itemPassed.reportParams).parameters[0].value),
            });
            this._scheduleInfo.push({
                caption: this.captions.reportEndDate,
                value: this.getDates(JSON.parse(itemPassed.reportParams).parameters[1].value),
            });
            this._scheduleInfo.push({
                caption: this.captions.reportScheduleInfo,
                value: this.getRecurringInfo(JSON.parse(itemPassed.scheduleParams)),
            });

            // We put this in here to preserve the order of the items displayed. This value gets filled in the 'setStoreNames' function
            this._scheduleInfo.push({
                caption: this.captions.stores,
                value: '',
                isstores: true,
            });

            this._scheduleInfo.push({
                caption: this.captions.lastExecuted,
                value: itemPassed.lastExecute ? this.getDates(itemPassed.lastExecute) : this.captions.scheduleHasntRan,
            });
            this._scheduleInfo.push({
                caption: this.captions.reportNextExecution,
                value: this.getDates(itemPassed.executeNext),
            });
            this.scheduleInfo = this._scheduleInfo;
            // This weird line here removes the delay for the 'Stores' value
            itemPassed.tooltip = false;
            this.showSchduleInfo = true;
        }
    }

    scheduledTooltip(itemPassed) {
        this._scheduleInfo = [];
        this.setStoreNames(itemPassed, 'schedule');
        this._scheduleInfo.push({ caption: this.captions.scheduleName, value: itemPassed.scheduleName });

        //**Depreciated Start and end date from display */
        // Parse the 'parameters' object into a JSON object and pass them to get parsed. Object[0] is id1001(startDate), object[1] is id1002(endDate);
        // this._scheduleInfo.push({
        //     caption: this.captions.reportStartDate,
        //     value: this.getDates(JSON.parse(itemPassed.reportParams).parameters[0].value),
        // });
        // this._scheduleInfo.push({
        //     caption: this.captions.reportEndDate,
        //     value: this.getDates(JSON.parse(itemPassed.reportParams).parameters[1].value),
        // });
        /* end */

        this._scheduleInfo.push({
            caption: this.captions.reportScheduleInfo,
            value: this.getRecurringInfo(JSON.parse(itemPassed.scheduleParams)),
        });

        // We put this in here to preserve the order of the items displayed. This value gets filled in the 'setStoreNames' function
        this._scheduleInfo.push({
            caption: this.captions.stores,
            value: '',
            isstores: true,
        });

        this._scheduleInfo.push({
            caption: this.captions.lastExecuted,
            value: itemPassed.lastExecute ? this.getDates(itemPassed.lastExecute) : this.captions.scheduleHasntRan,
        });
        this._scheduleInfo.push({
            caption: this.captions.reportNextExecution,
            value: this.getDates(itemPassed.executeNext),
        });

        this.scheduleInfo = this._scheduleInfo;
        this.dialog.open(ReportTooltipComponent, {
            data: {
                scheduleInfo: this._scheduleInfo,
            },
        });
    }

    getDates(givenDate) {
        if (givenDate.match(/[a-z]/)) {
            switch (givenDate) {
                case 'currentDay':
                    return this.captions.current_day_front;

                case 'previousDay':
                    return this.captions.previous_day_front;

                case 'currentWeek':
                    return this.captions.current_week_front;

                case 'previousWeek':
                    return this.captions.previous_week_front;

                case 'currentMonth':
                    return this.captions.current_month_front;

                case 'previousMonth':
                    return this.captions.previous_month_front;
            }
        } else {
            return moment(givenDate, 'YYYYMMDD').format('YYYY-MM-DD');
        }
    }

    getRecurringInfo(scheduleParams) {
        let recurringString = '';

        // Object containing days with respective IDs
        let days = [
            { id: '1', name: this.captions.monday },
            { id: '2', name: this.captions.tuesday },
            { id: '3', name: this.captions.wednesday },
            { id: '4', name: this.captions.thursday },
            { id: '5', name: this.captions.friday },
            { id: '6', name: this.captions.saturday },
            { id: '7', name: this.captions.sunday },
        ];

        // Function finds a matching ID given a day, returns name value of said ID.
        let getDayName = (value) => days.find((day) => day.id == value).name;

        // Function determins appropriate caption based on value
        let checkLast = (value) =>
            value == 'last' ? this.captions.onTheLast.toLowerCase() : this.captions.onTheFirst.toLowerCase();

        // Object containing timezones for user display
        let timezones = [
            { id: 'HAST', value: 'UTC-10' },
            { id: 'AKST', value: 'UTC-9' },
            { id: 'PST', value: 'UTC-8' },
            { id: 'MST', value: 'UTC-7' },
            { id: 'CST', value: 'UTC-6' },
            { id: 'EST', value: 'UTC-5' },
            { id: 'AST', value: 'UTC-4' },
            { id: 'UTC', value: 'UTC' },
            { id: 'UTC+2', value: 'UTC+2' },
            { id: 'UTC+3', value: 'UTC+3' },
            { id: 'UTC+4', value: 'UTC+4' },
            { id: 'UTC+5', value: 'UTC+5' },
            { id: 'UTC+6', value: 'UTC+6' },
            { id: 'UTC+7', value: 'UTC+7' },
        ];

        // Object contain strings like: 'of every x month(s)'
        let ofEvery = [
            { id: '1', name: this.captions.ofEvery + ' ' + this.captions.monthFront.toLowerCase() },
            { id: '2', name: this.captions.ofEvery + ' 2 ' + this.captions.monthsFront.toLowerCase() },
            { id: '3', name: this.captions.ofEvery + ' 3 ' + this.captions.monthsFront.toLowerCase() },
            { id: '4', name: this.captions.ofEvery + ' 4 ' + this.captions.monthsFront.toLowerCase() },
        ];

        if (scheduleParams.repeats === 'weekly') {
            recurringString =
                this.captions.recursEvery + ' ' + scheduleParams.list.map((value) => getDayName(value)).join(', ');
            // Outputs: "Recurs every Monday, Tuesday, Wednesday"
        } else {
            recurringString = this.captions.recurs + ' ' + checkLast(scheduleParams.recur) + ' ';

            /** If day is 0, that means no days are involved, all you're getting is "[first,last] day of every [2,3,4] month(s)"
            If day is anything but 0, then you get: "[first,last] [Monday-Friday] of every..."**/
            recurringString +=
                scheduleParams.day === 0 ? this.captions.dayFront.toLowerCase() : getDayName(scheduleParams.day);

            recurringString += ' ' + ofEvery.find((x) => x.id == scheduleParams.month).name;

            // Outputs: "Recurs on the [first, last] [Monday-Friday] of every [2,3,4] month(s)"
        }

        // We add the time and timezone
        recurringString +=
            ' ' +
            this.captions.at.toLowerCase() +
            ' ' +
            scheduleParams.time +
            ' (' +
            timezones.find((timezone) => timezone.value == scheduleParams.timezone).id +
            ')';
        return recurringString;
    }

    setStoreNames(itemPassed, itemType) {
        if (itemType == 'job') {
            this.reportApiService.getStoreNamesFromJobID(this.conceptID, itemPassed.id).then((jobInfo) => {
                let jInfo = jobInfo as any;
                itemPassed.startDate = moment(jobInfo[0], 'YYYYMMDD').format('YYYY-MM-DD');
                itemPassed.endDate = moment(jobInfo[1], 'YYYYMMDD').format('YYYY-MM-DD');
                itemPassed.storeNames = jInfo.slice(2);
                itemPassed.tooltip = true;

                this.jobInfoTooltipDesc[itemPassed.id] =
                    `${this.captions.startDate}: ${itemPassed.startDate}
                    ${this.captions.endDate}: ${itemPassed.endDate}
                    ${this.captions.lastModified}: ${itemPassed.lastModified}

                    ${this.captions.stores + ': ' + itemPassed.storeNames.join(', ')}`;
            });
        } else if (itemType == 'schedule') {
            this.scheduleApiService.getStoreNames(itemPassed).then((storeNamesArray) => {
                this.scheduleInfo[2].value = storeNamesArray;
                itemPassed.tooltip = true;
            });
        }
    }

    getJobInfoTooltipDesc(itemPassed: any): string {
        return this.jobInfoTooltipDesc[itemPassed.id];
    }

    resetJobInfoTooltipDesc(itemPassed: any) {
        this.jobInfoTooltipDesc[itemPassed.id] = '';
    }

    openReportJob(job) {
        if (job.status == 'Done') {
            this.router.navigate(['reports/viewReport', this.conceptID, this.conceptName, job.displayName, job.id], {
                queryParams: {
                    isReady: true,
                },
            });
        } else {
            this.snackbarService.errorMessageTop(this.captions.failedReportJob);
        }
    }

    openReportSchedule(schedule, ev) {
        if (schedule.errorMessage != null) {
            let errMsg = schedule.errorMessage.toLowerCase();
            if (errMsg.includes('max number of jobs')) {
                this.snackbarService.errorMessageTop(
                    this.captions.limitOf + this.currentUser.limitation.maxJobs + ' ' + this.captions.maxReportJobs
                );
            } else if (errMsg.includes('too large')) {
                this.snackbarService.errorMessageTop(this.captions.scheduleLargeDate);
            } else if (errMsg.includes('invalid currency')) {
                this.snackbarService.errorMessageTop(this.captions.scheduleInvalidCurrency);
            } else if (errMsg.includes('tag users')) {
                this.snackbarService.errorMessageTop(this.captions.scheduleUseGrpItems);
            } else if (errMsg.includes('store users')) {
                this.snackbarService.errorMessageTop(this.captions.scheduleUseTagItems);
            }
        } else if (schedule.lastJobID == null) {
            this.snackbarService.errorMessageTop(this.captions.noJobsForSchedule);
        } else {
            let dialogRef = this.dialog.open(ScheduleJobsDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    captions: this.captions,
                    jobs: this.jobs,
                    conceptID: this.conceptID,
                    conceptName: this.conceptName,
                    selectedSchedule: schedule,
                },
            });
        }
    }

    getReportTemplates() {
        this.reportTemplatesDataService.getTemplates().then((templates) => {
            this.reportTemplates = templates;
            this.templatesLoaded = true;
            this.myReportsToggled = true;
            this.pixelReports = true;
        });
    }

    getReportSchedules() {
        this.scheduleApiService.getAllSchedulesByConceptID(this.conceptID).then((schedules) => {
            if (schedules) {
                this.reportSchedules = schedules;
                let trueTimeZone = { z: '' };

                //Takes in the time from the server (which is UTC, and converts it to the timezone the schedule was meant to run at)
                for (let i = 0; i < this.reportSchedules.length; i++) {
                    this.scheduledTimeZone = this.reportSchedules[i].scheduleParams.match(/UTC[+-][0-9]{1,}|UTC/);
                    this.trueTimeZone = trueTimeZone;

                    if (this.scheduledTimeZone[0] != 'UTC') {
                        this.trueTimeZone.z = this.scheduledTimeZone[0];
                        Object.assign(this.reportSchedules[i], trueTimeZone);
                        this.offset = this.reportSchedules[i].scheduleParams.match(/[+-][0-9]{1,}/);
                        this.sign = this.offset[0].match(/[+-]/);
                        this.difference = this.offset[0].match(/[0-9]{1,}/);
                        this.newDate = this.reportSchedules[i].executeNext;

                        if (this.sign[0] == '+') {
                            this.newDate = moment(this.newDate, 'YYYY-MM-DD HH:mm:ss').add(this.difference[0], 'h');
                            this.reportSchedules[i].executeNext = moment(this.newDate).format('YYYY-MM-DD HH:mm:ss');
                        } else if (this.sign[0] == '-') {
                            this.newDate = moment(this.newDate, 'YYYY-MM-DD HH:mm:ss').subtract(
                                this.difference[0],
                                'h'
                            );
                            this.reportSchedules[i].executeNext = moment(this.newDate).format('YYYY-MM-DD HH:mm:ss');
                        }
                    } else {
                        this.trueTimeZone.z = this.scheduledTimeZone[0];
                        Object.assign(this.reportSchedules[i], trueTimeZone);
                    }
                }
            }
        });
    }

    getReportTemplateById(template) {
        this.drawer.toggle();
        if (this.lastItemPassed) this.lastItemPassed.tooltip = false;
        this.reportApiService.getReportTemplateById(template.id).then((templateObj) => {
            // let descriptionID = this.reportTemplatesDataService.getDescriptionID(template.id, this.reportTemplates);
            // this.showIframe = true;
            this.selectedTemplate = templateObj;
            // let iframe = $('iframe'),
            //     contents = iframe.contents(),
            //     body = contents.find('body'),
            //     head = contents.find('head');
            // if (this.userInfoHandler.getUserInfo().theme === this.THEMES.dark) {
            //     head.append(
            //         $(
            //             "<style type='text/css'> body{background-color: #484848; color:rgba(255, 255, 255, 0.7);}b, h3{color: rgb(255,64,129)!important} </style>"
            //         )
            //     );
            // }
            // let templateDescription = '';
            // for (let description of this.reportDescriptions) {
            //     if (descriptionID === description.id) {
            //         templateDescription = description.caption;
            //     }
            // }
            // body.html(templateDescription);
            this.sharedWizardData.templateID = this.selectedTemplate.id;
            this.sharedWizardData.templateName = this.selectedTemplate.info.displayName;
            this.launchWizard(true);
        });
    }

    toggleCheckboxes() {
        this.toggleCheckbox = !this.toggleCheckbox;
    }

    revertCheckboxes() {
        this.toggleCheckbox = !this.toggleCheckbox;
        this.reportTemplates.customTemplates.map((item) => {
            item.isChecked = false;
        });
    }

    templateSelected(template) {
        this.reportTemplates.customTemplates.map((item) => {
            if (item.id === template.id) item.isChecked = !item.isChecked;
        });
    }

    editSchedule(schedule) {
        this.reportApiService.getReportTemplateById(schedule.reportTemplateID).then((template: any) => {
            var tpl = template as any;
            this.sharedWizardData.templateID = tpl.id;
            this.sharedWizardData.templateName = tpl.info.displayName;
            this.sharedWizardData.conceptID = this.conceptID;
            this.itemStorageService.reportWizard = this.sharedWizardData.caption = this.captions.scheduleWizard;
            this.reportDataWizard.setAllParameters(template?.parameters, false, schedule);
            this.sharedWizardData.save();
            this.router.navigate([
                'reports/wizard',
                this.conceptID,
                this.conceptName,
                {
                    scheduleID: '',
                },
            ]);
        });
    }

    getIframe() {
        this.showJobs = false;
        this.showIframe = true;
        this.showBackButton = true;
        this.showDeleteButton = false;
    }

    getIframeCustom() {
        this.getIframe();
        this.showDeleteButton = true;
    }

    getIframeForMyReports() {
        this.showJobs = false;
        this.showIframe = true;
        this.showBackButton = true;
    }

    showJobList() {
        this.showJobs = true;
        this.showIframe = false;
        this.showBackButton = false;
        this.showDeleteButton = false;
    }

    launchWizard(isNotSchedule) {
        if (isNotSchedule && this.jobs.length >= this.currentUser.limitation.maxJobs) {
            this.snackbarService.errorMessageTop(
                this.captions.limitOf + this.currentUser.limitation.maxJobs + ' ' + this.captions.maxReportJobs
            );
        } else if (
            !isNotSchedule &&
            this.reportSchedules &&
            this.reportSchedules.length >= this.currentUser.limitation.maxSchedules
        ) {
            this.snackbarService.errorMessageTop(
                this.currentUser.limitation.maxSchedules + ' ' + this.captions.maxReportSchedules
            );
        } else {
            this.itemStorageService.reportWizard = this.sharedWizardData.caption = isNotSchedule
                ? this.captions.reportWizard
                : this.captions.scheduleWizard;
            this.sharedWizardData.conceptID = this.conceptID;
            this.reportDataWizard.setAllParameters(this.selectedTemplate.parameters, isNotSchedule);
            this.sharedWizardData.save();
            this.router.navigate([
                'reports/wizard',
                this.conceptID,
                this.conceptName,
                {
                    scheduleID: '',
                },
            ]);
        }
    }

    deleteJobDialog(ev, job) {
        if (this.lastItemPassed) this.lastItemPassed.tooltip = false;
        if (job.scheduleName) job.displayName = job.scheduleName;
        let dialogRef = this.dialog.open(DeleteJobDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                currentJobToDelete: job,
                jobs: this.jobs,
                captions: this.captions,
                conceptID: this.conceptID,
                reportSchedules: this.reportSchedules,
            },
        });
    }

    deleteTemplateDialog(ev) {
        let templatesTodelete = []
        for (let template of this.reportTemplates.customTemplates) {
            if (template.isChecked) {
                templatesTodelete.push(template);
            }
        }
        if (templatesTodelete.length > 0) {
            let dialogRef = this.dialog.open(DeleteTemplateDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    captions: this.captions,
                    selectedTemplates: templatesTodelete,
                    reportTemplates: this.reportTemplates,
                },
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    this.showJobList();
                }
            });
        }
    }

    deleteAllJobsDialog(ev) {
        let dialogRef = this.dialog.open(DeleteAllJobsDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                captions: this.captions,
                conceptID: this.conceptID,
                jobs: this.jobs,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.reportApiService.deleteAllJobsByConceptID(this.conceptID).then(() => {
                    this.getJobsByConceptId(true);
                });
            }
        });
    }

    deleteAllSchedulesDialog() {
        let dialogRef = this.dialog.open(DeleteAllSchedulesDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                captions: this.captions,
                conceptID: this.conceptID,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.scheduleApiService.deleteAllSchedulesByConceptID(this.conceptID).then(() => {
                    this.getReportSchedules();
                });
            }
        });
    }

    onTabChanged(ev) {
        this.reportTabIdx = ev.index;
        this.sharedWizardData.reportTabIdx = this.reportTabIdx;
        this.sharedWizardData.save();
    }

    changedSearchText(searchStr: string) {
        this.searchStr = searchStr;
    }

    filterReports(job, searchStr: string) {
        return !searchStr || job.displayName.toLowerCase().includes(searchStr.toLowerCase());
    }
}
