import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { InventoryCountApiService } from '../services/inventory-counter.resource';
import { InventoryCounterService } from '../services/inventory-counter.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: 'src/app/features/inventory-counter/templates/date-picker.html',
})
export class DatePickerComponent implements OnInit {
  // the date that will be selected
  public selectedDate: Date;
  // the form for the dates
  public form;
  public formBuilder: UntypedFormBuilder = new UntypedFormBuilder();
  public captions: Record<string,string>;

  constructor(
    @Inject(CaptionService) private captionService,
    @Inject(InventoryCountApiService) private inventoryCountApiService: InventoryCountApiService,
    @Inject(InventoryCounterService) private inventoryCounterService,
    @Inject(Router) private router,
  ) {
    this.captions = this.captionService.captions;
    this.form = this.formBuilder.group({ picker: ["", [Validators.required]] });
  }

  ngOnInit(): void {
    // initialize the selected date to be today's date
    this.selectedDate = new Date();
    this.form.get("picker").touched = true;
    this.form.get("picker").setValue(this.selectedDate);
  }

  backClicked(): void {
    this.router.navigate(['inventoryCounter']);
  }

  submitDate(): void {
    // get the date that was selected from the input
    this.selectedDate = new Date(this.form.get("picker").value);
    // call the get service with that date
    this.inventoryCountApiService.getInventoryCountsheetXMLByDate(this.selectedDate).then((res) => {
      this.inventoryCounterService.xmlCountStringCtrl(res);
      this.router.navigate(["inventoryCounter/locations"], { queryParams: { type: "date" /*pass in type here*/ } });
    });
  }
}
