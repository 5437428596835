import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as txml from 'txml';
import { CaptionService } from "../../../utils/shared-services/caption.service";
import { SnackbarService } from "../../../utils/shared-services/snackbar/snackbar.service";
import { StoreApiService } from "../services/store.resource";
import { EoiApiService } from "../../eoi/services/eoi.resource";
import { eoiService as EoiService } from '../../eoi/services/eoi.service';
import { MatDialog } from "@angular/material/dialog";
import { EoiStatusDialog } from "../../eoi/components/dialogs/eoi-status-dialog.component";
import { BasicEoiInfo, EoiInformation, EOIQuickStatus, EOIQuickStatusResponse } from "../../eoi/models/eoi.model";
import { EnableCountsheet } from "../models/enable-countsheet.model";
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'enableCountsheet',
    templateUrl: 'src/app/features/stores/templates/enable-countsheet.html',
})
export class EnableCountsheetComponent {

    private storeID: number;
    private baseURl: URL;
    private fb: FormBuilder = new FormBuilder();
    private eoiInfo: BasicEoiInfo;

    public myForm = this.fb.group({
        pathName: ['', [Validators.required, Validators.maxLength(this.validationService.inventoryStoreIDMaxLength), Validators.minLength(this.validationService.inventoryStoreIDMinLength)]],
    });

    public captions: Record<string, string>;
    public pathName: string = ''; // this string stores the unique path name
    public enabled: boolean = false; 
    public isEditingCountID: boolean = false; // this boolean is true when the user is editing the id
    public countingURL: string = "";
    public eoiStatus: string = 'color-grey';

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(EoiApiService) private eoiApiService: EoiApiService,
        @Inject(EoiService) private eoiService: EoiService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.captions = this.captionService.captions;
        this.storeID = +this.actRoute.snapshot.paramMap.get('storeID');
        this.baseURl = new URL(window.location.toString().replace(this.router.url, "/inventoryCounter"));

        this.eoiApiService.getEoiByID(this.storeID, 7, 8, 1, null).then((eoi: EoiInformation) => {
            this.eoiApiService.getQuickStatus(this.storeID, 15000000).then((eoiStatusResponse: EOIQuickStatusResponse) => {
                const { Response } = txml.simplify(txml.parse(eoiStatusResponse.responseString));
                if (Response?.ErrorNum === '-1') {
                    return;
                }
                const eoiQuickStatus = Response.Status as EOIQuickStatus;
                this.eoiStatus = this.eoiService.getQuickStatusColor(eoiQuickStatus);
                this.eoiInfo = {
                    okToOrder: eoiQuickStatus.OkToOrder,
                    posOnline: eoiQuickStatus.PosOnline,
                    eoiOnline: eoiQuickStatus.EoiOnline,
                    disconnectedTime: eoi?.disconnectedTime,
                }
            });
        });

        this.loadEnableCountsheet();
    }

    ngOnInit(): void {
        this.myForm.get("pathName").disable();
        this.myForm.get("pathName").markAsTouched();

        this.storeApiService.getEnableCountsheet(this.storeID).then((enableCountsheet: EnableCountsheet) => {
            this.enabled = enableCountsheet ? enableCountsheet.isActive === 1 : false;
            if (enableCountsheet?.pathName) {
                this.pathName = enableCountsheet.pathName;
                this.myForm.get("pathName").setValue(this.pathName);
            }
        })
    }

    enableCount(): void {
        let aCountSheet: EnableCountsheet = {
            storeID: this.storeID,
            pathName: this.pathName,
            isActive: 1,
        };
        this.storeApiService.enableOrUpdateCountsheet(aCountSheet).then((res: EnableCountsheet) => {
            this.pathName = res.pathName;
            this.myForm.get("pathName").setValue(this.pathName);
            this.enabled = true;
            this.loadEnableCountsheet();
        });
    }

    disableCount(): void {
        this.storeApiService.disableCountsheet(this.storeID).then(() => {
            this.enabled = false;
        });
    }

    toggleEditCountID(): void {
        this.isEditingCountID = !this.isEditingCountID;
        this.isEditingCountID ? this.myForm.get('pathName').enable() : this.myForm.get('pathName').disable();
        this.myForm.get('pathName').setValue(this.pathName);
    }

    // submits the countsheet pathName string to be modified in db
    submitNewCountID(): void {
        if (this.myForm.valid) {
            let aCountSheet: EnableCountsheet = {
                storeID: this.storeID,
                pathName: this.myForm.value.pathName,
                isActive: this.enabled ? 1 : 0,
            }

            try {
                this.storeApiService.enableOrUpdateCountsheet(aCountSheet).then((res: EnableCountsheet) => {
                    this.pathName = res.pathName;
                    this.myForm.get("pathName").setValue(this.pathName);
                    // update the URL/QR Code
                    this.updateCountingURL(this.pathName);
                    // display success snackbar
                    this.snackbarService.successMessageTop(this.captions.success);
                });
            }
            catch (err) {
                console.error(err);
            }
            this.reset();
        }
    }

    eoiStatuses(): void {
        const dialogRef = this.dialog.open(EoiStatusDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                eoiOnline: this.eoiInfo?.eoiOnline,
                posOnline: this.eoiInfo?.posOnline,
                okToOrder: this.eoiInfo?.okToOrder,
                disconnectedtime: this.eoiInfo?.disconnectedTime,
                isStorePortal: false,
                isCountSheet: true
            },
        });

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    private updateCountingURL(aPathName: string): void {
        this.baseURl.searchParams.set('StoreID', aPathName);
        this.countingURL = this.baseURl.toString();
    }

    private loadEnableCountsheet(): void {
        this.storeApiService.getEnableCountsheet(this.storeID).then((enableCountsheet: EnableCountsheet) => {
            if (enableCountsheet) {
                this.updateCountingURL(enableCountsheet.pathName);
                this.enabled = enableCountsheet.isActive === 1;
            }
        });
    }

    private reset(): void {
        this.isEditingCountID = false;
        this.myForm.get("pathName").setValue(this.pathName);
        this.myForm.get("pathName").disable();
    }
}
