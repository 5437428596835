import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { InventoryCounterService } from '../services/inventory-counter.service';
import { Locations } from '../models/inventory-counter.model';

@Component({
    selector: 'countLocations',
    templateUrl: 'src/app/features/inventory-counter/templates/count-locations.html',
})
export class CountLocationsComponent {
    public captions: Record<string,string>;
    public subscriptions: Subscription[] = [];
    public countType: string;
    public countLocations: Locations[] = [];

    @Output() public locationClicked = new EventEmitter();

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(InventoryCounterService) private inventoryCounterService: InventoryCounterService,
    ) {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.subscriptions.push(
            this.inventoryCounterService.countLocations$.subscribe((locations) => {
                this.countType = this.inventoryCounterService.getActiveCountType();
                this.countLocations = locations;
            })
        );
    }

    ngOnInit() { }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    countLocationClicked(countLocation: Location) {
        this.locationClicked.emit(countLocation);
    }
}
