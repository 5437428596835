import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { UserConstants } from '../../../features/users/users.constants';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { HomeApiService } from '../services/home.resource';
import { MaintenanceData } from '../models/maintenance-data.model';
import { Notification } from '../models/maintenance-data.model';

@Component({
    selector: 'home',
    templateUrl: 'src/app/features/home/templates/home.html',
})
export class HomeComponent {
    public captions: Record<string, string>;
    public dashboardIcon: string = '';
    public reportIcon: string = '';
    public orgIcon: string = '';
    private theme: string = '';
    public viewSharedDashOnly: boolean = false;
    public viewOrganzationOnly: boolean = false;

    constructor(
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(HomeApiService) private homeApiService: HomeApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler
    ) {
        this.theme = this.browserStorage.getLocalstorage('appTheme', 'light');

        let currentUserPrivilege = JSON.parse(this.userAccessHandler.getUserAccess().userPrivilege);
        // check for  the viewSharedDashOnly privilege
        for (let priv of currentUserPrivilege) {
            if (priv.name == UserConstants.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY) {
                this.viewSharedDashOnly = true;
            } else if (priv.name == UserConstants.USER_PRIVILEGES.PORTAL_ADMIN || priv.name == UserConstants.USER_PRIVILEGES.PORTAL_USER) 
                this.viewOrganzationOnly = true;
        }
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        if (this.theme == 'light') {
            this.dashboardIcon = 'images/dashboard-icon-light.png';
            this.reportIcon = 'images/report-icon-light.png';
            this.orgIcon = 'images/org-icon-light.png';
        } else {
            this.dashboardIcon = 'images/dashboard-icon-dark.png';
            this.reportIcon = 'images/report-icon-dark.png';
            this.orgIcon = 'images/org-icon-dark.png';
        }

        this.homeApiService.getUserMaintenanceNotifications().then((response: Array<object>) => {
            if (response.length != 0) {
                const dialogRef = this.dialog.open(MaintenanceDialog, {
                    panelClass: 'app-full-bleed-dialog',
                    maxWidth: '90vw',
                    data: {
                        notifications: response,
                    },
                });
            }
        });
    }

    navigate(state: string): void {
        this.router.navigate([state]);
    }
}

@Component({
    selector: 'maintenance-dialog',
    templateUrl: 'src/app/features/home/templates/maintenance-dialog.html',
})
export class MaintenanceDialog {
    public captions: Record<string, string>;
    public notifications: Notification[] = [];
    // public pageSize: number = 10;
    // public lowValue: number = 0;
    // public highValue: number = 10;
    // public pageIndex: number = 0;
    // public currentPage: number = 0;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<MaintenanceDialog>,
        @Inject(CaptionService) private captionService,
        @Inject(HomeApiService) private homeApiService,
        @Inject(MAT_DIALOG_DATA) public data: MaintenanceData
    ) {
        this.captions = this.captionService.captions;
        this.notifications = data.notifications;
    }

    ngOnInit(): void {
        this.populateNotificationTextMessage(this.notifications);
    }

    /* The following code below is for paginator, which needs to be revisited 
  getNotificationData(event: PageEvent): void {
    this.currentPage = event.pageIndex;
    if (event.pageIndex === this.pageIndex + 1) {
      this.lowValue = this.lowValue + this.pageSize;
      this.highValue = this.highValue + this.pageSize;
    }
    else if (event.pageIndex === this.pageIndex - 1) {
      this.lowValue = this.lowValue - this.pageSize;
      this.highValue = this.highValue - this.pageSize;
    }
    this.pageIndex = event.pageIndex;
  }
  */

    dismiss(notification: Notification): void {
        let index = this.findIndexByKeyValue(this.notifications, 'id', notification.id);
        let id = [notification.id];
        this.notifications.splice(index, 1);
        this.homeApiService.dismissNotifications(id);
        this.populateNotificationTextMessage(this.notifications);
    }

    dismissAll(notifications: Notification[]): void {
        let ids = [];
        for (let i = 0; i < notifications.length; i++) {
            ids.push(notifications[i].id);
        }
        this.homeApiService.dismissNotifications(ids);
        this.dialogRef.close();
    }

    findIndexByKeyValue(arrayToSearch: Array<object>, key: string, valuetosearch: number): number {
        for (let i = 0; i < arrayToSearch.length; i++) {
            if (arrayToSearch[i][key] == valuetosearch) {
                return i;
            }
        }
        return null;
    }

    populateNotificationTextMessage(notis: Notification[]): void {
        setTimeout(function() {
            $('.textMessage').each(function (index) {
                $(this).html(notis[index].textMessage);
            });
        }, 1);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
