import { Component, Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedWizardData } from './services/report-shared-wizard-data.service';
import { ReportParamsHandler } from './services/report-params-handler.service';

@Component({
    selector: 'reportWizard',
    template: ''
})

@Injectable()
export class ReportWizardComponent {

    private conceptID: number;
    private conceptName: string;
    private scheduleID: number;
    private reportTemplateName: string;

    constructor(
        @Inject(Router) public router: Router,
        @Inject(ReportParamsHandler) public reportParamsHandler: ReportParamsHandler,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData) {
    }

    ngOnInit() {
        this.actRoute.params.subscribe(params => {
            this.conceptID = +params['conceptID'];
            this.conceptName = params['displayName'];
            this.reportTemplateName = this.sharedWizardData.templateName;
        });

        this.actRoute.queryParams.subscribe(params => {
            this.scheduleID = +params['scheduleID'];
        });

        if (this.reportParamsHandler.getReportParams()) {
            const singleStoreReports = [123, 138, 139, 140, 141, 142]
            const reportIsSingleStore = singleStoreReports.includes(this.sharedWizardData.templateID)
            const numStoresInSharedData = +this.sharedWizardData.storeList?.length || 0;

            // Redo store selection if there are no stores selected in shared data or...
            // report is for single store and more than one store is selected
            if (numStoresInSharedData <= 0 || reportIsSingleStore && numStoresInSharedData > 1) {
                this.router.navigate(["reports/wizard/stores", this.conceptID, this.conceptName, this.reportTemplateName]);
            }
            // Navigate to confirm report job with report params
            else {
                this.router.navigate(["reports/wizard/confirm", this.conceptID, this.conceptName]);
            }
        }
        else {
            this.router.navigate(["reports/wizard/stores", this.conceptID, this.conceptName, this.reportTemplateName]);
        }
    }
}
