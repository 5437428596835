import { Subject } from 'rxjs';

export class ReportEmitterService {
    //all stores report
    private leadLabelChanged = new Subject();
    leadLabelChangedCtrlItem$ = this.leadLabelChanged.asObservable();

    private tagsForReport = new Subject();
    getTagsForReportStoreSelectionItem$ = this.tagsForReport.asObservable();

    private groupItemsForReport = new Subject();
    getGroupItemsForReportStoreSelectionItem$ = this.groupItemsForReport.asObservable();

    private tagsSelected = new Subject();
    reportTagsSelectedItem$ = this.tagsSelected.asObservable();

    private submitTags = new Subject();
    triggerSubmitTagsItem$ = this.submitTags.asObservable();

    private groupItemsSelected = new Subject();
    reportGroupItemsSelectedItem$ = this.groupItemsSelected.asObservable();

    private submitGroupItems = new Subject();
    triggerSubmitGroupItemsItem$ = this.submitGroupItems.asObservable();

    private resetTags = new Subject();
    resetTagSelectionLabelItem$ = this.resetTags.asObservable();

    private resetGroupItems = new Subject();
    resetGroupItemSelectionLabelItem$ = this.resetGroupItems.asObservable();

    // report Wizard and all stores report

    private searchToggle = new Subject();
    toggleSearchItem$ = this.searchToggle.asObservable();


    // all stores report
    getTagsForReportStoreSelection(conceptID, userRole) {
        this.tagsForReport.next([conceptID, userRole]);
    }

    getGroupItemsForReportStoreSelection(conceptID) {
        this.groupItemsForReport.next(conceptID);
    }

    reportTagsSelected(tagList) {
        this.tagsSelected.next(tagList);
    }

    triggerSubmitTags(tagList) {
        this.submitTags.next(tagList);
    }

    reportGroupItemsSelected(groupItemList) {
        this.groupItemsSelected.next(groupItemList);
    }

    triggerSubmitGroupItems(groupItemList) {
        this.submitGroupItems.next(groupItemList);
    }

    resetTagSelectionLabel(value) {
        this.resetTags.next(value);
    }

    resetGroupItemSelectionLabel(value) {
        this.resetGroupItems.next(value);
    }

    reportLeadLabelChanged(value) {
        this.leadLabelChanged.next(value);
    }

    //report wizard and all stores report
    toggleSearch(searchStr) {
        this.searchToggle.next(searchStr);
    }

}