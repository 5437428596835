import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { ScheduledUpdatesDialogData } from "../../models/dialog.model";

@Component({
    selector: 'scheduled-updates-dialog',
    templateUrl: 'src/app/features/stores/templates/dialogs/scheduled-updates-dialog.html',
})
export class ScheduledUpdatesDialog {

    public captions: Record<string, string>;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ScheduledUpdatesDialog>,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: ScheduledUpdatesDialogData,
    ) {
        this.captions = this.captionService.captions;
    }
}
