import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CaptionService } from '../../shared-services/caption.service';

interface DialogData {
    concepts: any;
}

@Component({
    selector: 'concept-picker-dialog',
    templateUrl: 'src/app/utils/shared-templates/user-access/concept-picker-dialog.html',
})
export class ConceptPickerDialog {
    public captions: any;
    public concepts;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConceptPickerDialog>
    ) {
        this.captions = this.captionService.captions;
        this.concepts = data.concepts;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    selectConceptBox(selectedConcept) {
        this.dialogRef.close({
            data: {
                selectedConcept: selectedConcept,
            },
        });
    }
}
