import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { DataUtils } from '../../../utils/shared-services/data-utils.service';

@Injectable()
export class RecycleBinApiService {
    private BASE_URL;
    private recycleBinUrl: string = 'recycle-bin';
    private validateDeletionUrl: string = this.recycleBinUrl + '/validateDeletion';

    constructor(@Inject(HttpClient) private http: HttpClient,
        @Inject(DataUtils) private dataUtils: DataUtils) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getRecycledItems() {
        const data$ = this.http.get(this.BASE_URL + this.recycleBinUrl);
        const value = await lastValueFrom(data$);
        return value;
    }

    async restoreItems(itemsToRestore) {
        let validItems = [] as any;
        for (let item of itemsToRestore) {
            validItems.push(
                this.dataUtils.getCleanedObjectFields(item, ['id', 'displayName', 'type', 'lastChangedUTC'])
            );
        }
        const data$ = this.http.post(this.BASE_URL + this.recycleBinUrl, validItems);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getListOfInactiveStoresForConcepts(concepts) {
        let validItems = [] as any;
        for (let concept of concepts) {
            validItems.push(
                this.dataUtils.getCleanedObjectFields(concept, ['id', 'displayName', 'type', 'lastChangedUTC'])
            );
        }
        const data$ = this.http.post(this.BASE_URL + this.validateDeletionUrl, validItems);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteRecycledItems(recycledItems) {
        let validItems = [] as any;
        for (let recycledItem of recycledItems) {
            validItems.push(
                this.dataUtils.getCleanedObjectFields(recycledItem, ['id', 'displayName', 'type', 'lastChangedUTC'])
            );
        }
        const data$ = this.http.delete(this.BASE_URL + this.recycleBinUrl, {
            headers: { 'Content-Type': 'application/json' },
            body: validItems,
        });
        const value = await lastValueFrom(data$);
        return value;
    }
}