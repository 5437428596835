import { Component, Input } from '@angular/core';
import { WidgetConstants } from '../../../constants/widget.constants';

@Component({
    selector: 'table-legend',
    templateUrl: 'src/app/features/store-portal/widgets/charts/templates/table-legend.html',
})
export class TableLegend {
    @Input() public labels: string[];
    @Input() public datasets: any[];
    @Input() public headers: string[];
    @Input() public hasColors: boolean = true;
    @Input() public colors: any = WidgetConstants.CHART_COLORS_DEFAULT;

    constructor() { }

    ngOnInit() { }
}
