import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SnackbarService } from "../../../../utils/shared-services/snackbar/snackbar.service";


@Component({
    selector: "confirm-price-dialog",
    templateUrl: "./src/app/features/price-change/templates/dialogs/confirm-dialog.component.html",
})
export class ConfirmPriceChangeDialog implements OnInit {

    private fb: FormBuilder = new FormBuilder();
    formGroup: FormGroup;
    public captions: Record<string, string>;
    public priceChangeCount
    public confirmValue;


    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<ConfirmPriceChangeDialog>,
        @Inject(MAT_DIALOG_DATA) public data,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,

    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit(): void {
        this.priceChangeCount = this.data.changedPricesCount;
        this.formGroup = this.fb.group({
            confirm: ["", Validators.required]
        });

    }

    onSubmit() {
        if (this.formGroup.get('confirm')?.value !== 'Confirm') {
            this.snackbarService.successMessageBottom("Incorrect value entered, please try again.");
            this.formGroup.get('confirm')?.setValue('');
        } else {
            this.dialogRef.close({
                confirm: this.formGroup.get('confirm')?.value
            });
        }

    }

    onClose() {
        this.dialogRef.close();
    }

}