import { Component, Inject, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../../app.constants';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { TagApiService } from '../services/tag.resource';
import { ValidationService } from '../../../utils/shared-services/validation.service';
import { Tag } from '../models/tag.model';
import { NgForm } from '@angular/forms';

export interface DialogData {
    currentTagToDeleteDependentStoresDialog: any;
    conceptDisplayNameDialog: any;
}

@Component({
    selector: 'tagsManage',
    templateUrl: 'src/app/features/tags/templates/tags-manage.html'
})

export class TagsComponent {
    @ViewChild('form')
    public form: NgForm;

    private MAX_TAGS: number;
    public tags: any[];
    public newTag: any;
    public showTagOptions = true;
    public editNameBackup: any;
    public currentTagToDelete: any;
    public counter: number;
    public currentTagToDeleteDependentStores: any[];
    public captions: { limitOf: any; tagsCreated: any; ok: string; };
    public conceptDisplayName: any;
    @Input() public searchStr: any;

    passedConceptId: number;
    conceptName: any;


    constructor(
        @Inject(TagApiService) private tagApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.MAX_TAGS = AppConstants.MAX_TAGS;
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe(params => {
            this.passedConceptId = +params['conceptID'];
            this.conceptName = params['displayName']
        });
        this.editNameBackup = '';
        this.getAllTagsForConcept(this.passedConceptId);
    }

    getAllTagsForConcept(conceptId: number) {
        this.tagApiService.getAllTagsForConcept(conceptId).then((tags: any) => {
            for (let tag of tags) {
                tag.currentEdit = false;
            }
            this.tags = tags;
        })
            .catch((response) => {
                this.snackbarService.errorMessageBottom(response.error.reason);
            });
        this.newTag = {
            displayName: '',
            conceptID: this.passedConceptId,
        };
    }

    createTag() {
        if (this.tags.length >= this.MAX_TAGS) {
            this.snackbarService.errorMessageTop(this.captions.limitOf + this.MAX_TAGS + this.captions.tagsCreated);
        } else {
            this.tagApiService.createTag(this.newTag).then((value: Tag) => {
                let newTag = {
                    id: value.id,
                    displayName: value.displayName,
                    currentEdit: false
                };
                this.tags.push(newTag);
            })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
        this.newTag.displayName = '';
        this.form.resetForm();
    }

    startTagEdit(tag: Tag) {
        this.showTagOptions = false;
        tag.currentEdit = true;
        this.editNameBackup = tag.displayName;
    }

    updateTag(tag: Tag) {
        if (tag && tag.displayName) {
            this.tagApiService.updateTag(tag).then(() => {
                this.showTagOptions = true;
                tag.currentEdit = false;
            });
        }
    }

    revertEdit(tag: Tag) {
        this.showTagOptions = true;
        tag.currentEdit = false;
        tag.displayName = this.editNameBackup;
    }

    deleteTagDialog(ev: any, tag: Tag) {
        this.tagApiService.getTagInfoById(tag.id).then((storesForTag: any) => {
            this.currentTagToDeleteDependentStores = storesForTag;
            this.currentTagToDelete = tag;

            const dialogRef = this.dialog.open(DeleteTagDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    currentTagToDeleteDependentStoresDialog: this.currentTagToDeleteDependentStores,
                    conceptDisplayNameDialog: this.conceptDisplayName,
                },
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    this.deleteTag();
                    this.tags.filter(obj => obj !== tag);
                }
            });
        });
    }

    deleteTag() {
        if (typeof this.currentTagToDelete !== 'undefined') {
            this.tagApiService.deleteTag(this.currentTagToDelete.id).then(() => {
                this.counter = 0;
                for (let tag of this.tags) {
                    if (this.currentTagToDelete.id == tag.id) {
                        this.tags.splice(this.counter, 1);
                    }
                    this.counter++;
                }
                this.currentTagToDelete = {};
                this.currentTagToDeleteDependentStores = [];
            });
        }
    }
    changedSearchText(searchStr: string) {
        this.searchStr = searchStr;
    }

    filterTags(editTag: Tag, searchStr: string) {
        return !searchStr || editTag.displayName.toLowerCase().includes(searchStr.toLowerCase());
    }

    exit() {
        this.router.navigate(['concepts/allConcepts']);
    }
}

@Component({
    selector: 'delete-dialog',
    templateUrl: 'src/app/features/tags/templates/deleteDialog.html',
})
export class DeleteTagDialog {
    public captions: any;
    public currentTagToDeleteDependentStores: any;
    public conceptDisplayName: any;
    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<DeleteTagDialog>,
        @Inject(CaptionService) private captionService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
        this.captions = this.captionService.captions;
        this.currentTagToDeleteDependentStores = data.currentTagToDeleteDependentStoresDialog;
        this.conceptDisplayName = data.conceptDisplayNameDialog;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}