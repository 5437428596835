import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { Feedback } from '../models/feedback.model';

@Injectable()
export class FeedbackApiService {
    private feedbackUrl: string = 'feedback/form';
    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async submitFeedbackForm(feedback: Feedback): Promise<object> {
        let url = this.BASE_URL + this.feedbackUrl;
        const data$ = this.http.post(url, feedback);
        const value = await lastValueFrom(data$);
        return value;
    }
}