import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../../app.constants';

export interface Option {
    id: string,
    name: string,
}

export interface Vendor {
    apiConsumerDisplayName: string,
    apiConsumerID: number,
    apiPackageID: number,
    storeID?: number,
    conceptID?: number,
    hasToken?: boolean,
    isActive?: boolean,
    enabled?: boolean,
    disabled?: boolean,
    isGeneric?: boolean,
    genericOption?: string[] //array of Option ids
    isTokenActive?: number,
    readToken?: string,
    subFamily?: string | null,
    currentEdit?: boolean    
}

@Injectable()
export class VendorApiService {
    private getVendorUrl: string = 'vendor-authorization';
    private BASE_URL: string;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getVendorsByConceptIdPackageId(conceptID: number, packageID: number): Promise<Vendor[]> {
        const url = this.BASE_URL + this.getVendorUrl + '/concept';
        const data$ = this.http.get(url, { params: { conceptID: conceptID, apiPackageID: packageID } });
        const value = await lastValueFrom(data$) as Vendor[];
        return value;
    }

    async getVendorsByStoreIdPackageId(storeID: number, packageID: number): Promise<Vendor[]> {
        const url = this.BASE_URL + this.getVendorUrl + '/store';
        const data$ = this.http.get(url, { params: { storeID: storeID, apiPackageID: packageID } });
        const value = await lastValueFrom(data$) as Vendor[];
        return value;
    }

    async updateVendor(vendors: Vendor[], vendorType: string) {
        const url = this.BASE_URL + this.getVendorUrl + '/' + vendorType;
        const validVendors = this.getValidVendorsForApi(vendors, vendorType);
        const data$ = this.http.put(url, validVendors)
        const value = await lastValueFrom(data$);
        return value;
    }

    getValidVendorsForApi(vendors: Vendor[], vendorType: string) {
        let validVendorFields;
        validVendorFields = vendorType === 'concept' ? ['conceptID'] : ['storeID'];
        validVendorFields.push(
            'apiPackageID',
            'apiConsumerID',
            'readToken',
            'apiConsumerDisplayName',
            'isTokenActive',
            'subFamily'
        );

        let validVendors = [] as any;
        for (let vendor of vendors) {
            let validVendor = {};
            for (let prop in vendor) {
                if (vendor.hasOwnProperty(prop) && validVendorFields.indexOf(prop) >= 0) {
                    if (
                        typeof vendor[prop] === 'boolean' ||
                        typeof vendor[prop] == 'number' ||
                        typeof vendor[prop] == 'string' ||
                        vendor[prop] === null
                    ) {
                        validVendor[prop] = vendor[prop];
                    } else if (typeof vendor[prop] === 'object' && prop == 'apiConsumer') {
                        validVendor[prop] = {};
                    }
                }
            }
            validVendors.push(validVendor);
        }
        return validVendors;
    }
}
