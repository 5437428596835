export const environment = {
    isProd() {
        let isProd = false;
        let env;
        let hostNameParts = window.location.hostname.split('.');
        for (let part of hostNameParts) {
            if (part == 'localhost') {
                env = part;
                break;
            }
            else if (part == 'ci') {
                env = part;
                break;
            }
            else if (part == 'qa') {
                env = part;
                break;
            } else if (part == 'prod') {
                env = part;
                isProd = true;
                break;
            } else {
                env = "PPHQ";
                isProd = true;
                break;
            }
        }
        return isProd;
    }
};