import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { StorePortalApiService } from '../../services/store-portal.resource';
import { WidgetConstants } from "../../constants/widget.constants";

@Component({
    selector: 'widgetDescriptionDialog',
    templateUrl: 'src/app/features/store-portal/templates/dialogs/widget-description-dialog.html',
})
export class WidgetDescriptionDialog {
    public captions: any;
    public descriptionHTML: string;
    public helpURL: string;

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<WidgetDescriptionDialog>,
        @Inject(MAT_DIALOG_DATA) private data: any,
        @Inject(CaptionService) private captionService,
        @Inject(StorePortalApiService) private storePortalApiService: StorePortalApiService,
    ) {
        this.captions = this.captionService.captions;
        this.helpURL = WidgetConstants.WIDGET_DESCRIPTION_URL + this.data.widgetID + ".html";
        if(this.data.descriptionHTML) {
            this.descriptionHTML = this.data.descriptionHTML;
            return;
        }
        this.storePortalApiService.getWidgetDescriptionHTMLByID(this.data.widgetID).then((res) => {
            this.descriptionHTML = res;
        });
    }
}
