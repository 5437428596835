import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstants } from '../../../../app.constants';
import { ConceptApiService } from '../../../../features/concepts/services/concept.resource';
import { StoreGroupMembersApiService } from '../../../../features/store-groups/services/store-group-member.resource';
import { StoreApiService } from '../../../../features/stores/services/store.resource';
import { TagApiService } from '../../../../features/tags/services/tag.resource';
import { UserAccessHandler } from '../../../../features/users/services/user-access-handler.service';
import { UserConstants } from '../../../users/users.constants';
import { ScheduleApiService } from '../../services/report-schedules.resource';
import { ReportApiService } from '../../services/reports.resource';
import { ReportParamsHandler } from './report-params-handler.service';
import { SharedWizardData } from './report-shared-wizard-data.service';

@Injectable()
export class ReportDataWizard {
    private USER_ACCESS = UserConstants.USER_ACCESS;
    private FLAT_SEVEN_DAY_LABOUR = AppConstants.FLAT_SEVEN_DAY_LABOUR;

    public MAX_PARAMS_PER_STEP;
    public STEP_TYPE;
    public timezones;
    public user;
    public information;
    public currentStep;
    jobParams: any[];

    constructor(
        @Inject(ReportApiService) private reportApiService: ReportApiService,
        @Inject(ScheduleApiService) private scheduleApiService: ScheduleApiService,
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(ReportParamsHandler) private reportParamsHandler: ReportParamsHandler,
        @Inject(Router) private router: Router
    ) {
        //configs
        this.MAX_PARAMS_PER_STEP = 5;
        this.STEP_TYPE = {
            STORE_LIST: 'storeList',
            DATE: 'date',
            CONFIRM: 'confirm',
        };
        //timezones
        this.timezones = [
            {
                id: 'HAST',
                value: 'UTC-10',
            },
            {
                id: 'AKST',
                value: 'UTC-9',
            },
            {
                id: 'PST',
                value: 'UTC-8',
            },
            {
                id: 'MST',
                value: 'UTC-7',
            },
            {
                id: 'CST',
                value: 'UTC-6',
            },
            {
                id: 'EST',
                value: 'UTC-5',
            },
            {
                id: 'AST',
                value: 'UTC-4',
            },
            {
                id: 'UTC',
                value: 'UTC',
            },
            {
                id: 'UTC+2',
                value: 'UTC+2',
            },
            {
                id: 'UTC+3',
                value: 'UTC+3',
            },
            {
                id: 'UTC+4',
                value: 'UTC+4',
            },
            {
                id: 'UTC+5',
                value: 'UTC+5',
            },
            {
                id: 'UTC+6',
                value: 'UTC+6',
            },
            {
                id: 'UTC+7',
                value: 'UTC+7',
            },
        ];

        this.user = this.userAccessHandler.getUserAccess();
        this.jobParams =  reportParamsHandler.getJobParams().parameters;
        //private
    }

    setAllParameters(parameters, scheduleDone = true, scheduleParameters = null) {
        this.jobParams = [];
        this.sharedWizardData.delete();
        let paramsInStep = [];
        let dateDone = false;
        let reportParams = this.reportParamsHandler.getReportParams();
        let reportVars = null,
            scheduleVars = null;
        let scheduleID = scheduleParameters && !scheduleDone ? scheduleParameters.id : null;
        // Pre-Set Schedule Params
        if (scheduleID) {
            reportVars = JSON.parse(scheduleParameters.reportParams);
            scheduleVars = JSON.parse(scheduleParameters.scheduleParams);
            this.sharedWizardData.templateName = scheduleParameters.scheduleName;
            this.sharedWizardData.sendOut = scheduleParameters.sendOut ? scheduleParameters.sendOut : null;
        }
        for (let param of parameters) {
            if (param.parameterType === 'datepicker') {
                // ReportParams loaded here
                if (reportParams !== null && scheduleDone) {
                    if (param.id === 1001 && reportParams.startDate != null) {
                        if (this.sharedWizardData.templateID !== this.FLAT_SEVEN_DAY_LABOUR) {
                            param.value = new Date(reportParams.startDate);
                        } else {
                            param.value =
                                reportParams.endDate != null
                                    ? new Date(new Date(reportParams.endDate).getTime() - 6 * 24 * 60 * 60 * 1000)
                                    : new Date(Date.now() - 6 * 24 * 60 * 60 * 1000);
                        }
                    } else if (param.id === 1002 && reportParams.endDate != null) {
                        param.value = new Date(reportParams.endDate);
                    }
                    // TODO: LOAD SCHEDULE PARAMS FROM REPORTPARAMS FOR DATE STEP
                } else if (!scheduleDone) {
                    if (param.id === 1001) {
                        param.parameterType = 'dateschedule';
                        param.value = null;
                    } else if (param.id === 1002) {
                        param.parameterType = 'dateschedule';
                        param.value = null;
                    }
                } else {
                    param.value =
                        param.id === 1001 && this.sharedWizardData.templateID === this.FLAT_SEVEN_DAY_LABOUR
                            ? new Date(Date.now() - 6 * 24 * 60 * 60 * 1000)
                            : new Date();
                }
                paramsInStep.push(param);
            } else if (param.parameterType === 'dropdown') {
                paramsInStep.push(param);
            } else if (param.parameterType === 'storelist') {
                if (paramsInStep.length > 0 && !dateDone) {
                    this.jobParams.push({
                        params: paramsInStep,
                        type: this.STEP_TYPE.DATE,
                    });
                    paramsInStep = [];
                    dateDone = true;
                }
                if (param.id === 2001) {
                    if (this.user.role === this.USER_ACCESS.STORE) {
                        this.sharedWizardData.groupItemList = null;
                        this.sharedWizardData.tagList = null;
                        this.sharedWizardData.storeList = [this.user.roleItem];
                        param.value = this.user.roleItem.id.toString();
                        this.sharedWizardData.currencyCode = this.user.roleItem.currencyCode;
                        this.conceptApiService.getConceptById(this.sharedWizardData.conceptID).then((response) => {
                            let concept = response as any;
                            if (this.sharedWizardData.currencyCode != concept.currencyCode) {
                                this.sharedWizardData.localCurrency = this.sharedWizardData.currencyCode;
                                this.sharedWizardData.commonCurrency = concept.currencyCode;
                            } else {
                                this.sharedWizardData.localCurrency = null;
                                this.sharedWizardData.commonCurrency = null;
                            }
                        });
                    } else if (reportParams !== null && reportParams.conceptID === this.sharedWizardData.conceptID) {
                        this.sharedWizardData.groupItemList = reportParams.groupItems;
                        this.sharedWizardData.tagList = reportParams.tags;
                        this.sharedWizardData.storeList = reportParams.stores;
                        param.value = this.sharedWizardData.storeList.map((s) => s.id.toString()).join(',');
                        this.sharedWizardData.currencyCode = reportParams.currency;
                        this.sharedWizardData.localCurrency = reportParams.localCurrency;
                        this.sharedWizardData.commonCurrency = reportParams.commonCurrency;
                    } else {
                        this.sharedWizardData.groupItemList = null;
                        this.sharedWizardData.tagList = null;
                        this.sharedWizardData.storeList = null;
                        param.value = null;
                        this.sharedWizardData.currencyCode = null;
                        this.sharedWizardData.localCurrency = null;
                        this.sharedWizardData.commonCurrency = null;
                    }
                }
                paramsInStep.push(param);
                this.jobParams.push({
                    params: paramsInStep,
                    type: this.STEP_TYPE.STORE_LIST,
                });
            }

            // On-Set Schedule Params
            if (scheduleID && reportVars) {
                reportVars.parameters.some((reportVar) => {
                    if (param.id == reportVar.id) {
                        param.value = reportVar.value;
                        return true;
                    } else return false;
                });
            }
        }
        // Post-Set Schedule Params
        if (scheduleID && scheduleVars && reportVars) {
            // AutoSet scheduleDate based on 'endDate'
            let scheduleDate = reportVars.parameters.find((param) => param.id === 1002);
            if (scheduleDate) {
                let indexOfN = scheduleDate.value.indexOf('End');
                this.sharedWizardData.scheduleDate = scheduleDate.value.substring(
                    0,
                    indexOfN != -1 ? indexOfN : scheduleDate.value.length
                );
            } else {
                this.sharedWizardData.scheduleDate = null;
            }
            // AutoSet stores
            let storeIDList = reportVars.parameters.find((param) => param.id === 2001);
            if (storeIDList) {
                let storeList = [];
                Promise.all(
                    storeIDList.value.split(',').map((storeID) => {
                        this.storeApiService.getStoreById(storeID).then((response) => {
                            let storeObject = response;
                            if (storeObject != null) {
                                storeList.push(storeObject);
                            }
                        });
                    })
                );

                this.sharedWizardData.storeList = storeList;

                if (storeList != null && storeList.length) {
                    // AutoSet Currency
                    let isLocalCurrencyApplicable = true;
                    let sampleLocalCurrency = storeList[0].currencyCode;
                    for (let store of storeList) {
                        if (sampleLocalCurrency != store.currencyCode) isLocalCurrencyApplicable = false;
                    }
                    this.conceptApiService.getConceptById(this.sharedWizardData.conceptID).then((response) => {
                        let concept = response as any;
                        if (isLocalCurrencyApplicable && sampleLocalCurrency != concept.currencyCode) {
                            this.sharedWizardData.localCurrency = sampleLocalCurrency;
                            this.sharedWizardData.commonCurrency = concept.currencyCode;
                        }
                    });
                }
            }
            // ReportVars
            this.sharedWizardData.currencyCode = reportVars.currencyCode;
            this.sharedWizardData.groupItemIDList = reportVars.groupItemIDList;
            this.sharedWizardData.tagIDList = reportVars.tagIDList;

            // Get GroupItemList if there is any groupItemIDs
            let groupItemList = null;
            if (this.sharedWizardData.groupItemIDList != null && this.sharedWizardData.groupItemIDList != '') {
                groupItemList = [];
                Promise.all(
                    this.sharedWizardData.groupItemIDList.split(',').map((groupItemID) => {
                        this.storeGroupMembersApiService.getStoreGroupItemById(groupItemID).then((response) => {
                            let groupItemObject = response;
                            if (groupItemObject != null) {
                                groupItemList.push(groupItemObject);
                            }
                        });
                    })
                );
            }
            this.sharedWizardData.groupItemList = groupItemList;

            // Get tagList if there is any tagIDs
            let tagList = null;
            if (this.sharedWizardData.tagIDList != null && this.sharedWizardData.tagIDList != '') {
                tagList = [];
                Promise.all(
                    this.sharedWizardData.tagIDList.split(',').map((tagID) => {
                        this.tagApiService.getTagById(tagID).then((tagObject) => {
                            if (tagObject != null) {
                                tagList.push(tagObject);
                            }
                        });
                    })
                );
            }
            this.sharedWizardData.tagList = tagList;

            // Schedule Options
            this.sharedWizardData.scheduleParams.repeats = scheduleVars.repeats;
            if (scheduleVars.repeats == 'monthly') {
                this.sharedWizardData.scheduleParams.recurs = scheduleVars.day != '0' ? 'optionTwo' : 'optionOne';
                this.sharedWizardData.scheduleParams.option = {
                    firstOne: scheduleVars.recur,
                    firstTwo: scheduleVars.recur,
                    secondTwo: scheduleVars.day,
                    third: scheduleVars.month,
                };
                this.sharedWizardData.scheduleParams.list = [];
            } else {
                this.sharedWizardData.scheduleParams.option = {
                    firstOne: 'first',
                    firstTwo: 'first',
                    secondTwo: '1',
                    third: '1',
                };
                this.sharedWizardData.scheduleParams.list = scheduleVars.list;
            }
            this.sharedWizardData.scheduleParams.time = scheduleVars.time;
            // TimeZone to translate for USER Friendly viewing.
            this.sharedWizardData.scheduleParams.timezone = this.timezones.find(
                (timezone) => timezone.value == scheduleVars.timezone
            ).id;
            this.sharedWizardData.scheduleParams.reportsToHold = scheduleVars.reportsToHold;
        }

        this.jobParams.push({
            params: null,
            type: this.STEP_TYPE.CONFIRM,
        });
        this.reportParamsHandler.setJobParams(this.jobParams);
    }

    getParameters() {
        let allParams = [];
        let jobParameters = this.reportParamsHandler.getJobParams().parameters
        for (let step of jobParameters) {
            if (step.params != null) {
                allParams = allParams.concat(step.params);
            }
        }
        return allParams;
    }

    getAllParameters() {
        let allParams = this.getParameters(),
            cleanParams = [];
        for (let param of allParams) {
            if (param.parameterType == 'datepicker') {
                if (param.id === 1001) {
                    this.reportParamsHandler.setStartDate(param.value);
                } else if (param.id === 1002) {
                    this.reportParamsHandler.setEndDate(param.value);
                }

                let newValue = new Date(param.value);
                let month = (newValue.getMonth() + 1).toString(),
                    day = newValue.getDate().toString();

                if (month.length < 2) {
                    month = '0' + month;
                }

                if (day.length < 2) {
                    day = '0' + day;
                }

                let newValueString = newValue.getFullYear().toString() + month + day;
                cleanParams.push({
                    id: param.id,
                    value: newValueString,
                });
            } else {
                cleanParams.push({
                    id: param.id,
                    value: param.value,
                });
            }
        }
        return cleanParams;
    }

    convertScheduleParamsToJsonString(object) {
        let scheduleString;

        if (object.repeats == 'weekly') {
            scheduleString = {
                repeats: 'weekly',
                list: object.list,
            };
        } else {
            scheduleString =
                object.recurs == 'optionOne'
                    ? {
                        repeats: 'monthly',
                        recur: object.option.firstOne,
                        day: '1',
                        month: object.option.third,
                    }
                    : {
                        repeats: 'monthly',
                        recur: object.option.firstTwo,
                        day: object.option.secondTwo,
                        month: object.option.third,
                    };
        }

        // Converts the time from 12 hour (12:00 AM) or {24 hour with a missing ':' || a leading '0'} (0:00 || 0000) to a proper 24 hour time (00:00)
        let time = object.time;
        let hours = time.match(/^(\d+):/);
        // If hours is missing
        if (hours != null) {
            hours = Number(hours[1]);
            // If hours is less than or equal to 12, check the _?(AM/PM) conversion
            let AMPM = time.match(/\s?((A|P|a|p)(M|m))$/);
            if (hours <= 12 && AMPM) {
                // Gets Minutes
                let minutes = Number(time.match(/:(\d+)/)[1]);
                AMPM = AMPM[1].toUpperCase();
                if (AMPM == 'PM' && hours < 12) hours = hours + 12;
                if (AMPM == 'AM' && hours == 12) hours = hours - 12;
                let sHours = hours.toString();
                let sMinutes = minutes.toString();
                if (hours < 10) sHours = '0' + sHours;
                if (minutes < 10) sMinutes = '0' + sMinutes;
                time = sHours + ':' + sMinutes;
            }
        } else {
            // If the time length is equal to 4 assume 0000 else assume 000
            time =
                time.length == 4
                    ? time.slice(0, 2) + ':' + time.slice(2, 4)
                    : '0' + time.slice(0, 1) + ':' + time.slice(1, 3);
        }

        // Sets the time accordingly
        scheduleString.time = time;

        // Sets the timezone accordingly (DEFAULT: 'UTC')
        scheduleString.timezone = object.timezone
            ? this.timezones.find((timezone) => timezone.id == object.timezone).value
            : 'UTC';

        // Sets the number of jobs the schedule can hold
        scheduleString.reportsToHold = object.reportsToHold;

        // Stringifies the Object
        return JSON.stringify(scheduleString);
    }

    convertObjectsToString(objects) {
        let ids = [];
        if (objects != null) {
            for (let object of objects) {
                ids.push(object.id);
            }
        }
        return ids.toString();
    }

    /*public method*/
    submitJob() {
        this.sharedWizardData.save();
        // Converts both IDLists into usable Strings
        let groupItemIDList = this.convertObjectsToString(this.sharedWizardData.groupItemList);
        let tagIDList = this.convertObjectsToString(this.sharedWizardData.tagList);
        let isSchedule = false;

        // Creates a different 'job' object based on if Schedule step exists
        // sending empty strings for tags and groups otherwise creates reports on all stores in it rather than selected only
        let job = isSchedule
            ? {
                conceptID: this.sharedWizardData.conceptID,
                reportTemplateID: this.sharedWizardData.templateID,
                scheduleName: this.sharedWizardData.templateName,
                groupItemIDList: '',
                tagIDList: '',
                currencyCode: this.sharedWizardData.currencyCode,
                parameters: this.getAllParameters(),
                scheduleParams: this.convertScheduleParamsToJsonString(this.sharedWizardData.scheduleParams),
                sendOut: this.sharedWizardData.sendOut,
            }
            : {
                conceptID: this.sharedWizardData.conceptID,
                groupItemIDList: '',
                tagIDList: '',
                templateID: this.sharedWizardData.templateID,
                displayName: this.sharedWizardData.templateName,
                currencyCode: this.sharedWizardData.currencyCode,
                parameters: this.getAllParameters(),
            };

        // Saves the params into the reportParamsHandler
        this.reportParamsHandler.setConceptID(job.conceptID);
        if (job.tagIDList !== '') {
            this.reportParamsHandler.setTags(this.sharedWizardData.tagList);
            this.reportParamsHandler.setStores(null);
        } else {
            this.reportParamsHandler.setTags(null);
        }
        if (job.groupItemIDList !== '') {
            this.reportParamsHandler.setGroupItems(this.sharedWizardData.groupItemList);
            this.reportParamsHandler.setStores(null);
        } else {
            this.reportParamsHandler.setGroupItems(null);
        }
        if (job.tagIDList === '' && job.groupItemIDList === '') {
            this.reportParamsHandler.setStores(this.sharedWizardData.storeList);
        }
        this.reportParamsHandler.setCurrency(job.currencyCode);
        this.reportParamsHandler.setCurrencyParams(
            this.sharedWizardData.localCurrency,
            this.sharedWizardData.commonCurrency
        );

        // Runs ScheduleApiService or ReportApiService based on if Schedule step exists
        if (isSchedule) {
            this.scheduleApiService.submitSchedule(job, this.sharedWizardData.scheduleID).then((confirmation) => {
                if (confirmation) {
                    this.router.navigate([
                        'reports/manageReports',
                        this.sharedWizardData.conceptID,
                        this.sharedWizardData.conceptName,
                    ]);
                } else {
                    throw new Error('Failed');
                }
            });
        } else {
            this.reportApiService.submitJob(job).then((confirmation) => {
                let conf = confirmation as any;
                if (confirmation) {
                    this.router.navigate([
                        'reports/viewReport',
                        this.sharedWizardData.conceptID,
                        this.sharedWizardData.conceptName,
                        this.sharedWizardData.templateName,
                        conf.id,
                    ]);
                } else {
                    throw new Error('Failed');
                }
            });
        }
    }
}
