import { Component, Inject, Input } from '@angular/core';
import { CaptionService } from '../../../../../utils/shared-services/caption.service';

@Component({
    selector: 'table-hourly',
    templateUrl: 'src/app/features/store-portal/widgets/charts/templates/table-hourly.html',
})
export class TableHourly {
    public captions: any;

    @Input() public headers: string[];
    @Input() public hourData: any;
    @Input() public dayData: any;
    @Input() public activeHour: any = -1;

    public tensLeadingZero = (i) => i < 10 ? '0' + i : i;

    constructor(
        @Inject(CaptionService) private captionService,
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit() { }
}
