export class ConceptConstants {
    // remove this down the line
    public static DAY_OF_WEEK = {
        SUNDAY: { STRING: 'Sunday', NUM: 0 },
        MONDAY: { STRING: 'Monday', NUM: 1 },
        TUESDAY: { STRING: 'Tuesday', NUM: 2 },
        WEDNESDAY: { STRING: 'Wednesday', NUM: 3 },
        THURSDAY: { STRING: 'Thursday', NUM: 4 },
        FRIDAY: { STRING: 'Friday', NUM: 5 },
        SATURDAY: { STRING: 'Saturday', NUM: 6 }
    }

    public static DAY_OF_WEEK_MAP = {
        0: { STRING: 'Sunday', NUM: 0 },
        1: { STRING: 'Monday', NUM: 1 },
        2: { STRING: 'Tuesday', NUM: 2 },
        3: { STRING: 'Wednesday', NUM: 3 },
        4: { STRING: 'Thursday', NUM: 4 },
        5: { STRING: 'Friday', NUM: 5 },
        6: { STRING: 'Saturday', NUM: 6 }
    }

    public static WEEK_OF_YEAR_MAP = {
        0: { STRING: 'Method 1', NUM: 0 },
        1: { STRING: 'Method 2', NUM: 1 },
        2: { STRING: 'Method 3', NUM: 2 }
    }

    public static WEEK_OF_YEAR = {
        METHOD_1: { STRING: 'Method 1', NUM: 0 },
        METHOD_2: { STRING: 'Method 2', NUM: 1 },
        METHOD_3: { STRING: 'Method 3', NUM: 2 }
    }
}
