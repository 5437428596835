

import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { ConceptApiService } from '../../concepts/services/concept.resource';
import { StoreApiService } from '../../stores/services/store.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserConstants } from '../../users/users.constants';

@Component({
    selector: 'reportConcepts',
    templateUrl: 'src/app/features/reports/templates/reports-concepts.html',
})
export class AllConceptsReportsComponent implements OnInit {
    private USER_ACCESS;
    private USER_PRIVILEGES;

    public currentUser;
    public conceptName;
    public concepts;
    public captions;

    @Input() public searchStr;


    allStores: any;
    allStoresCount: any;
    dataLoaded: boolean;

    constructor(
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) public router: Router,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.USER_PRIVILEGES = UserConstants.USER_PRIVILEGES;
        this.currentUser = userAccessHandler.getUserAccess();
    }

    ngOnInit() {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
        this.conceptApiService.getAllActiveConcepts().then(
            (activeConcepts) => {
                this.dataLoaded = true;
                this.concepts = activeConcepts;
            });
    }
    addOrganization() {
        this.router.navigate(['concepts/addConcept']);
    }

    validateNumOfStores(concept) {
        this.storeApiService.getStoresByConceptId(concept.id).then((stores: any) => {
            this.allStoresCount = stores.length + this.allStoresCount;
            this.userAccessHandler.setStoreCount(this.allStoresCount);
            concept.numStores = stores.length;
        });
        if (concept.numStores !== 0) {
            this.router.navigate(['reports/manageReports', concept.id, concept.displayName]);
        } else {
            this.snackbarService.errorMessageTop(this.captions.limitOf + this.currentUser.limitation.maxConcept + ' ' + this.captions.concepts);
        }
    }

    changedSearchText(searchStr) {
        this.searchStr = searchStr;
    }
}