import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { ValidationService } from '../../../utils/shared-services/validation.service';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { FormValidatorService } from '../../../utils/shared-services/form-validation.service';
import { ConceptApiService } from '../../concepts/services/concept.resource';
import { StoreGroupMembersApiService } from '../../store-groups/services/store-group-member.resource';
import { StoreApiService } from '../../stores/services/store.resource';
import { TagApiService } from '../../tags/services/tag.resource';
import { UserAccessHandler } from '../services/user-access-handler.service';
import { UserInfoHandler } from '../services/user-info-handler.service';
import { UserApiService } from '../services/users.resource';
import { UserConstants } from '../users.constants';

@Component({
    selector: 'addUser',
    templateUrl: 'src/app/features/users/templates/add-user.html',
})
export class AddUserComponent {
    public currentUser;
    public accessType;
    public store;
    public captions;
    public user;
    public selectedUserSecurity;
    public globalUser;
    public email;
    public concept;
    public accessTypeName;
    public storeUser;
    public languages;
    public hidePrivilegeAccess;
    public currentUserInfo;
    public form: UntypedFormGroup;
    private USER_ACCESS;
    private state: any = 'addUser';
    public persistData: boolean;
    public hidePassword: boolean = true;
    passedUserId: string;
    
    constructor(
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(FormValidatorService) private formValidationService: FormValidatorService,
        @Inject(Router) private router: Router,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.languages = this.captionService.languages;
        this.currentUser = this.userAccessHandler.getUserAccess();
        this.currentUserInfo = this.userInfoHandler.getUserInfo();
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        let savedData = this.browserStorage.getSessionstorage('newUserForm', null);
        this.user = {
            email: savedData ? savedData.email : '',
            firstName: savedData ? savedData.firstName : '',
            lastName: savedData ? savedData.lastName : '',
            password: '',
            confirmPassword: '',
            language: savedData ? savedData.language : 'ENG',
            resetPassOnLogin: savedData ? savedData.resetPassOnLogin : 0,
            userPrivilege: savedData ? savedData.userPrivilege : this.currentUser.userPrivilege,
            userManagement: savedData ? savedData.userManagement : this.currentUser.userManagement,
            role: savedData ? savedData.role : this.currentUser.role,
            userID: savedData ? savedData.userID : this.currentUser.webUserID,
            conceptID: savedData ? savedData.conceptID : this.currentUser.conceptID,
            groupItemID: savedData ? savedData.groupItemID : this.currentUser.groupItemID,
            tagID: savedData ? savedData.tagID : this.currentUser.tagID,
            storeID: savedData ? savedData.storeID : this.currentUser.storeID,
        };
        this.form = new UntypedFormGroup(
            {
                email: new UntypedFormControl(this.user.email, [
                    Validators.pattern(this.validationService.getEmailRegex()),
                    Validators.required,
                    Validators.maxLength(this.validationService.maxEmailLength),
                ]),
                firstName: new UntypedFormControl(this.user.firstName, [Validators.required, Validators.maxLength(this.validationService.userFirstLastNameLength)]),
                lastName: new UntypedFormControl(this.user.lastName, [Validators.required, Validators.maxLength(this.validationService.userFirstLastNameLength)]),
                language: new UntypedFormControl(this.user.language, [Validators.required]),
                password: new UntypedFormControl('', [
                    Validators.required,
                    Validators.pattern(this.validationService.getPasswordRegex()),
                    Validators.maxLength(this.validationService.maxPasswordLength),
                    Validators.minLength(this.validationService.minPasswordLength),
                ]),
                confirmPassword: new UntypedFormControl('', [
                    Validators.required,
                    Validators.maxLength(this.validationService.maxPasswordLength),
                    Validators.minLength(this.validationService.minPasswordLength),
                ]),
                userPrivilege: new UntypedFormControl(this.user.userPrivilege, [Validators.required]),
                userManagement: new UntypedFormControl(this.user.userManagement, [Validators.required]),
                resetPassOnLogin: new UntypedFormControl(this.user.resetPassOnLogin, [Validators.required]),
                role: new UntypedFormControl(this.user.role, [Validators.required]),
                userID: new UntypedFormControl(this.user.userID, [Validators.required]),
                conceptID: new UntypedFormControl(this.user.conceptID, [Validators.required]),
                groupItemID: new UntypedFormControl(this.user.groupItemID, [Validators.required]),
                tagID: new UntypedFormControl(this.user.tagID, [Validators.required]),
                storeID: new UntypedFormControl(this.user.storeID, [Validators.required]),
            },
            {
                validators: [this.formValidationService.matchPassword('password', 'confirmPassword') as any],
            }
        );
        this.persistData = true;
        this.globalUser = this.user.role == this.USER_ACCESS.GLOBAL ? true : false;
        if (!this.globalUser) {
            this.getConceptById(this.user.conceptID).then(() => {
                switch (this.user.role) {
                    case this.USER_ACCESS.GROUP_ITEM:
                        this.getStoreGroupItemById(this.user.groupItemID);
                        break;
                    case this.USER_ACCESS.TAG:
                        this.getTagById(this.user.tagID);
                        break;
                    case this.USER_ACCESS.STORE:
                        this.getStoreById(this.user.storeID);
                        break;
                }
            });
        }
        if (this.currentUser.userPrivilege === null) {
            this.hidePrivilegeAccess = true;
        }
        this.passedUserId = this.currentUser.webUserID;
        this.inheritCurrentUserLanguage();
    }

    ngOnDestroy() {
        if (this.persistData) {
            this.browserStorage.setSessionstorage('newUserForm', this.form.value);
        } else {
            this.browserStorage.removeSessionstorageItem('newUserForm');
        }
    }

    getConceptById(conceptId): Promise<void> {
        return this.conceptApiService.getConceptById(conceptId).then((concept: any) => {
            this.concept = concept;
            this.accessType = this.captions.organization;
            this.accessTypeName = concept.displayName;
        });
    }

    getStoreGroupItemById(groupItemId): Promise<void> {
        this.storeUser;
        return this.storeGroupMembersApiService.getStoreGroupItemById(groupItemId).then((groupItem: any) => {
            this.accessType = this.captions.groupItem;
            this.accessTypeName = groupItem.displayName;
        });
    }

    getTagById(tagId): Promise<void> {
        return this.tagApiService.getTagById(tagId).then((tagObj) => {
            let tag = tagObj as any;
            this.accessType = this.captions.tag;
            this.accessTypeName = tag.displayName;
        });
    }

    getStoreById(storeId): Promise<void> {
        return this.storeApiService.getStoreById(storeId).then((store: any) => {
            this.storeUser = true;
            this.accessType = this.captions.storeList;
            this.accessTypeName = store.displayName;
        });
    }

    gotoManageUserAccess() {
        this.router.navigate(['users/addUser/manageUserAccess', this.passedUserId], {
            queryParams: { state: this.state },
        });
    }

    gotoManageUserPrivileges() {
        this.router.navigate(['users/addUser/manageUserPrivileges', this.passedUserId], {
            queryParams: { state: this.state },
        });
    }

    submitForm() {
        if (this.form.valid) {
            delete this.user.confirmPassword;
            delete this.user.userID;
            this.user.access = JSON.stringify([
                {
                    groupItemID: this.user.groupItemID,
                    storeID: this.user.storeID,
                    conceptID: this.user.conceptID,
                    tagID: this.user.tagID,
                    role: this.user.role,
                },
            ]);
            const formValues = this.form.value;
            this.user.language = formValues.language;
            this.user.email = formValues.email;
            this.user.firstName = formValues.firstName;
            this.user.lastName = formValues.lastName;
            this.user.password = formValues.password;
            this.userApiService
                .addUser(this.user)
                .then(() => {
                    this.router.navigate(['users/allUsers']);
                    this.persistData = false;
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
    }

    inheritCurrentUserLanguage() {
        for (let language of this.languages) {
            if (language.language === this.currentUserInfo.language) this.user.language = language;
        }
    }

    cancelChanges() {
        this.router.navigate(['users/allUsers']);
        this.persistData = false;
    }

    togglePasswordVisibility(): void {
        this.hidePassword = !this.hidePassword;
    }

    onConfirmPasswordChange() {
        this.form.controls.confirmPassword.markAsTouched();
    }
}
