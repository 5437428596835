import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom, map, Observable } from 'rxjs';
import { AppConstants } from '../../../app.constants';
import { UserInfoHandler } from '../../users/services/user-info-handler.service';
import { WidgetCustomDateOption } from '../enums/widget.enum';
import { DashboardTab, DashboardTabGetWithContent, DashboardTabPost, SharedDashboardTab } from '../models/dashboard.model';
import {
    Widget, 
    WidgetDefinitionData, 
    WidgetInstaceGet, 
    WidgetInstance, 
    WidgetInstancePost, 
    WidgetInstancePut, 
    WidgetOpenDates, 
    WidgetTemplate, 
    WidgetTemplateGet 
} from '../models/widget.model'

import { SerializerService } from './serializer.service';

@Injectable()
export class DashboardApiService {
    private tabsdUrl = 'dashboardtabs';
    private tabsSharedUrl = 'shared/';
    private widgetUrlV1 = 'widget/';
    private widgetUrlV2 = 'widgetv2/';
    private widgetTemplateUrl = 'templates';
    private widgetInstanceUrl = 'instances';
    private widgetFlatReportHTMLUrl = 'instances/{widgetInstanceId}/html';
    private BASE_URL: string;

    constructor(
        @Inject(HttpClient) private http: HttpClient,
        @Inject(UserInfoHandler) private userInfoHandler: UserInfoHandler,
        @Inject(SerializerService) private serializerService: SerializerService
    ) {
        this.BASE_URL = AppConstants.BASE_URL;
    }

    async getTabs(): Promise<DashboardTabGetWithContent[]> {
        let url = this.BASE_URL + this.tabsdUrl;
        const data$ = this.http.get<DashboardTabGetWithContent[]>(url, { params: { content: true } });
        const value = await lastValueFrom(data$);
        return value;
    }

    async getOwnerSharedTabs(): Promise<number[]> {
        let url = this.BASE_URL + this.tabsdUrl + '/' + this.tabsSharedUrl + 'owned';
        const data$ = this.http.get<number[]>(url, { params: { content: true } });
        const value = await lastValueFrom(data$);
        return value;
    }

    async addTab(tab: DashboardTabPost): Promise<DashboardTabGetWithContent> {
        let url = this.BASE_URL + this.tabsdUrl;
        const data$ = this.http.post<DashboardTabGetWithContent>(url, tab);
        const value = await lastValueFrom(data$);
        return value;
    }

    async updateTab(tab: DashboardTabGetWithContent): Promise<DashboardTabGetWithContent> {
        let url = this.BASE_URL + this.tabsdUrl + '/' + tab.id;
        const data$ = this.http.put<DashboardTabGetWithContent>(url, { tabName: tab.tabName, content: tab.content });
        const value = await lastValueFrom(data$);
        return value;
    }

    async removeTab(tabId: number): Promise<void> {
        let url = this.BASE_URL + this.tabsdUrl + '/' + tabId;
        const data$ = this.http.delete<void>(url);
        const value = await lastValueFrom(data$);
        return value; // returns NO CONTENT on success
    }

    async getSharedDashboardUsers(tabId: number): Promise<number[]> {
        let url = this.BASE_URL + this.tabsdUrl + '/' + this.tabsSharedUrl + tabId;
        const data$ = this.http.get<number[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getSharedDashboardTabs(): Promise<DashboardTab[]> {
        let url = this.BASE_URL + this.tabsdUrl + '/shared';
        const data$ = this.http.get<DashboardTab[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async addSharedDashboardTab(tabId: number, userIds: number[]): Promise<SharedDashboardTab[]> {
        let url = this.BASE_URL + this.tabsdUrl + '/' + this.tabsSharedUrl + tabId;
        const data$ = this.http.post<SharedDashboardTab[]>(url, userIds);
        const value = await lastValueFrom(data$);
        return value;
    }

    async deleteShareDashboardTab(tabId: number, userIds: number[]): Promise<void> {
        let url = this.BASE_URL + this.tabsdUrl + '/' + this.tabsSharedUrl + tabId;
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            body: userIds,
        };
        const data$ = this.http.delete<void>(url, httpOptions);
        const value = await lastValueFrom(data$);
        return value; // returns NO CONTENT on success
    }

    async getWidgetTemplates(): Promise<WidgetTemplateGet[]> {
        let url = this.BASE_URL + this.widgetUrlV1 + this.widgetTemplateUrl;
        const data$ = this.http.get<WidgetTemplateGet[]>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getWidgetTemplateById(templateId: number): Promise<WidgetTemplate> {
        let url = this.BASE_URL + this.widgetUrlV1 + this.widgetTemplateUrl + '/' + templateId;
        const data$ = this.http.get<WidgetTemplate>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async addWidget(widgetInfo: WidgetInstancePost, dataSource: string): Promise<WidgetInstaceGet> {
        let url = dataSource != 'Store' ? 
            this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl : 
            this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl;
        const data$ = this.http.post<WidgetInstaceGet>(url, widgetInfo);
        const value = await lastValueFrom(data$);
        return value;
    }

    getWidgetInstanceByIdHelper(widgetId: number): Observable<WidgetInstance> {
        let url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widgetId;
        return this.http.get<WidgetInstance>(url).pipe(map((data) => data));
    }

    getWidgetDataByIdAndPeriodHelper(widget: Widget, customPeriod: WidgetCustomDateOption | null, forceRefresh: boolean): Observable<Object[]> {
        let url: string;
        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        } 
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        }
        if (customPeriod) {
            return this.http.get<Object[]>(url, { params: { period: customPeriod, refresh: forceRefresh } }).pipe(
                map((data) => {
                    return data;
                })
            );
        } else {
            return this.http.get<Object[]>(url, { params: { refresh: forceRefresh } }).pipe(
                map((data) => {
                    return data;
                })
            );
        }
    }

    getWidgetInstanceById$(widgetId: number): Observable<WidgetInstance> {
        return this.serializerService.queueUp(this.getWidgetInstanceByIdHelper(widgetId));
    }

    getViewedWidgetInstanceByIdHelper(widgetId): Observable<any> {
        let url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/viewed/' + widgetId;
        return this.http.get(url).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getViewedWidgetInstanceById(widgetId): Observable<Response> {
        return this.serializerService.queueUp(this.getViewedWidgetInstanceByIdHelper(widgetId));
    }

    async updateWidgetInstance(widgetInfo: WidgetInstancePut, widgetInstanceId: number, dataSource: string): Promise<void> {
        let url = dataSource != 'Store' ? 
            this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widgetInstanceId : 
            this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/' + widgetInstanceId;
        const data$ = this.http.put<void>(url, widgetInfo);
        const value = await lastValueFrom(data$);
        return value; // returns NO CONTENT on success
    }

    async deleteWidgetInstance(widgetInstanceId: number): Promise<void> {
        let url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widgetInstanceId;
        const data$ = this.http.delete<void>(url);
        const value = await lastValueFrom(data$);
        return value; // returns NO CONTENT on success
    }

    getWidgetDefinitionByIdHelper(widget: Widget): Observable<WidgetDefinitionData> {
        let url: string;
        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widget.widgetID + '/definition';
        }
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/' + widget.widgetID + '/definition';
        }
        return this.http.get<WidgetDefinitionData>(url).pipe(map((data) => { return data; }));
    }

    getWidgetDefinitionById$(widget: Widget): Observable<WidgetDefinitionData> {
        return this.serializerService.queueUp(this.getWidgetDefinitionByIdHelper(widget));
    }

    getViewedWidgetDefinitionByIdHelper(widget: Widget): Observable<WidgetDefinitionData> {
        let url: string;
        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/viewed/' + widget.widgetID + '/definition';
        } 
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/viewed/' + widget.widgetID + '/definition';
        }

        return this.http.get<WidgetDefinitionData>(url).pipe(map((data) => { return data; }));
    }

    getViewedWidgetDefinitionById$(widget: Widget): Observable<WidgetDefinitionData> {
        return this.getViewedWidgetDefinitionByIdHelper(widget);
    }

    getWidgetDataByIdAndPeriod$(widget: Widget, customPeriod: WidgetCustomDateOption | null, forceRefresh: boolean): Observable<Object[]> {
        return this.serializerService.queueUp(
            this.getWidgetDataByIdAndPeriodHelper(widget, customPeriod, forceRefresh)
        );
    }

    // type of data returned is ambiguous because data varies by widget
    getViewedWidgetDataByIdAndPeriodHelper(widget: Widget, customPeriod: WidgetCustomDateOption | null, forceRefresh: boolean): Observable<Object[]> {
        let url: string;
        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/viewed/' + widget.widgetID + '/data';
        } 
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/viewed/' + widget.widgetID + '/data';
        }

        if (customPeriod) {
            return this.http.get<Object[]>(url, { params: { period: customPeriod, refresh: forceRefresh } }).pipe(
                map((data) => {
                    return data;
                })
            );
        } 
        else {
            return this.http.get<Object[]>(url, { params: { refresh: forceRefresh } }).pipe(
                map((data) => {
                    return data;
                })
            );
        }
    }

    getViewedWidgetDataByIdAndPeriod$(widget: Widget, customPeriod: WidgetCustomDateOption, forceRefresh: boolean): Observable<Object[]> {
        return this.serializerService.queueUp(
            this.getViewedWidgetDataByIdAndPeriodHelper(widget, customPeriod, forceRefresh)
        );
    }

    async postWidgetDataByIdAndPeriod(widget: Widget, customPeriod: WidgetCustomDateOption, forceRefresh: boolean): Promise<Object[]> {
        let url: string;

        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        } 
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        }

        var data$: Observable<Object[]>;
        if (customPeriod) {
            data$ = this.http.post<Object[]>(url, { params: { period: customPeriod, refresh: forceRefresh } });
        } 
        else {
            data$ = this.http.post<Object[]>(url, { params: { refresh: forceRefresh } });
        }

        const value = await lastValueFrom(data$);
        return value;
    }

    async postWidgetDataByIdAndDateRange(widget: Widget, startDate: string, endDate: string): Promise<Object[]> {
        let url: string;

        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        } 
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        }

        const data$ = this.http.post<Object[]>(url, { params: { startDate: startDate, endDate: endDate } });
        const value = await lastValueFrom(data$);
        return value;
    }

    getWidgetDataByIdAndDateRangeHelper(widget: Widget, startDate: string, endDate: string): Observable<Object[]> {
        let url: string;
        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        } 
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/' + widget.widgetID + '/data';
        }
        return this.http.get<Object[]>(url, { params: { startDate: startDate, endDate: endDate } }).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getWidgetDataByIdAndDateRange$(widget: Widget, startDate: string, endDate: string): Observable<Object[]> {
        return this.serializerService.queueUp(this.getWidgetDataByIdAndDateRangeHelper(widget, startDate, endDate));
    }

    getViewedWidgetDataByIdAndDateRangeHelper(widget: Widget, startDate: string, endDate: string): Observable<Object[]> {
        let url: string;

        if (widget.dataSource != 'Store') {
            url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/viewed/' + widget.widgetID + '/data';
        } 
        else {
            url = this.BASE_URL + this.widgetUrlV2 + this.widgetInstanceUrl + '/viewed/' + widget.widgetID + '/data';
        }

        return this.http.get<Object[]>(url, { params: { startDate: startDate, endDate: endDate } }).pipe(
            map((data) => {
                return data;
            })
        );
    }

    getViewedWidgetDataByIdAndDateRange$(widget: Widget, startDate: string, endDate: string): Observable<Object[]> {
        return this.serializerService.queueUp(
            this.getViewedWidgetDataByIdAndDateRangeHelper(widget, startDate, endDate)
        );
    }

    async getWidgetOpenDates(widgetInstanceId: number): Promise<WidgetOpenDates> {
        let url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/' + widgetInstanceId + '/opendates';
        const data$ = this.http.get<WidgetOpenDates>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getViewedWidgetOpenDates(widgetInstanceId: number): Promise<WidgetOpenDates> {
        let url = this.BASE_URL + this.widgetUrlV1 + this.widgetInstanceUrl + '/viewed/' + widgetInstanceId + '/opendates';
        const data$ = this.http.get<WidgetOpenDates>(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    getLinkForFlatWidgetHTMLDateRange(widgetInstanceId, startDate, endDate) {
        let baseReqUrl = this.getBaseLinkForFlatWidget(widgetInstanceId);
        baseReqUrl += '&startDate=' + startDate + '&endDate=' + endDate;
        return baseReqUrl;
    }

    getLinkForFlatWidgetHTMLPeriod(widgetInstanceId, period) {
        let baseReqUrl = this.getBaseLinkForFlatWidget(widgetInstanceId);
        baseReqUrl += '&period=' + period;
        return baseReqUrl;
    }

    getLinkForFlatWidgetHTML(widgetInstanceId, refresh) {
        let baseReqUrl = this.getBaseLinkForFlatWidget(widgetInstanceId);
        if (refresh) baseReqUrl += '&refresh=' + refresh;
        return baseReqUrl;
    }

    getBaseLinkForFlatWidget(widgetInstanceId) {
        let userInfo = this.userInfoHandler.getUserInfo();
        let token = userInfo.token;
        let randomString = Math.random().toString(36).substring(0, 5); // add random query param to url in case we want to make a request
        // for data and url stays the same (without random string data would not be re requested cause url for iframe would stay same)
        let url =
            this.BASE_URL +
            this.widgetUrlV1 +
            this.widgetFlatReportHTMLUrl.replace('{widgetInstanceId}', widgetInstanceId);
        url += '?accessToken=' + token + '&rand=' + randomString;
        return url;
    }
}
