import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { CaptionService } from '../../../utils/shared-services/caption.service';
import { UtilsApiService } from '../../../utils/shared-services/utils.resource';
import { UserAccess } from '../models/user-access.model';
import { WebUserSecurityWithStoreList } from '../models/user-security.model';
import { UserAccessHandler } from '../services/user-access-handler.service';
import { UserSecurityApiService } from '../services/user-security.resource';
import { UserApiService } from '../services/users.resource';
import { UserConstants } from '../users.constants';
import { InheritUserPrivilegeDialog } from './inherit-user-privilege-dialog.component';

@Component({
    selector: 'manageUserPrivileges',
    templateUrl: 'src/app/features/users/templates/manage-user-privilege.html',
})
export class ManageUserPrivilegesComponent {
    public previousRoutePath = new BehaviorSubject<string>('');
    private USER_ACCESS;
    private USER_PRIVILEGES;
    public captions;
    public passedUserId;
    public cancel;
    public currentUserSecurity: UserAccess;
    public privilegeCategories;
    public selectedUser;
    public organizationPrivileges;
    public storePrivileges;
    public userOrganizationPrivileges;
    public userStorePriviliges;
    public isAdmin;
    public userName;
    public selectedUserSecurity: WebUserSecurityWithStoreList;
    public conceptCategory;
    public storeCategory;
    public userPrivilegesChecked;
    public numberOfPrivileges;
    public passedUser;
    public users;
    public storePortalAdmin = {
        "isChecked": false
    };
    public storePortalUser = {
        "isChecked": false
    };
    public storePortalChoice;

    previousPath: any;

    constructor(
        @Inject(UtilsApiService) private utilsApiService: UtilsApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UserApiService) private userApiService: UserApiService,
        @Inject(UserSecurityApiService) private userSecurityApiService: UserSecurityApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(Router) private router: Router,
        @Inject(CaptionService) private captionService: CaptionService
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.USER_PRIVILEGES = UserConstants.USER_PRIVILEGES;
    }

    ngOnInit() {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });
        this.actRoute.params.subscribe((params) => {
            this.passedUserId = +params['pathId'];
        });
        this.passedUser = this.browserStorage.getSessionstorage('newUserForm', null);
        this.actRoute.queryParams.subscribe((params) => {
            this.previousPath = params['state'];
        });
        if (this.passedUser) {
            this.cancel = () => {
                this.router.navigate(['users/addUser']);
            };
        } else if (this.passedUserId) {
            this.cancel = () => {
                this.router.navigate(['users/editUser', this.passedUserId]);
            };
        } else {
            this.router.navigate(['users/allUsers']);
        }
        this.currentUserSecurity = this.userAccessHandler.getUserAccess();
        if (typeof this.captions == 'undefined') {
            this.captionService.assignCaptions('ENG', 'FRONT').then(() => this.getUserPrivileges());
        } else {
            this.getUserPrivileges();
        }
    }

    getUserPrivileges() {
        this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
            this.selectedUserSecurity = selectedUserSecurity;
            if (this.currentUserSecurity.role === this.selectedUserSecurity.role) {
                this.userApiService
                    .getUserPrivileges(this.currentUserSecurity)
                    .then((data) => this.getFullPrivilegeListSuccess(data));
            } else {
                this.userApiService
                    .getUserPrivileges(this.selectedUserSecurity)
                    .then((data) => this.getFullPrivilegeListSuccess(data));
            }

            // when adding a new user, make sure to use the passedUser's role so 
            // that only the avalailable privileges for that role are shown
            if (this.passedUser) {
                selectedUserSecurity.role = this.passedUser.role;
            }

            // Set the available privileges based on user role
            this.userApiService
                .getUserPrivileges(selectedUserSecurity)
                .then((userPrivileges) => this.getUserPrivilegesSuccess(userPrivileges));
        });
    }

    getUserPrivilegesSuccess(userPrivileges) {
        this.conceptCategory = {};
        this.setPrivilegeNames(userPrivileges);
    }

    isAllPrivilegesSelected(privilegeCategory): boolean {
        for (let privilegeCategory of this.privilegeCategories) {
            const uncheckedPrivileges = privilegeCategory.userPrivilege
                .filter(privilege =>
                    privilege.name !== this.USER_PRIVILEGES.STORE_PORTAL_ONLY
                    && privilege.name !== this.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY
                    && !privilege.isChecked);
            privilegeCategory.allChecked = uncheckedPrivileges.length === 0;
        }

        return privilegeCategory.allChecked;
    }

    getFullPrivilegeListSuccess(data) {
        let numberOfPrivileges = 0;
        for (let privilegeCategory of data.privilegeCategories) {
            numberOfPrivileges += privilegeCategory.userPrivilege.length;
        }
        this.numberOfPrivileges = numberOfPrivileges;
    }

    setPrivilegeNames(userPrivileges) {
        for (let privilegeCategory of userPrivileges.privilegeCategories) {
            let privilegeCategoryName = privilegeCategory.displayName;
            privilegeCategory.displayName = this.captions[privilegeCategoryName];
            for (let privilege of privilegeCategory.userPrivilege) {
                let privilegeName = privilege.displayName;
                privilege.displayName = this.captions[privilegeName];
                privilegeCategory.toggle = false;
            }
        }
        this.privilegeCategories = userPrivileges.privilegeCategories;
        this.getCurrentUserInfo();
    }

    getCurrentUserInfo() {
        this.utilsApiService.getUserInfo().then((user) => this.getCurrentUserInfoSuccess(user));
    }

    getCurrentUserInfoSuccess(currentUser) {
        let currentConceptPrivileges = [],
            currentStorePrivileges = [];
        if (
            typeof currentUser.userPrivilege !== 'undefined' &&
            currentUser.userPrivilege !== this.USER_PRIVILEGES.ALL_PRIVILEGES
        ) {
            let currentUserPrivileges = JSON.parse(currentUser.userPrivilege);
            for (let privilegeCategory of this.privilegeCategories) {
                for (let i = 0; i < privilegeCategory.userPrivilege.length; i++) {
                    for (let j = 0; j < currentUserPrivileges.length; j++) {
                        if (
                            privilegeCategory.userPrivilege[i].name === currentUserPrivileges[j].name &&
                            privilegeCategory.displayName === this.USER_PRIVILEGES.ORGANIZATIONS
                        )
                            currentConceptPrivileges = currentConceptPrivileges.concat(
                                privilegeCategory.userPrivilege[i]
                            );
                        else if (
                            privilegeCategory.userPrivilege[i].name === currentUserPrivileges[j].name &&
                            privilegeCategory.displayName === this.USER_PRIVILEGES.STORES
                        )
                            currentStorePrivileges = currentStorePrivileges.concat(privilegeCategory.userPrivilege[i]);
                    }
                }
                if (privilegeCategory.displayName === this.USER_PRIVILEGES.ORGANIZATIONS)
                    privilegeCategory.userPrivilege = currentConceptPrivileges;
                else if (privilegeCategory.displayName === this.USER_PRIVILEGES.STORES)
                    privilegeCategory.userPrivilege = currentStorePrivileges;
            }
        }
        this.getSelectedUserInfoById(this.passedUserId);
    }

    getSelectedUserInfoById(userId) {
        if (this.isFromAddUser()) {
            this.getSelectedUserInfoByIdSuccess(this.passedUser);
        } else {
            this.userApiService.getUserById(userId).then((user) => {
                this.getSelectedUserInfoByIdSuccess(user);
            });
        }
    }

    getSelectedUserInfoByIdSuccess(user) {
        this.selectedUser = user;
        this.organizationPrivileges = [];
        this.storePrivileges = [];
        this.userOrganizationPrivileges = [];
        this.userStorePriviliges = [];
        if (typeof user.userPrivilege !== 'undefined' && user.userPrivilege !== this.USER_PRIVILEGES.ALL_PRIVILEGES) {
            this.getSelectedUserPrivileges(user);
        } else if (user.userPrivilege === this.USER_PRIVILEGES.ALL_PRIVILEGES) {
            this.isAdmin = true;
            this.selectAllPrivileges();
        }
        if (!this.isFromAddUser()) this.userName = user.firstName + ' ' + user.lastName;
        else this.userName = this.passedUser.firstName + ' ' + this.passedUser.lastName;
    }

    isFromAddUser() {
        return this.previousPath == 'addUser';
    }

    getSelectedUserPrivileges(user) {
        if (user.userPrivilege !== null) {
            let userPrivileges = JSON.parse(user.userPrivilege);
            for (let privilege of userPrivileges) {
                if (privilege.type === this.USER_PRIVILEGES.ORG_TYPE)
                    this.userOrganizationPrivileges = this.userOrganizationPrivileges.concat(privilege);
                else if (privilege.type === this.USER_PRIVILEGES.STORE_TYPE)
                    this.userStorePriviliges = this.userStorePriviliges.concat(privilege);
            }
            for (let privilegeCategory of this.privilegeCategories) {
                if (privilegeCategory.displayName === this.USER_PRIVILEGES.ORGANIZATIONS) {
                    this.organizationPrivileges = this.organizationPrivileges.concat(privilegeCategory.userPrivilege);
                    this.conceptCategory = privilegeCategory;
                } else if (privilegeCategory.displayName === this.USER_PRIVILEGES.STORES) {
                    this.storePrivileges = this.storePrivileges.concat(privilegeCategory.userPrivilege);
                    this.storeCategory = privilegeCategory;
                }
                privilegeCategory.isEmpty = privilegeCategory.userPrivilege.length === 0;
                for (let i = 0; i < privilegeCategory.userPrivilege.length; i++) {
                    for (let j = 0; j < userPrivileges.length; j++) {
                        // incoming for store portal will be ADMIN or USER need to set isChecked
                        if (userPrivileges[j].name == 'STORE_PORTAL_ADMIN_ONLY') {
                            this.storePortalAdmin.isChecked = true;
                        } else if (userPrivileges[j].name == 'STORE_PORTAL_USER_ONLY') {
                            this.storePortalUser.isChecked = true;
                        }

                        if (privilegeCategory.userPrivilege[i].name === userPrivileges[j].name)
                            privilegeCategory.userPrivilege[i].isChecked = true;
                    }
                }
            }
            this.setUserPrivileges();
        }
    }

    setUserPrivileges() {
        if (
            this.selectedUserSecurity.role === this.USER_ACCESS.CONCEPT ||
            this.selectedUserSecurity.role === this.USER_ACCESS.GLOBAL
        ) {
            if (this.userOrganizationPrivileges.length === this.organizationPrivileges.length) {
                this.conceptCategory.allChecked = true;
            }
            const index = this.storePrivileges.findIndex((priv) => priv.name == this.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY);
            if (
                this.userStorePriviliges.length === this.storePrivileges.length - 1 &&
                !this.storePrivileges[index].isChecked
            ) {
                this.storeCategory.allChecked = true;
            }
        }
    }

    selectAllPrivileges() {
        for (let privilegeCategory of this.privilegeCategories) {
            privilegeCategory.allChecked = true;
            this.selectAllPrivilegesForCategory(privilegeCategory);
        }
    }

    userPrivilegeChanged(privilege, privilegeCategory, storePortalChoice) {

        if (privilege.name == this.USER_PRIVILEGES.STORE_PORTAL_ONLY
            && (this.storePortalAdmin.isChecked || this.storePortalUser.isChecked)) {
            privilege.isChecked = true;
            if (storePortalChoice == 'admin') {
                this.storePortalAdmin.isChecked = true;
                this.storePortalUser.isChecked = false;
                // this will be submitted for priv name
                this.storePortalChoice = "STORE_PORTAL_ADMIN_ONLY";
            } else if (storePortalChoice == 'user') {
                this.storePortalAdmin.isChecked = false;
                this.storePortalUser.isChecked = true;
                this.storePortalChoice = "STORE_PORTAL_USER_ONLY";
            }
        } else if (privilege.name == this.USER_PRIVILEGES.STORE_PORTAL_ONLY
            && (!this.storePortalAdmin.isChecked && !this.storePortalUser.isChecked)) {
            this.storePortalAdmin.isChecked = false;
            this.storePortalUser.isChecked = false;
            this.storePortalChoice = null;
            privilege.isChecked = false;
        }

        // disable STORE_PORTAL_ONLY if other privileges are selected
        if (privilegeCategory.displayName == this.USER_PRIVILEGES.STORES
            && privilege.name !== this.USER_PRIVILEGES.STORE_PORTAL_ONLY
            && (this.storePortalAdmin.isChecked || this.storePortalUser.isChecked)) {
            this.storePortalAdmin.isChecked = false;
            this.storePortalUser.isChecked = false;
            this.storePortalChoice = null;
            privilegeCategory.userPrivilege
                .find((priv) => priv.name == this.USER_PRIVILEGES.STORE_PORTAL_ONLY)
                .isChecked = false;
        }

        var viewSharedDashPriv = null;
        var storePortalPriv = null;

        // case for when you are toggling VIEW_SHARED_DASH_PRIV
        if (privilege.name == this.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY && privilege.isChecked) {
            for (let privCat of this.privilegeCategories) {
                privCat.allChecked = false;
                for (let priv of privCat.userPrivilege.filter(
                    (priv) => priv.name != this.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY
                )) {
                    priv.isChecked = false;
                    this.storePortalAdmin.isChecked = false;
                    this.storePortalUser.isChecked = false;
                }
            }
        }

        // case for when one of store portal options toggled
        if (privilege.name == this.USER_PRIVILEGES.STORE_PORTAL_ONLY && privilege.isChecked) {
            for (let privCat of this.privilegeCategories) {
                privCat.allChecked = false;
                for (let priv of privCat.userPrivilege.filter(
                    (priv) => priv.name != this.USER_PRIVILEGES.STORE_PORTAL_ONLY
                )) {
                    priv.isChecked = false;
                }
            }
        }

        // array to see if all elements isChecked
        this.userPrivilegesChecked = [];
        for (let priv of privilegeCategory.userPrivilege) {
            if (priv.isChecked) {
                this.userPrivilegesChecked = [...this.userPrivilegesChecked, priv];
            }
            if (priv.name == this.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY) {
                viewSharedDashPriv = priv;
            }

        }

        // case for when your privilegeCategory has viewSharedDashPriv
        if (viewSharedDashPriv) {
            if (privilege != viewSharedDashPriv) {
                viewSharedDashPriv.isChecked = false;

            }
            if (this.userPrivilegesChecked.length === privilegeCategory.userPrivilege.length - 1) {
                privilegeCategory.allChecked = true;
            } else {
                privilegeCategory.allChecked = false;
            }
            // case for when privilegeCategory does not have viewSharedDashPriv
        } else if (this.userPrivilegesChecked.length === privilegeCategory.userPrivilege.length) {
            privilegeCategory.allChecked = true;
        } else {
            privilegeCategory.allChecked = false;
        }
    }

    selectAllPrivilegesForCategory(privilegeCategory) {
        for (let privilege of privilegeCategory.userPrivilege) {
            if (privilegeCategory.allChecked) {
                privilege.isChecked = true;
            } else {
                privilege.isChecked = false;
            }
            if (privilege.name == this.USER_PRIVILEGES.VIEW_SHARED_DASH_ONLY) {
                privilege.isChecked = !privilegeCategory.allChecked;
            }
            if (privilege.name == this.USER_PRIVILEGES.STORE_PORTAL_ONLY) {
                privilege.isChecked = false;
                this.storePortalAdmin.isChecked = false;
                this.storePortalUser.isChecked = false;
            }
        }
    }

    submitUser() {
        let checkedPrivileges = [],
            allPrivileges = [],
            userPrivileges = [],
            privilegesToSubmit = [],
            privilegeObjectString = null;

        for (let privilegeCategory of this.privilegeCategories) {
            for (let privilege of privilegeCategory.userPrivilege) {
                allPrivileges = allPrivileges.concat(privilege);
                if (privilege.isChecked === true) {
                    checkedPrivileges = checkedPrivileges.concat(privilege);
                }
            }
        }

        for (let privilege of checkedPrivileges) {
            if (privilege.name == this.USER_PRIVILEGES.STORE_PORTAL_ONLY) {
                // set to admin or user choice
                privilege.name = this.storePortalChoice;
            }
            let userPrivilege = [
                {
                    name: privilege.name,
                    type: privilege.type,
                },
            ];
            userPrivileges = userPrivileges.concat(userPrivilege);

        }

        for (let privilege of userPrivileges) {
            let privilegeString = JSON.stringify(privilege);
            privilegesToSubmit = privilegesToSubmit.concat(privilegeString);
        }

        if (privilegesToSubmit.length === this.numberOfPrivileges)
            privilegeObjectString = { userPrivilege: this.USER_PRIVILEGES.ALL_PRIVILEGES };
        else if (privilegesToSubmit.length > 0)
            privilegeObjectString = { userPrivilege: '[' + privilegesToSubmit + ']' };
        else privilegeObjectString = { userPrivilege: null };

        if (!this.isFromAddUser()) {
            this.userApiService.updateUserPrivileges(privilegeObjectString, this.passedUserId).then(() => {
                this.router.navigate(['users/editUser', this.passedUserId]);
            });
        } else {
            this.passedUser.userPrivilege = privilegeObjectString.userPrivilege;
            this.browserStorage.setSessionstorage('newUserForm', this.passedUser);
            this.router.navigate(['users/addUser']);
        }
    }

    inheritUserPrivilegeDialog() {
        this.userApiService.getUsersForPrivilegeInheritance(this.passedUserId).then((users: any) => {
            this.users = users;
            const dialogRef = this.dialog.open(InheritUserPrivilegeDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    users: users,
                    USER_PRIVILEGES: this.USER_PRIVILEGES,
                    USER_ACCESS: this.USER_ACCESS,
                    privilegeCategories: this.privilegeCategories,
                    selectAllPrivileges: this.selectAllPrivileges,
                },
            });

            dialogRef.backdropClick().subscribe(() => {
                dialogRef.close();
            });
        });
    }
}
