import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from '../../../app.constants';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { ConceptApiService } from '../services/concept.resource';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { Concept, ConceptAdd } from '../models/concept.model';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'addConcept',
    templateUrl: 'src/app/features/concepts/templates/concept.add.html',
})
export class AddConceptComponent {
    private concept: ConceptAdd;
    private timeZones: string[];
    private formBuilder: FormBuilder = new FormBuilder();

    protected _onDestroy = new Subject<void>();

    public form = this.formBuilder.group({
        name: ['', [Validators.required, Validators.maxLength(this.validationService.conceptNameMaxLength)]],
        currencyCode: ['', [Validators.required]],
        timezone: ['', [Validators.required]],
        dow: ['', [Validators.required]],
    });

    public webBgColor = '#808080';
    public filteredTimeZones: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
    public timeZonesCtrl: FormControl<string> = new FormControl<string>('');

    @Input() captions: Record<string, string>;
    @Input() currencyCodes: string[];

    constructor(
        @Inject(ConceptApiService) private conceptApiService: ConceptApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) {
        this.currencyCodes = AppConstants.CURRENCYCODES_NG;
        this.getTimeZones();
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });

        // listen for search field value changes
        this.timeZonesCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => { 
                this.filterTimezones(); 
            });
    }

    addConcept(): void {
        const values = this.form.value;
        if ((values.timezone = '0')) values.timezone = 'UTC';
        this.concept = {
            displayName: values.name,
            webBgColor: this.webBgColor,
            currencyCode: values.currencyCode,
            dayOfWeek: +values.dow,
            weekOfYear: 1,
            localTimeZone: values.timezone,
        };
        let trigger = false;
        this.conceptApiService.getAllActiveConcepts().then((concepts: Concept[]) => {
            for (let concept of concepts) {
                if (concept.displayName.toUpperCase() === this.concept.displayName.toUpperCase()) {
                    trigger = true;
                    this.snackbarService.errorMessageTop(this.captions.duplicateConceptName);
                    return;
                }
            }
            if (!trigger) {
                this.conceptApiService.addConcept(this.concept).then(() => {
                    this.router.navigate(['concepts/allConcepts']);
                });
            }
        });
    }

    colorSelected(color: string): void {
        this.webBgColor = color;
    }

    cancelChanges(): void {
        this.router.navigate(['concepts/allConcepts']);
    }

    protected filterTimezones(): void {
        if (!this.timeZones) {
            return;
        }
        // get the search keyword
        let search = this.timeZonesCtrl.value;
        if (!search) {
            this.filteredTimeZones.next(this.timeZones.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the time-zones
        this.filteredTimeZones.next(
            this.timeZones.filter(zone => zone.toLowerCase().indexOf(search) > -1)
        );
    }

    private getTimeZones(): void {
        this.conceptApiService.getTimeZones().then((result: string[]) => {
            this.timeZones = result;
            this.filteredTimeZones.next(this.timeZones.slice());
        });
    }
}
