import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class BrowserStorage {
    private window: Window;
    constructor(@Inject(DOCUMENT) private document: Document) {
        this.window = this.document.defaultView;
    }

    setLocalstorage(key, value) {
        if (value) this.window.localStorage[key] = JSON.stringify(value);
    }

    getLocalstorage(key, defaultValue) {
        if (typeof this.window.localStorage[key] !== 'undefined') return JSON.parse(this.window.localStorage[key]);
        else return defaultValue;
    }

    setSessionstorage(key, value) {
        if (value) this.window.sessionStorage[key] = JSON.stringify(value);
    }

    getSessionstorage(key, defaultValue) {
        if (typeof this.window.sessionStorage[key] !== 'undefined') return JSON.parse(this.window.sessionStorage[key]);
        else return defaultValue;
    }

    removeSessionstorageItem(key) {
        if (typeof this.window.sessionStorage[key] !== 'undefined') sessionStorage.removeItem(key);
    }

    removeLocalstorageItem(key) {
        localStorage.removeItem(key);
    }

    clearAllSessionStorage() {
        sessionStorage.clear();
    }

    clearAllLocalStorage() {
        localStorage.clear();
    }
}
