import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { EmitterService } from '../../../utils/shared-services/emitter.service';
import { ItemStorageService } from '../../../utils/shared-services/item-storage.service';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { StoreGroupMembersApiService } from '../../store-groups/services/store-group-member.resource';
import { StoreGroupApiService } from '../../store-groups/services/store-group.resource';
import { TagApiService } from '../../tags/services/tag.resource';
import { UserAccessHandler } from '../../users/services/user-access-handler.service';
import { UserConstants } from '../../users/users.constants';
import { StoreApiService } from '../services/store.resource';
import { StoreTagFilterStorage } from '../services/stores.service';
import { StoreAndConceptConfirmDeleteDialog } from './dialogs/confirm-delete-dialog.component';
import { NoStoresDialog } from './dialogs/no-stores-dialog.component';
import { StoreDetailsDialog } from './dialogs/store-details-dialog.component';
import { StoreOptionsComponent } from './store-options.component';
import { StorePortalService } from '../../store-portal/services/store-portal.service';
import { StorePortalApiService } from '../../store-portal/services/store-portal.resource';
import { BrowserStorage } from '../../../utils/shared-services/browser-storage.service';
import { UserAccess } from '../../users/models/user-access.model';
import { UserRole } from '../../users/enums/user-role.enum';
import { PrivilegeName, PrivilegeType } from '../../users/enums/user-privileges.enum';
import { EnableStorePortal } from '../models/enable-store-portal.model';
import { Tag } from '../../tags/models/tag.model';
import { Store, StoreBase, StoreBaseWithAddress } from '../models/store.model';
import { GroupItem, GroupWithItems } from '../../store-groups/models/group-item.model';
import { StorePortalData } from '../../store-portal/models/store-portal.model';

@Component({
    selector: 'allStores',
    templateUrl: 'src/app/features/stores/templates/all-stores.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllStoresComponent implements OnInit {
    private currentUser: UserAccess;
    private storeTagFilterStorageType: string;

    public conceptLocalTimezone: string;
    public captions: Record<string, string>;
    public stores: StoreBase[] = [];
    public tags: Tag[];
    public storeInfoData: Partial<StoreBaseWithAddress> & {conceptLocalTimezone?: string};
    public storesToDeactivate: StoreBase[];
    public storesWithUsers: Store[];
    public storesWithUsersSelected: boolean;
    public emptyList: boolean = true;
    public showOptions: boolean = false;
    public showStoreFilters: boolean = false;
    public showActiveButton: boolean = false;
    public showRemoveOptions: boolean = false;
    public showRecycleBinOptions: boolean = true;
    public showCheckbox: boolean = false;
    public showEditIcons: boolean = true;
    public showConfirmDelete: boolean = false;
    public showNoItemsToDelete: boolean = false;
    public showAddStoreButton: boolean = true;
    public showStoreFilterIcon: boolean = true;
    public storeAccessUser: boolean = true;
    public isGroupItemOrStoreAccess: boolean = false;
    public showStoreDetails: boolean = false;
    public isGroupItemAccess: boolean = false;
    public manageStoresPrivilege: boolean = false;
    public assignGroupItemsPrivilege: boolean = false;
    public assignStoreTagsPrivilege: boolean = false;
    public manageEOIAPIPrivilege: boolean = false;
    public adminStorePortalPrivilege: boolean = false;
    public userStorePortalPrivilege: boolean = false;
    public hideStoreGroupsFilterSection: boolean = false;
    public storeGroups: GroupWithItems[];
    public storesForChosenItems: Record<string, number[]> = {};
    public storesForChosenTags: Record<string, number[]> = {};
    public totalChecked: number;
    public storesLoaded: boolean = false;
    public storeInfoIcon: boolean = false;
    public conceptID: number;
    public conceptName: string;
    public icon: string;

    @Input() public searchStr: string;

    constructor(
        @Inject(StoreApiService) private storeApiService: StoreApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(ItemStorageService) private itemStorageService: ItemStorageService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(StoreTagFilterStorage) private storeTagFilterStorage: StoreTagFilterStorage,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(EmitterService) private emitterService: EmitterService,
        @Inject(StoreGroupApiService) private storeGroupApiService: StoreGroupApiService,
        @Inject(TagApiService) private tagApiService: TagApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(MatBottomSheet) private matBottomSheet: MatBottomSheet,
        @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
        @Inject(Router) private router: Router,
        @Inject(StorePortalService) private storePortalService: StorePortalService,
        @Inject(StorePortalApiService) private storePortalApiService: StorePortalApiService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute
    ) {
        this.currentUser = userAccessHandler.getUserAccess();
        this.storeTagFilterStorageType = storeTagFilterStorage.keyType.CONCEPT_KIND;
    }

    ngOnInit(): void {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.actRoute.params.subscribe((params) => {
            this.conceptID = +params['conceptID'];
            this.conceptName = params['displayName'];
        });

        this.manageStoresBasedOnUserRole(this.currentUser);
        this.manageStoresBasedOnUserPrivileges(this.currentUser);
        this.loadStores();
        this.cdr.detectChanges();

        this.getAllTagsForConcept(this.conceptID).then(() => {
            this.getTotalNumberOfItemsChecked();
        });

        this.getStoreGroupsForValidUsers(this.conceptID);
    }

    changedSearchText(searchStr: string): void {
        this.searchStr = searchStr;
    }

    addOneStore(): void {
        if (this.currentUser.storeCount >= this.currentUser.limitation.maxStore) {
            this.snackbarService.errorMessageTop(
                this.captions.limitOf + this.currentUser.limitation.maxStore + ' ' + this.captions.stores
            );
        } else {
            this.router.navigate(['stores/addStore', this.conceptID, this.conceptName]);
        }
    }

    //delete stores functions
    toggleCheckbox(): void {
        this.showCheckbox = !this.showCheckbox;
        this.showRecycleBinOptions = !this.showRecycleBinOptions;
        this.showEditIcons = !this.showEditIcons;
        this.showRemoveOptions = !this.showRemoveOptions;
        this.showAddStoreButton = !this.showAddStoreButton;
    }

    openDeleteDialog(): void {
        let storesToDeactivate: StoreBase[] = []
        let storeIdsToDeactivate: number[] = [];

        for (let store of this.stores) {
            if (store.isChecked === true) {
                storesToDeactivate.push(store);
                storeIdsToDeactivate.push(store.id);
            }
            this.storesToDeactivate = storesToDeactivate;
        }

        if (storeIdsToDeactivate.length > 0) {

            this.storeApiService.validateStoresDeletion(storeIdsToDeactivate).then((storesWithUsers) => {
                this.storesWithUsers = storesWithUsers;
                this.storesWithUsersSelected = storesWithUsers.length > 0 ? true : false;
            });

            const dialogRef = this.dialog.open(StoreAndConceptConfirmDeleteDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '40em',
                data: {
                    storesToDelete: this.storesToDeactivate,
                    storesWithUsersSelectedToDelete: this.storesWithUsersSelected,
                    storesWithUsersToDelete: this.storesWithUsers,
                    storeOrConcept: 'Store',
                },
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                if (result) {
                    this.confirmDelete();
                }
            });
        } 
        else {
            this.dialog.open(NoStoresDialog, {
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
            });
        }
    }

    // cancelDelete(): void {
    //     this.revertSelection();
    // }

    confirmDelete(): void {
        this.deleteStores();
    }

    // hideConfirmDelete(): void {
    //     this.showConfirmDelete = false;
    // }

    // //side bar/nav functions
    // hideSideBar(): void {
    //     this.showStoreFilterIcon = true;
    //     this.showStoreFilters = false;
    // }

    toggleSidenav(): void {
        this.revertSelection();
    }

    // showSideBar(): void {
    //     this.showStoreFilters = true;
    //     this.showStoreFilterIcon = false;
    //     this.showAddStoreButton = false;
    //     this.showRecycleBinOptions = false;
    // }

    // showSideBarForReporting(): void {
    //     this.showStoreFilters = true;
    //     this.showStoreFilterIcon = false;
    // }

    // hideSideBarForReporting(): void {
    //     this.showStoreFilters = false;
    //     this.showStoreFilterIcon = false;
    // }

    revertSelection(): void {
        this.showRecycleBinOptions = true;
        this.showRemoveOptions = false;
        this.showCheckbox = false;
        this.showEditIcons = true;
        this.showNoItemsToDelete = false;
        this.showConfirmDelete = false;
        this.showAddStoreButton = true;
        for (let store of this.stores) {
            store.isChecked = false;
        }
    }

    // toggleFilterStoreGroup(storeGroup): void {
    //     storeGroup.toggled = !storeGroup.toggled;
    // }

    selectStoreBox(store: StoreBase): void {
        if (!this.showCheckbox) {
            this.triggerInfo(store, false);
            this.storeApiService.getStoreById(store.id).then((response) => {
                this.storeInfoData = response;
                this.showStoreDetailsDialog(response);
                this.storeInfoData.conceptLocalTimezone = this.conceptLocalTimezone;
            });
        } else {
            store.isChecked = !store.isChecked;
        }
    }

    triggerInfo(store: StoreBase, options: boolean): void {
        if (this.icon == 'settings' && options) {
            this.router.navigate(['stores/enableStorePortal', this.conceptID, store.id, store.displayName]);
            return;
        } 
        else if (this.icon == 'login' && options) {
            let pathName: string;
            this.storeApiService.getEnableStorePortal(store.id).then((enableStorePortal: EnableStorePortal) => {
                if (enableStorePortal) {
                    pathName = enableStorePortal.pathName;
                    // login to store portal api and forward to portal home page
                    this.storePortalApiService
                        .hqLoginForStorePortal(pathName, '', 'HQUser', this.browserStorage.getSessionstorage('userAccess', null))
                        .then((res: StorePortalData) => {
                            this.storePortalService.setStorePortalData(res);
                            if (res?.AccessToken) {
                                window.open('storePortal/home/0', '_blank');
                        }});
                }
            });
            return;
        }
        this.storeInfoData = store;
        this.itemStorageService.selectedStore.conceptLocalTimezone = this.conceptLocalTimezone;
        this.itemStorageService.selectedStore = store;

        if (options) {
            this.matBottomSheet.open(StoreOptionsComponent, {
                panelClass: 'options-bottom-sheet',
                data: {
                    conceptID: this.conceptID,
                    conceptName: this.conceptName,
                },
            });
        }
    }

    showStoreDetailsDialog(store: StoreBaseWithAddress): void {
        const dialogRef = this.dialog.open(StoreDetailsDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                storeDetail: store,
            },
        });
    }


    storeGroupFilterChanged(item: GroupItem): void {
        this.getTotalNumberOfItemsChecked();
        let storeItemKey = item.id.toString() + '.' + item.groupID.toString();
        this.storeTagFilterStorage.saveStoreGroupActive(this.conceptID, item, this.storeTagFilterStorageType);

        if (item.isChecked) {
            this.storeGroupMembersApiService.getGroupItemInfoById(item.id).then((storesWithThisItem) => {
                let storeIds: number[] = [];
                if (storesWithThisItem.length > 0) {
                    for (let store of storesWithThisItem) {
                        if (typeof store !== 'undefined' && store !== null) {
                            storeIds.push(store.id);
                        }
                    }
                }
                this.storesForChosenItems[storeItemKey] = storeIds;
            });
        }
        else {
            delete this.storesForChosenItems[storeItemKey];
        }

        this.checkActiveTagsAndItemsForButton();
        setTimeout(() => this.cdr.detectChanges(), 250);
    }

    tagTriggered(tag: Tag): void {
        this.getTotalNumberOfItemsChecked();
        let tagKey = tag.id.toString() + 'key';
        this.storeTagFilterStorage.saveTagActive(tag, this.storeTagFilterStorageType);
        if (tag.isChecked) {
            this.tagApiService.getTagInfoById(tag.id).then((stores) => {
                let storeIds: number[] = [];
                for (let store of stores) {
                    storeIds.push(store.id);
                }
                this.storesForChosenTags[tagKey] = storeIds;
            });
        }
        else {
            delete this.storesForChosenTags[tagKey];
        }
        this.checkActiveTagsAndItemsForButton();
        setTimeout(() => this.cdr.detectChanges(), 250);
    }

    resetStoreFiltering(type: string): void {
        if (type === 'Tags') {
            for (let tag of this.tags) {
                tag.isChecked = false;
            }
            this.storeTagFilterStorage.removeTagFiltersForConcept(this.conceptID, this.storeTagFilterStorageType);
            this.storesForChosenTags = {};
        }
        if (type === 'Items') {
            if (this.currentUser.role !== UserRole.GroupItem) {
                for (let group of this.storeGroups) {
                    for (let item of group.groupItems) {
                        item.isChecked = false;
                    }
                }
                this.storeTagFilterStorage.removeItemFiltersForConcept(this.conceptID, this.storeTagFilterStorageType);
                this.storesForChosenItems = {};
            }
        }

        if (Object.keys(this.storesForChosenItems).length === 0 && Object.keys(this.storesForChosenTags).length == 0)
            this.showActiveButton = false;
    }

    private loadStores(): void {
        this.storeApiService.getStoresByConceptId(this.conceptID).then((stores) => {
            this.emptyList = stores.length === 0;
            if (this.userStorePortalPrivilege || this.adminStorePortalPrivilege) {
                for (let store of stores) {
                    this.storeApiService.getEnableStorePortal(store.id).then((enableStorePortal: EnableStorePortal) => {
                        if (enableStorePortal) {
                            this.stores.push(store);
                        }
                    });
                }
                this.storesLoaded = true;
            }
            else {
                this.stores = stores;
                this.storesLoaded = true;
            }
            this.emitterService.loadedStores('');
            setTimeout(() => this.cdr.detectChanges(), 350);
        });
    }

    private getStoreGroupsForValidUsers(conceptID: number): void {
        if (this.currentUser.role === UserRole.Global || this.currentUser.role === UserRole.Concept) {
            this.getAllStoreGroupsForConcept(conceptID).then(() => {
                this.getTotalNumberOfItemsChecked();
            });
        }
    }

    // logic put into init function
    private getStoresByConceptId(conceptId: number): void {
        this.storeApiService.getStoresByConceptId(conceptId).then((stores) => {
            this.emitterService.loadedStores('');
            this.stores = stores;
            this.emptyList = this.stores.length === 0;
        });
    }

    private deleteStores(): void {
        let storeIdsToDeactivate: number[] = [];
        for (let store of this.stores) {
            if (store.isChecked === true) {
                storeIdsToDeactivate.push(store.id);
            }
        }

        this.revertSelection();
        this.storeApiService.deactivateStores(storeIdsToDeactivate).then(() => {
            let newStoreCount = this.currentUser.storeCount - storeIdsToDeactivate.length;
            this.userAccessHandler.setStoreCount(newStoreCount);
            this.getStoresByConceptId(this.conceptID);
            this.loadStores();
            this.snackbarService.successMessageBottom(this.captions.storeDeleteSuccess);
        });
    }

    private manageStoresBasedOnUserRole(user: UserAccess): void {
        if (user.role === UserRole.GroupItem) {
            this.showStoreFilterIcon = true;
            this.isGroupItemAccess = true;
            this.hideStoreGroupsFilterSection = true;
        } 
        else if (user.role === UserRole.Tag) {
            this.showStoreFilterIcon = false;
            this.isGroupItemAccess = true;
            this.showAddStoreButton = false;
            this.showRecycleBinOptions = false;
            this.storeAccessUser = false;
        } 
        else if (user.role === UserRole.Store) {
            this.showStoreFilters = false;
            this.showAddStoreButton = false;
            this.showRecycleBinOptions = false;
            this.showStoreFilterIcon = false;
            this.storeAccessUser = false;
        }
    }

    private manageStoresBasedOnUserPrivileges(user: UserAccess): void {
        this.icon = 'list_alt';
        if (user.userPrivilege !== null && user.userPrivilege !== UserConstants.USER_PRIVILEGES.ALL_PRIVILEGES) {
            let privileges = JSON.parse(user.userPrivilege) as {name: PrivilegeName, type: PrivilegeType}[];
            for (let privilege of privileges) {
                if (privilege.name === PrivilegeName.ManageStores) {
                    this.manageStoresPrivilege = true;
                }
                if (privilege.name === PrivilegeName.PortalAdmin) {
                    this.adminStorePortalPrivilege = true;
                }
                else if (privilege.name === PrivilegeName.PortalUser) {
                    this.userStorePortalPrivilege = true;
                }
            }
            if (!this.manageStoresPrivilege) {
                this.showAddStoreButton = false;
                this.showStoreFilterIcon = false;
                this.showRecycleBinOptions = false;
            }
            if (this.adminStorePortalPrivilege) {
                this.showStoreFilterIcon = false;
                this.showAddStoreButton = false;
                this.showRecycleBinOptions = false;
                this.icon = 'settings';
            }
            if (this.userStorePortalPrivilege) {
                this.showStoreFilterIcon = false;
                this.showAddStoreButton = false;
                this.showRecycleBinOptions = false;
                this.icon = 'login';
            }
        } 
        else if (user.userPrivilege === null) {
            this.showAddStoreButton = false;
            this.showRecycleBinOptions = false;
        }
        else {
            this.manageStoresPrivilege = true;
            this.assignStoreTagsPrivilege = true;
            this.assignGroupItemsPrivilege = true;
            this.manageEOIAPIPrivilege = true;
        }
    }

    private getAllTagsForConcept(conceptId: number): Promise<void> {
        return this.tagApiService.getAllTagsForConcept(conceptId).then((tags) => {
            this.tags = tags;
            let savedTagFiltersForConcept = this.storeTagFilterStorage.getSavedTagFiltersForConcept(conceptId, this.storeTagFilterStorageType);

            for (let tag of this.tags) {
                if (
                    typeof savedTagFiltersForConcept[tag.id.toString()] !== undefined &&
                    savedTagFiltersForConcept[tag.id.toString()] === true
                ) {
                    tag.isChecked = true;
                    this.showActiveButton = true;
                    this.tagTriggered(tag);
                }
            }
        });
    }

    private getAllStoreGroupsForConcept(conceptId: number): Promise<void> {
        return this.storeGroupApiService.getAllStoreGroupsWithItemsForConcept(conceptId).then((storeGroups) => {

            let storeGroupsToBeDisplayed: GroupWithItems[] = [];

            for (let group of storeGroups) {
                if (group.groupItems.length > 0) {
                    group.toggled = false;
                    storeGroupsToBeDisplayed.push(group);
                }
            }

            this.storeGroups = storeGroupsToBeDisplayed;

            let savedItemFiltersForConcept = this.storeTagFilterStorage.getSavedItemFiltersForConcept(conceptId, this.storeTagFilterStorageType);

            for (let group of this.storeGroups) {
                for (let item of group.groupItems) {
                    const storeItemKey = item.id.toString() + '.' + group.groupID.toString();
                    if (
                        typeof savedItemFiltersForConcept[storeItemKey] !== undefined &&
                        savedItemFiltersForConcept[storeItemKey] === true
                    ) {
                        group.toggled = true;
                        item.isChecked = true;
                        this.showActiveButton = true;
                        this.storeGroupFilterChanged(item);
                    }
                }
            }
        });
    }

    private checkActiveTagsAndItemsForButton(): void {
        let checkedItemExists = false;
        let checkedTagExists = false;

        if (typeof this.storeGroups !== 'undefined') {
            for (let group of this.storeGroups) {
                for (let item of group.groupItems) {
                    if (item.isChecked === true) {
                        checkedItemExists = true;
                    }
                }
            }
        }
        if (typeof this.tags !== 'undefined') {
            for (let tag of this.tags) {
                if (tag.isChecked === true) {
                    checkedTagExists = true;
                }
            }
        }
        this.showActiveButton = checkedTagExists || checkedItemExists;
    }

    private getTotalNumberOfItemsChecked(): void {
        let countTags = 0;
        let countItems = 0;

        if (this.tags) {
            for (let tag of this.tags) {
                if (tag.isChecked === true) countTags++;
            }
        }

        if (this.storeGroups) {
            for (let group of this.storeGroups) {
                for (let item of group.groupItems) {
                    if (item.isChecked === true) countItems++;
                }
            }
        }
        this.totalChecked = countTags + countItems;
    }
}
