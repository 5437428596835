import { HttpClient, HttpParams } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AppConstants } from '../../app.constants';

@Injectable()
export class UtilsApiService {
    private longCaptionsUrl: string = '';
    public serverUrl: string = './data/captions/';
    private captionsUrl: string = 'captions';
    private userInfoUrl: string = 'currentuser';
    private countryListUrl: string = './data/countries.json';
    private usaStatesUrl: string = './data/usaStates.json';
    private canadaStatesUrl: string = './data/canadaStates.json';

    private BASE_URL;

    constructor(@Inject(HttpClient) private http: HttpClient) {
        this.BASE_URL = AppConstants.BASE_URL;
        this.longCaptionsUrl = this.captionsUrl + '/long';
    }

    async getCaptions(language: string, level: string): Promise<Record<string, string>> {
        let url = this.BASE_URL + this.captionsUrl;
        let params = new HttpParams();
        params = params.append('language', language);
        params = params.append('level1', level);
        const data$ = this.http.get(url, { params: params });
        const value = await lastValueFrom(data$) as Record<string, string>;
        return value;
    }

    async getLongCaptions(language: string, level: string): Promise<Record<string, string>> {
        let url = this.BASE_URL + this.longCaptionsUrl;
        let params = new HttpParams();
        params = params.append('language', language);
        params = params.append('level', level);
        const data$ = this.http.get(url, { params: params });
        const value = await lastValueFrom(data$) as Record<string, string>;
        return value;
    }

    async updateCurrentUser(lang: string, theme: string, showDashboardOnLogin: boolean) {
        let url = this.BASE_URL + this.userInfoUrl;
        let updatedUsr = {
            language: '',
            theme: '',
            showDashboardOnLogin: 0
        };
        if (lang) updatedUsr.language = lang;
        if (theme) updatedUsr.theme = theme;
        if (typeof showDashboardOnLogin === 'boolean')
            updatedUsr.showDashboardOnLogin = showDashboardOnLogin === true ? 1 : 0;
        const data$ = this.http.put(url, updatedUsr);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getUserInfo() {
        let url = this.BASE_URL + this.userInfoUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$);
        return value;
    }

    async getCountryList(): Promise<{countries: string[]}> {
        let url = this.countryListUrl;
        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$) as {countries: string[]};
        return value;
    }

    async getStates(country): Promise<{states: string[]}> {
        let url;
        if (country === 'USA') url = this.usaStatesUrl;
        else if (country === 'Canada') url = this.canadaStatesUrl;

        const data$ = this.http.get(url);
        const value = await lastValueFrom(data$) as {states: string[]};
        return value;
    }
}
