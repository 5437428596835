import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ReportEmitterService } from '../../../features/reports/services/reportEmitter.service';
import { SharedWizardData } from '../../../features/reports/wizard/services/report-shared-wizard-data.service';
import { StoreGroupMembersApiService } from '../../../features/store-groups/services/store-group-member.resource';
import { StoreGroupApiService } from '../../../features/store-groups/services/store-group.resource';
import { UserAccessHandler } from '../../../features/users/services/user-access-handler.service';
import { UserEmitterService } from '../../../features/users/services/user-emitter.service';
import { UserSecurityApiService } from '../../../features/users/services/user-security.resource';
import { UserConstants } from '../../../features/users/users.constants';
import { CaptionService } from '../../shared-services/caption.service';
import { EmitterService } from '../../shared-services/emitter.service';
import { GroupItemPickerDialog } from './group-item-picker-dialog.component';

@Component({
    selector: 'groupItemPicker',
    templateUrl: 'src/app/utils/shared-templates/user-access/group-item-picker.html',
})
export class GroupItemPickerComponent {
    @Output() groupItemPicker: EventEmitter<any> = new EventEmitter();
    // value that is passed in to determine whether you can select multiple groups or just a single one
    @Input() groupItemMultiSelect: boolean = false;
    // value that is passed in to determine whether the select all button should appear
    @Input() showSelectAllButton: boolean = false;

    private USER_ACCESS;
    public captions;
    public passedUserId;
    public currentUserAccess;
    public selectedUserSecurity;
    public showGroupItemBox;
    public showSingleItemBox;
    public showAllGroupItemsBox;
    public showNoGroupItemsBox;
    public customAllGroupItemsName;
    public storeGroups;
    public concept;
    public eventName;
    public groupItems;
    public isGroupItemUser;
    public selectedGroupItem;
    public selectedGroupItemsForRenderingStores;
    public showButtonLabels;
    public allGroupItems;
    // an array of the subscriptions to
    private subscriptions: Subscription[] = [];
    public groupItemPickerModel: number;
    public previousPath: any;

    constructor(
        @Inject(StoreGroupApiService) private storeGroupApiService: StoreGroupApiService,
        @Inject(StoreGroupMembersApiService) private storeGroupMembersApiService: StoreGroupMembersApiService,
        @Inject(UserAccessHandler) private userAccessHandler: UserAccessHandler,
        @Inject(UserSecurityApiService) private userSecurityApiService: UserSecurityApiService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(UserEmitterService) private userEmitterService: UserEmitterService,
        @Inject(EmitterService) private emitterService: EmitterService,
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData,
        @Inject(ReportEmitterService) private reportEmitterService: ReportEmitterService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute
    ) {
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.currentUserAccess = this.userAccessHandler.getUserAccess();
        this.showGroupItemBox = false;
        this.isGroupItemUser = false;
        this.showButtonLabels = true;
        this.subscriptions.push(
            this.userEmitterService.groupItemChangedDirectiveItem$.subscribe((groupItemAndConcept) => {
                this.groupItems = groupItemAndConcept[0];
                if (this.selectedGroupItem?.id === 0) {
                    this.showSingleItemBox = false;
                    this.showAllGroupItemsBox = false;
                    this.showNoGroupItemsBox = true;
                    this.customAllGroupItemsName =
                        this.captions.groupItemUserAccess + ' (' + this.concept.displayName + ')';
                } else if (this.selectedGroupItem?.length === 1) {
                    this.customAllGroupItemsName =
                        "'" + this.selectedGroupItem[0].displayName + "' " + this.captions.groupItemsSelected;
                } else if (this.selectedGroupItem?.length > 1) {
                    this.customAllGroupItemsName =
                        this.selectedGroupItem.length + ' ' + this.captions.groupItemsSelected;
                } else {
                    this.customAllGroupItemsName =
                        this.captions.groupItemUserAccess + ' (' + this.concept.displayName + ')';
                }
            })
        );
        this.subscriptions.push(
            this.reportEmitterService.getGroupItemsForReportStoreSelectionItem$.subscribe((conceptID: number) => {
                let tempGroupItem = [];
                this.storeGroupApiService.getAllStoreGroupsWithItemsForConcept(conceptID).then((response: any) => {
                    this.showGroupItemBox = response.length > 0;
                    this.storeGroups = response;
                    if (
                        this.sharedWizardData !== null &&
                        this.sharedWizardData.groupItemList !== null &&
                        this.sharedWizardData.groupItemList.length > 0
                    ) {
                        for (let selectedGroupItem of this.sharedWizardData.groupItemList) {
                            for (let storeGroup of this.storeGroups) {
                                for (let groupItem of storeGroup.groupItems) {
                                    if (selectedGroupItem.id === groupItem.id) {
                                        groupItem.isChecked = true;
                                        tempGroupItem.push(groupItem);
                                    }
                                }
                            }
                        }
                        //check if the array is undefined
                        if (typeof this.selectedGroupItemsForRenderingStores === 'undefined') {
                            this.selectedGroupItemsForRenderingStores = [];
                        }
                        //push the filtered data into the rendering stores
                        this.selectedGroupItemsForRenderingStores.push(tempGroupItem);
                    }
                    if (
                        typeof this.selectedGroupItemsForRenderingStores !== 'undefined' &&
                        this.selectedGroupItemsForRenderingStores.length !== 0
                    ) {
                        this.reportEmitterService.reportLeadLabelChanged(true);
                    }
                    this.showButtonLabels = false;
                    this.isGroupItemUser = true;
                    this.showSingleItemBox = false;
                    this.showAllGroupItemsBox = true;
                    this.showNoGroupItemsBox = false;
                    if (typeof this.customAllGroupItemsName === 'undefined') {
                        this.customAllGroupItemsName = this.captions.selectByGroupItems;
                    }
                });
            })
        );

        this.subscriptions.push(
            this.reportEmitterService.resetGroupItemSelectionLabelItem$.subscribe((ev) => {
                this.showSingleItemBox = false;
                this.showAllGroupItemsBox = true;
                this.showNoGroupItemsBox = false;
                this.customAllGroupItemsName = this.captions.selectByGroupItems;
                // make all the isChecked false
                if (typeof this.storeGroups !== 'undefined') {
                    for (let stores of this.storeGroups) {
                        for (let aGroup of stores.groupItems) {
                            aGroup.isChecked = false;
                        }
                    }
                }
                this.selectedGroupItemsForRenderingStores = [];
                this.groupItems = [];
            })
        );

        this.subscriptions.push(
            this.userEmitterService.conceptLoadedDirectiveItem$.subscribe((concept) => {
                this.eventName = 'conceptLoadedDirective';
                this.concept = concept;
                if (typeof this.selectedUserSecurity == 'undefined') {
                    this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
                        this.selectedUserSecurity = selectedUserSecurity;
                        this.getGroupItemsByUserAccess();
                    });
                } else this.getGroupItemsByUserAccess();
            })
        );

        this.subscriptions.push(
            this.userEmitterService.conceptChangedDirectiveItem$.subscribe((concept: any) => {
                this.concept = concept;
                this.eventName = 'conceptChangedDirective';
                // select single concept
                if (concept.id !== 0 && typeof concept.id !== 'undefined') {
                    this.showAllGroupItemsBox = true;
                    this.showSingleItemBox = false;
                    this.showNoGroupItemsBox = false;
                    this.showGroupItemBox = true;

                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                    this.customAllGroupItemsName =
                        this.captions.groupItemUserAccess + ' (' + this.concept.displayName + ')';
                    this.storeGroupApiService
                        .getAllStoreGroupsWithItemsForConcept(concept.id)
                        .then((storeGroups) => this.getAllStoreGroupsWithItemsSuccess(storeGroups));
                } else if (concept.id === 0) {
                    // select all concepts
                    this.showGroupItemBox = false;
                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                }
            })
        );

        // when a tag gets selected
        this.subscriptions.push(
            this.userEmitterService.tagChangedDirectiveItem$.subscribe((tagAndConcept) => {
                this.eventName = 'tagChangedDirective';
                // on select all tags
                if (tagAndConcept[0] == null || tagAndConcept[0].id === 0) {
                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                    this.showSingleItemBox = false;
                    this.showAllGroupItemsBox = true;
                    this.showNoGroupItemsBox = false;
                    this.customAllGroupItemsName =
                        this.captions.groupItemUserAccess + ' (' + this.concept.displayName + ')';
                } else {
                    // on select one tag

                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                    this.showSingleItemBox = false;
                    this.showAllGroupItemsBox = false;
                    this.showNoGroupItemsBox = true;
                    this.customAllGroupItemsName = this.captions.noStoreGroups;
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.storeChangedForGroupItemDirectiveItem$.subscribe((storeAndGroupItem) => {
                this.eventName = 'storeChangedForGroupItemDirective';
                if (typeof storeAndGroupItem[0]?.id || storeAndGroupItem[1]?.id) {
                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                    this.showSingleItemBox = false;
                    this.showAllGroupItemsBox = false;
                    this.showNoGroupItemsBox = true;
                    this.customAllGroupItemsName = this.captions.noStoreGroups;
                } else if (storeAndGroupItem[0].id === 0) {
                    if (typeof storeAndGroupItem[1] !== 'undefined' && storeAndGroupItem[1].id !== 0) {
                        this.groupItemPickerModel = storeAndGroupItem[1].id;
                        this.groupItemPicker.emit(this.groupItemPickerModel);
                        this.showSingleItemBox = true;
                        this.showAllGroupItemsBox = false;
                        this.showNoGroupItemsBox = false;
                    }
                } else {
                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                    this.showSingleItemBox = false;
                    this.showAllGroupItemsBox = false;
                    this.showNoGroupItemsBox = true;
                    this.customAllGroupItemsName = this.captions.noStoreGroups;
                }
            })
        );

        this.subscriptions.push(
            this.userEmitterService.storeChangedDirectiveItem$.subscribe((allStoresSelected) => {
                this.showSingleItemBox = false;
                if (allStoresSelected) {
                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                    this.showAllGroupItemsBox = false;
                    this.showNoGroupItemsBox = true;
                    this.customAllGroupItemsName = this.captions.noStoreGroups;
                } else {
                    this.showAllGroupItemsBox = true;
                    this.showNoGroupItemsBox = false;
                    this.groupItemPickerModel = 0;
                    this.groupItemPicker.emit(this.groupItemPickerModel);
                    this.customAllGroupItemsName =
                        this.captions.groupItemUserAccess + ' (' + this.concept.displayName + ')';
                }
            })
        );

        this.subscriptions.push(
            this.reportEmitterService.triggerSubmitGroupItemsItem$.subscribe((groupItems: any) => {
                this.customAllGroupItemsName = groupItems.length + ' ' + this.captions.groupItemsSelected;
            })
        );
    }

    ngOnDestroy() {
        // unsubscribe to all the subscriptions
        for (let sub of this.subscriptions) {
            sub?.unsubscribe();
        }
    }

    ngOnInit() {
        this.captions = this.captionService.captions;
        this.actRoute.params.subscribe((params) => {
            this.passedUserId = +params['pathId'];
        });
        this.actRoute.queryParams.subscribe((params) => {
            this.previousPath = params['state'];
        });
        if (typeof this.passedUserId === 'undefined') {
            this.passedUserId = this.currentUserAccess.webUserID;
        }
        if (this.currentUserAccess === null || typeof this.currentUserAccess == 'undefined') {
            this.currentUserAccess = this.userAccessHandler.getUserAccess();
        }
        if (this.previousPath === 'addUser' || this.previousPath === 'editUser') {
            this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
                this.selectedUserSecurity = selectedUserSecurity;
            });
        }
        this.allGroupItems = {
            id: 0,
        };
        this.groupItems = [];
        this.storeGroups = [];
        this.selectedGroupItemsForRenderingStores = [];
    }

    async getWebUserSecurity() {
        return this.userSecurityApiService.getSelectedUserById(this.passedUserId).then((selectedUserSecurity) => {
            this.selectedUserSecurity = selectedUserSecurity;
            return this.selectedUserSecurity;
        });
    }

    async getGroupItemsByUserAccess() {
        if (typeof this.selectedUserSecurity == "undefined") {
            await this.getWebUserSecurity()
        }
        if (
            this.currentUserAccess.role === this.USER_ACCESS.GLOBAL ||
            this.currentUserAccess.role === this.USER_ACCESS.CONCEPT
        ) {
            this.storeGroupApiService
                .getAllStoreGroupsWithItemsForConcept(this.selectedUserSecurity.conceptID)
                .then((storeGroups) => this.getAllStoreGroupsWithItemsSuccess(storeGroups));
        } else if (this.currentUserAccess.role === this.USER_ACCESS.GROUP_ITEM)
            this.storeGroupMembersApiService
                .getStoreGroupItemById(this.currentUserAccess.groupItemID)
                .then((groupItem) => this.getGroupItemByIdSuccess(groupItem));
        else {
            this.groupItemPickerModel = 0;
            this.groupItemPicker.emit(this.groupItemPickerModel);
            this.showGroupItemBox = false;
            this.showSingleItemBox = false;
            this.showAllGroupItemsBox = false;
            this.showNoGroupItemsBox = true;
            this.customAllGroupItemsName = this.captions.noStoreGroups;
        }
    }

    async getAllStoreGroupsWithItemsSuccess(storeGroups) {
        if (typeof this.selectedUserSecurity == "undefined") {
            await this.getWebUserSecurity()
        }
        this.storeGroups = storeGroups;
        if (this.selectedUserSecurity.role === this.USER_ACCESS.CONCEPT) {
            this.showGroupItemBox = true;
            this.showSingleItemBox = false;
            this.showAllGroupItemsBox = true;
            this.showNoGroupItemsBox = false;
            this.customAllGroupItemsName = this.captions.groupItemUserAccess + ' (' + this.concept.displayName + ')';
        } else if (this.selectedUserSecurity.role === this.USER_ACCESS.GROUP_ITEM) {
            if (this.eventName === 'conceptChangedDirective') {
                this.showGroupItemBox = true;
                this.showSingleItemBox = false;
                this.showAllGroupItemsBox = true;
                this.showNoGroupItemsBox = false;
            } else {
                this.showGroupItemBox = true;
                this.showSingleItemBox = true;
                this.showAllGroupItemsBox = false;
                this.showNoGroupItemsBox = false;
                for (let storeGroup of this.storeGroups) {
                    for (let i = 0; i < storeGroup.groupItems.length; i++) {
                        if (storeGroup.groupItems[i].id === this.selectedUserSecurity.groupItemID) {
                            this.selectedGroupItem = storeGroup.groupItems[i];
                            this.userEmitterService.groupItemLoadedDirective(this.selectedGroupItem);
                        }
                    }
                }
            }
        } else {
            if (this.eventName === 'conceptChangedDirective') {
                // this.groupItemPickerModel = 0;
                // this.groupItemPicker.emit(this.groupItemPickerModel);
                // this.showGroupItemBox = true;
                // this.showSingleItemBox = false;
                // this.showAllGroupItemsBox = true;
                // this.showNoGroupItemsBox = false;
            } else {
                this.groupItemPickerModel = 0;
                this.groupItemPicker.emit(this.groupItemPickerModel);
                this.showGroupItemBox = true;
                this.showSingleItemBox = false;
                this.showAllGroupItemsBox = false;
                this.showNoGroupItemsBox = true;
                this.customAllGroupItemsName = this.captions.noStoreGroups;
            }
        }
    }

    async getGroupItemByIdSuccess(groupItem) {
        if (typeof this.selectedUserSecurity == "undefined") {
            await this.getWebUserSecurity()
        }
        this.groupItems = this.groupItems.concat(groupItem);
        this.isGroupItemUser = true;
        this.storeGroups = [
            {
                groupItems: this.groupItems,
            },
        ];
        this.selectedGroupItem = groupItem;
        if (this.selectedUserSecurity.role === this.USER_ACCESS.GROUP_ITEM) {
            this.emitterService.groupItemLoadedCtrl(this.selectedGroupItem);
            this.showGroupItemBox = true;
            this.showSingleItemBox = true;
            this.showAllGroupItemsBox = false;
            this.showNoGroupItemsBox = false;
        } else {
            this.emitterService.groupItemLoadedCtrl(this.selectedGroupItem);
            this.showGroupItemBox = true;
            this.showSingleItemBox = false;
            this.showAllGroupItemsBox = false;
            this.showNoGroupItemsBox = true;
            this.customAllGroupItemsName = this.captions.noStoreGroups;
        }
    }

    toggleGroupItemSelection(groupItem) {
        let groupItemIndex = this.selectedGroupItemsForRenderingStores.indexOf(groupItem);
        if (groupItem.isChecked) this.selectedGroupItemsForRenderingStores.push(groupItem);
        else this.selectedGroupItemsForRenderingStores.splice(groupItemIndex, 1);
    }

    submitGroupItemsForStoreRendering() {
        this.reportEmitterService.reportGroupItemsSelected(this.selectedGroupItemsForRenderingStores);
        this.customAllGroupItemsName =
            this.selectedGroupItemsForRenderingStores.length + ' ' + this.captions.groupItemsSelected;
    }

    stopPropagation(ev) {
        ev.stopPropagation();
    }

    groupItemPickerDialog(ev) {
        if (
            this.currentUserAccess.role === this.USER_ACCESS.GLOBAL ||
            this.currentUserAccess.role === this.USER_ACCESS.CONCEPT ||
            this.currentUserAccess.role === this.USER_ACCESS.GROUP_ITEM
        ) {
            const dialogRef = this.dialog.open(GroupItemPickerDialog, {
                disableClose: true,
                panelClass: 'app-full-bleed-dialog',
                maxWidth: '90vw',
                data: {
                    storeGroups: this.storeGroups,
                    groupItemMultiSelect: this.groupItemMultiSelect,
                    showSelectAllButton: this.showSelectAllButton,
                    selectedGroupItemsForRenderingStores: this.selectedGroupItemsForRenderingStores,
                },
            });

            dialogRef.afterClosed().subscribe((res) => {
                //check if cancelled out of the window
                if (typeof res != 'undefined') {
                    //check if no groups was selected
                    if (res.data.selectedGroupItem) {
                        //update the data so that subscribers are notified
                        this.selectedGroupItem = res.data.selectedGroupItem;
                        this.emitterService.groupItemChangedCtrl(this.selectedGroupItem, this.concept);
                        if (this.selectedGroupItem?.length === 1) {
                            this.customAllGroupItemsName =
                                "'" + this.selectedGroupItem[0].displayName + "' " + this.captions.groupItemsSelected;
                        } else if (this.selectedGroupItem?.length && this.selectedGroupItem.length > 1) {
                            this.customAllGroupItemsName =
                                this.selectedGroupItem?.length + ' ' + this.captions.groupItemsSelected;
                        }
                        if (this.selectedGroupItem?.id === 0) {
                            this.showSingleItemBox = false;
                            this.showAllGroupItemsBox = true;
                            this.showNoGroupItemsBox = false;
                        }
                    } else {
                        // all items was selected
                        this.selectedGroupItem = this.groupItems;
                        this.selectedGroupItem = { id: 0 };
                        this.emitterService.groupItemChangedCtrl(this.selectedGroupItem, this.concept);
                    }
                }
            });
        }
    }
}
