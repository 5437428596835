import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from '../../../../../utils/shared-services/caption.service';
import { EditColumnsData } from "../wid-011";
import { BrowserStorage } from '../../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'editColumnsDialogV2',
    templateUrl: 'src/app/features/store-portal/widgets/templates/dialogs/wid-011-edit-columns-dialog.html',
})
export class EditColumnsDialogV2 {
    public captions;
    public columnSelectForm: any;
    public pos = 0;
    public punch = 0;
    public multiView = 0;

    private readonly WIDGET_COLUMN1_KEY: string = 'Sx1_column1_V2';
    private readonly WIDGET_COLUMN2_KEY: string = 'Sx1_column2_V2';
    private readonly WIDGET_COLUMN3_KEY: string = 'Sx1_column3_V2';

    constructor(
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EditColumnsDialogV2>,
        @Inject(CaptionService) private captionService,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage,
        @Inject(UntypedFormBuilder) private formBuilder: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) private data: EditColumnsData,
    ) {
        this.captions = this.captionService.captions;
    }

    ngOnInit() {
        this.pos = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN1_KEY, this.data.pos);
        this.punch = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN2_KEY, this.data.punch);
        this.multiView = this.browserStorage.getSessionstorage(this.WIDGET_COLUMN3_KEY, this.data.multiView);

        this.columnSelectForm = this.formBuilder.group({
            column1Selection: (this.pos != 0) ? [this.pos, Validators.required] : ['0', Validators.required],
            column2Selection: (this.punch != 0) ? [this.punch, Validators.required] : ['0', Validators.required],
            column3Selection: (this.multiView != 0) ? [this.multiView, Validators.required] : ['0', Validators.required],
        });
    }

    saveColumns() {
        const dialogData: EditColumnsData = {
            pos: this.columnSelectForm.value.column1Selection,
            punch: this.columnSelectForm.value.column2Selection,
            multiView: this.columnSelectForm.value.column3Selection
        };
        
        this.dialogRef.close(dialogData);
    }   
}
