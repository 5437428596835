import { Subject } from 'rxjs';
import { WidgetCustomDateOption } from 'src/app/features/dashboard/enums/widget.enum';

export class EmitterService {

    // concept picker directive 
    private loadedConceptCtrl = new Subject();
    conceptLoadedCtrlItem$ = this.loadedConceptCtrl.asObservable();

    private changedConceptCtrl = new Subject();
    conceptChangedCtrlItem$ = this.changedConceptCtrl.asObservable();

    // group item picker directive 
    private groupItem = new Subject();
    groupItemLoadedCtrlItem$ = this.groupItem.asObservable();

    private changedGroupItem = new Subject();
    groupItemChangedCtrlItem$ = this.changedGroupItem.asObservable();

    // store picker directive 
    private storeChangeGroup = new Subject();
    storeChangedForGroupItemCtrlItem$ = this.storeChangeGroup.asObservable();

    private storeChanged = new Subject();
    storeChangedCtrlItem$ = this.storeChanged.asObservable();

    private storeChangeTag = new Subject();
    storeChangedForTagCtrlItem$ = this.storeChangeTag.asObservable();

    private tagsForStores = new Subject();
    addTagForSelectedStoresPopUpItem$ = this.tagsForStores.asObservable();

    // tag picker directive
    private loadedTag = new Subject();
    tagLoadedCtrlItem$ = this.loadedTag.asObservable();

    private changedTag = new Subject();
    tagChangedCtrlItem$ = this.changedTag.asObservable();

    // titlebar directive 
    private selectDate = new Subject<WidgetCustomDateOption>();
    pickADateSelectionItem$ = this.selectDate.asObservable();

    private dateValSelected = new Subject<WidgetCustomDateOption>();
    dropdownSelectionItem$ = this.dateValSelected.asObservable();

    private selectDateRange = new Subject<WidgetCustomDateOption>();
    dateRangeSelectionItem$ = this.selectDateRange.asObservable();

    private toTriggerSpin = new Subject();
    triggerSpinItem$ = this.toTriggerSpin.asObservable();

    // all stores 
    private storesLoaded = new Subject();
    loadedStoresItem$ = this.storesLoaded.asObservable();


    conceptLoadedCtrl(concept) {
        this.loadedConceptCtrl.next(concept);
    }

    conceptChangedCtrl(selectedConcept) {
        this.changedConceptCtrl.next(selectedConcept);
    }

    // group item picker directive 
    groupItemLoadedCtrl(selectedGroupItem) {
        this.groupItem.next(selectedGroupItem);
    }

    groupItemChangedCtrl(selectedGroupItem, concept) {
        this.changedGroupItem.next([selectedGroupItem, concept]);
    }

    // store picker directive 
    storeChangedForGroupItemCtrl(store, groupItem) {
        this.storeChangeGroup.next([store, groupItem]);
    }

    storeChangedCtrl(stores) {
        this.storeChanged.next(stores);
    }

    storeChangedForTagCtrl(store, tag) {
        this.storeChangeTag.next([store, tag]);
    }

    addTagForSelectedStoresPopUp(stores, dialog) {
        this.tagsForStores.next([stores, dialog]);
    }

    // tag picker directive 
    tagLoadedCtrl(tag) {
        this.loadedTag.next(tag);
    }

    tagChangedCtrl(tag, concept) {
        this.changedTag.next([tag, concept])
    }

    // from title bar directive 
    pickADateSelection(widgetcustomDate: WidgetCustomDateOption): void {
        this.selectDate.next(widgetcustomDate);
    }

    dropdownSelection(selectedDateVal: WidgetCustomDateOption) {
        this.dateValSelected.next(selectedDateVal);
    }

    dateRangeSelection(widgetcustomDate: WidgetCustomDateOption): void {
        this.selectDateRange.next(widgetcustomDate);
    }

    // all stores component 
    loadedStores(stores) {
        this.storesLoaded.next(stores);
    }
}
