import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorePortalApiService } from '../services/store-portal.resource';
import { StorePortalService } from '../services/store-portal.service';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'storePortalLogin',
    templateUrl: 'src/app/features/store-portal/templates/store-portal-login.html',
})
export class StorePortalLoginComponent {
    public captions: any;
    public passedStoreAlias;
    public storeName: string;

    form: UntypedFormGroup;
    formBuilder: UntypedFormBuilder = new UntypedFormBuilder();

    constructor(
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(Router) private router: Router,
        @Inject(StorePortalService) private storePortalService: StorePortalService,
        @Inject(StorePortalApiService) private storePortalApiService: StorePortalApiService,
        @Inject(ValidationService) public validationService: ValidationService

    ) {
        this.actRoute.data.subscribe((data) => {
            this.captions = data.captions;
        });

        this.actRoute.queryParams.subscribe((params) => {
            this.passedStoreAlias = params['PortalID'];
        });

        this.form = this.formBuilder.group({
            storeAlias: ['', [Validators.required, Validators.maxLength(this.validationService.portalAliasMaxLength), Validators.minLength(this.validationService.portalAliasMinLength)]],
            userID: ['', []],
            empID: ['', [Validators.required, Validators.maxLength(this.validationService.portalEmpIDMaxLength)]],
        });
    }

    ngOnInit() {
        // Check if alias was passed
        if (this.passedStoreAlias) {
            this.storePortalApiService.getStoreName(this.passedStoreAlias).then((res: string) => {
                this.storeName = res
            })
        }
    }

    login(value) {
        this.storePortalApiService.loginForStorePortal(value.storeAlias, value.userID, value.empID, {}).then((res: any) => {
            let empNum = 0;
            this.storePortalService.setStorePortalData(res);
            if (res.DataSets.DataSet[0].Record) {
                empNum = res.DataSets.DataSet[0].Record.EMPNUM;
            }
            this.router.navigate(['storePortal/home', empNum]);
        });
    }

    onFieldBlur(event: Event) {
        const alias = (event.target as HTMLInputElement).value;
        //reset store name if alias changed
        this.storeName = '';
        if (alias) {
            this.storePortalApiService.getStoreName(alias).then((res: string) => {
                this.storeName = res
            })
        }
    }
}