import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaptionService } from "../../../../utils/shared-services/caption.service";
import { ColumnSelectionDialogData } from "../../models/dialog.model";
import { ColDef } from 'ag-grid-community';

@Component({
    selector: "report-category-selection",
    templateUrl: "./src/app/features/price-change/templates/dialogs/column-selection.component.html"
})
export class PriceChangeColumnSelectionDialog implements OnInit {

    public captions: Record<string, string>
    public columns: ColDef[];
    public groupBy: string;

    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<PriceChangeColumnSelectionDialog>,
        @Inject(MAT_DIALOG_DATA) public data: ColumnSelectionDialogData,
    ) {
        this.captions = this.captionService.captions;
        this.columns = [];
        this.groupBy = null;
    }

    ngOnInit(): void {
        this.columns = this.data.columns;
        this.groupBy = this.data.groupBy;
    }

    checkShouldDisable(column: ColDef): boolean {
        return (
            this.groupBy === "Descript" && column.headerName === 'PLink' ||
            this.groupBy === "PLink" && column.headerName === 'Description'
        );
    }

    toggleColumn(column: ColDef): void {
        if (
            this.groupBy === "Descript" && column.headerName !== 'PLink' ||
            this.groupBy === "PLink" && column.headerName !== 'Description' ||
            this.groupBy !== "Descript" && this.groupBy !== 'PLink'
        ) {
            column.hide = !column.hide;
        }
    }

    selectAllColumns(): void {
        this.columns.forEach(column => column.hide = false);
    }

    resetSelection(): void {
        this.columns.forEach(column => column.hide = true);
    }

    confirmSelection(): void {
        this.dialogRef.close({
            columns: this.columns,
        })
    }
}
