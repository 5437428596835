import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { UserEmitterService } from '../../../features/users/services/user-emitter.service';
import { UserConstants } from '../../../features/users/users.constants';
import { CaptionService } from '../../shared-services/caption.service';
import { EmitterService } from '../../shared-services/emitter.service';

interface DialogData {
    stores: any;
    currentUserAccess: any;
    storeCheckboxes: any;
    selectedUserSecurity: any;
    groupItem: any;
    groupItemModel: any;
    customAllStoresName;
    allStores: any;
    storeItemMultiSelect: boolean;
    selectedStores: any;
}

@Component({
    selector: 'store-picker-dialog',
    templateUrl: 'src/app/utils/shared-templates/user-access/store-picker-dialog.html',
})
export class StorePickerDialog {
    public newSelectedStoreTags: any = [];

    public captions: any;
    public stores;
    public currentUserAccess;
    public showAllStoresBox;
    public showErrorStoresBox;
    public USER_ACCESS;
    public storeCheckboxes;
    public selectedStore;
    public groupItem: any;
    public groupItemModel: number;
    public tagModel: number;
    public customAllStoresName: string;
    public storeItemMultiSelect: boolean;
    public selectedStores: any;
    tag: any;
    concept: any;
    public storesForTag;
    public newTag;
    public selectedUserSecurity;
    public subscriptions: Subscription[] = [];
    public allStores;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        @Inject(CaptionService) private captionService,
        // @Inject(EmitterService) private emitterService,
        @Inject(UserEmitterService) private userEmitterService,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<StorePickerDialog>,
        @Inject(EmitterService) public emitterService: EmitterService
    ) {
        this.selectedStores = data.selectedStores ?? [];
        this.captions = this.captionService.captions;
        this.stores = data.stores;
        this.storeCheckboxes = data.storeCheckboxes;
        this.currentUserAccess = data.currentUserAccess;
        this.selectedUserSecurity = data.selectedUserSecurity;
        this.groupItem = data.groupItem;
        this.groupItemModel = data.groupItemModel;
        this.customAllStoresName = data.customAllStoresName;
        this.allStores = data.allStores;
        this.storeItemMultiSelect = this.data.storeItemMultiSelect;
        this.USER_ACCESS = UserConstants.USER_ACCESS;
        this.dialogRef.keydownEvents().subscribe((event) => {
            //call cancel on escape
            if (event.key === 'Escape') {
                this.onCancel();
            }
        });

        this.dialogRef.backdropClick().subscribe(() => {
            //call cancel on clicking outside of dialog
            this.onCancel();
        });
    }

    submitStoresForStoreRendering() {
        for (let aStore of this.newSelectedStoreTags) {
            //handle single group being clicked
            if (aStore.isChecked) {
                this.selectedStores.push(aStore);
            } else {
                let tagItemIndex = this.selectedStores.indexOf(aStore);
                this.selectedStores.splice(tagItemIndex, 1);
            }
        }
        // this.emitterService.storeChangedCtrl(this.selectedStores);
        this.dialogRef.close({ data: { selectedStores: this.selectedStores } });
    }

    selectStoreBox(selectedStore) {
        // handle selecting tags
        if (typeof this.selectedStores === 'undefined') {
            this.selectedStores = [];
        }

        // check if we select multiple groups or just one group
        if (selectedStore === 0) {
            for (let store of this.stores) {
                store.isChecked = false;
            }
            this.dialogRef.close({ data: { selectedStores: null } });
        } else {
            this.handleClick(selectedStore);
        }
    }

    handleClick(selectedTagItem) {
        // if storeItemMultiSelect is false, immediately exit
        if (!this.storeItemMultiSelect) {
            this.selectedStores = [];
            this.selectedStores.push(selectedTagItem);
            this.dialogRef.close({ data: { selectedStores: this.selectedStores } });
        }

        //keep track of which buttons were pressed and undo them if we do not click select
        let newTagItemIndex = this.newSelectedStoreTags.indexOf(selectedTagItem);
        if (newTagItemIndex < 0) {
            this.newSelectedStoreTags.push(selectedTagItem);
        } else {
            this.newSelectedStoreTags.splice(newTagItemIndex, 1);
        }
        selectedTagItem.isChecked = !selectedTagItem.isChecked;
    }

    ngOnInit() {}

    ngOnDestroy() {
        for (let sub of this.subscriptions) {
            sub?.unsubscribe();
        }
    }

    onCancel() {
        // on cancelling out, we only undo the checkmarks
        for (let aStore of this.newSelectedStoreTags) {
            aStore.isChecked = !aStore.isChecked;
        }
        this.dialogRef.close();
    }
}
