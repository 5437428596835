import { Inject, Injectable } from '@angular/core';
import { ReportApiService } from './reports.resource';

@Injectable()
export class ReportTemplatesDataService {
    private reportTemplates = null;
    public templateID;
    constructor(@Inject(ReportApiService) private reportApiService: ReportApiService) { }

    getTemplates() {
        return new Promise((resolve) => {
            this.reportApiService.getAllReportTemplates().then((reportTemplates) => {
                this.reportTemplates = reportTemplates;
                resolve(reportTemplates);
            });
        });
    }

    setTemplates(reportTemplates) {
        this.reportTemplates = reportTemplates;
    }

    /**
     * @return {String array} of names of custom templates created by user
     */
    _getCustomTemplatesNames() {
        return new Promise((resolve) => {
            this.getTemplates().then((reportTemplates) => {
                this.reportTemplates = reportTemplates;
                resolve(this._getNames(this.reportTemplates.customTemplates));
            });
        });
    }

    _getNames(templates) {
        return templates.map((template) => {
            return template.displayName;
        });
    }

    getDescriptionID(templateID, reportTemplates) {
        this.templateID = templateID;
        if (reportTemplates) {
            return this._findMatch(reportTemplates, templateID);
        }
        else {
            return this.getTemplates().then((reportTemplates) => {
                this.reportTemplates = reportTemplates;
                return this._findMatch(this.reportTemplates, templateID);
            });
        }
    }

    _findMatch(templates, templateID) {
        let templatesToCheck;
        if (templateID.toString().length == 3) {
            templatesToCheck = templates.defaultTemplates;
        }
        else templatesToCheck = templates.customTemplates;
        for (let i = 0; i < templatesToCheck.length; i++) {
            let template = templatesToCheck[i];
            if (template.id == templateID) {
                return template.descriptionID;
            }
        }
    }

    isTemplateUniqueName(reportName) {
        let isUniqueTemplate = true;
        return this.reportApiService.getAllReportTemplates().then((reportTemplates) => {
            this.reportTemplates = reportTemplates;
            for (let customTemplateName of this.reportTemplates.customTemplates) {
                if (reportName == customTemplateName) {
                    isUniqueTemplate = false
                }
            }
            return isUniqueTemplate;
        });
    }
}