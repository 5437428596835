import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { ReportDataWizard } from '../services/report-data-wizard.service';
import { SharedWizardData } from '../services/report-shared-wizard-data.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'reportWizardButtons',
    templateUrl: 'src/app/features/reports/wizard/templates/report-wizard-buttons.html',
})
@Injectable()
export class ReportWizardButtonsComponent {
    @Input() public btnClickedMoreThanOnce = false;
    @Output() nextClicked = new EventEmitter();
    @Input() public hasError = false;

    public buttonsText = { ok: '', back: '', cancel: '' };
    public captions;
    constructor(
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(ReportDataWizard) private reportDataWizard: ReportDataWizard,
        @Inject(SharedWizardData) private sharedWizardData: SharedWizardData,
        @Inject(Router) public router: Router,
        @Inject(ActivatedRoute) public actRoute: ActivatedRoute,
    ) {
        this.captions = this.captionService.captions;
        this.buttonsText.back = this.captions.back;
        this.buttonsText.cancel = this.captions.cancelFront;
        // if (this.reportDataWizard.isNextStepExists()) {
        //     this.buttonsText.ok = this.captions.next;
        // } else {
        //     this.buttonsText.ok =
        //         reportDataWizard.containsStep(reportDataWizard.STEP_TYPE.SCHEDULE) != -1 ?
        //             this.captions.confirm :
        //             this.captions.run;
        // }
    }

    // backBtnFunc() {
    //     this.reportDataWizard.moveToPreviousStep();
    //     this.router.navigate(['reports/wizard',
    //         this.sharedWizardData.conceptID,
    //         this.sharedWizardData.conceptName
    //     ]);
    // }

//     cancelBtnFunc() {
//         // on cancel reset the schedule date data
//         this.reportDataWizard.moveToBeginning();
//         this.router.navigate(['reports/manageReports',
//         this.sharedWizardData.conceptID,
//         this.sharedWizardData.conceptName
//     ]);
//     // this.sharedWizardData.delete();
//     this.sharedWizardData.resetScheduleWizardData();
// }

    btnClickedOnce(buttonText) {
        this.btnClickedMoreThanOnce = buttonText == 'Run' ? true : false;
        this.nextClicked.emit();
        // reset sharedwizarddata on confirmation/submit
        if (buttonText.toUpperCase() === 'CONFIRM') {
            this.sharedWizardData.resetScheduleWizardData();
        }
    };


}