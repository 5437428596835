import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/shared-services/snackbar/snackbar.service';
import { FeedbackApiService } from '../services/feedback.resource';
import { Feedback } from '../models/feedback.model';
import { ValidationService } from '../../../utils/shared-services/validation.service';

@Component({
    selector: 'feedback',
    templateUrl: 'src/app/features/feedback/templates/feedback-form.html',
})
export class FeedbackFormComponent {
    public captions: Record<string, string>;
    public feedbackObject: Feedback = {
        rating: 0,
        submissionType: '',
        feedbackProvided: '',
    };
    public errorMsg: string = '';

    constructor(
        @Inject(FeedbackApiService) private feedbackApiService: FeedbackApiService,
        @Inject(SnackbarService) private snackbarService: SnackbarService,
        @Inject(Router) private router: Router,
        @Inject(ActivatedRoute) private actRoute: ActivatedRoute,
        @Inject(ValidationService) public validationService: ValidationService,
    ) { }

    ngOnInit(): void {
        this.actRoute.data.subscribe(data => {
            this.captions = data.captions;
        });
    }

    ratingOption(rate: number): void {
        this.feedbackObject.rating = rate;
    }

    selectedOption(option: string): void {
        this.feedbackObject.submissionType = option;
    }

    submit(): void {
        if (!this.feedbackObject.rating) {
            this.errorMsg = this.captions.selectRating;
        } else if (!this.feedbackObject.submissionType) {
            this.errorMsg = this.captions.selectCategory;
        } else if (!this.feedbackObject.feedbackProvided) {
            this.errorMsg = this.captions.provideDetails;
        } else {
            this.errorMsg = '';
            this.feedbackApiService
                .submitFeedbackForm(this.feedbackObject)
                .then(() => {
                    this.snackbarService.successMessageBottom(this.captions.feedbackThanks);
                    this.router.navigate(['home']);
                })
                .catch((response) => {
                    this.snackbarService.errorMessageBottom(response.error.reason);
                });
        }
    }

    cancelChanges(): void {
        this.router.navigate(['home']);
    }
}
