import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as txml from 'txml';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { EoiApiService } from '../../services/eoi.resource';
import { EoiFailResponseDialog } from './eoi-fail-response-dialog.component';
import { EoiSuccessResponseDialog } from './eoi-success-response-dialog.component';

interface Parameters {
    virtualVendor: any;
    EOIArray: any;
    vendorClicked: any;
    storeID: any;
    timeRange: any;
    reqType: any;
    vendorID: any;
    duration: any;
    updateTimeRange: any;
}

@Component({
    selector: 'eoi-vendor-dialog',
    templateUrl: 'src/app/features/eoi/components/dialogs/templates/eoi-vendor.dialog.html',
})
export class EoiVendorDialog {
    public captions: Record<string,string>;

    constructor(
        @Inject(EoiApiService) private eoiApiService,
        @Inject(CaptionService) private captionService: CaptionService,
        @Inject(MAT_DIALOG_DATA) public data: Parameters,
        @Inject(MatDialogRef) public dialogRef: MatDialogRef<EoiVendorDialog>,
        @Inject(MatDialog) private dialog: MatDialog
    ) {
        this.captions = this.captionService.captions;
        // no longer having pop up, automatically choosing orders to made via virtual vendor
        this.sendOrder(this.data.virtualVendor);
    }

    failResponse(): void {
        const dialogRef = this.dialog.open(EoiFailResponseDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
        });

        this.data.updateTimeRange(this.data.storeID, this.data.timeRange, this.data.reqType, this.data.vendorID);

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    successResponse(): void {
        const dialogRef = this.dialog.open(EoiSuccessResponseDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '90vw',
            data: {
                duration: this.data.duration,
            },
        });

        this.data.updateTimeRange(this.data.storeID, this.data.timeRange, this.data.reqType, this.data.vendorID);

        dialogRef.backdropClick().subscribe(() => {
            dialogRef.close();
        });
    }

    sendOrder(eoi): void {
        this.data.vendorClicked = true;
        let apiConsumerID;
        let readToken;
        let storeID;
        if (eoi == 15000000) {
            apiConsumerID = 15000000;
            readToken = this.data.storeID;
            storeID = this.data.storeID;
        } else {
            apiConsumerID = eoi.apiConsumerID;
            readToken = eoi.readToken;
            storeID = eoi.storeID;
        }
        this.eoiApiService.getLazyApiResponseByXMLString(apiConsumerID, readToken, storeID).then((response) => {
            this.dialogRef.close();
            const result = txml.simplify(txml.parse(response.responseString));
            this.data.duration = response.duration;

            if (Number(result.Response.ErrorNum) !== 0) {
                this.failResponse();
            } else {
                this.successResponse();
            }
        });
    }
}
