import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { InventoryCountApiService } from '../../features/inventory-counter/services/inventory-counter.resource';
import { InventoryCounterService } from '../../features/inventory-counter/services/inventory-counter.service';

@Injectable()
export class OnlyLoggedInInventoryUsersGuard implements CanActivate {
    constructor(
        @Inject(InventoryCountApiService) private inventoryCountApiService: InventoryCountApiService,
        @Inject(InventoryCounterService) private inventoryCounterService: InventoryCounterService,
        @Inject(Router) private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot) {
        let path = route.url[0].path;
        let savedAccessToken = this.inventoryCounterService.getAccessToken();
        let activeCountType = this.inventoryCounterService.getActiveCountType();

        if (!savedAccessToken) {
            this.router.navigate(['inventoryCounter']);
            return false;
        }
        if (!activeCountType && path !== 'countType') {
            this.router.navigate(['inventoryCounter/countType']);
            return false;
        }
        if (activeCountType && path === 'countType') {
            this.router.navigate(['inventoryCounter/countWizard']);
            return false;
        }

        return this.inventoryCountApiService
            .validateAccessToken(savedAccessToken)
            .then((res) => {
                if (res) {
                    return true;
                } else {
                    return false;
                }
            })
            .catch(() => {
                this.router.navigate(['inventoryCounter']);
                this.inventoryCounterService.clearAccessToken();
                return false;
            });
    }
}
