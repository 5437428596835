import { Component, Inject, Optional } from '@angular/core';
import { CaptionService } from '../../../../utils/shared-services/caption.service';
import { MatDialog } from '@angular/material/dialog';
import { WidgetDescriptionDialog } from '../../components/dialogs/widget-description-dialog.component';
import { Sort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { BrowserStorage } from '../../../../utils/shared-services/browser-storage.service';

@Component({
    selector: 'wid014',
    templateUrl: 'src/app/features/store-portal/widgets/templates/wid-014.html',
})
export class Wid014 {
    private readonly WIDGET_COLUMN1_KEY: string = 'wid014_col1';
    private readonly WIDGET_COLUMN2_KEY: string = 'wid014_col2';

    public areDatapointsInvalid: boolean = true;
    public invalidDatapoints: any[] = [];
    public datapoints: any[] = [];

    public pageEvent: PageEvent;
    private pageIndex = 0;
    public pageSize = 20;
    public captions: any;
    public isTimedOut: boolean = false;
    public records = [];
    public recordData = [];
    // array used for displaying a portion of data on the table
    public displayData = [];
    // key used for storing current sort state to keep it after page change
    public currentSort: Sort = null;

    constructor(
        @Inject(Object) @Optional() private widget: any,
        @Inject(CaptionService) private captionService,
        @Inject(MatDialog) private dialog: MatDialog,
        @Inject(BrowserStorage) private browserStorage: BrowserStorage
    ) {
        this.captions = this.captionService.captions;
        if (this.widget?.id) {
            this.datapoints = this.browserStorage.getSessionstorage(this.widget.id, null);
        }
    }

    openInfoDialog() {
        this.dialog.open(WidgetDescriptionDialog, {
            panelClass: 'app-full-bleed-dialog',
            maxWidth: '95vw',
            data: {
                widgetID: this.widget.id,
            },
        });
    }

    ngOnInit() {
        // to change animation on wid-000
        if (this.widget && this.widget.isTimedOut) {
            this.isTimedOut = true;
            return;
        }

        if (!this.widget || !this.widget.isLoaded) {
            return;
        }

        // Verify datapoints, making sure they are not null or undefined
        this.areDatapointsInvalid = false;
        for (let i = 0; i < this.widget.data.length; i++) {
            if (!this.widget.data[i]) {
                this.areDatapointsInvalid = true;
                this.invalidDatapoints.push(this.datapoints[i]);
            }
        }
        if (this.areDatapointsInvalid) {
            return;
        }

        
        // parse data from this.widget
        this.records = this.widget.data[0].Record;
        // create another copy of data for sorting and avoid data mutation
        if(this.records){
            this.recordData = this.records.slice();
            this.updateDisplayData();
        }
    }

    sortData(sort: Sort) {
        this.currentSort = sort;
        const data = this.updateDisplayData();
        if (!sort.active || sort.direction === '') {
            this.displayData = data;
            return;
        }
        this.displayData = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'wid014_col1':
                    return this.compare(a.NAME + ' #' + a.UID, b.NAME + ' #' + b.UID, isAsc);
                case 'wid014_col2':
                    return this.compare(a.VERS, b.VERS, isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: any, b: any, isAsc: boolean) {
        if ((isNaN(a) && isNaN(b)) || (!isNaN(a) && !isNaN(b))) {
            // sort strings, then numbers
            return a > b ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
        } else {
            // only one value is a number
            return isNaN(a) ? (isAsc ? -1 : 1) : isAsc ? 1 : -1;
        }
    }

    updateDisplayData(): any[] {
        const startIndex = this.pageIndex * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.displayData = this.recordData.slice(startIndex, endIndex);

        return this.displayData;
    }

    handlePageEvent(e: PageEvent) {
        this.pageEvent = e;
        this.pageIndex = e.pageIndex;
        if (this.currentSort) {
            this.sortData(this.currentSort);
        } else {
            this.updateDisplayData();
        }
    }
}
